import * as geolib from 'geolib'

export const airports = [
  {
    ICAOcode: 'UKLL',
    Runways: {
      31: {
        heading: 310,
        threshold: {
          latitude: 49.799906,
          longitude: 23.973181,
        },
        elevation: 1077,
        approachArea: [
          {
            latitude: 49.799906,
            longitude: 23.973181,
          },
          geolib.computeDestinationPoint(
            {
              latitude: 49.799906,
              longitude: 23.973181,
            },
            18 * 1.852 * 1000,
            (310 + 180 + 5 + 6) % 360,
          ),
          geolib.computeDestinationPoint(
            {
              latitude: 49.799906,
              longitude: 23.973181,
            },
            18 * 1.852 * 1000,
            (310 + 180 - 5 + 6) % 360,
          ),
          {
            latitude: 49.799906,
            longitude: 23.973181,
          },
        ],
      },
      13: {
        heading: 130,
        threshold: {
          latitude: 49.817781,
          longitude: 23.946283,
        },
        elevation: 1071,
        approachArea: [
          {
            latitude: 49.817781,
            longitude: 23.946283,
          },
          geolib.computeDestinationPoint(
            {
              latitude: 49.817781,
              longitude: 23.946283,
            },
            18 * 1.852 * 1000,
            (130 + 180 + 5 + 6) % 360,
          ),
          geolib.computeDestinationPoint(
            {
              latitude: 49.817781,
              longitude: 23.946283,
            },
            18 * 1.852 * 1000,
            (130 + 180 - 5 + 6) % 360,
          ),
          {
            latitude: 49.817781,
            longitude: 23.946283,
          },
        ],
      },
    },
    Sid: [
      {
        Name: 'ADBA1F',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LL401',
            Type: 'Normal',
            Latitude: '49.735778',
            Longitude: '24.069444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LLE01',
            Type: 'Normal',
            Latitude: '49.803889',
            Longitude: '24.903056',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'ADBAN',
            Type: 'Normal',
            Latitude: '49.802222',
            Longitude: '25.391111',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'ADBA1G',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL203',
            Type: 'Normal',
            Latitude: '49.968028',
            Longitude: '23.899250',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Right',
          },
          {
            ID: '3',
            Name: 'LLN01',
            Type: 'Normal',
            Latitude: '49.932278',
            Longitude: '24.237028',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LLE01',
            Type: 'Normal',
            Latitude: '49.803889',
            Longitude: '24.903056',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'ADBAN',
            Type: 'Normal',
            Latitude: '49.802222',
            Longitude: '25.391111',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'ADBA2B',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
          },
          {
            ID: '3',
            Name: 'ADBAN',
            Type: 'Normal',
            Latitude: '49.802222',
            Longitude: '25.391111',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'ADBA3A',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '130',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '49.802222',
            Longitude: '25.391111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '020',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '264',
            Sp_Turn: 'Left',
          },
          {
            ID: '3',
            Name: 'ADBAN',
            Type: 'Normal',
            Latitude: '49.802222',
            Longitude: '25.391111',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'DIBE1F',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LL401',
            Type: 'Normal',
            Latitude: '49.735778',
            Longitude: '24.069444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL403',
            Type: 'Normal',
            Latitude: '49.677361',
            Longitude: '23.976694',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LLW01',
            Type: 'Normal',
            Latitude: '49.737639',
            Longitude: '23.672333',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'DIBED',
            Type: 'Normal',
            Latitude: '49.888333',
            Longitude: '23.058333',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'DIBE1G',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL201',
            Type: 'Normal',
            Latitude: '49.904278',
            Longitude: '23.785139',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LL205',
            Type: 'Normal',
            Latitude: '49.901778',
            Longitude: '23.455750',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'DIBED',
            Type: 'Normal',
            Latitude: '49.888333',
            Longitude: '23.058333',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'DIBE7A',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '130',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
          },
          {
            ID: '3',
            Name: 'DIBED',
            Type: 'Normal',
            Latitude: '49.888333',
            Longitude: '23.058333',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'DIBE8B',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '49.888333',
            Longitude: '23.058333',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '224',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '092',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'DIBED',
            Type: 'Normal',
            Latitude: '49.888333',
            Longitude: '23.058333',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GIDN1F',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LL401',
            Type: 'Normal',
            Latitude: '49.735778',
            Longitude: '24.069444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LLE01',
            Type: 'Normal',
            Latitude: '49.803889',
            Longitude: '24.903056',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'GIDNO',
            Type: 'Normal',
            Latitude: '50.121389',
            Longitude: '26.037778',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GIDN1G',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL203',
            Type: 'Normal',
            Latitude: '49.968028',
            Longitude: '23.899250',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Right',
          },
          {
            ID: '3',
            Name: 'LLN01',
            Type: 'Normal',
            Latitude: '49.932278',
            Longitude: '24.237028',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LLE01',
            Type: 'Normal',
            Latitude: '49.803889',
            Longitude: '24.903056',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'GIDNO',
            Type: 'Normal',
            Latitude: '50.121389',
            Longitude: '26.037778',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GIDN2B',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
          },
          {
            ID: '3',
            Name: 'GIDNO',
            Type: 'Normal',
            Latitude: '50.121389',
            Longitude: '26.037778',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GIDN3A',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '130',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.121389',
            Longitude: '26.037778',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '353',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '250',
            Sp_Turn: 'Left',
          },
          {
            ID: '3',
            Name: 'GIDNO',
            Type: 'Normal',
            Latitude: '50.121389',
            Longitude: '26.037778',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GORK1F',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LL401',
            Type: 'Normal',
            Latitude: '49.735778',
            Longitude: '24.069444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'GORKU',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '25.072222',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GORK1G',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL203',
            Type: 'Normal',
            Latitude: '49.968028',
            Longitude: '23.899250',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Right',
          },
          {
            ID: '3',
            Name: 'LLN01',
            Type: 'Normal',
            Latitude: '49.932278',
            Longitude: '24.237028',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LLE01',
            Type: 'Normal',
            Latitude: '49.803889',
            Longitude: '24.903056',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'GORKU',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '25.072222',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GORK2B',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
          },
          {
            ID: '3',
            Name: 'GORKU',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '25.072222',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GORK3A',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '130',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '49.605278',
            Longitude: '25.072222',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '1',
            Hdg_Crs_value: '069',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '279',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'GORKU',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '25.072222',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GOTI2F',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LL401',
            Type: 'Normal',
            Latitude: '49.735778',
            Longitude: '24.069444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL403',
            Type: 'Normal',
            Latitude: '49.677361',
            Longitude: '23.976694',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LLW01',
            Type: 'Normal',
            Latitude: '49.737639',
            Longitude: '23.672333',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'GOTIX',
            Type: 'Normal',
            Latitude: '50.021667',
            Longitude: '23.211944',
            Speed: '0',
            Altitude: '14000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GOTI2G',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL201',
            Type: 'Normal',
            Latitude: '49.904278',
            Longitude: '23.785139',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LL205',
            Type: 'Normal',
            Latitude: '49.901778',
            Longitude: '23.455750',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'GOTIX',
            Type: 'Normal',
            Latitude: '50.021667',
            Longitude: '23.211944',
            Speed: '0',
            Altitude: '14000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GOTI5A',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '130',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
          },
          {
            ID: '3',
            Name: 'GOTIX',
            Type: 'Normal',
            Latitude: '50.021667',
            Longitude: '23.211944',
            Speed: '0',
            Altitude: '14000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GOTI5B',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.021667',
            Longitude: '23.211944',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '1',
            Hdg_Crs_value: '258',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '108',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'GOTIX',
            Type: 'Normal',
            Latitude: '50.021667',
            Longitude: '23.211944',
            Speed: '0',
            Altitude: '14000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KOKU1F',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LL401',
            Type: 'Normal',
            Latitude: '49.735778',
            Longitude: '24.069444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL403',
            Type: 'Normal',
            Latitude: '49.677361',
            Longitude: '23.976694',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'KOKUP',
            Type: 'Normal',
            Latitude: '49.528333',
            Longitude: '23.655000',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KOKU1G',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL204',
            Type: 'Normal',
            Latitude: '49.863583',
            Longitude: '23.715056',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'KOKUP',
            Type: 'Normal',
            Latitude: '49.528333',
            Longitude: '23.655000',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KOKU4A',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '130',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
          },
          {
            ID: '3',
            Name: 'KOKUP',
            Type: 'Normal',
            Latitude: '49.528333',
            Longitude: '23.655000',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KOKU4B',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
          },
          {
            ID: '3',
            Name: 'KOKUP',
            Type: 'Normal',
            Latitude: '49.528333',
            Longitude: '23.655000',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KOVU1F',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LL401',
            Type: 'Normal',
            Latitude: '49.735778',
            Longitude: '24.069444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL402',
            Type: 'Normal',
            Latitude: '49.794111',
            Longitude: '24.162389',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
          },
          {
            ID: '3',
            Name: 'LLE02',
            Type: 'Normal',
            Latitude: '49.927667',
            Longitude: '24.218111',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'KOVUS',
            Type: 'Normal',
            Latitude: '50.142222',
            Longitude: '24.241389',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KOVU1G',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL202',
            Type: 'Normal',
            Latitude: '49.959944',
            Longitude: '23.846167',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'KOVUS',
            Type: 'Normal',
            Latitude: '50.142222',
            Longitude: '24.241389',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KOVU2A',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '130',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
          },
          {
            ID: '3',
            Name: 'KOVUS',
            Type: 'Normal',
            Latitude: '50.142222',
            Longitude: '24.241389',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KOVU3B',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.142222',
            Longitude: '24.241389',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '101',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '203',
            Sp_Turn: 'Right',
          },
          {
            ID: '3',
            Name: 'KOVUS',
            Type: 'Normal',
            Latitude: '50.142222',
            Longitude: '24.241389',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KUSA1F',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LL401',
            Type: 'Normal',
            Latitude: '49.735778',
            Longitude: '24.069444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'KUSAK',
            Type: 'Normal',
            Latitude: '49.345000',
            Longitude: '24.501667',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KUSA1G',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL204',
            Type: 'Normal',
            Latitude: '49.863583',
            Longitude: '23.715056',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'KOKUP',
            Type: 'Normal',
            Latitude: '49.528333',
            Longitude: '23.655000',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'KUSAK',
            Type: 'Normal',
            Latitude: '49.345000',
            Longitude: '24.501667',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KUSA7B',
        Runways: '31',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
          },
          {
            ID: '3',
            Name: 'KUSAK',
            Type: 'Normal',
            Latitude: '49.345000',
            Longitude: '24.501667',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KUSA8A',
        Runways: '13',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LIV',
            Type: 'DmeIntc',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '3450',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '130',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'KUSAK',
            Type: 'Normal',
            Latitude: '49.345000',
            Longitude: '24.501667',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
    ],
    Star: [
      {
        Name: 'ADBA1H',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'ADBAN',
            Type: 'Normal',
            Latitude: '49.802222',
            Longitude: '25.391111',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LLE01',
            Type: 'Normal',
            Latitude: '49.803889',
            Longitude: '24.903056',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LLE02',
            Type: 'Normal',
            Latitude: '49.927667',
            Longitude: '24.218111',
            Speed: '220',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LL304',
            Type: 'Normal',
            Latitude: '49.988944',
            Longitude: '23.869083',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LL302',
            Type: 'Normal',
            Latitude: '49.930361',
            Longitude: '23.776194',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '6',
            Name: 'CI13',
            Type: 'Normal',
            Latitude: '49.891894',
            Longitude: '23.834889',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'ADBA1J',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'ADBAN',
            Type: 'Normal',
            Latitude: '49.802222',
            Longitude: '25.391111',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LLE01',
            Type: 'Normal',
            Latitude: '49.803889',
            Longitude: '24.903056',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LL115',
            Type: 'Normal',
            Latitude: '49.817611',
            Longitude: '24.307306',
            Speed: '220',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LL114',
            Type: 'Normal',
            Latitude: '49.865611',
            Longitude: '24.235639',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LL113',
            Type: 'Normal',
            Latitude: '49.807306',
            Longitude: '24.142583',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '6',
            Name: 'LL112',
            Type: 'Normal',
            Latitude: '49.759389',
            Longitude: '24.214222',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '7',
            Name: 'LL111',
            Type: 'Normal',
            Latitude: '49.711444',
            Longitude: '24.285694',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '8',
            Name: 'LL110',
            Type: 'Normal',
            Latitude: '49.663444',
            Longitude: '24.357056',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '9',
            Name: 'LL104',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '24.264167',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '10',
            Name: 'LL103',
            Type: 'Normal',
            Latitude: '49.665389',
            Longitude: '24.174444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '11',
            Name: 'CI31',
            Type: 'Normal',
            Latitude: '49.707564',
            Longitude: '24.111789',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'ADBA2C',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'ADBAN',
            Type: 'Normal',
            Latitude: '49.802222',
            Longitude: '25.391111',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'ADBA2D',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'ADBAN',
            Type: 'Normal',
            Latitude: '49.802222',
            Longitude: '25.391111',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'DIBE1J',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'DIBED',
            Type: 'Normal',
            Latitude: '49.888333',
            Longitude: '23.058333',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'KOKUP',
            Type: 'Normal',
            Latitude: '49.528333',
            Longitude: '23.655000',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LL109',
            Type: 'Normal',
            Latitude: '49.632000',
            Longitude: '23.864611',
            Speed: '220',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LL108',
            Type: 'Normal',
            Latitude: '49.690500',
            Longitude: '23.957028',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LL107',
            Type: 'Normal',
            Latitude: '49.642722',
            Longitude: '24.028667',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '6',
            Name: 'LL106',
            Type: 'Normal',
            Latitude: '49.594889',
            Longitude: '24.100167',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '7',
            Name: 'LL105',
            Type: 'Normal',
            Latitude: '49.547000',
            Longitude: '24.171528',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '8',
            Name: 'LL104',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '24.264167',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '9',
            Name: 'LL103',
            Type: 'Normal',
            Latitude: '49.665389',
            Longitude: '24.174444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '10',
            Name: 'CI31',
            Type: 'Normal',
            Latitude: '49.707564',
            Longitude: '24.111789',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'DIBE2H',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'DIBED',
            Type: 'Normal',
            Latitude: '49.888333',
            Longitude: '23.058333',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LLW01',
            Type: 'Normal',
            Latitude: '49.737639',
            Longitude: '23.672333',
            Speed: '220',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LL303',
            Type: 'Normal',
            Latitude: '49.871722',
            Longitude: '23.683500',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LL302',
            Type: 'Normal',
            Latitude: '49.930361',
            Longitude: '23.776194',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'CI13',
            Type: 'Normal',
            Latitude: '49.891894',
            Longitude: '23.834889',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'DIBE7C',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'DIBED',
            Type: 'Normal',
            Latitude: '49.888333',
            Longitude: '23.058333',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'DIBE7D',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'DIBED',
            Type: 'Normal',
            Latitude: '49.888333',
            Longitude: '23.058333',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GORK1H',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'GORKU',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '25.072222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LLE01',
            Type: 'Normal',
            Latitude: '49.803889',
            Longitude: '24.903056',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LLE02',
            Type: 'Normal',
            Latitude: '49.927667',
            Longitude: '24.218111',
            Speed: '220',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LL304',
            Type: 'Normal',
            Latitude: '49.988944',
            Longitude: '23.869083',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LL302',
            Type: 'Normal',
            Latitude: '49.930361',
            Longitude: '23.776194',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '6',
            Name: 'CI13',
            Type: 'Normal',
            Latitude: '49.891894',
            Longitude: '23.834889',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GORK1J',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'GORKU',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '25.072222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL115',
            Type: 'Normal',
            Latitude: '49.817611',
            Longitude: '24.307306',
            Speed: '220',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LL114',
            Type: 'Normal',
            Latitude: '49.865611',
            Longitude: '24.235639',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LL113',
            Type: 'Normal',
            Latitude: '49.807306',
            Longitude: '24.142583',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LL112',
            Type: 'Normal',
            Latitude: '49.759389',
            Longitude: '24.214222',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '6',
            Name: 'LL111',
            Type: 'Normal',
            Latitude: '49.711444',
            Longitude: '24.285694',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '7',
            Name: 'LL110',
            Type: 'Normal',
            Latitude: '49.663444',
            Longitude: '24.357056',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '8',
            Name: 'LL104',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '24.264167',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '9',
            Name: 'LL103',
            Type: 'Normal',
            Latitude: '49.665389',
            Longitude: '24.174444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '10',
            Name: 'CI31',
            Type: 'Normal',
            Latitude: '49.707564',
            Longitude: '24.111789',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GORK2C',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'GORKU',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '25.072222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'GORK2D',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'GORKU',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '25.072222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },

      {
        Name: 'KOKU1J',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'KOKUP',
            Type: 'Normal',
            Latitude: '49.528333',
            Longitude: '23.655000',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL109',
            Type: 'Normal',
            Latitude: '49.632000',
            Longitude: '23.864611',
            Speed: '220',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LL108',
            Type: 'Normal',
            Latitude: '49.690500',
            Longitude: '23.957028',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LL107',
            Type: 'Normal',
            Latitude: '49.642722',
            Longitude: '24.028667',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LL106',
            Type: 'Normal',
            Latitude: '49.594889',
            Longitude: '24.100167',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '6',
            Name: 'LL105',
            Type: 'Normal',
            Latitude: '49.547000',
            Longitude: '24.171528',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '7',
            Name: 'LL104',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '24.264167',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '8',
            Name: 'LL103',
            Type: 'Normal',
            Latitude: '49.665389',
            Longitude: '24.174444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '9',
            Name: 'CI31',
            Type: 'Normal',
            Latitude: '49.707564',
            Longitude: '24.111789',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KOKU2H',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'KOKUP',
            Type: 'Normal',
            Latitude: '49.528333',
            Longitude: '23.655000',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LLW01',
            Type: 'Normal',
            Latitude: '49.737639',
            Longitude: '23.672333',
            Speed: '220',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LL303',
            Type: 'Normal',
            Latitude: '49.871722',
            Longitude: '23.683500',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LL302',
            Type: 'Normal',
            Latitude: '49.930361',
            Longitude: '23.776194',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'CI13',
            Type: 'Normal',
            Latitude: '49.891894',
            Longitude: '23.834889',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KOKU1D',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'KOKUP',
            Type: 'Normal',
            Latitude: '49.528333',
            Longitude: '23.655000',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KOKU4C',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'KOKUP',
            Type: 'Normal',
            Latitude: '49.528333',
            Longitude: '23.655000',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },

      {
        Name: 'KOVU1H',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'KOVUS',
            Type: 'Normal',
            Latitude: '50.142222',
            Longitude: '24.241389',
            Speed: '220',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL304',
            Type: 'Normal',
            Latitude: '49.988944',
            Longitude: '23.869083',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LL302',
            Type: 'Normal',
            Latitude: '49.930361',
            Longitude: '23.776194',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'CI13',
            Type: 'Normal',
            Latitude: '49.891894',
            Longitude: '23.834889',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KOVU1J',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'KOVUS',
            Type: 'Normal',
            Latitude: '50.142222',
            Longitude: '24.241389',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LLN01',
            Type: 'Normal',
            Latitude: '49.932278',
            Longitude: '24.237028',
            Speed: '220',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LL114',
            Type: 'Normal',
            Latitude: '49.865611',
            Longitude: '24.235639',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LL113',
            Type: 'Normal',
            Latitude: '49.807306',
            Longitude: '24.142583',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LL112',
            Type: 'Normal',
            Latitude: '49.759389',
            Longitude: '24.214222',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '6',
            Name: 'LL111',
            Type: 'Normal',
            Latitude: '49.711444',
            Longitude: '24.285694',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '7',
            Name: 'LL110',
            Type: 'Normal',
            Latitude: '49.663444',
            Longitude: '24.357056',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '8',
            Name: 'LL104',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '24.264167',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '9',
            Name: 'LL103',
            Type: 'Normal',
            Latitude: '49.665389',
            Longitude: '24.174444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '10',
            Name: 'CI31',
            Type: 'Normal',
            Latitude: '49.707564',
            Longitude: '24.111789',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KOVU1D',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'KOVUS',
            Type: 'Normal',
            Latitude: '50.142222',
            Longitude: '24.241389',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KOVU4C',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'KOVUS',
            Type: 'Normal',
            Latitude: '50.142222',
            Longitude: '24.241389',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KUSA1J',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'KUSAK',
            Type: 'Normal',
            Latitude: '49.345000',
            Longitude: '24.501667',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL116',
            Type: 'Normal',
            Latitude: '49.584222',
            Longitude: '23.936306',
            Speed: '220',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LL109',
            Type: 'Normal',
            Latitude: '49.632000',
            Longitude: '23.864611',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LL108',
            Type: 'Normal',
            Latitude: '49.690500',
            Longitude: '23.957028',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LL107',
            Type: 'Normal',
            Latitude: '49.642722',
            Longitude: '24.028667',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '6',
            Name: 'LL106',
            Type: 'Normal',
            Latitude: '49.594889',
            Longitude: '24.100167',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '7',
            Name: 'LL105',
            Type: 'Normal',
            Latitude: '49.547000',
            Longitude: '24.171528',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '8',
            Name: 'LL104',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '24.264167',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '9',
            Name: 'LL103',
            Type: 'Normal',
            Latitude: '49.665389',
            Longitude: '24.174444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '10',
            Name: 'CI31',
            Type: 'Normal',
            Latitude: '49.707564',
            Longitude: '24.111789',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KUSA2H',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'KUSAK',
            Type: 'Normal',
            Latitude: '49.345000',
            Longitude: '24.501667',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'KOKUP',
            Type: 'Normal',
            Latitude: '49.528333',
            Longitude: '23.655000',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LLW01',
            Type: 'Normal',
            Latitude: '49.737639',
            Longitude: '23.672333',
            Speed: '220',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LL303',
            Type: 'Normal',
            Latitude: '49.871722',
            Longitude: '23.683500',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LL302',
            Type: 'Normal',
            Latitude: '49.930361',
            Longitude: '23.776194',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '6',
            Name: 'CI13',
            Type: 'Normal',
            Latitude: '49.891894',
            Longitude: '23.834889',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KUSA7C',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'KUSAK',
            Type: 'Normal',
            Latitude: '49.345000',
            Longitude: '24.501667',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'KUSA7D',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'KUSAK',
            Type: 'Normal',
            Latitude: '49.345000',
            Longitude: '24.501667',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'ROLK1H',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'ROLKA',
            Type: 'Normal',
            Latitude: '50.404167',
            Longitude: '23.825556',
            Speed: '0',
            Altitude: '21000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'KOVUS',
            Type: 'Normal',
            Latitude: '50.142222',
            Longitude: '24.241389',
            Speed: '220',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LL304',
            Type: 'Normal',
            Latitude: '49.988944',
            Longitude: '23.869083',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LL302',
            Type: 'Normal',
            Latitude: '49.930361',
            Longitude: '23.776194',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'CI13',
            Type: 'Normal',
            Latitude: '49.891894',
            Longitude: '23.834889',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'ROLK1J',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'ROLKA',
            Type: 'Normal',
            Latitude: '50.404167',
            Longitude: '23.825556',
            Speed: '0',
            Altitude: '21000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'KOVUS',
            Type: 'Normal',
            Latitude: '50.142222',
            Longitude: '24.241389',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LLN01',
            Type: 'Normal',
            Latitude: '49.932278',
            Longitude: '24.237028',
            Speed: '220',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LL114',
            Type: 'Normal',
            Latitude: '49.865611',
            Longitude: '24.235639',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LL113',
            Type: 'Normal',
            Latitude: '49.807306',
            Longitude: '24.142583',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '6',
            Name: 'LL112',
            Type: 'Normal',
            Latitude: '49.759389',
            Longitude: '24.214222',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '7',
            Name: 'LL111',
            Type: 'Normal',
            Latitude: '49.711444',
            Longitude: '24.285694',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '8',
            Name: 'LL110',
            Type: 'Normal',
            Latitude: '49.663444',
            Longitude: '24.357056',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '9',
            Name: 'LL104',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '24.264167',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '10',
            Name: 'LL103',
            Type: 'Normal',
            Latitude: '49.665389',
            Longitude: '24.174444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '11',
            Name: 'CI31',
            Type: 'Normal',
            Latitude: '49.707564',
            Longitude: '24.111789',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'ROLK2C',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'ROLKA',
            Type: 'Normal',
            Latitude: '50.404167',
            Longitude: '23.825556',
            Speed: '0',
            Altitude: '21000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'ROLK2D',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'ROLKA',
            Type: 'Normal',
            Latitude: '50.404167',
            Longitude: '23.825556',
            Speed: '0',
            Altitude: '21000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'TEPN1J',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'TEPNA',
            Type: 'Normal',
            Latitude: '49.504722',
            Longitude: '22.656111',
            Speed: '0',
            Altitude: '15000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'KOKUP',
            Type: 'Normal',
            Latitude: '49.528333',
            Longitude: '23.655000',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LL109',
            Type: 'Normal',
            Latitude: '49.632000',
            Longitude: '23.864611',
            Speed: '220',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LL108',
            Type: 'Normal',
            Latitude: '49.690500',
            Longitude: '23.957028',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LL107',
            Type: 'Normal',
            Latitude: '49.642722',
            Longitude: '24.028667',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '6',
            Name: 'LL106',
            Type: 'Normal',
            Latitude: '49.594889',
            Longitude: '24.100167',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '7',
            Name: 'LL105',
            Type: 'Normal',
            Latitude: '49.547000',
            Longitude: '24.171528',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '8',
            Name: 'LL104',
            Type: 'Normal',
            Latitude: '49.605278',
            Longitude: '24.264167',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '9',
            Name: 'LL103',
            Type: 'Normal',
            Latitude: '49.665389',
            Longitude: '24.174444',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '10',
            Name: 'CI31',
            Type: 'Normal',
            Latitude: '49.707564',
            Longitude: '24.111789',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'TEPN2H',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'TEPNA',
            Type: 'Normal',
            Latitude: '49.504722',
            Longitude: '22.656111',
            Speed: '0',
            Altitude: '15000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'KOKUP',
            Type: 'Normal',
            Latitude: '49.528333',
            Longitude: '23.655000',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LLW01',
            Type: 'Normal',
            Latitude: '49.737639',
            Longitude: '23.672333',
            Speed: '220',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LL303',
            Type: 'Normal',
            Latitude: '49.871722',
            Longitude: '23.683500',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LL302',
            Type: 'Normal',
            Latitude: '49.930361',
            Longitude: '23.776194',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '6',
            Name: 'CI13',
            Type: 'Normal',
            Latitude: '49.891894',
            Longitude: '23.834889',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'TEPN2C',
        Runways: '13',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'TEPNA',
            Type: 'Normal',
            Latitude: '49.504722',
            Longitude: '22.656111',
            Speed: '0',
            Altitude: '15000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'D244Y',
            Type: 'Normal',
            Latitude: '49.672025',
            Longitude: '23.351856',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'TEPN2D',
        Runways: '31',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'TEPNA',
            Type: 'Normal',
            Latitude: '49.504722',
            Longitude: '22.656111',
            Speed: '0',
            Altitude: '15000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'D244Y',
            Type: 'Normal',
            Latitude: '49.672025',
            Longitude: '23.351856',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
    ],
    Approach: [
      {
        Name: 'VDM13',
        App_Waypoint: [
          {
            ID: '1',
            Name: 'FD13',
            Type: 'Normal',
            Latitude: '49.871944',
            Longitude: '23.876111',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'MD13',
            Type: 'Normal',
            Latitude: '49.826564',
            Longitude: '23.933083',
            Speed: '0',
            Altitude: '1357',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'RW13',
            Type: 'Runway',
            Latitude: '49.817781',
            Longitude: '23.946283',
            Speed: '0',
            Altitude: '1132',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: '(2960)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '135',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
          },
        ],
        App_Transition: [
          {
            Name: 'LIV1',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'LIV',
                Type: 'Normal',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '0',
                Altitude: '2960',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'LIV',
                Type: 'DmeIntc',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '0',
                Altitude: '2630',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '276',
                Flytype: 'Fly-by',
                BankLimit: '25',
                DMEtoIntercept: '7.0',
                Sp_Turn: 'Auto',
              },
            ],
          },
          {
            Name: 'LIV2',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'LIV',
                Type: 'Normal',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '0',
                Altitude: '2960',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'LIV',
                Type: 'DmeIntc',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '0',
                Altitude: '2630',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '300',
                Flytype: 'Fly-by',
                BankLimit: '25',
                DMEtoIntercept: '6.0',
                Sp_Turn: 'Auto',
              },
            ],
          },
        ],
      },
      {
        Name: 'VDM31',
        App_Waypoint: [
          {
            ID: '1',
            Name: 'FD31',
            Type: 'Normal',
            Latitude: '49.733400',
            Longitude: '24.081214',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'MD31',
            Type: 'Normal',
            Latitude: '49.790475',
            Longitude: '23.986939',
            Speed: '0',
            Altitude: '1373',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'RW31',
            Type: 'Runway',
            Latitude: '49.799906',
            Longitude: '23.973181',
            Speed: '0',
            Altitude: '1127',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: '(2960)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '307',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
          },
        ],
        App_Transition: [
          {
            Name: 'LIV1',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'LIV',
                Type: 'Normal',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '0',
                Altitude: '0',
                AltitudeCons: '0',
                AltitudeRestriction: 'at',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'LIV',
                Type: 'DmeIntc',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '0',
                Altitude: '2960',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '156',
                Flytype: 'Fly-by',
                BankLimit: '25',
                DMEtoIntercept: '9.0',
                Sp_Turn: 'Auto',
              },
            ],
          },
          {
            Name: 'LIV2',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'LIV',
                Type: 'Normal',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '0',
                Altitude: '0',
                AltitudeCons: '0',
                AltitudeRestriction: 'at',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'LIV',
                Type: 'DmeIntc',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '0',
                Altitude: '2960',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '138',
                Flytype: 'Fly-by',
                BankLimit: '25',
                DMEtoIntercept: '8.0',
                Sp_Turn: 'Auto',
              },
            ],
          },
        ],
      },
      {
        Name: 'ILS13',
        App_Waypoint: [
          {
            ID: '1',
            Name: 'CI13',
            Type: 'Normal',
            Latitude: '49.891894',
            Longitude: '23.834889',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL301',
            Type: 'Normal',
            Latitude: '49.868056',
            Longitude: '23.870833',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'RW13',
            Type: 'Runway',
            Latitude: '49.817781',
            Longitude: '23.946283',
            Speed: '0',
            Altitude: '1132',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: '(2960)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '130',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
          },
        ],
        App_Transition: [
          {
            Name: 'LIV1',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'LIV',
                Type: 'Normal',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '230',
                Altitude: '2960',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'LIV',
                Type: 'DmeIntc',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '0',
                Altitude: '2630',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '284',
                Flytype: 'Fly-by',
                BankLimit: '25',
                DMEtoIntercept: '8.0',
                Sp_Turn: 'Auto',
              },
            ],
          },
          {
            Name: 'LIV2',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'LIV',
                Type: 'Normal',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '230',
                Altitude: '2960',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'LIV',
                Type: 'DmeIntc',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '0',
                Altitude: '2630',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '298',
                Flytype: 'Fly-by',
                BankLimit: '25',
                DMEtoIntercept: '8.0',
                Sp_Turn: 'Auto',
              },
            ],
          },
        ],
      },
      {
        Name: 'ILS31',
        App_Waypoint: [
          {
            ID: '1',
            Name: 'CI31',
            Type: 'Normal',
            Latitude: '49.707564',
            Longitude: '24.111789',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LL102',
            Type: 'Normal',
            Latitude: '49.731389',
            Longitude: '24.075833',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'RW31',
            Type: 'Runway',
            Latitude: '49.799906',
            Longitude: '23.973181',
            Speed: '0',
            Altitude: '1127',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: '(2960)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '310',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'LIV',
            Type: 'Normal',
            Latitude: '49.811944',
            Longitude: '23.951389',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
          },
        ],
        App_Transition: [
          {
            Name: 'LIV1',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'LIV',
                Type: 'Normal',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '0',
                Altitude: '2960',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'LIV',
                Type: 'DmeIntc',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '0',
                Altitude: '0',
                AltitudeCons: '0',
                AltitudeRestriction: 'at',
                Hdg_Crs: '0',
                Hdg_Crs_value: '152',
                Flytype: 'Fly-by',
                BankLimit: '25',
                DMEtoIntercept: '11.0',
                Sp_Turn: 'Auto',
              },
            ],
          },
          {
            Name: 'LIV2',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'LIV',
                Type: 'Normal',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '0',
                Altitude: '2960',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'LIV',
                Type: 'DmeIntc',
                Latitude: '49.811944',
                Longitude: '23.951389',
                Speed: '0',
                Altitude: '0',
                AltitudeCons: '0',
                AltitudeRestriction: 'at',
                Hdg_Crs: '0',
                Hdg_Crs_value: '139',
                Flytype: 'Fly-by',
                BankLimit: '25',
                DMEtoIntercept: '9.0',
                Sp_Turn: 'Auto',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    ICAOcode: 'UKLN',
    Runways: {
      33: {
        heading: 328,
        threshold: {
          latitude: 48.250617,
          longitude: 25.986811,
        },
        elevation: 827,
        approachArea: [
          {
            latitude: 48.250617,
            longitude: 25.986811,
          },
          geolib.computeDestinationPoint(
            {
              latitude: 48.250617,
              longitude: 25.986811,
            },
            18 * 1.852 * 1000,
            (328 + 180 + 5 + 6) % 360,
          ),
          geolib.computeDestinationPoint(
            {
              latitude: 49.799906,
              longitude: 23.973181,
            },
            18 * 1.852 * 1000,
            (328 + 180 - 5 + 6) % 360,
          ),
          {
            latitude: 48.250617,
            longitude: 25.986811,
          },
        ],
      },
      15: {
        heading: 148,
        threshold: {
          latitude: 48.268389,
          longitude: 25.973303,
        },
        elevation: 727,
        approachArea: [
          {
            latitude: 48.268389,
            longitude: 25.973303,
          },
          geolib.computeDestinationPoint(
            {
              latitude: 48.268389,
              longitude: 25.973303,
            },
            18 * 1.852 * 1000,
            (148 + 180 + 5 + 6) % 360,
          ),
          geolib.computeDestinationPoint(
            {
              latitude: 48.268389,
              longitude: 25.973303,
            },
            18 * 1.852 * 1000,
            (148 + 180 - 5 + 6) % 360,
          ),
          {
            latitude: 48.268389,
            longitude: 25.973303,
          },
        ],
      },
    },
    Sid: [
      {
        Name: 'BUKO1T',
        Runways: '15',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LN101',
            Type: 'Normal',
            Latitude: '48.188389',
            Longitude: '26.033889',
            Speed: '0',
            Altitude: '1640',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'BUKOV',
            Type: 'Normal',
            Latitude: '47.951667',
            Longitude: '25.958333',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'BUKO1V',
        Runways: '33',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LN301',
            Type: 'Normal',
            Latitude: '48.332556',
            Longitude: '25.924417',
            Speed: '0',
            Altitude: '1640',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LNN10',
            Type: 'Normal',
            Latitude: '48.371028',
            Longitude: '26.037750',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '2960',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LNS05',
            Type: 'Normal',
            Latitude: '48.147000',
            Longitude: '26.205778',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'BUKOV',
            Type: 'Normal',
            Latitude: '47.951667',
            Longitude: '25.958333',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'BUKO9A',
        Runways: '15',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '148',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
          },
          {
            ID: '3',
            Name: 'BUKOV',
            Type: 'Normal',
            Latitude: '47.951667',
            Longitude: '25.958333',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'BUKO9B',
        Runways: '33',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '328',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
          },
          {
            ID: '3',
            Name: 'BUKOV',
            Type: 'Normal',
            Latitude: '47.951667',
            Longitude: '25.958333',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'LOPN1T',
        Runways: '15',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LN101',
            Type: 'Normal',
            Latitude: '48.188389',
            Longitude: '26.033889',
            Speed: '0',
            Altitude: '1640',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LNS01',
            Type: 'Normal',
            Latitude: '48.226417',
            Longitude: '26.146472',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '2960',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LNE01',
            Type: 'Normal',
            Latitude: '48.526556',
            Longitude: '26.214500',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LOPNU',
            Type: 'Normal',
            Latitude: '49.074167',
            Longitude: '26.681389',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'LOPN1V',
        Runways: '33',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LN301',
            Type: 'Normal',
            Latitude: '48.332556',
            Longitude: '25.924417',
            Speed: '0',
            Altitude: '1640',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LNN10',
            Type: 'Normal',
            Latitude: '48.371028',
            Longitude: '26.037750',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '2960',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LOPNU',
            Type: 'Normal',
            Latitude: '49.074167',
            Longitude: '26.681389',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'LOPN6E',
        Runways: '15',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '148',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '49.074167',
            Longitude: '26.681389',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '353',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '204',
            Sp_Turn: 'Left',
          },
          {
            ID: '3',
            Name: 'LOPNU',
            Type: 'Normal',
            Latitude: '49.074167',
            Longitude: '26.681389',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'LOPN6F',
        Runways: '33',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '328',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '49.074167',
            Longitude: '26.681389',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '083',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '204',
            Sp_Turn: 'Right',
          },
          {
            ID: '3',
            Name: 'LOPNU',
            Type: 'Normal',
            Latitude: '49.074167',
            Longitude: '26.681389',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'POBE1T',
        Runways: '15',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LN101',
            Type: 'Normal',
            Latitude: '48.188389',
            Longitude: '26.033889',
            Speed: '0',
            Altitude: '1640',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LNS01',
            Type: 'Normal',
            Latitude: '48.226417',
            Longitude: '26.146472',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '2960',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LNN05',
            Type: 'Normal',
            Latitude: '48.446306',
            Longitude: '25.978083',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'POBED',
            Type: 'Normal',
            Latitude: '48.720556',
            Longitude: '25.457222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'POBE1V',
        Runways: '33',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LN301',
            Type: 'Normal',
            Latitude: '48.332556',
            Longitude: '25.924417',
            Speed: '0',
            Altitude: '1640',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'POBED',
            Type: 'Normal',
            Latitude: '48.720556',
            Longitude: '25.457222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'POBE4A',
        Runways: '15',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '148',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
          },
          {
            ID: '3',
            Name: 'POBED',
            Type: 'Normal',
            Latitude: '48.720556',
            Longitude: '25.457222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'POBE4B',
        Runways: '33',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '328',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'POBED',
            Type: 'Normal',
            Latitude: '48.720556',
            Longitude: '25.457222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'RIMO1T',
        Runways: '15',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LN101',
            Type: 'Normal',
            Latitude: '48.188389',
            Longitude: '26.033889',
            Speed: '0',
            Altitude: '1640',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LNS01',
            Type: 'Normal',
            Latitude: '48.226417',
            Longitude: '26.146472',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '2960',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LNN05',
            Type: 'Normal',
            Latitude: '48.446306',
            Longitude: '25.978083',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: 'LNN01',
            Type: 'Normal',
            Latitude: '48.583528',
            Longitude: '25.978972',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'RIMOS',
            Type: 'Normal',
            Latitude: '48.758333',
            Longitude: '25.980000',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'RIMO1V',
        Runways: '33',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LN301',
            Type: 'Normal',
            Latitude: '48.332556',
            Longitude: '25.924417',
            Speed: '0',
            Altitude: '1640',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LNN10',
            Type: 'Normal',
            Latitude: '48.371028',
            Longitude: '26.037750',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '2960',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'RIMOS',
            Type: 'Normal',
            Latitude: '48.758333',
            Longitude: '25.980000',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'RIMO9A',
        Runways: '15',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '148',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
          },
          {
            ID: '3',
            Name: 'RIMOS',
            Type: 'Normal',
            Latitude: '48.758333',
            Longitude: '25.980000',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'RIMO9B',
        Runways: '33',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '328',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '48.758333',
            Longitude: '25.980000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '024',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '174',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'RIMOS',
            Type: 'Normal',
            Latitude: '48.758333',
            Longitude: '25.980000',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'TUMB1T',
        Runways: '15',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LN101',
            Type: 'Normal',
            Latitude: '48.188389',
            Longitude: '26.033889',
            Speed: '0',
            Altitude: '1640',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LNS01',
            Type: 'Normal',
            Latitude: '48.226417',
            Longitude: '26.146472',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '2960',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'TUMBI',
            Type: 'Normal',
            Latitude: '48.502778',
            Longitude: '26.602222',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'TUMB1V',
        Runways: '33',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: 'LN301',
            Type: 'Normal',
            Latitude: '48.332556',
            Longitude: '25.924417',
            Speed: '0',
            Altitude: '1640',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LNN10',
            Type: 'Normal',
            Latitude: '48.371028',
            Longitude: '26.037750',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '2960',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'TUMBI',
            Type: 'Normal',
            Latitude: '48.502778',
            Longitude: '26.602222',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'TUMB6A',
        Runways: '15',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '148',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '48.502778',
            Longitude: '26.602222',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '353',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '232',
            Sp_Turn: 'Left',
          },
          {
            ID: '3',
            Name: 'TUMBI',
            Type: 'Normal',
            Latitude: '48.502778',
            Longitude: '26.602222',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'TUMB6B',
        Runways: '33',
        Sid_Waypoint: [
          {
            ID: '1',
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '328',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '48.502778',
            Longitude: '26.602222',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '142',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '232',
            Sp_Turn: 'Right',
          },
          {
            ID: '3',
            Name: 'TUMBI',
            Type: 'Normal',
            Latitude: '48.502778',
            Longitude: '26.602222',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
    ],
    Star: [
      {
        Name: 'BUKO2J',
        Runways: '15',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'BUKOV',
            Type: 'Normal',
            Latitude: '47.951667',
            Longitude: '25.958333',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LNS01',
            Type: 'Normal',
            Latitude: '48.226417',
            Longitude: '26.146472',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LNN05',
            Type: 'Normal',
            Latitude: '48.446306',
            Longitude: '25.978083',
            Speed: '220',
            Altitude: '6070',
            AltitudeCons: '3940',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'BUKO2U',
        Runways: '33',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'BUKOV',
            Type: 'Normal',
            Latitude: '47.951667',
            Longitude: '25.958333',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LN405',
            Type: 'Normal',
            Latitude: '48.109306',
            Longitude: '26.094111',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'BUKO9C',
        Runways: '15',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'BUKOV',
            Type: 'Normal',
            Latitude: '47.951667',
            Longitude: '25.958333',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'BUKO9D',
        Runways: '33',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'BUKOV',
            Type: 'Normal',
            Latitude: '47.951667',
            Longitude: '25.958333',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'LOPN2J',
        Runways: '15',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'LOPNU',
            Type: 'Normal',
            Latitude: '49.074167',
            Longitude: '26.681389',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LNE01',
            Type: 'Normal',
            Latitude: '48.526556',
            Longitude: '26.214500',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LNN05',
            Type: 'Normal',
            Latitude: '48.446306',
            Longitude: '25.978083',
            Speed: '220',
            Altitude: '6070',
            AltitudeCons: '3940',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'LOPN2U',
        Runways: '33',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'LOPNU',
            Type: 'Normal',
            Latitude: '49.074167',
            Longitude: '26.681389',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LNN10',
            Type: 'Normal',
            Latitude: '48.371028',
            Longitude: '26.037750',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LNS05',
            Type: 'Normal',
            Latitude: '48.147000',
            Longitude: '26.205778',
            Speed: '220',
            Altitude: '6070',
            AltitudeCons: '3940',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'LOPN6G',
        Runways: '15',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'LOPNU',
            Type: 'Normal',
            Latitude: '49.074167',
            Longitude: '26.681389',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'LOPN6H',
        Runways: '33',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'LOPNU',
            Type: 'Normal',
            Latitude: '49.074167',
            Longitude: '26.681389',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'POBE2J',
        Runways: '15',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'POBED',
            Type: 'Normal',
            Latitude: '48.720556',
            Longitude: '25.457222',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LN215',
            Type: 'Normal',
            Latitude: '48.467000',
            Longitude: '25.672222',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LN210',
            Type: 'Normal',
            Latitude: '48.370333',
            Longitude: '25.754000',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'POBE2U',
        Runways: '33',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'POBED',
            Type: 'Normal',
            Latitude: '48.720556',
            Longitude: '25.457222',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LNN10',
            Type: 'Normal',
            Latitude: '48.371028',
            Longitude: '26.037750',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LNS05',
            Type: 'Normal',
            Latitude: '48.147000',
            Longitude: '26.205778',
            Speed: '220',
            Altitude: '6070',
            AltitudeCons: '3940',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'POBE4C',
        Runways: '15',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'POBED',
            Type: 'Normal',
            Latitude: '48.720556',
            Longitude: '25.457222',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'POBE4D',
        Runways: '33',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'POBED',
            Type: 'Normal',
            Latitude: '48.720556',
            Longitude: '25.457222',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'RIMO2J',
        Runways: '15',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'RIMOS',
            Type: 'Normal',
            Latitude: '48.758333',
            Longitude: '25.980000',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LNN01',
            Type: 'Normal',
            Latitude: '48.583528',
            Longitude: '25.978972',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '6070',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LNN05',
            Type: 'Normal',
            Latitude: '48.446306',
            Longitude: '25.978083',
            Speed: '220',
            Altitude: '6070',
            AltitudeCons: '3940',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'RIMO2U',
        Runways: '33',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'RIMOS',
            Type: 'Normal',
            Latitude: '48.758333',
            Longitude: '25.980000',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LNN10',
            Type: 'Normal',
            Latitude: '48.371028',
            Longitude: '26.037750',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LNS05',
            Type: 'Normal',
            Latitude: '48.147000',
            Longitude: '26.205778',
            Speed: '220',
            Altitude: '6070',
            AltitudeCons: '3940',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'RIMO9C',
        Runways: '15',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'RIMOS',
            Type: 'Normal',
            Latitude: '48.758333',
            Longitude: '25.980000',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'RIMO9D',
        Runways: '33',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'RIMOS',
            Type: 'Normal',
            Latitude: '48.758333',
            Longitude: '25.980000',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'TUMB2J',
        Runways: '15',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'TUMBI',
            Type: 'Normal',
            Latitude: '48.502778',
            Longitude: '26.602222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LNE01',
            Type: 'Normal',
            Latitude: '48.526556',
            Longitude: '26.214500',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LNN05',
            Type: 'Normal',
            Latitude: '48.446306',
            Longitude: '25.978083',
            Speed: '220',
            Altitude: '6070',
            AltitudeCons: '3940',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'TUMB2U',
        Runways: '33',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'TUMBI',
            Type: 'Normal',
            Latitude: '48.502778',
            Longitude: '26.602222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'LN410',
            Type: 'Normal',
            Latitude: '48.314417',
            Longitude: '26.392083',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'LNS05',
            Type: 'Normal',
            Latitude: '48.147000',
            Longitude: '26.205778',
            Speed: '220',
            Altitude: '6070',
            AltitudeCons: '3940',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'TUMB6C',
        Runways: '15',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'TUMBI',
            Type: 'Normal',
            Latitude: '48.502778',
            Longitude: '26.602222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
      {
        Name: 'TUMB6D',
        Runways: '33',
        Star_Waypoint: [
          {
            ID: '1',
            Name: 'TUMBI',
            Type: 'Normal',
            Latitude: '48.502778',
            Longitude: '26.602222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
        ],
      },
    ],
    Approach: [
      {
        Name: 'ILS15',
        App_Waypoint: [
          {
            ID: '1',
            Name: 'CI15',
            Type: 'Normal',
            Latitude: '48.365569',
            Longitude: '25.899328',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'FF15',
            Type: 'Normal',
            Latitude: '48.336056',
            Longitude: '25.921139',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'RW15',
            Type: 'Runway',
            Latitude: '48.268389',
            Longitude: '25.973303',
            Speed: '0',
            Altitude: '879',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: '(1970)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1970',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '148',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
          },
        ],
        App_Transition: [
          {
            Name: 'CR1',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'CR',
                Type: 'Normal',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '3940',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'CR',
                Type: 'PBD',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '2790',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '357',
                Flytype: 'Fly-over',
                BankLimit: '25',
                DMEtoIntercept: '007',
                Sp_Turn: 'Auto',
              },
            ],
          },
          {
            Name: 'CR2',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'CR',
                Type: 'Normal',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '3940',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'CR',
                Type: 'PBD',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '2630',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '340',
                Flytype: 'Fly-over',
                BankLimit: '25',
                DMEtoIntercept: '007',
                Sp_Turn: 'Auto',
              },
            ],
          },
          {
            Name: 'LN210',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'LN210',
                Type: 'Normal',
                Latitude: '48.370333',
                Longitude: '25.754000',
                Speed: '220',
                Altitude: '3940',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'LN205',
                Type: 'Normal',
                Latitude: '48.408278',
                Longitude: '25.866056',
                Speed: '0',
                Altitude: '2960',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
            ],
          },
          {
            Name: 'LNN05',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'LNN05',
                Type: 'Normal',
                Latitude: '48.446306',
                Longitude: '25.978083',
                Speed: '220',
                Altitude: '6070',
                AltitudeCons: '3940',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'LN205',
                Type: 'Normal',
                Latitude: '48.408278',
                Longitude: '25.866056',
                Speed: '0',
                Altitude: '2960',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
            ],
          },
        ],
      },
      {
        Name: 'ILS33',
        App_Waypoint: [
          {
            ID: '1',
            Name: 'CI33',
            Type: 'Normal',
            Latitude: '48.154803',
            Longitude: '26.059817',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'FF33',
            Type: 'Normal',
            Latitude: '48.184028',
            Longitude: '26.037667',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'RW33',
            Type: 'Runway',
            Latitude: '48.250617',
            Longitude: '25.986811',
            Speed: '0',
            Altitude: '881',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '328',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
          },
        ],
        App_Transition: [
          {
            Name: 'CR1',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'CR',
                Type: 'Normal',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '3940',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'CR',
                Type: 'PBD',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '2790',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '110',
                Flytype: 'Fly-over',
                BankLimit: '25',
                DMEtoIntercept: '005',
                Sp_Turn: 'Auto',
              },
            ],
          },
          {
            Name: 'CR2',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'CR',
                Type: 'Normal',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '3940',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'CR',
                Type: 'PBD',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '2630',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '134',
                Flytype: 'Fly-over',
                BankLimit: '25',
                DMEtoIntercept: '006',
                Sp_Turn: 'Auto',
              },
            ],
          },
          {
            Name: 'LN405',
            AppTr_Waypoint: {
              ID: '1',
              Name: 'LN405',
              Type: 'Normal',
              Latitude: '48.109306',
              Longitude: '26.094111',
              Speed: '220',
              Altitude: '2960',
              AltitudeCons: '0',
              AltitudeRestriction: 'above',
              Flytype: 'Fly-by',
              BankLimit: '25',
              Sp_Turn: 'Auto',
            },
          },
          {
            Name: 'LNS05',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'LNS05',
                Type: 'Normal',
                Latitude: '48.147000',
                Longitude: '26.205778',
                Speed: '220',
                Altitude: '6070',
                AltitudeCons: '3940',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'LN405',
                Type: 'Normal',
                Latitude: '48.109306',
                Longitude: '26.094111',
                Speed: '0',
                Altitude: '2960',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
            ],
          },
        ],
      },
      {
        Name: 'NDB15',
        App_Waypoint: [
          {
            ID: '1',
            Name: 'FF15',
            Type: 'Normal',
            Latitude: '48.336056',
            Longitude: '25.921139',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'RW15',
            Type: 'Runway',
            Latitude: '48.268389',
            Longitude: '25.973303',
            Speed: '0',
            Altitude: '879',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: '(1970)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1970',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '148',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
          },
        ],
        App_Transition: [
          {
            Name: 'CR1',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'CR',
                Type: 'Normal',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '3940',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'CR',
                Type: 'PBD',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '2790',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '357',
                Flytype: 'Fly-over',
                BankLimit: '25',
                DMEtoIntercept: '007',
                Sp_Turn: 'Auto',
              },
            ],
          },
          {
            Name: 'CR2',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'CR',
                Type: 'Normal',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '3940',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'CR',
                Type: 'PBD',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '2630',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '340',
                Flytype: 'Fly-over',
                BankLimit: '25',
                DMEtoIntercept: '007',
                Sp_Turn: 'Auto',
              },
            ],
          },
        ],
      },
      {
        Name: 'NDB33',
        App_Waypoint: [
          {
            ID: '1',
            Name: 'FF33',
            Type: 'Normal',
            Latitude: '48.184028',
            Longitude: '26.037667',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '2',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '1082',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '3',
            Name: 'RW33',
            Type: 'Runway',
            Latitude: '48.250617',
            Longitude: '25.986811',
            Speed: '0',
            Altitude: '881',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '4',
            Name: '(3120)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '3120',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '328',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
          },
          {
            ID: '5',
            Name: 'CR',
            Type: 'Normal',
            Latitude: '48.241167',
            Longitude: '25.993778',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
          },
        ],
        App_Transition: [
          {
            Name: 'CR1',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'CR',
                Type: 'Normal',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '3940',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'CR',
                Type: 'PBD',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '2790',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '110',
                Flytype: 'Fly-over',
                BankLimit: '25',
                DMEtoIntercept: '005',
                Sp_Turn: 'Auto',
              },
            ],
          },
          {
            Name: 'CR2',
            AppTr_Waypoint: [
              {
                ID: '1',
                Name: 'CR',
                Type: 'Normal',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '3940',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
              },
              {
                ID: '2',
                Name: 'CR',
                Type: 'PBD',
                Latitude: '48.241167',
                Longitude: '25.993778',
                Speed: '0',
                Altitude: '2630',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '134',
                Flytype: 'Fly-over',
                BankLimit: '25',
                DMEtoIntercept: '006',
                Sp_Turn: 'Auto',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    ICAOcode: 'UKBB',
    Runways: {
      '18L': {
        heading: 180,
        threshold: {
          latitude: 50.360539,
          longitude: 30.906472,
        },
        elevation: 478,
        approachArea: [
          {
            latitude: 50.360539,
            longitude: 30.906472,
          },
          geolib.computeDestinationPoint(
            {
              latitude: 50.360539,
              longitude: 30.906472,
            },
            18 * 1.852 * 1000,
            (180 + 180 + 5 + 6) % 360,
          ),
          geolib.computeDestinationPoint(
            {
              latitude: 50.360539,
              longitude: 30.906472,
            },
            18 * 1.852 * 1000,
            (180 + 180 - 5 + 6) % 360,
          ),
          {
            latitude: 50.360539,
            longitude: 30.906472,
          },
        ],
      },
      '18R': {
        heading: 180,
        threshold: {
          latitude: 50.352133,
          longitude: 30.881592,
        },
        elevation: 478,
        approachArea: [
          {
            latitude: 50.352133,
            longitude: 30.881592,
          },
          geolib.computeDestinationPoint(
            {
              latitude: 50.352133,
              longitude: 30.881592,
            },
            18 * 1.852 * 1000,
            (180 + 180 + 5 + 6) % 360,
          ),
          geolib.computeDestinationPoint(
            {
              latitude: 50.352133,
              longitude: 30.881592,
            },
            18 * 1.852 * 1000,
            (180 + 180 - 5 + 6) % 360,
          ),
          {
            latitude: 50.352133,
            longitude: 30.881592,
          },
        ],
      },
      '36L': {
        heading: 360,
        threshold: {
          latitude: 50.320706,
          longitude: 30.879317,
        },
        elevation: 481,
        approachArea: [
          {
            latitude: 50.320706,
            longitude: 30.879317,
          },
          geolib.computeDestinationPoint(
            {
              latitude: 50.320706,
              longitude: 30.879317,
            },
            18 * 1.852 * 1000,
            (360 + 180 + 5 + 6) % 360,
          ),
          geolib.computeDestinationPoint(
            {
              latitude: 50.320706,
              longitude: 30.879317,
            },
            18 * 1.852 * 1000,
            (360 + 180 - 5 + 6) % 360,
          ),
          {
            latitude: 50.320706,
            longitude: 30.879317,
          },
        ],
      },
      '36R': {
        heading: 360,
        threshold: {
          latitude: 50.324619,
          longitude: 30.903833,
        },
        elevation: 479,
        approachArea: [
          {
            latitude: 50.324619,
            longitude: 30.903833,
          },
          geolib.computeDestinationPoint(
            {
              latitude: 50.324619,
              longitude: 30.903833,
            },
            18 * 1.852 * 1000,
            (360 + 180 + 5 + 6) % 360,
          ),
          geolib.computeDestinationPoint(
            {
              latitude: 50.324619,
              longitude: 30.903833,
            },
            18 * 1.852 * 1000,
            (360 + 180 - 5 + 6) % 360,
          ),
          {
            latitude: 50.324619,
            longitude: 30.903833,
          },
        ],
      },
    },
    Sid: [
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BASOR',
            Type: 'Normal',
            Latitude: '50.051944',
            Longitude: '30.833333',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
        ],
        Name: 'BASO1A',
        Runways: '18L',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BASOR',
            Type: 'Normal',
            Latitude: '50.051944',
            Longitude: '30.833333',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
        ],
        Name: 'BASO1B',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '013',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '2',
          },
          {
            Name: 'BASOR',
            Type: 'Normal',
            Latitude: '50.051944',
            Longitude: '30.833333',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'BASO1D',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB710',
            Type: 'Normal',
            Latitude: '50.501389',
            Longitude: '30.916667',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBN03',
            Type: 'Normal',
            Latitude: '50.551389',
            Longitude: '31.023611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBN04',
            Type: 'Normal',
            Latitude: '50.484444',
            Longitude: '31.101111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BASOR',
            Type: 'Normal',
            Latitude: '50.051944',
            Longitude: '30.833333',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
        ],
        Name: 'BASO1R',
        Runways: '36R',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB510',
            Type: 'Normal',
            Latitude: '50.495000',
            Longitude: '30.891944',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBN03',
            Type: 'Normal',
            Latitude: '50.551389',
            Longitude: '31.023611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBN04',
            Type: 'Normal',
            Latitude: '50.484444',
            Longitude: '31.101111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BASOR',
            Type: 'Normal',
            Latitude: '50.051944',
            Longitude: '30.833333',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
        ],
        Name: 'BASO1S',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(990)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '990',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '211',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB325',
            Type: 'Normal',
            Latitude: '50.188611',
            Longitude: '30.765556',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BASOR',
            Type: 'Normal',
            Latitude: '50.051944',
            Longitude: '30.833333',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'BASO1Y',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB110',
            Type: 'Normal',
            Latitude: '50.186389',
            Longitude: '30.893611',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BASOR',
            Type: 'Normal',
            Latitude: '50.051944',
            Longitude: '30.833333',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'BASO1Z',
        Runways: '18L',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '2',
          },
        ],
        Name: 'BO2A',
        Runways: '18L',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '2',
          },
        ],
        Name: 'BO2B',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '013',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
        ],
        Name: 'BO2D',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB710',
            Type: 'Normal',
            Latitude: '50.501389',
            Longitude: '30.916667',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'BO3R',
        Runways: '36R',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB510',
            Type: 'Normal',
            Latitude: '50.495000',
            Longitude: '30.891944',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'BO3S',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB315',
            Type: 'Normal',
            Latitude: '50.179722',
            Longitude: '30.869167',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE01',
            Type: 'Normal',
            Latitude: '50.144167',
            Longitude: '31.056111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE05',
            Type: 'Normal',
            Latitude: '50.205833',
            Longitude: '31.095000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'BO3Y',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB110',
            Type: 'Normal',
            Latitude: '50.186389',
            Longitude: '30.893611',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE01',
            Type: 'Normal',
            Latitude: '50.144167',
            Longitude: '31.056111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE05',
            Type: 'Normal',
            Latitude: '50.205833',
            Longitude: '31.095000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'BO3Z',
        Runways: '18L',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '031',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '259',
            Sp_Turn: 'Left',
            ID: '2',
          },
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'GOTA2A',
        Runways: '18L',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '028',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '259',
            Sp_Turn: 'Left',
            ID: '2',
          },
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'GOTA2B',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '013',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '081',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '296',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'GOTA2D',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB710',
            Type: 'Normal',
            Latitude: '50.501389',
            Longitude: '30.916667',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBN03',
            Type: 'Normal',
            Latitude: '50.551389',
            Longitude: '31.023611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE04',
            Type: 'Normal',
            Latitude: '50.531389',
            Longitude: '31.307778',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'GOTA2R',
        Runways: '36R',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB510',
            Type: 'Normal',
            Latitude: '50.495000',
            Longitude: '30.891944',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBN03',
            Type: 'Normal',
            Latitude: '50.551389',
            Longitude: '31.023611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE04',
            Type: 'Normal',
            Latitude: '50.531389',
            Longitude: '31.307778',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'GOTA2S',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB315',
            Type: 'Normal',
            Latitude: '50.179722',
            Longitude: '30.869167',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE01',
            Type: 'Normal',
            Latitude: '50.144167',
            Longitude: '31.056111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE02',
            Type: 'Normal',
            Latitude: '50.191111',
            Longitude: '31.377500',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'GOTA2Y',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB110',
            Type: 'Normal',
            Latitude: '50.186389',
            Longitude: '30.893611',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE01',
            Type: 'Normal',
            Latitude: '50.144167',
            Longitude: '31.056111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE02',
            Type: 'Normal',
            Latitude: '50.191111',
            Longitude: '31.377500',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'GOTA2Z',
        Runways: '18L',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BI',
            Type: 'Normal',
            Latitude: '50.397444',
            Longitude: '30.909111',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '2',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '275',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '020',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KAFEL',
            Type: 'Normal',
            Latitude: '50.606944',
            Longitude: '29.280556',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'KAFE1A',
        Runways: '18L',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KB',
            Type: 'Normal',
            Latitude: '50.386389',
            Longitude: '30.884083',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '2',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '276',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '020',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KAFEL',
            Type: 'Normal',
            Latitude: '50.606944',
            Longitude: '29.280556',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'KAFE1B',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '010',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.415811',
            Longitude: '30.312561',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '296',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '057',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'SL32A',
            Type: 'Normal',
            Latitude: '50.415811',
            Longitude: '30.312561',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KAFEL',
            Type: 'Normal',
            Latitude: '50.606944',
            Longitude: '29.280556',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'KAFE1D',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB710',
            Type: 'Normal',
            Latitude: '50.501389',
            Longitude: '30.916667',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBW02',
            Type: 'Normal',
            Latitude: '50.532778',
            Longitude: '30.662778',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBW01',
            Type: 'Normal',
            Latitude: '50.404722',
            Longitude: '30.288333',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'KAFEL',
            Type: 'Normal',
            Latitude: '50.606944',
            Longitude: '29.280556',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'KAFE1R',
        Runways: '36R',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(990)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '990',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '321',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB515',
            Type: 'Normal',
            Latitude: '50.501389',
            Longitude: '30.775556',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBW02',
            Type: 'Normal',
            Latitude: '50.532778',
            Longitude: '30.662778',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BBW01',
            Type: 'Normal',
            Latitude: '50.404722',
            Longitude: '30.288333',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'KAFEL',
            Type: 'Normal',
            Latitude: '50.606944',
            Longitude: '29.280556',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
        ],
        Name: 'KAFE1S',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB315',
            Type: 'Normal',
            Latitude: '50.179722',
            Longitude: '30.869167',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE01',
            Type: 'Normal',
            Latitude: '50.144167',
            Longitude: '31.056111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE05',
            Type: 'Normal',
            Latitude: '50.205833',
            Longitude: '31.095000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BBN02',
            Type: 'Normal',
            Latitude: '50.397500',
            Longitude: '30.909167',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BBW01',
            Type: 'Normal',
            Latitude: '50.404722',
            Longitude: '30.288333',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'KAFEL',
            Type: 'Normal',
            Latitude: '50.606944',
            Longitude: '29.280556',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
        ],
        Name: 'KAFE1Y',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB110',
            Type: 'Normal',
            Latitude: '50.186389',
            Longitude: '30.893611',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE01',
            Type: 'Normal',
            Latitude: '50.144167',
            Longitude: '31.056111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE05',
            Type: 'Normal',
            Latitude: '50.205833',
            Longitude: '31.095000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BBN02',
            Type: 'Normal',
            Latitude: '50.397500',
            Longitude: '30.909167',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BBW01',
            Type: 'Normal',
            Latitude: '50.404722',
            Longitude: '30.288333',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'KAFEL',
            Type: 'Normal',
            Latitude: '50.606944',
            Longitude: '29.280556',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
        ],
        Name: 'KAFE1Z',
        Runways: '18L',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '340',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '224',
            Sp_Turn: 'Left',
            ID: '2',
          },
          {
            Name: 'KUROS',
            Type: 'Normal',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'KURO2A',
        Runways: '18L',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '342',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '224',
            Sp_Turn: 'Left',
            ID: '2',
          },
          {
            Name: 'KUROS',
            Type: 'Normal',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'KURO2B',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '013',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KUROS',
            Type: 'Normal',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
        ],
        Name: 'KURO2D',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB710',
            Type: 'Normal',
            Latitude: '50.501389',
            Longitude: '30.916667',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBN03',
            Type: 'Normal',
            Latitude: '50.551389',
            Longitude: '31.023611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE04',
            Type: 'Normal',
            Latitude: '50.531389',
            Longitude: '31.307778',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'KUROS',
            Type: 'Normal',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'KURO3R',
        Runways: '36R',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB510',
            Type: 'Normal',
            Latitude: '50.495000',
            Longitude: '30.891944',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBN03',
            Type: 'Normal',
            Latitude: '50.551389',
            Longitude: '31.023611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE04',
            Type: 'Normal',
            Latitude: '50.531389',
            Longitude: '31.307778',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'KUROS',
            Type: 'Normal',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'KURO3S',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB315',
            Type: 'Normal',
            Latitude: '50.179722',
            Longitude: '30.869167',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE01',
            Type: 'Normal',
            Latitude: '50.144167',
            Longitude: '31.056111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE03',
            Type: 'Normal',
            Latitude: '50.388056',
            Longitude: '31.353611',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'KUROS',
            Type: 'Normal',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'KURO3Y',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB110',
            Type: 'Normal',
            Latitude: '50.186389',
            Longitude: '30.893611',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE01',
            Type: 'Normal',
            Latitude: '50.144167',
            Longitude: '31.056111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE03',
            Type: 'Normal',
            Latitude: '50.388056',
            Longitude: '31.353611',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'KUROS',
            Type: 'Normal',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'KURO3Z',
        Runways: '18L',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BI',
            Type: 'Normal',
            Latitude: '50.397444',
            Longitude: '30.909111',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '2',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '275',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '020',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'SLV2A',
        Runways: '18L',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KB',
            Type: 'Normal',
            Latitude: '50.386389',
            Longitude: '30.884083',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '2',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '276',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '020',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'SLV2B',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '010',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.415811',
            Longitude: '30.312561',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '296',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '057',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'SL32A',
            Type: 'Normal',
            Latitude: '50.415811',
            Longitude: '30.312561',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'SLV2D',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB710',
            Type: 'Normal',
            Latitude: '50.501389',
            Longitude: '30.916667',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBW02',
            Type: 'Normal',
            Latitude: '50.532778',
            Longitude: '30.662778',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBW01',
            Type: 'Normal',
            Latitude: '50.404722',
            Longitude: '30.288333',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'SLV2R',
        Runways: '36R',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(990)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '990',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '321',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB515',
            Type: 'Normal',
            Latitude: '50.501389',
            Longitude: '30.775556',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBW02',
            Type: 'Normal',
            Latitude: '50.532778',
            Longitude: '30.662778',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BBW01',
            Type: 'Normal',
            Latitude: '50.404722',
            Longitude: '30.288333',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
        ],
        Name: 'SLV2S',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB315',
            Type: 'Normal',
            Latitude: '50.179722',
            Longitude: '30.869167',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE01',
            Type: 'Normal',
            Latitude: '50.144167',
            Longitude: '31.056111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE05',
            Type: 'Normal',
            Latitude: '50.205833',
            Longitude: '31.095000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BBN02',
            Type: 'Normal',
            Latitude: '50.397500',
            Longitude: '30.909167',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BBW01',
            Type: 'Normal',
            Latitude: '50.404722',
            Longitude: '30.288333',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
        ],
        Name: 'SLV2Y',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB110',
            Type: 'Normal',
            Latitude: '50.186389',
            Longitude: '30.893611',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE01',
            Type: 'Normal',
            Latitude: '50.144167',
            Longitude: '31.056111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE05',
            Type: 'Normal',
            Latitude: '50.205833',
            Longitude: '31.095000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BBN02',
            Type: 'Normal',
            Latitude: '50.397500',
            Longitude: '30.909167',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BBW01',
            Type: 'Normal',
            Latitude: '50.404722',
            Longitude: '30.288333',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
        ],
        Name: 'SLV2Z',
        Runways: '18L',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '2',
          },
          {
            Name: 'TUVOG',
            Type: 'Normal',
            Latitude: '50.946389',
            Longitude: '30.977778',
            Speed: '0',
            Altitude: '13000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'TUVO2A',
        Runways: '18L',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '2',
          },
          {
            Name: 'TUVOG',
            Type: 'Normal',
            Latitude: '50.946389',
            Longitude: '30.977778',
            Speed: '0',
            Altitude: '13000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'TUVO2B',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '013',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'TUVOG',
            Type: 'Normal',
            Latitude: '50.946389',
            Longitude: '30.977778',
            Speed: '0',
            Altitude: '13000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'TUVO2D',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB710',
            Type: 'Normal',
            Latitude: '50.501389',
            Longitude: '30.916667',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'TUVOG',
            Type: 'Normal',
            Latitude: '50.946389',
            Longitude: '30.977778',
            Speed: '0',
            Altitude: '13000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'TUVO2R',
        Runways: '36R',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB510',
            Type: 'Normal',
            Latitude: '50.495000',
            Longitude: '30.891944',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'TUVOG',
            Type: 'Normal',
            Latitude: '50.946389',
            Longitude: '30.977778',
            Speed: '0',
            Altitude: '13000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'TUVO2S',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB315',
            Type: 'Normal',
            Latitude: '50.179722',
            Longitude: '30.869167',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE01',
            Type: 'Normal',
            Latitude: '50.144167',
            Longitude: '31.056111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE05',
            Type: 'Normal',
            Latitude: '50.205833',
            Longitude: '31.095000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'TUVOG',
            Type: 'Normal',
            Latitude: '50.946389',
            Longitude: '30.977778',
            Speed: '0',
            Altitude: '13000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'TUVO2Y',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB110',
            Type: 'Normal',
            Latitude: '50.186389',
            Longitude: '30.893611',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE01',
            Type: 'Normal',
            Latitude: '50.144167',
            Longitude: '31.056111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE05',
            Type: 'Normal',
            Latitude: '50.205833',
            Longitude: '31.095000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'TUVOG',
            Type: 'Normal',
            Latitude: '50.946389',
            Longitude: '30.977778',
            Speed: '0',
            Altitude: '13000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'TUVO2Z',
        Runways: '18L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB710',
            Type: 'Normal',
            Latitude: '50.501389',
            Longitude: '30.916667',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBN03',
            Type: 'Normal',
            Latitude: '50.551389',
            Longitude: '31.023611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBN04',
            Type: 'Normal',
            Latitude: '50.484444',
            Longitude: '31.101111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'VESEL',
            Type: 'Normal',
            Latitude: '49.650000',
            Longitude: '29.695278',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
        ],
        Name: 'VESE2R',
        Runways: '36R',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '006',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '49.650000',
            Longitude: '29.695278',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '276',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '044',
            Sp_Turn: 'Right',
            ID: '2',
          },
          {
            Name: 'VESEL',
            Type: 'Normal',
            Latitude: '49.650000',
            Longitude: '29.695278',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'VESE3A',
        Runways: '18L',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '007',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '49.650000',
            Longitude: '29.695278',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '276',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '044',
            Sp_Turn: 'Right',
            ID: '2',
          },
          {
            Name: 'VESEL',
            Type: 'Normal',
            Latitude: '49.650000',
            Longitude: '29.695278',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'VESE3B',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '013',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '2',
          },
          {
            Name: 'VESEL',
            Type: 'Normal',
            Latitude: '49.650000',
            Longitude: '29.695278',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'VESE3D',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB510',
            Type: 'Normal',
            Latitude: '50.495000',
            Longitude: '30.891944',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBN03',
            Type: 'Normal',
            Latitude: '50.551389',
            Longitude: '31.023611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBN04',
            Type: 'Normal',
            Latitude: '50.484444',
            Longitude: '31.101111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'VESEL',
            Type: 'Normal',
            Latitude: '49.650000',
            Longitude: '29.695278',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
        ],
        Name: 'VESE3S',
        Runways: '36L',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(990)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '990',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '211',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB325',
            Type: 'Normal',
            Latitude: '50.188611',
            Longitude: '30.765556',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'VESEL',
            Type: 'Normal',
            Latitude: '49.650000',
            Longitude: '29.695278',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'VESE3Y',
        Runways: '18R',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB110',
            Type: 'Normal',
            Latitude: '50.186389',
            Longitude: '30.893611',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'VESEL',
            Type: 'Normal',
            Latitude: '49.650000',
            Longitude: '29.695278',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'VESE3Z',
        Runways: '18L',
      },
    ],
    Star: [
      {
        Star_Waypoint: [
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'D086R',
            Type: 'Normal',
            Latitude: '50.611983',
            Longitude: '31.362386',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D003U',
            Type: 'Normal',
            Latitude: '50.625136',
            Longitude: '30.995069',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'CY1E',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'D086R',
            Type: 'Normal',
            Latitude: '50.611983',
            Longitude: '31.362386',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D086C',
            Type: 'Normal',
            Latitude: '50.625978',
            Longitude: '30.970356',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'CY1J',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBE03',
            Type: 'Normal',
            Latitude: '50.388056',
            Longitude: '31.353611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB454',
            Type: 'Normal',
            Latitude: '50.478611',
            Longitude: '31.035278',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB452',
            Type: 'Normal',
            Latitude: '50.545000',
            Longitude: '31.040278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB450',
            Type: 'Normal',
            Latitude: '50.611389',
            Longitude: '31.045278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB448',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '31.050278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB446',
            Type: 'Normal',
            Latitude: '50.744167',
            Longitude: '31.055278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB444',
            Type: 'Normal',
            Latitude: '50.810556',
            Longitude: '31.060278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
        ],
        Name: 'CY1T',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBE03',
            Type: 'Normal',
            Latitude: '50.388056',
            Longitude: '31.353611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB454',
            Type: 'Normal',
            Latitude: '50.478611',
            Longitude: '31.035278',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB452',
            Type: 'Normal',
            Latitude: '50.545000',
            Longitude: '31.040278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB450',
            Type: 'Normal',
            Latitude: '50.611389',
            Longitude: '31.045278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB448',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '31.050278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB446',
            Type: 'Normal',
            Latitude: '50.744167',
            Longitude: '31.055278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB444',
            Type: 'Normal',
            Latitude: '50.810556',
            Longitude: '31.060278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
        ],
        Name: 'CY1U',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB656',
            Type: 'Normal',
            Latitude: '50.201667',
            Longitude: '31.138611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB654',
            Type: 'Normal',
            Latitude: '50.191944',
            Longitude: '31.011944',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB652',
            Type: 'Normal',
            Latitude: '50.125278',
            Longitude: '31.007222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB650',
            Type: 'Normal',
            Latitude: '50.058611',
            Longitude: '31.002500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB648',
            Type: 'Normal',
            Latitude: '49.991944',
            Longitude: '30.997778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB646',
            Type: 'Normal',
            Latitude: '49.925278',
            Longitude: '30.993056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB644',
            Type: 'Normal',
            Latitude: '49.858611',
            Longitude: '30.988333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
        ],
        Name: 'CY1V',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB656',
            Type: 'Normal',
            Latitude: '50.201667',
            Longitude: '31.138611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB654',
            Type: 'Normal',
            Latitude: '50.191944',
            Longitude: '31.011944',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB652',
            Type: 'Normal',
            Latitude: '50.125278',
            Longitude: '31.007222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB650',
            Type: 'Normal',
            Latitude: '50.058611',
            Longitude: '31.002500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB648',
            Type: 'Normal',
            Latitude: '49.991944',
            Longitude: '30.997778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB646',
            Type: 'Normal',
            Latitude: '49.925278',
            Longitude: '30.993056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB644',
            Type: 'Normal',
            Latitude: '49.858611',
            Longitude: '30.988333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'RUNOZ',
            Type: 'Normal',
            Latitude: '50.131667',
            Longitude: '30.889722',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
        ],
        Name: 'CY1W',
        Runways: '36R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'D260R',
            Type: 'Normal',
            Latitude: '50.051747',
            Longitude: '30.928231',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '3',
          },
        ],
        Name: 'CY2Q',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'D086S',
            Type: 'Normal',
            Latitude: '50.268567',
            Longitude: '31.381311',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D086R',
            Type: 'Normal',
            Latitude: '50.611983',
            Longitude: '31.362386',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'D003U',
            Type: 'Normal',
            Latitude: '50.625136',
            Longitude: '30.995069',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'GOTA2E',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'D086S',
            Type: 'Normal',
            Latitude: '50.268567',
            Longitude: '31.381311',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D086R',
            Type: 'Normal',
            Latitude: '50.611983',
            Longitude: '31.362386',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'D086C',
            Type: 'Normal',
            Latitude: '50.625978',
            Longitude: '30.970356',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'GOTA2J',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D260R',
            Type: 'Normal',
            Latitude: '50.051747',
            Longitude: '30.928231',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '4',
          },
        ],
        Name: 'GOTA2Q',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBE03',
            Type: 'Normal',
            Latitude: '50.388056',
            Longitude: '31.353611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB454',
            Type: 'Normal',
            Latitude: '50.478611',
            Longitude: '31.035278',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB452',
            Type: 'Normal',
            Latitude: '50.545000',
            Longitude: '31.040278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB450',
            Type: 'Normal',
            Latitude: '50.611389',
            Longitude: '31.045278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB448',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '31.050278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB446',
            Type: 'Normal',
            Latitude: '50.744167',
            Longitude: '31.055278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB444',
            Type: 'Normal',
            Latitude: '50.810556',
            Longitude: '31.060278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
        ],
        Name: 'GOTA2T',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBE03',
            Type: 'Normal',
            Latitude: '50.388056',
            Longitude: '31.353611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB454',
            Type: 'Normal',
            Latitude: '50.478611',
            Longitude: '31.035278',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB452',
            Type: 'Normal',
            Latitude: '50.545000',
            Longitude: '31.040278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB450',
            Type: 'Normal',
            Latitude: '50.611389',
            Longitude: '31.045278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB448',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '31.050278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB446',
            Type: 'Normal',
            Latitude: '50.744167',
            Longitude: '31.055278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB444',
            Type: 'Normal',
            Latitude: '50.810556',
            Longitude: '31.060278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
        ],
        Name: 'GOTA2U',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB656',
            Type: 'Normal',
            Latitude: '50.201667',
            Longitude: '31.138611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB654',
            Type: 'Normal',
            Latitude: '50.191944',
            Longitude: '31.011944',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB652',
            Type: 'Normal',
            Latitude: '50.125278',
            Longitude: '31.007222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB650',
            Type: 'Normal',
            Latitude: '50.058611',
            Longitude: '31.002500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB648',
            Type: 'Normal',
            Latitude: '49.991944',
            Longitude: '30.997778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB646',
            Type: 'Normal',
            Latitude: '49.925278',
            Longitude: '30.993056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB644',
            Type: 'Normal',
            Latitude: '49.858611',
            Longitude: '30.988333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
        ],
        Name: 'GOTA2V',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB656',
            Type: 'Normal',
            Latitude: '50.201667',
            Longitude: '31.138611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB654',
            Type: 'Normal',
            Latitude: '50.191944',
            Longitude: '31.011944',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB652',
            Type: 'Normal',
            Latitude: '50.125278',
            Longitude: '31.007222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB650',
            Type: 'Normal',
            Latitude: '50.058611',
            Longitude: '31.002500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB648',
            Type: 'Normal',
            Latitude: '49.991944',
            Longitude: '30.997778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB646',
            Type: 'Normal',
            Latitude: '49.925278',
            Longitude: '30.993056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB644',
            Type: 'Normal',
            Latitude: '49.858611',
            Longitude: '30.988333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'RUNOZ',
            Type: 'Normal',
            Latitude: '50.131667',
            Longitude: '30.889722',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
        ],
        Name: 'GOTA2W',
        Runways: '36R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'KONIP',
            Type: 'Normal',
            Latitude: '49.745000',
            Longitude: '31.443333',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D086R',
            Type: 'Normal',
            Latitude: '50.611983',
            Longitude: '31.362386',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'D086C',
            Type: 'Normal',
            Latitude: '50.625978',
            Longitude: '30.970356',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'KONI1J',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'KONIP',
            Type: 'Normal',
            Latitude: '49.745000',
            Longitude: '31.443333',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D086R',
            Type: 'Normal',
            Latitude: '50.611983',
            Longitude: '31.362386',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'D003U',
            Type: 'Normal',
            Latitude: '50.625136',
            Longitude: '30.995069',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'KONI2E',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'KONIP',
            Type: 'Normal',
            Latitude: '49.745000',
            Longitude: '31.443333',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D260R',
            Type: 'Normal',
            Latitude: '50.051747',
            Longitude: '30.928231',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '4',
          },
        ],
        Name: 'KONI2Q',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'KONIP',
            Type: 'Normal',
            Latitude: '49.745000',
            Longitude: '31.443333',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ASVEN',
            Type: 'Normal',
            Latitude: '49.951667',
            Longitude: '31.406111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE03',
            Type: 'Normal',
            Latitude: '50.388056',
            Longitude: '31.353611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB454',
            Type: 'Normal',
            Latitude: '50.478611',
            Longitude: '31.035278',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB452',
            Type: 'Normal',
            Latitude: '50.545000',
            Longitude: '31.040278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB450',
            Type: 'Normal',
            Latitude: '50.611389',
            Longitude: '31.045278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB448',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '31.050278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB446',
            Type: 'Normal',
            Latitude: '50.744167',
            Longitude: '31.055278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB444',
            Type: 'Normal',
            Latitude: '50.810556',
            Longitude: '31.060278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '15',
          },
        ],
        Name: 'KONI4T',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'KONIP',
            Type: 'Normal',
            Latitude: '49.745000',
            Longitude: '31.443333',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ASVEN',
            Type: 'Normal',
            Latitude: '49.951667',
            Longitude: '31.406111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE03',
            Type: 'Normal',
            Latitude: '50.388056',
            Longitude: '31.353611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB454',
            Type: 'Normal',
            Latitude: '50.478611',
            Longitude: '31.035278',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB452',
            Type: 'Normal',
            Latitude: '50.545000',
            Longitude: '31.040278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB450',
            Type: 'Normal',
            Latitude: '50.611389',
            Longitude: '31.045278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB448',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '31.050278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB446',
            Type: 'Normal',
            Latitude: '50.744167',
            Longitude: '31.055278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB444',
            Type: 'Normal',
            Latitude: '50.810556',
            Longitude: '31.060278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '15',
          },
        ],
        Name: 'KONI4U',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'KONIP',
            Type: 'Normal',
            Latitude: '49.745000',
            Longitude: '31.443333',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ASVEN',
            Type: 'Normal',
            Latitude: '49.951667',
            Longitude: '31.406111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB656',
            Type: 'Normal',
            Latitude: '50.201667',
            Longitude: '31.138611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB654',
            Type: 'Normal',
            Latitude: '50.191944',
            Longitude: '31.011944',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB652',
            Type: 'Normal',
            Latitude: '50.125278',
            Longitude: '31.007222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB650',
            Type: 'Normal',
            Latitude: '50.058611',
            Longitude: '31.002500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB648',
            Type: 'Normal',
            Latitude: '49.991944',
            Longitude: '30.997778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB646',
            Type: 'Normal',
            Latitude: '49.925278',
            Longitude: '30.993056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB644',
            Type: 'Normal',
            Latitude: '49.858611',
            Longitude: '30.988333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '15',
          },
        ],
        Name: 'KONI4V',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'KONIP',
            Type: 'Normal',
            Latitude: '49.745000',
            Longitude: '31.443333',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ASVEN',
            Type: 'Normal',
            Latitude: '49.951667',
            Longitude: '31.406111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB656',
            Type: 'Normal',
            Latitude: '50.201667',
            Longitude: '31.138611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB654',
            Type: 'Normal',
            Latitude: '50.191944',
            Longitude: '31.011944',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB652',
            Type: 'Normal',
            Latitude: '50.125278',
            Longitude: '31.007222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB650',
            Type: 'Normal',
            Latitude: '50.058611',
            Longitude: '31.002500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB648',
            Type: 'Normal',
            Latitude: '49.991944',
            Longitude: '30.997778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB646',
            Type: 'Normal',
            Latitude: '49.925278',
            Longitude: '30.993056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB644',
            Type: 'Normal',
            Latitude: '49.858611',
            Longitude: '30.988333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
          {
            Name: 'RUNOZ',
            Type: 'Normal',
            Latitude: '50.131667',
            Longitude: '30.889722',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '15',
          },
        ],
        Name: 'KONI4W',
        Runways: '36R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'KUVAL',
            Type: 'Normal',
            Latitude: '51.813056',
            Longitude: '31.007222',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'CY059',
            Type: 'Normal',
            Latitude: '51.048747',
            Longitude: '31.173608',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '1',
            Hdg_Crs_value: '177',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '027',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'KUVA1J',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'KUVAL',
            Type: 'Normal',
            Latitude: '51.813056',
            Longitude: '31.007222',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'CY059',
            Type: 'Normal',
            Latitude: '51.048747',
            Longitude: '31.173608',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '1',
            Hdg_Crs_value: '177',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '027',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'KUVA2E',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'KUVAL',
            Type: 'Normal',
            Latitude: '51.813056',
            Longitude: '31.007222',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'CY059',
            Type: 'Normal',
            Latitude: '51.048747',
            Longitude: '31.173608',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'D260R',
            Type: 'Normal',
            Latitude: '50.051747',
            Longitude: '30.928231',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '4',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '5',
          },
        ],
        Name: 'KUVA2Q',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'KUVAL',
            Type: 'Normal',
            Latitude: '51.813056',
            Longitude: '31.007222',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBN01',
            Type: 'Normal',
            Latitude: '50.844722',
            Longitude: '31.378611',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB456',
            Type: 'Normal',
            Latitude: '50.474722',
            Longitude: '31.166389',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB454',
            Type: 'Normal',
            Latitude: '50.478611',
            Longitude: '31.035278',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB452',
            Type: 'Normal',
            Latitude: '50.545000',
            Longitude: '31.040278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB450',
            Type: 'Normal',
            Latitude: '50.611389',
            Longitude: '31.045278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB448',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '31.050278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB446',
            Type: 'Normal',
            Latitude: '50.744167',
            Longitude: '31.055278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB444',
            Type: 'Normal',
            Latitude: '50.810556',
            Longitude: '31.060278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
        ],
        Name: 'KUVA4T',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'KUVAL',
            Type: 'Normal',
            Latitude: '51.813056',
            Longitude: '31.007222',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBN01',
            Type: 'Normal',
            Latitude: '50.844722',
            Longitude: '31.378611',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB456',
            Type: 'Normal',
            Latitude: '50.474722',
            Longitude: '31.166389',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB454',
            Type: 'Normal',
            Latitude: '50.478611',
            Longitude: '31.035278',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB452',
            Type: 'Normal',
            Latitude: '50.545000',
            Longitude: '31.040278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB450',
            Type: 'Normal',
            Latitude: '50.611389',
            Longitude: '31.045278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB448',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '31.050278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB446',
            Type: 'Normal',
            Latitude: '50.744167',
            Longitude: '31.055278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB444',
            Type: 'Normal',
            Latitude: '50.810556',
            Longitude: '31.060278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
        ],
        Name: 'KUVA4U',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'KUVAL',
            Type: 'Normal',
            Latitude: '51.813056',
            Longitude: '31.007222',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBN01',
            Type: 'Normal',
            Latitude: '50.844722',
            Longitude: '31.378611',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE04',
            Type: 'Normal',
            Latitude: '50.531389',
            Longitude: '31.307778',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'INVAD',
            Type: 'Normal',
            Latitude: '50.366667',
            Longitude: '31.270556',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB654',
            Type: 'Normal',
            Latitude: '50.191944',
            Longitude: '31.011944',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB652',
            Type: 'Normal',
            Latitude: '50.125278',
            Longitude: '31.007222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB650',
            Type: 'Normal',
            Latitude: '50.058611',
            Longitude: '31.002500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB648',
            Type: 'Normal',
            Latitude: '49.991944',
            Longitude: '30.997778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB646',
            Type: 'Normal',
            Latitude: '49.925278',
            Longitude: '30.993056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB644',
            Type: 'Normal',
            Latitude: '49.858611',
            Longitude: '30.988333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '15',
          },
        ],
        Name: 'KUVA4V',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'KUVAL',
            Type: 'Normal',
            Latitude: '51.813056',
            Longitude: '31.007222',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBN01',
            Type: 'Normal',
            Latitude: '50.844722',
            Longitude: '31.378611',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE04',
            Type: 'Normal',
            Latitude: '50.531389',
            Longitude: '31.307778',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'INVAD',
            Type: 'Normal',
            Latitude: '50.366667',
            Longitude: '31.270556',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB654',
            Type: 'Normal',
            Latitude: '50.191944',
            Longitude: '31.011944',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB652',
            Type: 'Normal',
            Latitude: '50.125278',
            Longitude: '31.007222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB650',
            Type: 'Normal',
            Latitude: '50.058611',
            Longitude: '31.002500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB648',
            Type: 'Normal',
            Latitude: '49.991944',
            Longitude: '30.997778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB646',
            Type: 'Normal',
            Latitude: '49.925278',
            Longitude: '30.993056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB644',
            Type: 'Normal',
            Latitude: '49.858611',
            Longitude: '30.988333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
          {
            Name: 'RUNOZ',
            Type: 'Normal',
            Latitude: '50.131667',
            Longitude: '30.889722',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '15',
          },
        ],
        Name: 'KUVA4W',
        Runways: '36R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NANIR',
            Type: 'Normal',
            Latitude: '50.833333',
            Longitude: '30.261667',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'NANI1J',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NANIR',
            Type: 'Normal',
            Latitude: '50.833333',
            Longitude: '30.261667',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'NANI2E',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NANIR',
            Type: 'Normal',
            Latitude: '50.833333',
            Longitude: '30.261667',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'D317G',
            Type: 'Normal',
            Latitude: '50.379464',
            Longitude: '30.792842',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '184',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '013',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '4',
          },
        ],
        Name: 'NANI2Q',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NANIR',
            Type: 'Normal',
            Latitude: '50.833333',
            Longitude: '30.261667',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB470',
            Type: 'Normal',
            Latitude: '50.603611',
            Longitude: '30.579722',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'GILEV',
            Type: 'Normal',
            Latitude: '50.556667',
            Longitude: '30.644722',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB468',
            Type: 'Normal',
            Latitude: '50.552778',
            Longitude: '30.775833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB466',
            Type: 'Normal',
            Latitude: '50.619167',
            Longitude: '30.780833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB464',
            Type: 'Normal',
            Latitude: '50.685556',
            Longitude: '30.785833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB462',
            Type: 'Normal',
            Latitude: '50.751944',
            Longitude: '30.790833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB460',
            Type: 'Normal',
            Latitude: '50.818333',
            Longitude: '30.795833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
        ],
        Name: 'NANI4T',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NANIR',
            Type: 'Normal',
            Latitude: '50.833333',
            Longitude: '30.261667',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB470',
            Type: 'Normal',
            Latitude: '50.603611',
            Longitude: '30.579722',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'GILEV',
            Type: 'Normal',
            Latitude: '50.556667',
            Longitude: '30.644722',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB468',
            Type: 'Normal',
            Latitude: '50.552778',
            Longitude: '30.775833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB466',
            Type: 'Normal',
            Latitude: '50.619167',
            Longitude: '30.780833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB464',
            Type: 'Normal',
            Latitude: '50.685556',
            Longitude: '30.785833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB462',
            Type: 'Normal',
            Latitude: '50.751944',
            Longitude: '30.790833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB460',
            Type: 'Normal',
            Latitude: '50.818333',
            Longitude: '30.795833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
        ],
        Name: 'NANI4U',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NANIR',
            Type: 'Normal',
            Latitude: '50.833333',
            Longitude: '30.261667',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB674',
            Type: 'Normal',
            Latitude: '50.496944',
            Longitude: '30.555000',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB672',
            Type: 'Normal',
            Latitude: '50.307778',
            Longitude: '30.719444',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB670',
            Type: 'Normal',
            Latitude: '50.199722',
            Longitude: '30.752500',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB668',
            Type: 'Normal',
            Latitude: '50.133056',
            Longitude: '30.747778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB665',
            Type: 'Normal',
            Latitude: '50.066389',
            Longitude: '30.743056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB664',
            Type: 'Normal',
            Latitude: '49.999722',
            Longitude: '30.738333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB662',
            Type: 'Normal',
            Latitude: '49.933056',
            Longitude: '30.733611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB660',
            Type: 'Normal',
            Latitude: '49.866389',
            Longitude: '30.728889',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
        ],
        Name: 'NANI4V',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NANIR',
            Type: 'Normal',
            Latitude: '50.833333',
            Longitude: '30.261667',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BB674',
            Type: 'Normal',
            Latitude: '50.496944',
            Longitude: '30.555000',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB672',
            Type: 'Normal',
            Latitude: '50.307778',
            Longitude: '30.719444',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB670',
            Type: 'Normal',
            Latitude: '50.199722',
            Longitude: '30.752500',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB668',
            Type: 'Normal',
            Latitude: '50.133056',
            Longitude: '30.747778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB665',
            Type: 'Normal',
            Latitude: '50.066389',
            Longitude: '30.743056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB664',
            Type: 'Normal',
            Latitude: '49.999722',
            Longitude: '30.738333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB662',
            Type: 'Normal',
            Latitude: '49.933056',
            Longitude: '30.733611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB660',
            Type: 'Normal',
            Latitude: '49.866389',
            Longitude: '30.728889',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'RUNOZ',
            Type: 'Normal',
            Latitude: '50.131667',
            Longitude: '30.889722',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
        ],
        Name: 'NANI4W',
        Runways: '36R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NEMIP',
            Type: 'Normal',
            Latitude: '52.070278',
            Longitude: '32.386389',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BRP47',
            Type: 'Normal',
            Latitude: '51.027208',
            Longitude: '31.294064',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'NEMI1J',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NEMIP',
            Type: 'Normal',
            Latitude: '52.070278',
            Longitude: '32.386389',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BRP47',
            Type: 'Normal',
            Latitude: '51.027208',
            Longitude: '31.294064',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'NEMI2E',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NEMIP',
            Type: 'Normal',
            Latitude: '52.070278',
            Longitude: '32.386389',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BRP47',
            Type: 'Normal',
            Latitude: '51.027208',
            Longitude: '31.294064',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '1',
            Hdg_Crs_value: '195',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '345',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'D260R',
            Type: 'Normal',
            Latitude: '50.051747',
            Longitude: '30.928231',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '5',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '6',
          },
        ],
        Name: 'NEMI2Q',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NEMIP',
            Type: 'Normal',
            Latitude: '52.070278',
            Longitude: '32.386389',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBN01',
            Type: 'Normal',
            Latitude: '50.844722',
            Longitude: '31.378611',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB456',
            Type: 'Normal',
            Latitude: '50.474722',
            Longitude: '31.166389',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB454',
            Type: 'Normal',
            Latitude: '50.478611',
            Longitude: '31.035278',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB452',
            Type: 'Normal',
            Latitude: '50.545000',
            Longitude: '31.040278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB450',
            Type: 'Normal',
            Latitude: '50.611389',
            Longitude: '31.045278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB448',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '31.050278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB446',
            Type: 'Normal',
            Latitude: '50.744167',
            Longitude: '31.055278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB444',
            Type: 'Normal',
            Latitude: '50.810556',
            Longitude: '31.060278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
        ],
        Name: 'NEMI4T',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NEMIP',
            Type: 'Normal',
            Latitude: '52.070278',
            Longitude: '32.386389',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBN01',
            Type: 'Normal',
            Latitude: '50.844722',
            Longitude: '31.378611',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB456',
            Type: 'Normal',
            Latitude: '50.474722',
            Longitude: '31.166389',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB454',
            Type: 'Normal',
            Latitude: '50.478611',
            Longitude: '31.035278',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB452',
            Type: 'Normal',
            Latitude: '50.545000',
            Longitude: '31.040278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB450',
            Type: 'Normal',
            Latitude: '50.611389',
            Longitude: '31.045278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB448',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '31.050278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB446',
            Type: 'Normal',
            Latitude: '50.744167',
            Longitude: '31.055278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB444',
            Type: 'Normal',
            Latitude: '50.810556',
            Longitude: '31.060278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
        ],
        Name: 'NEMI4U',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NEMIP',
            Type: 'Normal',
            Latitude: '52.070278',
            Longitude: '32.386389',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBN01',
            Type: 'Normal',
            Latitude: '50.844722',
            Longitude: '31.378611',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE04',
            Type: 'Normal',
            Latitude: '50.531389',
            Longitude: '31.307778',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'INVAD',
            Type: 'Normal',
            Latitude: '50.366667',
            Longitude: '31.270556',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB654',
            Type: 'Normal',
            Latitude: '50.191944',
            Longitude: '31.011944',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB652',
            Type: 'Normal',
            Latitude: '50.125278',
            Longitude: '31.007222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB650',
            Type: 'Normal',
            Latitude: '50.058611',
            Longitude: '31.002500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB648',
            Type: 'Normal',
            Latitude: '49.991944',
            Longitude: '30.997778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB646',
            Type: 'Normal',
            Latitude: '49.925278',
            Longitude: '30.993056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB644',
            Type: 'Normal',
            Latitude: '49.858611',
            Longitude: '30.988333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '15',
          },
        ],
        Name: 'NEMI4V',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NEMIP',
            Type: 'Normal',
            Latitude: '52.070278',
            Longitude: '32.386389',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBN01',
            Type: 'Normal',
            Latitude: '50.844722',
            Longitude: '31.378611',
            Speed: '0',
            Altitude: '12000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BBE04',
            Type: 'Normal',
            Latitude: '50.531389',
            Longitude: '31.307778',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'INVAD',
            Type: 'Normal',
            Latitude: '50.366667',
            Longitude: '31.270556',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB654',
            Type: 'Normal',
            Latitude: '50.191944',
            Longitude: '31.011944',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB652',
            Type: 'Normal',
            Latitude: '50.125278',
            Longitude: '31.007222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB650',
            Type: 'Normal',
            Latitude: '50.058611',
            Longitude: '31.002500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB648',
            Type: 'Normal',
            Latitude: '49.991944',
            Longitude: '30.997778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB646',
            Type: 'Normal',
            Latitude: '49.925278',
            Longitude: '30.993056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB644',
            Type: 'Normal',
            Latitude: '49.858611',
            Longitude: '30.988333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
          {
            Name: 'RUNOZ',
            Type: 'Normal',
            Latitude: '50.131667',
            Longitude: '30.889722',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '15',
          },
        ],
        Name: 'NEMI4W',
        Runways: '36R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'SL32B',
            Type: 'Normal',
            Latitude: '50.478453',
            Longitude: '30.279128',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'SLV50',
            Type: 'Normal',
            Latitude: '50.635811',
            Longitude: '30.669817',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BO',
            Type: 'Normal',
            Latitude: '50.628611',
            Longitude: '30.892222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'SLV2E',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBW01',
            Type: 'Normal',
            Latitude: '50.404722',
            Longitude: '30.288333',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB672',
            Type: 'Normal',
            Latitude: '50.307778',
            Longitude: '30.719444',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB670',
            Type: 'Normal',
            Latitude: '50.199722',
            Longitude: '30.752500',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB668',
            Type: 'Normal',
            Latitude: '50.133056',
            Longitude: '30.747778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB665',
            Type: 'Normal',
            Latitude: '50.066389',
            Longitude: '30.743056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB664',
            Type: 'Normal',
            Latitude: '49.999722',
            Longitude: '30.738333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB662',
            Type: 'Normal',
            Latitude: '49.933056',
            Longitude: '30.733611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB660',
            Type: 'Normal',
            Latitude: '49.866389',
            Longitude: '30.728889',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'RUNOZ',
            Type: 'Normal',
            Latitude: '50.131667',
            Longitude: '30.889722',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
        ],
        Name: 'SLV2W',
        Runways: '36R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'SL32B',
            Type: 'Normal',
            Latitude: '50.478453',
            Longitude: '30.279128',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'SLV50',
            Type: 'Normal',
            Latitude: '50.635811',
            Longitude: '30.669817',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'D266B',
            Type: 'Normal',
            Latitude: '50.630400',
            Longitude: '30.838117',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '1',
            Hdg_Crs_value: '131',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '356',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
        ],
        Name: 'SLV2J',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'SL32C',
            Type: 'Normal',
            Latitude: '50.420814',
            Longitude: '30.329033',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'SLV45',
            Type: 'Normal',
            Latitude: '50.513733',
            Longitude: '30.637061',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'D317G',
            Type: 'Normal',
            Latitude: '50.379464',
            Longitude: '30.792842',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '184',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '013',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '6',
          },
        ],
        Name: 'SLV2Q',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBW01',
            Type: 'Normal',
            Latitude: '50.404722',
            Longitude: '30.288333',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB468',
            Type: 'Normal',
            Latitude: '50.552778',
            Longitude: '30.775833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB466',
            Type: 'Normal',
            Latitude: '50.619167',
            Longitude: '30.780833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB464',
            Type: 'Normal',
            Latitude: '50.685556',
            Longitude: '30.785833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB462',
            Type: 'Normal',
            Latitude: '50.751944',
            Longitude: '30.790833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB460',
            Type: 'Normal',
            Latitude: '50.818333',
            Longitude: '30.795833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
        ],
        Name: 'SLV2T',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBW01',
            Type: 'Normal',
            Latitude: '50.404722',
            Longitude: '30.288333',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB468',
            Type: 'Normal',
            Latitude: '50.552778',
            Longitude: '30.775833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB466',
            Type: 'Normal',
            Latitude: '50.619167',
            Longitude: '30.780833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB464',
            Type: 'Normal',
            Latitude: '50.685556',
            Longitude: '30.785833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB462',
            Type: 'Normal',
            Latitude: '50.751944',
            Longitude: '30.790833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB460',
            Type: 'Normal',
            Latitude: '50.818333',
            Longitude: '30.795833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
        ],
        Name: 'SLV2U',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBW01',
            Type: 'Normal',
            Latitude: '50.404722',
            Longitude: '30.288333',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB672',
            Type: 'Normal',
            Latitude: '50.307778',
            Longitude: '30.719444',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB670',
            Type: 'Normal',
            Latitude: '50.199722',
            Longitude: '30.752500',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB668',
            Type: 'Normal',
            Latitude: '50.133056',
            Longitude: '30.747778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB665',
            Type: 'Normal',
            Latitude: '50.066389',
            Longitude: '30.743056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB664',
            Type: 'Normal',
            Latitude: '49.999722',
            Longitude: '30.738333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB662',
            Type: 'Normal',
            Latitude: '49.933056',
            Longitude: '30.733611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB660',
            Type: 'Normal',
            Latitude: '49.866389',
            Longitude: '30.728889',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
        ],
        Name: 'SLV2V',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SULUM',
            Type: 'Normal',
            Latitude: '49.557778',
            Longitude: '30.692500',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D086R',
            Type: 'Normal',
            Latitude: '50.611983',
            Longitude: '31.362386',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'D086C',
            Type: 'Normal',
            Latitude: '50.625978',
            Longitude: '30.970356',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'SULU1J',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SULUM',
            Type: 'Normal',
            Latitude: '49.557778',
            Longitude: '30.692500',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D260R',
            Type: 'Normal',
            Latitude: '50.051747',
            Longitude: '30.928231',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '3',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '4',
          },
        ],
        Name: 'SULU1Q',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SULUM',
            Type: 'Normal',
            Latitude: '49.557778',
            Longitude: '30.692500',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D086R',
            Type: 'Normal',
            Latitude: '50.611983',
            Longitude: '31.362386',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'D003U',
            Type: 'Normal',
            Latitude: '50.625136',
            Longitude: '30.995069',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'SULU2E',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SULUM',
            Type: 'Normal',
            Latitude: '49.557778',
            Longitude: '30.692500',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ASVEN',
            Type: 'Normal',
            Latitude: '49.951667',
            Longitude: '31.406111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE03',
            Type: 'Normal',
            Latitude: '50.388056',
            Longitude: '31.353611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB454',
            Type: 'Normal',
            Latitude: '50.478611',
            Longitude: '31.035278',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB452',
            Type: 'Normal',
            Latitude: '50.545000',
            Longitude: '31.040278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB450',
            Type: 'Normal',
            Latitude: '50.611389',
            Longitude: '31.045278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB448',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '31.050278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB446',
            Type: 'Normal',
            Latitude: '50.744167',
            Longitude: '31.055278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB444',
            Type: 'Normal',
            Latitude: '50.810556',
            Longitude: '31.060278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '15',
          },
        ],
        Name: 'SULU2T',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SULUM',
            Type: 'Normal',
            Latitude: '49.557778',
            Longitude: '30.692500',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ASVEN',
            Type: 'Normal',
            Latitude: '49.951667',
            Longitude: '31.406111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BBE03',
            Type: 'Normal',
            Latitude: '50.388056',
            Longitude: '31.353611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB454',
            Type: 'Normal',
            Latitude: '50.478611',
            Longitude: '31.035278',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB452',
            Type: 'Normal',
            Latitude: '50.545000',
            Longitude: '31.040278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB450',
            Type: 'Normal',
            Latitude: '50.611389',
            Longitude: '31.045278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB448',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '31.050278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB446',
            Type: 'Normal',
            Latitude: '50.744167',
            Longitude: '31.055278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB444',
            Type: 'Normal',
            Latitude: '50.810556',
            Longitude: '31.060278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '15',
          },
        ],
        Name: 'SULU4U',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SULUM',
            Type: 'Normal',
            Latitude: '49.557778',
            Longitude: '30.692500',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ASVEN',
            Type: 'Normal',
            Latitude: '49.951667',
            Longitude: '31.406111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB656',
            Type: 'Normal',
            Latitude: '50.201667',
            Longitude: '31.138611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB654',
            Type: 'Normal',
            Latitude: '50.191944',
            Longitude: '31.011944',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB652',
            Type: 'Normal',
            Latitude: '50.125278',
            Longitude: '31.007222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB650',
            Type: 'Normal',
            Latitude: '50.058611',
            Longitude: '31.002500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB648',
            Type: 'Normal',
            Latitude: '49.991944',
            Longitude: '30.997778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB646',
            Type: 'Normal',
            Latitude: '49.925278',
            Longitude: '30.993056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB644',
            Type: 'Normal',
            Latitude: '49.858611',
            Longitude: '30.988333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '15',
          },
        ],
        Name: 'SULU4V',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SULUM',
            Type: 'Normal',
            Latitude: '49.557778',
            Longitude: '30.692500',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ASVEN',
            Type: 'Normal',
            Latitude: '49.951667',
            Longitude: '31.406111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB656',
            Type: 'Normal',
            Latitude: '50.201667',
            Longitude: '31.138611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB654',
            Type: 'Normal',
            Latitude: '50.191944',
            Longitude: '31.011944',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB652',
            Type: 'Normal',
            Latitude: '50.125278',
            Longitude: '31.007222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB650',
            Type: 'Normal',
            Latitude: '50.058611',
            Longitude: '31.002500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB648',
            Type: 'Normal',
            Latitude: '49.991944',
            Longitude: '30.997778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB646',
            Type: 'Normal',
            Latitude: '49.925278',
            Longitude: '30.993056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB644',
            Type: 'Normal',
            Latitude: '49.858611',
            Longitude: '30.988333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
          {
            Name: 'RUNOZ',
            Type: 'Normal',
            Latitude: '50.131667',
            Longitude: '30.889722',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '15',
          },
        ],
        Name: 'SULU4W',
        Runways: '36R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'VEBIX',
            Type: 'Normal',
            Latitude: '49.826111',
            Longitude: '31.852500',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D086R',
            Type: 'Normal',
            Latitude: '50.611983',
            Longitude: '31.362386',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'D086C',
            Type: 'Normal',
            Latitude: '50.625978',
            Longitude: '30.970356',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'VEBI1J',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'VEBIX',
            Type: 'Normal',
            Latitude: '49.826111',
            Longitude: '31.852500',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D260R',
            Type: 'Normal',
            Latitude: '50.051747',
            Longitude: '30.928231',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '4',
          },
        ],
        Name: 'VEBI1Q',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'VEBIX',
            Type: 'Normal',
            Latitude: '49.826111',
            Longitude: '31.852500',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D086R',
            Type: 'Normal',
            Latitude: '50.611983',
            Longitude: '31.362386',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'D003U',
            Type: 'Normal',
            Latitude: '50.625136',
            Longitude: '30.995069',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'VEBI2E',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'VEBIX',
            Type: 'Normal',
            Latitude: '49.826111',
            Longitude: '31.852500',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBE03',
            Type: 'Normal',
            Latitude: '50.388056',
            Longitude: '31.353611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB454',
            Type: 'Normal',
            Latitude: '50.478611',
            Longitude: '31.035278',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB452',
            Type: 'Normal',
            Latitude: '50.545000',
            Longitude: '31.040278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB450',
            Type: 'Normal',
            Latitude: '50.611389',
            Longitude: '31.045278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB448',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '31.050278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB446',
            Type: 'Normal',
            Latitude: '50.744167',
            Longitude: '31.055278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB444',
            Type: 'Normal',
            Latitude: '50.810556',
            Longitude: '31.060278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
        ],
        Name: 'VEBI2T',
        Runways: '18R',
      },
      {
        Star_Waypoint: [
          {
            Name: 'VEBIX',
            Type: 'Normal',
            Latitude: '49.826111',
            Longitude: '31.852500',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BBE03',
            Type: 'Normal',
            Latitude: '50.388056',
            Longitude: '31.353611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB454',
            Type: 'Normal',
            Latitude: '50.478611',
            Longitude: '31.035278',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB452',
            Type: 'Normal',
            Latitude: '50.545000',
            Longitude: '31.040278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB450',
            Type: 'Normal',
            Latitude: '50.611389',
            Longitude: '31.045278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB448',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '31.050278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB446',
            Type: 'Normal',
            Latitude: '50.744167',
            Longitude: '31.055278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB444',
            Type: 'Normal',
            Latitude: '50.810556',
            Longitude: '31.060278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB442',
            Type: 'Normal',
            Latitude: '50.814444',
            Longitude: '30.928056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB440',
            Type: 'Normal',
            Latitude: '50.748056',
            Longitude: '30.923056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB438',
            Type: 'Normal',
            Latitude: '50.681667',
            Longitude: '30.918056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB436',
            Type: 'Normal',
            Latitude: '50.615278',
            Longitude: '30.913056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
        ],
        Name: 'VEBI2U',
        Runways: '18L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'VEBIX',
            Type: 'Normal',
            Latitude: '49.826111',
            Longitude: '31.852500',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB656',
            Type: 'Normal',
            Latitude: '50.201667',
            Longitude: '31.138611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB654',
            Type: 'Normal',
            Latitude: '50.191944',
            Longitude: '31.011944',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB652',
            Type: 'Normal',
            Latitude: '50.125278',
            Longitude: '31.007222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB650',
            Type: 'Normal',
            Latitude: '50.058611',
            Longitude: '31.002500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB648',
            Type: 'Normal',
            Latitude: '49.991944',
            Longitude: '30.997778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB646',
            Type: 'Normal',
            Latitude: '49.925278',
            Longitude: '30.993056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB644',
            Type: 'Normal',
            Latitude: '49.858611',
            Longitude: '30.988333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
        ],
        Name: 'VEBI2V',
        Runways: '36L',
      },
      {
        Star_Waypoint: [
          {
            Name: 'VEBIX',
            Type: 'Normal',
            Latitude: '49.826111',
            Longitude: '31.852500',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BB656',
            Type: 'Normal',
            Latitude: '50.201667',
            Longitude: '31.138611',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BB654',
            Type: 'Normal',
            Latitude: '50.191944',
            Longitude: '31.011944',
            Speed: '220',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BB652',
            Type: 'Normal',
            Latitude: '50.125278',
            Longitude: '31.007222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BB650',
            Type: 'Normal',
            Latitude: '50.058611',
            Longitude: '31.002500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BB648',
            Type: 'Normal',
            Latitude: '49.991944',
            Longitude: '30.997778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BB646',
            Type: 'Normal',
            Latitude: '49.925278',
            Longitude: '30.993056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'BB644',
            Type: 'Normal',
            Latitude: '49.858611',
            Longitude: '30.988333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'BB642',
            Type: 'Normal',
            Latitude: '49.862500',
            Longitude: '30.858611',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
          {
            Name: 'BB640',
            Type: 'Normal',
            Latitude: '49.929167',
            Longitude: '30.863333',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '11',
          },
          {
            Name: 'BB638',
            Type: 'Normal',
            Latitude: '49.995833',
            Longitude: '30.868056',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '12',
          },
          {
            Name: 'BB636',
            Type: 'Normal',
            Latitude: '50.062500',
            Longitude: '30.872778',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '13',
          },
          {
            Name: 'RUNOZ',
            Type: 'Normal',
            Latitude: '50.131667',
            Longitude: '30.889722',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '14',
          },
        ],
        Name: 'VEBI2W',
        Runways: '36R',
      },
    ],
    Approach: [
      {
        App_Waypoint: [
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'FF18L',
            Type: 'Normal',
            Latitude: '50.490983',
            Longitude: '30.916103',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'MD18L',
            Type: 'Normal',
            Latitude: '50.369206',
            Longitude: '30.907181',
            Speed: '0',
            Altitude: '627',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'RW18L',
            Type: 'Runway',
            Latitude: '50.360539',
            Longitude: '30.906472',
            Speed: '0',
            Altitude: '478',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '6.0',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
        ],
        Name: 'VDM18L',
      },
      {
        App_Waypoint: [
          {
            Name: 'RUNOZ',
            Type: 'Normal',
            Latitude: '50.131667',
            Longitude: '30.889722',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'FF36R',
            Type: 'Normal',
            Latitude: '50.194975',
            Longitude: '30.894364',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'MD36R',
            Type: 'Normal',
            Latitude: '50.311739',
            Longitude: '30.902778',
            Speed: '0',
            Altitude: '721',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'RW36R',
            Type: 'Runway',
            Latitude: '50.324619',
            Longitude: '30.903833',
            Speed: '0',
            Altitude: '479',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '13.0',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BRP',
            Type: 'VorRadialIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '146',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '034',
            Sp_Turn: 'Right',
            ID: '6',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '7',
          },
        ],
        Name: 'VDM36R',
      },
      {
        App_Waypoint: [
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'FF18L',
            Type: 'Normal',
            Latitude: '50.490983',
            Longitude: '30.916103',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'RW18L',
            Type: 'Runway',
            Latitude: '50.360539',
            Longitude: '30.906472',
            Speed: '0',
            Altitude: '478',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '6.0',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
        ],
        Name: 'ILS18L',
      },
      {
        App_Waypoint: [
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'FF18R',
            Type: 'Normal',
            Latitude: '50.481722',
            Longitude: '30.891000',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'RW18R',
            Type: 'Runway',
            Latitude: '50.352133',
            Longitude: '30.881592',
            Speed: '0',
            Altitude: '484',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '990',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '2.0',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.180608',
            Longitude: '30.769217',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '218',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '003',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'D003H',
            Type: 'Normal',
            Latitude: '50.180608',
            Longitude: '30.769217',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '3940',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'BRP',
            Type: 'VorRadialIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '093',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '170',
            Sp_Turn: 'Left',
            ID: '7',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
        ],
        Name: 'ILS18R',
      },
      {
        App_Waypoint: [
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'FF36L',
            Type: 'Normal',
            Latitude: '50.190161',
            Longitude: '30.869933',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'RW36L',
            Type: 'Runway',
            Latitude: '50.320706',
            Longitude: '30.879317',
            Speed: '0',
            Altitude: '481',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '990',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '4.0',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '295',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '325',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '3940',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '325',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '14.0',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.415678',
            Longitude: '31.077994',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '086',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '323',
            Sp_Turn: 'Right',
            ID: '7',
          },
          {
            Name: 'D034J',
            Type: 'Normal',
            Latitude: '50.415678',
            Longitude: '31.077994',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '8',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
        ],
        Name: 'ILS36L',
      },
      {
        App_Waypoint: [
          {
            Name: 'RUNOZ',
            Type: 'Normal',
            Latitude: '50.131667',
            Longitude: '30.889722',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'FF36R',
            Type: 'Normal',
            Latitude: '50.194975',
            Longitude: '30.894364',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'RW36R',
            Type: 'Runway',
            Latitude: '50.324619',
            Longitude: '30.903833',
            Speed: '0',
            Altitude: '479',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '13.0',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BRP',
            Type: 'VorRadialIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '146',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '034',
            Sp_Turn: 'Right',
            ID: '5',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
        ],
        Name: 'ILS36R',
      },
      {
        App_Waypoint: [
          {
            Name: 'LESOF',
            Type: 'Normal',
            Latitude: '50.548056',
            Longitude: '30.920278',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'FF18L',
            Type: 'Normal',
            Latitude: '50.490983',
            Longitude: '30.916103',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'I',
            Type: 'Normal',
            Latitude: '50.368889',
            Longitude: '30.907139',
            Speed: '0',
            Altitude: '623',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'RW18L',
            Type: 'Runway',
            Latitude: '50.360539',
            Longitude: '30.906472',
            Speed: '0',
            Altitude: '478',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '6.0',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
        ],
        Name: 'NDM18L',
      },
      {
        App_Waypoint: [
          {
            Name: 'RISVI',
            Type: 'Normal',
            Latitude: '50.548611',
            Longitude: '30.895833',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'FF18R',
            Type: 'Normal',
            Latitude: '50.481722',
            Longitude: '30.891000',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'B',
            Type: 'Normal',
            Latitude: '50.362667',
            Longitude: '30.882361',
            Speed: '0',
            Altitude: '678',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'RW18R',
            Type: 'Runway',
            Latitude: '50.352133',
            Longitude: '30.881592',
            Speed: '0',
            Altitude: '484',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '990',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '176',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '2.0',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.180608',
            Longitude: '30.769217',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '218',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '003',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'D003H',
            Type: 'Normal',
            Latitude: '50.180608',
            Longitude: '30.769217',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '3940',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: 'BRP',
            Type: 'VorRadialIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '093',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '170',
            Sp_Turn: 'Left',
            ID: '8',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
        ],
        Name: 'NDM18R',
      },
      {
        App_Waypoint: [
          {
            Name: 'LANED',
            Type: 'Normal',
            Latitude: '50.132222',
            Longitude: '30.865556',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'FF36L',
            Type: 'Normal',
            Latitude: '50.190161',
            Longitude: '30.869933',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'E',
            Type: 'Normal',
            Latitude: '50.311667',
            Longitude: '30.878667',
            Speed: '0',
            Altitude: '633',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'RW36L',
            Type: 'Runway',
            Latitude: '50.320706',
            Longitude: '30.879317',
            Speed: '0',
            Altitude: '481',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '990',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '4.0',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.491942',
            Longitude: '30.728958',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '295',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '145',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'D325N',
            Type: 'Normal',
            Latitude: '50.491942',
            Longitude: '30.728958',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '3940',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.415678',
            Longitude: '31.077994',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '086',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '323',
            Sp_Turn: 'Right',
            ID: '8',
          },
          {
            Name: 'D034J',
            Type: 'Normal',
            Latitude: '50.415678',
            Longitude: '31.077994',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '9',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '6070',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '10',
          },
        ],
        Name: 'NDM36L',
      },
      {
        App_Waypoint: [
          {
            Name: 'RUNOZ',
            Type: 'Normal',
            Latitude: '50.131667',
            Longitude: '30.889722',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'FF36R',
            Type: 'Normal',
            Latitude: '50.194975',
            Longitude: '30.894364',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'O',
            Type: 'Normal',
            Latitude: '50.315694',
            Longitude: '30.903111',
            Speed: '0',
            Altitude: '645',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'RW36R',
            Type: 'Runway',
            Latitude: '50.324619',
            Longitude: '30.903833',
            Speed: '0',
            Altitude: '479',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BRP',
            Type: 'DmeIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '356',
            Flytype: 'Fly-by',
            BankLimit: '25',
            DMEtoIntercept: '13.0',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'BRP',
            Type: 'VorRadialIntc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '146',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '034',
            Sp_Turn: 'Right',
            ID: '6',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '7',
          },
        ],
        Name: 'NDM36R',
      },
    ],
    ICAOcode: 'UKBB',
  },
  {
    ICAOcode: 'UKKK',
    Runways: {
      '08': {
        heading: 80,
        threshold: {
          latitude: 50.401161,
          longitude: 30.436919,
        },
        elevation: 637,
        approachArea: [
          {
            latitude: 50.401161,
            longitude: 30.436919,
          },
          geolib.computeDestinationPoint(
            {
              latitude: 50.401161,
              longitude: 30.436919,
            },
            18 * 1.852 * 1000,
            (80 + 180 + 5 + 6) % 360,
          ),
          geolib.computeDestinationPoint(
            {
              latitude: 50.401161,
              longitude: 30.436919,
            },
            18 * 1.852 * 1000,
            (310 + 180 - 5 + 6) % 360,
          ),
          {
            latitude: 50.401161,
            longitude: 30.436919,
          },
        ],
      },
      26: {
        heading: 260,
        threshold: {
          latitude: 50.402431,
          longitude: 30.466569,
        },
        elevation: 640,
        approachArea: [
          {
            latitude: 50.402431,
            longitude: 30.466569,
          },
          geolib.computeDestinationPoint(
            {
              latitude: 50.402431,
              longitude: 30.466569,
            },
            18 * 1.852 * 1000,
            (260 + 180 + 5 + 6) % 360,
          ),
          geolib.computeDestinationPoint(
            {
              latitude: 50.402431,
              longitude: 30.466569,
            },
            18 * 1.852 * 1000,
            (260 + 180 - 5 + 6) % 360,
          ),
          {
            latitude: 50.402431,
            longitude: 30.466569,
          },
        ],
      },
    },
    Sid: [
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KK305',
            Type: 'Normal',
            Latitude: '50.409722',
            Longitude: '30.634722',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BASOR',
            Type: 'Normal',
            Latitude: '50.051944',
            Longitude: '30.833333',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'BASO1K',
        Runways: '08',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(1970)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1970',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KKS01',
            Type: 'Normal',
            Latitude: '50.303889',
            Longitude: '30.515556',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '2',
          },
          {
            Name: 'BASOR',
            Type: 'Normal',
            Latitude: '50.051944',
            Longitude: '30.833333',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'BASO1L',
        Runways: '26',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2960)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.051944',
            Longitude: '30.833333',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '202',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '316',
            Sp_Turn: 'Right',
            ID: '2',
          },
          {
            Name: 'BASOR',
            Type: 'Normal',
            Latitude: '50.051944',
            Longitude: '30.833333',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'BASO2G',
        Runways: '08',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(1150)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1150',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D270O',
            Type: 'Normal',
            Latitude: '50.315506',
            Longitude: '30.513569',
            Speed: '195',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '3',
          },
          {
            Name: 'BASOR',
            Type: 'Normal',
            Latitude: '50.051944',
            Longitude: '30.833333',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'BASO2H',
        Runways: '26',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2960)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'GOTA2G',
        Runways: '08',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(1150)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1150',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D270O',
            Type: 'Normal',
            Latitude: '50.315506',
            Longitude: '30.513569',
            Speed: '195',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '3',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'GOTA2H',
        Runways: '26',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KK305',
            Type: 'Normal',
            Latitude: '50.409722',
            Longitude: '30.634722',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'KKE01',
            Type: 'Normal',
            Latitude: '50.379444',
            Longitude: '30.910556',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'INVAD',
            Type: 'Normal',
            Latitude: '50.366667',
            Longitude: '31.270556',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'GOTA2K',
        Runways: '08',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(1970)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1970',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KKS01',
            Type: 'Normal',
            Latitude: '50.303889',
            Longitude: '30.515556',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '2',
          },
          {
            Name: 'KKE01',
            Type: 'Normal',
            Latitude: '50.379444',
            Longitude: '30.910556',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'INVAD',
            Type: 'Normal',
            Latitude: '50.366667',
            Longitude: '31.270556',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'GOTA2L',
        Runways: '26',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(1150)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1150',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D270O',
            Type: 'Normal',
            Latitude: '50.315506',
            Longitude: '30.513569',
            Speed: '195',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '3',
          },
          {
            Name: 'D083I',
            Type: 'Normal',
            Latitude: '50.400203',
            Longitude: '30.642286',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '083',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '296',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
        ],
        Name: 'GOTA2X',
        Runways: '26',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2960)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '083',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '296',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'GOTA7Q',
        Runways: '08',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2960)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '2',
          },
          {
            Name: 'KAFEL',
            Type: 'Normal',
            Latitude: '50.606944',
            Longitude: '29.280556',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'KAFE1G',
        Runways: '08',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(1150)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1150',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D270O',
            Type: 'Normal',
            Latitude: '50.315506',
            Longitude: '30.513569',
            Speed: '195',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '3',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '4',
          },
          {
            Name: 'KAFEL',
            Type: 'Normal',
            Latitude: '50.606944',
            Longitude: '29.280556',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'KAFE1H',
        Runways: '26',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KKS01',
            Type: 'Normal',
            Latitude: '50.303889',
            Longitude: '30.515556',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '2',
          },
          {
            Name: 'KK315',
            Type: 'Normal',
            Latitude: '50.368611',
            Longitude: '30.255278',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KAFEL',
            Type: 'Normal',
            Latitude: '50.606944',
            Longitude: '29.280556',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'KAFE1K',
        Runways: '08',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(1970)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1970',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KK105',
            Type: 'Normal',
            Latitude: '50.395000',
            Longitude: '30.297778',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'KAFEL',
            Type: 'Normal',
            Latitude: '50.606944',
            Longitude: '29.280556',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'KAFE1L',
        Runways: '26',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(1970)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1970',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KAFEL',
            Type: 'Normal',
            Latitude: '50.606944',
            Longitude: '29.280556',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
        ],
        Name: 'KAFE1X',
        Runways: '26',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2960)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '038',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '242',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'KUROS',
            Type: 'Normal',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'KURO2G',
        Runways: '08',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(1150)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1150',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D270O',
            Type: 'Normal',
            Latitude: '50.315506',
            Longitude: '30.513569',
            Speed: '195',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '3',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '028',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '242',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'KUROS',
            Type: 'Normal',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'KURO2H',
        Runways: '26',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(1970)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1970',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KKS01',
            Type: 'Normal',
            Latitude: '50.303889',
            Longitude: '30.515556',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '2',
          },
          {
            Name: 'KKE01',
            Type: 'Normal',
            Latitude: '50.379444',
            Longitude: '30.910556',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'INVAD',
            Type: 'Normal',
            Latitude: '50.366667',
            Longitude: '31.270556',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'KUROS',
            Type: 'Normal',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'KURO2L',
        Runways: '26',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(1150)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1150',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D270O',
            Type: 'Normal',
            Latitude: '50.315506',
            Longitude: '30.513569',
            Speed: '195',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '3',
          },
          {
            Name: 'D083I',
            Type: 'Normal',
            Latitude: '50.400203',
            Longitude: '30.642286',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '083',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '224',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'KUROS',
            Type: 'Normal',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
        ],
        Name: 'KURO2X',
        Runways: '26',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KK305',
            Type: 'Normal',
            Latitude: '50.409722',
            Longitude: '30.634722',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'KKE01',
            Type: 'Normal',
            Latitude: '50.379444',
            Longitude: '30.910556',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'INVAD',
            Type: 'Normal',
            Latitude: '50.366667',
            Longitude: '31.270556',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'KUROS',
            Type: 'Normal',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'KURO3K',
        Runways: '08',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2960)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '083',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '224',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'KUROS',
            Type: 'Normal',
            Latitude: '50.748333',
            Longitude: '31.796667',
            Speed: '0',
            Altitude: '17000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'KURO3Q',
        Runways: '08',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2960)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '2',
          },
          {
            Name: 'LASDA',
            Type: 'Normal',
            Latitude: '51.476389',
            Longitude: '30.261667',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'LASD2G',
        Runways: '08',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(1150)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1150',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D270O',
            Type: 'Normal',
            Latitude: '50.315506',
            Longitude: '30.513569',
            Speed: '195',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '3',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '4',
          },
          {
            Name: 'LASDA',
            Type: 'Normal',
            Latitude: '51.476389',
            Longitude: '30.261667',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'LASD2H',
        Runways: '26',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KK305',
            Type: 'Normal',
            Latitude: '50.409722',
            Longitude: '30.634722',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'GILEV',
            Type: 'Normal',
            Latitude: '50.556667',
            Longitude: '30.644722',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'LASDA',
            Type: 'Normal',
            Latitude: '51.476389',
            Longitude: '30.261667',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'LASD2K',
        Runways: '08',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(1970)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1970',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KKS01',
            Type: 'Normal',
            Latitude: '50.303889',
            Longitude: '30.515556',
            Speed: '0',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '2',
          },
          {
            Name: 'GILEV',
            Type: 'Normal',
            Latitude: '50.556667',
            Longitude: '30.644722',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'LASDA',
            Type: 'Normal',
            Latitude: '51.476389',
            Longitude: '30.261667',
            Speed: '0',
            Altitude: '8040',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'LASD2L',
        Runways: '26',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2960)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '2',
          },
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'SLV2G',
        Runways: '08',
      },
      {
        Sid_Waypoint: [
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(1150)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1150',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D270O',
            Type: 'Normal',
            Latitude: '50.315506',
            Longitude: '30.513569',
            Speed: '195',
            Altitude: '3940',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '3',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '4',
          },
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'SLV2H',
        Runways: '26',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(2470)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KKS01',
            Type: 'Normal',
            Latitude: '50.303889',
            Longitude: '30.515556',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '2',
          },
          {
            Name: 'KK315',
            Type: 'Normal',
            Latitude: '50.368611',
            Longitude: '30.255278',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'SLV2K',
        Runways: '08',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(1970)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1970',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KK105',
            Type: 'Normal',
            Latitude: '50.395000',
            Longitude: '30.297778',
            Speed: '0',
            Altitude: '2470',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'SLV2L',
        Runways: '26',
      },
      {
        Sid_Waypoint: [
          {
            Name: '(1970)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '1970',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
        ],
        Name: 'SLV2X',
        Runways: '26',
      },
    ],
    Star: [
      {
        Star_Waypoint: [
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KKE02',
            Type: 'Normal',
            Latitude: '50.270139',
            Longitude: '31.368056',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KK840',
            Type: 'Normal',
            Latitude: '50.307222',
            Longitude: '30.203889',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'KK820',
            Type: 'Normal',
            Latitude: '50.390694',
            Longitude: '30.195000',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'CY2M',
        Runways: '08',
      },
      {
        Star_Waypoint: [
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '351',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '086',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'CY2P',
        Runways: 'All',
      },
      {
        Star_Waypoint: [
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KKE02',
            Type: 'Normal',
            Latitude: '50.270139',
            Longitude: '31.368056',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KK630',
            Type: 'Normal',
            Latitude: '50.329167',
            Longitude: '30.714722',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'KK620',
            Type: 'Normal',
            Latitude: '50.412639',
            Longitude: '30.706111',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'CY3N',
        Runways: '26',
      },
      {
        Star_Waypoint: [
          {
            Name: 'GAMLA',
            Type: 'Normal',
            Latitude: '49.270000',
            Longitude: '30.422778',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ASVEN',
            Type: 'Normal',
            Latitude: '49.951667',
            Longitude: '31.406111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KKE02',
            Type: 'Normal',
            Latitude: '50.270139',
            Longitude: '31.368056',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'KK840',
            Type: 'Normal',
            Latitude: '50.307222',
            Longitude: '30.203889',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'KK820',
            Type: 'Normal',
            Latitude: '50.390694',
            Longitude: '30.195000',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
        ],
        Name: 'GAML2M',
        Runways: '08',
      },
      {
        Star_Waypoint: [
          {
            Name: 'GAMLA',
            Type: 'Normal',
            Latitude: '49.270000',
            Longitude: '30.422778',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KK850',
            Type: 'Normal',
            Latitude: '49.774444',
            Longitude: '30.316944',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'KK840',
            Type: 'Normal',
            Latitude: '50.307222',
            Longitude: '30.203889',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KK820',
            Type: 'Normal',
            Latitude: '50.390694',
            Longitude: '30.195000',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'GAML2Q',
        Runways: '08',
      },
      {
        Star_Waypoint: [
          {
            Name: 'GAMLA',
            Type: 'Normal',
            Latitude: '49.270000',
            Longitude: '30.422778',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ASVEN',
            Type: 'Normal',
            Latitude: '49.951667',
            Longitude: '31.406111',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'CY',
            Type: 'Normal',
            Latitude: '50.068333',
            Longitude: '31.392222',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KKE02',
            Type: 'Normal',
            Latitude: '50.270139',
            Longitude: '31.368056',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
          {
            Name: 'KK630',
            Type: 'Normal',
            Latitude: '50.329167',
            Longitude: '30.714722',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '6',
          },
          {
            Name: 'KK620',
            Type: 'Normal',
            Latitude: '50.412639',
            Longitude: '30.706111',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '7',
          },
        ],
        Name: 'GAML3N',
        Runways: '26',
      },
      {
        Star_Waypoint: [
          {
            Name: 'GAMLA',
            Type: 'Normal',
            Latitude: '49.270000',
            Longitude: '30.422778',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KK640',
            Type: 'Normal',
            Latitude: '49.744167',
            Longitude: '30.465278',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'KKS01',
            Type: 'Normal',
            Latitude: '50.303889',
            Longitude: '30.515556',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KK630',
            Type: 'Normal',
            Latitude: '50.329167',
            Longitude: '30.714722',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'KK620',
            Type: 'Normal',
            Latitude: '50.412639',
            Longitude: '30.706111',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'GAML3X',
        Runways: '26',
      },
      {
        Star_Waypoint: [
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KKE02',
            Type: 'Normal',
            Latitude: '50.270139',
            Longitude: '31.368056',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KK840',
            Type: 'Normal',
            Latitude: '50.307222',
            Longitude: '30.203889',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'KK820',
            Type: 'Normal',
            Latitude: '50.390694',
            Longitude: '30.195000',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'GOTA2M',
        Runways: '08',
      },
      {
        Star_Waypoint: [
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'GOTA2P',
        Runways: 'All',
      },
      {
        Star_Waypoint: [
          {
            Name: 'GOTAP',
            Type: 'Normal',
            Latitude: '50.255000',
            Longitude: '31.820000',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KKE02',
            Type: 'Normal',
            Latitude: '50.270139',
            Longitude: '31.368056',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'BRP',
            Type: 'Normal',
            Latitude: '50.285694',
            Longitude: '30.900972',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KK630',
            Type: 'Normal',
            Latitude: '50.329167',
            Longitude: '30.714722',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'KK620',
            Type: 'Normal',
            Latitude: '50.412639',
            Longitude: '30.706111',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'GOTA3N',
        Runways: '26',
      },
      {
        Star_Waypoint: [
          {
            Name: 'LAPVA',
            Type: 'Normal',
            Latitude: '50.456389',
            Longitude: '28.692500',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KK820',
            Type: 'Normal',
            Latitude: '50.390694',
            Longitude: '30.195000',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
        ],
        Name: 'LAPV1M',
        Runways: '08',
      },
      {
        Star_Waypoint: [
          {
            Name: 'LAPVA',
            Type: 'Normal',
            Latitude: '50.456389',
            Longitude: '28.692500',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KK650',
            Type: 'Normal',
            Latitude: '50.496111',
            Longitude: '30.697500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'KK620',
            Type: 'Normal',
            Latitude: '50.412639',
            Longitude: '30.706111',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'LAPV1N',
        Runways: '26',
      },
      {
        Star_Waypoint: [
          {
            Name: 'LAPVA',
            Type: 'Normal',
            Latitude: '50.456389',
            Longitude: '28.692500',
            Speed: '0',
            Altitude: '9030',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
        ],
        Name: 'LAPV1P',
        Runways: 'All',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NANIR',
            Type: 'Normal',
            Latitude: '50.833333',
            Longitude: '30.261667',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KK830',
            Type: 'Normal',
            Latitude: '50.474167',
            Longitude: '30.186111',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'KK820',
            Type: 'Normal',
            Latitude: '50.390694',
            Longitude: '30.195000',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
        ],
        Name: 'NANI2M',
        Runways: '08',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NANIR',
            Type: 'Normal',
            Latitude: '50.833333',
            Longitude: '30.261667',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.540961',
            Longitude: '30.438517',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '137',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '358',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'D304W',
            Type: 'Normal',
            Latitude: '50.540961',
            Longitude: '30.438517',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'NANI2P',
        Runways: 'All',
      },
      {
        Star_Waypoint: [
          {
            Name: 'NANIR',
            Type: 'Normal',
            Latitude: '50.833333',
            Longitude: '30.261667',
            Speed: '0',
            Altitude: '11000',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KK660',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '30.550000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'KK650',
            Type: 'Normal',
            Latitude: '50.496111',
            Longitude: '30.697500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KK620',
            Type: 'Normal',
            Latitude: '50.412639',
            Longitude: '30.706111',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'NANI3N',
        Runways: '26',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KK820',
            Type: 'Normal',
            Latitude: '50.390694',
            Longitude: '30.195000',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
        ],
        Name: 'SLV2M',
        Runways: '08',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
        ],
        Name: 'SLV2P',
        Runways: 'All',
      },
      {
        Star_Waypoint: [
          {
            Name: 'SLV',
            Type: 'Normal',
            Latitude: '50.186806',
            Longitude: '29.570139',
            Speed: '0',
            Altitude: '7060',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KKS01',
            Type: 'Normal',
            Latitude: '50.303889',
            Longitude: '30.515556',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'KK630',
            Type: 'Normal',
            Latitude: '50.329167',
            Longitude: '30.714722',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KK620',
            Type: 'Normal',
            Latitude: '50.412639',
            Longitude: '30.706111',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'SLV3N',
        Runways: '26',
      },
      {
        Star_Waypoint: [
          {
            Name: 'TUVOG',
            Type: 'Normal',
            Latitude: '50.946389',
            Longitude: '30.977778',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KKN01',
            Type: 'Normal',
            Latitude: '50.864722',
            Longitude: '30.556667',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'KK830',
            Type: 'Normal',
            Latitude: '50.474167',
            Longitude: '30.186111',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KK820',
            Type: 'Normal',
            Latitude: '50.390694',
            Longitude: '30.195000',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
        ],
        Name: 'TUVO2M',
        Runways: '08',
      },
      {
        Star_Waypoint: [
          {
            Name: 'TUVOG',
            Type: 'Normal',
            Latitude: '50.946389',
            Longitude: '30.977778',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'SLV50',
            Type: 'Normal',
            Latitude: '50.729064',
            Longitude: '30.567117',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: '(INTC)',
            Type: 'Intc',
            Latitude: '50.540961',
            Longitude: '30.438517',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Hdg_Crs: '0',
            Hdg_Crs_value: '222',
            Flytype: 'Fly-by',
            BankLimit: '25',
            RadialtoIntercept: '358',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'D304W',
            Type: 'Normal',
            Latitude: '50.540961',
            Longitude: '30.438517',
            Speed: '0',
            Altitude: '4930',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'TUVO2P',
        Runways: 'All',
      },
      {
        Star_Waypoint: [
          {
            Name: 'TUVOG',
            Type: 'Normal',
            Latitude: '50.946389',
            Longitude: '30.977778',
            Speed: '0',
            Altitude: '10010',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'KKN01',
            Type: 'Normal',
            Latitude: '50.864722',
            Longitude: '30.556667',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'KK660',
            Type: 'Normal',
            Latitude: '50.677778',
            Longitude: '30.550000',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: 'KK650',
            Type: 'Normal',
            Latitude: '50.496111',
            Longitude: '30.697500',
            Speed: '220',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'KK620',
            Type: 'Normal',
            Latitude: '50.412639',
            Longitude: '30.706111',
            Speed: '220',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '5',
          },
        ],
        Name: 'TUVO3N',
        Runways: '26',
      },
    ],
    Approach: [
      {
        App_Waypoint: [
          {
            Name: 'CF08',
            Type: 'Normal',
            Latitude: '50.393931',
            Longitude: '30.272856',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'FF08',
            Type: 'Normal',
            Latitude: '50.395092',
            Longitude: '30.298839',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'RW08',
            Type: 'Runway',
            Latitude: '50.401161',
            Longitude: '30.436919',
            Speed: '0',
            Altitude: '637',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: '(2300)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '5',
          },
        ],
        App_Transition: [
          {
            AppTr_Waypoint: [
              {
                Name: 'KK820',
                Type: 'Normal',
                Latitude: '50.390694',
                Longitude: '30.195000',
                Speed: '0',
                Altitude: '2300',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
                ID: '1',
              },
            ],
            Name: 'KK820',
          },
          {
            AppTr_Waypoint: [
              {
                Name: 'ZK',
                Type: 'Normal',
                Latitude: '50.400417',
                Longitude: '30.419222',
                Speed: '220',
                Altitude: '2960',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
                ID: '1',
              },
              {
                Name: 'ZK',
                Type: 'PBD',
                Latitude: '50.400417',
                Longitude: '30.419222',
                Speed: '0',
                Altitude: '2300',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '226',
                Flytype: 'Fly-over',
                BankLimit: '25',
                DMEtoIntercept: '007',
                Sp_Turn: 'Auto',
                ID: '2',
              },
            ],
            Name: 'ZK1',
          },
          {
            AppTr_Waypoint: [
              {
                Name: 'ZK',
                Type: 'Normal',
                Latitude: '50.400417',
                Longitude: '30.419222',
                Speed: '220',
                Altitude: '2960',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
                ID: '1',
              },
              {
                Name: 'ZK',
                Type: 'PBD',
                Latitude: '50.400417',
                Longitude: '30.419222',
                Speed: '0',
                Altitude: '2300',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '245',
                Flytype: 'Fly-over',
                BankLimit: '25',
                DMEtoIntercept: '007',
                Sp_Turn: 'Auto',
                ID: '2',
              },
            ],
            Name: 'ZK2',
          },
        ],
        Name: 'ILS08',
      },
      {
        App_Waypoint: [
          {
            Name: 'KK620',
            Type: 'Normal',
            Latitude: '50.412639',
            Longitude: '30.706111',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'FF26',
            Type: 'Normal',
            Latitude: '50.408061',
            Longitude: '30.601258',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'RW26',
            Type: 'Runway',
            Latitude: '50.402431',
            Longitude: '30.466569',
            Speed: '0',
            Altitude: '640',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: '(2300)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '5',
          },
        ],
        Name: 'ILS26',
      },
      {
        App_Waypoint: [
          {
            Name: 'FF08',
            Type: 'Normal',
            Latitude: '50.395092',
            Longitude: '30.298839',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '827',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'RW08',
            Type: 'Runway',
            Latitude: '50.401161',
            Longitude: '30.436919',
            Speed: '0',
            Altitude: '637',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: '(2300)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '079',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Right',
            ID: '5',
          },
        ],
        App_Transition: [
          {
            AppTr_Waypoint: [
              {
                Name: 'ZK',
                Type: 'Normal',
                Latitude: '50.400417',
                Longitude: '30.419222',
                Speed: '220',
                Altitude: '2960',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
                ID: '1',
              },
              {
                Name: 'ZK',
                Type: 'PBD',
                Latitude: '50.400417',
                Longitude: '30.419222',
                Speed: '0',
                Altitude: '2300',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '226',
                Flytype: 'Fly-over',
                BankLimit: '25',
                DMEtoIntercept: '005',
                Sp_Turn: 'Auto',
                ID: '2',
              },
            ],
            Name: 'ZK1',
          },
          {
            AppTr_Waypoint: [
              {
                Name: 'ZK',
                Type: 'Normal',
                Latitude: '50.400417',
                Longitude: '30.419222',
                Speed: '220',
                Altitude: '2960',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Flytype: 'Fly-by',
                BankLimit: '25',
                Sp_Turn: 'Auto',
                ID: '1',
              },
              {
                Name: 'ZK',
                Type: 'PBD',
                Latitude: '50.400417',
                Longitude: '30.419222',
                Speed: '0',
                Altitude: '2300',
                AltitudeCons: '0',
                AltitudeRestriction: 'above',
                Hdg_Crs: '0',
                Hdg_Crs_value: '245',
                Flytype: 'Fly-over',
                BankLimit: '25',
                DMEtoIntercept: '006',
                Sp_Turn: 'Auto',
                ID: '2',
              },
            ],
            Name: 'ZK2',
          },
        ],
        Name: 'NDB08',
      },
      {
        App_Waypoint: [
          {
            Name: 'FF26',
            Type: 'Normal',
            Latitude: '50.408061',
            Longitude: '30.601258',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '1',
          },
          {
            Name: 'RW26',
            Type: 'Runway',
            Latitude: '50.402431',
            Longitude: '30.466569',
            Speed: '0',
            Altitude: '640',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '2',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '0',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-over',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '3',
          },
          {
            Name: '(2300)',
            Type: 'ConstHdgtoAlt',
            Latitude: '0.000000',
            Longitude: '0.000000',
            Speed: '0',
            Altitude: '2300',
            AltitudeCons: '0',
            AltitudeRestriction: 'above',
            Hdg_Crs: '0',
            Hdg_Crs_value: '259',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Auto',
            ID: '4',
          },
          {
            Name: 'ZK',
            Type: 'Normal',
            Latitude: '50.400417',
            Longitude: '30.419222',
            Speed: '0',
            Altitude: '2960',
            AltitudeCons: '0',
            AltitudeRestriction: 'at',
            Flytype: 'Fly-by',
            BankLimit: '25',
            Sp_Turn: 'Left',
            ID: '5',
          },
        ],
        Name: 'NDB26',
      },
    ],
  },
]
