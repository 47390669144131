import React from 'react'
import Window from '../Window'
import { useCwpContext } from '../../../../../contexts/CwpContext'

function FAltWindow(props) {
  const { setPttWindows, fAlt, setFAlt } = useCwpContext()

  const handleChangeUpper = (event) => {
    setFAlt((prev) => {
      return {
        ...prev,
        upper: +event.target.value * -1,
        ...(prev.lower > +event.target.value * -1
          ? { lower: +event.target.value * -1 }
          : {}),
      }
    })
  }

  const handleChangeLower = (event) => {
    setFAlt((prev) => ({
      ...prev,
      lower: +event.target.value,
      ...(prev.upper < +event.target.value
        ? { upper: +event.target.value }
        : {}),
    }))
  }

  return (
    <Window
      label="#1"
      width="fit-content"
      maxContentHeight="300px"
      toggleWindow={() =>
        setPttWindows((prev) => ({ ...prev, fAltWindow: false }))
      }
    >
      <>
        <div>
          <span
            style={{
              display: 'inline-block',
              width: '30px',
              textAlign: 'right',
            }}
          >
            {fAlt.upper}
          </span>
          <input
            type="range"
            min={-660}
            max={0}
            step={5}
            style={{
              appearance: 'slider-vertical',
              width: '15px',
              transform: 'rotateX(180deg)',
            }}
            value={fAlt.upper * -1}
            onChange={handleChangeUpper}
          />
        </div>
        <div>
          <span
            style={{
              display: 'inline-block',
              width: '30px',
              textAlign: 'right',
            }}
          >
            {fAlt.lower}
          </span>
          <input
            type="range"
            min={0}
            max={660}
            step={5}
            style={{ appearance: 'slider-vertical', width: '15px' }}
            value={fAlt.lower}
            onChange={handleChangeLower}
          />
        </div>
      </>
    </Window>
  )
}

export default FAltWindow
