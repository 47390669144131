/*
    node_js
    2/1/2021 7:11 PM
    by Oleksandr
*/

import React, { useEffect } from 'react'
import { useCwpContext } from '../../contexts/CwpContext'
import FDP from './FDP'

const ATG = () => {
  const {
    tickValue,
    setTickValue,
    timeValue,
    setTimeValue,
    tickSpeed,
    tickPause,
  } = useCwpContext()

  useEffect(() => {
    const interval = setInterval(() => {
      if (!tickPause) {
        setTimeValue(timeValue + 1)
        if (timeValue % 4 === 3) {
          setTimeValue((tickValue + 1) * 4)
          setTickValue(tickValue + 1)
        }
      }
    }, tickSpeed)
    return () => clearInterval(interval)
  }, [timeValue, setTimeValue, tickValue, setTickValue, tickSpeed, tickPause])

  return <FDP />
}

export default ATG
