import React, { forwardRef } from 'react'
import EditRoutePath from '../../route/editRoutePath'
import Rbls from '../../rbl/Rbls'
import ADF from '../../ADF'
import { useSelector } from 'react-redux'

function SvgSubLayer(
  {
    trackPlanLines,
    flightTargetHistory,
    lines,
    rblsArray,
    projection,
    tickValue,
    flights,
    deleteRbl,
    minSepRbl,
    getEditRoutePath,
  },
  ref,
) {
  const reduxComm = useSelector((state) => state.comm)
  return (
    <svg width="2000" height="2000" id={'trackLayerSvg'} ref={ref}>
      <g id={'trackPlanLines'}>{trackPlanLines}</g>
      <g id={'flightTargetHistory'}></g>
      {/* <g id={'flightTargetHistory'}>{flightTargetHistory}</g> */}
      <g id={'lines'}>{lines}</g>
      <Rbls
        rbls={rblsArray}
        projection={projection}
        tickValue={tickValue}
        flights={flights}
        deleteRbl={deleteRbl}
        minSepRbl={minSepRbl}
      />
      <EditRoutePath editRoutePathArray={getEditRoutePath()} />
      {reduxComm.speaking && <ADF projection={projection} />}
    </svg>
  )
}

export default forwardRef(SvgSubLayer)
