export const columns = [
  'c',
  'type',
  'adep',
  'ades',
  'entryCop',
  'entryTime',
  'exitCop',
  'exitTime',
  'rfl',
]

export const columnsText = [
  'Callsign',
  'TYPE',
  'ADEP',
  'ADES',
  'ENTRY',
  'TIME',
  'EXIT',
  'TIME',
  'RFL',
]

export const historicalColumns = [
  'c',
  'type',
  'adep',
  'ades',
  'entryCop',
  'exitCop',
  'rfl',
]

export const historicalColumnsText = [
  'Callsign',
  'TYPE',
  'ADEP',
  'ADES',
  'ENTRY',
  'EXIT',
  'RFL',
]

export const sectorsList = {
  sectorLVCLVU1: {
    ops: {
      name: 'uklv',
      sector: 'ALVC',
      allowance: 'LVC+LVU',
      basicSectors: ['E345', 'W345', 'C355', 'C365', 'C375', 'C385', 'C660'],
      freq: '135.600',
      vLimit: { lower: 32500, upper: 66000 },
      maxTV: 35,
    },
  },
  sectorLVCLVU2: {
    ops: {
      name: 'uklv',
      sector: 'ALVC',
      allowance: 'LVC+LVU',
      basicSectors: ['C355', 'C365', 'C375', 'C385', 'C660'],
      freq: '135.600',
      vLimit: { lower: 34500, upper: 66000 },
      maxTV: 38,
    },
  },
  sectorLVU0: {
    ops: {
      name: 'uklv',
      sector: 'ALVU',
      allowance: 'LVU',
      basicSectors: ['C660'],
      freq: '134.050',
      vLimit: { lower: 38500, upper: 66000 },
      maxTV: 32,
    },
  },
  sectorLVU1: {
    ops: {
      name: 'uklv',
      sector: 'ALVU',
      allowance: 'LVU',
      basicSectors: ['C385', 'C660'],
      freq: '134.050',
      vLimit: { lower: 37500, upper: 66000 },
      maxTV: 32,
    },
  },
  sectorLVU2: {
    ops: {
      name: 'uklv',
      sector: 'ALVU',
      allowance: 'LVU',
      basicSectors: ['C375', 'C385', 'C660'],
      freq: '134.050',
      vLimit: { lower: 36500, upper: 66000 },
      maxTV: 34,
    },
  },
  sectorLVU3: {
    ops: {
      name: 'uklv',
      sector: 'ALVU',
      allowance: 'LVU',
      basicSectors: ['C365', 'C375', 'C385', 'C660'],
      freq: '134.050',
      vLimit: { lower: 35500, upper: 66000 },
      maxTV: 33,
    },
  },
  sectorLVC0: {
    ops: {
      name: 'uklv',
      sector: 'ALVC',
      allowance: 'LVC',
      basicSectors: ['C355', 'C365', 'C375', 'C385'],
      freq: '135.600',
      vLimit: { lower: 34500, upper: 38500 },
      maxTV: 35,
    },
  },
  sectorLVC1: {
    ops: {
      name: 'uklv',
      sector: 'ALVC',
      allowance: 'LVC',
      basicSectors: ['C355', 'C365', 'C375'],
      freq: '135.600',
      vLimit: { lower: 34500, upper: 37500 },
      maxTV: 33,
    },
  },
  sectorLVC2: {
    ops: {
      name: 'uklv',
      sector: 'ALVC',
      allowance: 'LVC',
      basicSectors: ['C355', 'C365'],
      freq: '135.600',
      vLimit: { lower: 34500, upper: 36500 },
      maxTV: 31,
    },
  },
  sectorLVC3: {
    ops: {
      name: 'uklv',
      sector: 'ALVC',
      allowance: 'LVC',
      basicSectors: ['E345', 'W345', 'C355', 'C365', 'C375'],
      freq: '135.600',
      vLimit: { lower: 32500, upper: 37500 },
      maxTV: 29,
    },
  },
  sectorLVC4: {
    ops: {
      name: 'uklv',
      sector: 'ALVC',
      allowance: 'LVC',
      basicSectors: ['E345', 'W345', 'C355', 'C365'],
      freq: '135.600',
      vLimit: { lower: 32500, upper: 36500 },
      maxTV: 29,
    },
  },
  sectorLVC5: {
    ops: {
      name: 'uklv',
      sector: 'ALVC',
      allowance: 'LVC',
      basicSectors: ['E345', 'W345', 'C355'],
      freq: '135.600',
      vLimit: { lower: 32500, upper: 35500 },
      maxTV: 31,
    },
  },
  sectorLVELVW1: {
    ops: {
      name: 'uklv',
      sector: 'ALVW',
      allowance: 'LVE+LVW',
      basicSectors: ['E325', 'E345', 'W325', 'W345'],
      freq: '118.675',
      vLimit: { lower: 5500, upper: 34500 },
      maxTV: 31,
    },
  },
  sectorLVELVW2: {
    ops: {
      name: 'uklv',
      sector: 'ALVW',
      allowance: 'LVE+LVW',
      basicSectors: ['E325', 'W325'],
      freq: '118.675',
      vLimit: { lower: 5500, upper: 32500 },
      maxTV: 31,
    },
  },
  sectorLVE1: {
    ops: {
      name: 'lve',
      sector: 'ALVE',
      allowance: 'LVE',
      basicSectors: ['E325', 'E345'],
      freq: '125.650',
      vLimit: { lower: 5500, upper: 34500 },
      maxTV: 31,
    },
  },
  sectorLVE2: {
    ops: {
      name: 'lve',
      sector: 'ALVE',
      allowance: 'LVE',
      basicSectors: ['E325'],
      freq: '125.650',
      vLimit: { lower: 5500, upper: 32500 },
      maxTV: 31,
    },
  },
  sectorLVW1: {
    ops: {
      name: 'lvw',
      sector: 'ALVW',
      allowance: 'LVW',
      basicSectors: ['W325', 'W345'],
      freq: '118.675',
      vLimit: { lower: 5500, upper: 34500 },
      maxTV: 31,
    },
  },
  sectorLVW2: {
    ops: {
      name: 'lvw',
      sector: 'ALVW',
      allowance: 'LVW',
      basicSectors: ['W325'],
      freq: '118.675',
      vLimit: { lower: 5500, upper: 32500 },
      maxTV: 31,
    },
  },
  sectorLVT: {
    ops: {
      name: 'lvt',
      sector: 'ALVT',
      allowance: 'LVT',
      basicSectors: ['LVTM'],
      freq: '120.525',
      vLimit: { lower: 2500, upper: 16500 },
      maxTV: 25,
    },
  },
  sectorBVL: {
    ops: {
      name: 'bvl',
      sector: 'BVL',
      allowance: 'BVL',
      basicSectors: ['BVL'],
      freq: '135.825',
      vLimit: { lower: 6000, upper: 66000 },
      maxTV: 27,
      callsign: 'Kyiv Radar',
    },
  },
  sectorBVO: {
    ops: {
      name: 'bvo',
      sector: 'BVO',
      allowance: 'BVO',
      basicSectors: ['BVO'],
      freq: '131.375',
      vLimit: { lower: 6000, upper: 66000 },
      maxTV: 27,
      callsign: 'Kyiv Radar',
    },
  },
  sectorBV1: {
    ops: {
      name: 'bv1',
      sector: 'BV1',
      allowance: 'BV1',
      basicSectors: ['BV1'],
      freq: '127.725',
      vLimit: { lower: 3000, upper: 66000 },
      maxTV: 38,
      callsign: 'Kyiv Radar',
    },
  },
}
