/*
    node_js
    2/22/2021 7:15 PM
    by Oleksandr
*/
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import auth from '../auth/Auth'

export const ProtectedRoute = (props) => {
  return (
    <Route {...props}>
      {auth.isAuthenticated() ? (
        props.children
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: {
              from: props.location,
            },
          }}
        />
      )}
    </Route>
  )
}
