/*
    node_js
    2/1/2021 11:24 PM
    by Oleksandr
*/
import React, { useCallback, useEffect, useMemo } from 'react'
import * as d3 from 'd3'

import { uklvArray, borderPoints, extFreq } from '../../../../config/adap/map'
import {
  ukll_app_rw13ils,
  ukll_app_rw13star,
  ukll_app_rw31ils,
  ukll_app_rw31star,
  ukln_app_rw15star,
  ukln_app_rw33star,
} from '../../../../config/adap/ukll_app'

import {
  getFrequencies,
  getPoints,
  getIlsLines,
  getStarLines,
} from '../../../../utils/calcGeometry'

import '../../CWP.css'
import { useCwpContext } from '../../../../contexts/CwpContext'

import { useSelector } from 'react-redux'
import { rte } from '../../../../config/adap/rte'

const stars = {
  ukll_app_rw13star,
  ukll_app_rw31star,
  ukln_app_rw15star,
  ukln_app_rw33star,
}

const ils = {
  ukll_app_rw13ils,
  ukll_app_rw31ils,
}

const MapLayer = () => {
  const { zoom, projection, map, sectors, lonLatCenter } = useCwpContext()
  const reduxMap = useSelector((state) => state.map)

  const arrival_lines = useMemo(
    () => getStarLines(stars, projection),
    [stars, projection],
  )
  const ils_lines = useMemo(
    () => getIlsLines(ils, projection),
    [stars, projection],
  )

  const border_points = useMemo(
    () => getPoints(borderPoints, projection),
    [borderPoints, projection],
  )

  const external_frequencies = useMemo(
    () => getFrequencies(extFreq, projection),
    [extFreq, projection],
  )

  const path = d3.geoPath(projection)

  const redraw = useCallback(() => {
    let svg = d3.select('#borderGroup').selectAll('path')
    svg.remove()

    svg = d3.select('#borderGroup').selectAll('path').data(uklvArray)

    svg
      .enter()
      .append('path')
      .attr('d', path)
      .style('fill', (d) =>
        d.name === sectors.ops.name
          ? 'rgba(78, 74, 74, 1)'
          : 'rgba(73, 70, 70,0)',
      )
      .style('stroke', 'rgb(150,150,150)')
      .style('stroke-width', 0.5)

    let svg2 = d3.select('#rteGroup').selectAll('path')
    svg2.remove()

    svg2 = d3.select('#rteGroup').selectAll('path').data(rte)

    svg2
      .enter()
      .append('path')
      .attr('d', path)
      .style('fill', 'rgba(73, 70, 70,0)')
      .style('stroke', 'rgba(15,15,15,0.5)')
      .style('stroke-width', 0.3)
  }, [sectors, zoom, lonLatCenter, reduxMap[7]])

  useEffect(() => {
    redraw()
  }, [redraw])

  return (
    <div
      style={{ position: 'absolute', left: '0px', top: '0px' }}
      id="mapSvgDiv"
    >
      <svg width="2000" height="2000" id="mapSvg">
        <g id="borderGroup" />
        {reduxMap[7].isVisible && <g id="rteGroup" />}
        <symbol id="myDot" width="4" height="4">
          <polygon points="0,4 4,4 2,0" />
        </symbol>
        {reduxMap[3].isVisible && ils_lines.ukll_app_rw31ils}
        {reduxMap[1].isVisible && ils_lines.ukll_app_rw13ils}
        {reduxMap[4].isVisible && arrival_lines.ukll_app_rw31star}
        {reduxMap[2].isVisible && arrival_lines.ukll_app_rw13star}
        {reduxMap[5].isVisible && arrival_lines.ukln_app_rw15star}
        {reduxMap[6].isVisible && arrival_lines.ukln_app_rw33star}
        {reduxMap[0].isVisible && border_points}
        {reduxMap[8].isVisible && external_frequencies}
      </svg>
    </div>
  )
}

export default MapLayer
