/*
    node_js
    5/15/2021 9:12 PM
    by Oleksandr
*/

import { calcApproach, calcFlight } from '../calc/calc'
const getState = (state, index, flight, value, type) => {
  const lastClearance = [
    index,
    flight.id,
    value,
    type,
    flight.c,
    flight.flight[index].isAssumed,
    flight.flight[index].a,
    flight.flight[index].nPN,
    flight.flight[index].cP,
    flight.pitch,
    flight.comm.freq,
    flight.comm,
  ]
  return {
    ...state,
    [flight.id]: [
      ...state[flight.id].filter((clearence) => clearence[0] <= index),
      lastClearance,
    ],
    lastClearance,
  }
}
const flightClearanceReducer = (state, action) => {
  const { type, value, flight, index, startDate } = action
  if (flight && !state[flight.id]) state[flight.id] = []
  switch (type) {
    case 'sfl':
    case 'cfl':
      flight.flight[index].sA = value * 100
      calcFlight(flight, index, startDate.getTime())
      return getState(state, index, flight, value, type)
    case 'spd':
      flight.flight[index].sS = value
      flight.flight[index].isOS = true
      calcFlight(flight, index, startDate.getTime())
      return getState(state, index, flight, value, type)
    case 'mach':
      flight.flight[index].M = value
      flight.flight[index].isOS = true
      calcFlight(flight, index, startDate.getTime())
      return getState(state, index, flight, value, type)
    case 'std':
      flight.flight[index].isOS = false
      calcFlight(flight, index, startDate.getTime())
      return getState(state, index, flight, value, type)
    case 'hdg':
      flight.flight[index].sH = parseInt(value)
      flight.flight[index].isOH = true
      flight.flight[index].isOO = false
      flight.flight[index].isOSt = false
      calcFlight(flight, index, startDate.getTime())
      return getState(state, index, flight, value, type)
    case 'dct':
      let ronPointIndex = flight.fpl[10].map((e) => e.name).indexOf(value)
      if (ronPointIndex >= 0) {
        flight.flight[index].nP = ronPointIndex
        flight.flight[index].isOH = false
      }
      calcFlight(flight, index, startDate.getTime())
      return getState(state, index, flight, value, type)
    case 'ga':
      flight.flight[index].isGA = true
      calcApproach(flight, value)
      calcFlight(flight, index, startDate.getTime())
      return getState(state, index, flight, value, type)
    case 'ukll':
      flight.ades = 'UKLL'
      flight.fpl[10].push({
        isAdes: true,
        latitude: 49.809722,
        longitude: 23.958333,
        name: 'UKLL',
      })
      flight.flight[index].nP = flight.fpl[10].length - 1
      calcApproach(flight, value)
      calcFlight(flight, index, startDate.getTime())
      return getState(state, index, flight, value, type)
    case 'assume':
      return getState(state, index, flight, value, type)
    case 'clear':
      return {}

    default:
      throw new Error()
  }
}

export default flightClearanceReducer
