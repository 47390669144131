export const calc = {
  toRadians: (d) => {
    return d * (Math.PI / 180)
  },
  toDegrees: (r) => {
    return r / (Math.PI / 180)
  },
}

export const getDiffBetweenAngles = (a, b) => {
  const x = calc.toRadians(a % 360)
  const y = calc.toRadians(b % 360)
  return calc.toDegrees(
    Math.acos(Math.cos(x) * Math.cos(y) + Math.sin(y) * Math.sin(x)),
  )
}

export const LatToY = (lat0, zoom) => {
  let lat =
    (180 / Math.PI) *
    Math.log(Math.tan(Math.PI / 4 + (lat0 * (Math.PI / 180)) / 2))
  let lat1 =
    (180 / Math.PI) *
    Math.log(Math.tan(Math.PI / 4 + (49.2 * (Math.PI / 180)) / 2))
  return 500 + (lat1 - lat) * (50000 / zoom)
}
export const LonToX = (lon, zoom) => {
  return 500 + (lon - 23) * (50000 / zoom)
}
export const YToLat = (y, zoom) => {
  let lat1 =
    (180 / Math.PI) *
    Math.log(Math.tan(Math.PI / 4 + (49.2 * (Math.PI / 180)) / 2))
  let lat = (LatToY(49.2, zoom) - y) / (50000 / zoom) + 49.2
  return lat
}

export const XToLon = (x, zoom) => {
  let lon = -1 * ((LonToX(23, zoom) - x) / (50000 / zoom) - 23)
  return lon
}
export const xmlToLat = (tempLat) => {
  return (
    Number(parseInt(tempLat.substr(1, 3))) +
    Number(parseInt(tempLat.substr(5, 2)) / 60) +
    Number(parseInt(tempLat.substr(8, 2)) / 3600)
  )
}

export const xmlToLon = (tempLon) => {
  return (
    Number(parseInt(tempLon.substr(1, 3))) +
    Number(parseInt(tempLon.substr(5, 2)) / 60) +
    Number(parseInt(tempLon.substr(8, 2)) / 3600)
  )
}
