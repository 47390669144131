/*
    node_js
    2/10/2021 6:59 AM
    by Oleksandr
*/

import React from 'react'
import { Link } from 'react-router-dom'

const Home = ({ setLogin }) => {
  return (
    <div>
      <div className={'headerContainer'}>
        <Link
          to="/"
          className={'routerLink'}
          style={{ width: '300px', textAlign: 'center' }}
        >
          Hello, {localStorage.getItem('user_display_name')}
        </Link>
        <span />
        <Link
          to="/"
          className={'routerLink'}
          style={{ width: '100px', textAlign: 'left' }}
          onClick={() => {
            setLogin(false)
            localStorage.clear()
          }}
        >
          Logout
        </Link>
      </div>
      <div style={{ height: '100vh', width: '100vw', position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: 'calc(50% - 50px)',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: 0,
          }}
        >
          <Link to="/epp" className={'routerLink homeLinkButton buttonSDD'}>
            Exercise Preparation Tool
          </Link>
          <Link to="/plt" className={'routerLink buttonSDD homeLinkButton'}>
            Run Exercise
          </Link>
          <Link to="/con" className={'routerLink buttonSDD homeLinkButton'}>
            Connect to session
          </Link>
          <Link to="/demo" className={'routerLink buttonSDD homeLinkButton'}>
            Demo
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Home
