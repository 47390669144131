/*
    node_js
    3/15/2021 9:15 AM
    by Oleksandr
*/

import React, { useState } from 'react'
import Window from './Window'
import ActiveFlightsList from './lists/ActiveFlightsList'
import { useCwpContext } from '../../../../contexts/CwpContext'
import { columns, columnsText } from '../../../../utils/constants'

const ActiveWindow = ({ isEpp, isPlt, isCon }) => {
  const {
    flights,
    setFlights,
    setPttWindows,
    startDate,
    tickValue,
    updateFlightForEpp,
  } = useCwpContext()

  const filterFlights = () =>
    flights.filter((flt) => {
      let index = isCon ? 0 : tickValue + flt.timeShift
      if (!flt.flight[index]) return false
      const tempFlt = isCon ? flt : flt.flight[index]
      return tempFlt.isAssumed || tempFlt.isAdvanced
    })

  const [actSort, setActSort] = useState('rfl')
  const sort = (arr) =>
    arr.sort((a, b) =>
      a[actSort] > b[actSort] ? 1 : b[actSort] > a[actSort] ? -1 : 0,
    )

  let stats = {}
  let statsFlights = {}
  flights.forEach((flt) => {
    flt.sectors.forEach((sector) => {
      if (stats[sector]) {
        stats[sector] += 1
        statsFlights[sector].push(flt.c)
      } else {
        stats[sector] = 1
        statsFlights[sector] = []
        statsFlights[sector].push(flt.c)
      }
    })
  })

  return (
    <Window
      isVisible={true}
      toggleWindow={() => {
        isEpp
          ? setPttWindows((prev) => ({ ...prev, activeWindow: false }))
          : setPttWindows((prev) => ({ ...prev, plannerWindow: false }))
      }}
      label={
        isEpp
          ? 'Active Flights (' + flights.length + ')'
          : isPlt || isCon
          ? 'Planner (' + flights.length + ')'
          : ''
      }
      width={isEpp ? 650 : 800}
    >
      <div>
        <table>
          <thead>
            <tr>
              {columns.map((heading, index) => (
                <th
                  className="filterHistFlights headerTh"
                  onClick={() => setActSort(heading)}
                  key={`th-${index}`}
                >
                  {columnsText[index]}
                </th>
              ))}
              {isEpp && (
                <>
                  <th className="headerTh">XFL</th>
                  <th className="headerTh" />
                </>
              )}
            </tr>
          </thead>

          <ActiveFlightsList
            flights={isCon ? sort(filterFlights()) : sort(flights)}
            startDate={startDate}
            isEpp={isEpp}
            isPlt={isPlt}
            isCon={isCon}
            tickValue={tickValue}
            updateFlightForEpp={updateFlightForEpp}
            setFlights={setFlights}
          />
        </table>
      </div>
    </Window>
  )
}

export default ActiveWindow
