/*
    node_js
    4/2/2021 1:21 AM
    by Oleksandr
*/
import React, { useEffect, useRef, useState } from 'react'
import VideoWithVolume from './VideoWithVolume'

// class Video extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {}
//     }
//
//     componentDidMount() {
//         if (this.props.videoStream) {
//             this.video.srcObject = this.props.videoStream
//         }
//
//     }
//     componentWillMount() {
//     }
//
//     componentWillReceiveProps(nextProps) {
//
//         if (nextProps.videoStream && nextProps.videoStream !== this.props.videoStream) {
//             this.video.srcObject = nextProps.videoStream
//         }
//         console.log(this.props.userName)
//         this.setState({userName : (this.props.userName && this.props.userName[0])? this.props.userName[0].name.split('___')[0]:''});
//
//
//     }
//
//
//     render() {
//         return (
//             <VideoWithVolume
//                 key={this.props.userName.name  }
//                 videoStream={this.props.videoStream}
//                 video={<div style={{position:"relative"}}>
//                     <span style={{position:"absolute", bottom: "3px", color:"green", fontSize:"12px"}}>{this.state.userName}</span>
//                     <video
//                     style={{height: "150px", maxWidth: "200px"}}
//                     autoPlay
//                     ref={(ref) => {
//                         this.video = ref
//                     }}
//                 ></video>
//                 </div>}
//             />
//         )
//     }
// }

const Video = (props) => {
  const { videoStream, userName } = props
  const videoStreamPrev = useRef(videoStream)
  const [userNameText, setUserNameText] = useState('')
  const videoRef = React.useRef()

  // useEffect(() => {
  //     if (videoStream) {
  //         console.log(videoRef);
  //         videoRef.srcObject = videoStream
  //     }
  // }, [])

  useEffect(() => {
    if (videoRef.current && videoStream && videoStream !== videoStreamPrev) {
      videoRef.current.srcObject = videoStream
    }
  }, [videoStream])

  useEffect(() => {
    setUserNameText(
      userName && userName[0] ? userName[0].name.split('___')[0] : '',
    )
  }, [userName])

  return (
    <VideoWithVolume
      key={userName.name}
      videoStream={videoStream}
      video={
        <div style={{ position: 'relative', background: 'black' }}>
          <span
            style={{
              position: 'absolute',
              bottom: '3px',
              color: 'green',
              fontSize: '12px',
            }}
          >
            {userNameText}
          </span>
          <video
            style={{ height: '150px', maxWidth: '200px' }}
            autoPlay
            ref={videoRef}
          ></video>
        </div>
      }
    />
  )
}

export default Video
