/*
    node_js
    3/5/2021 6:14 AM
    by Oleksandr
*/

import Window from '../Window'
import React from 'react'

const DemoMsgWindow = () => (
  <Window
    style={{ top: '30%', right: '0px' }}
    label="Demo messages"
    hideCloseButton={true}
  >
    <>
      <span>Тут будуть відображатися різні повідомлення та пояснення.</span>
      <br />
      <img
        width="500"
        src="https://cdn.jetphotos.com/full/6/81540_1442161473.jpg"
        alt="airport"
      />
    </>
  </Window>
)

export default DemoMsgWindow
