/*
    node_js
    3/12/2021 5:50 AM
    by Oleksandr
*/

import Window from '../Window'
import React from 'react'
import '../../../CWP.css'
import SpeedVectorWindow from './SpeedVectorWindow'
import MapWindow from './MapWindow'
import { useCwpContext } from '../../../../../contexts/CwpContext'
import FAltWindow from '../modalWindows/FAltWindow'

const AswToolbox = () => {
  const {
    pttWindows,
    setPttWindows,
    speedVector,
    setLabelSettings,
    labelSettings,
  } = useCwpContext()
  return (
    <>
      <Window
        isVisible={true}
        toggleWindow={() =>
          setPttWindows((prev) => ({ ...prev, aswToolbox1Window: false }))
        }
        label={'Asw Toolbox #1'}
        width={'auto'}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto auto auto',
            gridGap: '3px',
            color: 'grey',
            textAlign: 'center',
            padding: '3px',
          }}
        >
          <span className={`buttonSDD buttonSDDdisabled`}>Trail 10</span>
          <span className={`buttonSDD buttonSDDdisabled`}>R&B Track</span>
          <span
            className={`buttonSDD ${
              pttWindows.mapWindow ? 'buttonSDDpressed' : ''
            }`}
            onClick={() =>
              setPttWindows({
                ...pttWindows,
                mapWindow: !pttWindows.mapWindow,
              })
            }
          >
            Map
          </span>
          <span
            className={`buttonSDD ${
              pttWindows.speedVectorWindow ? 'buttonSDDpressed' : ''
            }`}
            onClick={() =>
              setPttWindows({
                ...pttWindows,
                speedVectorWindow: !pttWindows.speedVectorWindow,
              })
            }
          >
            Vector {speedVector.time}
          </span>
          <select
            onInput={(e) => {
              setLabelSettings({ fontSize: e.target.value })
            }}
          >
            <option selected={labelSettings.fontSize === 10} value={10}>
              Font1
            </option>
            <option selected={labelSettings.fontSize === 15} value={15}>
              Font2
            </option>
            <option selected={labelSettings.fontSize === 20} value={20}>
              Font3
            </option>
            <option selected={labelSettings.fontSize === 25} value={25}>
              Font4
            </option>
          </select>
          <span
            className={`buttonSDD ${
              pttWindows.fAltWindow ? 'buttonSDDpressed' : ''
            }`}
            onClick={() =>
              setPttWindows({
                ...pttWindows,
                fAltWindow: !pttWindows.fAltWindow,
              })
            }
          >
            F Alt
          </span>
        </div>
      </Window>
      {pttWindows.mapWindow && <MapWindow />}
      {pttWindows.speedVectorWindow && <SpeedVectorWindow />}
      {pttWindows.fAltWindow && <FAltWindow />}
    </>
  )
}

export default AswToolbox
