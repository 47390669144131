/*
    node_js
    3/19/2021 9:47 AM
    by Oleksandr
*/

import Window from './Window'
import React from 'react'
import { useCwpContext } from '../../../../contexts/CwpContext'

const RwyWindow = () => {
  const { setPttWindows, runways, setRunways } = useCwpContext()

  const handleRwy = (e) => {
    runways[0] = { ICAOcode: 'UKLL', Runways: e.target.value }
    setRunways([...runways, runways])
  }

  return (
    <Window
      label="RUNWAYS"
      width="fit-content"
      toggleWindow={() =>
        setPttWindows((prev) => ({ ...prev, rwyWindow: false }))
      }
    >
      <>
        <span
          className="buttonSDD"
          style={{ width: 'auto', margin: '10px 10px 0' }}
        >
          UKLL
        </span>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '20px auto',
            gridGap: '10px',
            color: 'grey',
            textAlign: 'center',
            padding: '10px',
          }}
        >
          <span>13</span>
          <div className="form_radio_btn">
            <input
              id="radio-1"
              type="radio"
              name="radio"
              value="13"
              checked={runways[0].Runways === '13'}
              onChange={handleRwy}
            />
            <label className="buttonSDD" htmlFor="radio-1">
              Arr/Dep
            </label>
          </div>
          <span>31</span>
          <div className="form_radio_btn">
            <input
              id="radio-2"
              type="radio"
              name="radio"
              value="31"
              checked={runways[0].Runways === '31'}
              onChange={handleRwy}
            />
            <label className="buttonSDD" htmlFor="radio-2">
              Arr/Dep
            </label>
          </div>
        </div>
      </>
    </Window>
  )
}

export default RwyWindow
