/*
    node_js
    2/13/2021 4:40 PM
    by Oleksandr
*/

import React from 'react'
import { createPortal } from 'react-dom'

const TargetHistory = ({ track, color, trackLayerSvg }) => {
  return createPortal(
    <circle stroke={color} cx={track.X} cy={track.Y} r={1.5} fillOpacity="0" />,
    trackLayerSvg.current.children[1],
  )
}

export default TargetHistory
