/*
    node_js
    3/23/2021 9:29 PM
    by Oleksandr
*/

import Window from './Window'
import React, { useEffect } from 'react'
import { EditorState } from 'draft-js'
import { useCwpContext } from '../../../../contexts/CwpContext'

const ExerciseWindow = ({ isPlt, isEpp, isDemo }) => {
  const {
    files,
    getAllSim,
    setExerciseName,
    setFlights,
    setExerciseDescription,
    flights,
    saveExercise,
    pttWindows,
    setPttWindows,
    loadExercise,
    renameExercise,
    deleteExercise,
  } = useCwpContext()

  useEffect(() => {
    getAllSim()
  }, [])

  const getOtherExercises = () => {
    const atm_code = localStorage.getItem('atm_code')
    return (
      <div style={{ overflowY: 'scroll', height: '500px', width: '500px' }}>
        <table style={{ padding: '5px', width: '100%' }}>
          <thead>
            <tr className="headerTh">
              <td colSpan="2" style={{ textAlign: 'center' }}>
                Other Exercises
              </td>
            </tr>
          </thead>
          <tbody>
            {files
              .filter((file) => {
                return file.name.split('_')[0] !== atm_code
              })
              .map((exercise, index) => {
                return (
                  <tr className="sddTableTr" key={index}>
                    <td style={{ paddingRight: '20px' }}>
                      <span>{exercise.name}</span>
                    </td>
                    <td
                      style={{ cursor: 'pointer' }}
                      key={'exercisename' + exercise.name}
                    >
                      <span
                        className="buttonSDD"
                        id={exercise.name}
                        onClick={(e) => {
                          loadExercise(e.target.id, isPlt)
                          pttWindows.systemTimeWindow = true
                          setPttWindows({
                            ...pttWindows,
                            exerciseWindow: false,
                          })
                        }}
                        style={{ width: '50px' }}
                      >
                        Load
                      </span>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    )
  }

  const getMyExercises = () => {
    let atm_code = localStorage.getItem('atm_code')
    return (
      <div style={{ overflowY: 'scroll', height: '500px', width: '500px' }}>
        <table style={{ padding: '5px', width: '100%' }}>
          <thead>
            <tr className="headerTh">
              <td colSpan="4" style={{ textAlign: 'center' }}>
                My Exercises
              </td>
            </tr>
          </thead>
          <tbody>
            {files
              .filter((file) => {
                return file.name.split('_')[0] === atm_code
              })
              .map((exercise, index) => {
                return (
                  <tr className="sddTableTr" key={index}>
                    <td style={{ paddingRight: '20px' }}>
                      <span>{exercise.name}</span>
                    </td>
                    <td
                      style={{ cursor: 'pointer' }}
                      key={'exercisename' + exercise.name}
                    >
                      <span
                        className="buttonSDD"
                        id={exercise.name}
                        onClick={(e) => {
                          loadExercise(e.target.id, isPlt, isEpp, isDemo)
                          pttWindows.systemTimeWindow = true
                          setPttWindows({
                            ...pttWindows,
                            exerciseWindow: false,
                          })
                        }}
                        style={{ width: 'fit-content' }}
                      >
                        Load
                      </span>
                    </td>
                    <td
                      style={{ cursor: 'pointer', padding: '3px' }}
                      onClick={() => renameExercise(atm_code, exercise.name)}
                    >
                      <span
                        className="buttonSDD"
                        style={{ width: 'fit-content' }}
                      >
                        Rename
                      </span>
                    </td>
                    <td style={{ cursor: 'pointer', padding: '3px' }}>
                      <span
                        className="buttonSDD"
                        style={{ width: 'fit-content' }}
                        id={exercise.name}
                        onClick={(e) => {
                          deleteExercise(e.target.id)
                        }}
                      >
                        Delete
                      </span>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    )
  }
  return (
    <Window
      label="Exercise Management"
      width={'auto'}
      maxContentHeight={'auto'}
      toggleWindow={() =>
        setPttWindows((prev) => ({ ...prev, exerciseWindow: false }))
      }
    >
      <>
        {isEpp && (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 108px)',
              gridGap: '3px',
              padding: '5px 7px',
            }}
          >
            <span
              className="buttonSDD"
              onClick={() => {
                setFlights([])
                setExerciseName('new_exercise')
                setExerciseDescription(EditorState.createEmpty())
                setPttWindows({ ...pttWindows, exerciseWindow: false })
              }}
            >
              New Exercise
            </span>
            <span
              className="buttonSDD"
              onClick={() => {
                const exerciseJson = []
                flights.forEach((flight) => {
                  exerciseJson.push({
                    id: flight.id,
                    timeShift: flight.timeShift,
                    rfl: flight.rfl,
                    xfl: flight.xfl,
                  })
                })
                saveExercise(exerciseJson, flights)
              }}
            >
              Save
            </span>
          </div>
        )}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            gridGap: '3px',
          }}
        >
          {files && getMyExercises()}
          {files && getOtherExercises()}
        </div>
      </>
    </Window>
  )
}

export default ExerciseWindow
