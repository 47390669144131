/*
    node_js
    2/13/2021 12:06 AM
    by Oleksandr
*/
import { createPortal } from 'react-dom'

const ConnectingLine = (props) => {
  const { color, left, top, fltId, trackLayerSvg } = props
  const label = document.getElementById('acclabel_' + fltId)
  let intersection
  if (label != null) {
    intersection = getIntersectionXY(
      {
        left: label.style.left,
        top: label.style.top,
        clientHeight: label.clientHeight,
        clientWidth: label.clientWidth,
      },
      { left: left, top: top },
    )
  } else {
    intersection = { x: parseInt(left), y: parseInt(top) }
  }
  let idStr = 'connectingLine' + fltId

  return createPortal(
    <line
      id={idStr}
      x1={left}
      y1={top}
      x2={intersection.x || left}
      y2={intersection.y || top}
      stroke={color}
    />,
    trackLayerSvg.current.children[2],
  )
}

export default ConnectingLine

function getIntersectionXY(label, target) {
  let lx1 = parseInt(label.left)
  let ly1 = parseInt(label.top)
  let lx2 = lx1
  let ly2 = ly1 + label.clientHeight
  let lx3 = lx1 + label.clientWidth
  let ly3 = ly2
  let lx4 = lx3
  let ly4 = ly1
  let lcx = lx1 + label.clientWidth / 2
  let lcy = ly1 + label.clientHeight / 2

  let tx = parseInt(target.left)
  let ty = parseInt(target.top)

  let found = false
  let intection = checkLineIntersection(lx1, ly1, lx2, ly2, tx, ty, lcx, lcy)

  if (intection.onLine1 && intection.onLine2) {
    return { x: intection.x, y: intection.y }
  }
  if (!found) {
    intection = checkLineIntersection(lx1, ly1, lx4, ly4, tx, ty, lcx, lcy)
    if (intection.onLine1 && intection.onLine2) {
      return { x: intection.x, y: intection.y }
    }
  }
  if (!found) {
    intection = checkLineIntersection(lx4, ly4, lx3, ly3, tx, ty, lcx, lcy)
    if (intection.onLine1 && intection.onLine2) {
      return { x: intection.x, y: intection.y }
    }
  }
  if (!found) {
    intection = checkLineIntersection(lx2, ly2, lx3, ly3, tx, ty, lcx, lcy)
    if (intection.onLine1 && intection.onLine2) {
      return { x: intection.x, y: intection.y }
    }
  }
  return { x: null, y: null }
}

export function onLabelMove(label, target, connectingLine) {
  const intection = getIntersectionXY(label, target)
  if (intection.x) {
    connectingLine.setAttribute('x2', intection.x || parseInt(target.left))
    connectingLine.setAttribute('y2', intection.y || parseInt(target.top))
  }
}

function checkLineIntersection(
  line1StartX,
  line1StartY,
  line1EndX,
  line1EndY,
  line2StartX,
  line2StartY,
  line2EndX,
  line2EndY,
) {
  let denominator, a, b, numerator1, numerator2
  let result = { x: null, y: null, onLine1: false, onLine2: false }
  denominator =
    (line2EndY - line2StartY) * (line1EndX - line1StartX) -
    (line2EndX - line2StartX) * (line1EndY - line1StartY)
  if (denominator === 0) {
    return result
  }
  a = line1StartY - line2StartY
  b = line1StartX - line2StartX
  numerator1 = (line2EndX - line2StartX) * a - (line2EndY - line2StartY) * b
  numerator2 = (line1EndX - line1StartX) * a - (line1EndY - line1StartY) * b
  a = numerator1 / denominator
  b = numerator2 / denominator

  result.x = line1StartX + a * (line1EndX - line1StartX)
  result.y = line1StartY + a * (line1EndY - line1StartY)
  if (a > 0 && a < 1) {
    result.onLine1 = true
  }
  if (b > 0 && b < 1) {
    result.onLine2 = true
  }
  return result
}
