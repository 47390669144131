export const airports = [
  {
    name: 'LIRM',
    latitude: 41.061997,
    longitude: 14.082689,
    type: 'AIRPORT',
  },
  {
    name: 'LTBU',
    latitude: 41.129444,
    longitude: 27.906389,
    type: 'AIRPORT',
  },
  {
    name: 'LIBD',
    latitude: 41.138056,
    longitude: 16.765,
    type: 'AIRPORT',
  },
  {
    name: 'LTFO',
    latitude: 41.179722,
    longitude: 40.848889,
    type: 'AIRPORT',
  },
  {
    name: 'LWOH',
    latitude: 41.179922,
    longitude: 20.742328,
    type: 'AIRPORT',
  },
  {
    name: 'LTFH',
    latitude: 41.265556,
    longitude: 36.548611,
    type: 'AIRPORT',
  },
  {
    name: 'LTFM',
    latitude: 41.275278,
    longitude: 28.751944,
    type: 'AIRPORT',
  },
  {
    name: 'LTAL',
    latitude: 41.316944,
    longitude: 33.796111,
    type: 'AIRPORT',
  },
  {
    name: 'LATI',
    latitude: 41.414722,
    longitude: 19.720556,
    type: 'AIRPORT',
  },
  {
    name: 'LIBF',
    latitude: 41.433333,
    longitude: 15.534722,
    type: 'AIRPORT',
  },
  {
    name: 'LTAS',
    latitude: 41.506944,
    longitude: 32.089722,
    type: 'AIRPORT',
  },
  {
    name: 'LIBA',
    latitude: 41.538333,
    longitude: 15.714167,
    type: 'AIRPORT',
  },
  {
    name: 'LIRL',
    latitude: 41.546906,
    longitude: 12.908264,
    type: 'AIRPORT',
  },
  {
    name: 'LIRH',
    latitude: 41.644925,
    longitude: 13.299022,
    type: 'AIRPORT',
  },
  {
    name: 'LIRE',
    latitude: 41.659372,
    longitude: 12.445156,
    type: 'AIRPORT',
  },
  {
    name: 'LIRA',
    latitude: 41.799444,
    longitude: 12.597222,
    type: 'AIRPORT',
  },
  {
    name: 'LIRF',
    latitude: 41.800278,
    longitude: 12.238889,
    type: 'AIRPORT',
  },
  {
    name: 'LWSK',
    latitude: 41.961614,
    longitude: 21.621433,
    type: 'AIRPORT',
  },
  {
    name: 'LIRG',
    latitude: 41.996111,
    longitude: 12.734722,
    type: 'AIRPORT',
  },
  {
    name: 'LTCM',
    latitude: 42.015833,
    longitude: 35.066389,
    type: 'AIRPORT',
  },
  {
    name: 'LAKU',
    latitude: 42.035556,
    longitude: 20.415833,
    type: 'AIRPORT',
  },
  {
    name: 'LBPD',
    latitude: 42.067778,
    longitude: 24.850833,
    type: 'AIRPORT',
  },
  {
    name: 'LYPG',
    latitude: 42.359444,
    longitude: 19.251944,
    type: 'AIRPORT',
  },
  {
    name: 'LIAP',
    latitude: 42.379444,
    longitude: 13.309444,
    type: 'AIRPORT',
  },
  {
    name: 'LYTV',
    latitude: 42.404722,
    longitude: 18.723333,
    type: 'AIRPORT',
  },
  {
    name: 'LIRV',
    latitude: 42.430278,
    longitude: 12.064167,
    type: 'AIRPORT',
  },
  {
    name: 'LIBP',
    latitude: 42.437222,
    longitude: 14.187222,
    type: 'AIRPORT',
  },
  {
    name: 'LBSW',
    latitude: 42.444167,
    longitude: 22.984167,
    type: 'AIRPORT',
  },
  {
    name: 'LDDU',
    latitude: 42.561353,
    longitude: 18.268244,
    type: 'AIRPORT',
  },
  {
    name: 'LBBG',
    latitude: 42.570278,
    longitude: 27.515278,
    type: 'AIRPORT',
  },
  {
    name: 'BKPR',
    latitude: 42.572778,
    longitude: 21.035833,
    type: 'AIRPORT',
  },
  {
    name: 'LBSF',
    latitude: 42.695,
    longitude: 23.408333,
    type: 'AIRPORT',
  },
  {
    name: 'LYNK',
    latitude: 42.774444,
    longitude: 18.915278,
    type: 'AIRPORT',
  },
  {
    name: 'LIAF',
    latitude: 42.932778,
    longitude: 12.71,
    type: 'AIRPORT',
  },
  {
    name: 'LIRZ',
    latitude: 43.097222,
    longitude: 12.510278,
    type: 'AIRPORT',
  },
  {
    name: 'LBGO',
    latitude: 43.151667,
    longitude: 25.711944,
    type: 'AIRPORT',
  },
  {
    name: 'LBWN',
    latitude: 43.231944,
    longitude: 27.825278,
    type: 'AIRPORT',
  },
  {
    name: 'LQMO',
    latitude: 43.2829,
    longitude: 17.845878,
    type: 'AIRPORT',
  },
  {
    name: 'LDSB',
    latitude: 43.285719,
    longitude: 16.679719,
    type: 'AIRPORT',
  },
  {
    name: 'LYNI',
    latitude: 43.337222,
    longitude: 21.853611,
    type: 'AIRPORT',
  },
  {
    name: 'URSS',
    latitude: 43.444444,
    longitude: 39.946944,
    type: 'AIRPORT',
  },
  {
    name: 'LDSP',
    latitude: 43.538944,
    longitude: 16.297964,
    type: 'AIRPORT',
  },
  {
    name: 'LYTR',
    latitude: 43.614167,
    longitude: 21.030278,
    type: 'AIRPORT',
  },
  {
    name: 'LIPY',
    latitude: 43.616667,
    longitude: 13.360278,
    type: 'AIRPORT',
  },
  {
    name: 'LBRS',
    latitude: 43.695,
    longitude: 26.056667,
    type: 'AIRPORT',
  },
  {
    name: 'LYKV',
    latitude: 43.818056,
    longitude: 20.586111,
    type: 'AIRPORT',
  },
  {
    name: 'LQSA',
    latitude: 43.824472,
    longitude: 18.331778,
    type: 'AIRPORT',
  },
  {
    name: 'LYUZ',
    latitude: 43.900833,
    longitude: 19.688889,
    type: 'AIRPORT',
  },
  {
    name: 'LIPR',
    latitude: 44.019444,
    longitude: 12.609444,
    type: 'AIRPORT',
  },
  {
    name: 'LDZD',
    latitude: 44.108269,
    longitude: 15.346697,
    type: 'AIRPORT',
  },
  {
    name: 'LIPK',
    latitude: 44.195556,
    longitude: 12.069722,
    type: 'AIRPORT',
  },
  {
    name: 'LIPC',
    latitude: 44.221767,
    longitude: 12.317561,
    type: 'AIRPORT',
  },
  {
    name: 'LRCV',
    latitude: 44.318056,
    longitude: 23.888611,
    type: 'AIRPORT',
  },
  {
    name: 'LRCK',
    latitude: 44.362222,
    longitude: 28.488333,
    type: 'AIRPORT',
  },
  {
    name: 'LQTZ',
    latitude: 44.458653,
    longitude: 18.724781,
    type: 'AIRPORT',
  },
  {
    name: 'LRBS',
    latitude: 44.503611,
    longitude: 26.103611,
    type: 'AIRPORT',
  },
  {
    name: 'LROP',
    latitude: 44.571111,
    longitude: 26.085,
    type: 'AIRPORT',
  },
  {
    name: 'URKG',
    latitude: 44.581944,
    longitude: 38.013056,
    type: 'AIRPORT',
  },
  {
    name: 'LYBE',
    latitude: 44.819444,
    longitude: 20.306944,
    type: 'AIRPORT',
  },
  {
    name: 'LDPL',
    latitude: 44.893533,
    longitude: 13.922192,
    type: 'AIRPORT',
  },
  {
    name: 'LYBT',
    latitude: 44.941111,
    longitude: 20.250833,
    type: 'AIRPORT',
  },
  {
    name: 'LQBK',
    latitude: 44.941397,
    longitude: 17.297475,
    type: 'AIRPORT',
  },
  {
    name: 'URKA',
    latitude: 45.002222,
    longitude: 37.347222,
    type: 'AIRPORT',
  },
  {
    name: 'URKK',
    latitude: 45.034722,
    longitude: 39.170278,
    type: 'AIRPORT',
  },
  {
    name: 'LRTC',
    latitude: 45.062778,
    longitude: 28.714444,
    type: 'AIRPORT',
  },
  {
    name: 'LDRI',
    latitude: 45.216889,
    longitude: 14.570267,
    type: 'AIRPORT',
  },
  {
    name: 'LDRG',
    latitude: 45.379558,
    longitude: 14.503494,
    type: 'AIRPORT',
  },
  {
    name: 'LYNS',
    latitude: 45.385833,
    longitude: 19.833889,
    type: 'AIRPORT',
  },
  {
    name: 'LDOS',
    latitude: 45.462667,
    longitude: 18.810156,
    type: 'AIRPORT',
  },
  {
    name: 'LIPZ',
    latitude: 45.505278,
    longitude: 12.351944,
    type: 'AIRPORT',
  },
  {
    name: 'LIPH',
    latitude: 45.650833,
    longitude: 12.197778,
    type: 'AIRPORT',
  },
  {
    name: 'LIPS',
    latitude: 45.684444,
    longitude: 12.087222,
    type: 'AIRPORT',
  },
  {
    name: 'LDZA',
    latitude: 45.742931,
    longitude: 16.068778,
    type: 'AIRPORT',
  },
  {
    name: 'LRSB',
    latitude: 45.785833,
    longitude: 24.085556,
    type: 'AIRPORT',
  },
  {
    name: 'LRTR',
    latitude: 45.809722,
    longitude: 21.337778,
    type: 'AIRPORT',
  },
  {
    name: 'LIPQ',
    latitude: 45.8275,
    longitude: 13.472222,
    type: 'AIRPORT',
  },
  {
    name: 'LJCE',
    latitude: 45.9,
    longitude: 15.530278,
    type: 'AIRPORT',
  },
  {
    name: 'LIPI',
    latitude: 45.980692,
    longitude: 13.0499,
    type: 'AIRPORT',
  },
  {
    name: 'LHPP',
    latitude: 45.989167,
    longitude: 18.242222,
    type: 'AIRPORT',
  },
  {
    name: 'LIPA',
    latitude: 46.030072,
    longitude: 12.598839,
    type: 'AIRPORT',
  },
  {
    name: 'LRAR',
    latitude: 46.176667,
    longitude: 21.261944,
    type: 'AIRPORT',
  },
  {
    name: 'LJLJ',
    latitude: 46.224486,
    longitude: 14.456047,
    type: 'AIRPORT',
  },
  {
    name: 'LDVA',
    latitude: 46.294217,
    longitude: 16.383472,
    type: 'AIRPORT',
  },
  {
    name: 'UKOO',
    latitude: 46.426111,
    longitude: 30.673333,
    type: 'AIRPORT',
  },
  {
    name: 'LRTM',
    latitude: 46.467778,
    longitude: 24.4125,
    type: 'AIRPORT',
  },
  {
    name: 'LJMB',
    latitude: 46.479861,
    longitude: 15.686131,
    type: 'AIRPORT',
  },
  {
    name: 'LRBC',
    latitude: 46.521944,
    longitude: 26.910278,
    type: 'AIRPORT',
  },
  {
    name: 'LOWK',
    latitude: 46.642778,
    longitude: 14.337222,
    type: 'AIRPORT',
  },
  {
    name: 'UKOH',
    latitude: 46.676111,
    longitude: 32.506111,
    type: 'AIRPORT',
  },
  {
    name: 'LHSM',
    latitude: 46.686389,
    longitude: 17.159167,
    type: 'AIRPORT',
  },
  {
    name: 'LRCL',
    latitude: 46.789167,
    longitude: 23.692222,
    type: 'AIRPORT',
  },
  {
    name: 'LHKE',
    latitude: 46.9175,
    longitude: 19.749167,
    type: 'AIRPORT',
  },
  {
    name: 'LUKK',
    latitude: 46.927778,
    longitude: 28.930833,
    type: 'AIRPORT',
  },
  {
    name: 'LOWG',
    latitude: 46.993056,
    longitude: 15.439167,
    type: 'AIRPORT',
  },
  {
    name: 'LROD',
    latitude: 47.025278,
    longitude: 21.9025,
    type: 'AIRPORT',
  },
  {
    name: 'UKON',
    latitude: 47.058056,
    longitude: 31.919444,
    type: 'AIRPORT',
  },
  {
    name: 'LUKV',
    latitude: 47.0625,
    longitude: 29.098611,
    type: 'AIRPORT',
  },
  {
    name: 'LHSN',
    latitude: 47.122778,
    longitude: 20.235556,
    type: 'AIRPORT',
  },
  {
    name: 'LRIA',
    latitude: 47.180278,
    longitude: 27.620833,
    type: 'AIRPORT',
  },
  {
    name: 'URRT',
    latitude: 47.198333,
    longitude: 38.849167,
    type: 'AIRPORT',
  },
  {
    name: 'LOXZ',
    latitude: 47.203611,
    longitude: 14.745833,
    type: 'AIRPORT',
  },
  {
    name: 'LHPA',
    latitude: 47.363889,
    longitude: 17.500556,
    type: 'AIRPORT',
  },
  {
    name: 'LHBP',
    latitude: 47.439444,
    longitude: 19.261944,
    type: 'AIRPORT',
  },
  {
    name: 'LHDC',
    latitude: 47.488889,
    longitude: 21.615278,
    type: 'AIRPORT',
  },
  {
    name: 'URRP',
    latitude: 47.493889,
    longitude: 39.924722,
    type: 'AIRPORT',
  },
  {
    name: 'LHPR',
    latitude: 47.627222,
    longitude: 17.808333,
    type: 'AIRPORT',
  },
  {
    name: 'LRBM',
    latitude: 47.658333,
    longitude: 23.466111,
    type: 'AIRPORT',
  },
  {
    name: 'LRSV',
    latitude: 47.686389,
    longitude: 26.354444,
    type: 'AIRPORT',
  },
  {
    name: 'LRSM',
    latitude: 47.703333,
    longitude: 22.885556,
    type: 'AIRPORT',
  },
  {
    name: 'LOWS',
    latitude: 47.794444,
    longitude: 13.003333,
    type: 'AIRPORT',
  },
  {
    name: 'LOXN',
    latitude: 47.839722,
    longitude: 16.220278,
    type: 'AIRPORT',
  },
  {
    name: 'LUBM',
    latitude: 47.8625,
    longitude: 28.212778,
    type: 'AIRPORT',
  },
  {
    name: 'UKDE',
    latitude: 47.867222,
    longitude: 35.315,
    type: 'AIRPORT',
  },
  {
    name: 'UKDR',
    latitude: 48.044167,
    longitude: 33.208056,
    type: 'AIRPORT',
  },
  {
    name: 'LOWW',
    latitude: 48.110278,
    longitude: 16.569722,
    type: 'AIRPORT',
  },
  {
    name: 'LZIB',
    latitude: 48.17,
    longitude: 17.212778,
    type: 'AIRPORT',
  },
  {
    name: 'LOLW',
    latitude: 48.181111,
    longitude: 14.0375,
    type: 'AIRPORT',
  },
  {
    name: 'LOWL',
    latitude: 48.235278,
    longitude: 14.188056,
    type: 'AIRPORT',
  },
  {
    name: 'UKLN',
    latitude: 48.259444,
    longitude: 25.98,
    type: 'AIRPORT',
  },
  {
    name: 'LOXT',
    latitude: 48.320278,
    longitude: 16.113333,
    type: 'AIRPORT',
  },
  {
    name: 'UKDD',
    latitude: 48.357222,
    longitude: 35.100556,
    type: 'AIRPORT',
  },
  {
    name: 'LZPP',
    latitude: 48.625,
    longitude: 17.828611,
    type: 'AIRPORT',
  },
  {
    name: 'UKLU',
    latitude: 48.634444,
    longitude: 22.263056,
    type: 'AIRPORT',
  },
  {
    name: 'LZKZ',
    latitude: 48.663056,
    longitude: 21.241111,
    type: 'AIRPORT',
  },
  {
    name: 'LZTN',
    latitude: 48.864167,
    longitude: 17.992222,
    type: 'AIRPORT',
  },
  {
    name: 'UKLI',
    latitude: 48.883889,
    longitude: 24.684444,
    type: 'AIRPORT',
  },
  {
    name: 'LKCS',
    latitude: 48.946389,
    longitude: 14.4275,
    type: 'AIRPORT',
  },
  {
    name: 'LKKU',
    latitude: 49.029444,
    longitude: 17.439722,
    type: 'AIRPORT',
  },
  {
    name: 'LZTT',
    latitude: 49.073611,
    longitude: 20.241111,
    type: 'AIRPORT',
  },
  {
    name: 'UKHK',
    latitude: 49.134167,
    longitude: 33.476667,
    type: 'AIRPORT',
  },
  {
    name: 'LKTB',
    latitude: 49.151389,
    longitude: 16.693889,
    type: 'AIRPORT',
  },
  {
    name: 'LKNA',
    latitude: 49.166208,
    longitude: 16.124383,
    type: 'AIRPORT',
  },
  {
    name: 'UKWW',
    latitude: 49.2425,
    longitude: 28.613889,
    type: 'AIRPORT',
  },
  {
    name: 'LKPO',
    latitude: 49.425833,
    longitude: 17.404722,
    type: 'AIRPORT',
  },
  {
    name: 'EPNT',
    latitude: 49.462222,
    longitude: 20.052222,
    type: 'AIRPORT',
  },
  {
    name: 'UKHP',
    latitude: 49.569444,
    longitude: 34.394167,
    type: 'AIRPORT',
  },
  {
    name: 'LKLN',
    latitude: 49.675278,
    longitude: 13.274444,
    type: 'AIRPORT',
  },
  {
    name: 'LKMT',
    latitude: 49.696111,
    longitude: 18.110833,
    type: 'AIRPORT',
  },
  {
    name: 'LKPM',
    latitude: 49.72,
    longitude: 14.100278,
    type: 'AIRPORT',
  },
  {
    name: 'UKLL',
    latitude: 49.809722,
    longitude: 23.958333,
    type: 'AIRPORT',
  },
  {
    name: 'UKHH',
    latitude: 49.926944,
    longitude: 36.29,
    type: 'AIRPORT',
  },
  {
    name: 'LKCV',
    latitude: 49.939564,
    longitude: 15.381933,
    type: 'AIRPORT',
  },
  {
    name: 'LKPD',
    latitude: 50.013458,
    longitude: 15.738572,
    type: 'AIRPORT',
  },
  {
    name: 'UKHD',
    latitude: 50.025,
    longitude: 36.267222,
    type: 'AIRPORT',
  },
  {
    name: 'EPKK',
    latitude: 50.077778,
    longitude: 19.784722,
    type: 'AIRPORT',
  },
  {
    name: 'LKPR',
    latitude: 50.100833,
    longitude: 14.26,
    type: 'AIRPORT',
  },
  {
    name: 'EPRZ',
    latitude: 50.11,
    longitude: 22.018889,
    type: 'AIRPORT',
  },
  {
    name: 'LKKB',
    latitude: 50.121367,
    longitude: 14.543639,
    type: 'AIRPORT',
  },
  {
    name: 'UKKO',
    latitude: 50.158889,
    longitude: 28.738333,
    type: 'AIRPORT',
  },
  {
    name: 'LKKV',
    latitude: 50.203056,
    longitude: 12.915,
    type: 'AIRPORT',
  },
  {
    name: 'LKVO',
    latitude: 50.216667,
    longitude: 14.395556,
    type: 'AIRPORT',
  },
  {
    name: 'LKHK',
    latitude: 50.253333,
    longitude: 15.845278,
    type: 'AIRPORT',
  },
  {
    name: 'UKKV',
    latitude: 50.267778,
    longitude: 28.743611,
    type: 'AIRPORT',
  },
  {
    name: 'LKPC',
    latitude: 50.306389,
    longitude: 13.935,
    type: 'AIRPORT',
  },
  {
    name: 'EPML',
    latitude: 50.322222,
    longitude: 21.462222,
    type: 'AIRPORT',
  },
  {
    name: 'UKBB',
    latitude: 50.344722,
    longitude: 30.893333,
    type: 'AIRPORT',
  },
  {
    name: 'UKKK',
    latitude: 50.401944,
    longitude: 30.451944,
    type: 'AIRPORT',
  },
  {
    name: 'LKRO',
    latitude: 50.410556,
    longitude: 14.226111,
    type: 'AIRPORT',
  },
  {
    name: 'EPKT',
    latitude: 50.474167,
    longitude: 19.08,
    type: 'AIRPORT',
  },
  {
    name: 'UKKT',
    latitude: 50.478889,
    longitude: 30.384167,
    type: 'AIRPORT',
  },
  {
    name: 'EPKN',
    latitude: 50.529108,
    longitude: 18.085175,
    type: 'AIRPORT',
  },
  {
    name: 'LKMH',
    latitude: 50.54,
    longitude: 15.006667,
    type: 'AIRPORT',
  },
  {
    name: 'UKKM',
    latitude: 50.603611,
    longitude: 30.191111,
    type: 'AIRPORT',
  },
  {
    name: 'UKLR',
    latitude: 50.608333,
    longitude: 26.139722,
    type: 'AIRPORT',
  },
  {
    name: 'UUOB',
    latitude: 50.643889,
    longitude: 36.59,
    type: 'AIRPORT',
  },
  {
    name: 'UKHS',
    latitude: 50.858333,
    longitude: 34.762778,
    type: 'AIRPORT',
  },
  {
    name: 'EPRU',
    latitude: 50.884722,
    longitude: 19.203056,
    type: 'AIRPORT',
  },
  {
    name: 'EDAC',
    latitude: 50.981817,
    longitude: 12.506361,
    type: 'AIRPORT',
  },
  {
    name: 'EPWR',
    latitude: 51.102778,
    longitude: 16.885833,
    type: 'AIRPORT',
  },
  {
    name: 'EDDC',
    latitude: 51.134344,
    longitude: 13.768,
    type: 'AIRPORT',
  },
  {
    name: 'EDAB',
    latitude: 51.193531,
    longitude: 14.519747,
    type: 'AIRPORT',
  },
  {
    name: 'EPLB',
    latitude: 51.240278,
    longitude: 22.713611,
    type: 'AIRPORT',
  },
  {
    name: 'EDAK',
    latitude: 51.308,
    longitude: 13.555333,
    type: 'AIRPORT',
  },
  {
    name: 'UUOS',
    latitude: 51.329167,
    longitude: 37.769167,
    type: 'AIRPORT',
  },
  {
    name: 'EDBR',
    latitude: 51.365167,
    longitude: 14.951,
    type: 'AIRPORT',
  },
  {
    name: 'EPRA',
    latitude: 51.388889,
    longitude: 21.211667,
    type: 'AIRPORT',
  },
  {
    name: 'EDDP',
    latitude: 51.423992,
    longitude: 12.236383,
    type: 'AIRPORT',
  },
  {
    name: 'EPLK',
    latitude: 51.551111,
    longitude: 19.1825,
    type: 'AIRPORT',
  },
  {
    name: 'EPDE',
    latitude: 51.551111,
    longitude: 21.891944,
    type: 'AIRPORT',
  },
  {
    name: 'EDCY',
    latitude: 51.577,
    longitude: 14.137,
    type: 'AIRPORT',
  },
  {
    name: 'EPTM',
    latitude: 51.584444,
    longitude: 20.097222,
    type: 'AIRPORT',
  },
  {
    name: 'UUOD',
    latitude: 51.653333,
    longitude: 39.256944,
    type: 'AIRPORT',
  },
  {
    name: 'EPLL',
    latitude: 51.721944,
    longitude: 19.398056,
    type: 'AIRPORT',
  },
  {
    name: 'UUOK',
    latitude: 51.750556,
    longitude: 36.295833,
    type: 'AIRPORT',
  },
  {
    name: 'ETSH',
    latitude: 51.767869,
    longitude: 13.167594,
    type: 'AIRPORT',
  },
  {
    name: 'UUOO',
    latitude: 51.815,
    longitude: 39.229722,
    type: 'AIRPORT',
  },
  {
    name: 'EPLY',
    latitude: 52.005,
    longitude: 19.143889,
    type: 'AIRPORT',
  },
  {
    name: 'UMBB',
    latitude: 52.108333,
    longitude: 23.896389,
    type: 'AIRPORT',
  },
  {
    name: 'EPZG',
    latitude: 52.138611,
    longitude: 15.798611,
    type: 'AIRPORT',
  },
  {
    name: 'EPWA',
    latitude: 52.165833,
    longitude: 20.967222,
    type: 'AIRPORT',
  },
  {
    name: 'EPMM',
    latitude: 52.195556,
    longitude: 21.655833,
    type: 'AIRPORT',
  },
  {
    name: 'EDAZ',
    latitude: 52.203889,
    longitude: 13.16,
    type: 'AIRPORT',
  },
  {
    name: 'EPPG',
    latitude: 52.235,
    longitude: 16.241111,
    type: 'AIRPORT',
  },
  {
    name: 'EPKS',
    latitude: 52.331944,
    longitude: 16.966667,
    type: 'AIRPORT',
  },
  {
    name: 'EDDB',
    latitude: 52.362247,
    longitude: 13.500672,
    type: 'AIRPORT',
  },
  {
    name: 'EPPW',
    latitude: 52.379167,
    longitude: 17.852222,
    type: 'AIRPORT',
  },
  {
    name: 'EPPO',
    latitude: 52.421111,
    longitude: 16.826389,
    type: 'AIRPORT',
  },
  {
    name: 'EPMO',
    latitude: 52.451111,
    longitude: 20.651944,
    type: 'AIRPORT',
  },
  {
    name: 'UMGG',
    latitude: 52.526944,
    longitude: 31.016667,
    type: 'AIRPORT',
  },
  {
    name: 'EDON',
    latitude: 52.613056,
    longitude: 14.242778,
    type: 'AIRPORT',
  },
  {
    name: 'EDBW',
    latitude: 52.633056,
    longitude: 13.766944,
    type: 'AIRPORT',
  },
  {
    name: 'UUOL',
    latitude: 52.7025,
    longitude: 39.538333,
    type: 'AIRPORT',
  },
  {
    name: 'EDAV',
    latitude: 52.826333,
    longitude: 13.701667,
    type: 'AIRPORT',
  },
  {
    name: 'EPIR',
    latitude: 52.828611,
    longitude: 18.330278,
    type: 'AIRPORT',
  },
  {
    name: 'EPBY',
    latitude: 53.096667,
    longitude: 17.977778,
    type: 'AIRPORT',
  },
  {
    name: 'EPPI',
    latitude: 53.169722,
    longitude: 16.710556,
    type: 'AIRPORT',
  },
  {
    name: 'UUBP',
    latitude: 53.214444,
    longitude: 34.175833,
    type: 'AIRPORT',
  },
  {
    name: 'EDAX',
    latitude: 53.305167,
    longitude: 12.752167,
    type: 'AIRPORT',
  },
  {
    name: 'EPMI',
    latitude: 53.395,
    longitude: 16.082778,
    type: 'AIRPORT',
  },
  {
    name: 'EPSY',
    latitude: 53.481944,
    longitude: 20.937778,
    type: 'AIRPORT',
  },
  {
    name: 'EPSC',
    latitude: 53.584722,
    longitude: 14.902222,
    type: 'AIRPORT',
  },
  {
    name: 'UMMG',
    latitude: 53.601944,
    longitude: 24.053889,
    type: 'AIRPORT',
  },
  {
    name: 'EDBN',
    latitude: 53.602236,
    longitude: 13.306081,
    type: 'AIRPORT',
  },
  {
    name: 'EPSN',
    latitude: 53.790556,
    longitude: 15.826389,
    type: 'AIRPORT',
  },
  {
    name: 'EDAH',
    latitude: 53.878706,
    longitude: 14.152347,
    type: 'AIRPORT',
  },
  {
    name: 'UMMS',
    latitude: 53.8825,
    longitude: 28.030833,
    type: 'AIRPORT',
  },
  {
    name: 'ETNL',
    latitude: 53.918167,
    longitude: 12.279333,
    type: 'AIRPORT',
  },
  {
    name: 'EPBO',
    latitude: 53.951111,
    longitude: 17.947778,
    type: 'AIRPORT',
  },
  {
    name: 'UMOO',
    latitude: 53.955278,
    longitude: 30.094167,
    type: 'AIRPORT',
  },
  {
    name: 'EPMB',
    latitude: 54.026667,
    longitude: 19.135556,
    type: 'AIRPORT',
  },
  {
    name: 'EDCP',
    latitude: 54.157833,
    longitude: 13.772667,
    type: 'AIRPORT',
  },
  {
    name: 'EDBH',
    latitude: 54.338175,
    longitude: 12.710106,
    type: 'AIRPORT',
  },
  {
    name: 'EPGD',
    latitude: 54.3775,
    longitude: 18.466111,
    type: 'AIRPORT',
  },
  {
    name: 'EPCE',
    latitude: 54.416111,
    longitude: 17.763333,
    type: 'AIRPORT',
  },
  {
    name: 'UMIO',
    latitude: 54.439722,
    longitude: 30.296111,
    type: 'AIRPORT',
  },
  {
    name: 'UUBC',
    latitude: 54.548611,
    longitude: 36.371389,
    type: 'AIRPORT',
  },
  {
    name: 'EPOK',
    latitude: 54.579722,
    longitude: 18.517222,
    type: 'AIRPORT',
  },
  {
    name: 'EYVI',
    latitude: 54.636944,
    longitude: 25.287778,
    type: 'AIRPORT',
  },
  {
    name: 'UMKK',
    latitude: 54.891667,
    longitude: 20.598611,
    type: 'AIRPORT',
  },
  {
    name: 'EYKA',
    latitude: 54.963889,
    longitude: 24.084722,
    type: 'AIRPORT',
  },
  {
    name: 'EKRN',
    latitude: 55.063267,
    longitude: 14.759558,
    type: 'AIRPORT',
  },
  {
    name: 'UMII',
    latitude: 55.126389,
    longitude: 30.349722,
    type: 'AIRPORT',
  },
  {
    name: 'UUDD',
    latitude: 55.409167,
    longitude: 37.9075,
    type: 'AIRPORT',
  },
  {
    name: 'UUMO',
    latitude: 55.5075,
    longitude: 37.504167,
    type: 'AIRPORT',
  },
  {
    name: 'ESMS',
    latitude: 55.548333,
    longitude: 13.353333,
    type: 'AIRPORT',
  },
  {
    name: 'UUBW',
    latitude: 55.552778,
    longitude: 38.149722,
    type: 'AIRPORT',
  },
  {
    name: 'EKRK',
    latitude: 55.585567,
    longitude: 12.131428,
    type: 'AIRPORT',
  },
  {
    name: 'UUWW',
    latitude: 55.599167,
    longitude: 37.273056,
    type: 'AIRPORT',
  },
  {
    name: 'EKCH',
    latitude: 55.617917,
    longitude: 12.655972,
    type: 'AIRPORT',
  },
  {
    name: 'EYSA',
    latitude: 55.893889,
    longitude: 23.395,
    type: 'AIRPORT',
  },
  {
    name: 'ESMK',
    latitude: 55.920556,
    longitude: 14.085278,
    type: 'AIRPORT',
  },
  {
    name: 'UUEE',
    latitude: 55.9725,
    longitude: 37.413056,
    type: 'AIRPORT',
  },
  {
    name: 'EYPA',
    latitude: 55.973056,
    longitude: 21.093889,
    type: 'AIRPORT',
  },
  {
    name: 'ESTL',
    latitude: 56.085278,
    longitude: 13.206944,
    type: 'AIRPORT',
  },
  {
    name: 'ESDF',
    latitude: 56.266667,
    longitude: 15.265,
    type: 'AIRPORT',
  },
  {
    name: 'ESTA',
    latitude: 56.291111,
    longitude: 12.855,
    type: 'AIRPORT',
  },
  {
    name: 'EVLA',
    latitude: 56.5175,
    longitude: 21.096944,
    type: 'AIRPORT',
  },
  {
    name: 'ESMQ',
    latitude: 56.685556,
    longitude: 16.2875,
    type: 'AIRPORT',
  },
  {
    name: 'ESMT',
    latitude: 56.690833,
    longitude: 12.82,
    type: 'AIRPORT',
  },
  {
    name: 'EVGA',
    latitude: 56.779167,
    longitude: 24.853889,
    type: 'AIRPORT',
  },
  {
    name: 'EVRA',
    latitude: 56.923611,
    longitude: 23.971111,
    type: 'AIRPORT',
  },
  {
    name: 'ESMX',
    latitude: 56.930278,
    longitude: 14.728889,
    type: 'AIRPORT',
  },
  {
    name: 'UUBI',
    latitude: 56.9425,
    longitude: 40.932222,
    type: 'AIRPORT',
  },
]
