/*
    node_js
    1/30/2021 12:15 PM
    by Oleksandr
*/

// Define the standard atmospheric values that remain constant
const P0 = 101325
//const T0 = 288.15;
const a0 = 340.29
const rho0 = 1.225 // All SI units

// define the actual atm constiables as global constiables
//const tempSI;
let sonicSI
let pressureSI
let rhoSI
//let sigma;

// define the letious speeds in SI units
let cas
let eas
let tas
let mach

const sigFig = 1000

let outputCAS, outputEAS, outputTAS, outputMach

const getTASfromCAS = (casInput, altRaw) => {
  casCalc(casInput, altRaw)
  return outputTAS
}
const getMachfromCAS = (casInput, altRaw) => {
  casCalc(casInput, altRaw)
  return outputMach
}
const getCASfromMach = (machInput, altRaw) => {
  machCalc(machInput, altRaw)
  return outputCAS
}
const casCalc = (casInput, altRaw) => {
  atmCalc(altRaw) // calc atm parameters

  const velUnit = 0 // +document.getElementById("selectionCAS").value;
  let velFactor

  switch (velUnit) {
    case 0:
      velFactor = 0.514444444
      break

    case 1:
      velFactor = 1
      break

    case 2:
      velFactor = 0.277778
      break

    case 3:
      velFactor = 0.44704
      break
    default:
      break
  }

  cas = velFactor * casInput

  const qc = P0 * (Math.pow((Math.pow(cas / a0, 2) + 5) / 5, 7 / 2) - 1)

  mach = Math.pow(5 * (Math.pow(qc / pressureSI + 1, 2 / 7) - 1), 0.5)
  tas = mach * sonicSI

  eas = tas / Math.pow(rho0 / rhoSI, 0.5)

  let unitFactor = 1.94384
  outputCAS = Math.round(sigFig * cas * unitFactor) / sigFig
  outputEAS = Math.round(sigFig * eas * unitFactor) / sigFig
  outputTAS = Math.round(sigFig * tas * unitFactor) / sigFig
  outputMach = Math.round(sigFig * mach) / sigFig
  // console.log(outputMach + " " + outputTAS + " " + outputEAS + " " + outputCAS);

  //convertFromSI(); // convert results from SI to whatever units are required by the end user
}

const easCalc = (easInput, altRaw) => {
  atmCalc(altRaw) // calc atm parameters

  const velUnit = 0 // +document.getElementById("selectionEAS").value;
  let velFactor

  switch (velUnit) {
    case 0:
      velFactor = 0.514444444
      break

    case 1:
      velFactor = 1
      break

    case 2:
      velFactor = 0.277778
      break

    case 3:
      velFactor = 0.44704
      break
    default:
      break
  }

  eas = velFactor * easInput
  tas = eas * Math.pow(rho0 / rhoSI, 0.5)
  mach = tas / sonicSI

  let qc = pressureSI * (Math.pow(1 + 0.2 * Math.pow(mach, 2), 7 / 2) - 1)
  cas = a0 * Math.pow(5 * (Math.pow(qc / P0 + 1, 2 / 7) - 1), 0.5)

  // let outputCAS, outputEAS, outputTAS, outputMach;
  let unitFactor = 1.94384
  outputCAS = Math.round(sigFig * cas * unitFactor) / sigFig
  outputEAS = Math.round(sigFig * eas * unitFactor) / sigFig
  outputTAS = Math.round(sigFig * tas * unitFactor) / sigFig
  outputMach = Math.round(sigFig * mach) / sigFig
  console.log(outputMach + ' ' + outputTAS + ' ' + outputEAS + ' ' + outputCAS)

  //convertFromSI(); // convert results from SI to whatever units are required by the end user
}

const tasCalc = (tasInput, altRaw) => {
  atmCalc(altRaw) // calc atm parameters

  const velUnit = 0 //+document.getElementById("selectionTAS").value;
  let velFactor

  switch (velUnit) {
    case 0:
      velFactor = 0.514444444
      break

    case 1:
      velFactor = 1
      break

    case 2:
      velFactor = 0.277778
      break

    case 3:
      velFactor = 0.44704
      break
    default:
      break
  }

  tas = velFactor * tasInput
  eas = tas / Math.pow(rho0 / rhoSI, 0.5)
  mach = tas / sonicSI

  let qc = pressureSI * (Math.pow(1 + 0.2 * Math.pow(mach, 2), 7 / 2) - 1)
  cas = a0 * Math.pow(5 * (Math.pow(qc / P0 + 1, 2 / 7) - 1), 0.5)
  // let outputCAS, outputEAS, outputTAS, outputMach;
  let unitFactor = 1.94384
  outputCAS = Math.round(sigFig * cas * unitFactor) / sigFig
  outputEAS = Math.round(sigFig * eas * unitFactor) / sigFig
  outputTAS = Math.round(sigFig * tas * unitFactor) / sigFig
  outputMach = Math.round(sigFig * mach) / sigFig
  return { cas: outputCAS, Mach: outputMach }
  // console.log(outputMach + " " + outputTAS + " " + outputEAS + " " + outputCAS);

  //  convertFromSI(); // convert results from SI to whatever units are required by the end user
}

const machCalc = (machInput, altRaw) => {
  atmCalc(altRaw) // calc atm parameters

  mach = machInput
  tas = mach * sonicSI
  eas = tas / Math.pow(rho0 / rhoSI, 0.5)

  let qc = pressureSI * (Math.pow(1 + 0.2 * Math.pow(mach, 2), 7 / 2) - 1)
  cas = a0 * Math.pow(5 * (Math.pow(qc / P0 + 1, 2 / 7) - 1), 0.5)

  // let outputCAS, outputEAS, outputTAS, outputMach;
  let unitFactor = 1.94384
  outputCAS = Math.round(sigFig * cas * unitFactor) / sigFig
  outputEAS = Math.round(sigFig * eas * unitFactor) / sigFig
  outputTAS = Math.round(sigFig * tas * unitFactor) / sigFig
  outputMach = Math.round(sigFig * mach) / sigFig
  // console.log(outputMach + " " + outputTAS + " " + outputEAS + " " + outputCAS);

  //convertFromSI(); // convert results from SI to whatever units are required by the end user
}

function atmCalc(altRaw) {
  //const altRaw = +document.getElementById("inputAltitude").value;
  const tempRaw = 0
  const altSelection = 0

  let altSI
  if (altSelection === 0) {
    altSI = altRaw * 0.3048
  } else {
    altSI = altRaw
  }

  let altitudeArray = [0, 11000, 20000, 32000, 47000, 51000, 71000, 84852]
  let presRelsArray = [
    1, 2.23361105092158e-1, 5.403295010784876e-2, 8.566678359291667e-3,
    1.0945601337771144e-3, 6.606353132858367e-4, 3.904683373343926e-5,
    3.6850095235747942e-6,
  ]
  let tempsArray = [
    288.15, 216.65, 216.65, 228.65, 270.65, 270.65, 214.65, 186.946,
  ]
  let tempGradArray = [-6.5, 0, 1, 2.8, 0, -2.8, -2, 0]

  let i = 0
  while (altSI > altitudeArray[i + 1]) {
    i = i + 1
  }

  // i defines the array position that I require for the calc
  const alts = altitudeArray[i]
  const presRels = presRelsArray[i]
  const temps = tempsArray[i]
  const tempGrad = tempGradArray[i] / 1000

  const deltaAlt = altSI - alts
  const stdTemp = temps + deltaAlt * tempGrad // this is the standard temperature at STP

  const tempSI = stdTemp + tempRaw // includes the temp offset
  //const tempDegC = tempSI - 273.15;
  //const roundTSI = Math.round(sigFig * tempSI) / sigFig;
  //const roundTDeg = Math.round(sigFig * tempDegC) / sigFig;

  //	document.getElementById("writeAtmTemp").innerHTML = roundTSI+" K |"+roundTDeg+ " &deg;C";

  // Now for the relative pressure calc:

  //define constants
  const airMol = 28.9644
  const rhoSL = 1.225 // kg/m3
  const pSL = 101325 // Pa
  const tSL = 288.15 //K
  const gamma = 1.4
  const g = 9.80665 // m/s2
  //const stdTempGrad = -0.0065; // deg K/m
  const rGas = 8.31432 //kg/Mol/K
  const R = 287.053
  const gMR = (g * airMol) / rGas
  //const dryMM = 0.0289644; //kg/mol
  //const adLapse = 0.00198; //K/ft
  let relPres

  if (Math.abs(tempGrad) < 1e-10) {
    relPres = presRels * Math.exp((-1 * gMR * deltaAlt) / 1000 / temps)
  } else {
    relPres = presRels * Math.pow(temps / stdTemp, gMR / tempGrad / 1000)
  }

  // Now I can calculate the results:

  sonicSI = Math.sqrt(gamma * R * tempSI)
  pressureSI = pSL * relPres
  rhoSI = rhoSL * relPres * (tSL / tempSI)
  //sigma = rhoSI / rhoSL;
} // end atmCalc()

module.exports = {
  getTASfromCAS,
  getMachfromCAS,
  getCASfromMach,
  casCalc,
  easCalc,
  tasCalc,
  machCalc,
}
