export const navfix = [
  { name: '40VOR', latitude: 41.000658, longitude: 28.881928 },
  { name: 'RN603', latitude: 41.002956, longitude: 14.466483 },
  { name: 'RN409', latitude: 41.003181, longitude: 14.802119 },
  { name: 'DEVED', latitude: 41.003456, longitude: 24.799581 },
  { name: 'CT06', latitude: 41.003533, longitude: 13.929111 },
  { name: 'KINDR', latitude: 41.003636, longitude: 29.59605 },
  { name: 'D023G', latitude: 41.003653, longitude: 29.390339 },
  { name: 'D258S', latitude: 41.004269, longitude: 13.676811 },
  { name: 'D050E', latitude: 41.004486, longitude: 28.891842 },
  { name: 'IPLOD', latitude: 41.004722, longitude: 13.835 },
  { name: 'VP213', latitude: 41.005, longitude: 22.881667 },
  { name: 'D321G', latitude: 41.005106, longitude: 29.248475 },
  { name: 'D171J', latitude: 41.006111, longitude: 20.756111 },
  { name: 'CB510', latitude: 41.007197, longitude: 37.970558 },
  { name: 'FM536', latitude: 41.007875, longitude: 29.096469 },
  { name: 'VP129', latitude: 41.008333, longitude: 16.710278 },
  { name: 'D187J', latitude: 41.008789, longitude: 20.694381 },
  { name: 'LOC13', latitude: 41.010072, longitude: 37.811225 },
  { name: 'CG054', latitude: 41.010458, longitude: 40.426819 },
  { name: 'OM23', latitude: 41.0105, longitude: 28.907056 },
  { name: 'MA11', latitude: 41.010567, longitude: 39.740008 },
  { name: 'RN515', latitude: 41.010581, longitude: 14.556061 },
  { name: 'D243G', latitude: 41.010606, longitude: 13.949047 },
  { name: 'FN06Y', latitude: 41.011169, longitude: 13.945819 },
  { name: 'DME14', latitude: 41.013028, longitude: 14.561306 },
  { name: 'FJ051', latitude: 41.013247, longitude: 29.622333 },
  { name: 'LASBU', latitude: 41.014167, longitude: 24.033611 },
  { name: 'FJ587', latitude: 41.014636, longitude: 29.845775 },
  { name: 'BOZDE', latitude: 41.014928, longitude: 29.197025 },
  { name: 'SOR33', latitude: 41.015264, longitude: 14.78235 },
  { name: 'RN407', latitude: 41.015439, longitude: 14.282247 },
  { name: 'D253J', latitude: 41.015661, longitude: 13.876364 },
  { name: 'BA086', latitude: 41.015744, longitude: 29.219514 },
  { name: 'D272J', latitude: 41.016253, longitude: 39.577242 },
  { name: 'GIRNO', latitude: 41.01725, longitude: 33.254556 },
  { name: 'IBURA', latitude: 41.0175, longitude: 33.373611 },
  { name: 'FT06', latitude: 41.017678, longitude: 13.968989 },
  { name: 'D023H', latitude: 41.018506, longitude: 29.400353 },
  { name: 'FM833', latitude: 41.018569, longitude: 27.927769 },
  { name: 'CF04', latitude: 41.01865, longitude: 27.736903 },
  { name: 'FQ06Z', latitude: 41.018792, longitude: 13.968331 },
  { name: 'D321H', latitude: 41.019056, longitude: 29.236414 },
  { name: 'RIMKU', latitude: 41.019242, longitude: 27.735522 },
  { name: 'D165O', latitude: 41.019289, longitude: 36.607356 },
  { name: 'FILFU', latitude: 41.019761, longitude: 28.927028 },
  { name: 'DOGUS', latitude: 41.02, longitude: 15.703333 },
  { name: 'CF10', latitude: 41.020142, longitude: 37.746525 },
  { name: '40VOR', latitude: 41.020236, longitude: 39.714003 },
  { name: 'D340G', latitude: 41.020589, longitude: 29.295019 },
  { name: 'BATBA', latitude: 41.020658, longitude: 37.745492 },
  { name: 'D115O', latitude: 41.021003, longitude: 17.064133 },
  { name: 'ORVOR', latitude: 41.0225, longitude: 35.690278 },
  { name: 'FM731', latitude: 41.022558, longitude: 27.884033 },
  { name: 'BA360', latitude: 41.023708, longitude: 29.167519 },
  { name: 'BD618', latitude: 41.02385, longitude: 17.293189 },
  { name: 'CT063', latitude: 41.024744, longitude: 13.988933 },
  { name: 'CG023', latitude: 41.024772, longitude: 39.693689 },
  { name: 'FI11', latitude: 41.025886, longitude: 39.690617 },
  { name: 'CG925', latitude: 41.026247, longitude: 40.138831 },
  { name: 'TR28A', latitude: 41.027506, longitude: 19.375392 },
  { name: 'OGIBI', latitude: 41.028219, longitude: 28.712078 },
  { name: 'GAPDI', latitude: 41.028444, longitude: 28.732292 },
  { name: 'GUNCE', latitude: 41.028764, longitude: 28.761125 },
  { name: 'FD23', latitude: 41.030019, longitude: 28.935408 },
  { name: 'RN516', latitude: 41.031019, longitude: 14.599842 },
  { name: 'CF24', latitude: 41.031083, longitude: 14.599889 },
  { name: 'BD653', latitude: 41.031131, longitude: 17.010933 },
  { name: 'FF103', latitude: 41.031256, longitude: 39.64905 },
  { name: 'FT063', latitude: 41.031808, longitude: 14.008883 },
  { name: 'CG016', latitude: 41.032461, longitude: 39.536303 },
  { name: 'BA376', latitude: 41.032872, longitude: 28.73625 },
  { name: 'DRAMO', latitude: 41.034444, longitude: 26.611111 },
  { name: 'D237O', latitude: 41.035892, longitude: 27.641261 },
  { name: 'D340H', latitude: 41.036756, longitude: 29.289614 },
  { name: 'FH602', latitude: 41.038206, longitude: 36.920111 },
  { name: '70VOR', latitude: 41.038919, longitude: 39.652714 },
  { name: 'D272U', latitude: 41.0397, longitude: 39.329561 },
  { name: 'OKIPI', latitude: 41.039731, longitude: 29.695272 },
  { name: 'TUZWE', latitude: 41.039769, longitude: 28.181256 },
  { name: 'AGBET', latitude: 41.039906, longitude: 26.897425 },
  { name: 'FJ541', latitude: 41.040853, longitude: 27.503136 },
  { name: 'CI01', latitude: 41.041508, longitude: 20.6848 },
  { name: 'VP130', latitude: 41.042778, longitude: 16.826667 },
  { name: 'BCS35', latitude: 41.043031, longitude: 17.373492 },
  { name: 'D258F', latitude: 41.043558, longitude: 13.958344 },
  { name: 'D115L', latitude: 41.045711, longitude: 17.006681 },
  { name: 'GAYEM', latitude: 41.045833, longitude: 29.63 },
  { name: 'MT06', latitude: 41.045925, longitude: 14.048794 },
  { name: 'MQ06Z', latitude: 41.045978, longitude: 14.048778 },
  { name: 'FF04', latitude: 41.0482, longitude: 27.781767 },
  { name: 'D266Q', latitude: 41.048539, longitude: 13.714636 },
  { name: 'TIXAB', latitude: 41.048864, longitude: 27.780336 },
  { name: 'D245P', latitude: 41.049858, longitude: 16.446533 },
  { name: 'BD401', latitude: 41.049894, longitude: 16.446661 },
  { name: 'D067K', latitude: 41.049914, longitude: 40.022306 },
  { name: 'D265K', latitude: 41.050172, longitude: 13.846733 },
  { name: 'EKPIT', latitude: 41.050256, longitude: 39.611075 },
  { name: 'GUNGA', latitude: 41.050978, longitude: 28.994758 },
  { name: 'CI11', latitude: 41.051197, longitude: 39.609103 },
  { name: 'BC56A', latitude: 41.053378, longitude: 16.837186 },
  { name: 'D245O', latitude: 41.054192, longitude: 16.467933 },
  { name: 'D267L', latitude: 41.054456, longitude: 13.824417 },
  { name: 'RN604', latitude: 41.055697, longitude: 14.525314 },
  { name: 'CG923', latitude: 41.055936, longitude: 40.035883 },
  { name: 'CG681', latitude: 41.056472, longitude: 39.925697 },
  { name: 'TEMEL', latitude: 41.057222, longitude: 39.137222 },
  { name: 'CG680', latitude: 41.057453, longitude: 39.587686 },
  { name: 'DIRES', latitude: 41.057778, longitude: 19.1925 },
  { name: 'FM751', latitude: 41.057931, longitude: 27.116508 },
  { name: 'VP131', latitude: 41.058056, longitude: 17.096111 },
  { name: 'BA377', latitude: 41.058197, longitude: 28.903731 },
  { name: 'TROTA', latitude: 41.058611, longitude: 18.065833 },
  { name: 'KOFUN', latitude: 41.059167, longitude: 28.988056 },
  { name: 'BU213', latitude: 41.060794, longitude: 27.566511 },
  { name: 'BENTO', latitude: 41.060833, longitude: 14.663611 },
  { name: 'BD552', latitude: 41.06105, longitude: 16.488336 },
  { name: 'BD402', latitude: 41.062128, longitude: 16.968472 },
  { name: 'D115J', latitude: 41.062167, longitude: 16.968356 },
  { name: 'FM532', latitude: 41.062419, longitude: 29.426583 },
  { name: 'CF11', latitude: 41.062769, longitude: 39.570228 },
  { name: 'D329J', latitude: 41.062825, longitude: 24.519019 },
  { name: 'CB720', latitude: 41.063267, longitude: 38.484678 },
  { name: 'FN044', latitude: 41.063619, longitude: 27.807261 },
  { name: 'CD07Y', latitude: 41.063836, longitude: 16.500803 },
  { name: 'BU710', latitude: 41.063892, longitude: 27.805536 },
  { name: 'FM025', latitude: 41.063986, longitude: 28.460706 },
  { name: 'CI07Y', latitude: 41.064236, longitude: 16.499472 },
  { name: 'BD619', latitude: 41.064906, longitude: 16.501819 },
  { name: 'VP214', latitude: 41.065, longitude: 23.958333 },
  { name: 'FJ584', latitude: 41.067075, longitude: 30.156328 },
  { name: 'D245M', latitude: 41.067628, longitude: 16.508319 },
  { name: 'BD503', latitude: 41.067667, longitude: 16.508447 },
  { name: 'FM736', latitude: 41.067875, longitude: 28.292064 },
  { name: 'UNDER', latitude: 41.068056, longitude: 19.9475 },
  { name: 'CF23', latitude: 41.068325, longitude: 29.032461 },
  { name: 'VP132', latitude: 41.068611, longitude: 14.326667 },
  { name: 'FM556', latitude: 41.069239, longitude: 29.120675 },
  { name: 'FJ481', latitude: 41.069583, longitude: 30.200253 },
  { name: 'D064B', latitude: 41.069719, longitude: 14.119219 },
  { name: 'FRUGO', latitude: 41.070139, longitude: 28.731483 },
  { name: 'KESKN', latitude: 41.071508, longitude: 28.760314 },
  { name: 'CD07', latitude: 41.072269, longitude: 16.529569 },
  { name: 'FQ04', latitude: 41.072403, longitude: 27.820578 },
  { name: 'CI07', latitude: 41.072483, longitude: 16.528333 },
  { name: 'MIKOC', latitude: 41.073142, longitude: 28.818675 },
  { name: 'FH806', latitude: 41.07365, longitude: 36.594775 },
  { name: 'FD04', latitude: 41.074106, longitude: 27.818578 },
  { name: 'FM056', latitude: 41.075256, longitude: 28.392783 },
  { name: 'AFLEZ', latitude: 41.075306, longitude: 29.108636 },
  { name: 'CG025', latitude: 41.076353, longitude: 39.876328 },
  { name: 'CG082', latitude: 41.076561, longitude: 39.933689 },
  { name: 'TALAS', latitude: 41.076667, longitude: 21.916667 },
  { name: 'FM432', latitude: 41.076711, longitude: 29.466397 },
  { name: 'CI23', latitude: 41.076978, longitude: 29.051297 },
  { name: 'D115H', latitude: 41.078608, longitude: 16.930011 },
  { name: 'MAPIS', latitude: 41.078889, longitude: 16.549722 },
  { name: 'FM722', latitude: 41.079681, longitude: 27.330928 },
  { name: 'CQ07', latitude: 41.08185, longitude: 16.562617 },
  { name: 'FD07', latitude: 41.081897, longitude: 16.562453 },
  { name: 'FI07', latitude: 41.0819, longitude: 16.561325 },
  { name: 'BD556', latitude: 41.082125, longitude: 16.562156 },
  { name: 'FH603', latitude: 41.082564, longitude: 36.840139 },
  { name: 'D101O', latitude: 41.082836, longitude: 28.253344 },
  { name: 'FS044', latitude: 41.082869, longitude: 27.831869 },
  { name: 'FD01', latitude: 41.083889, longitude: 20.713889 },
  { name: 'FI01', latitude: 41.086061, longitude: 20.703269 },
  { name: 'GILOB', latitude: 41.088333, longitude: 14.103889 },
  { name: 'CG001', latitude: 41.090278, longitude: 39.466389 },
  { name: 'PATHO', latitude: 41.090336, longitude: 30.320414 },
  { name: 'IVNAP', latitude: 41.090833, longitude: 31.253333 },
  { name: 'D245I', latitude: 41.091269, longitude: 16.590753 },
  { name: 'CG543', latitude: 41.091383, longitude: 39.251469 },
  { name: 'FM343', latitude: 41.091567, longitude: 28.285292 },
  { name: 'CB520', latitude: 41.092192, longitude: 37.89305 },
  { name: 'FM757', latitude: 41.092217, longitude: 27.925889 },
  { name: 'BD609', latitude: 41.092364, longitude: 16.598036 },
  { name: 'HAVZA', latitude: 41.0925, longitude: 35.928611 },
  { name: 'FM396', latitude: 41.092508, longitude: 28.913056 },
  { name: '85DME', latitude: 41.093231, longitude: 16.6017 },
  { name: 'FM756', latitude: 41.093639, longitude: 27.7936 },
  { name: 'FM522', latitude: 41.095492, longitude: 30.142781 },
  { name: 'ARLATitude', latitude: 41.096944, longitude: 38.701944 },
  { name: 'D285H', latitude: 41.098908, longitude: 13.920064 },
  { name: 'D255N', latitude: 41.099303, longitude: 16.473475 },
  { name: 'VP133', latitude: 41.101667, longitude: 13.863889 },
  { name: 'IPSUD', latitude: 41.101681, longitude: 29.105092 },
  { name: 'RN519', latitude: 41.102022, longitude: 14.541997 },
  { name: 'FM074', latitude: 41.102564, longitude: 28.818269 },
  { name: 'TR28B', latitude: 41.103364, longitude: 19.25875 },
  { name: 'FM535', latitude: 41.104167, longitude: 29.18365 },
  { name: 'IPSAT', latitude: 41.105278, longitude: 38.605833 },
  { name: 'BU212', latitude: 41.105719, longitude: 27.631756 },
  { name: 'RN602', latitude: 41.106069, longitude: 14.185017 },
  { name: 'D136O', latitude: 41.106278, longitude: 14.185314 },
  { name: 'CB150', latitude: 41.106839, longitude: 37.679536 },
  { name: 'FM832', latitude: 41.10715, longitude: 27.957806 },
  { name: 'CG921', latitude: 41.107175, longitude: 39.857244 },
  { name: 'EKTOL', latitude: 41.107778, longitude: 15.171389 },
  { name: 'BD652', latitude: 41.107992, longitude: 17.115417 },
  { name: 'BCS41', latitude: 41.108642, longitude: 17.273661 },
  { name: 'VP135', latitude: 41.11, longitude: 14.214444 },
  { name: '41LOC', latitude: 41.11015, longitude: 16.660567 },
  { name: '38THR', latitude: 41.1106, longitude: 16.662164 },
  { name: '56VOR', latitude: 41.110725, longitude: 16.661167 },
  { name: 'GASVA', latitude: 41.110833, longitude: 14.3375 },
  { name: 'D153D', latitude: 41.111111, longitude: 20.771111 },
  { name: 'PIKUX', latitude: 41.113233, longitude: 33.407186 },
  { name: 'UVROX', latitude: 41.113783, longitude: 36.783736 },
  { name: 'BD506', latitude: 41.114889, longitude: 16.673367 },
  { name: 'MQ04', latitude: 41.116217, longitude: 27.887119 },
  { name: 'VP215', latitude: 41.116667, longitude: 25.409444 },
  { name: 'VP134', latitude: 41.116944, longitude: 13.932778 },
  { name: 'CG024', latitude: 41.117442, longitude: 39.743681 },
  { name: 'MD04', latitude: 41.117917, longitude: 27.885069 },
  { name: 'FM720', latitude: 41.1181, longitude: 27.067647 },
  { name: 'FM758', latitude: 41.118394, longitude: 28.098894 },
  { name: 'MA04', latitude: 41.119272, longitude: 27.889942 },
  { name: 'FM732', latitude: 41.119589, longitude: 27.916872 },
  { name: 'FM054', latitude: 41.121, longitude: 28.655753 },
  { name: '20LOC', latitude: 41.122458, longitude: 16.703919 },
  { name: '17THR', latitude: 41.122911, longitude: 16.705517 },
  { name: '35DME', latitude: 41.123119, longitude: 16.704619 },
  { name: '35VOR', latitude: 41.123308, longitude: 16.704383 },
  { name: 'DIKIZ', latitude: 41.123881, longitude: 28.759322 },
  { name: 'FJ719', latitude: 41.123917, longitude: 29.754325 },
  { name: 'NASVU', latitude: 41.123925, longitude: 28.730442 },
  { name: 'FM755', latitude: 41.123947, longitude: 27.644494 },
  { name: 'FJ522', latitude: 41.124339, longitude: 27.893556 },
  { name: 'VP136', latitude: 41.124722, longitude: 15.735278 },
  { name: 'DIGTI', latitude: 41.125278, longitude: 26.321389 },
  { name: 'OTPEX', latitude: 41.125433, longitude: 39.365833 },
  { name: 'ELNUG', latitude: 41.126425, longitude: 28.710142 },
  { name: 'BA087', latitude: 41.127, longitude: 29.316783 },
  { name: 'VP216', latitude: 41.128611, longitude: 25.185 },
  { name: 'MAKED', latitude: 41.129167, longitude: 22.516667 },
  { name: 'NONDU', latitude: 41.129722, longitude: 26.674722 },
  { name: 'VP137', latitude: 41.13, longitude: 16.544444 },
  { name: 'BD575', latitude: 41.130394, longitude: 16.731911 },
  { name: 'FH557', latitude: 41.130833, longitude: 36.351289 },
  { name: 'CG435', latitude: 41.131172, longitude: 40.163619 },
  { name: 'ML07', latitude: 41.131297, longitude: 16.735103 },
  { name: 'MD01', latitude: 41.131389, longitude: 20.726111 },
  { name: 'RN518', latitude: 41.131753, longitude: 14.605764 },
  { name: 'TEA16', latitude: 41.132058, longitude: 13.692461 },
  { name: 'MQ07', latitude: 41.132067, longitude: 16.735514 },
  { name: 'BRAV3', latitude: 41.132283, longitude: 16.75045 },
  { name: 'MD07', latitude: 41.132289, longitude: 16.735261 },
  { name: 'LETBU', latitude: 41.132778, longitude: 32.069167 },
  { name: 'ARSUG', latitude: 41.133056, longitude: 38.272222 },
  { name: 'LATitude42', latitude: 41.133078, longitude: 12.154017 },
  { name: 'ULGUR', latitude: 41.133333, longitude: 31.326944 },
  { name: 'FJ439', latitude: 41.135736, longitude: 29.282475 },
  { name: 'TIRER', latitude: 41.136389, longitude: 28.384167 },
  { name: 'FJ590', latitude: 41.136575, longitude: 27.108689 },
  { name: 'AGOTI', latitude: 41.137778, longitude: 14.647778 },
  { name: 'BD553', latitude: 41.139042, longitude: 16.449336 },
  { name: 'FM533', latitude: 41.139167, longitude: 29.360931 },
  { name: 'VP217', latitude: 41.139444, longitude: 24.8875 },
  { name: 'D148B', latitude: 41.143047, longitude: 20.7571 },
  { name: 'ORRIH', latitude: 41.143333, longitude: 20.588611 },
  { name: 'BU513', latitude: 41.143525, longitude: 27.460758 },
  { name: 'D143B', latitude: 41.144525, longitude: 20.760417 },
  { name: 'D086O', latitude: 41.145497, longitude: 28.265853 },
  { name: 'FM375', latitude: 41.147681, longitude: 28.271517 },
  { name: 'DRAMA', latitude: 41.148056, longitude: 24.145556 },
  { name: 'D273B', latitude: 41.148306, longitude: 16.732647 },
  { name: 'D203R', latitude: 41.148544, longitude: 19.537906 },
  { name: 'CG053', latitude: 41.14855, longitude: 40.150386 },
  { name: 'ENRAW', latitude: 41.149208, longitude: 28.381833 },
  { name: 'MD25', latitude: 41.1494, longitude: 16.797425 },
  { name: 'VEBOX', latitude: 41.15, longitude: 15.529722 },
  { name: 'FJ774', latitude: 41.150561, longitude: 30.579933 },
  { name: 'D165G', latitude: 41.150617, longitude: 36.576311 },
  { name: 'CB160', latitude: 41.150933, longitude: 37.778606 },
  { name: 'RN411', latitude: 41.150983, longitude: 14.711689 },
  { name: 'FJ487', latitude: 41.151106, longitude: 29.741367 },
  { name: 'FM395', latitude: 41.153156, longitude: 28.712747 },
  { name: 'VP138', latitude: 41.153333, longitude: 15.085833 },
  { name: 'VP139', latitude: 41.153889, longitude: 16.413333 },
  { name: 'XERIS', latitude: 41.153889, longitude: 24.140278 },
  { name: 'CG015', latitude: 41.154442, longitude: 39.682178 },
  { name: 'D118L', latitude: 41.155842, longitude: 36.773131 },
  { name: 'CB540', latitude: 41.156297, longitude: 38.015919 },
  { name: 'AMSOR', latitude: 41.157222, longitude: 14.869167 },
  { name: 'CG050', latitude: 41.157344, longitude: 39.139014 },
  { name: 'CG048', latitude: 41.158358, longitude: 39.257711 },
  { name: 'NUDMU', latitude: 41.159686, longitude: 29.598936 },
  { name: 'PEPIX', latitude: 41.16, longitude: 12.188611 },
  { name: 'FM735', latitude: 41.160003, longitude: 28.197208 },
  { name: 'FJ773', latitude: 41.160589, longitude: 30.895625 },
  { name: 'FM431', latitude: 41.160786, longitude: 29.394519 },
  { name: 'FH805', latitude: 41.160861, longitude: 36.473228 },
  { name: 'BAXOM', latitude: 41.163181, longitude: 36.694267 },
  { name: 'ERANA', latitude: 41.163333, longitude: 22.239444 },
  { name: 'TEA27', latitude: 41.163336, longitude: 14.540286 },
  { name: 'RN404', latitude: 41.163967, longitude: 14.540556 },
  { name: 'MD22', latitude: 41.164, longitude: 27.963069 },
  { name: 'ERKOS', latitude: 41.164444, longitude: 37.883889 },
  { name: 'OVDEL', latitude: 41.164842, longitude: 36.696306 },
  { name: 'FJ772', latitude: 41.1656, longitude: 31.06475 },
  { name: 'MQ22', latitude: 41.166061, longitude: 27.961172 },
  { name: 'BD554', latitude: 41.166311, longitude: 16.544811 },
  { name: 'BU720', latitude: 41.167225, longitude: 27.686744 },
  { name: 'ULTAX', latitude: 41.167814, longitude: 36.196831 },
  { name: 'FJ583', latitude: 41.167969, longitude: 30.125931 },
  { name: 'BD621', latitude: 41.169164, longitude: 16.344008 },
  { name: 'D078K', latitude: 41.169531, longitude: 17.016839 },
  { name: 'BD507', latitude: 41.169833, longitude: 16.536231 },
  { name: 'FF25', latitude: 41.169967, longitude: 16.881389 },
  { name: 'D110M', latitude: 41.171453, longitude: 36.817328 },
  { name: 'TI605', latitude: 41.172222, longitude: 19.5975 },
  { name: 'FM752', latitude: 41.175711, longitude: 27.240725 },
  { name: 'BD571', latitude: 41.176075, longitude: 16.893656 },
  { name: 'FH710', latitude: 41.17625, longitude: 36.6761 },
  { name: 'RF471', latitude: 41.176556, longitude: 12.129361 },
  { name: 'CB530', latitude: 41.177247, longitude: 37.815231 },
  { name: 'D271O', latitude: 41.177911, longitude: 27.605947 },
  { name: 'D203P', latitude: 41.178292, longitude: 19.557867 },
  { name: 'OLGAV', latitude: 41.178536, longitude: 28.729378 },
  { name: 'D245O', latitude: 41.178703, longitude: 36.23815 },
  { name: 'EMZIQ', latitude: 41.178853, longitude: 28.758281 },
  { name: 'FM048', latitude: 41.179367, longitude: 28.5193 },
  { name: 'FH716', latitude: 41.179547, longitude: 36.175561 },
  { name: 'FM060', latitude: 41.179786, longitude: 28.554331 },
  { name: 'FJ482', latitude: 41.180094, longitude: 30.167031 },
  { name: 'CD25', latitude: 41.180228, longitude: 16.923389 },
  { name: 'FM047', latitude: 41.180828, longitude: 28.645161 },
  { name: 'CB550', latitude: 41.180861, longitude: 38.200186 },
  { name: 'UPNEW', latitude: 41.181461, longitude: 28.709058 },
  { name: 'FM052', latitude: 41.181756, longitude: 28.729314 },
  { name: 'CB730', latitude: 41.181956, longitude: 38.412533 },
  { name: 'BD403', latitude: 41.182408, longitude: 17.360817 },
  { name: 'VP141', latitude: 41.182778, longitude: 16.671667 },
  { name: 'FM072', latitude: 41.182817, longitude: 28.823403 },
  { name: 'VP142', latitude: 41.183056, longitude: 16.060556 },
  { name: 'D165E', latitude: 41.183431, longitude: 36.568414 },
  { name: 'TINKI', latitude: 41.183889, longitude: 19.751111 },
  { name: 'FM073', latitude: 41.184086, longitude: 28.944808 },
  { name: 'PIKEL', latitude: 41.184167, longitude: 17.158333 },
  { name: 'D136R', latitude: 41.185914, longitude: 19.9736 },
  { name: 'D065G', latitude: 41.185992, longitude: 16.920703 },
  { name: 'BD501', latitude: 41.186028, longitude: 16.920822 },
  { name: 'BD651', latitude: 41.186317, longitude: 17.014711 },
  { name: 'FM831', latitude: 41.186803, longitude: 28.017242 },
  { name: 'EMLAD', latitude: 41.186944, longitude: 37.618056 },
  { name: 'BA30B', latitude: 41.187156, longitude: 17.436039 },
  { name: 'BD601', latitude: 41.187414, longitude: 16.933969 },
  { name: 'AL710', latitude: 41.188383, longitude: 33.776747 },
  { name: 'OSDUT', latitude: 41.19, longitude: 37.554444 },
  { name: 'IPKAM', latitude: 41.190833, longitude: 14.653333 },
  { name: 'INCEM', latitude: 41.190878, longitude: 28.555847 },
  { name: 'BA355', latitude: 41.191514, longitude: 26.946653 },
  { name: 'D218R', latitude: 41.192764, longitude: 19.452292 },
  { name: 'EBELA', latitude: 41.193333, longitude: 18.908889 },
  { name: 'ATPUM', latitude: 41.193333, longitude: 40.375944 },
  { name: 'VP143', latitude: 41.193611, longitude: 14.122222 },
  { name: 'GEFME', latitude: 41.194717, longitude: 30.160186 },
  { name: 'VOLTU', latitude: 41.195, longitude: 14.085278 },
  { name: 'LOPTI', latitude: 41.195278, longitude: 24.434167 },
  { name: 'TI606', latitude: 41.19535, longitude: 19.904775 },
  { name: 'FM721', latitude: 41.196022, longitude: 27.238233 },
  { name: 'VP144', latitude: 41.1975, longitude: 16.600556 },
  { name: 'FH441', latitude: 41.197606, longitude: 36.646225 },
  { name: 'D120G', latitude: 41.198806, longitude: 36.675778 },
  { name: 'YASEN', latitude: 41.198889, longitude: 29.516111 },
  { name: 'TUTLU', latitude: 41.198914, longitude: 39.534931 },
  { name: 'CG014', latitude: 41.199464, longitude: 39.535692 },
  { name: 'FM534', latitude: 41.200383, longitude: 29.271086 },
  { name: 'FN223', latitude: 41.200636, longitude: 28.012092 },
  { name: 'ENOXA', latitude: 41.201389, longitude: 17.668889 },
  { name: 'BD572', latitude: 41.202047, longitude: 16.986058 },
  { name: 'FS235', latitude: 41.202383, longitude: 28.053556 },
  { name: 'FM760', latitude: 41.202553, longitude: 28.170572 },
  { name: 'FH604', latitude: 41.202706, longitude: 36.884489 },
  { name: 'FJ591', latitude: 41.203678, longitude: 27.405783 },
  { name: '55DME', latitude: 41.204011, longitude: 36.639014 },
  { name: 'FH215', latitude: 41.206361, longitude: 36.634197 },
  { name: 'FM733', latitude: 41.206847, longitude: 27.981936 },
  { name: 'AL711', latitude: 41.207108, longitude: 33.557281 },
  { name: 'D008R', latitude: 41.207542, longitude: 13.026519 },
  { name: 'FM754', latitude: 41.208736, longitude: 27.505122 },
  { name: 'D065K', latitude: 41.209764, longitude: 17.003217 },
  { name: 'CG823', latitude: 41.211081, longitude: 40.193297 },
  { name: 'ULMAR', latitude: 41.211111, longitude: 27.186944 },
  { name: 'FD22', latitude: 41.212533, longitude: 28.066094 },
  { name: 'BU511', latitude: 41.212619, longitude: 28.032725 },
  { name: 'D074T', latitude: 41.212783, longitude: 17.208464 },
  { name: 'BEPRU', latitude: 41.213392, longitude: 35.905278 },
  { name: 'TERME', latitude: 41.214444, longitude: 37.224722 },
  { name: 'OSMAL', latitude: 41.215, longitude: 17.070833 },
  { name: 'ERIDU', latitude: 41.215333, longitude: 39.169778 },
  { name: 'VICTR', latitude: 41.215425, longitude: 16.530767 },
  { name: 'ELFOM', latitude: 41.216319, longitude: 31.969867 },
  { name: 'D180H', latitude: 41.217403, longitude: 33.784461 },
  { name: 'D007C', latitude: 41.221528, longitude: 20.748833 },
  { name: 'RIFFI', latitude: 41.224444, longitude: 12.532222 },
  { name: 'T', latitude: 41.224733, longitude: 13.848717 },
  { name: 'D245G', latitude: 41.225369, longitude: 36.403631 },
  { name: 'RIZKO', latitude: 41.226036, longitude: 28.865942 },
  { name: 'BRAV4', latitude: 41.226042, longitude: 13.121269 },
  { name: 'CB230', latitude: 41.226058, longitude: 37.904775 },
  { name: 'FQ22', latitude: 41.227394, longitude: 28.051556 },
  { name: 'RF469', latitude: 41.227583, longitude: 12.345389 },
  { name: 'FM430', latitude: 41.227861, longitude: 29.296117 },
  { name: 'FATIH', latitude: 41.228056, longitude: 28.799444 },
  { name: 'MD36', latitude: 41.228725, longitude: 28.757675 },
  { name: 'ML36', latitude: 41.228803, longitude: 28.757344 },
  { name: 'CG052', latitude: 41.229019, longitude: 39.988158 },
  { name: 'MD35R', latitude: 41.229467, longitude: 28.727406 },
  { name: 'ML35R', latitude: 41.229494, longitude: 28.728381 },
  { name: 'FM864', latitude: 41.230594, longitude: 28.194508 },
  { name: 'ML34L', latitude: 41.232106, longitude: 28.708067 },
  { name: 'MD34L', latitude: 41.232133, longitude: 28.709406 },
  { name: 'MICPO', latitude: 41.233056, longitude: 23.564722 },
  { name: 'OKTIM', latitude: 41.233056, longitude: 23.924444 },
  { name: 'TUNCA', latitude: 41.233056, longitude: 31.031111 },
  { name: 'GONOT', latitude: 41.233333, longitude: 18.391667 },
  { name: 'FM464', latitude: 41.233458, longitude: 29.181811 },
  { name: 'CG920', latitude: 41.2336, longitude: 39.411047 },
  { name: 'INGOV', latitude: 41.233711, longitude: 39.423722 },
  { name: 'FJ521', latitude: 41.234414, longitude: 27.514267 },
  { name: 'BD603', latitude: 41.23445, longitude: 16.525761 },
  { name: 'D011T', latitude: 41.235433, longitude: 13.064303 },
  { name: 'PEZAK', latitude: 41.236111, longitude: 19.697222 },
  { name: 'D245E', latitude: 41.236658, longitude: 36.445203 },
  { name: 'AZBAN', latitude: 41.237675, longitude: 29.537456 },
  { name: 'BD628', latitude: 41.238186, longitude: 16.610733 },
  { name: 'REBAH', latitude: 41.238361, longitude: 28.343781 },
  { name: 'D065P', latitude: 41.239394, longitude: 17.106447 },
  { name: 'BD502', latitude: 41.239431, longitude: 17.106564 },
  { name: 'VP146', latitude: 41.239722, longitude: 16.506667 },
  { name: 'BU211', latitude: 41.240203, longitude: 27.827936 },
  { name: 'AL510', latitude: 41.241661, longitude: 33.784811 },
  { name: 'VOR11', latitude: 41.241997, longitude: 19.742442 },
  { name: 'CG051', latitude: 41.244731, longitude: 39.8126 },
  { name: 'FM165', latitude: 41.245144, longitude: 28.461922 },
  { name: 'BD607', latitude: 41.245394, longitude: 16.228103 },
  { name: 'BA071', latitude: 41.245525, longitude: 29.104439 },
  { name: 'CG436', latitude: 41.246367, longitude: 39.820589 },
  { name: 'DISOR', latitude: 41.247222, longitude: 22.758333 },
  { name: 'FM187', latitude: 41.247314, longitude: 28.930714 },
  { name: 'EVTAD', latitude: 41.247778, longitude: 29.656667 },
  { name: 'FH804', latitude: 41.247811, longitude: 36.351528 },
  { name: 'D062O', latitude: 41.248725, longitude: 28.239725 },
  { name: 'FM019', latitude: 41.248825, longitude: 28.518703 },
  { name: 'FM021', latitude: 41.249261, longitude: 28.552925 },
  { name: 'CF36', latitude: 41.249586, longitude: 32.086556 },
  { name: 'VP147', latitude: 41.25, longitude: 14.066667 },
  { name: 'BEDPI', latitude: 41.25, longitude: 14.769444 },
  { name: 'DENEN', latitude: 41.250556, longitude: 31.410556 },
  { name: 'D065R', latitude: 41.251222, longitude: 17.147764 },
  { name: 'MA290', latitude: 41.2515, longitude: 36.588986 },
  { name: 'FM830', latitude: 41.252056, longitude: 28.102089 },
  { name: 'D004O', latitude: 41.253244, longitude: 28.582467 },
  { name: 'FM034', latitude: 41.253558, longitude: 28.948806 },
  { name: 'ARBAN', latitude: 41.254444, longitude: 19.808333 },
  { name: 'BD631', latitude: 41.255261, longitude: 16.804169 },
  { name: 'D090G', latitude: 41.255625, longitude: 36.703011 },
  { name: 'BU510', latitude: 41.256208, longitude: 28.099642 },
  { name: 'BD504', latitude: 41.257394, longitude: 16.481189 },
  { name: 'FJ582', latitude: 41.257708, longitude: 30.057694 },
  { name: 'D090E', latitude: 41.258467, longitude: 36.658975 },
  { name: 'AL511', latitude: 41.2604, longitude: 33.565169 },
  { name: 'FH711', latitude: 41.26075, longitude: 36.782747 },
  { name: 'CG010', latitude: 41.261144, longitude: 39.663517 },
  { name: 'DE17R', latitude: 41.261894, longitude: 28.725256 },
  { name: 'DE17L', latitude: 41.261922, longitude: 28.727761 },
  { name: 'BD574', latitude: 41.26195, longitude: 16.881919 },
  { name: 'FM560', latitude: 41.262181, longitude: 29.15935 },
  { name: 'DER18', latitude: 41.262244, longitude: 28.7567 },
  { name: 'FJ486', latitude: 41.262456, longitude: 29.787628 },
  { name: 'FH715', latitude: 41.263589, longitude: 36.416022 },
  { name: 'RUXEV', latitude: 41.263611, longitude: 34.862222 },
  { name: 'DE16R', latitude: 41.264847, longitude: 28.707419 },
  { name: 'DE16L', latitude: 41.264875, longitude: 28.709925 },
  { name: 'VP218', latitude: 41.266944, longitude: 24.501944 },
  { name: 'D058P', latitude: 41.269089, longitude: 17.088689 },
  { name: 'GOLEM', latitude: 41.269444, longitude: 19.536667 },
  { name: 'TI506', latitude: 41.270278, longitude: 19.739722 },
  { name: 'MM13', latitude: 41.270278, longitude: 36.545028 },
  { name: 'TACIR', latitude: 41.271358, longitude: 28.313433 },
  { name: 'ADBOR', latitude: 41.273139, longitude: 13.041858 },
  { name: 'TI505', latitude: 41.275833, longitude: 19.610833 },
  { name: 'RF462', latitude: 41.276306, longitude: 12.289389 },
  { name: 'VP148', latitude: 41.276389, longitude: 16.418889 },
  { name: 'REFUS', latitude: 41.276389, longitude: 23.805 },
  { name: 'VP149', latitude: 41.276667, longitude: 15.267778 },
  { name: 'VURKE', latitude: 41.276944, longitude: 18.096944 },
  { name: 'FM734', latitude: 41.278342, longitude: 28.074864 },
  { name: 'FJ483', latitude: 41.278403, longitude: 30.092336 },
  { name: '15LOC', latitude: 41.278989, longitude: 36.532869 },
  { name: 'BA012', latitude: 41.279758, longitude: 28.797167 },
  { name: 'BD573', latitude: 41.280203, longitude: 16.947567 },
  { name: 'VP151', latitude: 41.283611, longitude: 15.766667 },
  { name: 'CB210', latitude: 41.284019, longitude: 38.340542 },
  { name: 'KVITO', latitude: 41.284569, longitude: 27.86565 },
  { name: 'TIPAP', latitude: 41.285556, longitude: 12.083889 },
  { name: 'FENER', latitude: 41.286389, longitude: 28.357778 },
  { name: 'D159I', latitude: 41.286439, longitude: 15.591689 },
  { name: 'FM761', latitude: 41.287089, longitude: 28.042861 },
  { name: 'D111R', latitude: 41.288717, longitude: 20.078872 },
  { name: 'MA13', latitude: 41.289403, longitude: 36.518039 },
  { name: 'DER36', latitude: 41.289792, longitude: 28.756178 },
  { name: 'ORMAN', latitude: 41.291917, longitude: 33.385889 },
  { name: 'BPL37', latitude: 41.292139, longitude: 17.461058 },
  { name: 'AS113', latitude: 41.292419, longitude: 31.8794 },
  { name: 'CG019', latitude: 41.292544, longitude: 39.230264 },
  { name: 'KONUT', latitude: 41.292778, longitude: 12.016667 },
  { name: 'FM753', latitude: 41.293356, longitude: 27.365392 },
  { name: 'FM049', latitude: 41.296106, longitude: 28.516811 },
  { name: 'BAR30', latitude: 41.297119, longitude: 17.407342 },
  { name: 'DE34L', latitude: 41.298603, longitude: 28.706753 },
  { name: 'DE34R', latitude: 41.298631, longitude: 28.709258 },
  { name: 'DE35L', latitude: 41.298803, longitude: 28.724536 },
  { name: 'DE35R', latitude: 41.298831, longitude: 28.727044 },
  { name: 'PAZAR', latitude: 41.298889, longitude: 29.999444 },
  { name: 'FH605', latitude: 41.301422, longitude: 36.705606 },
  { name: 'FH442', latitude: 41.302564, longitude: 36.499917 },
  { name: 'NEBAS', latitude: 41.304167, longitude: 14.491389 },
  { name: 'OSRIN', latitude: 41.304583, longitude: 33.474722 },
  { name: 'RF463', latitude: 41.306056, longitude: 12.416028 },
  { name: 'MOLUX', latitude: 41.306667, longitude: 14.806944 },
  { name: 'FJ574', latitude: 41.307233, longitude: 29.215481 },
  { name: 'REDGI', latitude: 41.3076, longitude: 28.551675 },
  { name: 'HANCI', latitude: 41.307847, longitude: 28.942975 },
  { name: 'FM863', latitude: 41.308658, longitude: 28.076611 },
  { name: 'PITOF', latitude: 41.308683, longitude: 29.436611 },
  { name: 'BD606', latitude: 41.308725, longitude: 16.525317 },
  { name: 'BD602', latitude: 41.310711, longitude: 16.888906 },
  { name: 'TI607', latitude: 41.311972, longitude: 19.990619 },
  { name: '44LOC', latitude: 41.312369, longitude: 36.486164 },
  { name: 'BA457', latitude: 41.3132, longitude: 28.379297 },
  { name: 'MA18', latitude: 41.313303, longitude: 28.755733 },
  { name: 'FM463', latitude: 41.314111, longitude: 29.313844 },
  { name: 'UFKOZ', latitude: 41.314264, longitude: 32.084153 },
  { name: 'FH216', latitude: 41.314572, longitude: 36.770764 },
  { name: 'FM865', latitude: 41.314689, longitude: 28.266433 },
  { name: 'AHJET', latitude: 41.314964, longitude: 31.973272 },
  { name: 'CG049', latitude: 41.315986, longitude: 39.974656 },
  { name: '60VOR', latitude: 41.316631, longitude: 19.732064 },
  { name: 'FM342', latitude: 41.317778, longitude: 28.460278 },
  { name: 'NEGOT', latitude: 41.32, longitude: 22.392222 },
  { name: 'MA17L', latitude: 41.320825, longitude: 28.726614 },
  { name: 'MA16R', latitude: 41.321175, longitude: 28.706303 },
  { name: 'FM367', latitude: 41.321444, longitude: 28.924325 },
  { name: 'ADMOP', latitude: 41.321944, longitude: 12.119444 },
  { name: 'ML16R', latitude: 41.322164, longitude: 28.706289 },
  { name: 'MD16R', latitude: 41.322169, longitude: 28.707322 },
  { name: 'D294G', latitude: 41.322344, longitude: 36.413686 },
  { name: 'FJ438', latitude: 41.322669, longitude: 29.053147 },
  { name: 'ML17L', latitude: 41.32275, longitude: 28.726586 },
  { name: 'MD17L', latitude: 41.322836, longitude: 28.725244 },
  { name: 'D310E', latitude: 41.324222, longitude: 36.470386 },
  { name: 'NENDO', latitude: 41.325278, longitude: 34.246111 },
  { name: 'D254H', latitude: 41.326361, longitude: 33.626264 },
  { name: 'APFIB', latitude: 41.327222, longitude: 16.763611 },
  { name: 'D345M', latitude: 41.328078, longitude: 16.604283 },
  { name: 'FJ581', latitude: 41.328394, longitude: 29.957483 },
  { name: 'D254G', latitude: 41.32895, longitude: 33.647589 },
  { name: 'RUGAS', latitude: 41.329444, longitude: 22.791389 },
  { name: 'D254F', latitude: 41.332011, longitude: 33.669336 },
  { name: 'FH801', latitude: 41.332606, longitude: 36.457853 },
  { name: 'DOBAR', latitude: 41.332778, longitude: 20.494722 },
  { name: 'FS35', latitude: 41.333217, longitude: 19.729756 },
  { name: 'NEKPI', latitude: 41.333333, longitude: 13.083333 },
  { name: 'D321N', latitude: 41.334969, longitude: 16.598053 },
  { name: 'FM561', latitude: 41.336658, longitude: 29.281247 },
  { name: 'SKJ38', latitude: 41.336667, longitude: 21.913056 },
  { name: 'OS28A', latitude: 41.337156, longitude: 12.215906 },
  { name: 'FH556', latitude: 41.338664, longitude: 36.223811 },
  { name: 'FF131', latitude: 41.339219, longitude: 36.453692 },
  { name: 'TOLUN', latitude: 41.34, longitude: 38.79 },
  { name: 'OS28B', latitude: 41.340328, longitude: 12.313031 },
  { name: 'D170L', latitude: 41.342492, longitude: 12.950414 },
  { name: 'FF36', latitude: 41.342972, longitude: 32.086556 },
  { name: 'D037P', latitude: 41.345383, longitude: 17.008722 },
  { name: 'FS33', latitude: 41.346872, longitude: 15.613928 },
  { name: 'TUDVO', latitude: 41.347164, longitude: 39.516906 },
  { name: 'D006E', latitude: 41.347472, longitude: 36.569069 },
  { name: 'D310G', latitude: 41.347697, longitude: 36.438953 },
  { name: 'FM465', latitude: 41.348322, longitude: 29.091853 },
  { name: 'FH712', latitude: 41.348394, longitude: 36.660744 },
  { name: '70DME', latitude: 41.349131, longitude: 36.440872 },
  { name: 'MA18', latitude: 41.349386, longitude: 33.801158 },
  { name: 'PIPUR', latitude: 41.351111, longitude: 33.238889 },
  { name: 'D141N', latitude: 41.352094, longitude: 13.100167 },
  { name: 'FN331', latitude: 41.35335, longitude: 15.600358 },
  { name: 'D148M', latitude: 41.354408, longitude: 13.061047 },
  { name: 'VP152', latitude: 41.355278, longitude: 15.307778 },
  { name: 'FJ484', latitude: 41.35585, longitude: 29.982578 },
  { name: 'RF461', latitude: 41.355861, longitude: 12.256417 },
  { name: 'D033P', latitude: 41.357142, longitude: 16.989556 },
  { name: 'FJ572', latitude: 41.357272, longitude: 27.664344 },
  { name: 'FJ771', latitude: 41.357353, longitude: 31.206556 },
  { name: 'OSDAX', latitude: 41.358056, longitude: 16.576389 },
  { name: '35VOR', latitude: 41.358092, longitude: 19.726292 },
  { name: 'FM426', latitude: 41.358914, longitude: 29.296231 },
  { name: 'D285Q', latitude: 41.360981, longitude: 36.194842 },
  { name: 'FN332', latitude: 41.362083, longitude: 15.592611 },
  { name: 'D321P', latitude: 41.362192, longitude: 16.572483 },
  { name: 'D216E', latitude: 41.36585, longitude: 15.459786 },
  { name: 'ABTEN', latitude: 41.366322, longitude: 36.410569 },
  { name: 'INDUG', latitude: 41.368969, longitude: 29.687739 },
  { name: 'BRARI', latitude: 41.372222, longitude: 19.855 },
  { name: 'EZGEC', latitude: 41.373172, longitude: 28.7546 },
  { name: 'EKNOX', latitude: 41.373611, longitude: 35.921214 },
  { name: 'FJ580', latitude: 41.373778, longitude: 29.834056 },
  { name: 'MARIK', latitude: 41.376944, longitude: 19.639722 },
  { name: 'UVOKA', latitude: 41.379722, longitude: 16.863611 },
  { name: 'FM018', latitude: 41.379772, longitude: 28.515742 },
  { name: 'FM020', latitude: 41.380208, longitude: 28.550033 },
  { name: 'D006G', latitude: 41.380261, longitude: 36.577164 },
  { name: 'RIMBO', latitude: 41.380953, longitude: 28.55225 },
  { name: 'FM017', latitude: 41.381314, longitude: 28.641064 },
  { name: 'BU512', latitude: 41.381572, longitude: 27.837283 },
  { name: 'AS112', latitude: 41.381753, longitude: 32.080947 },
  { name: 'MIVPA', latitude: 41.381983, longitude: 28.705103 },
  { name: 'ATBES', latitude: 41.382208, longitude: 28.725419 },
  { name: 'FM012', latitude: 41.382272, longitude: 28.725342 },
  { name: 'FM032', latitude: 41.383331, longitude: 28.825458 },
  { name: 'VP153', latitude: 41.383333, longitude: 15.905 },
  { name: 'FM033', latitude: 41.384497, longitude: 28.946708 },
  { name: 'FF13', latitude: 41.384944, longitude: 36.394503 },
  { name: 'YUZVE', latitude: 41.385325, longitude: 29.495872 },
  { name: 'FM827', latitude: 41.385489, longitude: 28.099767 },
  { name: 'FJ573', latitude: 41.385622, longitude: 28.027853 },
  { name: 'D294O', latitude: 41.385633, longitude: 36.257819 },
  { name: 'RF466', latitude: 41.385667, longitude: 12.383167 },
  { name: 'CF13', latitude: 41.386933, longitude: 36.381736 },
  { name: 'D183N', latitude: 41.389375, longitude: 12.461372 },
  { name: 'BA072', latitude: 41.38955, longitude: 29.103797 },
  { name: 'RONIV', latitude: 41.391944, longitude: 12.039167 },
  { name: 'FH802', latitude: 41.394389, longitude: 36.809531 },
  { name: 'KERAB', latitude: 41.394578, longitude: 36.535808 },
  { name: 'OS27B', latitude: 41.395456, longitude: 12.490217 },
  { name: 'FM762', latitude: 41.396033, longitude: 27.953167 },
  { name: 'FJ576', latitude: 41.396858, longitude: 29.517042 },
  { name: 'FM866', latitude: 41.398733, longitude: 28.338544 },
  { name: 'D145J', latitude: 41.399633, longitude: 13.035414 },
  { name: 'AZTAF', latitude: 41.400858, longitude: 32.085189 },
  { name: 'ATFIR', latitude: 41.401667, longitude: 23.774722 },
  { name: 'EKSUK', latitude: 41.4025, longitude: 34.337222 },
  { name: 'EVIVI', latitude: 41.402778, longitude: 23.455556 },
  { name: 'RF486', latitude: 41.404194, longitude: 12.445083 },
  { name: 'FJ485', latitude: 41.405581, longitude: 29.847353 },
  { name: 'IDENU', latitude: 41.407222, longitude: 12.408611 },
  { name: 'D005P', latitude: 41.407767, longitude: 16.831883 },
  { name: 'D170H', latitude: 41.4087, longitude: 12.93965 },
  { name: 'PINAR', latitude: 41.409167, longitude: 19.797778 },
  { name: 'FM862', latitude: 41.409247, longitude: 27.993853 },
  { name: 'FM823', latitude: 41.414314, longitude: 27.446631 },
  { name: 'EKMUR', latitude: 41.414444, longitude: 16.523333 },
  { name: 'MS33', latitude: 41.416181, longitude: 15.544692 },
  { name: 'ADEVU', latitude: 41.416467, longitude: 33.811367 },
  { name: 'RF484', latitude: 41.419, longitude: 12.508528 },
  { name: 'FM462', latitude: 41.420258, longitude: 29.409172 },
  { name: 'NETUN', latitude: 41.420556, longitude: 12.259167 },
  { name: 'RODIP', latitude: 41.420833, longitude: 24.701111 },
  { name: 'ADMOR', latitude: 41.423575, longitude: 36.330106 },
  { name: 'D110R', latitude: 41.425114, longitude: 16.054042 },
  { name: 'D147H', latitude: 41.425544, longitude: 13.006675 },
  { name: 'IPNUD', latitude: 41.426767, longitude: 16.055181 },
  { name: 'AKCAK', latitude: 41.428889, longitude: 30.724722 },
  { name: 'ANEXU', latitude: 41.4293, longitude: 33.590256 },
  { name: 'D268Z', latitude: 41.429828, longitude: 19.142458 },
  { name: 'OS27A', latitude: 41.430036, longitude: 12.572056 },
  { name: 'VEGIM', latitude: 41.430936, longitude: 14.287928 },
  { name: 'ISSIZ', latitude: 41.432367, longitude: 28.753475 },
  { name: 'RF483', latitude: 41.433778, longitude: 12.572028 },
  { name: 'FM466', latitude: 41.434428, longitude: 29.024181 },
  { name: 'VI56A', latitude: 41.434433, longitude: 17.149578 },
  { name: 'FM562', latitude: 41.434667, longitude: 29.369217 },
  { name: 'IRDUN', latitude: 41.434917, longitude: 13.059083 },
  { name: 'ANAXA', latitude: 41.435278, longitude: 12.223333 },
  { name: 'UMRES', latitude: 41.436111, longitude: 19.431389 },
  { name: 'FM061', latitude: 41.4379, longitude: 28.571344 },
  { name: 'D173K', latitude: 41.438572, longitude: 12.510781 },
  { name: 'FM425', latitude: 41.439567, longitude: 29.374847 },
  { name: 'FH713', latitude: 41.439594, longitude: 36.533239 },
  { name: 'VP154', latitude: 41.440278, longitude: 12.926389 },
  { name: 'CF18Z', latitude: 41.441319, longitude: 33.815011 },
  { name: 'VI43A', latitude: 41.441647, longitude: 16.770131 },
  { name: 'D170F', latitude: 41.441803, longitude: 12.934258 },
  { name: 'NEDBA', latitude: 41.442014, longitude: 28.703917 },
  { name: 'LADUX', latitude: 41.442239, longitude: 28.724256 },
  { name: 'UNASA', latitude: 41.445, longitude: 19.300278 },
  { name: 'D133H', latitude: 41.445083, longitude: 13.041214 },
  { name: 'SIPRO', latitude: 41.446019, longitude: 13.680528 },
  { name: 'RF622', latitude: 41.447833, longitude: 12.283194 },
  { name: 'VP155', latitude: 41.448889, longitude: 12.624167 },
  { name: 'FM908', latitude: 41.449525, longitude: 28.212119 },
  { name: 'D348F', latitude: 41.449681, longitude: 33.784431 },
  { name: 'AFGAR', latitude: 41.450278, longitude: 27.806944 },
  { name: 'D181U', latitude: 41.450531, longitude: 12.553833 },
  { name: 'FD048', latitude: 41.450903, longitude: 15.565317 },
  { name: 'FM824', latitude: 41.452297, longitude: 27.6411 },
  { name: 'TEPKI', latitude: 41.453333, longitude: 32.992778 },
  { name: 'MA36', latitude: 41.454703, longitude: 32.086556 },
  { name: 'UPKAG', latitude: 41.454722, longitude: 34.135278 },
  { name: 'FM408', latitude: 41.456111, longitude: 29.172444 },
  { name: 'D151K', latitude: 41.456642, longitude: 12.600664 },
  { name: 'AL610', latitude: 41.457103, longitude: 33.817531 },
  { name: 'FM921', latitude: 41.457508, longitude: 29.170664 },
  { name: 'RODON', latitude: 41.458333, longitude: 19.1 },
  { name: 'FJ571', latitude: 41.45905, longitude: 27.363581 },
  { name: 'CT29', latitude: 41.459303, longitude: 15.952978 },
  { name: 'D110M', latitude: 41.459303, longitude: 15.952978 },
  { name: 'D165E', latitude: 41.459553, longitude: 12.941097 },
  { name: 'FM081', latitude: 41.459633, longitude: 28.834778 },
  { name: 'VAGSI', latitude: 41.460883, longitude: 29.947256 },
  { name: 'EVLEK', latitude: 41.461222, longitude: 36.277 },
  { name: 'ERTAS', latitude: 41.461389, longitude: 29.146667 },
  { name: 'D151W', latitude: 41.461822, longitude: 12.468997 },
  { name: 'ATVEP', latitude: 41.462222, longitude: 26.953611 },
  { name: 'FM166', latitude: 41.463772, longitude: 28.290275 },
  { name: 'RF467', latitude: 41.46525, longitude: 12.350222 },
  { name: 'FM035', latitude: 41.465658, longitude: 28.726236 },
  { name: 'FF178', latitude: 41.466872, longitude: 33.807981 },
  { name: 'FD30', latitude: 41.469103, longitude: 13.010458 },
  { name: 'FH606', latitude: 41.470294, longitude: 36.397083 },
  { name: 'ATAKI', latitude: 41.471597, longitude: 36.055886 },
  { name: 'TOBUP', latitude: 41.472222, longitude: 12.472778 },
  { name: 'EKREX', latitude: 41.472881, longitude: 36.735389 },
  { name: 'LIZTA', latitude: 41.473047, longitude: 28.990303 },
  { name: 'UVNIX', latitude: 41.473069, longitude: 33.630892 },
  { name: 'ENLEC', latitude: 41.474811, longitude: 29.090889 },
  { name: 'ENESU', latitude: 41.475403, longitude: 27.330506 },
  { name: 'FM826', latitude: 41.476953, longitude: 28.003325 },
  { name: 'UNSAV', latitude: 41.478333, longitude: 28.789722 },
  { name: 'VP156', latitude: 41.479722, longitude: 13.181667 },
  { name: 'TI503', latitude: 41.480833, longitude: 19.9225 },
  { name: 'AL611', latitude: 41.480953, longitude: 33.537328 },
  { name: 'BALEM', latitude: 41.481111, longitude: 18.685833 },
  { name: 'FM867', latitude: 41.482731, longitude: 28.410847 },
  { name: 'D348H', latitude: 41.482806, longitude: 33.779278 },
  { name: '95TAC', latitude: 41.483183, longitude: 15.882172 },
  { name: 'CI29', latitude: 41.483381, longitude: 15.882914 },
  { name: 'RF487', latitude: 41.483806, longitude: 12.412194 },
  { name: 'AVTEQ', latitude: 41.485078, longitude: 28.752475 },
  { name: 'D097T', latitude: 41.486819, longitude: 16.125983 },
  { name: 'D135D', latitude: 41.487311, longitude: 12.982456 },
  { name: 'D173H', latitude: 41.488519, longitude: 12.506706 },
  { name: 'NIVAX', latitude: 41.489278, longitude: 39.634194 },
  { name: 'FM825', latitude: 41.489947, longitude: 27.835792 },
  { name: 'D110H', latitude: 41.491019, longitude: 13.082558 },
  { name: 'FJ673', latitude: 41.491858, longitude: 29.094978 },
  { name: 'FH803', latitude: 41.492844, longitude: 36.537786 },
  { name: 'FJ577', latitude: 41.492969, longitude: 29.605003 },
  { name: 'FT29', latitude: 41.493403, longitude: 15.851811 },
  { name: 'FI29', latitude: 41.493517, longitude: 15.8525 },
  { name: 'DIGBO', latitude: 41.493611, longitude: 28.404444 },
  { name: 'ELVIN', latitude: 41.493889, longitude: 12.161389 },
  { name: '47VOR', latitude: 41.494214, longitude: 19.709108 },
  { name: 'D278W', latitude: 41.494797, longitude: 19.219219 },
  { name: 'LOKDI', latitude: 41.495, longitude: 18.339444 },
  { name: 'ELNIN', latitude: 41.495606, longitude: 28.723217 },
  { name: 'RE502', latitude: 41.496872, longitude: 12.506717 },
  { name: 'D192S', latitude: 41.497697, longitude: 12.128456 },
  { name: 'RF754', latitude: 41.497806, longitude: 12.128483 },
  { name: 'ENFEZ', latitude: 41.499006, longitude: 28.558919 },
  { name: 'DIVKU', latitude: 41.5, longitude: 15.837778 },
  { name: 'FF18', latitude: 41.50015, longitude: 33.810169 },
  { name: 'D326E', latitude: 41.501589, longitude: 15.475733 },
  { name: 'D159S', latitude: 41.502386, longitude: 12.367761 },
  { name: 'D008V', latitude: 41.502392, longitude: 16.881944 },
  { name: 'RF743', latitude: 41.50255, longitude: 12.367689 },
  { name: 'CRAYE', latitude: 41.502778, longitude: 18.129167 },
  { name: 'D069R', latitude: 41.503261, longitude: 20.100025 },
  { name: '30VOR', latitude: 41.505114, longitude: 12.964283 },
  { name: 'D138W', latitude: 41.505275, longitude: 12.558606 },
  { name: 'FM424', latitude: 41.505383, longitude: 29.602456 },
  { name: '60TAC', latitude: 41.507017, longitude: 15.811314 },
  { name: 'VP157', latitude: 41.508333, longitude: 12.773611 },
  { name: 'BAFRA', latitude: 41.508611, longitude: 35.966389 },
  { name: '50LOC', latitude: 41.509947, longitude: 19.707864 },
  { name: 'VP158', latitude: 41.510556, longitude: 13.265556 },
  { name: 'MD088', latitude: 41.510831, longitude: 32.089242 },
  { name: 'RF482', latitude: 41.512889, longitude: 12.539306 },
  { name: 'TI502', latitude: 41.513056, longitude: 20.024722 },
  { name: 'D110E', latitude: 41.513819, longitude: 15.791058 },
  { name: 'GOSEB', latitude: 41.514167, longitude: 14.483333 },
  { name: 'DIBVI', latitude: 41.517222, longitude: 12.41 },
  { name: 'D150G', latitude: 41.517531, longitude: 12.564286 },
  { name: 'D136W', latitude: 41.518256, longitude: 12.578986 },
  { name: 'FM763', latitude: 41.519953, longitude: 27.909453 },
  { name: 'FM467', latitude: 41.520494, longitude: 28.956331 },
  { name: 'FS088', latitude: 41.520906, longitude: 31.907322 },
  { name: 'D146G', latitude: 41.521472, longitude: 12.573769 },
  { name: 'BONZI', latitude: 41.523333, longitude: 20.457222 },
  { name: 'FM861', latitude: 41.523647, longitude: 27.953572 },
  { name: 'FD088', latitude: 41.525556, longitude: 31.823056 },
  { name: 'PERAV', latitude: 41.528333, longitude: 16.935 },
  { name: 'NEVUX', latitude: 41.528889, longitude: 12.323889 },
  { name: 'PISIP', latitude: 41.530278, longitude: 16.636389 },
  { name: 'D111M', latitude: 41.531319, longitude: 12.756267 },
  { name: 'OVBEK', latitude: 41.532475, longitude: 33.829075 },
  { name: 'VP159', latitude: 41.5325, longitude: 15.257222 },
  { name: 'VP160', latitude: 41.533333, longitude: 13.533333 },
  { name: 'MT29', latitude: 41.534206, longitude: 15.730269 },
  { name: 'EVKEB', latitude: 41.5356, longitude: 29.7589 },
  { name: 'MD048', latitude: 41.536533, longitude: 15.701369 },
  { name: 'PEVUD', latitude: 41.537069, longitude: 28.702036 },
  { name: 'TIGSU', latitude: 41.537294, longitude: 28.722408 },
  { name: 'EVLAP', latitude: 41.5375, longitude: 31.585833 },
  { name: 'KORQU', latitude: 41.537611, longitude: 28.751478 },
  { name: 'FM907', latitude: 41.538231, longitude: 28.368444 },
  { name: 'TI501', latitude: 41.538611, longitude: 20.106667 },
  { name: 'FM461', latitude: 41.542725, longitude: 29.459375 },
  { name: 'REBRU', latitude: 41.543825, longitude: 33.696725 },
  { name: 'ODULA', latitude: 41.545, longitude: 12.386944 },
  { name: 'VP161', latitude: 41.545833, longitude: 12.543056 },
  { name: 'NIKTI', latitude: 41.546389, longitude: 24.121667 },
  { name: 'FM563', latitude: 41.547733, longitude: 29.415492 },
  { name: 'D350H', latitude: 41.548808, longitude: 19.699631 },
  { name: 'GUMRU', latitude: 41.548889, longitude: 31.349444 },
  { name: 'MD12', latitude: 41.549944, longitude: 12.899231 },
  { name: 'D159P', latitude: 41.549986, longitude: 12.347278 },
  { name: 'MT11', latitude: 41.551167, longitude: 15.679581 },
  { name: 'ML12', latitude: 41.552486, longitude: 12.889494 },
  { name: 'D275E', latitude: 41.552661, longitude: 12.808139 },
  { name: 'FM822', latitude: 41.553497, longitude: 27.398317 },
  { name: 'IBODU', latitude: 41.554444, longitude: 27.078889 },
  { name: 'XIBRI', latitude: 41.557778, longitude: 12.176944 },
  { name: '17TAC', latitude: 41.5593, longitude: 15.655242 },
  { name: 'D275H', latitude: 41.559542, longitude: 12.742172 },
  { name: 'ODRAS', latitude: 41.559722, longitude: 20.174167 },
  { name: 'CD31Z', latitude: 41.560036, longitude: 12.572903 },
  { name: 'IDROX', latitude: 41.561111, longitude: 12.664722 },
  { name: 'D290B', latitude: 41.561333, longitude: 15.649156 },
  { name: 'RA773', latitude: 41.562556, longitude: 12.810808 },
  { name: 'FT31Z', latitude: 41.563608, longitude: 12.573633 },
  { name: 'VP162', latitude: 41.566667, longitude: 13.283333 },
  { name: 'INSTA', latitude: 41.566675, longitude: 28.483336 },
  { name: 'D270T', latitude: 41.570186, longitude: 15.246703 },
  { name: 'UVULU', latitude: 41.570278, longitude: 34.536389 },
  { name: 'D280H', latitude: 41.570989, longitude: 12.744967 },
  { name: 'EKLOS', latitude: 41.5725, longitude: 12.300278 },
  { name: 'AMTOG', latitude: 41.573633, longitude: 15.113608 },
  { name: 'FM853', latitude: 41.574208, longitude: 27.33765 },
  { name: 'D132D', latitude: 41.574372, longitude: 12.556586 },
  { name: 'FI17', latitude: 41.574656, longitude: 19.699208 },
  { name: 'D290D', latitude: 41.574878, longitude: 15.608575 },
  { name: 'FM090', latitude: 41.575192, longitude: 28.601972 },
  { name: 'D275O', latitude: 41.575447, longitude: 12.588194 },
  { name: 'BIRSU', latitude: 41.576111, longitude: 16.826111 },
  { name: '33LOC', latitude: 41.576139, longitude: 12.843517 },
  { name: '40VOR', latitude: 41.576431, longitude: 12.842725 },
  { name: 'FH714', latitude: 41.5771, longitude: 36.339908 },
  { name: 'SERCE', latitude: 41.577222, longitude: 27.309167 },
  { name: 'NEFTE', latitude: 41.577458, longitude: 28.696442 },
  { name: 'FM011', latitude: 41.577872, longitude: 28.290225 },
  { name: 'GOZCU', latitude: 41.578569, longitude: 28.764725 },
  { name: 'CI31X', latitude: 41.578639, longitude: 12.563714 },
  { name: 'NIPSA', latitude: 41.578889, longitude: 12.325 },
  { name: 'YAHNI', latitude: 41.579017, longitude: 28.674897 },
  { name: 'RE501', latitude: 41.579278, longitude: 12.562472 },
  { name: 'MORTT', latitude: 41.579628, longitude: 28.739825 },
  { name: 'VP163', latitude: 41.58, longitude: 15.489167 },
  { name: 'FH555', latitude: 41.581019, longitude: 36.528244 },
  { name: 'RA768', latitude: 41.581367, longitude: 12.585197 },
  { name: 'D130D', latitude: 41.581372, longitude: 12.553192 },
  { name: 'FT11', latitude: 41.581644, longitude: 15.588278 },
  { name: 'FS17', latitude: 41.582256, longitude: 19.698836 },
  { name: 'OST29', latitude: 41.582711, longitude: 12.811331 },
  { name: 'ULVEX', latitude: 41.583056, longitude: 31.531111 },
  { name: 'FD31Z', latitude: 41.583292, longitude: 12.541072 },
  { name: 'IPSAR', latitude: 41.583333, longitude: 12.766111 },
  { name: 'D129T', latitude: 41.584494, longitude: 12.572764 },
  { name: 'FD34Z', latitude: 41.584581, longitude: 13.372242 },
  { name: 'FM906', latitude: 41.585156, longitude: 28.453781 },
  { name: 'FM767', latitude: 41.585706, longitude: 28.352497 },
  { name: 'RF762', latitude: 41.585889, longitude: 12.426583 },
  { name: 'HAWLU', latitude: 41.586581, longitude: 28.569561 },
  { name: 'FD34Y', latitude: 41.587681, longitude: 13.318947 },
  { name: 'D326K', latitude: 41.588094, longitude: 15.408819 },
  { name: 'BEBIS', latitude: 41.588128, longitude: 29.618372 },
  { name: 'FI12', latitude: 41.589322, longitude: 12.817858 },
  { name: 'CD34L', latitude: 41.590975, longitude: 12.292622 },
  { name: 'NUGBA', latitude: 41.591111, longitude: 30.674167 },
  { name: 'D144O', latitude: 41.591117, longitude: 12.421361 },
  { name: 'TI601', latitude: 41.591158, longitude: 20.071331 },
  { name: 'BD404', latitude: 41.591336, longitude: 16.955556 },
  { name: 'D130S', latitude: 41.591353, longitude: 12.551156 },
  { name: '65TAC', latitude: 41.591786, longitude: 15.557822 },
  { name: 'IDABU', latitude: 41.592778, longitude: 12.366944 },
  { name: 'YAKBE', latitude: 41.592808, longitude: 28.715814 },
  { name: 'VP164', latitude: 41.593056, longitude: 12.651389 },
  { name: 'APTOX', latitude: 41.593056, longitude: 32.504167 },
  { name: 'RF481', latitude: 41.593083, longitude: 12.506472 },
  { name: 'RA762', latitude: 41.593361, longitude: 12.654414 },
  { name: 'FD12', latitude: 41.594072, longitude: 12.805028 },
  { name: 'BAUNE', latitude: 41.595036, longitude: 28.685067 },
  { name: 'CI12', latitude: 41.595347, longitude: 12.806125 },
  { name: 'OKUBU', latitude: 41.595833, longitude: 12.318056 },
  { name: 'TORLI', latitude: 41.596667, longitude: 12.018333 },
  { name: 'FI31', latitude: 41.596878, longitude: 12.534808 },
  { name: 'VAXEB', latitude: 41.596944, longitude: 33.578611 },
  { name: 'TI504', latitude: 41.598889, longitude: 19.696111 },
  { name: 'F2208', latitude: 41.599461, longitude: 32.121572 },
  { name: 'VP165', latitude: 41.602778, longitude: 13.298889 },
  { name: 'GAZOS', latitude: 41.602778, longitude: 28.577358 },
  { name: 'D289J', latitude: 41.6032, longitude: 12.711967 },
  { name: 'FI31X', latitude: 41.603314, longitude: 12.524603 },
  { name: 'RA772', latitude: 41.603814, longitude: 12.783672 },
  { name: 'D130B', latitude: 41.604136, longitude: 12.518656 },
  { name: 'FM766', latitude: 41.604589, longitude: 28.221578 },
  { name: 'RF731', latitude: 41.606167, longitude: 12.457861 },
  { name: 'DIVDI', latitude: 41.606525, longitude: 28.888303 },
  { name: 'MURAS', latitude: 41.606944, longitude: 19.712222 },
  { name: 'ESOSU', latitude: 41.607222, longitude: 33.538056 },
  { name: 'EFNAH', latitude: 41.609939, longitude: 28.752311 },
  { name: 'ATIFE', latitude: 41.610431, longitude: 28.732206 },
  { name: '20VO2', latitude: 41.611186, longitude: 13.307558 },
  { name: 'D161T', latitude: 41.612056, longitude: 21.742528 },
  { name: 'RA771', latitude: 41.612419, longitude: 12.894167 },
  { name: 'QUNTE', latitude: 41.614519, longitude: 28.797003 },
  { name: 'CT11', latitude: 41.615422, longitude: 15.486728 },
  { name: 'D159L', latitude: 41.616172, longitude: 12.6696 },
  { name: 'VP166', latitude: 41.616667, longitude: 13.233333 },
  { name: 'PATOK', latitude: 41.616667, longitude: 19.587778 },
  { name: 'NELNI', latitude: 41.6175, longitude: 30.5025 },
  { name: 'LUMAR', latitude: 41.618611, longitude: 17.563889 },
  { name: '20VOR', latitude: 41.619356, longitude: 13.324331 },
  { name: 'ZEHRE', latitude: 41.619528, longitude: 28.777989 },
  { name: 'SOSAK', latitude: 41.620833, longitude: 12.043889 },
  { name: 'ELNOG', latitude: 41.620833, longitude: 12.63 },
  { name: 'FM567', latitude: 41.621631, longitude: 29.020103 },
  { name: 'EKTEP', latitude: 41.621958, longitude: 28.757264 },
  { name: 'ERIKA', latitude: 41.622778, longitude: 14.031111 },
  { name: 'BA30A', latitude: 41.623164, longitude: 16.968436 },
  { name: 'FM765', latitude: 41.623322, longitude: 28.090578 },
  { name: 'D146T', latitude: 41.623742, longitude: 16.271378 },
  { name: 'ENEXU', latitude: 41.624167, longitude: 12.353889 },
  { name: 'FF208', latitude: 41.624328, longitude: 32.143972 },
  { name: 'CI34L', latitude: 41.627122, longitude: 12.305092 },
  { name: 'MD34Z', latitude: 41.627467, longitude: 13.313142 },
  { name: 'MD34Y', latitude: 41.628425, longitude: 13.2992 },
  { name: 'D290L', latitude: 41.628906, longitude: 15.446078 },
  { name: 'RA767', latitude: 41.630047, longitude: 12.569883 },
  { name: 'D156K', latitude: 41.6328, longitude: 12.675919 },
  { name: 'VI44A', latitude: 41.634028, longitude: 16.956967 },
  { name: 'D266W', latitude: 41.634697, longitude: 12.781214 },
  { name: 'RF532', latitude: 41.635028, longitude: 12.376028 },
  { name: 'FM920', latitude: 41.635417, longitude: 28.9427 },
  { name: 'FM566', latitude: 41.636581, longitude: 29.151958 },
  { name: 'TI604', latitude: 41.636944, longitude: 19.534444 },
  { name: 'D178J', latitude: 41.637944, longitude: 12.584119 },
  { name: 'D173J', latitude: 41.638325, longitude: 12.603506 },
  { name: 'ML31', latitude: 41.640836, longitude: 12.465031 },
  { name: 'MD31Z', latitude: 41.641394, longitude: 12.461392 },
  { name: 'FM860', latitude: 41.641908, longitude: 27.959503 },
  { name: 'D164J', latitude: 41.642181, longitude: 12.638006 },
  { name: 'D324P', latitude: 41.642244, longitude: 19.529714 },
  { name: 'RA763', latitude: 41.6423, longitude: 12.639147 },
  { name: 'FM423', latitude: 41.643264, longitude: 29.682967 },
  { name: 'ATVAM', latitude: 41.643333, longitude: 39.583056 },
  { name: 'RELTU', latitude: 41.644167, longitude: 32.323333 },
  { name: 'D161R', latitude: 41.644444, longitude: 21.731389 },
  { name: 'MT31Z', latitude: 41.644717, longitude: 12.461794 },
  { name: 'VP167', latitude: 41.646667, longitude: 13.244722 },
  { name: 'ELVAD', latitude: 41.647486, longitude: 15.384294 },
  { name: 'FACLI', latitude: 41.647653, longitude: 31.880808 },
  { name: 'FM764', latitude: 41.648069, longitude: 27.915792 },
  { name: 'VOLBI', latitude: 41.648611, longitude: 19.689444 },
  { name: 'D290O', latitude: 41.649108, longitude: 15.385072 },
  { name: 'FM565', latitude: 41.651381, longitude: 29.283869 },
  { name: 'FD34L', latitude: 41.651544, longitude: 12.276978 },
  { name: 'D289R', latitude: 41.652603, longitude: 12.546811 },
  { name: 'BETIZ', latitude: 41.653306, longitude: 29.358506 },
  { name: 'TI603', latitude: 41.655189, longitude: 19.778114 },
  { name: 'RF763', latitude: 41.656339, longitude: 12.292867 },
  { name: 'DEVEM', latitude: 41.656389, longitude: 12.693056 },
  { name: 'D192I', latitude: 41.658806, longitude: 12.185733 },
  { name: 'INPAP', latitude: 41.659136, longitude: 29.11865 },
  { name: 'FM854', latitude: 41.659922, longitude: 27.503631 },
  { name: 'TI602', latitude: 41.660069, longitude: 19.8445 },
  { name: 'OTKUB', latitude: 41.661944, longitude: 12.760556 },
  { name: 'OBAVO', latitude: 41.662583, longitude: 15.528853 },
  { name: 'RF713', latitude: 41.662611, longitude: 12.20275 },
  { name: 'MD13', latitude: 41.664617, longitude: 12.429481 },
  { name: 'MT13', latitude: 41.665294, longitude: 12.426644 },
  { name: 'EROVA', latitude: 41.665497, longitude: 28.829683 },
  { name: 'FM564', latitude: 41.666025, longitude: 29.415833 },
  { name: 'D290R', latitude: 41.669278, longitude: 15.324028 },
  { name: 'ASMOB', latitude: 41.669444, longitude: 32.232778 },
  { name: 'GITVO', latitude: 41.669872, longitude: 28.608456 },
  { name: 'MAVAR', latitude: 41.67, longitude: 20.53 },
  { name: 'D307E', latitude: 41.670411, longitude: 12.417867 },
  { name: 'IPMUT', latitude: 41.670556, longitude: 12.504722 },
  { name: 'FM460', latitude: 41.670872, longitude: 29.459831 },
  { name: 'RF414', latitude: 41.671, longitude: 12.755889 },
  { name: 'D134K', latitude: 41.671067, longitude: 12.755792 },
  { name: 'GOVGU', latitude: 41.671617, longitude: 28.460722 },
  { name: 'FM420', latitude: 41.671731, longitude: 30.093667 },
  { name: 'RA416', latitude: 41.672175, longitude: 12.754308 },
  { name: 'NIBUG', latitude: 41.672778, longitude: 12.473611 },
  { name: 'RA764', latitude: 41.672811, longitude: 12.629569 },
  { name: 'RF531', latitude: 41.673083, longitude: 12.423444 },
  { name: 'VP168', latitude: 41.673889, longitude: 13.148889 },
  { name: 'CI34R', latitude: 41.675061, longitude: 12.332814 },
  { name: 'ACCUA', latitude: 41.675597, longitude: 28.031372 },
  { name: 'FI34L', latitude: 41.67805, longitude: 12.283856 },
  { name: 'VEMAG', latitude: 41.678889, longitude: 31.135833 },
  { name: 'CE33', latitude: 41.679556, longitude: 12.626525 },
  { name: 'FJ672', latitude: 41.680519, longitude: 28.792878 },
  { name: 'RATIR', latitude: 41.681667, longitude: 12.449167 },
  { name: 'EKMOT', latitude: 41.683056, longitude: 14.807222 },
  { name: 'VP170', latitude: 41.684167, longitude: 15.378611 },
  { name: 'VP171', latitude: 41.686667, longitude: 13.238333 },
  { name: 'RF733', latitude: 41.690636, longitude: 12.326361 },
  { name: 'FI34R', latitude: 41.690978, longitude: 12.326186 },
  { name: 'FM852', latitude: 41.692575, longitude: 27.228703 },
  { name: 'FM821', latitude: 41.692661, longitude: 27.3498 },
  { name: 'D305E', latitude: 41.693822, longitude: 13.204703 },
  { name: 'RA501', latitude: 41.693889, longitude: 12.637417 },
  { name: 'UNVUS', latitude: 41.695, longitude: 32.141389 },
  { name: 'RF607', latitude: 41.697178, longitude: 12.132758 },
  { name: 'ROXIR', latitude: 41.698186, longitude: 33.854486 },
  { name: 'RF789', latitude: 41.705222, longitude: 12.043889 },
  { name: 'RF603', latitude: 41.705333, longitude: 12.187306 },
  { name: 'RF609', latitude: 41.7054, longitude: 12.113786 },
  { name: 'FE33', latitude: 41.707178, longitude: 12.618033 },
  { name: 'RA502', latitude: 41.707811, longitude: 12.830561 },
  { name: 'OLUPO', latitude: 41.708611, longitude: 37.661944 },
  { name: 'HITMI', latitude: 41.711878, longitude: 28.53425 },
  { name: 'NUSGI', latitude: 41.712286, longitude: 29.120853 },
  { name: 'RF703', latitude: 41.713167, longitude: 12.141222 },
  { name: '40TH4', latitude: 41.718339, longitude: 12.267006 },
  { name: 'FM451', latitude: 41.719328, longitude: 30.466892 },
  { name: 'RA603', latitude: 41.719886, longitude: 12.492231 },
  { name: 'FT13', latitude: 41.719911, longitude: 12.342603 },
  { name: 'RF742', latitude: 41.721619, longitude: 12.313453 },
  { name: '50VOR', latitude: 41.721933, longitude: 12.258761 },
  { name: 'VP172', latitude: 41.722222, longitude: 12.836111 },
  { name: 'D159H', latitude: 41.722536, longitude: 12.314456 },
  { name: 'FD13', latitude: 41.722639, longitude: 12.3496 },
  { name: 'HAKAN', latitude: 41.723333, longitude: 32.298333 },
  { name: 'RA703', latitude: 41.724361, longitude: 12.497222 },
  { name: 'D109F', latitude: 41.72455, longitude: 12.498303 },
  { name: 'CD07', latitude: 41.725325, longitude: 12.040997 },
  { name: 'FM421', latitude: 41.726519, longitude: 29.928897 },
  { name: 'BASBU', latitude: 41.726944, longitude: 12.7075 },
  { name: 'BANAV', latitude: 41.728611, longitude: 17.011111 },
  { name: '53LOC', latitude: 41.730772, longitude: 12.309611 },
  { name: 'RF608', latitude: 41.732314, longitude: 12.095247 },
  { name: 'MUTBE', latitude: 41.733517, longitude: 28.931325 },
  { name: 'TIVUG', latitude: 41.733611, longitude: 33.031111 },
  { name: 'RF604', latitude: 41.733794, longitude: 12.230553 },
  { name: 'D316Y', latitude: 41.734617, longitude: 19.359967 },
  { name: 'ALAXI', latitude: 41.734756, longitude: 13.347667 },
  { name: 'TOBIR', latitude: 41.735278, longitude: 12.435278 },
  { name: 'RF606', latitude: 41.736667, longitude: 12.150589 },
  { name: '40VO2', latitude: 41.739675, longitude: 12.608031 },
  { name: 'RA766', latitude: 41.739675, longitude: 12.608542 },
  { name: 'TEPKO', latitude: 41.740833, longitude: 18.428056 },
  { name: 'CT13', latitude: 41.741742, longitude: 12.308947 },
  { name: 'ZOCOP', latitude: 41.742778, longitude: 12.027778 },
  { name: 'RA604', latitude: 41.743189, longitude: 12.518517 },
  { name: 'VP173', latitude: 41.745, longitude: 12.976389 },
  { name: 'FM855', latitude: 41.745392, longitude: 27.670047 },
  { name: 'D120T', latitude: 41.745881, longitude: 21.997872 },
  { name: 'GOKPA', latitude: 41.746111, longitude: 34.594722 },
  { name: 'TETSA', latitude: 41.746164, longitude: 28.779281 },
  { name: 'BITNI', latitude: 41.748056, longitude: 12.0325 },
  { name: '41LOC', latitude: 41.749872, longitude: 12.301647 },
  { name: 'D249J', latitude: 41.752053, longitude: 12.02575 },
  { name: 'ATPIX', latitude: 41.753333, longitude: 30.623611 },
  { name: 'FM452', latitude: 41.754083, longitude: 30.295056 },
  { name: 'RF786', latitude: 41.755028, longitude: 12.167639 },
  { name: 'ME33', latitude: 41.755922, longitude: 12.603028 },
  { name: 'CD13', latitude: 41.757425, longitude: 12.301603 },
  { name: 'DETOS', latitude: 41.758333, longitude: 36.671111 },
  { name: 'UPMIV', latitude: 41.759767, longitude: 29.596956 },
  { name: 'FM857', latitude: 41.760167, longitude: 27.965458 },
  { name: 'RF620', latitude: 41.760531, longitude: 12.200378 },
  { name: 'FM062', latitude: 41.761519, longitude: 28.564711 },
  { name: 'ATVAS', latitude: 41.762583, longitude: 28.778639 },
  { name: 'MD34L', latitude: 41.762856, longitude: 12.24815 },
  { name: 'TIMPU', latitude: 41.763611, longitude: 16.429722 },
  { name: 'FD07', latitude: 41.764594, longitude: 12.139203 },
  { name: 'FM082', latitude: 41.764672, longitude: 28.851658 },
  { name: 'RF782', latitude: 41.765258, longitude: 12.1094 },
  { name: 'VP174', latitude: 41.766389, longitude: 12.477778 },
  { name: 'UNSUL', latitude: 41.766667, longitude: 31.308611 },
  { name: 'RA507', latitude: 41.766742, longitude: 12.40865 },
  { name: 'D104H', latitude: 41.766881, longitude: 12.408806 },
  { name: 'NASKO', latitude: 41.766944, longitude: 22.097778 },
  { name: 'ENFAR', latitude: 41.768611, longitude: 20.534444 },
  { name: 'RF601', latitude: 41.771028, longitude: 12.245 },
  { name: 'FM063', latitude: 41.771653, longitude: 28.379356 },
  { name: 'PETAK', latitude: 41.775278, longitude: 19.313889 },
  { name: 'FM083', latitude: 41.776439, longitude: 29.052656 },
  { name: 'D185J', latitude: 41.776714, longitude: 12.463903 },
  { name: 'RF753', latitude: 41.778814, longitude: 12.241731 },
  { name: 'REKNU', latitude: 41.779814, longitude: 28.845789 },
  { name: 'FM422', latitude: 41.781078, longitude: 29.763836 },
  { name: 'FM064', latitude: 41.781483, longitude: 28.193936 },
  { name: 'FM084', latitude: 41.787853, longitude: 29.2537 },
  { name: 'RF792', latitude: 41.789111, longitude: 12.195222 },
  { name: 'CF34', latitude: 41.789861, longitude: 21.681753 },
  { name: 'RF791', latitude: 41.790389, longitude: 12.099222 },
  { name: 'RF787', latitude: 41.791806, longitude: 12.151556 },
  { name: 'VP175', latitude: 41.793056, longitude: 12.7625 },
  { name: 'RF777', latitude: 41.793361, longitude: 12.210556 },
  { name: 'D161A', latitude: 41.793622, longitude: 12.283242 },
  { name: 'UNLIR', latitude: 41.794047, longitude: 28.085047 },
  { name: 'FM856', latitude: 41.794303, longitude: 27.793122 },
  { name: 'RF726', latitude: 41.795472, longitude: 12.218217 },
  { name: 'MD07', latitude: 41.795947, longitude: 12.217853 },
  { name: 'RF602', latitude: 41.797444, longitude: 12.281806 },
  { name: 'D161B', latitude: 41.798175, longitude: 12.281381 },
  { name: 'FM458', latitude: 41.799019, longitude: 29.460303 },
  { name: 'ROLIN', latitude: 41.799167, longitude: 40.656389 },
  { name: 'EVPUV', latitude: 41.800278, longitude: 32.759444 },
  { name: 'BAVBO', latitude: 41.800833, longitude: 33.729528 },
  { name: 'MM34R', latitude: 41.804528, longitude: 12.27875 },
  { name: 'VP176', latitude: 41.805556, longitude: 12.677778 },
  { name: 'RA506', latitude: 41.805567, longitude: 12.393453 },
  { name: 'D087G', latitude: 41.805708, longitude: 12.393506 },
  { name: 'RA508', latitude: 41.806103, longitude: 12.449153 },
  { name: 'NAPIP', latitude: 41.806667, longitude: 31.738889 },
  { name: 'D087L', latitude: 41.807022, longitude: 12.510958 },
  { name: 'D337Y', latitude: 41.809344, longitude: 19.533683 },
  { name: 'FM820', latitude: 41.810839, longitude: 27.119356 },
  { name: 'GOSTI', latitude: 41.811667, longitude: 21.108333 },
  { name: 'MM25', latitude: 41.812361, longitude: 12.27975 },
  { name: 'RF725', latitude: 41.812461, longitude: 12.279747 },
  { name: 'MA25', latitude: 41.812961, longitude: 12.280378 },
  { name: 'VP177', latitude: 41.815, longitude: 12.719167 },
  { name: 'D161G', latitude: 41.815556, longitude: 21.672222 },
  { name: 'RF764', latitude: 41.816344, longitude: 12.226019 },
  { name: 'D339B', latitude: 41.816508, longitude: 12.225972 },
  { name: 'FM091', latitude: 41.817317, longitude: 28.246011 },
  { name: 'D323A', latitude: 41.817547, longitude: 12.224958 },
  { name: 'RF801', latitude: 41.817889, longitude: 12.225361 },
  { name: 'MD16R', latitude: 41.818889, longitude: 12.228106 },
  { name: 'MD15', latitude: 41.820014, longitude: 12.579289 },
  { name: 'VP178', latitude: 41.820833, longitude: 12.356389 },
  { name: 'INSOX', latitude: 41.821667, longitude: 13.222222 },
  { name: 'FF34', latitude: 41.821944, longitude: 21.67 },
  { name: '40VOR', latitude: 41.822128, longitude: 12.323239 },
  { name: 'ROKVA', latitude: 41.829167, longitude: 31.657222 },
  { name: 'RF702', latitude: 41.830278, longitude: 12.062806 },
  { name: 'AKILI', latitude: 41.830556, longitude: 12.319444 },
  { name: 'BUREK', latitude: 41.832222, longitude: 21.196944 },
  { name: 'GERZE', latitude: 41.832222, longitude: 35.166111 },
  { name: '40TH2', latitude: 41.832692, longitude: 12.353139 },
  { name: 'D289E', latitude: 41.834961, longitude: 12.134164 },
  { name: '70VOR', latitude: 41.83585, longitude: 12.387556 },
  { name: 'D198S', latitude: 41.839289, longitude: 13.060906 },
  { name: 'YAHAV', latitude: 41.84, longitude: 31.081667 },
  { name: 'RA704', latitude: 41.841306, longitude: 12.413583 },
  { name: 'FM456', latitude: 41.841311, longitude: 29.768503 },
  { name: '57LOC', latitude: 41.841431, longitude: 12.384997 },
  { name: 'D172Q', latitude: 41.84145, longitude: 12.414564 },
  { name: 'BUNUL', latitude: 41.842222, longitude: 12.403056 },
  { name: 'RF706', latitude: 41.843028, longitude: 12.103778 },
  { name: 'RA532', latitude: 41.845528, longitude: 12.914278 },
  { name: 'FM457', latitude: 41.846831, longitude: 29.577772 },
  { name: 'MIMTO', latitude: 41.846944, longitude: 33.046667 },
  { name: 'D185F', latitude: 41.847297, longitude: 12.474875 },
  { name: 'RF802', latitude: 41.847861, longitude: 12.136972 },
  { name: 'D074L', latitude: 41.851119, longitude: 12.493022 },
  { name: 'RF812', latitude: 41.852444, longitude: 12.071472 },
  { name: 'D289H', latitude: 41.853628, longitude: 12.072097 },
  { name: 'FD25', latitude: 41.854092, longitude: 12.473353 },
  { name: 'ML16L', latitude: 41.854325, longitude: 12.257994 },
  { name: 'RF729', latitude: 41.855067, longitude: 12.072772 },
  { name: 'D358S', latitude: 41.857931, longitude: 12.918056 },
  { name: 'FM065', latitude: 41.859575, longitude: 28.018797 },
  { name: 'GOKBA', latitude: 41.860278, longitude: 12.382222 },
  { name: 'VP179', latitude: 41.8625, longitude: 12.605556 },
  { name: 'RF781', latitude: 41.862586, longitude: 12.461989 },
  { name: 'CD25', latitude: 41.863186, longitude: 12.516272 },
  { name: 'D330D', latitude: 41.863464, longitude: 12.546194 },
  { name: 'FI25', latitude: 41.864394, longitude: 12.46875 },
  { name: 'PINUK', latitude: 41.865556, longitude: 18.59 },
  { name: '40VOR', latitude: 41.866056, longitude: 12.553133 },
  { name: 'GINLI', latitude: 41.871111, longitude: 28.774444 },
  { name: 'FM453', latitude: 41.871456, longitude: 30.207633 },
  { name: 'ADORU', latitude: 41.871667, longitude: 26.568333 },
  { name: 'RA761', latitude: 41.872017, longitude: 12.544644 },
  { name: 'DEVMU', latitude: 41.8725, longitude: 32.461111 },
  { name: 'D172O', latitude: 41.874672, longitude: 12.410692 },
  { name: 'FM066', latitude: 41.875825, longitude: 27.489244 },
  { name: 'URIPI', latitude: 41.875833, longitude: 15.184722 },
  { name: 'CI25', latitude: 41.875856, longitude: 12.51065 },
  { name: '40TH3', latitude: 41.879156, longitude: 12.199669 },
  { name: 'OM16R', latitude: 41.879583, longitude: 12.199417 },
  { name: '23LOC', latitude: 41.879783, longitude: 12.247331 },
  { name: 'NAPOX', latitude: 41.880833, longitude: 12.528889 },
  { name: 'RA414', latitude: 41.881331, longitude: 12.807822 },
  { name: 'SUXUB', latitude: 41.881667, longitude: 14.079722 },
  { name: 'RF412', latitude: 41.882194, longitude: 12.810333 },
  { name: 'LEBGA', latitude: 41.883056, longitude: 19.171667 },
  { name: 'VP180', latitude: 41.883333, longitude: 12.622222 },
  { name: 'VP181', latitude: 41.884167, longitude: 12.381111 },
  { name: 'D248J', latitude: 41.885047, longitude: 21.417586 },
  { name: 'VAGPE', latitude: 41.885556, longitude: 12.545833 },
  { name: 'D064L', latitude: 41.885886, longitude: 12.481542 },
  { name: 'D061K', latitude: 41.886119, longitude: 12.807781 },
  { name: 'FM956', latitude: 41.890589, longitude: 28.205136 },
  { name: 'MD34', latitude: 41.8928, longitude: 21.645686 },
  { name: 'EVPIM', latitude: 41.895, longitude: 32.366667 },
  { name: 'IPMIL', latitude: 41.897603, longitude: 26.928797 },
  { name: 'TUVEL', latitude: 41.898333, longitude: 12.461667 },
  { name: 'CF30', latitude: 41.902556, longitude: 25.143694 },
  { name: 'KARDE', latitude: 41.903889, longitude: 37.045278 },
  { name: 'RA701', latitude: 41.905417, longitude: 12.521528 },
  { name: 'FM967', latitude: 41.906072, longitude: 29.261125 },
  { name: 'ETLEY', latitude: 41.9068, longitude: 29.435269 },
  { name: 'EKRAX', latitude: 41.907222, longitude: 12.208056 },
  { name: 'VP182', latitude: 41.9075, longitude: 15.32 },
  { name: 'OKDEB', latitude: 41.909444, longitude: 13.094722 },
  { name: 'LUUPU', latitude: 41.909444, longitude: 32.238333 },
  { name: 'FD16R', latitude: 41.909542, longitude: 12.171492 },
  { name: 'D022G', latitude: 41.909542, longitude: 12.303561 },
  { name: '40THR', latitude: 41.909608, longitude: 12.234781 },
  { name: '42LOC', latitude: 41.910017, longitude: 12.234653 },
  { name: 'RF804', latitude: 41.910694, longitude: 12.234611 },
  { name: 'FM455', latitude: 41.915181, longitude: 29.944006 },
  { name: 'RF811', latitude: 41.916667, longitude: 12.0925 },
  { name: 'FM955', latitude: 41.918464, longitude: 28.509928 },
  { name: 'GISAM', latitude: 41.918611, longitude: 17.758611 },
  { name: 'RA759', latitude: 41.92415, longitude: 12.51095 },
  { name: 'SUBAR', latitude: 41.925278, longitude: 12.514722 },
  { name: 'D314J', latitude: 41.925622, longitude: 12.085253 },
  { name: 'KOFER', latitude: 41.927222, longitude: 18.663611 },
  { name: 'BEDOP', latitude: 41.927222, longitude: 32.232778 },
  { name: 'AIOSA', latitude: 41.928333, longitude: 17.248333 },
  { name: 'FI15', latitude: 41.928569, longitude: 12.505419 },
  { name: 'FM038', latitude: 41.928878, longitude: 29.446458 },
  { name: 'VOR12', latitude: 41.929781, longitude: 25.095647 },
  { name: 'BEVIS', latitude: 41.932778, longitude: 18.194444 },
  { name: 'FD15', latitude: 41.933572, longitude: 12.514706 },
  { name: 'FM966', latitude: 41.935158, longitude: 28.982525 },
  { name: 'CI16R', latitude: 41.936767, longitude: 12.175497 },
  { name: 'RF752', latitude: 41.937189, longitude: 12.175283 },
  { name: 'CD16R', latitude: 41.939753, longitude: 12.152583 },
  { name: 'VP183', latitude: 41.940278, longitude: 12.622222 },
  { name: 'D111O', latitude: 41.943381, longitude: 25.177408 },
  { name: 'RA536', latitude: 41.944917, longitude: 12.591194 },
  { name: 'LOCRI', latitude: 41.946389, longitude: 12.493056 },
  { name: 'D110O', latitude: 41.947314, longitude: 25.179944 },
  { name: 'LEGMU', latitude: 41.9475, longitude: 31.589167 },
  { name: 'PD604', latitude: 41.948194, longitude: 25.061361 },
  { name: 'D271J', latitude: 41.949881, longitude: 21.408111 },
  { name: 'D314L', latitude: 41.949967, longitude: 12.054728 },
  { name: 'RA417', latitude: 41.950781, longitude: 12.758167 },
  { name: 'RF784', latitude: 41.950808, longitude: 12.055922 },
  { name: 'PD510', latitude: 41.952333, longitude: 25.054111 },
  { name: 'VALDA', latitude: 41.953056, longitude: 19.148333 },
  { name: 'RF809', latitude: 41.953139, longitude: 12.2165 },
  { name: 'RF818', latitude: 41.955583, longitude: 12.06825 },
  { name: 'RA413', latitude: 41.956869, longitude: 13.025811 },
  { name: 'RF707', latitude: 41.957056, longitude: 12.061278 },
  { name: 'RA705', latitude: 41.957611, longitude: 12.400417 },
  { name: 'D172J', latitude: 41.957728, longitude: 12.400997 },
  { name: 'D081P', latitude: 41.957797, longitude: 21.986606 },
  { name: 'D170J', latitude: 41.958178, longitude: 12.406978 },
  { name: 'RF821', latitude: 41.960917, longitude: 12.006306 },
  { name: 'FF30', latitude: 41.961972, longitude: 25.038667 },
  { name: 'VELBA', latitude: 41.966667, longitude: 22.883333 },
  { name: 'TUDBU', latitude: 41.966667, longitude: 27.776944 },
  { name: 'FL16L', latitude: 41.967283, longitude: 12.210572 },
  { name: 'RF741', latitude: 41.967367, longitude: 12.210489 },
  { name: 'LATitude29', latitude: 41.969333, longitude: 13.218039 },
  { name: 'RA711', latitude: 41.969861, longitude: 12.600667 },
  { name: 'D081Y', latitude: 41.970142, longitude: 22.187131 },
  { name: 'ELITO', latitude: 41.970942, longitude: 12.312506 },
  { name: 'FD30', latitude: 41.971028, longitude: 25.022639 },
  { name: 'RILEX', latitude: 41.971111, longitude: 26.766389 },
  { name: 'RF819', latitude: 41.971861, longitude: 12.101278 },
  { name: 'VP184', latitude: 41.973889, longitude: 12.508333 },
  { name: 'VP185', latitude: 41.974444, longitude: 12.545278 },
  { name: 'POD34', latitude: 41.975569, longitude: 18.729122 },
  { name: 'TETOV', latitude: 41.976667, longitude: 20.99 },
  { name: 'EKVAV', latitude: 41.978611, longitude: 12.659444 },
  { name: 'INLOT', latitude: 41.979722, longitude: 19.453056 },
  { name: 'OMAMI', latitude: 41.981389, longitude: 26.310556 },
  { name: 'IBLAL', latitude: 41.982222, longitude: 28.001111 },
  { name: 'TV304', latitude: 41.982528, longitude: 18.889833 },
  { name: 'D282J', latitude: 41.982625, longitude: 21.416467 },
  { name: 'RA407', latitude: 41.983125, longitude: 12.4677 },
  { name: 'VP186', latitude: 41.983333, longitude: 12.408611 },
  { name: 'RINAV', latitude: 41.983611, longitude: 19.788333 },
  { name: 'TV306', latitude: 41.983778, longitude: 18.738139 },
  { name: 'ESATU', latitude: 41.985556, longitude: 12.343333 },
  { name: 'PD611', latitude: 41.98875, longitude: 25.201417 },
  { name: 'FM454', latitude: 41.988769, longitude: 30.119903 },
  { name: 'FM039', latitude: 41.988786, longitude: 29.771731 },
  { name: 'D334L', latitude: 41.9888, longitude: 12.483206 },
  { name: 'D332M', latitude: 41.992614, longitude: 12.119453 },
  { name: 'D229C', latitude: 41.992889, longitude: 35.022556 },
  { name: 'DESUK', latitude: 41.994758, longitude: 13.135792 },
  { name: 'ROVDO', latitude: 41.995833, longitude: 25.44 },
  { name: 'OSMEV', latitude: 41.997778, longitude: 30.571667 },
  { name: 'OTKOK', latitude: 41.999444, longitude: 24.0125 },
  { name: 'D341D', latitude: 41.999722, longitude: 21.608333 },
  { name: 'VP241', latitude: 42.0, longitude: 12.383333 },
  { name: '4213E', latitude: 42.0, longitude: 13.0 },
  { name: '4214E', latitude: 42.0, longitude: 14.0 },
  { name: '4215E', latitude: 42.0, longitude: 15.0 },
  { name: '4216E', latitude: 42.0, longitude: 16.0 },
  { name: '4217E', latitude: 42.0, longitude: 17.0 },
  { name: '4218E', latitude: 42.0, longitude: 18.0 },
  { name: '4219E', latitude: 42.0, longitude: 19.0 },
  { name: '4220E', latitude: 42.0, longitude: 20.0 },
  { name: '4221E', latitude: 42.0, longitude: 21.0 },
  { name: '4222E', latitude: 42.0, longitude: 22.0 },
  { name: '4223E', latitude: 42.0, longitude: 23.0 },
  { name: '4224E', latitude: 42.0, longitude: 24.0 },
  { name: '4225E', latitude: 42.0, longitude: 25.0 },
  { name: '4226E', latitude: 42.0, longitude: 26.0 },
  { name: '4227E', latitude: 42.0, longitude: 27.0 },
  { name: '4228E', latitude: 42.0, longitude: 28.0 },
  { name: '4229E', latitude: 42.0, longitude: 29.0 },
  { name: '4230E', latitude: 42.0, longitude: 30.0 },
  { name: '4231E', latitude: 42.0, longitude: 31.0 },
  { name: '4232E', latitude: 42.0, longitude: 32.0 },
  { name: '4233E', latitude: 42.0, longitude: 33.0 },
  { name: '4234E', latitude: 42.0, longitude: 34.0 },
  { name: '4235E', latitude: 42.0, longitude: 35.0 },
  { name: '4236E', latitude: 42.0, longitude: 36.0 },
  { name: '4237E', latitude: 42.0, longitude: 37.0 },
  { name: '4238E', latitude: 42.0, longitude: 38.0 },
  { name: '4239E', latitude: 42.0, longitude: 39.0 },
  { name: '4240E', latitude: 42.0, longitude: 40.0 },
  { name: 'ERMUP', latitude: 42.000556, longitude: 31.022778 },
  { name: 'D339J', latitude: 42.000764, longitude: 12.194128 },
  { name: 'RF735', latitude: 42.001358, longitude: 12.196281 },
  { name: 'ROM27', latitude: 42.003844, longitude: 13.128458 },
  { name: 'GULMO', latitude: 42.004167, longitude: 36.733333 },
  { name: 'RF751', latitude: 42.004811, longitude: 12.146761 },
  { name: 'PD550', latitude: 42.005472, longitude: 24.960778 },
  { name: 'VP188', latitude: 42.008333, longitude: 12.497222 },
  { name: 'MODAU', latitude: 42.009167, longitude: 31.885833 },
  { name: 'SUNUM', latitude: 42.011944, longitude: 34.8575 },
  { name: 'PD606', latitude: 42.011972, longitude: 24.949361 },
  { name: 'VAKSU', latitude: 42.014167, longitude: 18.526944 },
  { name: 'PD603', latitude: 42.014417, longitude: 25.129222 },
  { name: 'OXERU', latitude: 42.015833, longitude: 12.190278 },
  { name: 'RA533', latitude: 42.018, longitude: 12.436861 },
  { name: 'RF401', latitude: 42.020028, longitude: 12.852833 },
  { name: 'GEGDU', latitude: 42.021389, longitude: 12.593056 },
  { name: 'RA712', latitude: 42.0215, longitude: 12.564222 },
  { name: 'MIKSO', latitude: 42.024444, longitude: 12.186667 },
  { name: 'RA412', latitude: 42.026531, longitude: 12.444642 },
  { name: 'RA408', latitude: 42.026728, longitude: 12.437375 },
  { name: 'RA409', latitude: 42.029358, longitude: 12.338808 },
  { name: 'PEMAR', latitude: 42.029725, longitude: 12.919444 },
  { name: 'LISIN', latitude: 42.030833, longitude: 19.139722 },
  { name: 'D157Y', latitude: 42.031925, longitude: 18.722964 },
  { name: 'MA23', latitude: 42.036047, longitude: 35.10395 },
  { name: 'VP189', latitude: 42.036111, longitude: 12.635278 },
  { name: 'MD23', latitude: 42.036647, longitude: 35.104286 },
  { name: 'D230I', latitude: 42.036706, longitude: 12.226617 },
  { name: 'MQ23', latitude: 42.037353, longitude: 35.103597 },
  { name: 'LELSU', latitude: 42.037778, longitude: 12.8075 },
  { name: 'OM31', latitude: 42.038611, longitude: 24.902528 },
  { name: 'TV303', latitude: 42.039694, longitude: 18.808444 },
  { name: '12VOR', latitude: 42.041372, longitude: 24.897742 },
  { name: 'RF747', latitude: 42.041389, longitude: 12.131861 },
  { name: 'TV305', latitude: 42.043278, longitude: 18.966556 },
  { name: 'D182U', latitude: 42.043631, longitude: 19.198022 },
  { name: 'OGTOM', latitude: 42.044444, longitude: 12.565 },
  { name: 'KRATO', latitude: 42.047778, longitude: 22.081944 },
  { name: 'GOLPO', latitude: 42.049167, longitude: 12.128056 },
  { name: 'PD652', latitude: 42.051944, longitude: 24.9085 },
  { name: 'ATNOP', latitude: 42.052222, longitude: 34.858333 },
  { name: 'PG156', latitude: 42.053, longitude: 19.220639 },
  { name: 'MM31', latitude: 42.054944, longitude: 24.873611 },
  { name: 'PD503', latitude: 42.055417, longitude: 24.802194 },
  { name: 'D084Q', latitude: 42.057797, longitude: 25.263317 },
  { name: 'D226F', latitude: 42.058153, longitude: 12.280211 },
  { name: 'PD504', latitude: 42.058583, longitude: 24.682111 },
  { name: 'MOPUV', latitude: 42.059722, longitude: 13.281944 },
  { name: 'D332Q', latitude: 42.060564, longitude: 12.076775 },
  { name: 'ADUNO', latitude: 42.060833, longitude: 25.452167 },
  { name: 'MAVEN', latitude: 42.061944, longitude: 12.122778 },
  { name: '40NDB', latitude: 42.062419, longitude: 35.148283 },
  { name: 'FM085', latitude: 42.062556, longitude: 29.674025 },
  { name: 'RF748', latitude: 42.063417, longitude: 12.274333 },
  { name: 'D230F', latitude: 42.063575, longitude: 12.274306 },
  { name: 'VADEN', latitude: 42.065556, longitude: 27.216111 },
  { name: 'RA404', latitude: 42.066192, longitude: 12.712708 },
  { name: 'D232F', latitude: 42.066397, longitude: 12.271544 },
  { name: 'ETOPO', latitude: 42.066667, longitude: 26.328611 },
  { name: 'PD602', latitude: 42.066694, longitude: 25.0375 },
  { name: 'RF744', latitude: 42.067306, longitude: 12.270583 },
  { name: 'IRBES', latitude: 42.068611, longitude: 12.167778 },
  { name: 'D049E', latitude: 42.071272, longitude: 35.167328 },
  { name: 'ADUMU', latitude: 42.071389, longitude: 33.077778 },
  { name: 'D118P', latitude: 42.077244, longitude: 12.039556 },
  { name: 'RF822', latitude: 42.077722, longitude: 12.023417 },
  { name: 'RF708', latitude: 42.078389, longitude: 12.049389 },
  { name: 'FF230', latitude: 42.079633, longitude: 35.186808 },
  { name: 'FD23', latitude: 42.079633, longitude: 35.186808 },
  { name: 'FI23', latitude: 42.080872, longitude: 35.185528 },
  { name: 'LELKA', latitude: 42.081944, longitude: 25.154444 },
  { name: 'RIXEN', latitude: 42.082222, longitude: 28.766111 },
  { name: 'FF23', latitude: 42.082461, longitude: 35.184058 },
  { name: 'KU504', latitude: 42.086847, longitude: 20.443917 },
  { name: 'D050G', latitude: 42.089178, longitude: 35.205161 },
  { name: 'GIVEV', latitude: 42.089444, longitude: 32.2775 },
  { name: 'D048G', latitude: 42.092478, longitude: 35.201953 },
  { name: 'KU505', latitude: 42.093481, longitude: 20.315814 },
  { name: 'D046O', latitude: 42.093883, longitude: 21.889567 },
  { name: '42VOR', latitude: 42.095536, longitude: 24.801211 },
  { name: 'SARAX', latitude: 42.096944, longitude: 20.895556 },
  { name: 'LETNI', latitude: 42.096944, longitude: 22.605833 },
  { name: 'RA406', latitude: 42.098969, longitude: 12.5248 },
  { name: 'PD651', latitude: 42.099917, longitude: 24.794167 },
  { name: 'LASTI', latitude: 42.100444, longitude: 18.885083 },
  { name: 'CF23', latitude: 42.100644, longitude: 35.221581 },
  { name: 'VP190', latitude: 42.101944, longitude: 12.497778 },
  { name: 'RA534', latitude: 42.104389, longitude: 12.547139 },
  { name: 'OKUNO', latitude: 42.107222, longitude: 12.9725 },
  { name: 'PD505', latitude: 42.108861, longitude: 24.592833 },
  { name: 'KU701', latitude: 42.110433, longitude: 20.280719 },
  { name: 'MAMOL', latitude: 42.110889, longitude: 18.989 },
  { name: 'OTKOT', latitude: 42.111389, longitude: 34.432778 },
  { name: 'KOGAT', latitude: 42.1125, longitude: 21.055556 },
  { name: 'PG155', latitude: 42.114417, longitude: 19.226889 },
  { name: 'URUXO', latitude: 42.116111, longitude: 12.371111 },
  { name: 'SUVOK', latitude: 42.118333, longitude: 12.040278 },
  { name: 'D154R', latitude: 42.120286, longitude: 18.883475 },
  { name: 'ODMAX', latitude: 42.121389, longitude: 31.403056 },
  { name: '3NM', latitude: 42.121958, longitude: 20.491758 },
  { name: 'PD508', latitude: 42.124833, longitude: 24.750028 },
  { name: 'D286L', latitude: 42.125436, longitude: 24.623292 },
  { name: 'ARBER', latitude: 42.130278, longitude: 20.4975 },
  { name: 'FM086', latitude: 42.134231, longitude: 30.107092 },
  { name: 'XAXAN', latitude: 42.136694, longitude: 21.326694 },
  { name: 'PG310', latitude: 42.137944, longitude: 19.213556 },
  { name: 'D182O', latitude: 42.137964, longitude: 19.213542 },
  { name: 'PD502', latitude: 42.139, longitude: 24.849111 },
  { name: 'PD601', latitude: 42.140333, longitude: 24.907778 },
  { name: 'VP191', latitude: 42.141667, longitude: 12.504722 },
  { name: 'PD551', latitude: 42.146611, longitude: 24.868833 },
  { name: 'OLIVU', latitude: 42.148417, longitude: 18.946889 },
  { name: 'TV144', latitude: 42.149306, longitude: 18.7375 },
  { name: 'EXAMA', latitude: 42.15, longitude: 12.192222 },
  { name: 'KU702', latitude: 42.151231, longitude: 20.188119 },
  { name: 'EVKAM', latitude: 42.151389, longitude: 35.413056 },
  { name: 'VP192', latitude: 42.152778, longitude: 12.648611 },
  { name: 'FF12', latitude: 42.153397, longitude: 24.699667 },
  { name: 'MNL39', latitude: 42.153772, longitude: 15.374183 },
  { name: 'FD12', latitude: 42.158611, longitude: 24.688389 },
  { name: 'LONgitudeTA', latitude: 42.159444, longitude: 21.397222 },
  { name: 'OSTEX', latitude: 42.159444, longitude: 26.956111 },
  { name: 'GOSUT', latitude: 42.161056, longitude: 18.862722 },
  { name: 'TINIX', latitude: 42.165833, longitude: 13.122778 },
  { name: 'OLDUD', latitude: 42.166111, longitude: 32.611944 },
  { name: 'EKMIL', latitude: 42.168056, longitude: 14.133611 },
  { name: 'EVULI', latitude: 42.168889, longitude: 13.345556 },
  { name: 'TV142', latitude: 42.170278, longitude: 18.845917 },
  { name: 'MAKOL', latitude: 42.170556, longitude: 29.142778 },
  { name: 'VP193', latitude: 42.175, longitude: 13.833333 },
  { name: 'PD506', latitude: 42.175361, longitude: 24.660389 },
  { name: 'PD610', latitude: 42.179278, longitude: 24.732944 },
  { name: 'D188W', latitude: 42.179986, longitude: 20.920903 },
  { name: 'PD552', latitude: 42.18075, longitude: 24.791833 },
  { name: 'D211O', latitude: 42.182983, longitude: 19.058275 },
  { name: 'SOSIV', latitude: 42.183056, longitude: 12.039167 },
  { name: 'GERGI', latitude: 42.183333, longitude: 25.521944 },
  { name: 'TUDEK', latitude: 42.183889, longitude: 39.403056 },
  { name: 'KU503', latitude: 42.184831, longitude: 20.577381 },
  { name: 'VAKAB', latitude: 42.185, longitude: 12.342222 },
  { name: 'NAPEV', latitude: 42.187222, longitude: 18.732667 },
  { name: 'ASPIR', latitude: 42.188889, longitude: 13.7375 },
  { name: 'GAKSU', latitude: 42.189167, longitude: 33.814167 },
  { name: 'RA531', latitude: 42.192028, longitude: 12.109194 },
  { name: 'PD653', latitude: 42.192694, longitude: 24.669028 },
  { name: 'DU611', latitude: 42.196306, longitude: 18.025806 },
  { name: 'RF428', latitude: 42.197778, longitude: 12.006389 },
  { name: 'LIMAN', latitude: 42.198333, longitude: 19.095556 },
  { name: 'SORAG', latitude: 42.203167, longitude: 19.017361 },
  { name: 'TV146', latitude: 42.203194, longitude: 18.685611 },
  { name: 'TV301', latitude: 42.203222, longitude: 18.841 },
  { name: 'PD501', latitude: 42.203444, longitude: 24.642389 },
  { name: 'RASOV', latitude: 42.203611, longitude: 19.009722 },
  { name: 'D121H', latitude: 42.205442, longitude: 18.941903 },
  { name: 'PD600', latitude: 42.209778, longitude: 24.658861 },
  { name: 'D155L', latitude: 42.209908, longitude: 18.834072 },
  { name: 'D016R', latitude: 42.216389, longitude: 21.771667 },
  { name: 'RA411', latitude: 42.219197, longitude: 12.046614 },
  { name: 'ESALU', latitude: 42.219722, longitude: 12.492222 },
  { name: 'UVUDA', latitude: 42.225278, longitude: 27.803611 },
  { name: 'PD500', latitude: 42.225611, longitude: 24.570917 },
  { name: 'RETRA', latitude: 42.228333, longitude: 19.335 },
  { name: 'RF448', latitude: 42.2295, longitude: 12.158639 },
  { name: 'OLOTA', latitude: 42.23, longitude: 21.616667 },
  { name: 'TV322', latitude: 42.230611, longitude: 18.59925 },
  { name: 'D227O', latitude: 42.231914, longitude: 18.989419 },
  { name: 'TV143', latitude: 42.233333, longitude: 18.987972 },
  { name: 'CI36Y', latitude: 42.236642, longitude: 19.239406 },
  { name: 'PG302', latitude: 42.238056, longitude: 19.306389 },
  { name: 'PD553', latitude: 42.238167, longitude: 24.661944 },
  { name: 'OSDIP', latitude: 42.238333, longitude: 34.798611 },
  { name: 'DESOL', latitude: 42.238639, longitude: 18.632 },
  { name: 'PG301', latitude: 42.241778, longitude: 19.239917 },
  { name: 'KU502', latitude: 42.243322, longitude: 20.657289 },
  { name: 'TV145', latitude: 42.245306, longitude: 18.699611 },
  { name: 'VIRPA', latitude: 42.246111, longitude: 19.091389 },
  { name: 'SINNE', latitude: 42.246544, longitude: 20.167244 },
  { name: 'GIPDA', latitude: 42.246944, longitude: 32.205 },
  { name: 'TIBER', latitude: 42.248875, longitude: 12.599 },
  { name: 'SOVAP', latitude: 42.249722, longitude: 36.796111 },
  { name: 'ALFA', latitude: 42.25, longitude: 21.152778 },
  { name: 'PG151', latitude: 42.2505, longitude: 19.240833 },
  { name: 'GASRU', latitude: 42.251111, longitude: 26.099444 },
  { name: 'TIXIR', latitude: 42.255278, longitude: 13.891667 },
  { name: 'D337A', latitude: 42.256081, longitude: 18.624369 },
  { name: 'VP194', latitude: 42.256944, longitude: 12.178611 },
  { name: 'OSVOP', latitude: 42.258611, longitude: 27.090833 },
  { name: 'D219K', latitude: 42.260106, longitude: 19.09095 },
  { name: 'TV141', latitude: 42.262389, longitude: 18.794139 },
  { name: 'ASBEP', latitude: 42.262778, longitude: 32.123889 },
  { name: 'TV323', latitude: 42.263056, longitude: 18.726583 },
  { name: 'RF442', latitude: 42.264444, longitude: 12.308722 },
  { name: 'D302U', latitude: 42.266472, longitude: 24.494472 },
  { name: 'GARGA', latitude: 42.266667, longitude: 16.116667 },
  { name: 'FD36', latitude: 42.269767, longitude: 19.243647 },
  { name: 'FQ36Y', latitude: 42.269828, longitude: 19.243678 },
  { name: 'CI36Z', latitude: 42.269892, longitude: 19.242778 },
  { name: 'FI36Y', latitude: 42.269892, longitude: 19.242778 },
  { name: 'RIGVA', latitude: 42.270556, longitude: 18.239444 },
  { name: 'EDIKA', latitude: 42.276944, longitude: 22.878611 },
  { name: 'BP451', latitude: 42.27785, longitude: 14.154361 },
  { name: 'D182G', latitude: 42.278631, longitude: 19.236775 },
  { name: 'D296Z', latitude: 42.278972, longitude: 24.36825 },
  { name: 'VEDUR', latitude: 42.281222, longitude: 18.601361 },
  { name: 'D148R', latitude: 42.285889, longitude: 21.223192 },
  { name: 'BG377', latitude: 42.28675, longitude: 27.299083 },
  { name: 'RIXUB', latitude: 42.287222, longitude: 30.668889 },
  { name: 'NAPET', latitude: 42.288333, longitude: 23.005556 },
  { name: 'PG303', latitude: 42.288944, longitude: 19.311583 },
  { name: 'MOREK', latitude: 42.291944, longitude: 23.319167 },
  { name: 'PG152', latitude: 42.292444, longitude: 19.12975 },
  { name: 'RUVOM', latitude: 42.293611, longitude: 34.393333 },
  { name: 'D188P', latitude: 42.293903, longitude: 20.955069 },
  { name: 'VANET', latitude: 42.294722, longitude: 23.410278 },
  { name: 'PG355', latitude: 42.295722, longitude: 19.168611 },
  { name: 'VP195', latitude: 42.295833, longitude: 14.469444 },
  { name: 'D125N', latitude: 42.296322, longitude: 14.436519 },
  { name: 'BP439', latitude: 42.296375, longitude: 14.436578 },
  { name: 'OMENO', latitude: 42.296944, longitude: 23.481389 },
  { name: 'OM36', latitude: 42.298833, longitude: 19.24575 },
  { name: 'VP196', latitude: 42.299722, longitude: 13.277778 },
  { name: 'RUPAX', latitude: 42.300833, longitude: 15.471667 },
  { name: 'D154F', latitude: 42.301039, longitude: 18.790458 },
  { name: 'PG309', latitude: 42.30125, longitude: 19.240528 },
  { name: 'USALI', latitude: 42.301944, longitude: 23.645556 },
  { name: 'KU501', latitude: 42.302669, longitude: 20.578458 },
  { name: 'DU900', latitude: 42.304972, longitude: 18.243972 },
  { name: 'VP001', latitude: 42.305, longitude: 18.241944 },
  { name: 'D182O', latitude: 42.305, longitude: 18.243889 },
  { name: 'RUMEN', latitude: 42.305, longitude: 24.313333 },
  { name: 'RAXAD', latitude: 42.308333, longitude: 22.242778 },
  { name: 'RF447', latitude: 42.309, longitude: 12.124972 },
  { name: 'DILVO', latitude: 42.31, longitude: 23.936944 },
  { name: 'D182E', latitude: 42.310072, longitude: 19.241975 },
  { name: 'DU703', latitude: 42.311444, longitude: 18.154972 },
  { name: 'D200P', latitude: 42.311989, longitude: 20.884 },
  { name: 'D231L', latitude: 42.316575, longitude: 13.967669 },
  { name: 'VP197', latitude: 42.318056, longitude: 12.05 },
  { name: 'BP605', latitude: 42.323714, longitude: 14.051397 },
  { name: 'BIGLO', latitude: 42.323889, longitude: 18.720278 },
  { name: 'D231K', latitude: 42.326489, longitude: 13.985567 },
  { name: 'REKAR', latitude: 42.327306, longitude: 18.996833 },
  { name: 'TV321', latitude: 42.3275, longitude: 18.583278 },
  { name: 'PG345', latitude: 42.331333, longitude: 19.249 },
  { name: 'ABSAX', latitude: 42.333611, longitude: 32.583611 },
  { name: 'BG376', latitude: 42.334528, longitude: 27.361806 },
  { name: 'MD36', latitude: 42.336297, longitude: 19.249908 },
  { name: 'MQ36', latitude: 42.336356, longitude: 19.249936 },
  { name: 'MM36', latitude: 42.338278, longitude: 19.249806 },
  { name: 'PG304', latitude: 42.339833, longitude: 19.316833 },
  { name: 'LANNO', latitude: 42.341944, longitude: 13.888333 },
  { name: 'VP198', latitude: 42.342222, longitude: 12.855 },
  { name: 'D200O', latitude: 42.342506, longitude: 27.382742 },
  { name: 'CD35', latitude: 42.342653, longitude: 21.101611 },
  { name: 'VP199', latitude: 42.343333, longitude: 13.345 },
  { name: 'RF443', latitude: 42.344, longitude: 12.275222 },
  { name: 'BP434', latitude: 42.344386, longitude: 14.520714 },
  { name: 'BG365', latitude: 42.344917, longitude: 27.218528 },
  { name: 'MQ302', latitude: 42.345131, longitude: 18.687303 },
  { name: 'TILVO', latitude: 42.346111, longitude: 18.5575 },
  { name: 'LOKRU', latitude: 42.348611, longitude: 17.935556 },
  { name: 'ATRUP', latitude: 42.348889, longitude: 13.595556 },
  { name: 'ML32', latitude: 42.349189, longitude: 18.765581 },
  { name: 'VP201', latitude: 42.35, longitude: 14.175 },
  { name: 'D155M', latitude: 42.350142, longitude: 21.135836 },
  { name: 'VUKOV', latitude: 42.351667, longitude: 19.174167 },
  { name: 'KUKAD', latitude: 42.352222, longitude: 20.181389 },
  { name: 'VESLO', latitude: 42.3525, longitude: 18.594444 },
  { name: 'DU802', latitude: 42.352583, longitude: 18.258694 },
  { name: 'VP200', latitude: 42.354167, longitude: 14.411111 },
  { name: 'REKAS', latitude: 42.355278, longitude: 19.028056 },
  { name: 'D217P', latitude: 42.355364, longitude: 20.795222 },
  { name: 'D145O', latitude: 42.356556, longitude: 27.693528 },
  { name: 'DOTAM', latitude: 42.356667, longitude: 23.406944 },
  { name: 'BP615', latitude: 42.359, longitude: 14.278233 },
  { name: 'RERVA', latitude: 42.359722, longitude: 33.8925 },
  { name: 'LIGBU', latitude: 42.361389, longitude: 12.4325 },
  { name: 'TV324', latitude: 42.362972, longitude: 18.733667 },
  { name: 'PG354', latitude: 42.363333, longitude: 19.224861 },
  { name: 'ARTAT', latitude: 42.363889, longitude: 29.624167 },
  { name: 'TUZIV', latitude: 42.364167, longitude: 19.329722 },
  { name: 'D341O', latitude: 42.364658, longitude: 12.290728 },
  { name: 'D145M', latitude: 42.366058, longitude: 21.182036 },
  { name: 'D133O', latitude: 42.367783, longitude: 21.261564 },
  { name: 'D257G', latitude: 42.369792, longitude: 19.098283 },
  { name: 'D167K', latitude: 42.372781, longitude: 18.316872 },
  { name: 'UTAPO', latitude: 42.375556, longitude: 14.172778 },
  { name: 'D196K', latitude: 42.381222, longitude: 18.193778 },
  { name: 'PG308', latitude: 42.381361, longitude: 19.072556 },
  { name: 'NEGOX', latitude: 42.381806, longitude: 18.825222 },
  { name: 'BP611', latitude: 42.382014, longitude: 14.132031 },
  { name: 'OTLOG', latitude: 42.382222, longitude: 27.424722 },
  { name: 'D215D', latitude: 42.382833, longitude: 14.130308 },
  { name: 'D128O', latitude: 42.383033, longitude: 21.2826 },
  { name: 'D264B', latitude: 42.385592, longitude: 19.208842 },
  { name: 'D125E', latitude: 42.386072, longitude: 14.274419 },
  { name: 'VP204', latitude: 42.386111, longitude: 12.948333 },
  { name: 'RF446', latitude: 42.3885, longitude: 12.091194 },
  { name: 'D257N', latitude: 42.390592, longitude: 13.875142 },
  { name: 'DU708', latitude: 42.392, longitude: 18.343139 },
  { name: 'OBODA', latitude: 42.3925, longitude: 18.924444 },
  { name: 'ELTOG', latitude: 42.392778, longitude: 27.281389 },
  { name: 'D300L', latitude: 42.394267, longitude: 18.585464 },
  { name: 'MEDEM', latitude: 42.395, longitude: 27.971667 },
  { name: 'D216C', latitude: 42.395131, longitude: 14.1456 },
  { name: 'D212M', latitude: 42.395578, longitude: 27.347983 },
  { name: 'VP205', latitude: 42.395833, longitude: 12.008333 },
  { name: 'GENGI', latitude: 42.395833, longitude: 13.055833 },
  { name: 'SOLAN', latitude: 42.395833, longitude: 18.709167 },
  { name: 'D222O', latitude: 42.395953, longitude: 27.278622 },
  { name: 'D218M', latitude: 42.396461, longitude: 20.834603 },
  { name: 'VP002', latitude: 42.396667, longitude: 18.014722 },
  { name: 'RA421', latitude: 42.3969, longitude: 12.631278 },
  { name: 'BG430', latitude: 42.399639, longitude: 27.4825 },
  { name: 'BG364', latitude: 42.403, longitude: 27.137861 },
  { name: 'D257J', latitude: 42.403603, longitude: 13.963389 },
  { name: 'D300M', latitude: 42.404806, longitude: 18.565214 },
  { name: 'D257I', latitude: 42.406869, longitude: 13.985594 },
  { name: 'VP206', latitude: 42.410556, longitude: 12.768333 },
  { name: 'TIBRI', latitude: 42.410556, longitude: 18.554167 },
  { name: 'PG312', latitude: 42.411806, longitude: 18.879889 },
  { name: 'BP612', latitude: 42.41355, longitude: 14.025036 },
  { name: 'CS315', latitude: 42.415306, longitude: 18.439806 },
  { name: 'VP207', latitude: 42.416667, longitude: 12.105556 },
  { name: 'VP209', latitude: 42.418056, longitude: 13.340833 },
  { name: 'BP614', latitude: 42.420136, longitude: 14.171422 },
  { name: 'VEPUM', latitude: 42.420556, longitude: 33.417222 },
  { name: 'DU500', latitude: 42.421306, longitude: 18.293139 },
  { name: 'RF444', latitude: 42.423528, longitude: 12.241611 },
  { name: 'D199B', latitude: 42.423742, longitude: 19.275992 },
  { name: 'INBAV', latitude: 42.423833, longitude: 19.135861 },
  { name: 'D150H', latitude: 42.427933, longitude: 18.360303 },
  { name: 'BG374', latitude: 42.430306, longitude: 27.488 },
  { name: 'DU702', latitude: 42.431833, longitude: 17.850111 },
  { name: 'TV147', latitude: 42.432417, longitude: 18.696083 },
  { name: 'VP211', latitude: 42.433333, longitude: 12.15 },
  { name: 'PG351', latitude: 42.433389, longitude: 19.277 },
  { name: 'D013C', latitude: 42.433761, longitude: 19.275475 },
  { name: 'PG305', latitude: 42.434, longitude: 19.3265 },
  { name: 'KONUV', latitude: 42.435833, longitude: 18.436667 },
  { name: 'VP212', latitude: 42.436111, longitude: 13.299167 },
  { name: 'PG346', latitude: 42.436861, longitude: 19.265861 },
  { name: 'PG307', latitude: 42.438222, longitude: 19.090222 },
  { name: 'GEBSU', latitude: 42.440556, longitude: 27.344167 },
  { name: 'ESODU', latitude: 42.442778, longitude: 14.331389 },
  { name: 'BG610', latitude: 42.443694, longitude: 27.294444 },
  { name: 'BG622', latitude: 42.444444, longitude: 27.768222 },
  { name: 'D084H', latitude: 42.444544, longitude: 14.363856 },
  { name: 'ML22', latitude: 42.445333, longitude: 14.197528 },
  { name: 'FD35', latitude: 42.446636, longitude: 21.069311 },
  { name: 'FS315', latitude: 42.448083, longitude: 18.4015 },
  { name: 'MD22', latitude: 42.448397, longitude: 14.199508 },
  { name: 'D232M', latitude: 42.449597, longitude: 27.277228 },
  { name: 'D084N', latitude: 42.449933, longitude: 14.498736 },
  { name: 'SF640', latitude: 42.450194, longitude: 23.473083 },
  { name: 'MOKUN', latitude: 42.450278, longitude: 18.48 },
  { name: 'BG363', latitude: 42.450861, longitude: 27.200583 },
  { name: 'BP604', latitude: 42.450892, longitude: 14.5178 },
  { name: 'DOSOS', latitude: 42.451667, longitude: 19.047639 },
  { name: 'VP003', latitude: 42.4525, longitude: 18.436389 },
  { name: 'D084Q', latitude: 42.452842, longitude: 14.566161 },
  { name: 'BAVOM', latitude: 42.453056, longitude: 14.545278 },
  { name: 'PG353', latitude: 42.455139, longitude: 19.172667 },
  { name: 'BIMVO', latitude: 42.456111, longitude: 33.131944 },
  { name: 'EVGEG', latitude: 42.457222, longitude: 31.1075 },
  { name: 'VP213', latitude: 42.458333, longitude: 13.927778 },
  { name: 'D284N', latitude: 42.459294, longitude: 18.966481 },
  { name: 'D084X', latitude: 42.459475, longitude: 14.723514 },
  { name: 'DU606', latitude: 42.459583, longitude: 17.855778 },
  { name: 'BP435', latitude: 42.459686, longitude: 14.723722 },
  { name: 'PG352', latitude: 42.461083, longitude: 19.193194 },
  { name: 'BP613', latitude: 42.461642, longitude: 14.098683 },
  { name: 'NEGEM', latitude: 42.461667, longitude: 29.870278 },
  { name: 'D171F', latitude: 42.462386, longitude: 21.044528 },
  { name: 'PG356', latitude: 42.463056, longitude: 19.262444 },
  { name: 'PG347', latitude: 42.468167, longitude: 19.1895 },
  { name: 'BG621', latitude: 42.468194, longitude: 27.669944 },
  { name: 'PG311', latitude: 42.470667, longitude: 18.98725 },
  { name: 'INTUD', latitude: 42.471389, longitude: 12.620556 },
  { name: 'D248P', latitude: 42.473636, longitude: 20.692006 },
  { name: 'D198G', latitude: 42.475028, longitude: 18.152525 },
  { name: 'DUC01', latitude: 42.475222, longitude: 18.332056 },
  { name: 'DUC02', latitude: 42.475222, longitude: 18.351583 },
  { name: 'DU502', latitude: 42.47525, longitude: 18.414639 },
  { name: 'RAVUX', latitude: 42.476389, longitude: 12.341111 },
  { name: 'BG373', latitude: 42.478306, longitude: 27.551472 },
  { name: 'ERPOG', latitude: 42.478333, longitude: 15.2025 },
  { name: 'VP215', latitude: 42.479167, longitude: 12.120833 },
  { name: 'ALELU', latitude: 42.479167, longitude: 19.850556 },
  { name: 'LIGNA', latitude: 42.480778, longitude: 18.849917 },
  { name: 'D104Q', latitude: 42.482603, longitude: 27.883911 },
  { name: '56VOR', latitude: 42.483344, longitude: 18.360186 },
  { name: 'OLEGU', latitude: 42.485028, longitude: 18.131667 },
  { name: 'D113K', latitude: 42.485844, longitude: 27.739422 },
  { name: 'D171D', latitude: 42.487333, longitude: 21.042172 },
  { name: 'BP601', latitude: 42.487681, longitude: 14.241425 },
  { name: 'BG611', latitude: 42.488389, longitude: 27.148778 },
  { name: 'BG431', latitude: 42.488944, longitude: 27.565556 },
  { name: 'BP616', latitude: 42.489464, longitude: 14.098978 },
  { name: 'IDLER', latitude: 42.490278, longitude: 40.145833 },
  { name: 'D033D', latitude: 42.490733, longitude: 14.236119 },
  { name: '42LOC', latitude: 42.491767, longitude: 14.245661 },
  { name: 'D198D', latitude: 42.492, longitude: 18.243675 },
  { name: 'SF631', latitude: 42.492972, longitude: 23.995028 },
  { name: '48VOR', latitude: 42.493419, longitude: 18.348375 },
  { name: 'D248L', latitude: 42.495297, longitude: 20.784339 },
  { name: 'BIDVO', latitude: 42.498611, longitude: 27.263333 },
  { name: 'RIVAM', latitude: 42.499167, longitude: 15.772778 },
  { name: 'DINEN', latitude: 42.499722, longitude: 25.992222 },
  { name: 'BG768', latitude: 42.501333, longitude: 27.424444 },
  { name: 'LARAT', latitude: 42.501667, longitude: 23.871111 },
  { name: '32VOR', latitude: 42.501875, longitude: 21.052106 },
  { name: 'IDLOL', latitude: 42.502778, longitude: 13.928056 },
  { name: 'VABUR', latitude: 42.503056, longitude: 28.059722 },
  { name: 'SF506', latitude: 42.504583, longitude: 23.814944 },
  { name: 'MS315', latitude: 42.506008, longitude: 18.333608 },
  { name: 'DU504', latitude: 42.506333, longitude: 18.398611 },
  { name: 'VP004', latitude: 42.506944, longitude: 18.2275 },
  { name: 'KATTI', latitude: 42.507778, longitude: 16.048889 },
  { name: 'DU609', latitude: 42.507806, longitude: 18.069056 },
  { name: 'FD22', latitude: 42.510331, longitude: 14.274886 },
  { name: 'MEDUN', latitude: 42.512222, longitude: 19.351389 },
  { name: 'BUFRA', latitude: 42.512222, longitude: 24.705556 },
  { name: 'RISAN', latitude: 42.513889, longitude: 18.696111 },
  { name: 'SF705', latitude: 42.514139, longitude: 23.661611 },
  { name: 'DU607', latitude: 42.514194, longitude: 17.716389 },
  { name: 'FS22', latitude: 42.514417, longitude: 14.279869 },
  { name: 'BG384', latitude: 42.514778, longitude: 27.599778 },
  { name: 'BG623', latitude: 42.515167, longitude: 27.899056 },
  { name: 'SF630', latitude: 42.515639, longitude: 23.639222 },
  { name: 'DU506', latitude: 42.516333, longitude: 18.381472 },
  { name: 'D218C', latitude: 42.516658, longitude: 18.232192 },
  { name: 'FI22', latitude: 42.518111, longitude: 14.273028 },
  { name: 'FS22W', latitude: 42.522142, longitude: 14.289283 },
  { name: 'SF507', latitude: 42.52225, longitude: 23.532056 },
  { name: 'BG421', latitude: 42.524333, longitude: 27.438306 },
  { name: 'BG630', latitude: 42.524917, longitude: 27.438 },
  { name: 'DU704', latitude: 42.526111, longitude: 17.609139 },
  { name: 'SF425', latitude: 42.526139, longitude: 23.178667 },
  { name: 'BG390', latitude: 42.528833, longitude: 27.618417 },
  { name: 'PG306', latitude: 42.529472, longitude: 19.336361 },
  { name: '48VOR', latitude: 42.530006, longitude: 27.462208 },
  { name: '24THR', latitude: 42.531219, longitude: 27.463758 },
  { name: 'AKIKA', latitude: 42.534167, longitude: 19.603889 },
  { name: 'VP217', latitude: 42.535278, longitude: 12.761111 },
  { name: 'GUKMO', latitude: 42.535278, longitude: 23.61 },
  { name: 'ORAKA', latitude: 42.536944, longitude: 17.200556 },
  { name: 'D060M', latitude: 42.537489, longitude: 14.443186 },
  { name: 'DU801', latitude: 42.538639, longitude: 18.325917 },
  { name: 'D018J', latitude: 42.539775, longitude: 19.342242 },
  { name: 'CI22Z', latitude: 42.5405, longitude: 14.296306 },
  { name: 'D258M', latitude: 42.540522, longitude: 27.229619 },
  { name: 'BAKLO', latitude: 42.542778, longitude: 26.492222 },
  { name: 'AMUGO', latitude: 42.544167, longitude: 17.583889 },
  { name: 'CI22Y', latitude: 42.544528, longitude: 14.300583 },
  { name: 'D065Q', latitude: 42.547833, longitude: 14.535972 },
  { name: 'MD04', latitude: 42.5485, longitude: 27.486556 },
  { name: 'BP421', latitude: 42.549042, longitude: 14.505692 },
  { name: 'BG383', latitude: 42.5505, longitude: 27.647167 },
  { name: 'DANIL', latitude: 42.551944, longitude: 19.103889 },
  { name: 'DU399', latitude: 42.552972, longitude: 18.289333 },
  { name: 'BG432', latitude: 42.553222, longitude: 27.650806 },
  { name: 'D085O', latitude: 42.553361, longitude: 21.374139 },
  { name: 'BG420', latitude: 42.553528, longitude: 27.49325 },
  { name: 'D314J', latitude: 42.555483, longitude: 14.027272 },
  { name: 'VADKI', latitude: 42.558889, longitude: 14.336111 },
  { name: 'DU701', latitude: 42.559833, longitude: 17.941972 },
  { name: 'BG361', latitude: 42.561361, longitude: 27.217278 },
  { name: 'ERASO', latitude: 42.562694, longitude: 17.92975 },
  { name: 'BP424', latitude: 42.566364, longitude: 14.571586 },
  { name: 'ASTUB', latitude: 42.567861, longitude: 14.325639 },
  { name: 'GANTU', latitude: 42.569222, longitude: 18.872056 },
  { name: 'D013H', latitude: 42.569486, longitude: 14.232619 },
  { name: 'MM11', latitude: 42.574392, longitude: 18.235786 },
  { name: 'SF605', latitude: 42.578694, longitude: 23.963306 },
  { name: 'SF424', latitude: 42.579639, longitude: 23.124833 },
  { name: 'NAVAG', latitude: 42.580278, longitude: 14.096667 },
  { name: 'DER04', latitude: 42.580431, longitude: 27.528883 },
  { name: 'MD11', latitude: 42.583639, longitude: 18.211417 },
  { name: '20LOC', latitude: 42.583806, longitude: 18.211639 },
  { name: 'SF434', latitude: 42.584028, longitude: 23.880583 },
  { name: 'ABKEV', latitude: 42.584167, longitude: 32.048333 },
  { name: 'SF606', latitude: 42.5845, longitude: 23.873444 },
  { name: 'D270M', latitude: 42.584528, longitude: 27.227161 },
  { name: 'MD17', latitude: 42.585458, longitude: 21.032897 },
  { name: 'RITAG', latitude: 42.585833, longitude: 29.557778 },
  { name: 'ML22', latitude: 42.587222, longitude: 27.537694 },
  { name: 'BAMUP', latitude: 42.5875, longitude: 32.0175 },
  { name: 'D270O', latitude: 42.588003, longitude: 27.182244 },
  { name: 'D019J', latitude: 42.591542, longitude: 14.265644 },
  { name: 'SF607', latitude: 42.591639, longitude: 23.761083 },
  { name: 'NASEM', latitude: 42.591667, longitude: 29.715833 },
  { name: 'BAVGA', latitude: 42.593889, longitude: 22.665 },
  { name: 'PIDOR', latitude: 42.593889, longitude: 23.656111 },
  { name: 'DEDIN', latitude: 42.594167, longitude: 24.079444 },
  { name: 'D297E', latitude: 42.596111, longitude: 18.180278 },
  { name: 'D048O', latitude: 42.596292, longitude: 14.443458 },
  { name: 'BG511', latitude: 42.596417, longitude: 27.761278 },
  { name: 'BP603', latitude: 42.597947, longitude: 14.344022 },
  { name: 'ARKIR', latitude: 42.598611, longitude: 23.648611 },
  { name: 'ML17', latitude: 42.599161, longitude: 21.033189 },
  { name: 'UNERU', latitude: 42.601667, longitude: 23.637778 },
  { name: 'EBOVA', latitude: 42.602056, longitude: 18.802917 },
  { name: 'MADOS', latitude: 42.6025, longitude: 18.249167 },
  { name: 'IXUKO', latitude: 42.604167, longitude: 26.7675 },
  { name: '15VOR', latitude: 42.605042, longitude: 27.561642 },
  { name: '21THR', latitude: 42.605436, longitude: 27.562042 },
  { name: 'SF609', latitude: 42.605611, longitude: 23.53625 },
  { name: 'ETRIN', latitude: 42.605833, longitude: 14.72 },
  { name: 'D045O', latitude: 42.606317, longitude: 14.432006 },
  { name: 'BG404', latitude: 42.607611, longitude: 27.881639 },
  { name: 'D044O', latitude: 42.609297, longitude: 14.4277 },
  { name: 'SF610', latitude: 42.609722, longitude: 23.468778 },
  { name: 'BG408', latitude: 42.610361, longitude: 27.410639 },
  { name: 'ODERO', latitude: 42.610556, longitude: 30.264444 },
  { name: 'BG382', latitude: 42.610889, longitude: 27.727472 },
  { name: 'BG500', latitude: 42.611056, longitude: 27.553361 },
  { name: 'SF430', latitude: 42.614306, longitude: 23.392722 },
  { name: 'SF611', latitude: 42.616139, longitude: 23.361833 },
  { name: 'PELEV', latitude: 42.616556, longitude: 19.386222 },
  { name: 'DU605', latitude: 42.617389, longitude: 17.790222 },
  { name: 'GIKIN', latitude: 42.618333, longitude: 12.048611 },
  { name: 'BG401', latitude: 42.618639, longitude: 27.737833 },
  { name: 'DU400', latitude: 42.619361, longitude: 18.121639 },
  { name: 'FI11Z', latitude: 42.619417, longitude: 18.121556 },
  { name: 'DU705', latitude: 42.619889, longitude: 17.367417 },
  { name: 'BP414', latitude: 42.6206, longitude: 14.380936 },
  { name: 'SF429', latitude: 42.626639, longitude: 23.183444 },
  { name: '84VOR', latitude: 42.62675, longitude: 18.115639 },
  { name: '41LOC', latitude: 42.629197, longitude: 27.593547 },
  { name: 'BP618', latitude: 42.630014, longitude: 14.304872 },
  { name: 'OGMOS', latitude: 42.631389, longitude: 39.501944 },
  { name: 'SF423', latitude: 42.633111, longitude: 23.070889 },
  { name: 'STUBA', latitude: 42.635833, longitude: 22.033611 },
  { name: 'BG600', latitude: 42.63775, longitude: 27.410944 },
  { name: 'XIMRI', latitude: 42.639444, longitude: 22.958333 },
  { name: 'D032O', latitude: 42.643006, longitude: 14.373583 },
  { name: 'XILTI', latitude: 42.643056, longitude: 28.137222 },
  { name: 'D258X', latitude: 42.643611, longitude: 22.8545 },
  { name: 'SF421', latitude: 42.6445, longitude: 22.868222 },
  { name: 'VP006', latitude: 42.645, longitude: 17.9475 },
  { name: '55VOR', latitude: 42.645333, longitude: 21.027222 },
  { name: 'UTOMO', latitude: 42.645833, longitude: 19.852778 },
  { name: '38LOC', latitude: 42.645878, longitude: 21.028422 },
  { name: 'D334N', latitude: 42.648686, longitude: 14.054639 },
  { name: 'SF420', latitude: 42.649444, longitude: 22.778139 },
  { name: 'BG379', latitude: 42.652278, longitude: 27.62425 },
  { name: 'D028O', latitude: 42.652286, longitude: 14.353558 },
  { name: 'FI11Y', latitude: 42.653944, longitude: 18.033917 },
  { name: 'AMGOK', latitude: 42.655, longitude: 13.896111 },
  { name: 'BG403', latitude: 42.655056, longitude: 27.945139 },
  { name: 'RILIM', latitude: 42.658611, longitude: 16.815556 },
  { name: 'BG501', latitude: 42.658944, longitude: 27.327417 },
  { name: 'BG381', latitude: 42.65975, longitude: 27.792667 },
  { name: 'BG502', latitude: 42.660167, longitude: 27.247667 },
  { name: 'FD11', latitude: 42.660528, longitude: 18.040361 },
  { name: 'CF11Y', latitude: 42.661564, longitude: 18.014553 },
  { name: 'SF604', latitude: 42.661694, longitude: 23.973194 },
  { name: 'DU610', latitude: 42.662389, longitude: 17.334778 },
  { name: 'BG503', latitude: 42.663083, longitude: 27.039333 },
  { name: 'BG717', latitude: 42.663667, longitude: 27.639694 },
  { name: 'BP401', latitude: 42.664086, longitude: 14.045289 },
  { name: 'BG504', latitude: 42.664778, longitude: 26.90275 },
  { name: 'DU402', latitude: 42.665722, longitude: 18.004 },
  { name: 'AMGUG', latitude: 42.666111, longitude: 27.801111 },
  { name: 'SF603', latitude: 42.667528, longitude: 23.883194 },
  { name: 'SF505', latitude: 42.667944, longitude: 23.876389 },
  { name: 'D356N', latitude: 42.669103, longitude: 14.173181 },
  { name: 'DU604', latitude: 42.671917, longitude: 17.650444 },
  { name: 'OSTRO', latitude: 42.671944, longitude: 18.995556 },
  { name: 'RUBIV', latitude: 42.674722, longitude: 23.770833 },
  { name: 'BG409', latitude: 42.675722, longitude: 27.497167 },
  { name: 'D271F', latitude: 42.676911, longitude: 23.68805 },
  { name: 'CF27', latitude: 42.6785, longitude: 23.704611 },
  { name: 'D272H', latitude: 42.67915, longitude: 23.626714 },
  { name: 'ARSOB', latitude: 42.679167, longitude: 14.896111 },
  { name: 'D060O', latitude: 42.680278, longitude: 27.824497 },
  { name: 'BP617', latitude: 42.680611, longitude: 14.135536 },
  { name: 'KARAT', latitude: 42.681389, longitude: 39.270278 },
  { name: 'BAGIP', latitude: 42.681667, longitude: 23.658056 },
  { name: 'GAPVO', latitude: 42.681667, longitude: 24.145556 },
  { name: 'TUFTE', latitude: 42.684167, longitude: 13.467222 },
  { name: 'BALUM', latitude: 42.685, longitude: 37.666667 },
  { name: 'D356O', latitude: 42.685761, longitude: 14.172336 },
  { name: 'BP419', latitude: 42.686464, longitude: 14.450156 },
  { name: 'D310J', latitude: 42.686628, longitude: 27.357136 },
  { name: 'TUTPO', latitude: 42.687222, longitude: 23.568056 },
  { name: 'SF450', latitude: 42.687222, longitude: 23.569556 },
  { name: 'DU901', latitude: 42.687528, longitude: 17.948472 },
  { name: 'MA27', latitude: 42.69425, longitude: 23.452444 },
  { name: 'OTRET', latitude: 42.694444, longitude: 14.026667 },
  { name: 'FI17', latitude: 42.697414, longitude: 21.023156 },
  { name: 'FD17', latitude: 42.698553, longitude: 21.022169 },
  { name: 'RITEB', latitude: 42.698611, longitude: 12.163611 },
  { name: 'ML09', latitude: 42.699194, longitude: 23.371361 },
  { name: 'MD09', latitude: 42.699333, longitude: 23.369111 },
  { name: '25LOC', latitude: 42.700647, longitude: 23.347108 },
  { name: '21VOR', latitude: 42.700925, longitude: 23.342075 },
  { name: '26THR', latitude: 42.701008, longitude: 23.341006 },
  { name: 'BG402', latitude: 42.702472, longitude: 28.008722 },
  { name: 'BG601', latitude: 42.702806, longitude: 27.394778 },
  { name: 'XOLTA', latitude: 42.703889, longitude: 15.748333 },
  { name: 'FI09', latitude: 42.707156, longitude: 23.2367 },
  { name: 'MAGUP', latitude: 42.707222, longitude: 23.235 },
  { name: 'ODIRA', latitude: 42.708889, longitude: 36.914722 },
  { name: 'D322J', latitude: 42.709081, longitude: 27.393203 },
  { name: 'BP433', latitude: 42.712228, longitude: 14.232431 },
  { name: 'BG406', latitude: 42.713667, longitude: 27.864722 },
  { name: 'BP602', latitude: 42.714247, longitude: 14.191739 },
  { name: 'LUXUV', latitude: 42.7175, longitude: 23.056944 },
  { name: 'SF612', latitude: 42.717556, longitude: 23.202444 },
  { name: 'LOC16', latitude: 42.717722, longitude: 23.051872 },
  { name: 'D356Q', latitude: 42.719086, longitude: 14.170814 },
  { name: 'PILAP', latitude: 42.7205, longitude: 17.864306 },
  { name: 'D355J', latitude: 42.720578, longitude: 21.037264 },
  { name: 'VP226', latitude: 42.722222, longitude: 12.129167 },
  { name: 'BENGO', latitude: 42.7225, longitude: 22.966944 },
  { name: 'D048O', latitude: 42.724708, longitude: 27.787139 },
  { name: 'VAGEN', latitude: 42.724722, longitude: 22.077778 },
  { name: 'IVKOR', latitude: 42.724722, longitude: 27.720556 },
  { name: 'SF403', latitude: 42.727639, longitude: 22.876667 },
  { name: 'BG385', latitude: 42.729194, longitude: 27.093667 },
  { name: 'D058T', latitude: 42.730719, longitude: 27.934333 },
  { name: 'BRSNO', latitude: 42.731944, longitude: 19.023056 },
  { name: 'SF404', latitude: 42.732583, longitude: 22.786472 },
  { name: 'D058U', latitude: 42.733022, longitude: 27.940356 },
  { name: 'SF704', latitude: 42.734472, longitude: 23.529917 },
  { name: 'BG372', latitude: 42.735333, longitude: 27.893778 },
  { name: 'BG410', latitude: 42.7355, longitude: 27.576556 },
  { name: 'D348J', latitude: 42.736064, longitude: 27.487597 },
  { name: 'DOPUT', latitude: 42.736111, longitude: 17.899167 },
  { name: 'UDROS', latitude: 42.738889, longitude: 30.596111 },
  { name: 'D120U', latitude: 42.740972, longitude: 17.876597 },
  { name: 'D319N', latitude: 42.743353, longitude: 20.8513 },
  { name: 'D310O', latitude: 42.744514, longitude: 27.275797 },
  { name: 'LANGI', latitude: 42.744722, longitude: 23.983056 },
  { name: 'BP432', latitude: 42.746933, longitude: 14.229919 },
  { name: 'MEDUX', latitude: 42.7475, longitude: 20.021889 },
  { name: 'ARNOV', latitude: 42.747778, longitude: 26.768056 },
  { name: 'BP423', latitude: 42.748214, longitude: 14.416003 },
  { name: 'VP227', latitude: 42.75, longitude: 13.966667 },
  { name: 'ETRIL', latitude: 42.750556, longitude: 23.893056 },
  { name: 'GOLUB', latitude: 42.751667, longitude: 18.796944 },
  { name: 'DU902', latitude: 42.755972, longitude: 17.773528 },
  { name: 'SF622', latitude: 42.757722, longitude: 23.780333 },
  { name: 'BG407', latitude: 42.761111, longitude: 27.928333 },
  { name: 'ETPOK', latitude: 42.761389, longitude: 12.166667 },
  { name: 'D046R', latitude: 42.765231, longitude: 27.829908 },
  { name: 'RUDOP', latitude: 42.766111, longitude: 19.390833 },
  { name: 'SUTOB', latitude: 42.766389, longitude: 14.506111 },
  { name: 'SF706', latitude: 42.76675, longitude: 23.267861 },
  { name: 'D310Q', latitude: 42.767389, longitude: 27.242872 },
  { name: 'ENMOC', latitude: 42.768889, longitude: 14.315278 },
  { name: 'SF707', latitude: 42.769167, longitude: 23.095111 },
  { name: 'D018Y', latitude: 42.770011, longitude: 19.474497 },
  { name: 'NIKSA', latitude: 42.771389, longitude: 18.947778 },
  { name: 'GIGPU', latitude: 42.772222, longitude: 27.784167 },
  { name: 'SF417', latitude: 42.773583, longitude: 23.524861 },
  { name: 'BG602', latitude: 42.773639, longitude: 27.377167 },
  { name: 'D035P', latitude: 42.773717, longitude: 27.742867 },
  { name: 'DU601', latitude: 42.775139, longitude: 17.724333 },
  { name: 'D319P', latitude: 42.775808, longitude: 20.819542 },
  { name: 'D030O', latitude: 42.778222, longitude: 27.709689 },
  { name: 'UMPIT', latitude: 42.779722, longitude: 24.174167 },
  { name: 'SF416', latitude: 42.780472, longitude: 23.411 },
  { name: 'CI17', latitude: 42.780536, longitude: 21.014642 },
  { name: 'KUGOS', latitude: 42.780556, longitude: 34.087778 },
  { name: 'SF702', latitude: 42.781444, longitude: 23.613222 },
  { name: 'CD17', latitude: 42.781706, longitude: 21.014258 },
  { name: 'D303K', latitude: 42.782006, longitude: 23.614136 },
  { name: 'VP230', latitude: 42.782222, longitude: 12.415833 },
  { name: 'EKDOD', latitude: 42.783056, longitude: 27.64 },
  { name: 'LASDU', latitude: 42.783611, longitude: 17.148333 },
  { name: 'SF409', latitude: 42.791139, longitude: 23.230528 },
  { name: 'KOSOV', latitude: 42.793583, longitude: 21.011306 },
  { name: 'RAKUR', latitude: 42.798889, longitude: 31.947778 },
  { name: 'SUMOL', latitude: 42.798889, longitude: 33.181111 },
  { name: 'BP431', latitude: 42.799192, longitude: 14.247669 },
  { name: 'D020O', latitude: 42.799428, longitude: 27.657983 },
  { name: 'GITOD', latitude: 42.800347, longitude: 12.3107 },
  { name: 'LEMDO', latitude: 42.800556, longitude: 23.065833 },
  { name: 'GEBNI', latitude: 42.802222, longitude: 18.130556 },
  { name: 'SF407', latitude: 42.805722, longitude: 22.975472 },
  { name: 'D027P', latitude: 42.806925, longitude: 27.716094 },
  { name: 'GUDPO', latitude: 42.808056, longitude: 13.692778 },
  { name: 'NESAR', latitude: 42.809722, longitude: 28.141667 },
  { name: 'SF406', latitude: 42.810778, longitude: 22.885139 },
  { name: 'D027Q', latitude: 42.812603, longitude: 27.720842 },
  { name: 'D319S', latitude: 42.813667, longitude: 20.782442 },
  { name: 'ETUBA', latitude: 42.814444, longitude: 26.596111 },
  { name: 'SF503', latitude: 42.814556, longitude: 23.85325 },
  { name: 'SF405', latitude: 42.815722, longitude: 22.794806 },
  { name: 'BG414', latitude: 42.819722, longitude: 27.847778 },
  { name: 'D343P', latitude: 42.821278, longitude: 20.958667 },
  { name: 'DU706', latitude: 42.823139, longitude: 17.252472 },
  { name: 'D351P', latitude: 42.826608, longitude: 21.009978 },
  { name: 'RZ502', latitude: 42.827697, longitude: 12.536969 },
  { name: 'VAGAN', latitude: 42.828611, longitude: 19.514444 },
  { name: 'BG412', latitude: 42.830694, longitude: 27.703472 },
  { name: 'D350H', latitude: 42.830975, longitude: 23.373594 },
  { name: 'DOLEV', latitude: 42.833889, longitude: 20.311389 },
  { name: 'INKIM', latitude: 42.835833, longitude: 14.165278 },
  { name: 'DU707', latitude: 42.839056, longitude: 17.338278 },
  { name: 'SF623', latitude: 42.84075, longitude: 23.790028 },
  { name: 'OKANA', latitude: 42.846389, longitude: 22.493056 },
  { name: 'SF701', latitude: 42.847472, longitude: 23.330306 },
  { name: 'RZ503', latitude: 42.848308, longitude: 12.427236 },
  { name: 'UPONO', latitude: 42.849167, longitude: 12.004722 },
  { name: 'SOPIS', latitude: 42.849444, longitude: 24.620833 },
  { name: 'LETVA', latitude: 42.85, longitude: 24.184444 },
  { name: 'RZ607', latitude: 42.856956, longitude: 12.507508 },
  { name: 'SF502', latitude: 42.857278, longitude: 23.523806 },
  { name: 'JELOV', latitude: 42.863889, longitude: 18.901389 },
  { name: 'D191O', latitude: 42.864558, longitude: 12.432992 },
  { name: 'NI29A', latitude: 42.867178, longitude: 22.02525 },
  { name: 'RZ508', latitude: 42.868814, longitude: 12.317433 },
  { name: 'RZ403', latitude: 42.869028, longitude: 12.387694 },
  { name: 'D199O', latitude: 42.869767, longitude: 12.384531 },
  { name: 'D200O', latitude: 42.871431, longitude: 12.379047 },
  { name: 'NI107', latitude: 42.872278, longitude: 22.018722 },
  { name: 'D189N', latitude: 42.873381, longitude: 12.445911 },
  { name: 'SF410', latitude: 42.874222, longitude: 23.239472 },
  { name: 'BG413', latitude: 42.87825, longitude: 27.767056 },
  { name: 'SF411', latitude: 42.8805, longitude: 23.131 },
  { name: 'RUTAR', latitude: 42.883611, longitude: 28.020556 },
  { name: 'IPGOR', latitude: 42.885556, longitude: 12.269167 },
  { name: 'SF412', latitude: 42.889167, longitude: 22.978361 },
  { name: 'D190M', latitude: 42.890506, longitude: 12.445586 },
  { name: 'D193M', latitude: 42.893336, longitude: 12.430642 },
  { name: 'RZ606', latitude: 42.904931, longitude: 12.388161 },
  { name: 'NERRA', latitude: 42.905278, longitude: 17.543333 },
  { name: 'D203M', latitude: 42.907175, longitude: 12.381292 },
  { name: 'VELUG', latitude: 42.9075, longitude: 15.4375 },
  { name: 'RZ501', latitude: 42.908439, longitude: 12.565081 },
  { name: 'RZ507', latitude: 42.917286, longitude: 12.334128 },
  { name: 'TODRO', latitude: 42.920278, longitude: 23.3625 },
  { name: 'SITNO', latitude: 42.927778, longitude: 28.202778 },
  { name: 'LESKO', latitude: 42.928333, longitude: 22.016389 },
  { name: 'DERUX', latitude: 42.929167, longitude: 12.455278 },
  { name: 'D191J', latitude: 42.92975, longitude: 12.452394 },
  { name: 'INTIT', latitude: 42.93, longitude: 18.054444 },
  { name: 'RZ608', latitude: 42.930206, longitude: 12.571419 },
  { name: 'VP238', latitude: 42.932222, longitude: 12.6075 },
  { name: 'RZ402', latitude: 42.934861, longitude: 12.279083 },
  { name: 'TUGSO', latitude: 42.937222, longitude: 27.581389 },
  { name: 'RZ609', latitude: 42.949564, longitude: 12.289375 },
  { name: 'D223M', latitude: 42.95095, longitude: 12.299661 },
  { name: 'CD01', latitude: 42.954925, longitude: 12.469419 },
  { name: 'SF504', latitude: 42.956306, longitude: 24.115722 },
  { name: 'IBISI', latitude: 42.958056, longitude: 21.683889 },
  { name: 'SP614', latitude: 42.95925, longitude: 16.268583 },
  { name: 'CI01', latitude: 42.961497, longitude: 12.466533 },
  { name: 'D233O', latitude: 42.961511, longitude: 12.229617 },
  { name: 'NISVA', latitude: 42.972778, longitude: 22.7975 },
  { name: 'D180P', latitude: 42.973414, longitude: 27.789772 },
  { name: 'DEMED', latitude: 42.980556, longitude: 37.810556 },
  { name: 'RZ515', latitude: 42.981864, longitude: 12.473533 },
  { name: 'FD01', latitude: 42.987542, longitude: 12.478839 },
  { name: 'BANUT', latitude: 42.99, longitude: 39.983333 },
  { name: 'BUVAK', latitude: 42.990556, longitude: 27.4375 },
  { name: 'FI01', latitude: 42.993811, longitude: 12.477736 },
  { name: 'D234K', latitude: 42.997619, longitude: 12.292242 },
  { name: 'VP240', latitude: 42.998889, longitude: 12.5825 },
  { name: '4313E', latitude: 43.0, longitude: 13.0 },
  { name: '4314E', latitude: 43.0, longitude: 14.0 },
  { name: '4315E', latitude: 43.0, longitude: 15.0 },
  { name: '4316E', latitude: 43.0, longitude: 16.0 },
  { name: '4317E', latitude: 43.0, longitude: 17.0 },
  { name: '4318E', latitude: 43.0, longitude: 18.0 },
  { name: '4319E', latitude: 43.0, longitude: 19.0 },
  { name: '4320E', latitude: 43.0, longitude: 20.0 },
  { name: '4321E', latitude: 43.0, longitude: 21.0 },
  { name: '4322E', latitude: 43.0, longitude: 22.0 },
  { name: '4323E', latitude: 43.0, longitude: 23.0 },
  { name: '4324E', latitude: 43.0, longitude: 24.0 },
  { name: '4325E', latitude: 43.0, longitude: 25.0 },
  { name: '4326E', latitude: 43.0, longitude: 26.0 },
  { name: '4327E', latitude: 43.0, longitude: 27.0 },
  { name: '4328E', latitude: 43.0, longitude: 28.0 },
  { name: '4329E', latitude: 43.0, longitude: 29.0 },
  { name: '4330E', latitude: 43.0, longitude: 30.0 },
  { name: '4331E', latitude: 43.0, longitude: 31.0 },
  { name: '4332E', latitude: 43.0, longitude: 32.0 },
  { name: '4333E', latitude: 43.0, longitude: 33.0 },
  { name: '4334E', latitude: 43.0, longitude: 34.0 },
  { name: '4335E', latitude: 43.0, longitude: 35.0 },
  { name: '4336E', latitude: 43.0, longitude: 36.0 },
  { name: '4337E', latitude: 43.0, longitude: 37.0 },
  { name: '4338E', latitude: 43.0, longitude: 38.0 },
  { name: '4339E', latitude: 43.0, longitude: 39.0 },
  { name: '4340E', latitude: 43.0, longitude: 40.0 },
  { name: 'VABEK', latitude: 43.003056, longitude: 20.621111 },
  { name: '60VOR', latitude: 43.00385, longitude: 12.483553 },
  { name: 'NI905', latitude: 43.005694, longitude: 22.299222 },
  { name: 'MODRA', latitude: 43.006944, longitude: 19.588611 },
  { name: '47THR', latitude: 43.012592, longitude: 12.484211 },
  { name: 'REDVA', latitude: 43.013583, longitude: 20.585583 },
  { name: 'D160T', latitude: 43.015167, longitude: 21.970419 },
  { name: 'OBANE', latitude: 43.017017, longitude: 17.725875 },
  { name: 'TIKSA', latitude: 43.0175, longitude: 17.314444 },
  { name: 'POPPE', latitude: 43.019239, longitude: 17.862158 },
  { name: '50VOR', latitude: 43.020158, longitude: 12.488269 },
  { name: 'LIZIN', latitude: 43.021325, longitude: 18.000028 },
  { name: 'CF33X', latitude: 43.023294, longitude: 17.860653 },
  { name: 'D354O', latitude: 43.023294, longitude: 17.860653 },
  { name: 'VP242', latitude: 43.025833, longitude: 12.437222 },
  { name: 'RETKU', latitude: 43.026389, longitude: 12.234722 },
  { name: 'RZ612', latitude: 43.026575, longitude: 12.252975 },
  { name: 'ESENA', latitude: 43.026944, longitude: 26.8725 },
  { name: 'IZMOR', latitude: 43.027222, longitude: 22.257778 },
  { name: 'WN806', latitude: 43.027389, longitude: 28.227194 },
  { name: 'PENEV', latitude: 43.031667, longitude: 23.809444 },
  { name: 'KEPOL', latitude: 43.033056, longitude: 37.988333 },
  { name: 'PERAN', latitude: 43.033333, longitude: 18.666667 },
  { name: 'D160L', latitude: 43.033892, longitude: 27.894203 },
  { name: 'PITOP', latitude: 43.038889, longitude: 39.773611 },
  { name: '30THR', latitude: 43.040053, longitude: 12.493764 },
  { name: 'NETKO', latitude: 43.041667, longitude: 17.661667 },
  { name: 'OTPER', latitude: 43.043333, longitude: 25.53 },
  { name: 'WN863', latitude: 43.050306, longitude: 27.977139 },
  { name: 'RZ611', latitude: 43.050439, longitude: 12.371019 },
  { name: 'KATAR', latitude: 43.051667, longitude: 12.677778 },
  { name: 'RZ604', latitude: 43.052742, longitude: 12.498183 },
  { name: 'CF358', latitude: 43.054925, longitude: 17.841483 },
  { name: 'VP001', latitude: 43.055, longitude: 18.437778 },
  { name: 'VAPUP', latitude: 43.055833, longitude: 15.205556 },
  { name: 'CF004', latitude: 43.058842, longitude: 17.812142 },
  { name: 'D227D', latitude: 43.059086, longitude: 12.441058 },
  { name: 'MATEL', latitude: 43.059167, longitude: 28.791944 },
  { name: 'VP002', latitude: 43.063611, longitude: 17.685 },
  { name: 'NIKMA', latitude: 43.066389, longitude: 12.180278 },
  { name: 'WN805', latitude: 43.066417, longitude: 27.797806 },
  { name: 'D196M', latitude: 43.066786, longitude: 17.754031 },
  { name: 'MD01', latitude: 43.069081, longitude: 12.502433 },
  { name: 'VP003', latitude: 43.075556, longitude: 17.948611 },
  { name: 'TABAN', latitude: 43.078333, longitude: 39.601667 },
  { name: 'DIRUN', latitude: 43.086389, longitude: 38.171944 },
  { name: 'PERIP', latitude: 43.087583, longitude: 16.841972 },
  { name: 'SPL34', latitude: 43.087603, longitude: 16.841922 },
  { name: 'BEBOT', latitude: 43.089444, longitude: 37.247222 },
  { name: 'FF33X', latitude: 43.089975, longitude: 17.859069 },
  { name: 'SP712', latitude: 43.095139, longitude: 16.393028 },
  { name: 'RORKI', latitude: 43.096111, longitude: 29.143333 },
  { name: 'D180H', latitude: 43.096411, longitude: 27.802817 },
  { name: 'GONGO', latitude: 43.098056, longitude: 27.0325 },
  { name: 'FF358', latitude: 43.098211, longitude: 17.844575 },
  { name: 'D191W', latitude: 43.099228, longitude: 39.804639 },
  { name: 'FF004', latitude: 43.100008, longitude: 17.821008 },
  { name: 'D220T', latitude: 43.100539, longitude: 21.528614 },
  { name: 'D191V', latitude: 43.100814, longitude: 39.805339 },
  { name: 'NI903', latitude: 43.1015, longitude: 22.37525 },
  { name: 'NI303', latitude: 43.10175, longitude: 21.756167 },
  { name: 'MO33F', latitude: 43.102575, longitude: 17.859719 },
  { name: 'WN709', latitude: 43.103528, longitude: 28.312 },
  { name: 'WN862', latitude: 43.103806, longitude: 27.367861 },
  { name: 'SB510', latitude: 43.104972, longitude: 16.6455 },
  { name: 'IVGOT', latitude: 43.105, longitude: 28.536389 },
  { name: 'CF33Y', latitude: 43.106642, longitude: 17.858675 },
  { name: 'DUBOV', latitude: 43.108333, longitude: 21.694722 },
  { name: 'D196O', latitude: 43.108361, longitude: 21.728222 },
  { name: 'SS068', latitude: 43.110667, longitude: 39.460889 },
  { name: 'UVODI', latitude: 43.110833, longitude: 16.541944 },
  { name: 'ASLIL', latitude: 43.111386, longitude: 17.723117 },
  { name: 'WN708', latitude: 43.111944, longitude: 28.221694 },
  { name: 'CF33Z', latitude: 43.114975, longitude: 17.858478 },
  { name: 'UDVAR', latitude: 43.115833, longitude: 21.040556 },
  { name: 'SUGUL', latitude: 43.118333, longitude: 32.971667 },
  { name: 'RZ602', latitude: 43.118422, longitude: 12.350133 },
  { name: 'WN707', latitude: 43.120278, longitude: 28.131361 },
  { name: 'CD27', latitude: 43.121306, longitude: 25.933417 },
  { name: 'GO605', latitude: 43.121389, longitude: 25.436944 },
  { name: 'D272O', latitude: 43.122981, longitude: 12.171814 },
  { name: 'WN750', latitude: 43.123361, longitude: 28.0975 },
  { name: 'GODEK', latitude: 43.126667, longitude: 22.989444 },
  { name: 'RZ603', latitude: 43.127164, longitude: 12.263856 },
  { name: 'XANEX', latitude: 43.127222, longitude: 24.980278 },
  { name: 'GO383', latitude: 43.128167, longitude: 25.935056 },
  { name: 'WN706', latitude: 43.128528, longitude: 28.041 },
  { name: 'D277I', latitude: 43.128803, longitude: 12.310461 },
  { name: 'HUMZA', latitude: 43.129672, longitude: 17.830464 },
  { name: 'WN751', latitude: 43.131611, longitude: 28.007139 },
  { name: 'RZ601', latitude: 43.13335, longitude: 12.526278 },
  { name: 'VP245', latitude: 43.133889, longitude: 12.290833 },
  { name: 'D012B', latitude: 43.133897, longitude: 12.523669 },
  { name: 'DELIN', latitude: 43.134167, longitude: 25.165 },
  { name: 'D360B', latitude: 43.134989, longitude: 12.514269 },
  { name: 'SIPAL', latitude: 43.136667, longitude: 17.073611 },
  { name: 'WN705', latitude: 43.136722, longitude: 27.950611 },
  { name: 'FD27', latitude: 43.137389, longitude: 25.821694 },
  { name: 'SB902', latitude: 43.137611, longitude: 16.703028 },
  { name: 'GO387', latitude: 43.137778, longitude: 25.843861 },
  { name: 'BEKIS', latitude: 43.138056, longitude: 36.988333 },
  { name: 'NI293', latitude: 43.138111, longitude: 21.841722 },
  { name: 'WN752', latitude: 43.139778, longitude: 27.916722 },
  { name: 'FF33', latitude: 43.139978, longitude: 17.857883 },
  { name: 'NI292', latitude: 43.141944, longitude: 21.722167 },
  { name: 'TUNVO', latitude: 43.142222, longitude: 25.387778 },
  { name: 'GACMO', latitude: 43.142778, longitude: 18.558056 },
  { name: 'GO375', latitude: 43.143917, longitude: 25.785194 },
  { name: 'WN704', latitude: 43.144833, longitude: 27.860194 },
  { name: 'D120I', latitude: 43.145322, longitude: 27.990408 },
  { name: 'GO385', latitude: 43.146139, longitude: 26.02275 },
  { name: 'RZ401', latitude: 43.146639, longitude: 12.175972 },
  { name: 'MODMU', latitude: 43.146722, longitude: 15.922278 },
  { name: 'WN753', latitude: 43.147861, longitude: 27.826306 },
  { name: 'GOKIN', latitude: 43.148056, longitude: 39.296389 },
  { name: 'D244M', latitude: 43.149514, longitude: 27.542733 },
  { name: 'ROVAM', latitude: 43.151111, longitude: 23.807778 },
  { name: 'SB509', latitude: 43.152861, longitude: 16.552278 },
  { name: 'WN703', latitude: 43.152889, longitude: 27.769778 },
  { name: 'SP621', latitude: 43.153722, longitude: 15.391833 },
  { name: 'D120H', latitude: 43.155417, longitude: 27.97005 },
  { name: 'LAKOX', latitude: 43.155556, longitude: 12.108889 },
  { name: 'WN754', latitude: 43.155889, longitude: 27.735861 },
  { name: 'VP247', latitude: 43.156111, longitude: 12.195278 },
  { name: 'NI291', latitude: 43.156278, longitude: 21.723028 },
  { name: 'SS202', latitude: 43.157217, longitude: 39.7507 },
  { name: 'GO500', latitude: 43.157222, longitude: 26.04 },
  { name: 'VP248', latitude: 43.159167, longitude: 12.600556 },
  { name: 'GO386', latitude: 43.159167, longitude: 25.638139 },
  { name: 'PIROT', latitude: 43.159444, longitude: 22.588611 },
  { name: 'NUTRO', latitude: 43.16, longitude: 14.149167 },
  { name: '36VOR', latitude: 43.160406, longitude: 25.630392 },
  { name: 'VP007', latitude: 43.160833, longitude: 16.365833 },
  { name: 'WN702', latitude: 43.160861, longitude: 27.679306 },
  { name: 'GO376', latitude: 43.161028, longitude: 25.353944 },
  { name: 'CI02X', latitude: 43.161647, longitude: 39.740769 },
  { name: '38THR', latitude: 43.161806, longitude: 25.612314 },
  { name: 'CD02X', latitude: 43.162619, longitude: 39.732106 },
  { name: 'WN755', latitude: 43.163833, longitude: 27.645389 },
  { name: 'FD09', latitude: 43.164361, longitude: 25.585333 },
  { name: 'GO374', latitude: 43.165139, longitude: 25.58 },
  { name: 'GO600', latitude: 43.165917, longitude: 25.571694 },
  { name: 'ADL32', latitude: 43.167433, longitude: 39.338264 },
  { name: 'SB506', latitude: 43.1695, longitude: 16.735222 },
  { name: 'SOMIG', latitude: 43.170556, longitude: 16.073889 },
  { name: 'WN756', latitude: 43.171722, longitude: 27.554917 },
  { name: 'AMTOV', latitude: 43.1725, longitude: 26.151944 },
  { name: '60LOC', latitude: 43.173314, longitude: 17.857089 },
  { name: 'D102L', latitude: 43.174186, longitude: 28.086464 },
  { name: 'CD09', latitude: 43.174333, longitude: 25.47225 },
  { name: '52DME', latitude: 43.174789, longitude: 17.850056 },
  { name: 'D160J', latitude: 43.175989, longitude: 21.910519 },
  { name: 'D094W', latitude: 43.176478, longitude: 28.336 },
  { name: 'GO372', latitude: 43.176778, longitude: 25.465778 },
  { name: 'WN855', latitude: 43.177611, longitude: 28.418194 },
  { name: 'WN711', latitude: 43.17775, longitude: 28.416833 },
  { name: '50VOR', latitude: 43.179044, longitude: 17.838067 },
  { name: 'WN757', latitude: 43.179528, longitude: 27.464389 },
  { name: 'BODMO', latitude: 43.180833, longitude: 26.421389 },
  { name: 'USOVO', latitude: 43.181389, longitude: 28.336944 },
  { name: 'D209T', latitude: 43.181728, longitude: 39.691636 },
  { name: 'VP008', latitude: 43.184444, longitude: 16.601111 },
  { name: 'BUNEX', latitude: 43.184444, longitude: 19.288611 },
  { name: 'VP249', latitude: 43.186111, longitude: 12.451389 },
  { name: 'WN712', latitude: 43.186222, longitude: 28.326417 },
  { name: 'ALIVI', latitude: 43.187222, longitude: 26.645833 },
  { name: 'WN758', latitude: 43.18725, longitude: 27.373833 },
  { name: 'SS024', latitude: 43.188722, longitude: 39.469639 },
  { name: 'MG33', latitude: 43.188861, longitude: 17.857233 },
  { name: 'SP702', latitude: 43.190028, longitude: 16.300194 },
  { name: 'D152T', latitude: 43.1925, longitude: 16.491944 },
  { name: 'WN713', latitude: 43.194639, longitude: 28.236 },
  { name: 'WN759', latitude: 43.194917, longitude: 27.283278 },
  { name: 'D211G', latitude: 43.196444, longitude: 16.591631 },
  { name: 'FF027', latitude: 43.197142, longitude: 16.604514 },
  { name: 'FF03', latitude: 43.197142, longitude: 16.604514 },
  { name: 'SB903', latitude: 43.19775, longitude: 16.760861 },
  { name: 'EKSOL', latitude: 43.198889, longitude: 26.940278 },
  { name: 'NI112', latitude: 43.199083, longitude: 21.923278 },
  { name: 'TITPI', latitude: 43.203056, longitude: 28.145556 },
  { name: 'SS030', latitude: 43.204069, longitude: 39.901444 },
  { name: 'GO610', latitude: 43.204556, longitude: 25.444444 },
  { name: 'TOTKA', latitude: 43.205556, longitude: 27.116667 },
  { name: 'ADL28', latitude: 43.205842, longitude: 39.420975 },
  { name: 'ML33', latitude: 43.20665, longitude: 17.856294 },
  { name: 'GOTMO', latitude: 43.207222, longitude: 12.906389 },
  { name: 'DAFRO', latitude: 43.207472, longitude: 16.604583 },
  { name: 'D260M', latitude: 43.207994, longitude: 27.522519 },
  { name: 'SPL32', latitude: 43.209083, longitude: 16.920472 },
  { name: 'D263Z', latitude: 43.209856, longitude: 27.227089 },
  { name: 'GO382', latitude: 43.210694, longitude: 25.951333 },
  { name: 'RIGBI', latitude: 43.211389, longitude: 28.055 },
  { name: 'CD27', latitude: 43.212472, longitude: 28.05525 },
  { name: 'OGOTA', latitude: 43.214167, longitude: 23.326667 },
  { name: 'D131J', latitude: 43.216889, longitude: 22.008833 },
  { name: 'SB901', latitude: 43.217861, longitude: 16.614528 },
  { name: 'BEPAL', latitude: 43.218889, longitude: 22.311944 },
  { name: 'D191O', latitude: 43.219783, longitude: 39.857861 },
  { name: 'D263N', latitude: 43.22005, longitude: 27.500653 },
  { name: 'FD27', latitude: 43.221722, longitude: 27.937361 },
  { name: 'WN716', latitude: 43.221917, longitude: 27.937389 },
  { name: 'D141T', latitude: 43.222222, longitude: 16.564444 },
  { name: 'GO381', latitude: 43.222306, longitude: 25.840833 },
  { name: 'MEKIN', latitude: 43.223611, longitude: 21.794167 },
  { name: 'SB804', latitude: 43.224389, longitude: 16.888 },
  { name: 'D263H', latitude: 43.224733, longitude: 27.632717 },
  { name: '33VOR', latitude: 43.22525, longitude: 27.892 },
  { name: 'WN771', latitude: 43.225417, longitude: 27.8985 },
  { name: '23THR', latitude: 43.225928, longitude: 27.892706 },
  { name: 'NI105', latitude: 43.227528, longitude: 21.986917 },
  { name: 'WN860', latitude: 43.227917, longitude: 27.870611 },
  { name: 'SP620', latitude: 43.228722, longitude: 16.035778 },
  { name: 'SS201', latitude: 43.229281, longitude: 39.887517 },
  { name: 'SS023', latitude: 43.229333, longitude: 39.644111 },
  { name: 'ADL30', latitude: 43.229642, longitude: 39.362872 },
  { name: 'SP622', latitude: 43.230222, longitude: 15.810167 },
  { name: 'PROKU', latitude: 43.230278, longitude: 21.582778 },
  { name: 'DER09', latitude: 43.230578, longitude: 27.840661 },
  { name: 'SS247', latitude: 43.231172, longitude: 39.613444 },
  { name: 'MD09', latitude: 43.234028, longitude: 27.787806 },
  { name: 'GO380', latitude: 43.234111, longitude: 25.727639 },
  { name: 'VP004', latitude: 43.234444, longitude: 17.705556 },
  { name: 'ML09', latitude: 43.2345, longitude: 27.796428 },
  { name: 'D268H', latitude: 43.235289, longitude: 27.644911 },
  { name: 'MA358', latitude: 43.236386, longitude: 17.854472 },
  { name: 'SB408', latitude: 43.236639, longitude: 16.788722 },
  { name: '25VOR', latitude: 43.236806, longitude: 27.760694 },
  { name: 'SP701', latitude: 43.237472, longitude: 16.154361 },
  { name: 'SB511', latitude: 43.238306, longitude: 16.773556 },
  { name: 'WN765', latitude: 43.238694, longitude: 27.749028 },
  { name: '35VOR', latitude: 43.239111, longitude: 27.738111 },
  { name: '30THR', latitude: 43.239319, longitude: 27.742 },
  { name: '30LOC', latitude: 43.239328, longitude: 27.741878 },
  { name: 'YUYUH', latitude: 43.239722, longitude: 19.638333 },
  { name: 'LOPKU', latitude: 43.241389, longitude: 18.4975 },
  { name: 'SP503', latitude: 43.242833, longitude: 16.400694 },
  { name: 'REBKO', latitude: 43.243056, longitude: 34.161944 },
  { name: 'SB407', latitude: 43.243361, longitude: 16.638972 },
  { name: 'CD02Y', latitude: 43.243575, longitude: 39.795647 },
  { name: 'CI02Y', latitude: 43.243625, longitude: 39.801819 },
  { name: 'FI09', latitude: 43.243639, longitude: 27.692917 },
  { name: 'WN764', latitude: 43.243639, longitude: 27.692917 },
  { name: 'FD09', latitude: 43.243722, longitude: 27.692917 },
  { name: 'GO370', latitude: 43.245, longitude: 25.594583 },
  { name: 'D327J', latitude: 43.246006, longitude: 12.397867 },
  { name: 'MOM01', latitude: 43.246144, longitude: 17.855508 },
  { name: 'AZBUX', latitude: 43.247778, longitude: 21.640556 },
  { name: 'SS066', latitude: 43.249528, longitude: 39.710833 },
  { name: 'MA004', latitude: 43.249844, longitude: 17.853386 },
  { name: 'SOBLO', latitude: 43.25, longitude: 36.383333 },
  { name: 'KUTAK', latitude: 43.250278, longitude: 18.543611 },
  { name: 'UTENO', latitude: 43.250833, longitude: 13.682222 },
  { name: 'INKOM', latitude: 43.253333, longitude: 30.64 },
  { name: 'VP005', latitude: 43.253611, longitude: 17.923611 },
  { name: 'EVRAB', latitude: 43.2545, longitude: 27.568333 },
  { name: 'D231V', latitude: 43.255775, longitude: 39.52885 },
  { name: 'CF09', latitude: 43.256278, longitude: 27.568639 },
  { name: 'CD09', latitude: 43.256278, longitude: 27.568722 },
  { name: 'D354A', latitude: 43.256653, longitude: 17.855103 },
  { name: 'D053C', latitude: 43.256742, longitude: 27.870647 },
  { name: 'SB803', latitude: 43.25725, longitude: 16.818167 },
  { name: 'D316B', latitude: 43.258583, longitude: 27.785469 },
  { name: 'GO371', latitude: 43.259361, longitude: 25.481444 },
  { name: 'WN737', latitude: 43.260444, longitude: 28.431389 },
  { name: 'BARIT', latitude: 43.261111, longitude: 17.855833 },
  { name: 'EVUGA', latitude: 43.261472, longitude: 16.341694 },
  { name: 'EMPED', latitude: 43.262222, longitude: 27.477778 },
  { name: 'UNAMI', latitude: 43.263056, longitude: 38.78 },
  { name: 'SS067', latitude: 43.26325, longitude: 39.411944 },
  { name: 'NI201', latitude: 43.267028, longitude: 22.167917 },
  { name: 'WN761', latitude: 43.270083, longitude: 27.387 },
  { name: 'SS022', latitude: 43.270361, longitude: 39.82175 },
  { name: 'WN738', latitude: 43.271056, longitude: 28.31825 },
  { name: 'IVMEP', latitude: 43.272778, longitude: 13.368056 },
  { name: 'DER15', latitude: 43.272967, longitude: 17.851436 },
  { name: 'NI901', latitude: 43.273972, longitude: 22.252639 },
  { name: 'NI200', latitude: 43.274333, longitude: 22.102694 },
  { name: 'SS069', latitude: 43.275306, longitude: 39.880517 },
  { name: 'WN811', latitude: 43.27625, longitude: 27.314083 },
  { name: 'WN760', latitude: 43.27775, longitude: 27.296333 },
  { name: 'FD29', latitude: 43.279778, longitude: 22.062417 },
  { name: 'D238Y', latitude: 43.280031, longitude: 39.453406 },
  { name: 'CF29', latitude: 43.280094, longitude: 22.073619 },
  { name: 'D232T', latitude: 43.280358, longitude: 39.567042 },
  { name: 'NUPVU', latitude: 43.282222, longitude: 27.379444 },
  { name: 'NI29F', latitude: 43.28325, longitude: 22.023083 },
  { name: 'LUKOV', latitude: 43.284722, longitude: 23.806111 },
  { name: 'WN789', latitude: 43.285361, longitude: 27.205611 },
  { name: 'LAHNE', latitude: 43.286003, longitude: 17.700056 },
  { name: 'VP009', latitude: 43.286667, longitude: 16.873611 },
  { name: 'URITA', latitude: 43.286667, longitude: 34.396667 },
  { name: 'D223P', latitude: 43.288203, longitude: 39.688017 },
  { name: 'SB507', latitude: 43.289667, longitude: 16.851028 },
  { name: 'NIBAN', latitude: 43.291389, longitude: 22.003889 },
  { name: 'GULDE', latitude: 43.292111, longitude: 22.030861 },
  { name: '61TH2', latitude: 43.292628, longitude: 21.993764 },
  { name: 'NEPSI', latitude: 43.293056, longitude: 18.761111 },
  { name: 'LAGIR', latitude: 43.293333, longitude: 32.315 },
  { name: 'MA124', latitude: 43.293472, longitude: 16.667278 },
  { name: 'UMDOX', latitude: 43.293889, longitude: 28.069167 },
  { name: '74VOR', latitude: 43.294194, longitude: 22.008917 },
  { name: 'ERDON', latitude: 43.295, longitude: 13.036667 },
  { name: 'D225P', latitude: 43.295286, longitude: 39.680211 },
  { name: 'NI115', latitude: 43.295361, longitude: 22.201111 },
  { name: 'VP250', latitude: 43.297222, longitude: 13.45 },
  { name: 'SEZAN', latitude: 43.297961, longitude: 17.623867 },
  { name: 'SP708', latitude: 43.297972, longitude: 16.685389 },
  { name: 'RILNO', latitude: 43.3, longitude: 16.355944 },
  { name: 'D187L', latitude: 43.300206, longitude: 16.252461 },
  { name: '62LOC', latitude: 43.300611, longitude: 21.996417 },
  { name: 'CD06X', latitude: 43.300858, longitude: 39.551308 },
  { name: 'SS203', latitude: 43.301833, longitude: 39.672253 },
  { name: 'GOTRI', latitude: 43.30325, longitude: 16.139278 },
  { name: 'ZALOM', latitude: 43.305167, longitude: 22.118611 },
  { name: 'SB802', latitude: 43.306083, longitude: 16.865333 },
  { name: 'BAJUN', latitude: 43.307083, longitude: 21.2955 },
  { name: 'FN02', latitude: 43.307367, longitude: 39.844283 },
  { name: 'VP252', latitude: 43.3075, longitude: 12.3325 },
  { name: 'WN810', latitude: 43.307611, longitude: 27.905444 },
  { name: 'GORAV', latitude: 43.308056, longitude: 19.077778 },
  { name: 'PIRIL', latitude: 43.308056, longitude: 38.571667 },
  { name: 'GINAM', latitude: 43.308611, longitude: 21.376389 },
  { name: 'SS063', latitude: 43.309306, longitude: 39.54575 },
  { name: 'CI06X', latitude: 43.309758, longitude: 39.549392 },
  { name: 'XAMIT', latitude: 43.311667, longitude: 14.797778 },
  { name: 'KEMIX', latitude: 43.311778, longitude: 15.924139 },
  { name: 'D286N', latitude: 43.312736, longitude: 27.510694 },
  { name: 'OKROV', latitude: 43.313361, longitude: 15.698083 },
  { name: 'OGRIZ', latitude: 43.31375, longitude: 22.074167 },
  { name: 'SP606', latitude: 43.313861, longitude: 16.367139 },
  { name: 'VP253', latitude: 43.313889, longitude: 13.730556 },
  { name: '26THR', latitude: 43.316, longitude: 21.920564 },
  { name: '36VO2', latitude: 43.316083, longitude: 21.9275 },
  { name: 'D210M', latitude: 43.3168, longitude: 16.138756 },
  { name: 'D118T', latitude: 43.317278, longitude: 16.69025 },
  { name: '24LOC', latitude: 43.320806, longitude: 21.914194 },
  { name: 'LAPMI', latitude: 43.322222, longitude: 38.123056 },
  { name: 'MD29', latitude: 43.325306, longitude: 21.893194 },
  { name: 'NI29M', latitude: 43.326, longitude: 21.889167 },
  { name: 'MA29', latitude: 43.326667, longitude: 21.890361 },
  { name: 'RESLA', latitude: 43.328333, longitude: 23.312778 },
  { name: 'SP607', latitude: 43.328944, longitude: 16.709111 },
  { name: 'DER11', latitude: 43.332253, longitude: 21.869547 },
  { name: 'FTP29', latitude: 43.332667, longitude: 21.865844 },
  { name: 'NI119', latitude: 43.33275, longitude: 21.998194 },
  { name: 'LAPVO', latitude: 43.333333, longitude: 13.875833 },
  { name: 'NEDSI', latitude: 43.333611, longitude: 28.556389 },
  { name: 'D316H', latitude: 43.334336, longitude: 27.698181 },
  { name: 'SAJLO', latitude: 43.334722, longitude: 16.726889 },
  { name: 'NI296', latitude: 43.336194, longitude: 21.851444 },
  { name: 'SB508', latitude: 43.336417, longitude: 16.728528 },
  { name: 'IDEXI', latitude: 43.337222, longitude: 27.581667 },
  { name: 'D277A', latitude: 43.337278, longitude: 21.847056 },
  { name: 'SP802', latitude: 43.339167, longitude: 16.203528 },
  { name: 'KUNOV', latitude: 43.340278, longitude: 22.086111 },
  { name: 'CD06Y', latitude: 43.340544, longitude: 39.656861 },
  { name: 'SS021', latitude: 43.341019, longitude: 39.874575 },
  { name: 'DER29', latitude: 43.341242, longitude: 21.841286 },
  { name: 'FD02', latitude: 43.341831, longitude: 39.873033 },
  { name: 'SS028', latitude: 43.341917, longitude: 39.875267 },
  { name: 'D154J', latitude: 43.342222, longitude: 16.391389 },
  { name: 'LASUL', latitude: 43.343056, longitude: 16.215472 },
  { name: 'D244T', latitude: 43.344008, longitude: 39.525864 },
  { name: 'GERBU', latitude: 43.344044, longitude: 13.623878 },
  { name: 'FF02', latitude: 43.344586, longitude: 39.877267 },
  { name: 'MD11', latitude: 43.344611, longitude: 21.830167 },
  { name: 'FQ21', latitude: 43.344633, longitude: 16.744517 },
  { name: 'NI295', latitude: 43.346472, longitude: 21.827056 },
  { name: 'SB409', latitude: 43.346778, longitude: 16.880472 },
  { name: 'CI06Y', latitude: 43.346911, longitude: 39.656672 },
  { name: 'PY656', latitude: 43.348289, longitude: 13.550542 },
  { name: 'NI302', latitude: 43.348389, longitude: 21.819083 },
  { name: 'NILKU', latitude: 43.348611, longitude: 18.698611 },
  { name: 'SB801', latitude: 43.348833, longitude: 16.7405 },
  { name: 'LAMET', latitude: 43.349722, longitude: 39.088611 },
  { name: 'VELIT', latitude: 43.351667, longitude: 17.277222 },
  { name: 'WN786', latitude: 43.352889, longitude: 27.400222 },
  { name: 'BAMRO', latitude: 43.353333, longitude: 16.613333 },
  { name: 'WN739', latitude: 43.35375, longitude: 28.332722 },
  { name: 'D123N', latitude: 43.354444, longitude: 16.558889 },
  { name: 'PY652', latitude: 43.354528, longitude: 13.441228 },
  { name: 'SS026', latitude: 43.354639, longitude: 39.205444 },
  { name: 'SS062', latitude: 43.354994, longitude: 39.679886 },
  { name: 'GENLU', latitude: 43.355556, longitude: 19.443333 },
  { name: 'VP006', latitude: 43.356111, longitude: 17.811944 },
  { name: 'ODAGO', latitude: 43.356389, longitude: 39.997222 },
  { name: 'ETIMA', latitude: 43.356389, longitude: 39.997222 },
  { name: 'AD14A', latitude: 43.356542, longitude: 39.675353 },
  { name: 'WN787', latitude: 43.360583, longitude: 27.309417 },
  { name: 'WN785', latitude: 43.361694, longitude: 27.493667 },
  { name: '33THR', latitude: 43.3624, longitude: 21.774639 },
  { name: 'D233Q', latitude: 43.362972, longitude: 15.967028 },
  { name: 'FF124', latitude: 43.364694, longitude: 16.540667 },
  { name: '36VOR', latitude: 43.364833, longitude: 21.778167 },
  { name: 'NITUX', latitude: 43.365833, longitude: 29.929722 },
  { name: 'FN06', latitude: 43.366453, longitude: 39.742883 },
  { name: 'D272O', latitude: 43.366647, longitude: 21.510106 },
  { name: 'DIGRA', latitude: 43.368056, longitude: 38.3 },
  { name: 'D223K', latitude: 43.371389, longitude: 16.120889 },
  { name: 'DOLAP', latitude: 43.374167, longitude: 22.768333 },
  { name: 'OSGOL', latitude: 43.374694, longitude: 16.05875 },
  { name: 'SS241', latitude: 43.376028, longitude: 39.457694 },
  { name: 'SB410', latitude: 43.377, longitude: 16.767667 },
  { name: 'D244N', latitude: 43.378697, longitude: 39.661975 },
  { name: 'USIBI', latitude: 43.379167, longitude: 39.988889 },
  { name: '55VOR', latitude: 43.379556, longitude: 21.740194 },
  { name: 'NI902', latitude: 43.380472, longitude: 22.308167 },
  { name: '61THR', latitude: 43.380989, longitude: 21.71595 },
  { name: 'SP617', latitude: 43.381833, longitude: 15.358444 },
  { name: 'VP010', latitude: 43.3825, longitude: 16.551389 },
  { name: 'ERLIT', latitude: 43.383725, longitude: 13.167303 },
  { name: 'ABMIN', latitude: 43.385278, longitude: 39.993611 },
  { name: 'IDNUM', latitude: 43.385389, longitude: 16.066167 },
  { name: 'FF11', latitude: 43.386478, longitude: 21.73255 },
  { name: 'SP707', latitude: 43.387028, longitude: 16.723 },
  { name: 'NIDEP', latitude: 43.387606, longitude: 39.625539 },
  { name: 'D230K', latitude: 43.388611, longitude: 16.101306 },
  { name: 'SS246', latitude: 43.388667, longitude: 39.802056 },
  { name: 'GOGAR', latitude: 43.388889, longitude: 23.921111 },
  { name: 'D205G', latitude: 43.389528, longitude: 16.222361 },
  { name: 'D187L', latitude: 43.390072, longitude: 13.418772 },
  { name: 'VP011', latitude: 43.390278, longitude: 16.290833 },
  { name: 'T10', latitude: 43.390556, longitude: 39.948056 },
  { name: 'FD06', latitude: 43.391533, longitude: 39.793064 },
  { name: 'BOBVO', latitude: 43.392222, longitude: 23.091111 },
  { name: 'MJ02', latitude: 43.392647, longitude: 39.913283 },
  { name: 'NI29B', latitude: 43.393006, longitude: 21.201375 },
  { name: 'MI02', latitude: 43.393144, longitude: 39.913658 },
  { name: 'SS061', latitude: 43.393931, longitude: 39.792956 },
  { name: 'NI117', latitude: 43.393944, longitude: 22.04 },
  { name: 'FF06', latitude: 43.394206, longitude: 39.793853 },
  { name: 'SS071', latitude: 43.394439, longitude: 39.794544 },
  { name: 'SP619', latitude: 43.394889, longitude: 15.8125 },
  { name: 'SP401', latitude: 43.39575, longitude: 16.041778 },
  { name: 'VP012', latitude: 43.396111, longitude: 16.053333 },
  { name: 'D141G', latitude: 43.396131, longitude: 16.401139 },
  { name: 'ELRUR', latitude: 43.396389, longitude: 27.193611 },
  { name: '76VOR', latitude: 43.396417, longitude: 21.696722 },
  { name: 'WN864', latitude: 43.399417, longitude: 27.831111 },
  { name: 'D205F', latitude: 43.4005, longitude: 16.230806 },
  { name: 'D254T', latitude: 43.400886, longitude: 39.505983 },
  { name: 'BESLI', latitude: 43.402222, longitude: 24.034167 },
  { name: 'ML02', latitude: 43.402653, longitude: 39.920792 },
  { name: 'NI904', latitude: 43.40375, longitude: 22.17475 },
  { name: 'MD02', latitude: 43.403936, longitude: 39.9221 },
  { name: 'D152F', latitude: 43.404014, longitude: 16.361239 },
  { name: 'SP502', latitude: 43.404444, longitude: 16.597639 },
  { name: 'SS020', latitude: 43.404778, longitude: 39.922389 },
  { name: 'SP703', latitude: 43.404861, longitude: 16.425278 },
  { name: 'D224P', latitude: 43.406286, longitude: 13.200956 },
  { name: 'T12', latitude: 43.406389, longitude: 39.93 },
  { name: 'NI11F', latitude: 43.406861, longitude: 21.634056 },
  { name: 'SP801', latitude: 43.408389, longitude: 16.327472 },
  { name: 'TODSI', latitude: 43.41, longitude: 38.105 },
  { name: 'MASEG', latitude: 43.412894, longitude: 13.749328 },
  { name: 'D115D', latitude: 43.414378, longitude: 16.223631 },
  { name: 'D208O', latitude: 43.415156, longitude: 13.1937 },
  { name: 'CF05', latitude: 43.416306, longitude: 16.078417 },
  { name: 'D205E', latitude: 43.417167, longitude: 16.243611 },
  { name: 'T13', latitude: 43.4175, longitude: 39.915 },
  { name: 'D224O', latitude: 43.417567, longitude: 13.217794 },
  { name: 'BOCKO', latitude: 43.417889, longitude: 21.641194 },
  { name: 'RAMAP', latitude: 43.418333, longitude: 20.891111 },
  { name: 'FF019', latitude: 43.419833, longitude: 16.260028 },
  { name: 'ADVER', latitude: 43.422222, longitude: 22.986667 },
  { name: 'T11', latitude: 43.422222, longitude: 39.911944 },
  { name: 'UNUMU', latitude: 43.422778, longitude: 12.719722 },
  { name: 'MJ06', latitude: 43.425136, longitude: 39.883956 },
  { name: 'D208J', latitude: 43.425169, longitude: 13.199556 },
  { name: 'MI06', latitude: 43.425311, longitude: 39.884461 },
  { name: 'D277Z', latitude: 43.425747, longitude: 21.266403 },
  { name: 'SS060', latitude: 43.429028, longitude: 39.895333 },
  { name: 'MD06', latitude: 43.429478, longitude: 39.894856 },
  { name: 'ML06', latitude: 43.429708, longitude: 39.897294 },
  { name: 'NI101', latitude: 43.43, longitude: 21.560556 },
  { name: 'EGNOF', latitude: 43.43, longitude: 22.168333 },
  { name: 'D236C', latitude: 43.431708, longitude: 39.903972 },
  { name: 'DER20', latitude: 43.434325, longitude: 39.944572 },
  { name: 'D235B', latitude: 43.436342, longitude: 39.917006 },
  { name: 'D250M', latitude: 43.4365, longitude: 16.018861 },
  { name: 'WN809', latitude: 43.438389, longitude: 27.540278 },
  { name: 'ERITU', latitude: 43.439167, longitude: 14.126111 },
  { name: 'DER24', latitude: 43.439461, longitude: 39.925775 },
  { name: 'PY653', latitude: 43.440439, longitude: 13.572297 },
  { name: 'VP013', latitude: 43.441944, longitude: 16.696944 },
  { name: 'WN784', latitude: 43.4445, longitude: 27.507056 },
  { name: 'NURAT', latitude: 43.444667, longitude: 16.338778 },
  { name: 'T2', latitude: 43.445, longitude: 39.992778 },
  { name: 'T9', latitude: 43.446111, longitude: 39.903333 },
  { name: 'DEXIS', latitude: 43.446583, longitude: 16.1325 },
  { name: 'FI05', latitude: 43.446583, longitude: 16.1325 },
  { name: 'VP256', latitude: 43.447222, longitude: 13.013889 },
  { name: 'T8', latitude: 43.450278, longitude: 39.914167 },
  { name: 'T14', latitude: 43.450278, longitude: 39.918333 },
  { name: 'D097N', latitude: 43.450739, longitude: 16.618928 },
  { name: 'LUZAN', latitude: 43.451389, longitude: 21.743333 },
  { name: 'NI906', latitude: 43.453111, longitude: 21.888278 },
  { name: 'ARKEM', latitude: 43.453333, longitude: 13.480556 },
  { name: 'UZDAH', latitude: 43.454028, longitude: 21.547472 },
  { name: 'D097M', latitude: 43.454042, longitude: 16.5965 },
  { name: 'T5', latitude: 43.454722, longitude: 39.925556 },
  { name: 'T6', latitude: 43.454722, longitude: 39.9775 },
  { name: 'NI102', latitude: 43.455833, longitude: 21.47825 },
  { name: 'D265D', latitude: 43.456942, longitude: 39.863325 },
  { name: 'SP800', latitude: 43.459, longitude: 16.418417 },
  { name: 'T1', latitude: 43.459444, longitude: 39.968056 },
  { name: 'D265I', latitude: 43.459619, longitude: 39.752181 },
  { name: 'WN782', latitude: 43.459972, longitude: 27.325167 },
  { name: 'T7', latitude: 43.463611, longitude: 39.950833 },
  { name: 'INBOL', latitude: 43.470833, longitude: 23.803889 },
  { name: 'NI294', latitude: 43.472306, longitude: 21.753472 },
  { name: 'ROMOK', latitude: 43.473333, longitude: 31.728333 },
  { name: 'REMSO', latitude: 43.473611, longitude: 18.9575 },
  { name: 'EXIGA', latitude: 43.475833, longitude: 26.886944 },
  { name: 'OSLUD', latitude: 43.477222, longitude: 18.787222 },
  { name: 'LURAS', latitude: 43.478056, longitude: 37.785 },
  { name: 'PY638', latitude: 43.478786, longitude: 13.392631 },
  { name: 'HUM16', latitude: 43.479506, longitude: 16.431833 },
  { name: 'T4', latitude: 43.479722, longitude: 39.943333 },
  { name: 'D228E', latitude: 43.481664, longitude: 16.198561 },
  { name: 'KV315', latitude: 43.481667, longitude: 21.084583 },
  { name: 'SILAG', latitude: 43.481861, longitude: 21.486222 },
  { name: 'VP257', latitude: 43.483333, longitude: 13.180556 },
  { name: 'VP258', latitude: 43.483333, longitude: 13.480556 },
  { name: 'UTAME', latitude: 43.483889, longitude: 14.232778 },
  { name: 'D286D', latitude: 43.483958, longitude: 39.868697 },
  { name: 'VITAN', latitude: 43.484167, longitude: 21.275556 },
  { name: 'OBLAK', latitude: 43.486944, longitude: 22.363889 },
  { name: 'D241N', latitude: 43.487447, longitude: 13.180617 },
  { name: 'IRBUL', latitude: 43.488194, longitude: 15.944 },
  { name: 'RORKA', latitude: 43.488333, longitude: 16.391944 },
  { name: 'SOSTA', latitude: 43.489722, longitude: 39.845278 },
  { name: 'D293T', latitude: 43.492903, longitude: 21.446208 },
  { name: 'RINUN', latitude: 43.493611, longitude: 39.989167 },
  { name: 'DIXUM', latitude: 43.495833, longitude: 17.199444 },
  { name: 'ORVAT', latitude: 43.496667, longitude: 17.215556 },
  { name: 'SB601', latitude: 43.496722, longitude: 16.569611 },
  { name: 'OM05', latitude: 43.496847, longitude: 16.222517 },
  { name: 'NI106', latitude: 43.499278, longitude: 21.616222 },
  { name: 'SP402', latitude: 43.50325, longitude: 16.233861 },
  { name: 'SS243', latitude: 43.503472, longitude: 39.429278 },
  { name: 'TONAC', latitude: 43.50425, longitude: 21.595611 },
  { name: 'D288Z', latitude: 43.505456, longitude: 21.300092 },
  { name: 'NI103', latitude: 43.505722, longitude: 21.318667 },
  { name: 'LODBI', latitude: 43.511389, longitude: 37.623333 },
  { name: 'SS242', latitude: 43.512361, longitude: 39.557139 },
  { name: 'AMOLU', latitude: 43.513056, longitude: 15.916111 },
  { name: 'ERDOM', latitude: 43.5175, longitude: 23.289167 },
  { name: 'UMSON', latitude: 43.519167, longitude: 15.7675 },
  { name: 'PY651', latitude: 43.519194, longitude: 13.224011 },
  { name: 'KV311', latitude: 43.519417, longitude: 20.879222 },
  { name: 'ROGOV', latitude: 43.520278, longitude: 15.733056 },
  { name: 'D293X', latitude: 43.523181, longitude: 21.367167 },
  { name: 'D299T', latitude: 43.525481, longitude: 21.473169 },
  { name: 'D251N', latitude: 43.525622, longitude: 13.161311 },
  { name: 'D130Y', latitude: 43.525833, longitude: 20.989422 },
  { name: 'MM05', latitude: 43.527258, longitude: 16.276969 },
  { name: 'SS244', latitude: 43.527806, longitude: 39.784667 },
  { name: 'PY654', latitude: 43.527897, longitude: 13.706353 },
  { name: 'GOSLO', latitude: 43.528611, longitude: 28.441944 },
  { name: 'NI104', latitude: 43.529, longitude: 21.521556 },
  { name: 'PODOB', latitude: 43.531667, longitude: 19.144444 },
  { name: 'SP504', latitude: 43.535278, longitude: 16.40275 },
  { name: 'PY637', latitude: 43.537022, longitude: 13.53155 },
  { name: 'SP706', latitude: 43.537472, longitude: 16.726722 },
  { name: 'SS245', latitude: 43.538528, longitude: 39.962667 },
  { name: 'CLODY', latitude: 43.540278, longitude: 21.322694 },
  { name: 'D229A', latitude: 43.541611, longitude: 16.30275 },
  { name: 'SP403', latitude: 43.541611, longitude: 16.30275 },
  { name: 'VP261', latitude: 43.541667, longitude: 12.05 },
  { name: 'EREDO', latitude: 43.543056, longitude: 13.249731 },
  { name: 'D213C', latitude: 43.546497, longitude: 13.429733 },
  { name: 'ELGUS', latitude: 43.547778, longitude: 14.966667 },
  { name: 'D278V', latitude: 43.550039, longitude: 39.466231 },
  { name: 'D259Q', latitude: 43.551253, longitude: 13.084394 },
  { name: 'SORTI', latitude: 43.551389, longitude: 39.670278 },
  { name: 'ATBOG', latitude: 43.555833, longitude: 26.931111 },
  { name: 'VP262', latitude: 43.556111, longitude: 13.633333 },
  { name: 'SP505', latitude: 43.55625, longitude: 16.367194 },
  { name: 'NI118', latitude: 43.556389, longitude: 21.875639 },
  { name: 'PROZR', latitude: 43.558056, longitude: 39.710278 },
  { name: 'PEVON', latitude: 43.558611, longitude: 17.04 },
  { name: 'SP506', latitude: 43.559444, longitude: 16.334806 },
  { name: 'GLOMY', latitude: 43.56, longitude: 17.663611 },
  { name: 'OBAGI', latitude: 43.560556, longitude: 22.095833 },
  { name: 'SP901', latitude: 43.562361, longitude: 16.023722 },
  { name: 'D069N', latitude: 43.562642, longitude: 16.61235 },
  { name: 'SP709', latitude: 43.563083, longitude: 15.623333 },
  { name: 'KV411', latitude: 43.563583, longitude: 20.690222 },
  { name: 'VP263', latitude: 43.563889, longitude: 12.833333 },
  { name: 'TORPO', latitude: 43.564167, longitude: 14.424722 },
  { name: 'SS205', latitude: 43.566583, longitude: 39.949167 },
  { name: 'MAPUT', latitude: 43.566667, longitude: 34.396667 },
  { name: 'MOGPU', latitude: 43.566944, longitude: 12.358056 },
  { name: 'D069O', latitude: 43.567328, longitude: 16.634347 },
  { name: 'KV203', latitude: 43.569028, longitude: 20.809222 },
  { name: 'D283O', latitude: 43.569036, longitude: 15.974825 },
  { name: 'LISKO', latitude: 43.571389, longitude: 14.132222 },
  { name: 'PY702', latitude: 43.571681, longitude: 13.301094 },
  { name: 'SP805', latitude: 43.573833, longitude: 16.470556 },
  { name: 'VP014', latitude: 43.578056, longitude: 16.215556 },
  { name: 'GLOBO', latitude: 43.578056, longitude: 21.143889 },
  { name: 'D340O', latitude: 43.578267, longitude: 21.761289 },
  { name: 'KEGED', latitude: 43.578819, longitude: 13.837847 },
  { name: 'D290M', latitude: 43.584394, longitude: 16.032386 },
  { name: 'SP704', latitude: 43.5855, longitude: 16.242278 },
  { name: 'ABRIG', latitude: 43.586111, longitude: 40.044444 },
  { name: 'ZDA42', latitude: 43.586133, longitude: 16.038058 },
  { name: 'ZD767', latitude: 43.587, longitude: 16.157139 },
  { name: 'RUTEP', latitude: 43.588056, longitude: 38.51 },
  { name: 'VP015', latitude: 43.588333, longitude: 15.924444 },
  { name: 'PY209', latitude: 43.589556, longitude: 13.737017 },
  { name: 'KISAL', latitude: 43.589722, longitude: 40.011944 },
  { name: 'KV312', latitude: 43.593028, longitude: 20.567278 },
  { name: 'RUTEV', latitude: 43.594444, longitude: 21.627778 },
  { name: 'XELMI', latitude: 43.596944, longitude: 18.150278 },
  { name: 'KOGUL', latitude: 43.6, longitude: 39.174444 },
  { name: 'D053L', latitude: 43.604444, longitude: 16.536331 },
  { name: 'MAXOV', latitude: 43.609167, longitude: 27.838611 },
  { name: 'PY657', latitude: 43.609314, longitude: 13.127747 },
  { name: 'KV302', latitude: 43.613333, longitude: 20.926694 },
  { name: 'VP265', latitude: 43.614444, longitude: 13.146667 },
  { name: 'D130R', latitude: 43.6146, longitude: 20.868344 },
  { name: 'ABRAN', latitude: 43.617222, longitude: 33.122222 },
  { name: 'PY634', latitude: 43.617486, longitude: 13.635431 },
  { name: 'TRILJ', latitude: 43.6175, longitude: 16.728889 },
  { name: 'SP610', latitude: 43.619639, longitude: 15.54775 },
  { name: 'VP266', latitude: 43.621389, longitude: 13.500278 },
  { name: 'D330S', latitude: 43.622167, longitude: 21.667028 },
  { name: 'D039C', latitude: 43.623006, longitude: 13.518058 },
  { name: 'REDMA', latitude: 43.623056, longitude: 37.08 },
  { name: 'PAVKE', latitude: 43.625, longitude: 21.099722 },
  { name: 'DIPVO', latitude: 43.626389, longitude: 12.208056 },
  { name: 'UPAMA', latitude: 43.626389, longitude: 25.418333 },
  { name: 'MM22', latitude: 43.631528, longitude: 13.382806 },
  { name: 'D134O', latitude: 43.632294, longitude: 20.810286 },
  { name: 'PY633', latitude: 43.632642, longitude: 13.5555 },
  { name: 'ABDES', latitude: 43.633094, longitude: 39.49105 },
  { name: 'KENEM', latitude: 43.633333, longitude: 16.946667 },
  { name: 'MQ22', latitude: 43.634194, longitude: 13.387717 },
  { name: 'SP804', latitude: 43.635889, longitude: 16.350917 },
  { name: 'KV303', latitude: 43.637833, longitude: 21.153778 },
  { name: 'VP016', latitude: 43.638333, longitude: 16.608611 },
  { name: 'SP803', latitude: 43.639861, longitude: 16.304444 },
  { name: 'VRNCI', latitude: 43.6425, longitude: 20.912778 },
  { name: 'MRZEN', latitude: 43.645833, longitude: 21.383611 },
  { name: 'KV301', latitude: 43.647806, longitude: 20.797389 },
  { name: 'RUDAR', latitude: 43.648611, longitude: 18.489167 },
  { name: 'VP007', latitude: 43.650556, longitude: 17.956944 },
  { name: 'PY631', latitude: 43.661408, longitude: 13.424181 },
  { name: 'KV304', latitude: 43.661917, longitude: 21.381028 },
  { name: 'BIDMA', latitude: 43.661931, longitude: 13.071364 },
  { name: 'KV204', latitude: 43.664472, longitude: 20.560444 },
  { name: 'D281S', latitude: 43.666081, longitude: 13.049122 },
  { name: 'PY406', latitude: 43.667014, longitude: 13.588989 },
  { name: '50DME', latitude: 43.667931, longitude: 13.468047 },
  { name: 'KV313', latitude: 43.669306, longitude: 20.239306 },
  { name: 'PY402', latitude: 43.669597, longitude: 13.273433 },
  { name: 'D134L', latitude: 43.670117, longitude: 20.765231 },
  { name: 'KV314', latitude: 43.670278, longitude: 20.014139 },
  { name: 'TORTO', latitude: 43.670833, longitude: 19.731389 },
  { name: 'BALIK', latitude: 43.676667, longitude: 27.3 },
  { name: 'SP618', latitude: 43.677833, longitude: 15.993306 },
  { name: 'SORUG', latitude: 43.678514, longitude: 12.982397 },
  { name: 'VP017', latitude: 43.680278, longitude: 15.917222 },
  { name: 'KV410', latitude: 43.680806, longitude: 20.526972 },
  { name: 'ROSUV', latitude: 43.683056, longitude: 12.063611 },
  { name: 'BANUR', latitude: 43.683333, longitude: 30.966667 },
  { name: 'ETIDA', latitude: 43.685833, longitude: 22.417778 },
  { name: 'RAVAK', latitude: 43.6875, longitude: 21.626944 },
  { name: 'BINOL', latitude: 43.688258, longitude: 40.108606 },
  { name: 'VP271', latitude: 43.688889, longitude: 12.808333 },
  { name: 'TIMUR', latitude: 43.688889, longitude: 24.239167 },
  { name: 'D329M', latitude: 43.6895, longitude: 16.168936 },
  { name: 'FN22X', latitude: 43.689681, longitude: 13.519956 },
  { name: 'MATAR', latitude: 43.689722, longitude: 20.608056 },
  { name: 'FQ22', latitude: 43.692961, longitude: 13.527792 },
  { name: 'KV202', latitude: 43.693028, longitude: 20.741472 },
  { name: 'LYKEA', latitude: 43.693056, longitude: 20.83 },
  { name: 'NENKO', latitude: 43.693056, longitude: 27.971667 },
  { name: 'GAPRI', latitude: 43.694722, longitude: 15.800278 },
  { name: 'D134J', latitude: 43.695322, longitude: 20.735164 },
  { name: 'VP018', latitude: 43.696389, longitude: 16.5475 },
  { name: 'D223E', latitude: 43.698617, longitude: 20.578908 },
  { name: 'PY603', latitude: 43.698692, longitude: 13.160147 },
  { name: 'TPL36', latitude: 43.699722, longitude: 21.313031 },
  { name: 'SOMOV', latitude: 43.7, longitude: 24.85 },
  { name: 'DINRO', latitude: 43.7, longitude: 28.808333 },
  { name: 'DOLOT', latitude: 43.701667, longitude: 33.47 },
  { name: 'PR533', latitude: 43.701692, longitude: 13.062133 },
  { name: 'VP008', latitude: 43.704722, longitude: 17.786944 },
  { name: 'KV206', latitude: 43.707056, longitude: 20.056 },
  { name: 'CQ22', latitude: 43.709622, longitude: 13.567631 },
  { name: 'KV31F', latitude: 43.709833, longitude: 20.720639 },
  { name: 'PY701', latitude: 43.714492, longitude: 13.497217 },
  { name: 'KV305', latitude: 43.714583, longitude: 20.900056 },
  { name: 'FI22', latitude: 43.714758, longitude: 13.497617 },
  { name: 'VP272', latitude: 43.716667, longitude: 13.216667 },
  { name: 'BINBI', latitude: 43.718333, longitude: 29.635556 },
  { name: 'PESAK', latitude: 43.718611, longitude: 19.504167 },
  { name: 'MOSOP', latitude: 43.719167, longitude: 29.530278 },
  { name: 'D130I', latitude: 43.719772, longitude: 20.724206 },
  { name: 'D166R', latitude: 43.720619, longitude: 12.695575 },
  { name: 'DIPAS', latitude: 43.720833, longitude: 34.239444 },
  { name: 'SP902', latitude: 43.721222, longitude: 16.0725 },
  { name: 'CICEV', latitude: 43.722222, longitude: 21.441111 },
  { name: 'IRDUM', latitude: 43.723333, longitude: 29.389444 },
  { name: 'OSKED', latitude: 43.723333, longitude: 39.533333 },
  { name: 'D252X', latitude: 43.727433, longitude: 20.047739 },
  { name: 'SP903', latitude: 43.728556, longitude: 16.401611 },
  { name: 'PY403', latitude: 43.728675, longitude: 13.354461 },
  { name: 'INRIB', latitude: 43.728889, longitude: 13.679722 },
  { name: 'D026J', latitude: 43.730744, longitude: 13.586044 },
  { name: 'D134G', latitude: 43.731853, longitude: 20.691519 },
  { name: 'D046J', latitude: 43.732153, longitude: 13.544547 },
  { name: 'REVDA', latitude: 43.733333, longitude: 29.143333 },
  { name: 'FF22', latitude: 43.733617, longitude: 13.523622 },
  { name: 'VP019', latitude: 43.734167, longitude: 15.786389 },
  { name: 'LUGEB', latitude: 43.735556, longitude: 28.501111 },
  { name: 'D058N', latitude: 43.735678, longitude: 13.653136 },
  { name: 'REMPI', latitude: 43.736667, longitude: 16.822778 },
  { name: 'ZD736', latitude: 43.737861, longitude: 15.624194 },
  { name: 'D010O', latitude: 43.739625, longitude: 16.385528 },
  { name: 'D163E', latitude: 43.739697, longitude: 20.608342 },
  { name: 'KEPUM', latitude: 43.740556, longitude: 13.533333 },
  { name: 'TISOM', latitude: 43.741667, longitude: 36.491667 },
  { name: 'VP020', latitude: 43.742222, longitude: 16.1825 },
  { name: 'KV201', latitude: 43.742611, longitude: 20.679972 },
  { name: 'KV111', latitude: 43.74325, longitude: 20.679167 },
  { name: 'SP710', latitude: 43.743806, longitude: 15.404806 },
  { name: 'KV205', latitude: 43.744056, longitude: 20.397083 },
  { name: 'D356O', latitude: 43.746617, longitude: 16.304297 },
  { name: 'PY632', latitude: 43.747733, longitude: 13.432525 },
  { name: 'VP009', latitude: 43.749444, longitude: 18.406944 },
  { name: 'BULEN', latitude: 43.75, longitude: 25.816667 },
  { name: 'D155J', latitude: 43.752014, longitude: 39.412942 },
  { name: 'BOKOV', latitude: 43.753056, longitude: 20.673056 },
  { name: 'VRANA', latitude: 43.753611, longitude: 17.537778 },
  { name: 'D034J', latitude: 43.755058, longitude: 13.510328 },
  { name: 'VP021', latitude: 43.755278, longitude: 16.622222 },
  { name: 'NAVOD', latitude: 43.755833, longitude: 24.559722 },
  { name: 'D150J', latitude: 43.757428, longitude: 39.431642 },
  { name: 'FD31', latitude: 43.758297, longitude: 20.659881 },
  { name: 'D032J', latitude: 43.758358, longitude: 13.504047 },
  { name: 'PR524', latitude: 43.760714, longitude: 13.129 },
  { name: '40THR', latitude: 43.760789, longitude: 20.657397 },
  { name: 'PY207', latitude: 43.761247, longitude: 13.632886 },
  { name: 'D323S', latitude: 43.762039, longitude: 16.066917 },
  { name: 'KV408', latitude: 43.762083, longitude: 20.052194 },
  { name: 'PY639', latitude: 43.762186, longitude: 13.293675 },
  { name: 'ZD707', latitude: 43.763806, longitude: 15.675833 },
  { name: 'LADUV', latitude: 43.764167, longitude: 13.565833 },
  { name: 'D302S', latitude: 43.767542, longitude: 13.113122 },
  { name: 'D121Z', latitude: 43.769158, longitude: 13.110003 },
  { name: 'AMSED', latitude: 43.771111, longitude: 13.018611 },
  { name: 'D252M', latitude: 43.771514, longitude: 20.297992 },
  { name: 'SATOL', latitude: 43.772778, longitude: 15.875 },
  { name: 'MITNO', latitude: 43.773333, longitude: 19.241667 },
  { name: 'TOTMA', latitude: 43.773889, longitude: 34.073889 },
  { name: 'TODNI', latitude: 43.773972, longitude: 18.307167 },
  { name: 'D020J', latitude: 43.774725, longitude: 13.463567 },
  { name: 'OGAPA', latitude: 43.778056, longitude: 38.036667 },
  { name: 'D252K', latitude: 43.778547, longitude: 20.338244 },
  { name: 'D324T', latitude: 43.780111, longitude: 16.063842 },
  { name: 'SOSEK', latitude: 43.780556, longitude: 20.955556 },
  { name: 'IRGID', latitude: 43.780556, longitude: 39.913611 },
  { name: 'D220C', latitude: 43.781017, longitude: 20.528969 },
  { name: 'D029N', latitude: 43.782025, longitude: 13.646533 },
  { name: 'KV207', latitude: 43.782111, longitude: 20.878417 },
  { name: 'OSTOV', latitude: 43.783333, longitude: 23.8 },
  { name: 'ASDOR', latitude: 43.784722, longitude: 12.399722 },
  { name: 'SP601', latitude: 43.785556, longitude: 16.021444 },
  { name: '28VOR', latitude: 43.785989, longitude: 20.626708 },
  { name: 'PY404', latitude: 43.787692, longitude: 13.43565 },
  { name: 'IVMAN', latitude: 43.787778, longitude: 13.598333 },
  { name: 'EDUGI', latitude: 43.79105, longitude: 14.172306 },
  { name: 'D135R', latitude: 43.791864, longitude: 12.893783 },
  { name: 'VP010', latitude: 43.793056, longitude: 18.321389 },
  { name: 'D013M', latitude: 43.793636, longitude: 13.558572 },
  { name: 'LIKNO', latitude: 43.793889, longitude: 12.348333 },
  { name: 'BUSIL', latitude: 43.794444, longitude: 21.496389 },
  { name: 'PIDIT', latitude: 43.794444, longitude: 34.251667 },
  { name: 'EGHOS', latitude: 43.796667, longitude: 12.863056 },
  { name: 'D221R', latitude: 43.797414, longitude: 12.331153 },
  { name: 'KV409', latitude: 43.797778, longitude: 20.363083 },
  { name: 'ADINA', latitude: 43.803333, longitude: 30.505 },
  { name: 'IDOMO', latitude: 43.804444, longitude: 24.813889 },
  { name: 'KV107', latitude: 43.8055, longitude: 21.271472 },
  { name: 'PY604', latitude: 43.805911, longitude: 13.243544 },
  { name: 'KV306', latitude: 43.8065, longitude: 20.845528 },
  { name: 'MD31', latitude: 43.808639, longitude: 20.599544 },
  { name: 'D012N', latitude: 43.810733, longitude: 13.559933 },
  { name: 'PY206', latitude: 43.811889, longitude: 13.563303 },
  { name: 'D129R', latitude: 43.813992, longitude: 12.924553 },
  { name: 'VP022', latitude: 43.815833, longitude: 15.668056 },
  { name: 'EMKES', latitude: 43.816897, longitude: 17.853267 },
  { name: 'PIDEP', latitude: 43.817778, longitude: 12.2175 },
  { name: 'VP011', latitude: 43.818611, longitude: 18.459444 },
  { name: 'ZD704', latitude: 43.819917, longitude: 15.238111 },
  { name: 'KV407', latitude: 43.822417, longitude: 20.26675 },
  { name: 'PR529', latitude: 43.822925, longitude: 12.623006 },
  { name: 'KV109', latitude: 43.823722, longitude: 20.032889 },
  { name: 'D338O', latitude: 43.824189, longitude: 13.364394 },
  { name: 'SPL30', latitude: 43.826667, longitude: 15.774444 },
  { name: 'VP023', latitude: 43.827778, longitude: 15.301111 },
  { name: 'NULGI', latitude: 43.827925, longitude: 13.315053 },
  { name: 'VP012', latitude: 43.829167, longitude: 18.373889 },
  { name: 'DER30', latitude: 43.829958, longitude: 18.317117 },
  { name: 'D274D', latitude: 43.831019, longitude: 20.498706 },
  { name: 'VP013', latitude: 43.831667, longitude: 18.189167 },
  { name: 'ZD911', latitude: 43.83175, longitude: 15.382972 },
  { name: 'SP705', latitude: 43.832528, longitude: 15.878917 },
  { name: 'LOMOS', latitude: 43.833333, longitude: 23.25 },
  { name: 'ROPUT', latitude: 43.833333, longitude: 32.501667 },
  { name: 'MD13', latitude: 43.833614, longitude: 20.569028 },
  { name: 'CEV33', latitude: 43.834147, longitude: 12.918244 },
  { name: 'PR527', latitude: 43.835869, longitude: 12.699308 },
  { name: 'KV13M', latitude: 43.837833, longitude: 20.5615 },
  { name: 'MA11', latitude: 43.839953, longitude: 18.290342 },
  { name: 'MQ11', latitude: 43.842019, longitude: 18.291964 },
  { name: 'SAVAC', latitude: 43.8425, longitude: 21.359167 },
  { name: 'VP014', latitude: 43.843056, longitude: 18.328611 },
  { name: 'PR532', latitude: 43.8435, longitude: 12.580844 },
  { name: 'KATFU', latitude: 43.846667, longitude: 13.516944 },
  { name: 'KREM1', latitude: 43.849444, longitude: 19.579722 },
  { name: 'SA009', latitude: 43.849992, longitude: 18.263564 },
  { name: 'VAKIS', latitude: 43.852222, longitude: 28.724722 },
  { name: 'D207O', latitude: 43.853575, longitude: 18.326367 },
  { name: 'ORLO1', latitude: 43.856111, longitude: 19.844722 },
  { name: '25THR', latitude: 43.856389, longitude: 20.538344 },
  { name: '29VOR', latitude: 43.857142, longitude: 20.539736 },
  { name: 'ANAKO', latitude: 43.858278, longitude: 15.458389 },
  { name: 'IZVOR', latitude: 43.859444, longitude: 21.623611 },
  { name: 'FI11S', latitude: 43.86135, longitude: 18.233025 },
  { name: '43LOC', latitude: 43.86135, longitude: 18.233025 },
  { name: 'D285G', latitude: 43.863003, longitude: 20.429003 },
  { name: 'D211K', latitude: 43.863639, longitude: 12.473772 },
  { name: 'SA011', latitude: 43.865733, longitude: 18.221417 },
  { name: 'GILUK', latitude: 43.866944, longitude: 18.105556 },
  { name: 'OKLAX', latitude: 43.8675, longitude: 16.042778 },
  { name: 'VP282', latitude: 43.869444, longitude: 12.493056 },
  { name: 'VP015', latitude: 43.869722, longitude: 18.282778 },
  { name: 'ZD910', latitude: 43.870972, longitude: 15.273028 },
  { name: 'D212K', latitude: 43.871742, longitude: 17.910464 },
  { name: 'PR553', latitude: 43.873828, longitude: 12.089397 },
  { name: 'ROGAK', latitude: 43.874722, longitude: 12.902778 },
  { name: 'GUBOR', latitude: 43.881389, longitude: 40.710278 },
  { name: 'KV212', latitude: 43.882944, longitude: 20.109833 },
  { name: 'D113R', latitude: 43.883033, longitude: 12.989475 },
  { name: 'SP904', latitude: 43.883278, longitude: 16.287389 },
  { name: 'CI11S', latitude: 43.885769, longitude: 18.167472 },
  { name: 'FF11', latitude: 43.885769, longitude: 18.167472 },
  { name: 'SA001', latitude: 43.885947, longitude: 18.167208 },
  { name: 'KV401', latitude: 43.887333, longitude: 20.499694 },
  { name: 'PR713', latitude: 43.890236, longitude: 12.834267 },
  { name: 'PR512', latitude: 43.890306, longitude: 12.844806 },
  { name: 'D312E', latitude: 43.891686, longitude: 18.233236 },
  { name: 'FQ11', latitude: 43.891919, longitude: 18.172164 },
  { name: 'CF31', latitude: 43.894258, longitude: 12.836961 },
  { name: 'SOGRA', latitude: 43.897278, longitude: 15.117389 },
  { name: 'BAKRI', latitude: 43.898056, longitude: 38.281667 },
  { name: 'PEVAT', latitude: 43.901944, longitude: 35.2175 },
  { name: 'KV208', latitude: 43.903222, longitude: 20.801167 },
  { name: 'RIMUG', latitude: 43.903667, longitude: 15.341056 },
  { name: 'KV308', latitude: 43.90625, longitude: 20.660861 },
  { name: 'KV13F', latitude: 43.9075, longitude: 20.474472 },
  { name: 'NANER', latitude: 43.908333, longitude: 20.464444 },
  { name: 'UTEKA', latitude: 43.912222, longitude: 22.392222 },
  { name: 'D121K', latitude: 43.912444, longitude: 12.826903 },
  { name: 'KUPIL', latitude: 43.912778, longitude: 33.548333 },
  { name: 'D212H', latitude: 43.914392, longitude: 17.954761 },
  { name: 'EDADI', latitude: 43.916694, longitude: 15.382722 },
  { name: 'D212G', latitude: 43.919719, longitude: 17.9603 },
  { name: 'RASOK', latitude: 43.919722, longitude: 37.993889 },
  { name: 'FF31', latitude: 43.920833, longitude: 12.789611 },
  { name: 'PR509', latitude: 43.920889, longitude: 12.790083 },
  { name: 'VP016', latitude: 43.921944, longitude: 18.316944 },
  { name: 'D285R', latitude: 43.922408, longitude: 20.206261 },
  { name: 'TUNSA', latitude: 43.923333, longitude: 32.758333 },
  { name: 'KV402', latitude: 43.924417, longitude: 20.453306 },
  { name: 'VP284', latitude: 43.925, longitude: 12.516667 },
  { name: 'GIKIR', latitude: 43.925, longitude: 39.043611 },
  { name: 'KV108', latitude: 43.925611, longitude: 20.235639 },
  { name: 'D104R', latitude: 43.926756, longitude: 13.013622 },
  { name: 'RIVUS', latitude: 43.927583, longitude: 18.054861 },
  { name: 'PESED', latitude: 43.927778, longitude: 31.7725 },
  { name: 'KV106', latitude: 43.929306, longitude: 20.893556 },
  { name: 'VP024', latitude: 43.929444, longitude: 15.802222 },
  { name: 'OBUMA', latitude: 43.929444, longitude: 37.861944 },
  { name: 'GAMZA', latitude: 43.93, longitude: 22.167222 },
  { name: 'D211F', latitude: 43.932536, longitude: 12.538622 },
  { name: 'KULEM', latitude: 43.933333, longitude: 35.518333 },
  { name: 'INDIM', latitude: 43.935, longitude: 38.833611 },
  { name: 'D293N', latitude: 43.935364, longitude: 18.034231 },
  { name: 'CUPRI', latitude: 43.935556, longitude: 21.389722 },
  { name: 'D121I', latitude: 43.935825, longitude: 12.779178 },
  { name: 'ZD807', latitude: 43.937111, longitude: 15.422389 },
  { name: 'CQ11', latitude: 43.937547, longitude: 18.062169 },
  { name: 'D112K', latitude: 43.938636, longitude: 18.278736 },
  { name: 'D113R', latitude: 43.939067, longitude: 18.034697 },
  { name: 'RODGO', latitude: 43.939611, longitude: 15.574139 },
  { name: 'MOBIT', latitude: 43.939722, longitude: 38.735 },
  { name: 'D121H', latitude: 43.940497, longitude: 12.769631 },
  { name: 'PR519', latitude: 43.940653, longitude: 12.973514 },
  { name: 'D123H', latitude: 43.941228, longitude: 18.199331 },
  { name: 'RIM08', latitude: 43.945169, longitude: 12.760078 },
  { name: 'D115N', latitude: 43.945833, longitude: 18.042153 },
  { name: 'KV333', latitude: 43.946278, longitude: 20.425917 },
  { name: 'EMGER', latitude: 43.946389, longitude: 39.771667 },
  { name: 'NALOB', latitude: 43.947222, longitude: 37.610556 },
  { name: 'D115R', latitude: 43.948744, longitude: 18.035114 },
  { name: 'BORA1', latitude: 43.949167, longitude: 19.59 },
  { name: 'KV406', latitude: 43.950528, longitude: 20.189833 },
  { name: 'PR705', latitude: 43.952019, longitude: 12.691928 },
  { name: 'ZD808', latitude: 43.952444, longitude: 15.5915 },
  { name: 'D254R', latitude: 43.953725, longitude: 12.209919 },
  { name: 'D123L', latitude: 43.955242, longitude: 18.174581 },
  { name: 'LANLI', latitude: 43.957308, longitude: 13.180475 },
  { name: 'FD31', latitude: 43.959183, longitude: 12.731417 },
  { name: 'ODLET', latitude: 43.960556, longitude: 19.389722 },
  { name: 'VP025', latitude: 43.961389, longitude: 15.398333 },
  { name: 'D322J', latitude: 43.963758, longitude: 20.456225 },
  { name: 'ULKOT', latitude: 43.964722, longitude: 40.0 },
  { name: '49LOC', latitude: 43.965764, longitude: 12.709389 },
  { name: 'PR432', latitude: 43.966536, longitude: 12.433886 },
  { name: 'PR406', latitude: 43.967861, longitude: 12.705667 },
  { name: 'ABUNI', latitude: 43.968919, longitude: 39.649103 },
  { name: 'ALENO', latitude: 43.969167, longitude: 29.841389 },
  { name: 'D348H', latitude: 43.969917, longitude: 18.285486 },
  { name: '40THR', latitude: 43.973028, longitude: 12.696647 },
  { name: 'APROB', latitude: 43.973333, longitude: 25.0025 },
  { name: 'D219J', latitude: 43.973694, longitude: 15.206222 },
  { name: 'D197C', latitude: 43.974597, longitude: 18.038561 },
  { name: 'ZD702', latitude: 43.974639, longitude: 14.996528 },
  { name: 'D206H', latitude: 43.976917, longitude: 15.269592 },
  { name: 'ORTIP', latitude: 43.977778, longitude: 25.333056 },
  { name: '40VOR', latitude: 43.977858, longitude: 12.693181 },
  { name: 'NIGEV', latitude: 43.978056, longitude: 24.753889 },
  { name: 'SUREN', latitude: 43.978056, longitude: 37.148333 },
  { name: 'JAGOD', latitude: 43.978333, longitude: 21.259167 },
  { name: 'D110L', latitude: 43.978808, longitude: 18.180022 },
  { name: 'RASUB', latitude: 43.978889, longitude: 25.418333 },
  { name: 'INRES', latitude: 43.98, longitude: 20.768889 },
  { name: 'KUSAL', latitude: 43.98, longitude: 35.971667 },
  { name: 'ZD401', latitude: 43.981194, longitude: 15.220389 },
  { name: 'NESUX', latitude: 43.982222, longitude: 19.556667 },
  { name: 'KOMAN', latitude: 43.983333, longitude: 26.216667 },
  { name: 'SP905', latitude: 43.983528, longitude: 16.213389 },
  { name: 'D339J', latitude: 43.984689, longitude: 20.519519 },
  { name: 'PR623', latitude: 43.986897, longitude: 12.860536 },
  { name: 'D357J', latitude: 43.987808, longitude: 20.592231 },
  { name: 'ARBAD', latitude: 43.99, longitude: 33.476667 },
  { name: 'SA013', latitude: 43.990667, longitude: 18.258928 },
  { name: 'KV101', latitude: 43.992028, longitude: 20.368528 },
  { name: 'D129N', latitude: 43.993106, longitude: 18.091739 },
  { name: 'D137H', latitude: 43.9949, longitude: 15.476781 },
  { name: 'D309O', latitude: 43.996933, longitude: 20.338417 },
  { name: 'PR526', latitude: 43.997456, longitude: 12.872131 },
  { name: 'IDABO', latitude: 43.997778, longitude: 21.029444 },
  { name: '15THR', latitude: 43.998472, longitude: 12.650944 },
  { name: 'D321L', latitude: 43.998536, longitude: 18.154117 },
  { name: 'D221H', latitude: 43.998597, longitude: 15.230344 },
  { name: 'LEVPA', latitude: 43.999417, longitude: 15.493722 },
  { name: '4413E', latitude: 44.0, longitude: 13.0 },
  { name: '4414E', latitude: 44.0, longitude: 14.0 },
  { name: '4415E', latitude: 44.0, longitude: 15.0 },
  { name: '4416E', latitude: 44.0, longitude: 16.0 },
  { name: '4417E', latitude: 44.0, longitude: 17.0 },
  { name: '4418E', latitude: 44.0, longitude: 18.0 },
  { name: '4419E', latitude: 44.0, longitude: 19.0 },
  { name: '4420E', latitude: 44.0, longitude: 20.0 },
  { name: '4421E', latitude: 44.0, longitude: 21.0 },
  { name: '4422E', latitude: 44.0, longitude: 22.0 },
  { name: '4423E', latitude: 44.0, longitude: 23.0 },
  { name: '4424E', latitude: 44.0, longitude: 24.0 },
  { name: '4425E', latitude: 44.0, longitude: 25.0 },
  { name: '4426E', latitude: 44.0, longitude: 26.0 },
  { name: '4427E', latitude: 44.0, longitude: 27.0 },
  { name: '4428E', latitude: 44.0, longitude: 28.0 },
  { name: '4429E', latitude: 44.0, longitude: 29.0 },
  { name: '4430E', latitude: 44.0, longitude: 30.0 },
  { name: '4431E', latitude: 44.0, longitude: 31.0 },
  { name: '4432E', latitude: 44.0, longitude: 32.0 },
  { name: '4433E', latitude: 44.0, longitude: 33.0 },
  { name: '4434E', latitude: 44.0, longitude: 34.0 },
  { name: '4435E', latitude: 44.0, longitude: 35.0 },
  { name: '4436E', latitude: 44.0, longitude: 36.0 },
  { name: '4437E', latitude: 44.0, longitude: 37.0 },
  { name: '4438E', latitude: 44.0, longitude: 38.0 },
  { name: '4439E', latitude: 44.0, longitude: 39.0 },
  { name: '4440E', latitude: 44.0, longitude: 40.0 },
  { name: 'CD04', latitude: 44.000278, longitude: 15.234528 },
  { name: 'KOSE1', latitude: 44.001389, longitude: 19.906667 },
  { name: 'VP287', latitude: 44.001944, longitude: 13.031667 },
  { name: 'D265R', latitude: 44.003939, longitude: 12.201228 },
  { name: 'MAGUR', latitude: 44.004167, longitude: 22.260556 },
  { name: 'ZD908', latitude: 44.004667, longitude: 15.062944 },
  { name: 'MD31', latitude: 44.005844, longitude: 12.635783 },
  { name: 'NEPOS', latitude: 44.006111, longitude: 21.4375 },
  { name: 'ADNAN', latitude: 44.007203, longitude: 18.114186 },
  { name: 'MA31', latitude: 44.008703, longitude: 12.632514 },
  { name: 'VP017', latitude: 44.01, longitude: 18.025833 },
  { name: 'KV210', latitude: 44.010639, longitude: 20.567472 },
  { name: 'IBENI', latitude: 44.014167, longitude: 13.921667 },
  { name: 'D333M', latitude: 44.017978, longitude: 20.474256 },
  { name: 'BEPTU', latitude: 44.020833, longitude: 15.690194 },
  { name: 'D269L', latitude: 44.021811, longitude: 12.339617 },
  { name: 'PR729', latitude: 44.023178, longitude: 12.339692 },
  { name: 'VP018', latitude: 44.025, longitude: 18.552778 },
  { name: 'KV309', latitude: 44.025556, longitude: 20.438444 },
  { name: 'D283R', latitude: 44.025892, longitude: 18.054364 },
  { name: 'D309G', latitude: 44.025931, longitude: 20.313483 },
  { name: 'DINKO', latitude: 44.026083, longitude: 15.275556 },
  { name: 'ZD950', latitude: 44.02625, longitude: 15.087139 },
  { name: 'ARKUT', latitude: 44.026389, longitude: 38.091667 },
  { name: 'D163L', latitude: 44.026494, longitude: 18.274486 },
  { name: 'D269V', latitude: 44.026833, longitude: 12.108719 },
  { name: 'D236H', latitude: 44.026864, longitude: 15.200175 },
  { name: 'KOSIV', latitude: 44.026944, longitude: 19.809167 },
  { name: 'UNIPA', latitude: 44.029444, longitude: 16.482778 },
  { name: 'FD04', latitude: 44.03, longitude: 15.275 },
  { name: 'D146N', latitude: 44.035931, longitude: 18.171433 },
  { name: 'D221E', latitude: 44.036361, longitude: 15.282539 },
  { name: 'PANZU', latitude: 44.036667, longitude: 24.136944 },
  { name: 'RENAT', latitude: 44.036667, longitude: 36.558333 },
  { name: 'OTBIL', latitude: 44.038056, longitude: 38.405 },
  { name: 'LASOR', latitude: 44.038333, longitude: 34.966667 },
  { name: 'KV404', latitude: 44.040111, longitude: 20.286972 },
  { name: 'D137L', latitude: 44.040392, longitude: 12.245894 },
  { name: 'SORDU', latitude: 44.042528, longitude: 25.446731 },
  { name: 'PR426', latitude: 44.043472, longitude: 12.345367 },
  { name: 'KV105', latitude: 44.044389, longitude: 20.537333 },
  { name: 'NULGO', latitude: 44.045, longitude: 23.717222 },
  { name: 'D117F', latitude: 44.045117, longitude: 15.479897 },
  { name: 'D136Q', latitude: 44.045225, longitude: 18.085861 },
  { name: 'PR616', latitude: 44.046908, longitude: 12.261389 },
  { name: 'D146J', latitude: 44.050061, longitude: 12.184925 },
  { name: 'RONBU', latitude: 44.051667, longitude: 26.498333 },
  { name: '40THR', latitude: 44.052617, longitude: 15.421958 },
  { name: 'D246Q', latitude: 44.053239, longitude: 20.383267 },
  { name: 'KV209', latitude: 44.054722, longitude: 20.704083 },
  { name: 'PR604', latitude: 44.055558, longitude: 12.515322 },
  { name: 'KV307', latitude: 44.056111, longitude: 20.696472 },
  { name: 'D174I', latitude: 44.056861, longitude: 12.372331 },
  { name: 'PR427', latitude: 44.058258, longitude: 12.13315 },
  { name: 'OSTAK', latitude: 44.058611, longitude: 15.099389 },
  { name: 'D163N', latitude: 44.059117, longitude: 18.264836 },
  { name: '32THR', latitude: 44.059411, longitude: 12.541547 },
  { name: 'BIRLU', latitude: 44.059444, longitude: 28.7525 },
  { name: 'ZD504', latitude: 44.0595, longitude: 15.412667 },
  { name: 'KV102', latitude: 44.060472, longitude: 20.267389 },
  { name: 'D127C', latitude: 44.062514, longitude: 15.416572 },
  { name: 'BORAK', latitude: 44.064167, longitude: 22.027778 },
  { name: '25NDB', latitude: 44.065417, longitude: 15.404472 },
  { name: '25DME', latitude: 44.065444, longitude: 15.404528 },
  { name: 'TIMID', latitude: 44.066383, longitude: 18.242261 },
  { name: 'DENUB', latitude: 44.066389, longitude: 29.443333 },
  { name: 'D128M', latitude: 44.066961, longitude: 12.587375 },
  { name: 'D171M', latitude: 44.069172, longitude: 28.494061 },
  { name: 'FD13Z', latitude: 44.069808, longitude: 12.5294 },
  { name: 'PEVEX', latitude: 44.07, longitude: 28.448611 },
  { name: 'KUKUN', latitude: 44.070833, longitude: 19.560278 },
  { name: 'KV104', latitude: 44.071472, longitude: 20.452806 },
  { name: 'MD04', latitude: 44.071583, longitude: 15.33175 },
  { name: 'VP026', latitude: 44.072778, longitude: 15.196389 },
  { name: 'PR506', latitude: 44.073306, longitude: 12.516583 },
  { name: 'D357O', latitude: 44.074433, longitude: 20.596297 },
  { name: 'D246L', latitude: 44.076983, longitude: 20.477739 },
  { name: 'D304F', latitude: 44.077064, longitude: 12.507658 },
  { name: 'D017D', latitude: 44.077908, longitude: 18.096422 },
  { name: 'PR731', latitude: 44.079406, longitude: 12.354028 },
  { name: 'ZD850', latitude: 44.079833, longitude: 15.148444 },
  { name: 'FD13Y', latitude: 44.080728, longitude: 12.511933 },
  { name: 'ARGES', latitude: 44.082222, longitude: 26.826667 },
  { name: 'PR531', latitude: 44.084561, longitude: 12.873211 },
  { name: 'KV403', latitude: 44.085167, longitude: 20.536583 },
  { name: 'PR609', latitude: 44.085256, longitude: 12.382869 },
  { name: 'VP027', latitude: 44.086389, longitude: 15.5325 },
  { name: 'TALAM', latitude: 44.086389, longitude: 30.438611 },
  { name: 'D345P', latitude: 44.087125, longitude: 20.519392 },
  { name: 'D246J', latitude: 44.087383, longitude: 20.519236 },
  { name: 'D307N', latitude: 44.087847, longitude: 18.212181 },
  { name: 'PR436', latitude: 44.090108, longitude: 12.722697 },
  { name: 'ZD909', latitude: 44.090111, longitude: 15.029944 },
  { name: 'D308G', latitude: 44.091644, longitude: 12.494461 },
  { name: 'D046H', latitude: 44.096986, longitude: 18.198011 },
  { name: 'D133O', latitude: 44.097231, longitude: 28.706881 },
  { name: 'D131H', latitude: 44.101044, longitude: 12.200772 },
  { name: 'D308H', latitude: 44.102561, longitude: 12.476983 },
  { name: 'PR408', latitude: 44.1045, longitude: 12.460444 },
  { name: 'SOROK', latitude: 44.105, longitude: 32.71 },
  { name: 'BUSER', latitude: 44.106667, longitude: 13.648889 },
  { name: 'OBUGA', latitude: 44.109011, longitude: 26.094272 },
  { name: 'D312H', latitude: 44.109378, longitude: 12.485775 },
  { name: 'D042H', latitude: 44.109378, longitude: 12.7475 },
  { name: 'D301R', latitude: 44.109717, longitude: 18.11295 },
  { name: 'PR614', latitude: 44.109883, longitude: 12.272394 },
  { name: 'PR717', latitude: 44.110853, longitude: 12.812108 },
  { name: 'D307P', latitude: 44.111981, longitude: 18.174753 },
  { name: 'SOLGU', latitude: 44.115833, longitude: 17.661389 },
  { name: 'D151K', latitude: 44.116744, longitude: 28.577772 },
  { name: 'MQ13Z', latitude: 44.119139, longitude: 15.331833 },
  { name: 'MD13', latitude: 44.119194, longitude: 15.331889 },
  { name: 'PR421', latitude: 44.120339, longitude: 12.256619 },
  { name: 'NUGRI', latitude: 44.121389, longitude: 37.165 },
  { name: 'D133M', latitude: 44.122369, longitude: 28.676492 },
  { name: 'D115K', latitude: 44.123008, longitude: 12.583947 },
  { name: 'MM13', latitude: 44.123203, longitude: 15.326456 },
  { name: 'D308J', latitude: 44.124383, longitude: 12.442006 },
  { name: 'KV211', latitude: 44.124556, longitude: 20.317417 },
  { name: 'D353L', latitude: 44.125425, longitude: 18.440539 },
  { name: 'PR611', latitude: 44.125833, longitude: 12.610208 },
  { name: 'VP292', latitude: 44.126389, longitude: 12.061111 },
  { name: 'VP293', latitude: 44.127778, longitude: 12.786667 },
  { name: 'LIKVI', latitude: 44.128333, longitude: 17.991667 },
  { name: 'SUPED', latitude: 44.129167, longitude: 40.867778 },
  { name: 'D027H', latitude: 44.130731, longitude: 12.708903 },
  { name: 'LALVA', latitude: 44.130878, longitude: 12.450161 },
  { name: 'D326H', latitude: 44.132653, longitude: 12.5189 },
  { name: 'GALMI', latitude: 44.133333, longitude: 30.025556 },
  { name: 'NUPSO', latitude: 44.134167, longitude: 15.852222 },
  { name: 'D307R', latitude: 44.134197, longitude: 18.140253 },
  { name: 'PR411', latitude: 44.134917, longitude: 12.405333 },
  { name: 'D308K', latitude: 44.135289, longitude: 12.424506 },
  { name: 'VEBAR', latitude: 44.136667, longitude: 19.180278 },
  { name: 'KEDUR', latitude: 44.136944, longitude: 38.368333 },
  { name: 'D115I', latitude: 44.138211, longitude: 12.542747 },
  { name: 'ELGEB', latitude: 44.140556, longitude: 20.192222 },
  { name: 'OLTIN', latitude: 44.141667, longitude: 27.726667 },
  { name: 'KV310', latitude: 44.141972, longitude: 20.219778 },
  { name: 'D105M', latitude: 44.142758, longitude: 12.644919 },
  { name: '40DME', latitude: 44.143028, longitude: 15.299528 },
  { name: '40VOR', latitude: 44.143056, longitude: 15.299556 },
  { name: 'D012H', latitude: 44.143953, longitude: 12.664564 },
  { name: 'CD29', latitude: 44.144669, longitude: 12.436594 },
  { name: 'D360H', latitude: 44.148325, longitude: 12.626331 },
  { name: 'FD30', latitude: 44.149944, longitude: 12.217908 },
  { name: 'SIRMI', latitude: 44.15, longitude: 16.303611 },
  { name: 'VP028', latitude: 44.155, longitude: 15.034444 },
  { name: 'D307T', latitude: 44.156406, longitude: 18.105725 },
  { name: 'FD29', latitude: 44.156428, longitude: 12.420189 },
  { name: 'NEVKA', latitude: 44.1575, longitude: 32.476389 },
  { name: 'ZD851', latitude: 44.15825, longitude: 15.473194 },
  { name: 'D004I', latitude: 44.159528, longitude: 18.094075 },
  { name: 'KV103', latitude: 44.159611, longitude: 20.120222 },
  { name: 'D128N', latitude: 44.160531, longitude: 20.116119 },
  { name: 'CT29', latitude: 44.161003, longitude: 12.480944 },
  { name: 'KV405', latitude: 44.161083, longitude: 20.112 },
  { name: 'RUTOX', latitude: 44.161667, longitude: 28.461111 },
  { name: 'AMLAV', latitude: 44.161944, longitude: 29.766111 },
  { name: 'D129N', latitude: 44.162017, longitude: 20.105256 },
  { name: '50VOR', latitude: 44.162478, longitude: 12.175006 },
  { name: 'D288Y', latitude: 44.163219, longitude: 12.076225 },
  { name: 'GAZON', latitude: 44.163333, longitude: 20.886667 },
  { name: 'ELVAB', latitude: 44.164444, longitude: 25.090833 },
  { name: 'D307U', latitude: 44.167506, longitude: 18.088453 },
  { name: 'OM13', latitude: 44.169186, longitude: 15.264081 },
  { name: '35VOR', latitude: 44.171869, longitude: 12.142814 },
  { name: 'ZD768', latitude: 44.17475, longitude: 15.483 },
  { name: 'ORSUT', latitude: 44.176039, longitude: 26.075903 },
  { name: 'FT29', latitude: 44.176167, longitude: 12.439692 },
  { name: 'RUKOK', latitude: 44.176389, longitude: 12.6925 },
  { name: 'BAKEP', latitude: 44.176944, longitude: 12.594722 },
  { name: 'D300R', latitude: 44.178033, longitude: 12.267011 },
  { name: 'BAPIL', latitude: 44.178889, longitude: 12.354444 },
  { name: 'OKLOP', latitude: 44.183333, longitude: 21.331667 },
  { name: 'DONOX', latitude: 44.184769, longitude: 25.906633 },
  { name: 'BATRU', latitude: 44.185, longitude: 19.365 },
  { name: 'MD30', latitude: 44.187503, longitude: 12.089142 },
  { name: 'FI13', latitude: 44.190694, longitude: 15.234806 },
  { name: 'FF13', latitude: 44.190722, longitude: 15.234861 },
  { name: 'VOR15', latitude: 44.191689, longitude: 12.370931 },
  { name: 'ZD502', latitude: 44.192944, longitude: 15.005389 },
  { name: 'LERVU', latitude: 44.196111, longitude: 28.876667 },
  { name: 'MOBLU', latitude: 44.196111, longitude: 29.447222 },
  { name: 'D180E', latitude: 44.1977, longitude: 28.465497 },
  { name: 'VP029', latitude: 44.201111, longitude: 15.6825 },
  { name: 'PR613', latitude: 44.202067, longitude: 12.605789 },
  { name: 'ETUNO', latitude: 44.204167, longitude: 34.067778 },
  { name: 'IBROK', latitude: 44.206667, longitude: 32.255 },
  { name: 'MD12', latitude: 44.206994, longitude: 12.038189 },
  { name: 'LIMAS', latitude: 44.208056, longitude: 37.82 },
  { name: 'RASOV', latitude: 44.208333, longitude: 27.963333 },
  { name: 'NORPI', latitude: 44.209528, longitude: 18.016325 },
  { name: 'D102O', latitude: 44.211111, longitude: 28.810369 },
  { name: 'NEVAK', latitude: 44.211944, longitude: 20.602778 },
  { name: 'KONIM', latitude: 44.211944, longitude: 28.8325 },
  { name: 'PR424', latitude: 44.212453, longitude: 12.502939 },
  { name: 'MT29', latitude: 44.214028, longitude: 12.336506 },
  { name: 'CF13', latitude: 44.214464, longitude: 15.202417 },
  { name: 'VP019', latitude: 44.215, longitude: 18.724722 },
  { name: 'LEBDI', latitude: 44.217722, longitude: 15.198028 },
  { name: 'NIKOL', latitude: 44.221944, longitude: 13.686111 },
  { name: 'VRUKI', latitude: 44.223333, longitude: 20.168056 },
  { name: 'BGD42', latitude: 44.223844, longitude: 20.834225 },
  { name: 'EDALA', latitude: 44.225, longitude: 17.491389 },
  { name: 'D042R', latitude: 44.226011, longitude: 12.913006 },
  { name: 'ML11', latitude: 44.233478, longitude: 12.280072 },
  { name: 'POLUN', latitude: 44.237422, longitude: 25.223372 },
  { name: 'D315R', latitude: 44.237781, longitude: 12.337408 },
  { name: 'TUTIX', latitude: 44.240117, longitude: 25.518781 },
  { name: 'USIRO', latitude: 44.241778, longitude: 25.764611 },
  { name: 'VP020', latitude: 44.244167, longitude: 16.845278 },
  { name: 'D295E', latitude: 44.244258, longitude: 12.253869 },
  { name: 'FD36', latitude: 44.244528, longitude: 28.472639 },
  { name: 'IBRIK', latitude: 44.244572, longitude: 28.472431 },
  { name: 'PR618', latitude: 44.248839, longitude: 12.697819 },
  { name: 'D336J', latitude: 44.251583, longitude: 15.283528 },
  { name: 'PR419', latitude: 44.251647, longitude: 12.432181 },
  { name: 'UTIDI', latitude: 44.252786, longitude: 26.233869 },
  { name: 'D120G', latitude: 44.252881, longitude: 24.058856 },
  { name: 'D115H', latitude: 44.253056, longitude: 24.086683 },
  { name: 'ATREL', latitude: 44.253056, longitude: 40.945278 },
  { name: 'VP297', latitude: 44.254167, longitude: 12.097222 },
  { name: '70LOC', latitude: 44.257522, longitude: 12.211256 },
  { name: 'DISEN', latitude: 44.258333, longitude: 27.435 },
  { name: 'VIKBI', latitude: 44.258611, longitude: 24.655833 },
  { name: 'D057H', latitude: 44.260306, longitude: 12.228347 },
  { name: 'FT11', latitude: 44.264622, longitude: 12.190983 },
  { name: 'D295H', latitude: 44.266878, longitude: 12.191819 },
  { name: 'ZD708', latitude: 44.267861, longitude: 15.357472 },
  { name: 'D069K', latitude: 44.268206, longitude: 12.597289 },
  { name: 'KUTON', latitude: 44.269722, longitude: 38.315 },
  { name: 'FF11', latitude: 44.274175, longitude: 12.165919 },
  { name: 'ZD503', latitude: 44.274667, longitude: 15.120417 },
  { name: 'D027R', latitude: 44.275078, longitude: 12.824744 },
  { name: 'NOBRU', latitude: 44.275667, longitude: 14.891361 },
  { name: 'OSTAL', latitude: 44.277917, longitude: 26.772883 },
  { name: 'D294J', latitude: 44.279344, longitude: 12.148647 },
  { name: 'PR428', latitude: 44.279917, longitude: 12.574561 },
  { name: 'ZD852', latitude: 44.281806, longitude: 15.304417 },
  { name: 'VP030', latitude: 44.281944, longitude: 15.3475 },
  { name: 'CF11', latitude: 44.282244, longitude: 12.142989 },
  { name: 'D072V', latitude: 44.283439, longitude: 12.562217 },
  { name: 'FI11', latitude: 44.283711, longitude: 12.138839 },
  { name: 'ELDET', latitude: 44.286111, longitude: 23.755833 },
  { name: 'KEB32', latitude: 44.2863, longitude: 17.894186 },
  { name: 'D294K', latitude: 44.286611, longitude: 12.127767 },
  { name: 'LUNAT', latitude: 44.293333, longitude: 33.52 },
  { name: 'LELTI', latitude: 44.294444, longitude: 23.425556 },
  { name: 'ORION', latitude: 44.296667, longitude: 27.616667 },
  { name: 'BEKLU', latitude: 44.296686, longitude: 26.125128 },
  { name: 'BS259', latitude: 44.298603, longitude: 25.622317 },
  { name: 'D294M', latitude: 44.301178, longitude: 12.086017 },
  { name: 'NISUT', latitude: 44.301667, longitude: 33.693611 },
  { name: 'VP031', latitude: 44.301944, longitude: 15.084167 },
  { name: 'CI11', latitude: 44.302025, longitude: 12.086728 },
  { name: 'ELTIB', latitude: 44.303611, longitude: 16.816389 },
  { name: 'LAROM', latitude: 44.31, longitude: 31.443333 },
  { name: 'ANASA', latitude: 44.311944, longitude: 22.555278 },
  { name: 'D350R', latitude: 44.312933, longitude: 12.565717 },
  { name: 'RIM28', latitude: 44.314067, longitude: 12.118725 },
  { name: 'D294O', latitude: 44.315639, longitude: 12.044194 },
  { name: 'FD09', latitude: 44.316083, longitude: 23.740083 },
  { name: 'D294N', latitude: 44.316658, longitude: 12.045017 },
  { name: 'TUMOP', latitude: 44.317667, longitude: 26.297142 },
  { name: 'FI27', latitude: 44.319189, longitude: 23.970061 },
  { name: 'CI27', latitude: 44.32, longitude: 24.037861 },
  { name: 'FD27', latitude: 44.320028, longitude: 24.037861 },
  { name: 'ZD951', latitude: 44.322278, longitude: 15.092833 },
  { name: 'D085P', latitude: 44.322917, longitude: 24.294167 },
  { name: 'ARIES', latitude: 44.323333, longitude: 27.715 },
  { name: 'ESIRI', latitude: 44.324444, longitude: 21.462778 },
  { name: 'OSVIX', latitude: 44.325556, longitude: 21.245556 },
  { name: 'XENIT', latitude: 44.326944, longitude: 20.8125 },
  { name: 'SIBAN', latitude: 44.333056, longitude: 38.965 },
  { name: 'BE406', latitude: 44.334389, longitude: 20.499556 },
  { name: 'BS072', latitude: 44.335544, longitude: 25.797447 },
  { name: 'PLANA', latitude: 44.3375, longitude: 21.038333 },
  { name: 'AMLONgitude', latitude: 44.339167, longitude: 12.876389 },
  { name: 'MINTU', latitude: 44.34, longitude: 14.695556 },
  { name: 'TEVRO', latitude: 44.346411, longitude: 25.502036 },
  { name: 'OLENA', latitude: 44.35, longitude: 36.633333 },
  { name: 'D305C', latitude: 44.352086, longitude: 19.825828 },
  { name: 'D236M', latitude: 44.3524, longitude: 18.475336 },
  { name: 'DAROJ', latitude: 44.352778, longitude: 19.102222 },
  { name: 'D277K', latitude: 44.353317, longitude: 23.671925 },
  { name: 'BS776', latitude: 44.354928, longitude: 26.308886 },
  { name: 'MADAV', latitude: 44.357072, longitude: 12.210144 },
  { name: 'LUKAV', latitude: 44.357306, longitude: 15.007417 },
  { name: 'D067O', latitude: 44.359047, longitude: 28.811197 },
  { name: 'TINPU', latitude: 44.361389, longitude: 28.171111 },
  { name: 'D194N', latitude: 44.361667, longitude: 37.901667 },
  { name: 'D180M', latitude: 44.362433, longitude: 37.976181 },
  { name: 'BE318', latitude: 44.362806, longitude: 20.798694 },
  { name: 'D181M', latitude: 44.362914, longitude: 37.971075 },
  { name: 'PEMOK', latitude: 44.364722, longitude: 24.348333 },
  { name: 'D177L', latitude: 44.366361, longitude: 37.992042 },
  { name: 'RULAB', latitude: 44.366389, longitude: 38.408333 },
  { name: 'MOLIK', latitude: 44.366389, longitude: 39.448333 },
  { name: 'OP889', latitude: 44.36655, longitude: 26.365614 },
  { name: 'BGD32', latitude: 44.366597, longitude: 20.710006 },
  { name: 'D178L', latitude: 44.366644, longitude: 37.987028 },
  { name: 'D181L', latitude: 44.367864, longitude: 37.972042 },
  { name: 'IPKIS', latitude: 44.368333, longitude: 14.300833 },
  { name: 'D303Q', latitude: 44.368531, longitude: 12.033603 },
  { name: 'CF01', latitude: 44.368861, longitude: 37.938408 },
  { name: 'LAKOV', latitude: 44.368889, longitude: 20.1575 },
  { name: 'D183L', latitude: 44.368989, longitude: 37.962106 },
  { name: 'D189M', latitude: 44.369031, longitude: 37.9308 },
  { name: 'ADNET', latitude: 44.369167, longitude: 39.84 },
  { name: 'SISGU', latitude: 44.369414, longitude: 25.464628 },
  { name: 'D174L', latitude: 44.37, longitude: 38.005 },
  { name: 'D161L', latitude: 44.37, longitude: 38.07 },
  { name: 'BOSNA', latitude: 44.370556, longitude: 17.759722 },
  { name: 'INBAK', latitude: 44.372222, longitude: 34.347222 },
  { name: 'D180L', latitude: 44.373333, longitude: 37.976667 },
  { name: 'VADNU', latitude: 44.373611, longitude: 28.877778 },
  { name: 'D346K', latitude: 44.374092, longitude: 12.021708 },
  { name: 'BE207', latitude: 44.374361, longitude: 20.018056 },
  { name: 'D195M', latitude: 44.374672, longitude: 37.900714 },
  { name: 'VP021', latitude: 44.375278, longitude: 18.730556 },
  { name: 'RATAR', latitude: 44.376111, longitude: 20.827222 },
  { name: 'TOBAG', latitude: 44.377217, longitude: 26.702764 },
  { name: 'BE167', latitude: 44.377333, longitude: 20.680139 },
  { name: 'D197M', latitude: 44.377544, longitude: 37.891236 },
  { name: 'DODIN', latitude: 44.378167, longitude: 20.686889 },
  { name: 'D193L', latitude: 44.378281, longitude: 37.913581 },
  { name: 'D205N', latitude: 44.378414, longitude: 37.845611 },
  { name: 'ARNAX', latitude: 44.378525, longitude: 25.829381 },
  { name: 'D317V', latitude: 44.379833, longitude: 15.042475 },
  { name: 'D195L', latitude: 44.380858, longitude: 37.904186 },
  { name: 'D189L', latitude: 44.381667, longitude: 37.936667 },
  { name: 'D207N', latitude: 44.382681, longitude: 37.836225 },
  { name: 'NAMUV', latitude: 44.383056, longitude: 28.083056 },
  { name: 'D137N', latitude: 44.383333, longitude: 38.203333 },
  { name: 'EBITA', latitude: 44.385, longitude: 14.769167 },
  { name: 'D161M', latitude: 44.385, longitude: 38.07 },
  { name: 'D198N', latitude: 44.385158, longitude: 37.890342 },
  { name: 'D167K', latitude: 44.385222, longitude: 38.039589 },
  { name: 'FN01', latitude: 44.385575, longitude: 37.933175 },
  { name: 'BS073', latitude: 44.385619, longitude: 26.037247 },
  { name: 'D165K', latitude: 44.386022, longitude: 38.048689 },
  { name: 'D200L', latitude: 44.388311, longitude: 37.881292 },
  { name: 'D197L', latitude: 44.388333, longitude: 37.896667 },
  { name: 'OLNOR', latitude: 44.388364, longitude: 26.321692 },
  { name: 'TPL34', latitude: 44.389869, longitude: 20.034339 },
  { name: 'DIGOT', latitude: 44.39, longitude: 15.667778 },
  { name: 'D181K', latitude: 44.39, longitude: 37.975 },
  { name: 'D184K', latitude: 44.391667, longitude: 37.963333 },
  { name: 'D183K', latitude: 44.391667, longitude: 37.966667 },
  { name: 'BE205', latitude: 44.393028, longitude: 20.688278 },
  { name: 'D208M', latitude: 44.399014, longitude: 37.842797 },
  { name: 'VAMON', latitude: 44.399444, longitude: 24.679722 },
  { name: 'SOTUB', latitude: 44.4, longitude: 37.2225 },
  { name: 'D204L', latitude: 44.4, longitude: 37.865 },
  { name: 'D193K', latitude: 44.4, longitude: 37.923333 },
  { name: 'D177J', latitude: 44.401667, longitude: 37.995 },
  { name: 'D176J', latitude: 44.401667, longitude: 38.001667 },
  { name: '70VOR', latitude: 44.403333, longitude: 28.49425 },
  { name: 'D210M', latitude: 44.403344, longitude: 37.834447 },
  { name: 'D197K', latitude: 44.405, longitude: 37.91 },
  { name: 'DITUM', latitude: 44.405833, longitude: 12.247222 },
  { name: 'D234F', latitude: 44.407658, longitude: 18.621561 },
  { name: 'CD02', latitude: 44.407806, longitude: 14.319944 },
  { name: 'BS074', latitude: 44.409261, longitude: 26.151458 },
  { name: 'CI01', latitude: 44.410903, longitude: 37.953067 },
  { name: 'ORVET', latitude: 44.411914, longitude: 25.657097 },
  { name: 'VARAM', latitude: 44.411917, longitude: 20.233944 },
  { name: 'LUPUK', latitude: 44.412778, longitude: 29.612778 },
  { name: 'D324O', latitude: 44.413569, longitude: 12.162122 },
  { name: 'BS075', latitude: 44.415411, longitude: 26.233333 },
  { name: 'BE317', latitude: 44.416194, longitude: 20.680639 },
  { name: 'BAXON', latitude: 44.416389, longitude: 13.463056 },
  { name: 'D242F', latitude: 44.417483, longitude: 18.606572 },
  { name: 'D189J', latitude: 44.418708, longitude: 37.950603 },
  { name: 'RANIS', latitude: 44.419722, longitude: 40.288333 },
  { name: 'BABIN', latitude: 44.425, longitude: 31.0 },
  { name: 'BS258', latitude: 44.426128, longitude: 26.233333 },
  { name: 'BE405', latitude: 44.426583, longitude: 20.380444 },
  { name: 'D211K', latitude: 44.426667, longitude: 37.855 },
  { name: 'D130L', latitude: 44.427417, longitude: 14.591806 },
  { name: 'D296P', latitude: 44.428081, longitude: 28.1641 },
  { name: 'D212K', latitude: 44.43, longitude: 37.85 },
  { name: 'BS071', latitude: 44.431539, longitude: 25.758239 },
  { name: 'CD27', latitude: 44.434131, longitude: 18.989389 },
  { name: 'DESEL', latitude: 44.435833, longitude: 32.659167 },
  { name: 'LUTUP', latitude: 44.436186, longitude: 26.516111 },
  { name: 'NEDID', latitude: 44.438944, longitude: 15.123722 },
  { name: 'ZD735', latitude: 44.439861, longitude: 14.894028 },
  { name: 'BS257', latitude: 44.44035, longitude: 26.302619 },
  { name: 'PU39B', latitude: 44.441694, longitude: 14.574778 },
  { name: 'OLNET', latitude: 44.442778, longitude: 23.675167 },
  { name: 'D337I', latitude: 44.442861, longitude: 14.513333 },
  { name: 'FD27', latitude: 44.444486, longitude: 18.873975 },
  { name: 'EBEKU', latitude: 44.445833, longitude: 28.124722 },
  { name: 'SONIK', latitude: 44.448333, longitude: 16.143333 },
  { name: 'D217J', latitude: 44.448919, longitude: 37.845664 },
  { name: 'BABAK', latitude: 44.449444, longitude: 25.774722 },
  { name: '33VOR', latitude: 44.450028, longitude: 18.811633 },
  { name: 'BGD41', latitude: 44.450242, longitude: 21.113739 },
  { name: 'ABRUT', latitude: 44.450256, longitude: 25.183167 },
  { name: 'BE166', latitude: 44.451222, longitude: 20.168694 },
  { name: 'D264R', latitude: 44.452483, longitude: 18.311936 },
  { name: 'BS552', latitude: 44.452969, longitude: 25.860503 },
  { name: 'D219J', latitude: 44.453147, longitude: 37.839736 },
  { name: 'MD27', latitude: 44.453706, longitude: 18.770067 },
  { name: 'ADULI', latitude: 44.455167, longitude: 25.869833 },
  { name: 'ESUXU', latitude: 44.458553, longitude: 26.435133 },
  { name: 'FD01', latitude: 44.458769, longitude: 37.966597 },
  { name: 'PANOL', latitude: 44.459056, longitude: 20.363361 },
  { name: 'FI01', latitude: 44.459406, longitude: 37.970008 },
  { name: 'LIBRA', latitude: 44.46, longitude: 27.74 },
  { name: 'BE206', latitude: 44.460333, longitude: 20.538833 },
  { name: 'MN09', latitude: 44.460414, longitude: 18.702322 },
  { name: 'FD18', latitude: 44.460972, longitude: 28.502083 },
  { name: 'MD09', latitude: 44.461617, longitude: 18.670619 },
  { name: 'MA09', latitude: 44.462344, longitude: 18.677742 },
  { name: 'BS551', latitude: 44.462789, longitude: 25.907528 },
  { name: 'PU39A', latitude: 44.464361, longitude: 14.604056 },
  { name: 'ITVUK', latitude: 44.466933, longitude: 26.550947 },
  { name: 'D022V', latitude: 44.467008, longitude: 20.974786 },
  { name: 'FD09', latitude: 44.467125, longitude: 18.596544 },
  { name: 'FI07', latitude: 44.468444, longitude: 25.934694 },
  { name: 'BACAM', latitude: 44.468611, longitude: 23.473889 },
  { name: 'NURPO', latitude: 44.468611, longitude: 29.315556 },
  { name: 'BE306', latitude: 44.469417, longitude: 20.562444 },
  { name: 'FF09', latitude: 44.469547, longitude: 18.585194 },
  { name: 'VP022', latitude: 44.469722, longitude: 16.840556 },
  { name: 'D336X', latitude: 44.470222, longitude: 15.169833 },
  { name: 'IDASI', latitude: 44.470278, longitude: 17.105278 },
  { name: 'IVDEK', latitude: 44.475197, longitude: 26.666797 },
  { name: 'PETOS', latitude: 44.476686, longitude: 25.454292 },
  { name: 'PIVEX', latitude: 44.477583, longitude: 19.964778 },
  { name: 'NALUD', latitude: 44.479722, longitude: 37.403611 },
  { name: 'D324T', latitude: 44.482517, longitude: 12.096794 },
  { name: 'DIMIT', latitude: 44.483833, longitude: 19.809139 },
  { name: 'D091O', latitude: 44.484608, longitude: 18.38845 },
  { name: 'VP315', latitude: 44.484722, longitude: 12.282222 },
  { name: 'NESKU', latitude: 44.486639, longitude: 20.464194 },
  { name: 'OP269', latitude: 44.487183, longitude: 25.737356 },
  { name: 'FD02', latitude: 44.488056, longitude: 14.355861 },
  { name: 'D278I', latitude: 44.489561, longitude: 18.535869 },
  { name: 'D091T', latitude: 44.493306, longitude: 18.272672 },
  { name: 'TITEK', latitude: 44.493333, longitude: 23.573889 },
  { name: 'LAVRO', latitude: 44.494139, longitude: 20.2685 },
  { name: 'MQ07', latitude: 44.497167, longitude: 26.073028 },
  { name: 'BE302', latitude: 44.500806, longitude: 20.755111 },
  { name: 'ROTBI', latitude: 44.501389, longitude: 25.943056 },
  { name: 'D182S', latitude: 44.501875, longitude: 20.255186 },
  { name: 'DIKER', latitude: 44.502736, longitude: 25.824572 },
  { name: 'BE204', latitude: 44.503056, longitude: 20.387139 },
  { name: 'PIGIN', latitude: 44.505194, longitude: 20.99725 },
  { name: 'GURSA', latitude: 44.507125, longitude: 26.121217 },
  { name: 'MQ25', latitude: 44.50975, longitude: 26.134 },
  { name: 'D338N', latitude: 44.509817, longitude: 28.388453 },
  { name: 'D307E', latitude: 44.510036, longitude: 18.652064 },
  { name: 'DIRAL', latitude: 44.510833, longitude: 27.554167 },
  { name: 'D311E', latitude: 44.511392, longitude: 18.661661 },
  { name: 'OP083', latitude: 44.517344, longitude: 25.474972 },
  { name: 'BE498', latitude: 44.518722, longitude: 20.488556 },
  { name: 'BS771', latitude: 44.519836, longitude: 26.285064 },
  { name: 'BE305', latitude: 44.522528, longitude: 20.444028 },
  { name: 'MD01', latitude: 44.522861, longitude: 37.992231 },
  { name: 'SONOB', latitude: 44.523056, longitude: 25.766111 },
  { name: 'MI01U', latitude: 44.523914, longitude: 37.992581 },
  { name: 'MI01W', latitude: 44.523914, longitude: 37.992581 },
  { name: 'MI01Z', latitude: 44.523914, longitude: 37.992581 },
  { name: 'BS772', latitude: 44.524117, longitude: 26.343014 },
  { name: 'LEKPI', latitude: 44.524722, longitude: 39.478333 },
  { name: 'D251N', latitude: 44.525, longitude: 37.691667 },
  { name: 'OP082', latitude: 44.526697, longitude: 25.590742 },
  { name: 'OP268', latitude: 44.528019, longitude: 26.27015 },
  { name: 'RAVNA', latitude: 44.530278, longitude: 14.858333 },
  { name: 'VP034', latitude: 44.530833, longitude: 14.470833 },
  { name: 'GUDIV', latitude: 44.530833, longitude: 25.643889 },
  { name: 'AMODA', latitude: 44.53175, longitude: 26.501208 },
  { name: 'BUSOK', latitude: 44.532436, longitude: 26.600914 },
  { name: 'RUGAN', latitude: 44.533056, longitude: 37.748333 },
  { name: 'VASER', latitude: 44.533556, longitude: 20.636861 },
  { name: 'RIRUX', latitude: 44.533889, longitude: 26.068333 },
  { name: 'BOMKI', latitude: 44.535, longitude: 32.03 },
  { name: 'MASEB', latitude: 44.535739, longitude: 25.758194 },
  { name: 'OP081', latitude: 44.535933, longitude: 25.70655 },
  { name: 'UNUSU', latitude: 44.536389, longitude: 26.245833 },
  { name: 'ASNEL', latitude: 44.536667, longitude: 26.33 },
  { name: 'ZD734', latitude: 44.537583, longitude: 14.966583 },
  { name: 'FI25', latitude: 44.538472, longitude: 26.273722 },
  { name: 'OVDOT', latitude: 44.538889, longitude: 22.976944 },
  { name: 'ABGED', latitude: 44.538972, longitude: 25.745111 },
  { name: 'BS773', latitude: 44.539561, longitude: 26.55545 },
  { name: 'DEXUD', latitude: 44.540278, longitude: 25.623611 },
  { name: 'BE132', latitude: 44.541306, longitude: 20.665278 },
  { name: 'VP023', latitude: 44.541389, longitude: 18.71 },
  { name: 'BARUK', latitude: 44.541389, longitude: 28.569722 },
  { name: 'OP666', latitude: 44.541972, longitude: 25.783075 },
  { name: 'BELOV', latitude: 44.5425, longitude: 12.713333 },
  { name: 'MD02', latitude: 44.542722, longitude: 14.380361 },
  { name: 'NIGAB', latitude: 44.543222, longitude: 26.493458 },
  { name: 'MI01Y', latitude: 44.543311, longitude: 37.999383 },
  { name: 'MI01X', latitude: 44.543311, longitude: 37.999383 },
  { name: 'MI01V', latitude: 44.543311, longitude: 37.999383 },
  { name: 'MD012', latitude: 44.543689, longitude: 38.000575 },
  { name: 'ABATU', latitude: 44.544725, longitude: 25.674753 },
  { name: 'OBELA', latitude: 44.545433, longitude: 25.827281 },
  { name: 'LILGU', latitude: 44.545833, longitude: 26.377778 },
  { name: 'AMSIT', latitude: 44.5475, longitude: 26.590833 },
  { name: 'BS774', latitude: 44.547831, longitude: 26.671567 },
  { name: 'NAVSU', latitude: 44.548333, longitude: 16.901389 },
  { name: 'BE497', latitude: 44.549361, longitude: 20.361889 },
  { name: 'OP664', latitude: 44.55065, longitude: 25.894222 },
  { name: 'BAKOB', latitude: 44.550833, longitude: 28.514444 },
  { name: 'FF08R', latitude: 44.551444, longitude: 25.904333 },
  { name: 'TPL27', latitude: 44.551661, longitude: 21.029892 },
  { name: 'ABORA', latitude: 44.552203, longitude: 26.340822 },
  { name: 'MIVNU', latitude: 44.553611, longitude: 28.289722 },
  { name: 'BE303', latitude: 44.554139, longitude: 20.636806 },
  { name: 'OP665', latitude: 44.555331, longitude: 25.807875 },
  { name: 'MIHAL', latitude: 44.556111, longitude: 20.984167 },
  { name: 'DILAS', latitude: 44.556139, longitude: 25.820694 },
  { name: 'PUL31', latitude: 44.556189, longitude: 14.476189 },
  { name: 'HYDRA', latitude: 44.556667, longitude: 27.616667 },
  { name: 'BGD27', latitude: 44.557747, longitude: 20.809969 },
  { name: 'BS251', latitude: 44.558075, longitude: 26.420969 },
  { name: 'OP662', latitude: 44.559583, longitude: 26.010133 },
  { name: 'OP663', latitude: 44.561928, longitude: 25.892511 },
  { name: 'MQ08R', latitude: 44.56325, longitude: 26.058028 },
  { name: 'FF08L', latitude: 44.564722, longitude: 25.928694 },
  { name: 'MN300', latitude: 44.565989, longitude: 14.392131 },
  { name: 'BS252', latitude: 44.566469, longitude: 26.536978 },
  { name: 'PETAV', latitude: 44.569167, longitude: 26.411667 },
  { name: 'D085J', latitude: 44.569181, longitude: 14.624164 },
  { name: 'MQ26L', latitude: 44.569389, longitude: 26.139278 },
  { name: 'BURUD', latitude: 44.57, longitude: 34.64 },
  { name: 'OP661', latitude: 44.570858, longitude: 26.008444 },
  { name: 'D262Q', latitude: 44.571244, longitude: 26.164861 },
  { name: 'DIMSI', latitude: 44.571667, longitude: 19.18 },
  { name: 'DIBAT', latitude: 44.573056, longitude: 37.258333 },
  { name: 'OP882', latitude: 44.573594, longitude: 26.195189 },
  { name: 'BS253', latitude: 44.574747, longitude: 26.653022 },
  { name: 'PALEZ', latitude: 44.575, longitude: 15.533056 },
  { name: 'VP035', latitude: 44.575833, longitude: 14.316111 },
  { name: 'PITIS', latitude: 44.576389, longitude: 28.517222 },
  { name: 'D291Q', latitude: 44.578358, longitude: 18.380222 },
  { name: 'BARAV', latitude: 44.579167, longitude: 20.413056 },
  { name: 'SORUR', latitude: 44.579444, longitude: 25.899167 },
  { name: 'UDRIB', latitude: 44.579722, longitude: 25.925278 },
  { name: 'FF26L', latitude: 44.580944, longitude: 26.293944 },
  { name: 'D264Q', latitude: 44.582614, longitude: 26.163994 },
  { name: 'TOPAL', latitude: 44.583333, longitude: 27.963333 },
  { name: 'OP884', latitude: 44.583933, longitude: 26.3344 },
  { name: 'OTKIN', latitude: 44.584472, longitude: 20.825667 },
  { name: 'OP881', latitude: 44.584869, longitude: 26.193536 },
  { name: 'BE301', latitude: 44.585389, longitude: 20.829806 },
  { name: 'BE203', latitude: 44.585556, longitude: 20.8295 },
  { name: 'KK206', latitude: 44.587889, longitude: 39.495611 },
  { name: 'TPL27', latitude: 44.588019, longitude: 20.683375 },
  { name: 'ETOBI', latitude: 44.589444, longitude: 16.915833 },
  { name: 'LEVTA', latitude: 44.589444, longitude: 26.409594 },
  { name: 'KN46A', latitude: 44.589967, longitude: 40.066972 },
  { name: 'KK511', latitude: 44.590278, longitude: 40.066611 },
  { name: 'FF26R', latitude: 44.591611, longitude: 26.284056 },
  { name: 'D309L', latitude: 44.591725, longitude: 18.535478 },
  { name: 'NATAD', latitude: 44.59175, longitude: 20.682861 },
  { name: 'BE499', latitude: 44.593333, longitude: 20.57225 },
  { name: 'SOTAM', latitude: 44.593333, longitude: 32.933333 },
  { name: 'D118Y', latitude: 44.594136, longitude: 20.794342 },
  { name: 'OP883', latitude: 44.595208, longitude: 26.332775 },
  { name: 'SOMUN', latitude: 44.596389, longitude: 19.105 },
  { name: 'OP261', latitude: 44.59835, longitude: 26.532494 },
  { name: 'GOTOB', latitude: 44.598611, longitude: 26.2675 },
  { name: 'BE500', latitude: 44.59875, longitude: 20.455833 },
  { name: 'D036V', latitude: 44.600119, longitude: 20.211381 },
  { name: 'BEGAL', latitude: 44.600483, longitude: 25.771939 },
  { name: 'RARIT', latitude: 44.601217, longitude: 26.414811 },
  { name: 'SAVEN', latitude: 44.601667, longitude: 27.538333 },
  { name: 'VP036', latitude: 44.602778, longitude: 14.498611 },
  { name: 'LOPLI', latitude: 44.605833, longitude: 19.223889 },
  { name: 'D065G', latitude: 44.606083, longitude: 14.532167 },
  { name: 'BE165', latitude: 44.606194, longitude: 20.530667 },
  { name: 'OP262', latitude: 44.606631, longitude: 26.6486 },
  { name: 'BE304', latitude: 44.607333, longitude: 20.518278 },
  { name: 'OGUPI', latitude: 44.608889, longitude: 26.072222 },
  { name: 'OP084', latitude: 44.611431, longitude: 25.46 },
  { name: 'LIVGA', latitude: 44.611831, longitude: 26.410986 },
  { name: 'SUSUR', latitude: 44.613061, longitude: 26.743094 },
  { name: 'D274N', latitude: 44.615, longitude: 37.69 },
  { name: 'ENUPU', latitude: 44.616944, longitude: 19.362778 },
  { name: 'OP888', latitude: 44.618522, longitude: 26.818239 },
  { name: 'LOVIS', latitude: 44.619489, longitude: 26.669483 },
  { name: 'DILIM', latitude: 44.620286, longitude: 26.843806 },
  { name: 'OP085', latitude: 44.6208, longitude: 25.575953 },
  { name: 'VP003', latitude: 44.620833, longitude: 21.147778 },
  { name: 'RIDPA', latitude: 44.621964, longitude: 26.487208 },
  { name: 'RASAR', latitude: 44.623889, longitude: 25.745278 },
  { name: 'BE309', latitude: 44.628389, longitude: 20.2065 },
  { name: 'OP086', latitude: 44.630056, longitude: 25.691947 },
  { name: 'RIPAN', latitude: 44.630556, longitude: 20.471556 },
  { name: 'DOROL', latitude: 44.631389, longitude: 37.561667 },
  { name: 'OP887', latitude: 44.631811, longitude: 26.845894 },
  { name: 'MARIL', latitude: 44.633333, longitude: 17.333333 },
  { name: 'OBUDO', latitude: 44.634444, longitude: 26.186667 },
  { name: 'RENBA', latitude: 44.635, longitude: 34.023333 },
  { name: 'D110Q', latitude: 44.636111, longitude: 14.131944 },
  { name: 'DINBA', latitude: 44.637222, longitude: 28.526111 },
  { name: 'OP087', latitude: 44.639192, longitude: 25.807981 },
  { name: 'PLATitudeO', latitude: 44.64, longitude: 27.77 },
  { name: 'IDARU', latitude: 44.640142, longitude: 26.9818 },
  { name: 'LUROD', latitude: 44.641272, longitude: 26.545411 },
  { name: 'OSNIR', latitude: 44.642778, longitude: 38.283889 },
  { name: 'OSNIP', latitude: 44.643056, longitude: 19.258056 },
  { name: 'RUKAT', latitude: 44.643056, longitude: 26.161111 },
  { name: 'OP267', latitude: 44.644172, longitude: 25.871878 },
  { name: 'BE208', latitude: 44.644944, longitude: 19.876556 },
  { name: 'RIVOS', latitude: 44.655833, longitude: 27.654722 },
  { name: 'D132M', latitude: 44.657761, longitude: 20.522044 },
  { name: 'BE157', latitude: 44.659361, longitude: 21.106083 },
  { name: 'BE133', latitude: 44.659361, longitude: 21.106083 },
  { name: 'BE404', latitude: 44.66025, longitude: 19.778889 },
  { name: 'BE307', latitude: 44.660389, longitude: 20.399528 },
  { name: 'BE163', latitude: 44.661472, longitude: 20.660917 },
  { name: 'INDOR', latitude: 44.662569, longitude: 26.112144 },
  { name: 'BE134', latitude: 44.666111, longitude: 20.166389 },
  { name: 'BE168', latitude: 44.667167, longitude: 20.305139 },
  { name: 'D315P', latitude: 44.667864, longitude: 25.713211 },
  { name: 'KUDUL', latitude: 44.669722, longitude: 15.065278 },
  { name: 'BE312', latitude: 44.669917, longitude: 20.904722 },
  { name: 'GIKUN', latitude: 44.670278, longitude: 23.636389 },
  { name: 'CHI27', latitude: 44.670525, longitude: 12.019878 },
  { name: 'YEZAV', latitude: 44.671111, longitude: 20.918611 },
  { name: 'BE333', latitude: 44.671278, longitude: 19.731806 },
  { name: 'PL803', latitude: 44.671694, longitude: 14.291444 },
  { name: 'GOKAR', latitude: 44.673333, longitude: 20.715833 },
  { name: 'BE403', latitude: 44.675111, longitude: 20.056694 },
  { name: 'ALINO', latitude: 44.675556, longitude: 34.397778 },
  { name: 'D053L', latitude: 44.675972, longitude: 14.625389 },
  { name: 'BE202', latitude: 44.678278, longitude: 20.623444 },
  { name: 'DEPET', latitude: 44.678889, longitude: 15.969444 },
  { name: 'ARMOX', latitude: 44.680556, longitude: 26.375833 },
  { name: 'TPL36', latitude: 44.681106, longitude: 21.121361 },
  { name: 'OP265', latitude: 44.684147, longitude: 26.40295 },
  { name: 'KUPIN', latitude: 44.684944, longitude: 20.087028 },
  { name: 'OBISA', latitude: 44.685061, longitude: 18.395622 },
  { name: 'VP038', latitude: 44.689444, longitude: 14.279444 },
  { name: 'IPRAS', latitude: 44.689758, longitude: 26.480289 },
  { name: 'VISOK', latitude: 44.69125, longitude: 20.592972 },
  { name: 'DUBRA', latitude: 44.691944, longitude: 21.07 },
  { name: 'D118N', latitude: 44.691953, longitude: 20.585492 },
  { name: 'OP264', latitude: 44.692564, longitude: 26.519211 },
  { name: 'VP039', latitude: 44.693611, longitude: 14.393333 },
  { name: 'BE501', latitude: 44.694222, longitude: 20.416667 },
  { name: 'NETUL', latitude: 44.695383, longitude: 26.978564 },
  { name: 'CETUL', latitude: 44.6975, longitude: 28.626944 },
  { name: 'PL613', latitude: 44.70025, longitude: 14.277333 },
  { name: 'BS775', latitude: 44.700444, longitude: 26.317569 },
  { name: 'BE201', latitude: 44.700778, longitude: 20.57325 },
  { name: 'BOGVI', latitude: 44.700858, longitude: 26.635503 },
  { name: 'ULPIN', latitude: 44.703611, longitude: 14.653889 },
  { name: 'DEXIM', latitude: 44.709189, longitude: 26.753983 },
  { name: 'D180K', latitude: 44.709444, longitude: 13.899722 },
  { name: 'EVTON', latitude: 44.709722, longitude: 16.958056 },
  { name: 'D312L', latitude: 44.710167, longitude: 14.196222 },
  { name: 'UVALU', latitude: 44.710803, longitude: 25.444128 },
  { name: 'TUREL', latitude: 44.712222, longitude: 30.169444 },
  { name: 'BUNIR', latitude: 44.712925, longitude: 25.844153 },
  { name: 'VEVIN', latitude: 44.713056, longitude: 26.651944 },
  { name: 'BK402', latitude: 44.713183, longitude: 17.513028 },
  { name: 'BE308', latitude: 44.713333, longitude: 20.280528 },
  { name: 'TUNOT', latitude: 44.716389, longitude: 37.398333 },
  { name: 'PEXAL', latitude: 44.7167, longitude: 26.091383 },
  { name: 'IRGOR', latitude: 44.722778, longitude: 20.776667 },
  { name: 'BE313', latitude: 44.723417, longitude: 20.786167 },
  { name: 'LEMPA', latitude: 44.726944, longitude: 28.435278 },
  { name: 'TUPKO', latitude: 44.7275, longitude: 20.513528 },
  { name: 'BE140', latitude: 44.729389, longitude: 20.203944 },
  { name: 'PL716', latitude: 44.729944, longitude: 14.070556 },
  { name: 'BKI35', latitude: 44.732628, longitude: 17.351683 },
  { name: 'BAVAN', latitude: 44.733333, longitude: 21.127778 },
  { name: 'VP040', latitude: 44.735278, longitude: 13.896389 },
  { name: 'AKIBO', latitude: 44.736056, longitude: 19.737111 },
  { name: 'BE30F', latitude: 44.736833, longitude: 20.492611 },
  { name: 'OP088', latitude: 44.738619, longitude: 25.792694 },
  { name: 'KEB49', latitude: 44.739525, longitude: 18.605286 },
  { name: 'VAL53', latitude: 44.74045, longitude: 20.968936 },
  { name: 'SMI35', latitude: 44.740786, longitude: 20.164175 },
  { name: 'MAKIS', latitude: 44.742778, longitude: 20.381667 },
  { name: 'PL403', latitude: 44.743472, longitude: 13.915389 },
  { name: 'FD30', latitude: 44.744167, longitude: 20.476972 },
  { name: 'UTOKI', latitude: 44.744233, longitude: 25.864753 },
  { name: 'D095Z', latitude: 44.745469, longitude: 20.910658 },
  { name: 'D107X', latitude: 44.748611, longitude: 14.439722 },
  { name: 'DONIV', latitude: 44.748889, longitude: 21.169444 },
  { name: 'D103M', latitude: 44.750667, longitude: 20.606208 },
  { name: 'TESTU', latitude: 44.751617, longitude: 26.069597 },
  { name: 'PL502', latitude: 44.751806, longitude: 13.941972 },
  { name: 'YAKOV', latitude: 44.752222, longitude: 20.248611 },
  { name: 'OP667', latitude: 44.753197, longitude: 25.981044 },
  { name: 'TOSVI', latitude: 44.753892, longitude: 25.161311 },
  { name: 'RISTU', latitude: 44.755833, longitude: 20.028056 },
  { name: 'BEC01', latitude: 44.761267, longitude: 20.329044 },
  { name: 'BANVI', latitude: 44.762222, longitude: 20.470833 },
  { name: 'UBOGU', latitude: 44.763056, longitude: 24.668333 },
  { name: 'BE131', latitude: 44.766083, longitude: 20.161389 },
  { name: 'PL705', latitude: 44.766778, longitude: 13.507306 },
  { name: 'OP890', latitude: 44.767256, longitude: 26.166711 },
  { name: 'D140P', latitude: 44.768236, longitude: 18.181475 },
  { name: 'BE161', latitude: 44.7695, longitude: 20.419306 },
  { name: 'PEPIM', latitude: 44.769722, longitude: 13.624167 },
  { name: 'KOREX', latitude: 44.771111, longitude: 15.769167 },
  { name: 'PL612', latitude: 44.771167, longitude: 13.685083 },
  { name: 'D107T', latitude: 44.771667, longitude: 14.356667 },
  { name: 'ZILOV', latitude: 44.771667, longitude: 21.193333 },
  { name: 'VELIP', latitude: 44.771667, longitude: 21.533333 },
  { name: 'PL707', latitude: 44.772528, longitude: 13.741028 },
  { name: 'PL904', latitude: 44.772611, longitude: 13.759056 },
  { name: 'BE502', latitude: 44.772833, longitude: 20.384306 },
  { name: 'DOBOT', latitude: 44.773889, longitude: 17.9025 },
  { name: 'PL708', latitude: 44.775222, longitude: 13.857917 },
  { name: 'KK214', latitude: 44.775861, longitude: 39.823111 },
  { name: 'BE314', latitude: 44.77675, longitude: 20.667417 },
  { name: '40THR', latitude: 44.776947, longitude: 20.402592 },
  { name: 'OM30', latitude: 44.777306, longitude: 20.402722 },
  { name: 'PL709', latitude: 44.777778, longitude: 13.974833 },
  { name: 'PADGU', latitude: 44.778611, longitude: 22.610556 },
  { name: 'PL611', latitude: 44.779, longitude: 14.040278 },
  { name: 'PL905', latitude: 44.780083, longitude: 14.093694 },
  { name: 'GORER', latitude: 44.780833, longitude: 26.08 },
  { name: 'BABOL', latitude: 44.781142, longitude: 18.622783 },
  { name: 'PL610', latitude: 44.781333, longitude: 14.157194 },
  { name: 'PL710', latitude: 44.781472, longitude: 14.152861 },
  { name: 'RUSUS', latitude: 44.781944, longitude: 33.5925 },
  { name: 'BE171', latitude: 44.782333, longitude: 20.080861 },
  { name: 'LANIR', latitude: 44.783556, longitude: 14.274139 },
  { name: 'D315R', latitude: 44.784842, longitude: 25.716706 },
  { name: 'LOGAR', latitude: 44.785444, longitude: 19.963139 },
  { name: 'PL605', latitude: 44.786139, longitude: 14.420528 },
  { name: 'D118D', latitude: 44.787333, longitude: 20.379667 },
  { name: 'BG04B', latitude: 44.788028, longitude: 20.380306 },
  { name: 'BG04A', latitude: 44.789, longitude: 20.381139 },
  { name: 'PL402', latitude: 44.789306, longitude: 13.758806 },
  { name: 'KA008', latitude: 44.791444, longitude: 37.267917 },
  { name: 'SIERA', latitude: 44.795, longitude: 20.292222 },
  { name: 'BE503', latitude: 44.796083, longitude: 20.359528 },
  { name: 'OKAGO', latitude: 44.796883, longitude: 26.572103 },
  { name: 'ARDAM', latitude: 44.797778, longitude: 12.505833 },
  { name: 'D250V', latitude: 44.800506, longitude: 13.920369 },
  { name: 'BEC02', latitude: 44.801275, longitude: 20.170625 },
  { name: 'ETOGO', latitude: 44.803372, longitude: 26.663656 },
  { name: 'VP041', latitude: 44.803889, longitude: 13.9975 },
  { name: 'MA30', latitude: 44.806028, longitude: 20.340889 },
  { name: 'MM30', latitude: 44.806889, longitude: 20.335139 },
  { name: 'URULA', latitude: 44.809722, longitude: 38.631667 },
  { name: 'D315S', latitude: 44.8101, longitude: 25.717478 },
  { name: 'D129Q', latitude: 44.811144, longitude: 19.718761 },
  { name: 'DOBAN', latitude: 44.811389, longitude: 20.220833 },
  { name: 'VP024', latitude: 44.812222, longitude: 16.900556 },
  { name: 'OGATA', latitude: 44.8125, longitude: 30.130556 },
  { name: 'D136J', latitude: 44.812931, longitude: 17.444806 },
  { name: 'BKF35', latitude: 44.814569, longitude: 17.330453 },
  { name: 'PERUN', latitude: 44.815, longitude: 31.838333 },
  { name: 'BE402', latitude: 44.816861, longitude: 19.733889 },
  { name: 'D133J', latitude: 44.817314, longitude: 17.452058 },
  { name: 'BE130', latitude: 44.818778, longitude: 20.041972 },
  { name: 'D242K', latitude: 44.818889, longitude: 13.681944 },
  { name: 'OP891', latitude: 44.821308, longitude: 26.044072 },
  { name: 'PILATitude', latitude: 44.823889, longitude: 28.097778 },
  { name: 'ETUXA', latitude: 44.826372, longitude: 26.2993 },
  { name: 'MQ12', latitude: 44.831667, longitude: 20.280583 },
  { name: 'MM12', latitude: 44.831806, longitude: 20.279111 },
  { name: 'MD12', latitude: 44.8325, longitude: 20.281278 },
  { name: 'TURIR', latitude: 44.832833, longitude: 28.656194 },
  { name: 'PAPPA', latitude: 44.833056, longitude: 20.309167 },
  { name: 'VP004', latitude: 44.835833, longitude: 20.114722 },
  { name: 'OKSIG', latitude: 44.836944, longitude: 17.003056 },
  { name: 'VEDAP', latitude: 44.837786, longitude: 25.718369 },
  { name: 'BE106', latitude: 44.838528, longitude: 19.728917 },
  { name: 'D296C', latitude: 44.845056, longitude: 20.250861 },
  { name: 'IXONA', latitude: 44.845556, longitude: 13.548889 },
  { name: 'KK502', latitude: 44.845556, longitude: 38.842222 },
  { name: 'BGD03', latitude: 44.846306, longitude: 20.251944 },
  { name: 'SUBOT', latitude: 44.846667, longitude: 19.964167 },
  { name: 'D121Q', latitude: 44.847069, longitude: 19.744156 },
  { name: 'BE158', latitude: 44.847944, longitude: 19.745556 },
  { name: 'PL501', latitude: 44.851333, longitude: 14.073833 },
  { name: 'BS553', latitude: 44.851489, longitude: 25.353264 },
  { name: 'VP042', latitude: 44.855556, longitude: 14.386944 },
  { name: 'DAVOS', latitude: 44.856111, longitude: 21.300833 },
  { name: 'BE170', latitude: 44.857917, longitude: 20.220056 },
  { name: 'KA007', latitude: 44.858528, longitude: 37.163917 },
  { name: 'KK501', latitude: 44.859444, longitude: 39.004472 },
  { name: 'OM12', latitude: 44.862472, longitude: 20.209806 },
  { name: 'KA012', latitude: 44.865028, longitude: 37.532278 },
  { name: 'GOMDI', latitude: 44.865081, longitude: 25.944367 },
  { name: 'D090V', latitude: 44.866194, longitude: 14.422083 },
  { name: 'SODGO', latitude: 44.867222, longitude: 22.8475 },
  { name: 'D161M', latitude: 44.867578, longitude: 28.778031 },
  { name: 'MARIN', latitude: 44.868333, longitude: 20.761389 },
  { name: 'BE331', latitude: 44.8685, longitude: 20.196083 },
  { name: 'BE150', latitude: 44.869167, longitude: 20.194722 },
  { name: 'BE104', latitude: 44.871333, longitude: 19.922361 },
  { name: 'KK211', latitude: 44.873083, longitude: 39.138917 },
  { name: 'VAL34', latitude: 44.876633, longitude: 20.026739 },
  { name: 'VP005', latitude: 44.877222, longitude: 20.455833 },
  { name: 'KK507', latitude: 44.877889, longitude: 38.959028 },
  { name: 'KA019', latitude: 44.879778, longitude: 37.190972 },
  { name: 'VP025', latitude: 44.880556, longitude: 18.425278 },
  { name: 'KK208', latitude: 44.880889, longitude: 38.957278 },
  { name: 'BE209', latitude: 44.882472, longitude: 19.579028 },
  { name: 'ABNEK', latitude: 44.883056, longitude: 25.415278 },
  { name: 'D027H', latitude: 44.883131, longitude: 38.904272 },
  { name: 'PL704', latitude: 44.883694, longitude: 13.5015 },
  { name: 'GEKSI', latitude: 44.886583, longitude: 13.618583 },
  { name: 'KND46', latitude: 44.887028, longitude: 38.106075 },
  { name: 'GELKO', latitude: 44.889361, longitude: 13.735694 },
  { name: 'ZELMO', latitude: 44.889722, longitude: 20.616111 },
  { name: 'BE107', latitude: 44.890472, longitude: 19.609667 },
  { name: 'MD09', latitude: 44.891944, longitude: 13.894694 },
  { name: 'D283O', latitude: 44.893686, longitude: 19.987356 },
  { name: 'D296I', latitude: 44.8939, longitude: 20.136322 },
  { name: 'MM27', latitude: 44.894217, longitude: 13.953589 },
  { name: '38VOR', latitude: 44.894444, longitude: 14.008278 },
  { name: '17THR', latitude: 44.894772, longitude: 13.980631 },
  { name: 'D189O', latitude: 44.895344, longitude: 21.221008 },
  { name: 'OM27', latitude: 44.895356, longitude: 14.008081 },
  { name: 'FD27', latitude: 44.896667, longitude: 14.110278 },
  { name: 'BE310', latitude: 44.896889, longitude: 19.864111 },
  { name: 'FQ27Y', latitude: 44.897361, longitude: 14.110222 },
  { name: 'SITPA', latitude: 44.897417, longitude: 14.11025 },
  { name: 'FI27', latitude: 44.897417, longitude: 14.11025 },
  { name: 'FN268', latitude: 44.897642, longitude: 20.479103 },
  { name: 'CI27', latitude: 44.898342, longitude: 14.157014 },
  { name: 'TUVEK', latitude: 44.898733, longitude: 28.670431 },
  { name: 'BE12F', latitude: 44.899306, longitude: 20.126278 },
  { name: 'VAL35', latitude: 44.899522, longitude: 20.0331 },
  { name: 'PU15A', latitude: 44.9, longitude: 14.269667 },
  { name: 'FD12', latitude: 44.900194, longitude: 20.125194 },
  { name: 'PU15B', latitude: 44.900333, longitude: 14.269583 },
  { name: 'PL401', latitude: 44.900472, longitude: 14.269639 },
  { name: 'PU15C', latitude: 44.900472, longitude: 14.269639 },
  { name: 'KK510', latitude: 44.901472, longitude: 39.214861 },
  { name: 'CRKVA', latitude: 44.901944, longitude: 21.37 },
  { name: 'KK209', latitude: 44.903778, longitude: 38.486389 },
  { name: 'LOBSI', latitude: 44.905, longitude: 12.1725 },
  { name: 'BALBI', latitude: 44.905, longitude: 25.754167 },
  { name: 'CF05R', latitude: 44.905969, longitude: 38.952642 },
  { name: 'D097L', latitude: 44.906447, longitude: 13.637442 },
  { name: 'KK504', latitude: 44.906528, longitude: 38.953556 },
  { name: 'DANUL', latitude: 44.906694, longitude: 28.456278 },
  { name: 'CF04', latitude: 44.907006, longitude: 37.225714 },
  { name: 'PL604', latitude: 44.908833, longitude: 14.800889 },
  { name: 'D187N', latitude: 44.909572, longitude: 21.238514 },
  { name: 'VAKUP', latitude: 44.910069, longitude: 26.300447 },
  { name: 'FN04Y', latitude: 44.911939, longitude: 37.232778 },
  { name: 'ROPTA', latitude: 44.912222, longitude: 39.928333 },
  { name: 'KURAD', latitude: 44.914722, longitude: 40.583333 },
  { name: 'VP026', latitude: 44.915556, longitude: 15.990833 },
  { name: 'PL717', latitude: 44.916361, longitude: 14.800694 },
  { name: 'GOREP', latitude: 44.918333, longitude: 12.099722 },
  { name: 'KA020', latitude: 44.919444, longitude: 37.113333 },
  { name: 'OBALA', latitude: 44.920278, longitude: 14.9725 },
  { name: 'EKSUN', latitude: 44.920481, longitude: 26.285589 },
  { name: 'SOVUB', latitude: 44.920556, longitude: 12.018889 },
  { name: 'BIVBU', latitude: 44.920556, longitude: 24.331667 },
  { name: 'LABUD', latitude: 44.921389, longitude: 20.076139 },
  { name: 'BE103', latitude: 44.923778, longitude: 19.802528 },
  { name: 'KA009', latitude: 44.925528, longitude: 37.059667 },
  { name: 'MALUX', latitude: 44.926778, longitude: 19.661306 },
  { name: 'BIMSI', latitude: 44.928444, longitude: 14.665083 },
  { name: 'FU04', latitude: 44.930972, longitude: 37.256939 },
  { name: 'KA006', latitude: 44.931083, longitude: 37.256417 },
  { name: 'FF04', latitude: 44.931111, longitude: 37.256389 },
  { name: 'DABAR', latitude: 44.932222, longitude: 15.270278 },
  { name: 'GINEP', latitude: 44.933056, longitude: 37.813333 },
  { name: 'KK212', latitude: 44.939611, longitude: 39.014111 },
  { name: 'DESUX', latitude: 44.939669, longitude: 25.834786 },
  { name: 'CI05R', latitude: 44.940925, longitude: 39.0166 },
  { name: 'SUSAR', latitude: 44.941111, longitude: 21.126389 },
  { name: 'VP027', latitude: 44.941667, longitude: 17.409444 },
  { name: 'RASTU', latitude: 44.942222, longitude: 15.743333 },
  { name: 'BE108', latitude: 44.942556, longitude: 19.4895 },
  { name: 'D121L', latitude: 44.942906, longitude: 13.645792 },
  { name: 'D238K', latitude: 44.943789, longitude: 38.920956 },
  { name: 'CESKO', latitude: 44.946111, longitude: 21.369722 },
  { name: 'KND49', latitude: 44.947417, longitude: 38.018081 },
  { name: 'CIGLA', latitude: 44.948611, longitude: 20.740278 },
  { name: 'YABUK', latitude: 44.950556, longitude: 20.603611 },
  { name: 'D062U', latitude: 44.952442, longitude: 20.756319 },
  { name: 'KA014', latitude: 44.952444, longitude: 37.283722 },
  { name: 'D105J', latitude: 44.953356, longitude: 19.647114 },
  { name: 'PELOV', latitude: 44.954167, longitude: 20.861111 },
  { name: 'VP043', latitude: 44.955, longitude: 13.955833 },
  { name: 'DEVDA', latitude: 44.955889, longitude: 19.996167 },
  { name: 'VP028', latitude: 44.956111, longitude: 17.169444 },
  { name: 'D292R', latitude: 44.957172, longitude: 19.928464 },
  { name: 'KK210', latitude: 44.957833, longitude: 39.0475 },
  { name: 'KOMAR', latitude: 44.958056, longitude: 16.804167 },
  { name: 'D150C', latitude: 44.958094, longitude: 19.464447 },
  { name: 'VP044', latitude: 44.959444, longitude: 13.851667 },
  { name: 'KA016', latitude: 44.960278, longitude: 37.035806 },
  { name: 'MM16', latitude: 44.961039, longitude: 17.292347 },
  { name: 'KK505', latitude: 44.961083, longitude: 39.0535 },
  { name: 'FF34', latitude: 44.961139, longitude: 28.746139 },
  { name: 'FI05R', latitude: 44.961228, longitude: 39.053822 },
  { name: 'FD05R', latitude: 44.961744, longitude: 39.053394 },
  { name: 'BK404', latitude: 44.963408, longitude: 17.554258 },
  { name: 'D033K', latitude: 44.964278, longitude: 20.470769 },
  { name: 'D289K', latitude: 44.964444, longitude: 13.680833 },
  { name: 'TIVNU', latitude: 44.964722, longitude: 17.059444 },
  { name: 'MONID', latitude: 44.9675, longitude: 16.305833 },
  { name: 'SASIN', latitude: 44.9675, longitude: 19.744444 },
  { name: 'BE159', latitude: 44.973889, longitude: 19.956528 },
  { name: 'FS05R', latitude: 44.974319, longitude: 39.076839 },
  { name: 'MD16', latitude: 44.974656, longitude: 17.289447 },
  { name: 'D187J', latitude: 44.974786, longitude: 21.257961 },
  { name: 'BE102', latitude: 44.975778, longitude: 19.683139 },
  { name: 'D189J', latitude: 44.976172, longitude: 21.249519 },
  { name: 'OSDOR', latitude: 44.976389, longitude: 30.05 },
  { name: 'D305O', latitude: 44.976786, longitude: 20.046328 },
  { name: 'D278V', latitude: 44.977294, longitude: 13.916031 },
  { name: 'D182B', latitude: 44.978047, longitude: 19.425297 },
  { name: 'BIRMA', latitude: 44.98, longitude: 30.561667 },
  { name: 'D267M', latitude: 44.980686, longitude: 37.031653 },
  { name: 'KK207', latitude: 44.984694, longitude: 39.32875 },
  { name: 'KK503', latitude: 44.985694, longitude: 38.867694 },
  { name: 'LABIN', latitude: 44.985833, longitude: 13.091389 },
  { name: 'BK403', latitude: 44.987686, longitude: 17.285403 },
  { name: 'DIRER', latitude: 44.988333, longitude: 21.409722 },
  { name: 'TIRVO', latitude: 44.9925, longitude: 28.736389 },
  { name: 'D264M', latitude: 44.992547, longitude: 37.030664 },
  { name: 'SOKRU', latitude: 44.992817, longitude: 25.330292 },
  { name: 'RI406', latitude: 44.993611, longitude: 14.597778 },
  { name: 'DER22', latitude: 44.993783, longitude: 37.336617 },
  { name: 'VP045', latitude: 44.993889, longitude: 13.739722 },
  { name: 'D066R', latitude: 44.993889, longitude: 14.313889 },
  { name: 'BE311', latitude: 44.996111, longitude: 19.636472 },
  { name: 'RUDIK', latitude: 44.996667, longitude: 16.305 },
  { name: 'RI502', latitude: 44.997333, longitude: 14.797694 },
  { name: 'PUL33', latitude: 44.9975, longitude: 14.673056 },
  { name: 'VIBOP', latitude: 44.999167, longitude: 18.7275 },
  { name: 'OTGIG', latitude: 44.999722, longitude: 12.118056 },
  { name: 'PL703', latitude: 44.999889, longitude: 13.495417 },
  { name: '4513E', latitude: 45.0, longitude: 13.0 },
  { name: '4514E', latitude: 45.0, longitude: 14.0 },
  { name: '4515E', latitude: 45.0, longitude: 15.0 },
  { name: '4516E', latitude: 45.0, longitude: 16.0 },
  { name: '4517E', latitude: 45.0, longitude: 17.0 },
  { name: '4518E', latitude: 45.0, longitude: 18.0 },
  { name: '4519E', latitude: 45.0, longitude: 19.0 },
  { name: '4520E', latitude: 45.0, longitude: 20.0 },
  { name: '4521E', latitude: 45.0, longitude: 21.0 },
  { name: '4522E', latitude: 45.0, longitude: 22.0 },
  { name: '4523E', latitude: 45.0, longitude: 23.0 },
  { name: '4524E', latitude: 45.0, longitude: 24.0 },
  { name: '4525E', latitude: 45.0, longitude: 25.0 },
  { name: '4526E', latitude: 45.0, longitude: 26.0 },
  { name: '4527E', latitude: 45.0, longitude: 27.0 },
  { name: '4528E', latitude: 45.0, longitude: 28.0 },
  { name: '4529E', latitude: 45.0, longitude: 29.0 },
  { name: '4530E', latitude: 45.0, longitude: 30.0 },
  { name: '4531E', latitude: 45.0, longitude: 31.0 },
  { name: '4532E', latitude: 45.0, longitude: 32.0 },
  { name: '4533E', latitude: 45.0, longitude: 33.0 },
  { name: '4534E', latitude: 45.0, longitude: 34.0 },
  { name: '4535E', latitude: 45.0, longitude: 35.0 },
  { name: '4536E', latitude: 45.0, longitude: 36.0 },
  { name: 'GAMAN', latitude: 45.0, longitude: 36.591667 },
  { name: '4537E', latitude: 45.0, longitude: 37.0 },
  { name: '4538E', latitude: 45.0, longitude: 38.0 },
  { name: '4539E', latitude: 45.0, longitude: 39.0 },
  { name: '4540E', latitude: 45.0, longitude: 40.0 },
  { name: 'VAL41', latitude: 45.000781, longitude: 19.762161 },
  { name: 'D257N', latitude: 45.001381, longitude: 38.841017 },
  { name: 'ANAKA', latitude: 45.002222, longitude: 37.347222 },
  { name: 'DENAK', latitude: 45.002281, longitude: 26.435692 },
  { name: 'PL609', latitude: 45.002611, longitude: 13.579056 },
  { name: 'KONAS', latitude: 45.003472, longitude: 13.612972 },
  { name: 'RI802', latitude: 45.003972, longitude: 14.790889 },
  { name: 'LAPOV', latitude: 45.004167, longitude: 19.095556 },
  { name: 'BE401', latitude: 45.004694, longitude: 19.886139 },
  { name: '16DME', latitude: 45.004839, longitude: 39.133944 },
  { name: '16VOR', latitude: 45.004919, longitude: 39.133981 },
  { name: 'D257K', latitude: 45.004928, longitude: 38.898586 },
  { name: '21THR', latitude: 45.005006, longitude: 39.133639 },
  { name: 'PL901', latitude: 45.005778, longitude: 13.747944 },
  { name: 'D288J', latitude: 45.006197, longitude: 17.079281 },
  { name: 'PL711', latitude: 45.006889, longitude: 13.730278 },
  { name: 'LSJ30', latitude: 45.007056, longitude: 14.365611 },
  { name: 'RUMAK', latitude: 45.01, longitude: 19.822778 },
  { name: 'PL712', latitude: 45.010222, longitude: 13.847611 },
  { name: 'PL616', latitude: 45.010306, longitude: 13.912222 },
  { name: 'DER04', latitude: 45.010433, longitude: 37.357956 },
  { name: 'KK509', latitude: 45.010694, longitude: 39.416667 },
  { name: 'D261M', latitude: 45.011322, longitude: 37.030578 },
  { name: 'AGIDA', latitude: 45.011389, longitude: 37.878333 },
  { name: 'VODIN', latitude: 45.0125, longitude: 21.3425 },
  { name: 'PL608', latitude: 45.012806, longitude: 14.030278 },
  { name: 'PL903', latitude: 45.013917, longitude: 14.084444 },
  { name: 'PL715', latitude: 45.014861, longitude: 14.019333 },
  { name: 'PL607', latitude: 45.015167, longitude: 14.147694 },
  { name: 'AGOMO', latitude: 45.016944, longitude: 12.801944 },
  { name: 'NIGDO', latitude: 45.017389, longitude: 14.265111 },
  { name: 'BE332', latitude: 45.01875, longitude: 20.161167 },
  { name: 'ULAMA', latitude: 45.019167, longitude: 21.128611 },
  { name: 'D262N', latitude: 45.019878, longitude: 38.839797 },
  { name: 'EVINI', latitude: 45.02, longitude: 14.981667 },
  { name: 'D259M', latitude: 45.020022, longitude: 37.031083 },
  { name: 'PL601', latitude: 45.020028, longitude: 14.412528 },
  { name: 'ML05R', latitude: 45.020039, longitude: 39.161922 },
  { name: 'D262K', latitude: 45.020131, longitude: 38.897597 },
  { name: 'RI501', latitude: 45.022472, longitude: 14.920583 },
  { name: 'SOVIN', latitude: 45.026917, longitude: 20.536472 },
  { name: 'ODARI', latitude: 45.028056, longitude: 38.82 },
  { name: 'FQ01R', latitude: 45.028194, longitude: 21.265558 },
  { name: 'TIKVU', latitude: 45.0315, longitude: 20.117056 },
  { name: 'FF16', latitude: 45.031883, longitude: 17.273861 },
  { name: 'PL801', latitude: 45.033778, longitude: 14.113417 },
  { name: 'FF12L', latitude: 45.034353, longitude: 20.064453 },
  { name: 'D282M', latitude: 45.034683, longitude: 37.033369 },
  { name: 'BIDVU', latitude: 45.038556, longitude: 19.803722 },
  { name: 'BEDEM', latitude: 45.038889, longitude: 19.807778 },
  { name: 'VAL43', latitude: 45.0398, longitude: 19.919869 },
  { name: 'CD0RX', latitude: 45.039997, longitude: 21.277456 },
  { name: 'FD16', latitude: 45.040664, longitude: 17.276353 },
  { name: '25THR', latitude: 45.041386, longitude: 37.397275 },
  { name: 'GUBOK', latitude: 45.044722, longitude: 17.861667 },
  { name: 'PEMUD', latitude: 45.046417, longitude: 15.038417 },
  { name: 'KA024', latitude: 45.047639, longitude: 37.036583 },
  { name: 'D279M', latitude: 45.049136, longitude: 37.037194 },
  { name: 'BANKA', latitude: 45.05, longitude: 21.027778 },
  { name: 'RODVU', latitude: 45.051667, longitude: 33.979722 },
  { name: 'D308J', latitude: 45.054939, longitude: 17.123722 },
  { name: 'ODANI', latitude: 45.055111, longitude: 20.023139 },
  { name: 'KA023', latitude: 45.055889, longitude: 37.039194 },
  { name: 'REBDI', latitude: 45.056111, longitude: 28.473611 },
  { name: 'KA011', latitude: 45.056167, longitude: 37.387417 },
  { name: 'FD0RX', latitude: 45.056297, longitude: 21.282333 },
  { name: '15THR', latitude: 45.057514, longitude: 39.231456 },
  { name: 'D305B', latitude: 45.059167, longitude: 37.353056 },
  { name: '38DME', latitude: 45.059547, longitude: 39.234825 },
  { name: 'CD01R', latitude: 45.060064, longitude: 21.217992 },
  { name: 'BE101', latitude: 45.061056, longitude: 19.756861 },
  { name: 'GISER', latitude: 45.061667, longitude: 15.173889 },
  { name: 'IRDIV', latitude: 45.061667, longitude: 17.113056 },
  { name: 'RI801', latitude: 45.062194, longitude: 14.730861 },
  { name: 'KA013', latitude: 45.064333, longitude: 37.236694 },
  { name: 'RI405', latitude: 45.064361, longitude: 14.728583 },
  { name: 'PZ655', latitude: 45.067667, longitude: 12.111778 },
  { name: 'UMBIK', latitude: 45.068056, longitude: 40.576667 },
  { name: 'LIMAN', latitude: 45.068889, longitude: 36.997778 },
  { name: 'KA026', latitude: 45.068889, longitude: 36.997778 },
  { name: 'KK213', latitude: 45.068917, longitude: 38.8375 },
  { name: 'FF22', latitude: 45.071667, longitude: 37.436667 },
  { name: 'KA001', latitude: 45.071722, longitude: 37.436667 },
  { name: 'FU22', latitude: 45.072006, longitude: 37.436867 },
  { name: 'D276M', latitude: 45.072333, longitude: 37.045964 },
  { name: 'D346K', latitude: 45.072689, longitude: 13.871628 },
  { name: 'FD0RY', latitude: 45.073025, longitude: 21.232772 },
  { name: 'CD32', latitude: 45.074056, longitude: 14.715722 },
  { name: 'CQ32', latitude: 45.075, longitude: 14.717611 },
  { name: 'BUKEL', latitude: 45.077811, longitude: 25.725714 },
  { name: 'KA005', latitude: 45.077972, longitude: 37.635694 },
  { name: 'ENIMA', latitude: 45.079444, longitude: 24.9025 },
  { name: 'CN32', latitude: 45.079522, longitude: 14.712381 },
  { name: 'VP047', latitude: 45.080278, longitude: 14.159167 },
  { name: 'KK201', latitude: 45.081639, longitude: 39.508583 },
  { name: 'FN22X', latitude: 45.083269, longitude: 37.450419 },
  { name: 'D274M', latitude: 45.083311, longitude: 37.051247 },
  { name: 'FS23L', latitude: 45.083911, longitude: 39.282011 },
  { name: 'FD0RZ', latitude: 45.085986, longitude: 21.247561 },
  { name: 'LANEN', latitude: 45.087444, longitude: 19.958333 },
  { name: 'KA027', latitude: 45.08775, longitude: 37.45725 },
  { name: 'TERTI', latitude: 45.088333, longitude: 28.664472 },
  { name: 'MD0RX', latitude: 45.088903, longitude: 21.292103 },
  { name: 'KA017', latitude: 45.093028, longitude: 37.383028 },
  { name: 'PL713', latitude: 45.093472, longitude: 13.843028 },
  { name: 'NUSKA', latitude: 45.0935, longitude: 14.698528 },
  { name: 'BE113', latitude: 45.095667, longitude: 19.953056 },
  { name: 'CF22', latitude: 45.096642, longitude: 37.468644 },
  { name: 'ALAVO', latitude: 45.097667, longitude: 21.033722 },
  { name: 'PL614', latitude: 45.098444, longitude: 14.144444 },
  { name: 'KA010', latitude: 45.099806, longitude: 37.127639 },
  { name: 'TOLBA', latitude: 45.1, longitude: 34.4 },
  { name: 'SIVLA', latitude: 45.101944, longitude: 18.381667 },
  { name: 'KA015', latitude: 45.102694, longitude: 37.285444 },
  { name: 'KK508', latitude: 45.103806, longitude: 39.316667 },
  { name: 'LAMIT', latitude: 45.103889, longitude: 23.374722 },
  { name: 'PAVIS', latitude: 45.105556, longitude: 21.239444 },
  { name: 'FF23L', latitude: 45.106108, longitude: 39.323744 },
  { name: 'D303M', latitude: 45.106961, longitude: 37.066347 },
  { name: 'KK205', latitude: 45.106972, longitude: 39.322583 },
  { name: 'FI23L', latitude: 45.107144, longitude: 39.322922 },
  { name: 'RI906', latitude: 45.108472, longitude: 14.409083 },
  { name: 'D240D', latitude: 45.1096, longitude: 21.221461 },
  { name: 'BESEN', latitude: 45.115833, longitude: 19.707778 },
  { name: 'KA022', latitude: 45.1165, longitude: 37.073917 },
  { name: 'D300M', latitude: 45.120814, longitude: 37.077744 },
  { name: 'D138K', latitude: 45.123092, longitude: 19.951828 },
  { name: 'PZ850', latitude: 45.123139, longitude: 12.024056 },
  { name: 'MD01R', latitude: 45.124853, longitude: 21.291961 },
  { name: 'VP048', latitude: 45.125278, longitude: 14.528333 },
  { name: 'RI907', latitude: 45.125583, longitude: 14.751333 },
  { name: 'TUVAR', latitude: 45.126667, longitude: 19.0775 },
  { name: 'DUNAV', latitude: 45.127167, longitude: 28.482 },
  { name: 'CI23L', latitude: 45.127336, longitude: 39.360378 },
  { name: 'BE114', latitude: 45.128111, longitude: 20.1475 },
  { name: 'DEVUL', latitude: 45.130278, longitude: 16.441111 },
  { name: 'GULBU', latitude: 45.1305, longitude: 19.588167 },
  { name: 'VP362', latitude: 45.130556, longitude: 12.080556 },
  { name: 'BASOG', latitude: 45.1325, longitude: 12.493333 },
  { name: 'AGLIB', latitude: 45.134444, longitude: 17.153333 },
  { name: 'LURID', latitude: 45.135, longitude: 17.399444 },
  { name: 'MQ343', latitude: 45.136622, longitude: 21.276536 },
  { name: 'RELKI', latitude: 45.138194, longitude: 20.084583 },
  { name: 'D296M', latitude: 45.143075, longitude: 37.09995 },
  { name: 'KA002', latitude: 45.145389, longitude: 37.531556 },
  { name: 'D195E', latitude: 45.145633, longitude: 14.528644 },
  { name: 'RI701', latitude: 45.147639, longitude: 14.437833 },
  { name: 'KUSIB', latitude: 45.148056, longitude: 16.471667 },
  { name: 'BE112', latitude: 45.14825, longitude: 19.8325 },
  { name: 'RIBNA', latitude: 45.148889, longitude: 21.132222 },
  { name: 'RI803', latitude: 45.153222, longitude: 14.974444 },
  { name: 'PALER', latitude: 45.153333, longitude: 36.545 },
  { name: 'D294M', latitude: 45.153658, longitude: 37.112436 },
  { name: 'D183V', latitude: 45.154467, longitude: 12.314033 },
  { name: 'VAL51', latitude: 45.154694, longitude: 19.581836 },
  { name: '50VOR', latitude: 45.155944, longitude: 14.634667 },
  { name: 'ARVOD', latitude: 45.157778, longitude: 12.189722 },
  { name: 'PL902', latitude: 45.157778, longitude: 13.740639 },
  { name: 'CD2LZ', latitude: 45.159078, longitude: 39.423592 },
  { name: 'D040M', latitude: 45.159239, longitude: 19.645178 },
  { name: 'CI2LY', latitude: 45.160522, longitude: 39.422044 },
  { name: '35THR', latitude: 45.160603, longitude: 14.629014 },
  { name: 'KK204', latitude: 45.161222, longitude: 39.42325 },
  { name: 'D053R', latitude: 45.164939, longitude: 39.532847 },
  { name: 'KULEN', latitude: 45.165278, longitude: 15.133611 },
  { name: 'D040W', latitude: 45.166389, longitude: 14.287222 },
  { name: 'PL802', latitude: 45.170417, longitude: 14.105972 },
  { name: 'D359Q', latitude: 45.175278, longitude: 13.938056 },
  { name: 'KA021', latitude: 45.176111, longitude: 37.145944 },
  { name: 'RI702', latitude: 45.17675, longitude: 15.065194 },
  { name: 'PL615', latitude: 45.176917, longitude: 14.141361 },
  { name: 'LOBKI', latitude: 45.178056, longitude: 28.915833 },
  { name: 'D179I', latitude: 45.180642, longitude: 14.43535 },
  { name: 'BE115', latitude: 45.180806, longitude: 20.027222 },
  { name: 'D005S', latitude: 45.181389, longitude: 14.07 },
  { name: 'VBA82', latitude: 45.183056, longitude: 18.908056 },
  { name: 'BEO28', latitude: 45.183333, longitude: 20.716667 },
  { name: 'BETEG', latitude: 45.183333, longitude: 36.383333 },
  { name: 'KA018', latitude: 45.183917, longitude: 37.273833 },
  { name: 'PZ855', latitude: 45.185278, longitude: 12.102472 },
  { name: 'BAGRI', latitude: 45.186667, longitude: 29.803333 },
  { name: 'NAKIT', latitude: 45.188056, longitude: 13.447778 },
  { name: 'RI504', latitude: 45.190028, longitude: 14.393611 },
  { name: 'D034N', latitude: 45.191611, longitude: 39.381678 },
  { name: 'RIPGA', latitude: 45.191944, longitude: 27.901944 },
  { name: 'PZ862', latitude: 45.193694, longitude: 12.260167 },
  { name: 'ZAG42', latitude: 45.194961, longitude: 16.305369 },
  { name: 'VP049', latitude: 45.196944, longitude: 13.747778 },
  { name: 'D143W', latitude: 45.198486, longitude: 18.851328 },
  { name: 'CD2LY', latitude: 45.199994, longitude: 39.500958 },
  { name: 'TEBLI', latitude: 45.201389, longitude: 16.675833 },
  { name: 'CI2LX', latitude: 45.201833, longitude: 39.498994 },
  { name: 'RI407', latitude: 45.202, longitude: 14.381083 },
  { name: 'MQ32', latitude: 45.202722, longitude: 14.585278 },
  { name: 'MD32', latitude: 45.203667, longitude: 14.587306 },
  { name: 'D002K', latitude: 45.207175, longitude: 39.20915 },
  { name: 'DER14', latitude: 45.207614, longitude: 14.580194 },
  { name: 'VP050', latitude: 45.210278, longitude: 18.405556 },
  { name: 'KA003', latitude: 45.212722, longitude: 37.427194 },
  { name: 'VBA77', latitude: 45.216389, longitude: 18.806944 },
  { name: 'VP367', latitude: 45.2175, longitude: 12.287222 },
  { name: 'D264J', latitude: 45.217786, longitude: 14.321092 },
  { name: 'KA004', latitude: 45.219111, longitude: 37.626889 },
  { name: 'INDOD', latitude: 45.219444, longitude: 12.388889 },
  { name: 'TUPUS', latitude: 45.220833, longitude: 15.889722 },
  { name: 'KK202', latitude: 45.221611, longitude: 39.535806 },
  { name: 'PIXAL', latitude: 45.221667, longitude: 16.554444 },
  { name: 'ZA616', latitude: 45.223389, longitude: 16.187722 },
  { name: 'NIVES', latitude: 45.223889, longitude: 15.9075 },
  { name: 'IRMAM', latitude: 45.225278, longitude: 28.723056 },
  { name: 'RI602', latitude: 45.225833, longitude: 14.090972 },
  { name: 'RI804', latitude: 45.226889, longitude: 14.806778 },
  { name: 'NOVLO', latitude: 45.229444, longitude: 16.953056 },
  { name: 'D044N', latitude: 45.229942, longitude: 12.522911 },
  { name: 'BE116', latitude: 45.233306, longitude: 19.906917 },
  { name: 'DOGEX', latitude: 45.234167, longitude: 12.445556 },
  { name: 'MM14', latitude: 45.234389, longitude: 14.552353 },
  { name: 'MAVIT', latitude: 45.24, longitude: 21.308333 },
  { name: 'MQ14', latitude: 45.241444, longitude: 14.544861 },
  { name: 'LILEE', latitude: 45.241667, longitude: 12.918333 },
  { name: 'PZ704', latitude: 45.242897, longitude: 12.252067 },
  { name: 'TADAM', latitude: 45.247222, longitude: 19.546111 },
  { name: 'D002N', latitude: 45.247578, longitude: 39.218972 },
  { name: 'PL714', latitude: 45.250667, longitude: 13.834278 },
  { name: 'NIVIS', latitude: 45.2525, longitude: 19.787222 },
  { name: 'D002M', latitude: 45.254678, longitude: 18.802711 },
  { name: 'DIGAM', latitude: 45.258333, longitude: 31.111667 },
  { name: 'VP051', latitude: 45.258611, longitude: 14.595833 },
  { name: 'D080T', latitude: 45.258611, longitude: 15.036111 },
  { name: 'RI901', latitude: 45.261972, longitude: 14.497167 },
  { name: 'ROTAR', latitude: 45.262778, longitude: 12.995556 },
  { name: 'RI505', latitude: 45.264, longitude: 14.5215 },
  { name: 'D056W', latitude: 45.266694, longitude: 12.747406 },
  { name: 'D180O', latitude: 45.269464, longitude: 12.349667 },
  { name: 'ADULA', latitude: 45.270556, longitude: 18.641944 },
  { name: 'PISEM', latitude: 45.271111, longitude: 35.661389 },
  { name: 'BELOB', latitude: 45.271944, longitude: 20.370278 },
  { name: 'VIC41', latitude: 45.272294, longitude: 12.496969 },
  { name: 'BADUB', latitude: 45.273611, longitude: 20.820556 },
  { name: '40VOR', latitude: 45.275556, longitude: 14.506944 },
  { name: '40DM2', latitude: 45.276306, longitude: 14.508222 },
  { name: '40DME', latitude: 45.276472, longitude: 14.508528 },
  { name: '38THR', latitude: 45.276661, longitude: 14.508306 },
  { name: 'LAREN', latitude: 45.278056, longitude: 12.061667 },
  { name: 'RUGOG', latitude: 45.278056, longitude: 15.3125 },
  { name: 'PZ530', latitude: 45.278069, longitude: 12.339664 },
  { name: 'D321Q', latitude: 45.282622, longitude: 19.844028 },
  { name: 'TULIK', latitude: 45.283889, longitude: 12.789167 },
  { name: 'VP374', latitude: 45.286667, longitude: 12.029167 },
  { name: 'PELUR', latitude: 45.286667, longitude: 25.488333 },
  { name: 'VIC33', latitude: 45.289628, longitude: 12.281808 },
  { name: 'VP052', latitude: 45.291111, longitude: 18.801111 },
  { name: 'VP053', latitude: 45.291389, longitude: 14.275833 },
  { name: 'LAPKA', latitude: 45.292778, longitude: 26.509167 },
  { name: 'OS804', latitude: 45.296889, longitude: 18.693056 },
  { name: 'ERETA', latitude: 45.298056, longitude: 40.385 },
  { name: 'KLISA', latitude: 45.299444, longitude: 19.870556 },
  { name: 'OS803', latitude: 45.301389, longitude: 18.970278 },
  { name: 'VP054', latitude: 45.304444, longitude: 14.713889 },
  { name: 'ZA615', latitude: 45.3045, longitude: 16.080028 },
  { name: 'OS403', latitude: 45.307167, longitude: 18.933 },
  { name: 'ZA806', latitude: 45.309472, longitude: 15.846278 },
  { name: 'PEVAL', latitude: 45.311389, longitude: 13.2475 },
  { name: 'D211O', latitude: 45.311686, longitude: 12.170094 },
  { name: 'RI806', latitude: 45.311889, longitude: 14.768639 },
  { name: 'NERDI', latitude: 45.312778, longitude: 23.703056 },
  { name: 'ROSKA', latitude: 45.314167, longitude: 12.861667 },
  { name: 'KOTUS', latitude: 45.317778, longitude: 19.937778 },
  { name: 'TINAL', latitude: 45.32, longitude: 32.936667 },
  { name: 'D218P', latitude: 45.320564, longitude: 12.115461 },
  { name: 'D023G', latitude: 45.328056, longitude: 14.643056 },
  { name: 'KIZAF', latitude: 45.330278, longitude: 12.128056 },
  { name: 'LOBBE', latitude: 45.331944, longitude: 12.126667 },
  { name: 'FD14', latitude: 45.332111, longitude: 14.440806 },
  { name: 'AKADO', latitude: 45.333333, longitude: 12.5 },
  { name: 'RI902', latitude: 45.333611, longitude: 14.369944 },
  { name: 'NEGVI', latitude: 45.334639, longitude: 14.447778 },
  { name: 'OM14', latitude: 45.334653, longitude: 14.446819 },
  { name: 'VP380', latitude: 45.334722, longitude: 12.335 },
  { name: 'KANAL', latitude: 45.335556, longitude: 20.581667 },
  { name: 'BGD46', latitude: 45.337742, longitude: 19.514072 },
  { name: 'KUKIV', latitude: 45.340278, longitude: 31.881111 },
  { name: 'CHARE', latitude: 45.340892, longitude: 12.423861 },
  { name: 'D155H', latitude: 45.343056, longitude: 18.857222 },
  { name: 'PZ611', latitude: 45.3435, longitude: 12.300111 },
  { name: 'BEPES', latitude: 45.348056, longitude: 28.322222 },
  { name: 'ARNAD', latitude: 45.35, longitude: 36.646667 },
  { name: 'TIBET', latitude: 45.35, longitude: 37.133333 },
  { name: 'IRDAX', latitude: 45.351056, longitude: 14.5325 },
  { name: 'SOSIL', latitude: 45.351419, longitude: 21.307772 },
  { name: 'KILZI', latitude: 45.355278, longitude: 40.218611 },
  { name: 'RI603', latitude: 45.359389, longitude: 13.938528 },
  { name: 'CI14', latitude: 45.361733, longitude: 14.419433 },
  { name: 'LENKA', latitude: 45.363333, longitude: 35.788333 },
  { name: 'VAL65', latitude: 45.367911, longitude: 19.505253 },
  { name: 'RI409', latitude: 45.376222, longitude: 14.40425 },
  { name: 'PZ534', latitude: 45.376361, longitude: 12.186794 },
  { name: 'BADKA', latitude: 45.3775, longitude: 29.110833 },
  { name: 'PZ539', latitude: 45.377569, longitude: 12.184892 },
  { name: 'FD04', latitude: 45.378094, longitude: 12.194453 },
  { name: 'PZ209', latitude: 45.379817, longitude: 12.191286 },
  { name: 'FF04R', latitude: 45.379819, longitude: 12.191353 },
  { name: 'KONOP', latitude: 45.381389, longitude: 20.340833 },
  { name: 'PZ309', latitude: 45.381456, longitude: 12.189856 },
  { name: 'SORDO', latitude: 45.382139, longitude: 14.172694 },
  { name: 'SORUL', latitude: 45.383056, longitude: 37.348333 },
  { name: 'RI905', latitude: 45.384556, longitude: 14.611167 },
  { name: 'D020U', latitude: 45.385389, longitude: 12.470861 },
  { name: 'ZA617', latitude: 45.385583, longitude: 16.435056 },
  { name: 'BABAG', latitude: 45.386944, longitude: 13.126944 },
  { name: 'ABLATitude', latitude: 45.390433, longitude: 13.62615 },
  { name: 'RI401', latitude: 45.399028, longitude: 14.242778 },
  { name: 'PEROT', latitude: 45.400556, longitude: 19.012778 },
  { name: 'D116N', latitude: 45.40085, longitude: 12.653975 },
  { name: 'D125G', latitude: 45.406417, longitude: 18.920583 },
  { name: 'VP001', latitude: 45.409722, longitude: 13.656667 },
  { name: 'RIGMU', latitude: 45.41, longitude: 19.401111 },
  { name: 'RASIL', latitude: 45.41, longitude: 31.416667 },
  { name: 'LUSOM', latitude: 45.413511, longitude: 21.449469 },
  { name: 'RI903', latitude: 45.415278, longitude: 14.40275 },
  { name: 'TISAK', latitude: 45.421944, longitude: 20.227222 },
  { name: 'VP055', latitude: 45.422778, longitude: 18.775 },
  { name: 'CI29Y', latitude: 45.426222, longitude: 18.947917 },
  { name: 'PZ612', latitude: 45.4265, longitude: 12.107667 },
  { name: 'KLEKA', latitude: 45.426944, longitude: 20.486389 },
  { name: 'PZ497', latitude: 45.427917, longitude: 12.689 },
  { name: 'VP392', latitude: 45.428333, longitude: 12.388056 },
  { name: 'CI29X', latitude: 45.428881, longitude: 18.936964 },
  { name: '70VOR', latitude: 45.430997, longitude: 12.259506 },
  { name: 'FQ29', latitude: 45.431497, longitude: 18.925989 },
  { name: 'INGOP', latitude: 45.431944, longitude: 21.149722 },
  { name: 'LENIR', latitude: 45.433056, longitude: 40.015 },
  { name: 'D135H', latitude: 45.433333, longitude: 18.675 },
  { name: 'D116J', latitude: 45.434703, longitude: 12.572444 },
  { name: 'KUTIG', latitude: 45.434861, longitude: 18.509972 },
  { name: 'D126T', latitude: 45.43605, longitude: 12.028422 },
  { name: 'RUDOL', latitude: 45.438889, longitude: 37.806111 },
  { name: 'FI29', latitude: 45.440833, longitude: 18.892778 },
  { name: 'RI904', latitude: 45.440917, longitude: 14.413056 },
  { name: 'KOTOR', latitude: 45.441111, longitude: 15.572222 },
  { name: 'BALED', latitude: 45.441667, longitude: 32.341667 },
  { name: 'PZ601', latitude: 45.441889, longitude: 12.112306 },
  { name: 'ERSAB', latitude: 45.443056, longitude: 12.551944 },
  { name: 'PZ610', latitude: 45.444667, longitude: 12.274222 },
  { name: 'OS405', latitude: 45.445583, longitude: 18.874833 },
  { name: 'NASSY', latitude: 45.446667, longitude: 18.099722 },
  { name: 'D331Y', latitude: 45.447181, longitude: 12.027711 },
  { name: '39LOC', latitude: 45.447917, longitude: 12.278447 },
  { name: '37THR', latitude: 45.448367, longitude: 12.278997 },
  { name: '37TH2', latitude: 45.449675, longitude: 12.277178 },
  { name: 'KULED', latitude: 45.449722, longitude: 40.565 },
  { name: 'AGNEP', latitude: 45.45, longitude: 22.959722 },
  { name: 'D260I', latitude: 45.452778, longitude: 18.582222 },
  { name: 'RI408', latitude: 45.452917, longitude: 14.226472 },
  { name: 'D219E', latitude: 45.4539, longitude: 12.284844 },
  { name: 'GUPRO', latitude: 45.455278, longitude: 21.894722 },
  { name: 'D112I', latitude: 45.455536, longitude: 12.561322 },
  { name: '50VOR', latitude: 45.456175, longitude: 12.290525 },
  { name: 'OTPOL', latitude: 45.456389, longitude: 31.510278 },
  { name: 'PZ490', latitude: 45.456617, longitude: 12.509308 },
  { name: 'VP397', latitude: 45.458056, longitude: 12.443611 },
  { name: 'DER11', latitude: 45.458406, longitude: 18.826297 },
  { name: 'RI402', latitude: 45.459833, longitude: 14.408778 },
  { name: 'D313S', latitude: 45.460525, longitude: 14.257606 },
  { name: 'BADOP', latitude: 45.462222, longitude: 13.004167 },
  { name: 'D135E', latitude: 45.463889, longitude: 18.636667 },
  { name: 'VP400', latitude: 45.464167, longitude: 12.118056 },
  { name: 'ZA704', latitude: 45.465028, longitude: 15.890667 },
  { name: 'PZ613', latitude: 45.4655, longitude: 12.152861 },
  { name: 'BUGEV', latitude: 45.465569, longitude: 13.773442 },
  { name: 'SABAD', latitude: 45.465872, longitude: 14.867481 },
  { name: 'ZAG36', latitude: 45.466528, longitude: 15.719944 },
  { name: 'DITAX', latitude: 45.468889, longitude: 22.337222 },
  { name: 'ZA805', latitude: 45.469444, longitude: 16.087417 },
  { name: 'ML11', latitude: 45.469611, longitude: 18.783806 },
  { name: 'GEMKA', latitude: 45.470278, longitude: 14.204167 },
  { name: 'SIRIG', latitude: 45.4725, longitude: 19.799167 },
  { name: 'D324D', latitude: 45.4731, longitude: 14.301469 },
  { name: 'D100L', latitude: 45.473764, longitude: 12.645128 },
  { name: '15THR', latitude: 45.475064, longitude: 18.763106 },
  { name: 'GIRDA', latitude: 45.475575, longitude: 14.133953 },
  { name: '21LOC', latitude: 45.47775, longitude: 18.752917 },
  { name: '18DME', latitude: 45.477778, longitude: 18.752944 },
  { name: 'DIMOS', latitude: 45.478333, longitude: 35.054444 },
  { name: 'OS285', latitude: 45.479886, longitude: 18.741894 },
  { name: 'ZA611', latitude: 45.480472, longitude: 15.844861 },
  { name: 'NOBLO', latitude: 45.481944, longitude: 39.845 },
  { name: 'ARMIX', latitude: 45.482461, longitude: 14.267878 },
  { name: 'VP056', latitude: 45.485278, longitude: 15.991944 },
  { name: 'PZ703', latitude: 45.487158, longitude: 12.6232 },
  { name: 'MONFA', latitude: 45.487222, longitude: 13.279167 },
  { name: 'ML04R', latitude: 45.487628, longitude: 12.329375 },
  { name: 'MD04', latitude: 45.487636, longitude: 12.329336 },
  { name: 'D197Z', latitude: 45.489264, longitude: 16.085072 },
  { name: 'MD15', latitude: 45.489347, longitude: 13.6006 },
  { name: 'FQ11Z', latitude: 45.490258, longitude: 18.7012 },
  { name: 'LEMTO', latitude: 45.490556, longitude: 33.541667 },
  { name: 'USELU', latitude: 45.494167, longitude: 14.054722 },
  { name: 'DARZA', latitude: 45.495017, longitude: 15.007147 },
  { name: 'RULFE', latitude: 45.496461, longitude: 12.922697 },
  { name: 'URELA', latitude: 45.496667, longitude: 26.561111 },
  { name: 'LORVI', latitude: 45.496694, longitude: 18.680806 },
  { name: 'D073G', latitude: 45.501331, longitude: 13.773564 },
  { name: 'BUSET', latitude: 45.501772, longitude: 14.224164 },
  { name: 'ZA714', latitude: 45.50225, longitude: 16.224361 },
  { name: 'D254Q', latitude: 45.503558, longitude: 13.777567 },
  { name: '20VOR', latitude: 45.503919, longitude: 13.589106 },
  { name: 'D110T', latitude: 45.506253, longitude: 12.112653 },
  { name: 'VP405', latitude: 45.507222, longitude: 12.632222 },
  { name: 'UNEPA', latitude: 45.508056, longitude: 39.758333 },
  { name: 'D140N', latitude: 45.510283, longitude: 12.314417 },
  { name: 'UBAXI', latitude: 45.511606, longitude: 22.213075 },
  { name: 'VP406', latitude: 45.516667, longitude: 12.235556 },
  { name: 'NEKUL', latitude: 45.516794, longitude: 22.586769 },
  { name: '30VOR', latitude: 45.518492, longitude: 13.577608 },
  { name: 'D297E', latitude: 45.520208, longitude: 13.504397 },
  { name: 'DIVEL', latitude: 45.521847, longitude: 21.306908 },
  { name: 'PZ489', latitude: 45.522381, longitude: 12.544917 },
  { name: 'PZ481', latitude: 45.523008, longitude: 12.204583 },
  { name: 'PQ406', latitude: 45.523406, longitude: 13.205294 },
  { name: 'ALIVO', latitude: 45.523439, longitude: 14.739067 },
  { name: 'VP057', latitude: 45.524444, longitude: 18.841944 },
  { name: 'MD22R', latitude: 45.525272, longitude: 12.376125 },
  { name: 'LULIK', latitude: 45.526667, longitude: 19.444167 },
  { name: 'D073N', latitude: 45.527656, longitude: 13.935197 },
  { name: 'VP003', latitude: 45.528611, longitude: 13.568333 },
  { name: 'D254J', latitude: 45.529264, longitude: 13.939408 },
  { name: 'DIRSU', latitude: 45.531111, longitude: 39.694444 },
  { name: 'MD22L', latitude: 45.531464, longitude: 12.384058 },
  { name: 'IBNAR', latitude: 45.532222, longitude: 28.728611 },
  { name: 'FD15', latitude: 45.535975, longitude: 13.563806 },
  { name: 'PZ488', latitude: 45.537408, longitude: 12.397031 },
  { name: 'TAFNI', latitude: 45.537667, longitude: 15.931083 },
  { name: 'AZAVE', latitude: 45.538611, longitude: 14.062778 },
  { name: 'D079I', latitude: 45.539758, longitude: 12.579528 },
  { name: 'EKSON', latitude: 45.541028, longitude: 15.763444 },
  { name: 'D039D', latitude: 45.543167, longitude: 12.40065 },
  { name: 'PZ702', latitude: 45.543308, longitude: 12.400947 },
  { name: 'BRAVO', latitude: 45.543333, longitude: 12.915556 },
  { name: 'FN15', latitude: 45.543394, longitude: 13.558533 },
  { name: 'UMBEK', latitude: 45.544444, longitude: 13.419722 },
  { name: 'PZ712', latitude: 45.544517, longitude: 12.399044 },
  { name: 'VP409', latitude: 45.545, longitude: 12.304722 },
  { name: 'VP058', latitude: 45.545278, longitude: 18.285833 },
  { name: 'PEREV', latitude: 45.546389, longitude: 35.097222 },
  { name: 'D223R', latitude: 45.546928, longitude: 15.772294 },
  { name: 'OS904', latitude: 45.547083, longitude: 18.4445 },
  { name: 'VP410', latitude: 45.547222, longitude: 12.160278 },
  { name: 'OS801', latitude: 45.549556, longitude: 18.651 },
  { name: 'CI11', latitude: 45.551639, longitude: 18.471092 },
  { name: 'D191D', latitude: 45.551939, longitude: 12.070608 },
  { name: 'VP004', latitude: 45.556667, longitude: 13.878889 },
  { name: 'VP005', latitude: 45.557222, longitude: 13.75 },
  { name: 'OS601', latitude: 45.557472, longitude: 18.448917 },
  { name: 'MOSAV', latitude: 45.558611, longitude: 16.9325 },
  { name: 'PZ486', latitude: 45.560325, longitude: 12.430781 },
  { name: 'ADUGA', latitude: 45.564722, longitude: 39.59 },
  { name: 'AR014', latitude: 45.569167, longitude: 21.449167 },
  { name: 'D280M', latitude: 45.570814, longitude: 12.068822 },
  { name: 'ZA904', latitude: 45.574306, longitude: 16.343083 },
  { name: 'NEMEK', latitude: 45.574667, longitude: 15.298033 },
  { name: 'VP059', latitude: 45.578333, longitude: 18.585278 },
  { name: 'TR233', latitude: 45.578778, longitude: 21.828319 },
  { name: 'BARPI', latitude: 45.585806, longitude: 13.522864 },
  { name: 'FD22R', latitude: 45.588394, longitude: 12.457125 },
  { name: 'RI30A', latitude: 45.58855, longitude: 12.673908 },
  { name: 'PZ538', latitude: 45.589139, longitude: 12.456617 },
  { name: 'PZ531', latitude: 45.591442, longitude: 12.6615 },
  { name: 'PZ533', latitude: 45.591558, longitude: 12.463242 },
  { name: 'FD22L', latitude: 45.592106, longitude: 12.461894 },
  { name: 'ZA713', latitude: 45.593806, longitude: 16.085389 },
  { name: 'NOMPA', latitude: 45.595556, longitude: 33.237778 },
  { name: 'PZ477', latitude: 45.595942, longitude: 12.381758 },
  { name: 'ZA401', latitude: 45.598278, longitude: 15.849667 },
  { name: 'VP433', latitude: 45.6, longitude: 12.883333 },
  { name: 'LAKIK', latitude: 45.602222, longitude: 18.0975 },
  { name: 'VP420', latitude: 45.603056, longitude: 13.766667 },
  { name: 'GOVOX', latitude: 45.605197, longitude: 21.306486 },
  { name: 'VP006', latitude: 45.605833, longitude: 13.928889 },
  { name: 'TR232', latitude: 45.607383, longitude: 21.716814 },
  { name: 'CI07Z', latitude: 45.609867, longitude: 12.049061 },
  { name: 'EVIKA', latitude: 45.6125, longitude: 27.513889 },
  { name: 'ROTIM', latitude: 45.613333, longitude: 40.736667 },
  { name: 'CI04', latitude: 45.615583, longitude: 15.875778 },
  { name: 'D113J', latitude: 45.616903, longitude: 12.332286 },
  { name: 'OTFOX', latitude: 45.619167, longitude: 13.700278 },
  { name: 'KIJEW', latitude: 45.619722, longitude: 14.015 },
  { name: 'D191Y', latitude: 45.621417, longitude: 12.455856 },
  { name: 'DAXNU', latitude: 45.625, longitude: 13.619167 },
  { name: 'ZA708', latitude: 45.625, longitude: 15.708556 },
  { name: 'D096Z', latitude: 45.625594, longitude: 12.728544 },
  { name: 'UPIRO', latitude: 45.625833, longitude: 12.7425 },
  { name: 'VICKY', latitude: 45.628881, longitude: 13.554292 },
  { name: 'PZ532', latitude: 45.629675, longitude: 12.512511 },
  { name: 'PZ537', latitude: 45.630347, longitude: 12.509881 },
  { name: 'CD22', latitude: 45.630442, longitude: 12.511225 },
  { name: 'VP425', latitude: 45.631111, longitude: 12.566111 },
  { name: 'KEBBU', latitude: 45.632222, longitude: 13.514722 },
  { name: 'PQ404', latitude: 45.632456, longitude: 13.302758 },
  { name: 'D096V', latitude: 45.635333, longitude: 12.634561 },
  { name: 'IRLOX', latitude: 45.635556, longitude: 24.183889 },
  { name: 'TR231', latitude: 45.635878, longitude: 21.605194 },
  { name: 'ZA601', latitude: 45.63775, longitude: 15.632722 },
  { name: 'D225E', latitude: 45.638778, longitude: 15.899364 },
  { name: 'BAPEK', latitude: 45.638889, longitude: 15.911028 },
  { name: 'OSLOP', latitude: 45.640278, longitude: 13.176944 },
  { name: 'ML07', latitude: 45.641281, longitude: 12.16695 },
  { name: 'FQ04', latitude: 45.64275, longitude: 15.905944 },
  { name: 'OS902', latitude: 45.644111, longitude: 18.797028 },
  { name: 'SOGTA', latitude: 45.644444, longitude: 34.718056 },
  { name: 'TR932', latitude: 45.644678, longitude: 21.149183 },
  { name: 'GOVIN', latitude: 45.645, longitude: 21.147778 },
  { name: 'ZA712', latitude: 45.646778, longitude: 16.165861 },
  { name: 'VP007', latitude: 45.647778, longitude: 14.869167 },
  { name: 'D066A', latitude: 45.651828, longitude: 12.206731 },
  { name: 'ETAKO', latitude: 45.652172, longitude: 13.878525 },
  { name: 'BALEG', latitude: 45.653056, longitude: 39.318333 },
  { name: 'VBA37', latitude: 45.654217, longitude: 16.277056 },
  { name: 'VP060', latitude: 45.658611, longitude: 18.415556 },
  { name: 'TR230', latitude: 45.664264, longitude: 21.493461 },
  { name: 'PQ403', latitude: 45.667156, longitude: 12.94045 },
  { name: 'VP428', latitude: 45.668611, longitude: 13.104444 },
  { name: 'ZA901', latitude: 45.668861, longitude: 15.956361 },
  { name: '55THR', latitude: 45.669078, longitude: 15.956706 },
  { name: 'ZA804', latitude: 45.669361, longitude: 16.391833 },
  { name: 'D225B', latitude: 45.671614, longitude: 15.953342 },
  { name: '57DME', latitude: 45.671806, longitude: 15.953722 },
  { name: 'VP061', latitude: 45.671944, longitude: 15.651111 },
  { name: 'TR933', latitude: 45.672617, longitude: 21.037203 },
  { name: 'TR223', latitude: 45.672683, longitude: 21.877372 },
  { name: 'VP430', latitude: 45.673889, longitude: 13.388333 },
  { name: 'D092J', latitude: 45.676267, longitude: 12.342264 },
  { name: 'BANTO', latitude: 45.678333, longitude: 20.423056 },
  { name: 'D101B', latitude: 45.678892, longitude: 12.163006 },
  { name: 'D256W', latitude: 45.68, longitude: 16.608333 },
  { name: 'ZA612', latitude: 45.681028, longitude: 16.148472 },
  { name: 'OS903', latitude: 45.681167, longitude: 18.659333 },
  { name: 'D144Y', latitude: 45.681944, longitude: 12.919278 },
  { name: 'RASUG', latitude: 45.682953, longitude: 12.921142 },
  { name: 'MS08', latitude: 45.682986, longitude: 12.05095 },
  { name: 'OM04', latitude: 45.690636, longitude: 15.990017 },
  { name: 'MOPIP', latitude: 45.692728, longitude: 12.640103 },
  { name: 'VP008', latitude: 45.693611, longitude: 13.966667 },
  { name: 'GODLA', latitude: 45.695111, longitude: 15.718972 },
  { name: 'D176L', latitude: 45.695606, longitude: 16.306314 },
  { name: 'FT26', latitude: 45.697914, longitude: 12.223047 },
  { name: 'NEROB', latitude: 45.699167, longitude: 34.190278 },
  { name: 'ETAVA', latitude: 45.7, longitude: 29.104722 },
  { name: 'TR934', latitude: 45.700447, longitude: 20.925108 },
  { name: 'TR222', latitude: 45.701336, longitude: 21.765708 },
  { name: 'VP062', latitude: 45.706389, longitude: 16.393611 },
  { name: 'D073E', latitude: 45.706886, longitude: 12.220458 },
  { name: 'IPINO', latitude: 45.707222, longitude: 13.284167 },
  { name: 'VP063', latitude: 45.708333, longitude: 16.109444 },
  { name: 'D155L', latitude: 45.708369, longitude: 16.407203 },
  { name: 'TUTIV', latitude: 45.708611, longitude: 13.826667 },
  { name: 'CT26', latitude: 45.709339, longitude: 12.340833 },
  { name: 'D239F', latitude: 45.710181, longitude: 16.139642 },
  { name: '18THR', latitude: 45.711331, longitude: 16.020767 },
  { name: 'TR941', latitude: 45.711592, longitude: 21.305947 },
  { name: '20DME', latitude: 45.711639, longitude: 16.019417 },
  { name: 'D211T', latitude: 45.712253, longitude: 12.837631 },
  { name: 'VP064', latitude: 45.713889, longitude: 15.817222 },
  { name: 'POHES', latitude: 45.714144, longitude: 14.780794 },
  { name: 'ZA709', latitude: 45.719, longitude: 15.850306 },
  { name: 'NIORR', latitude: 45.720278, longitude: 13.423333 },
  { name: 'D078O', latitude: 45.720642, longitude: 12.458664 },
  { name: 'EKSUM', latitude: 45.723333, longitude: 14.567222 },
  { name: 'D242Y', latitude: 45.725058, longitude: 15.764064 },
  { name: 'D073I', latitude: 45.72615, longitude: 12.323908 },
  { name: 'EKNAB', latitude: 45.727778, longitude: 22.500833 },
  { name: 'ULGEK', latitude: 45.729722, longitude: 30.937222 },
  { name: 'TR221', latitude: 45.729878, longitude: 21.653928 },
  { name: 'VP009', latitude: 45.73, longitude: 15.201389 },
  { name: 'D079R', latitude: 45.733664, longitude: 12.540111 },
  { name: 'ZA803', latitude: 45.733722, longitude: 16.306167 },
  { name: 'TOKNO', latitude: 45.735278, longitude: 36.868611 },
  { name: 'TR931', latitude: 45.738872, longitude: 21.197161 },
  { name: 'VP439', latitude: 45.740833, longitude: 13.3225 },
  { name: 'VP440', latitude: 45.741667, longitude: 12.4625 },
  { name: 'POGOD', latitude: 45.743333, longitude: 32.096667 },
  { name: 'RI30B', latitude: 45.744106, longitude: 12.470069 },
  { name: 'PQ512', latitude: 45.745056, longitude: 13.359611 },
  { name: 'D068T', latitude: 45.745219, longitude: 12.126267 },
  { name: 'PQ523', latitude: 45.745222, longitude: 13.485194 },
  { name: 'D155J', latitude: 45.745289, longitude: 16.389064 },
  { name: 'PQ513', latitude: 45.745611, longitude: 13.205556 },
  { name: 'MAXUR', latitude: 45.745908, longitude: 14.910311 },
  { name: 'BAREB', latitude: 45.746111, longitude: 18.413333 },
  { name: 'D079W', latitude: 45.7462, longitude: 12.657742 },
  { name: 'CF29', latitude: 45.747092, longitude: 21.586564 },
  { name: 'D237R', latitude: 45.749533, longitude: 15.931989 },
  { name: 'RIDLA', latitude: 45.749722, longitude: 37.365 },
  { name: 'D064J', latitude: 45.749733, longitude: 12.337764 },
  { name: 'ZA610', latitude: 45.752361, longitude: 15.805333 },
  { name: 'OTMOH', latitude: 45.754247, longitude: 14.937272 },
  { name: 'D211Q', latitude: 45.754808, longitude: 12.875081 },
  { name: 'VP065', latitude: 45.755833, longitude: 16.0125 },
  { name: 'VP010', latitude: 45.757778, longitude: 14.052222 },
  { name: 'MM22', latitude: 45.758308, longitude: 16.092178 },
  { name: 'FF29', latitude: 45.758417, longitude: 21.541889 },
  { name: 'VP066', latitude: 45.758611, longitude: 15.900833 },
  { name: 'D242T', latitude: 45.759475, longitude: 15.872678 },
  { name: 'COK25', latitude: 45.762392, longitude: 14.958628 },
  { name: 'TR213', latitude: 45.766564, longitude: 21.926594 },
  { name: 'TITAG', latitude: 45.766667, longitude: 33.5 },
  { name: 'TR130', latitude: 45.766861, longitude: 21.085014 },
  { name: 'EDETA', latitude: 45.768969, longitude: 24.931181 },
  { name: 'ODNAK', latitude: 45.769167, longitude: 35.778889 },
  { name: 'BOCAR', latitude: 45.770833, longitude: 20.288611 },
  { name: 'D098K', latitude: 45.771639, longitude: 21.596889 },
  { name: 'SBI27', latitude: 45.772372, longitude: 24.730633 },
  { name: 'GORPA', latitude: 45.773075, longitude: 15.353242 },
  { name: 'VP448', latitude: 45.773611, longitude: 13.604444 },
  { name: 'ESALO', latitude: 45.774722, longitude: 30.458611 },
  { name: 'D185I', latitude: 45.775533, longitude: 12.402544 },
  { name: 'SB272', latitude: 45.776767, longitude: 24.579403 },
  { name: 'EPURA', latitude: 45.778408, longitude: 24.505047 },
  { name: 'SB271', latitude: 45.779161, longitude: 24.460392 },
  { name: 'XEBUN', latitude: 45.779756, longitude: 24.437544 },
  { name: 'D237Y', latitude: 45.780033, longitude: 12.578067 },
  { name: 'VP449', latitude: 45.780278, longitude: 12.256667 },
  { name: 'VP450', latitude: 45.780833, longitude: 12.835556 },
  { name: 'ETNIL', latitude: 45.781389, longitude: 31.396667 },
  { name: 'ALOXU', latitude: 45.781431, longitude: 24.341378 },
  { name: 'TR011', latitude: 45.781967, longitude: 21.448533 },
  { name: 'SB991', latitude: 45.782558, longitude: 24.279756 },
  { name: 'CI27', latitude: 45.782742, longitude: 24.27 },
  { name: '40DM2', latitude: 45.782972, longitude: 21.444556 },
  { name: 'BEO59', latitude: 45.783333, longitude: 20.316667 },
  { name: 'FI27', latitude: 45.783583, longitude: 24.222361 },
  { name: 'ML27', latitude: 45.785194, longitude: 24.127111 },
  { name: '38DME', latitude: 45.787389, longitude: 23.997806 },
  { name: 'OSDUK', latitude: 45.7875, longitude: 18.133611 },
  { name: 'SB771', latitude: 45.787594, longitude: 23.977894 },
  { name: '62DME', latitude: 45.788222, longitude: 23.940556 },
  { name: 'VBA28', latitude: 45.788522, longitude: 16.473075 },
  { name: '80DME', latitude: 45.788833, longitude: 23.897667 },
  { name: 'VP451', latitude: 45.788889, longitude: 12.788889 },
  { name: 'FQ09', latitude: 45.7895, longitude: 23.848972 },
  { name: 'EMPOS', latitude: 45.791133, longitude: 23.729939 },
  { name: 'SB091', latitude: 45.792639, longitude: 23.610914 },
  { name: 'TR131', latitude: 45.794736, longitude: 20.972756 },
  { name: 'TR212', latitude: 45.795264, longitude: 21.814767 },
  { name: 'VP452', latitude: 45.796667, longitude: 12.439444 },
  { name: 'ZA902', latitude: 45.797889, longitude: 16.225528 },
  { name: 'TR935', latitude: 45.79805, longitude: 20.858439 },
  { name: 'OMISU', latitude: 45.799889, longitude: 21.603361 },
  { name: 'D191N', latitude: 45.800236, longitude: 12.5133 },
  { name: 'MA29', latitude: 45.801056, longitude: 21.372778 },
  { name: 'ZA614', latitude: 45.803806, longitude: 16.656611 },
  { name: '47THR', latitude: 45.805475, longitude: 16.164069 },
  { name: 'OM22', latitude: 45.805711, longitude: 16.164539 },
  { name: 'BERTU', latitude: 45.81, longitude: 32.236667 },
  { name: 'ULMIN', latitude: 45.81385, longitude: 23.397956 },
  { name: 'VP067', latitude: 45.814167, longitude: 15.833056 },
  { name: 'TINBO', latitude: 45.817722, longitude: 16.42725 },
  { name: 'D144O', latitude: 45.820394, longitude: 12.786839 },
  { name: 'D224O', latitude: 45.821811, longitude: 12.831239 },
  { name: 'GIDRO', latitude: 45.821944, longitude: 28.734167 },
  { name: 'TR132', latitude: 45.822503, longitude: 20.860386 },
  { name: 'PP020', latitude: 45.822694, longitude: 18.211667 },
  { name: 'ITVAX', latitude: 45.823775, longitude: 24.473828 },
  { name: 'TR211', latitude: 45.823856, longitude: 21.702825 },
  { name: 'ZA707', latitude: 45.824194, longitude: 16.767806 },
  { name: 'D211L', latitude: 45.826981, longitude: 12.934653 },
  { name: 'D267G', latitude: 45.827047, longitude: 13.313486 },
  { name: 'CD09', latitude: 45.827319, longitude: 13.289631 },
  { name: 'PQ522', latitude: 45.827556, longitude: 13.521444 },
  { name: 'FD09', latitude: 45.827969, longitude: 13.337292 },
  { name: 'RINEK', latitude: 45.828056, longitude: 39.863333 },
  { name: 'D267E', latitude: 45.828289, longitude: 13.361122 },
  { name: 'PQ601', latitude: 45.828389, longitude: 13.313419 },
  { name: '40VOR', latitude: 45.8286, longitude: 13.384953 },
  { name: 'LEIRA', latitude: 45.828611, longitude: 13.29 },
  { name: 'FI09', latitude: 45.828708, longitude: 13.289711 },
  { name: 'MA09', latitude: 45.828781, longitude: 13.432581 },
  { name: 'CI09X', latitude: 45.828889, longitude: 13.242042 },
  { name: 'PQ401', latitude: 45.828939, longitude: 13.206103 },
  { name: 'PQ402', latitude: 45.829158, longitude: 13.122664 },
  { name: 'MD09', latitude: 45.829214, longitude: 13.432614 },
  { name: 'EPUPO', latitude: 45.830131, longitude: 24.515386 },
  { name: 'ERUKO', latitude: 45.8307, longitude: 22.091175 },
  { name: 'ZA801', latitude: 45.831556, longitude: 16.203917 },
  { name: 'ZA613', latitude: 45.834167, longitude: 16.382528 },
  { name: 'TR021', latitude: 45.834825, longitude: 21.2382 },
  { name: '40DME', latitude: 45.836694, longitude: 21.230667 },
  { name: 'MARTE', latitude: 45.836753, longitude: 12.696139 },
  { name: 'TIXIP', latitude: 45.8375, longitude: 22.646667 },
  { name: 'VP011', latitude: 45.838333, longitude: 15.334167 },
  { name: 'ROLBA', latitude: 45.8402, longitude: 15.655053 },
  { name: 'ZA710', latitude: 45.841667, longitude: 16.036389 },
  { name: 'LULUD', latitude: 45.8425, longitude: 15.683333 },
  { name: 'VP012', latitude: 45.843611, longitude: 15.188333 },
  { name: 'D076W', latitude: 45.843658, longitude: 24.633283 },
  { name: 'ZA609', latitude: 45.848889, longitude: 15.951611 },
  { name: 'PAYES', latitude: 45.850014, longitude: 12.650331 },
  { name: 'TR133', latitude: 45.850158, longitude: 20.747906 },
  { name: 'RIFEN', latitude: 45.851111, longitude: 13.589722 },
  { name: 'LUXOT', latitude: 45.853497, longitude: 21.586194 },
  { name: 'D211J', latitude: 45.854058, longitude: 12.962689 },
  { name: 'PP011', latitude: 45.854528, longitude: 18.321833 },
  { name: 'VP460', latitude: 45.855278, longitude: 13.056389 },
  { name: 'ROMUX', latitude: 45.855833, longitude: 20.623333 },
  { name: 'VP068', latitude: 45.856111, longitude: 15.806111 },
  { name: 'KUPEV', latitude: 45.856753, longitude: 12.321847 },
  { name: 'D223L', latitude: 45.857525, longitude: 12.881514 },
  { name: 'CI34', latitude: 45.857644, longitude: 18.319819 },
  { name: 'TR023', latitude: 45.857783, longitude: 21.146228 },
  { name: 'SOPAS', latitude: 45.859444, longitude: 39.716667 },
  { name: 'D100I', latitude: 45.859547, longitude: 16.515117 },
  { name: 'VP461', latitude: 45.859722, longitude: 12.811389 },
  { name: 'FF11', latitude: 45.861111, longitude: 21.132889 },
  { name: 'VP013', latitude: 45.861389, longitude: 14.263889 },
  { name: 'VP014', latitude: 45.862778, longitude: 14.6525 },
  { name: 'FI22', latitude: 45.862917, longitude: 16.251889 },
  { name: 'DIDEX', latitude: 45.863139, longitude: 16.252222 },
  { name: 'MOBRA', latitude: 45.864444, longitude: 24.819444 },
  { name: 'TARKA', latitude: 45.865, longitude: 31.1 },
  { name: 'D191H', latitude: 45.865942, longitude: 13.044775 },
  { name: 'VP462', latitude: 45.866667, longitude: 12.974167 },
  { name: 'CF11', latitude: 45.872281, longitude: 21.087994 },
  { name: 'D197K', latitude: 45.873889, longitude: 15.991944 },
  { name: 'ZA706', latitude: 45.874444, longitude: 16.514583 },
  { name: 'D259J', latitude: 45.875806, longitude: 16.069786 },
  { name: 'VP015', latitude: 45.876389, longitude: 13.966389 },
  { name: 'SB273', latitude: 45.876694, longitude: 24.583617 },
  { name: 'D250X', latitude: 45.877489, longitude: 12.540864 },
  { name: 'D213K', latitude: 45.877631, longitude: 12.436361 },
  { name: 'PP014', latitude: 45.878111, longitude: 18.178833 },
  { name: 'D237N', latitude: 45.878717, longitude: 12.799144 },
  { name: 'AGASU', latitude: 45.881111, longitude: 21.808056 },
  { name: 'SB275', latitude: 45.881358, longitude: 24.345167 },
  { name: 'TEMGI', latitude: 45.881667, longitude: 39.3875 },
  { name: 'OTRAD', latitude: 45.881944, longitude: 32.387222 },
  { name: 'OTRIX', latitude: 45.882483, longitude: 24.283436 },
  { name: 'UPOLI', latitude: 45.885114, longitude: 24.131442 },
  { name: 'PP010', latitude: 45.88625, longitude: 18.432111 },
  { name: 'D237M', latitude: 45.8871, longitude: 12.819764 },
  { name: 'DIPUX', latitude: 45.888317, longitude: 21.867325 },
  { name: 'DIDIX', latitude: 45.889006, longitude: 21.020564 },
  { name: 'SB094', latitude: 45.889422, longitude: 23.851869 },
  { name: 'UGZIR', latitude: 45.89, longitude: 37.583333 },
  { name: 'SB093', latitude: 45.89105, longitude: 23.732631 },
  { name: 'EMBIB', latitude: 45.892261, longitude: 23.637325 },
  { name: 'SB092', latitude: 45.892553, longitude: 23.613392 },
  { name: 'ZARVE', latitude: 45.892908, longitude: 14.947867 },
  { name: 'D265U', latitude: 45.8941, longitude: 15.019428 },
  { name: 'KUKFI', latitude: 45.895278, longitude: 15.090833 },
  { name: 'HONFA', latitude: 45.895556, longitude: 30.875278 },
  { name: 'ZA802', latitude: 45.89575, longitude: 16.302194 },
  { name: 'KOGIN', latitude: 45.895883, longitude: 12.404531 },
  { name: 'D265P', latitude: 45.895917, longitude: 15.138714 },
  { name: 'GITMU', latitude: 45.896944, longitude: 21.314444 },
  { name: 'D265M', latitude: 45.897006, longitude: 15.210289 },
  { name: 'D294K', latitude: 45.897028, longitude: 21.098583 },
  { name: 'CF09', latitude: 45.897069, longitude: 15.312358 },
  { name: 'D265L', latitude: 45.897358, longitude: 15.234147 },
  { name: 'IZMAH', latitude: 45.897778, longitude: 15.36 },
  { name: 'D238E', latitude: 45.898133, longitude: 12.845986 },
  { name: 'OM09', latitude: 45.898189, longitude: 15.391508 },
  { name: 'PP006', latitude: 45.898278, longitude: 18.295972 },
  { name: 'VP016', latitude: 45.898889, longitude: 15.026667 },
  { name: 'FD09', latitude: 45.899147, longitude: 15.360606 },
  { name: 'MM09', latitude: 45.899525, longitude: 15.497375 },
  { name: '42VOR', latitude: 45.899942, longitude: 15.420258 },
  { name: 'NIVAS', latitude: 45.9, longitude: 12.281389 },
  { name: 'D155G', latitude: 45.900453, longitude: 18.293747 },
  { name: 'VP465', latitude: 45.900556, longitude: 12.308056 },
  { name: 'MD09', latitude: 45.900736, longitude: 15.482297 },
  { name: 'D170K', latitude: 45.901781, longitude: 14.805008 },
  { name: 'D085C', latitude: 45.902056, longitude: 15.592064 },
  { name: 'MAXOX', latitude: 45.903019, longitude: 12.394464 },
  { name: 'ZA503', latitude: 45.903389, longitude: 16.313889 },
  { name: 'D237K', latitude: 45.903853, longitude: 12.861022 },
  { name: 'D215N', latitude: 45.904067, longitude: 14.565828 },
  { name: 'MIVSA', latitude: 45.905556, longitude: 21.473056 },
  { name: 'VP069', latitude: 45.906111, longitude: 16.125556 },
  { name: 'D161K', latitude: 45.907306, longitude: 14.847739 },
  { name: 'GOCAT', latitude: 45.907892, longitude: 12.421072 },
  { name: 'PP012', latitude: 45.909972, longitude: 18.289056 },
  { name: 'FI34', latitude: 45.910028, longitude: 18.288933 },
  { name: 'VP467', latitude: 45.911667, longitude: 13.303889 },
  { name: 'VADBI', latitude: 45.914889, longitude: 21.115056 },
  { name: 'ISA33', latitude: 45.915633, longitude: 12.832419 },
  { name: 'CT06', latitude: 45.916406, longitude: 12.891983 },
  { name: 'D238B', latitude: 45.916742, longitude: 12.893975 },
  { name: 'TR122', latitude: 45.916817, longitude: 20.908028 },
  { name: 'VP468', latitude: 45.917222, longitude: 13.423611 },
  { name: 'TR901', latitude: 45.917808, longitude: 21.751889 },
  { name: 'IBINU', latitude: 45.918039, longitude: 23.278756 },
  { name: 'D237T', latitude: 45.921153, longitude: 14.361489 },
  { name: 'GESBA', latitude: 45.922778, longitude: 21.79 },
  { name: 'VP469', latitude: 45.923611, longitude: 13.144444 },
  { name: 'AR941', latitude: 45.924444, longitude: 21.304722 },
  { name: 'D324G', latitude: 45.924942, longitude: 13.388289 },
  { name: 'MABEP', latitude: 45.928611, longitude: 33.398611 },
  { name: 'FT06', latitude: 45.928953, longitude: 12.922958 },
  { name: 'TUKBO', latitude: 45.930864, longitude: 12.621539 },
  { name: 'BABIT', latitude: 45.931667, longitude: 18.928889 },
  { name: 'ZA807', latitude: 45.934389, longitude: 16.065833 },
  { name: 'ISA36', latitude: 45.936147, longitude: 12.897739 },
  { name: 'AR932', latitude: 45.936389, longitude: 21.678611 },
  { name: 'LBL31', latitude: 45.936906, longitude: 15.031206 },
  { name: 'LJ624', latitude: 45.937389, longitude: 14.757 },
  { name: 'DUSAN', latitude: 45.938333, longitude: 19.751389 },
  { name: 'LJ417', latitude: 45.939972, longitude: 14.559194 },
  { name: 'SEHOR', latitude: 45.940278, longitude: 14.313333 },
  { name: 'TR123', latitude: 45.944519, longitude: 20.795378 },
  { name: 'LJ622', latitude: 45.944917, longitude: 14.788833 },
  { name: 'LJ416', latitude: 45.945667, longitude: 14.420667 },
  { name: 'ZA809', latitude: 45.948056, longitude: 16.401417 },
  { name: 'VASIS', latitude: 45.953222, longitude: 22.408075 },
  { name: 'VP474', latitude: 45.954722, longitude: 12.989444 },
  { name: 'LJ619', latitude: 45.955139, longitude: 14.989667 },
  { name: 'DOLAR', latitude: 45.955914, longitude: 12.487308 },
  { name: 'D220D', latitude: 45.957069, longitude: 18.167606 },
  { name: 'PP007', latitude: 45.958083, longitude: 18.157611 },
  { name: 'VADEL', latitude: 45.959672, longitude: 12.483644 },
  { name: 'RERNA', latitude: 45.959889, longitude: 16.40075 },
  { name: 'NEPOT', latitude: 45.960278, longitude: 23.088056 },
  { name: 'TR911', latitude: 45.964175, longitude: 21.145381 },
  { name: 'ODOKA', latitude: 45.966972, longitude: 16.227806 },
  { name: 'D222E', latitude: 45.967908, longitude: 12.503883 },
  { name: 'SOVOX', latitude: 45.968333, longitude: 13.597222 },
  { name: 'TR022', latitude: 45.970075, longitude: 21.545897 },
  { name: 'LJ414', latitude: 45.970222, longitude: 14.255583 },
  { name: 'MT06', latitude: 45.970711, longitude: 13.026306 },
  { name: 'ADOMO', latitude: 45.971233, longitude: 14.645031 },
  { name: 'MAGAM', latitude: 45.972669, longitude: 15.702931 },
  { name: 'D251Y', latitude: 45.972961, longitude: 14.202256 },
  { name: 'BOZKE', latitude: 45.973056, longitude: 30.675833 },
  { name: 'RUSAG', latitude: 45.973439, longitude: 24.264406 },
  { name: 'OGODI', latitude: 45.974742, longitude: 13.895464 },
  { name: 'VP017', latitude: 45.975, longitude: 15.482222 },
  { name: 'TAVRU', latitude: 45.975, longitude: 28.865 },
  { name: 'ZA903', latitude: 45.981361, longitude: 15.979 },
  { name: 'ALCAG', latitude: 45.982306, longitude: 12.523789 },
  { name: 'ADUXI', latitude: 45.983333, longitude: 21.068611 },
  { name: 'LJ415', latitude: 45.986639, longitude: 14.52475 },
  { name: 'VEBAL', latitude: 45.991389, longitude: 17.296667 },
  { name: 'VP476', latitude: 45.9925, longitude: 12.317222 },
  { name: 'AR013', latitude: 45.994722, longitude: 21.448056 },
  { name: 'FOCSA', latitude: 45.994722, longitude: 26.689722 },
  { name: 'VP477', latitude: 45.995833, longitude: 13.088333 },
  { name: 'LOGBO', latitude: 45.996667, longitude: 34.603056 },
  { name: '4613E', latitude: 46.0, longitude: 13.0 },
  { name: '4614E', latitude: 46.0, longitude: 14.0 },
  { name: '4615E', latitude: 46.0, longitude: 15.0 },
  { name: '4616E', latitude: 46.0, longitude: 16.0 },
  { name: '4617E', latitude: 46.0, longitude: 17.0 },
  { name: '4618E', latitude: 46.0, longitude: 18.0 },
  { name: '4619E', latitude: 46.0, longitude: 19.0 },
  { name: '4620E', latitude: 46.0, longitude: 20.0 },
  { name: '4621E', latitude: 46.0, longitude: 21.0 },
  { name: '4622E', latitude: 46.0, longitude: 22.0 },
  { name: '4623E', latitude: 46.0, longitude: 23.0 },
  { name: '4624E', latitude: 46.0, longitude: 24.0 },
  { name: '4625E', latitude: 46.0, longitude: 25.0 },
  { name: '4626E', latitude: 46.0, longitude: 26.0 },
  { name: '4627E', latitude: 46.0, longitude: 27.0 },
  { name: '4628E', latitude: 46.0, longitude: 28.0 },
  { name: '4629E', latitude: 46.0, longitude: 29.0 },
  { name: '4630E', latitude: 46.0, longitude: 30.0 },
  { name: '4631E', latitude: 46.0, longitude: 31.0 },
  { name: '4632E', latitude: 46.0, longitude: 32.0 },
  { name: '4633E', latitude: 46.0, longitude: 33.0 },
  { name: '4634E', latitude: 46.0, longitude: 34.0 },
  { name: '4635E', latitude: 46.0, longitude: 35.0 },
  { name: '4636E', latitude: 46.0, longitude: 36.0 },
  { name: '4637E', latitude: 46.0, longitude: 37.0 },
  { name: '4638E', latitude: 46.0, longitude: 38.0 },
  { name: '4639E', latitude: 46.0, longitude: 39.0 },
  { name: '4640E', latitude: 46.0, longitude: 40.0 },
  { name: 'D215G', latitude: 46.000583, longitude: 14.679631 },
  { name: 'D237J', latitude: 46.002836, longitude: 14.569728 },
  { name: 'D288R', latitude: 46.005833, longitude: 15.918889 },
  { name: 'PP001', latitude: 46.005833, longitude: 18.232778 },
  { name: 'LUPIN', latitude: 46.006111, longitude: 13.411944 },
  { name: 'PETOD', latitude: 46.007808, longitude: 12.561683 },
  { name: 'BESVA', latitude: 46.008611, longitude: 21.145 },
  { name: 'TR112', latitude: 46.011111, longitude: 20.955831 },
  { name: 'AMNEZ', latitude: 46.011111, longitude: 30.5775 },
  { name: 'DEXAT', latitude: 46.012947, longitude: 12.567778 },
  { name: 'OLBAM', latitude: 46.013486, longitude: 12.566944 },
  { name: 'ZA808', latitude: 46.013806, longitude: 15.958722 },
  { name: 'ZA603', latitude: 46.015167, longitude: 16.205417 },
  { name: '20DME', latitude: 46.016336, longitude: 18.226683 },
  { name: 'D170D', latitude: 46.017828, longitude: 14.788211 },
  { name: 'D161D', latitude: 46.019839, longitude: 14.803781 },
  { name: 'D249M', latitude: 46.022394, longitude: 14.490383 },
  { name: 'D172M', latitude: 46.022422, longitude: 14.450242 },
  { name: 'DER05', latitude: 46.023164, longitude: 12.583925 },
  { name: 'ZA711', latitude: 46.023861, longitude: 16.315167 },
  { name: 'PP013', latitude: 46.029167, longitude: 18.218389 },
  { name: 'UXULA', latitude: 46.033433, longitude: 24.209986 },
  { name: 'UMBRA', latitude: 46.037408, longitude: 12.606494 },
  { name: 'VP018', latitude: 46.038056, longitude: 14.450556 },
  { name: 'IBAVA', latitude: 46.038103, longitude: 24.250836 },
  { name: 'TR113', latitude: 46.038858, longitude: 20.843014 },
  { name: 'D215D', latitude: 46.039158, longitude: 14.725264 },
  { name: 'D335C', latitude: 46.039381, longitude: 18.212294 },
  { name: 'TINOV', latitude: 46.039853, longitude: 12.603497 },
  { name: 'D251J', latitude: 46.040017, longitude: 14.547775 },
  { name: 'VIITA', latitude: 46.041083, longitude: 14.663722 },
  { name: 'VANAX', latitude: 46.041117, longitude: 15.731436 },
  { name: 'LJ623', latitude: 46.041417, longitude: 14.729528 },
  { name: 'LJ621', latitude: 46.043333, longitude: 14.740028 },
  { name: 'VP019', latitude: 46.044722, longitude: 15.223889 },
  { name: 'D095N', latitude: 46.045203, longitude: 15.108989 },
  { name: 'OLMIP', latitude: 46.045278, longitude: 22.37 },
  { name: 'TONDO', latitude: 46.047222, longitude: 19.355833 },
  { name: 'AR222', latitude: 46.0475, longitude: 21.694167 },
  { name: 'SOBSA', latitude: 46.048056, longitude: 25.087222 },
  { name: 'VP070', latitude: 46.049167, longitude: 16.084444 },
  { name: 'LJ617', latitude: 46.051361, longitude: 15.053556 },
  { name: 'UPETA', latitude: 46.05465, longitude: 14.098256 },
  { name: 'AR221', latitude: 46.055556, longitude: 21.575 },
  { name: 'D171K', latitude: 46.055914, longitude: 14.451497 },
  { name: 'PIKUV', latitude: 46.058364, longitude: 24.094647 },
  { name: 'KUBUD', latitude: 46.059167, longitude: 13.603056 },
  { name: 'EBITI', latitude: 46.059919, longitude: 14.985525 },
  { name: 'MODRO', latitude: 46.060433, longitude: 14.367631 },
  { name: 'LJ618', latitude: 46.061667, longitude: 14.775139 },
  { name: 'AR220', latitude: 46.063333, longitude: 21.455833 },
  { name: 'VP020', latitude: 46.066389, longitude: 15.178611 },
  { name: 'BUNAG', latitude: 46.066667, longitude: 30.433333 },
  { name: 'ABIDA', latitude: 46.066708, longitude: 20.729208 },
  { name: 'D095F', latitude: 46.067569, longitude: 14.920242 },
  { name: 'RISOM', latitude: 46.068056, longitude: 12.960833 },
  { name: 'LUPIX', latitude: 46.068278, longitude: 14.75975 },
  { name: 'LJ616', latitude: 46.069, longitude: 14.903278 },
  { name: 'D137M', latitude: 46.07, longitude: 14.6244 },
  { name: 'D327L', latitude: 46.070483, longitude: 16.167775 },
  { name: 'AR940', latitude: 46.071944, longitude: 21.324722 },
  { name: 'AMGOL', latitude: 46.073333, longitude: 31.435 },
  { name: 'FQ16', latitude: 46.075036, longitude: 18.192597 },
  { name: 'XIMTO', latitude: 46.078778, longitude: 14.590611 },
  { name: 'D225N', latitude: 46.080853, longitude: 12.679011 },
  { name: 'PP005', latitude: 46.081722, longitude: 18.187139 },
  { name: 'LJ615', latitude: 46.082472, longitude: 14.787361 },
  { name: 'AR950', latitude: 46.083611, longitude: 21.144444 },
  { name: 'AR951', latitude: 46.088056, longitude: 21.073056 },
  { name: 'RASIN', latitude: 46.090278, longitude: 16.675278 },
  { name: 'D129M', latitude: 46.09055, longitude: 14.656236 },
  { name: 'MIPRO', latitude: 46.090611, longitude: 14.7165 },
  { name: 'ROPUS', latitude: 46.091372, longitude: 14.191969 },
  { name: 'NELOM', latitude: 46.091667, longitude: 35.438611 },
  { name: 'PP002', latitude: 46.092, longitude: 18.310583 },
  { name: 'VP482', latitude: 46.095, longitude: 13.059167 },
  { name: 'SHAGI', latitude: 46.09765, longitude: 12.735794 },
  { name: 'VP021', latitude: 46.097778, longitude: 14.463333 },
  { name: 'D122N', latitude: 46.098031, longitude: 14.702575 },
  { name: 'PATUM', latitude: 46.099722, longitude: 37.386667 },
  { name: 'OO440', latitude: 46.102222, longitude: 30.642222 },
  { name: 'OLEZE', latitude: 46.105867, longitude: 15.314386 },
  { name: 'ZUBOK', latitude: 46.105978, longitude: 21.703228 },
  { name: 'OO450', latitude: 46.108889, longitude: 30.513889 },
  { name: 'LJ611', latitude: 46.11225, longitude: 14.7825 },
  { name: 'VP484', latitude: 46.116667, longitude: 12.908611 },
  { name: 'XANAN', latitude: 46.117525, longitude: 24.234119 },
  { name: 'CI30', latitude: 46.118431, longitude: 14.662714 },
  { name: 'BILRO', latitude: 46.118436, longitude: 24.229736 },
  { name: 'PIDON', latitude: 46.122222, longitude: 18.069444 },
  { name: 'NITOK', latitude: 46.125, longitude: 32.908333 },
  { name: 'LJ411', latitude: 46.127694, longitude: 14.470083 },
  { name: 'BASTA', latitude: 46.128333, longitude: 14.643333 },
  { name: 'D058F', latitude: 46.131583, longitude: 14.905094 },
  { name: 'ELSEK', latitude: 46.131983, longitude: 24.118794 },
  { name: 'PENEP', latitude: 46.133047, longitude: 14.298747 },
  { name: 'MEDUD', latitude: 46.133178, longitude: 20.890811 },
  { name: 'OO430', latitude: 46.134722, longitude: 30.798889 },
  { name: 'LJ612', latitude: 46.136083, longitude: 14.855389 },
  { name: 'PP004', latitude: 46.137139, longitude: 18.154111 },
  { name: 'GOTEK', latitude: 46.138056, longitude: 13.188889 },
  { name: 'NORTO', latitude: 46.141472, longitude: 14.770806 },
  { name: 'BARID', latitude: 46.142167, longitude: 21.013 },
  { name: 'AR012', latitude: 46.142778, longitude: 21.77 },
  { name: 'D024D', latitude: 46.142975, longitude: 14.823594 },
  { name: 'VP022', latitude: 46.145833, longitude: 14.414444 },
  { name: 'AR212', latitude: 46.146944, longitude: 21.708056 },
  { name: 'LEZEB', latitude: 46.149378, longitude: 15.476653 },
  { name: 'MEDFI', latitude: 46.151344, longitude: 15.520475 },
  { name: 'AR211', latitude: 46.155, longitude: 21.588889 },
  { name: 'CF27', latitude: 46.155056, longitude: 21.588806 },
  { name: 'INVED', latitude: 46.157778, longitude: 20.401389 },
  { name: 'CI27', latitude: 46.158306, longitude: 21.501444 },
  { name: 'IPDIS', latitude: 46.161267, longitude: 24.239708 },
  { name: 'FF27', latitude: 46.163167, longitude: 21.467217 },
  { name: 'MOPUG', latitude: 46.163611, longitude: 20.708056 },
  { name: 'PARAK', latitude: 46.163889, longitude: 20.094167 },
  { name: 'AR011', latitude: 46.164444, longitude: 21.4475 },
  { name: 'OOS05', latitude: 46.166111, longitude: 30.966389 },
  { name: '63DME', latitude: 46.166389, longitude: 21.418361 },
  { name: 'LJ613', latitude: 46.168139, longitude: 14.953778 },
  { name: 'PP003', latitude: 46.169, longitude: 18.264861 },
  { name: 'OO460', latitude: 46.169167, longitude: 30.962778 },
  { name: 'VP023', latitude: 46.169444, longitude: 15.393056 },
  { name: 'PODET', latitude: 46.171375, longitude: 15.626797 },
  { name: 'GIKUS', latitude: 46.173333, longitude: 13.304444 },
  { name: 'RATNO', latitude: 46.173333, longitude: 30.196389 },
  { name: 'ENILO', latitude: 46.174722, longitude: 38.031667 },
  { name: 'MD09', latitude: 46.178028, longitude: 21.239167 },
  { name: 'BABIB', latitude: 46.178611, longitude: 29.051389 },
  { name: 'D058L', latitude: 46.178886, longitude: 15.031822 },
  { name: '59VOR', latitude: 46.180214, longitude: 14.542578 },
  { name: 'AR021', latitude: 46.182689, longitude: 21.165569 },
  { name: 'KEROP', latitude: 46.184444, longitude: 19.696667 },
  { name: 'LJ551', latitude: 46.185583, longitude: 14.808694 },
  { name: 'D024G', latitude: 46.187322, longitude: 14.856786 },
  { name: 'OOW04', latitude: 46.189167, longitude: 30.479167 },
  { name: 'FD09', latitude: 46.18975, longitude: 21.053889 },
  { name: 'EVNUR', latitude: 46.191833, longitude: 21.019361 },
  { name: '47VOR', latitude: 46.192078, longitude: 14.519406 },
  { name: 'KODRU', latitude: 46.192222, longitude: 28.123889 },
  { name: 'OO490', latitude: 46.193333, longitude: 31.078611 },
  { name: 'VP024', latitude: 46.195, longitude: 14.471667 },
  { name: 'EPODO', latitude: 46.195992, longitude: 14.077028 },
  { name: 'AR111', latitude: 46.197222, longitude: 20.934167 },
  { name: 'ASGIT', latitude: 46.201944, longitude: 38.965556 },
  { name: 'LJ614', latitude: 46.202556, longitude: 15.059806 },
  { name: 'LJ511', latitude: 46.204056, longitude: 14.649444 },
  { name: 'LJ516', latitude: 46.204417, longitude: 14.476778 },
  { name: 'OO135', latitude: 46.204722, longitude: 30.769167 },
  { name: 'AR112', latitude: 46.205278, longitude: 20.799167 },
  { name: 'MD30', latitude: 46.209864, longitude: 14.484631 },
  { name: 'MM30', latitude: 46.209889, longitude: 14.484528 },
  { name: 'OOW02', latitude: 46.213056, longitude: 30.594444 },
  { name: 'VP025', latitude: 46.215, longitude: 14.436667 },
  { name: 'PELES', latitude: 46.217222, longitude: 27.053333 },
  { name: 'TIBRO', latitude: 46.218333, longitude: 13.472778 },
  { name: 'VP071', latitude: 46.221667, longitude: 16.309167 },
  { name: 'VP026', latitude: 46.225556, longitude: 14.614722 },
  { name: 'VP027', latitude: 46.226389, longitude: 14.030556 },
  { name: 'LJ512', latitude: 46.229472, longitude: 14.559528 },
  { name: 'LJ552', latitude: 46.23025, longitude: 14.847111 },
  { name: 'D024J', latitude: 46.231528, longitude: 14.890392 },
  { name: 'DER30', latitude: 46.232492, longitude: 14.440392 },
  { name: 'CELJE', latitude: 46.233056, longitude: 15.295278 },
  { name: 'D124A', latitude: 46.233408, longitude: 14.438058 },
  { name: 'PESUT', latitude: 46.237475, longitude: 13.716203 },
  { name: 'BM30', latitude: 46.238611, longitude: 14.428333 },
  { name: 'VP028', latitude: 46.239722, longitude: 15.603889 },
  { name: 'KOPRY', latitude: 46.240278, longitude: 16.962778 },
  { name: 'D340J', latitude: 46.24415, longitude: 14.711744 },
  { name: 'OO420', latitude: 46.245556, longitude: 30.751111 },
  { name: 'OOS03', latitude: 46.250833, longitude: 30.5775 },
  { name: 'ABTER', latitude: 46.253056, longitude: 25.841667 },
  { name: 'LJ711', latitude: 46.257417, longitude: 14.118278 },
  { name: 'ABISO', latitude: 46.262392, longitude: 14.352258 },
  { name: 'D024Y', latitude: 46.262411, longitude: 16.590217 },
  { name: 'LJ412', latitude: 46.262639, longitude: 14.134833 },
  { name: 'TEGRI', latitude: 46.262786, longitude: 21.104539 },
  { name: 'VP072', latitude: 46.264167, longitude: 16.543889 },
  { name: 'BEDOX', latitude: 46.266039, longitude: 15.826233 },
  { name: 'BINOM', latitude: 46.2725, longitude: 28.733611 },
  { name: 'D116Q', latitude: 46.272881, longitude: 31.004953 },
  { name: 'D024M', latitude: 46.275725, longitude: 14.92405 },
  { name: 'LJ553', latitude: 46.276111, longitude: 14.886639 },
  { name: 'TM074', latitude: 46.279664, longitude: 24.199822 },
  { name: 'OO470', latitude: 46.280278, longitude: 30.914722 },
  { name: 'TULNU', latitude: 46.280556, longitude: 27.004444 },
  { name: 'OO125', latitude: 46.283611, longitude: 30.735 },
  { name: 'VP073', latitude: 46.286111, longitude: 16.243611 },
  { name: 'TM072', latitude: 46.2878, longitude: 24.027928 },
  { name: 'RAMIX', latitude: 46.289444, longitude: 27.338611 },
  { name: 'IPTOL', latitude: 46.291167, longitude: 24.044794 },
  { name: 'VALLU', latitude: 46.291589, longitude: 15.336317 },
  { name: 'KENUX', latitude: 46.292222, longitude: 26.767778 },
  { name: 'CF34', latitude: 46.294303, longitude: 30.730078 },
  { name: 'VP029', latitude: 46.295556, longitude: 14.346944 },
  { name: 'OO480', latitude: 46.304444, longitude: 31.030556 },
  { name: 'KROPA', latitude: 46.305, longitude: 14.206944 },
  { name: 'D290J', latitude: 46.307392, longitude: 14.209789 },
  { name: 'LJ513', latitude: 46.308611, longitude: 14.393333 },
  { name: 'PETOV', latitude: 46.309675, longitude: 15.976167 },
  { name: 'TM073', latitude: 46.310792, longitude: 24.143422 },
  { name: 'D328E', latitude: 46.311992, longitude: 14.371981 },
  { name: 'VP030', latitude: 46.312778, longitude: 14.246944 },
  { name: 'NOPTI', latitude: 46.315278, longitude: 21.996389 },
  { name: 'KOSAK', latitude: 46.316667, longitude: 31.533333 },
  { name: 'OOS01', latitude: 46.317778, longitude: 30.898889 },
  { name: 'VP031', latitude: 46.318333, longitude: 15.456667 },
  { name: 'D141K', latitude: 46.319081, longitude: 15.846294 },
  { name: 'PINUB', latitude: 46.320264, longitude: 24.1912 },
  { name: 'VP032', latitude: 46.321389, longitude: 14.341944 },
  { name: 'LJ413', latitude: 46.324861, longitude: 13.983056 },
  { name: 'FF34', latitude: 46.326242, longitude: 30.716358 },
  { name: 'FD34', latitude: 46.326742, longitude: 30.724908 },
  { name: 'D212J', latitude: 46.331989, longitude: 15.5658 },
  { name: 'TM552', latitude: 46.334414, longitude: 24.262797 },
  { name: 'VP074', latitude: 46.340833, longitude: 16.539722 },
  { name: 'VP033', latitude: 46.345833, longitude: 14.068333 },
  { name: 'OO115', latitude: 46.346389, longitude: 30.707778 },
  { name: 'CF32', latitude: 46.346717, longitude: 15.815236 },
  { name: 'SUDIR', latitude: 46.349722, longitude: 40.465 },
  { name: 'BOTNA', latitude: 46.351111, longitude: 28.288056 },
  { name: 'EVRIK', latitude: 46.351944, longitude: 22.242222 },
  { name: 'TRAIA', latitude: 46.354167, longitude: 14.293056 },
  { name: 'EBORO', latitude: 46.355833, longitude: 19.9875 },
  { name: 'DONID', latitude: 46.359167, longitude: 39.751111 },
  { name: 'LJ514', latitude: 46.359278, longitude: 14.235361 },
  { name: 'TM252', latitude: 46.359569, longitude: 24.390719 },
  { name: 'OOE02', latitude: 46.360556, longitude: 30.88 },
  { name: 'LJ554', latitude: 46.360861, longitude: 14.854194 },
  { name: 'ANEDO', latitude: 46.363333, longitude: 29.691944 },
  { name: 'D093T', latitude: 46.366997, longitude: 31.143681 },
  { name: 'OO140', latitude: 46.369722, longitude: 31.150833 },
  { name: 'D340R', latitude: 46.372131, longitude: 14.657992 },
  { name: 'FF32', latitude: 46.374389, longitude: 15.788436 },
  { name: 'FN32', latitude: 46.37505, longitude: 15.789739 },
  { name: 'CEPRA', latitude: 46.375556, longitude: 15.206389 },
  { name: 'VP075', latitude: 46.376389, longitude: 16.385 },
  { name: 'VP034', latitude: 46.377778, longitude: 14.084722 },
  { name: 'KISET', latitude: 46.378056, longitude: 39.538333 },
  { name: 'OBUTI', latitude: 46.378303, longitude: 16.27405 },
  { name: 'VP493', latitude: 46.379167, longitude: 13.122222 },
  { name: 'VP076', latitude: 46.379444, longitude: 16.429444 },
  { name: 'TM071', latitude: 46.383831, longitude: 23.987958 },
  { name: 'OOE04', latitude: 46.384722, longitude: 30.995833 },
  { name: 'APTAN', latitude: 46.3875, longitude: 26.348889 },
  { name: 'DOTEV', latitude: 46.390881, longitude: 24.023294 },
  { name: 'VP035', latitude: 46.391667, longitude: 15.569167 },
  { name: 'VP036', latitude: 46.393889, longitude: 15.909167 },
  { name: 'TIDGA', latitude: 46.394167, longitude: 23.063056 },
  { name: 'LJ515', latitude: 46.395389, longitude: 13.997889 },
  { name: 'MAVIR', latitude: 46.398333, longitude: 19.825278 },
  { name: 'D122K', latitude: 46.400425, longitude: 27.037119 },
  { name: 'D249J', latitude: 46.401103, longitude: 24.074669 },
  { name: 'MD34', latitude: 46.402972, longitude: 30.682694 },
  { name: 'ASKUT', latitude: 46.403056, longitude: 28.237778 },
  { name: 'RUTUV', latitude: 46.406861, longitude: 24.103639 },
  { name: 'NEKIN', latitude: 46.407222, longitude: 16.703333 },
  { name: 'VP494', latitude: 46.408333, longitude: 13.308333 },
  { name: 'TM772', latitude: 46.409003, longitude: 24.644603 },
  { name: 'ML34', latitude: 46.409908, longitude: 30.680339 },
  { name: 'CF34', latitude: 46.412361, longitude: 26.953139 },
  { name: 'ARLONgitude', latitude: 46.413822, longitude: 15.029764 },
  { name: 'VP037', latitude: 46.415556, longitude: 14.093056 },
  { name: 'GIZOT', latitude: 46.415556, longitude: 32.896944 },
  { name: 'URAVA', latitude: 46.416111, longitude: 13.532222 },
  { name: 'VP038', latitude: 46.417222, longitude: 16.11 },
  { name: 'LJ517', latitude: 46.419889, longitude: 14.299972 },
  { name: 'IRLIX', latitude: 46.422506, longitude: 15.694289 },
  { name: 'D102H', latitude: 46.423822, longitude: 15.897825 },
  { name: 'ODESE', latitude: 46.426389, longitude: 30.669722 },
  { name: 'D328M', latitude: 46.429517, longitude: 14.281147 },
  { name: 'FF07', latitude: 46.429778, longitude: 24.219417 },
  { name: 'TM551', latitude: 46.430533, longitude: 24.223197 },
  { name: 'TM251', latitude: 46.431433, longitude: 24.760919 },
  { name: 'ZAG37', latitude: 46.436836, longitude: 15.872083 },
  { name: 'KANIN', latitude: 46.440556, longitude: 13.628611 },
  { name: 'EKRAT', latitude: 46.440669, longitude: 24.138689 },
  { name: 'LJ521', latitude: 46.441, longitude: 13.691111 },
  { name: 'ML16', latitude: 46.442172, longitude: 30.666408 },
  { name: 'IBGIR', latitude: 46.444167, longitude: 26.566389 },
  { name: 'VP495', latitude: 46.448611, longitude: 13.313889 },
  { name: 'MD16', latitude: 46.449119, longitude: 30.662733 },
  { name: 'FI34', latitude: 46.449372, longitude: 26.938583 },
  { name: 'BERTA', latitude: 46.449708, longitude: 14.625236 },
  { name: 'D109I', latitude: 46.449756, longitude: 27.023739 },
  { name: 'BAI60', latitude: 46.459925, longitude: 24.682889 },
  { name: 'MA07', latitude: 46.461556, longitude: 24.380972 },
  { name: 'ENOSO', latitude: 46.462586, longitude: 24.761361 },
  { name: 'BUXLE', latitude: 46.463889, longitude: 22.195278 },
  { name: 'SB095', latitude: 46.46555, longitude: 24.007139 },
  { name: 'DEGUM', latitude: 46.465864, longitude: 13.699278 },
  { name: 'DOKUD', latitude: 46.468736, longitude: 24.955931 },
  { name: 'TUVAP', latitude: 46.469167, longitude: 29.328056 },
  { name: '25LOC', latitude: 46.475053, longitude: 30.652167 },
  { name: 'MIRSO', latitude: 46.476111, longitude: 15.841944 },
  { name: 'SUNOR', latitude: 46.479722, longitude: 17.297222 },
  { name: 'ZAG38', latitude: 46.4824, longitude: 15.945764 },
  { name: 'TOMET', latitude: 46.486667, longitude: 25.592222 },
  { name: '50DME', latitude: 46.488694, longitude: 24.52025 },
  { name: 'D142A', latitude: 46.489228, longitude: 15.677017 },
  { name: 'NIPEL', latitude: 46.489472, longitude: 14.032597 },
  { name: 'D071S', latitude: 46.493117, longitude: 31.115031 },
  { name: 'DEGET', latitude: 46.493611, longitude: 21.267222 },
  { name: 'MIKVI', latitude: 46.498611, longitude: 26.125833 },
  { name: 'MN34', latitude: 46.501431, longitude: 26.918219 },
  { name: 'BUCSA', latitude: 46.503333, longitude: 26.367222 },
  { name: 'VP496', latitude: 46.504167, longitude: 13.586111 },
  { name: 'TM771', latitude: 46.505264, longitude: 24.605611 },
  { name: 'FQ25', latitude: 46.505306, longitude: 24.605889 },
  { name: 'VP497', latitude: 46.505556, longitude: 13.305556 },
  { name: 'OO315', latitude: 46.506111, longitude: 30.638611 },
  { name: 'REDKU', latitude: 46.506667, longitude: 26.548889 },
  { name: 'VP498', latitude: 46.506944, longitude: 13.4375 },
  { name: 'KLAGY', latitude: 46.5143, longitude: 14.775169 },
  { name: 'D171I', latitude: 46.519767, longitude: 21.183178 },
  { name: 'RUVER', latitude: 46.520278, longitude: 29.088889 },
  { name: 'GIMIX', latitude: 46.522989, longitude: 13.714081 },
  { name: 'FF16', latitude: 46.525964, longitude: 30.630075 },
  { name: 'OOE06', latitude: 46.526944, longitude: 31.123889 },
  { name: 'OO150', latitude: 46.527222, longitude: 31.104444 },
  { name: 'SM602', latitude: 46.527472, longitude: 17.094528 },
  { name: 'ETEPA', latitude: 46.527739, longitude: 24.722114 },
  { name: 'SM008', latitude: 46.527972, longitude: 17.093833 },
  { name: 'BC011', latitude: 46.528139, longitude: 21.184028 },
  { name: 'FD16', latitude: 46.528969, longitude: 30.638131 },
  { name: 'TM075', latitude: 46.529083, longitude: 24.052794 },
  { name: 'BUKAN', latitude: 46.531667, longitude: 22.891667 },
  { name: 'UPADA', latitude: 46.532778, longitude: 40.093333 },
  { name: 'VP500', latitude: 46.534722, longitude: 13.638889 },
  { name: 'BC002', latitude: 46.535083, longitude: 21.079222 },
  { name: 'LAPNA', latitude: 46.535497, longitude: 15.520431 },
  { name: 'OON02', latitude: 46.535833, longitude: 30.453889 },
  { name: 'VP039', latitude: 46.539444, longitude: 16.183611 },
  { name: 'ARNOS', latitude: 46.541256, longitude: 13.569311 },
  { name: 'BC003', latitude: 46.541889, longitude: 21.182139 },
  { name: 'D356J', latitude: 46.5428, longitude: 15.793306 },
  { name: 'D073S', latitude: 46.543342, longitude: 16.1382 },
  { name: 'VP040', latitude: 46.543611, longitude: 15.5 },
  { name: 'SM006', latitude: 46.544139, longitude: 17.213 },
  { name: 'D340I', latitude: 46.544619, longitude: 17.213917 },
  { name: 'SURAP', latitude: 46.544722, longitude: 29.9 },
  { name: 'D340G', latitude: 46.545108, longitude: 17.213728 },
  { name: 'RATGO', latitude: 46.546111, longitude: 16.036944 },
  { name: 'IPRUS', latitude: 46.547222, longitude: 35.026944 },
  { name: 'VP001', latitude: 46.5475, longitude: 14.262222 },
  { name: 'TARLI', latitude: 46.548056, longitude: 39.01 },
  { name: 'SM601', latitude: 46.548583, longitude: 17.211306 },
  { name: 'SM009', latitude: 46.549167, longitude: 17.211111 },
  { name: 'OOW03', latitude: 46.549722, longitude: 30.320833 },
  { name: 'CQ34', latitude: 46.549936, longitude: 17.211858 },
  { name: 'BANID', latitude: 46.555278, longitude: 31.541111 },
  { name: 'ARCAZ', latitude: 46.555556, longitude: 21.457222 },
  { name: 'D191G', latitude: 46.557711, longitude: 21.123456 },
  { name: 'CF16', latitude: 46.557881, longitude: 30.616206 },
  { name: 'D346K', latitude: 46.559547, longitude: 15.745239 },
  { name: 'VEKEN', latitude: 46.563611, longitude: 13.379444 },
  { name: 'OO325', latitude: 46.568611, longitude: 30.611389 },
  { name: 'VP002', latitude: 46.571667, longitude: 13.7 },
  { name: 'D068M', latitude: 46.573189, longitude: 27.126189 },
  { name: 'OOW01', latitude: 46.573611, longitude: 30.436667 },
  { name: 'WK812', latitude: 46.576717, longitude: 14.666775 },
  { name: 'VP041', latitude: 46.577778, longitude: 15.835833 },
  { name: 'VP003', latitude: 46.580556, longitude: 14.383333 },
  { name: 'CI03', latitude: 46.581119, longitude: 32.416942 },
  { name: 'MEBAM', latitude: 46.583333, longitude: 36.7 },
  { name: 'REKTI', latitude: 46.584539, longitude: 13.897447 },
  { name: 'WK832', latitude: 46.588275, longitude: 14.608739 },
  { name: 'LUMUS', latitude: 46.590103, longitude: 14.1563 },
  { name: 'LOBRO', latitude: 46.594167, longitude: 28.733056 },
  { name: 'BC012', latitude: 46.594472, longitude: 21.070889 },
  { name: 'D355G', latitude: 46.595194, longitude: 15.689333 },
  { name: 'SARZI', latitude: 46.596667, longitude: 38.715833 },
  { name: 'WK827', latitude: 46.597583, longitude: 14.562322 },
  { name: 'D046Q', latitude: 46.599342, longitude: 31.003631 },
  { name: 'DIRCO', latitude: 46.599444, longitude: 27.254722 },
  { name: 'FQ35R', latitude: 46.601156, longitude: 21.173172 },
  { name: 'BC014', latitude: 46.601306, longitude: 21.173917 },
  { name: 'VP004', latitude: 46.601667, longitude: 13.53 },
  { name: 'OON04', latitude: 46.6025, longitude: 30.776111 },
  { name: 'FF28R', latitude: 46.602814, longitude: 14.534031 },
  { name: 'MOLID', latitude: 46.606389, longitude: 27.288333 },
  { name: 'OO220', latitude: 46.606667, longitude: 30.595278 },
  { name: 'VP042', latitude: 46.6075, longitude: 15.676111 },
  { name: 'IXUBA', latitude: 46.607542, longitude: 23.976272 },
  { name: 'FI03', latitude: 46.609081, longitude: 32.443233 },
  { name: 'DIPSA', latitude: 46.609606, longitude: 14.922244 },
  { name: 'FF03', latitude: 46.609686, longitude: 32.44435 },
  { name: 'VP006', latitude: 46.61, longitude: 14.051944 },
  { name: 'TM253', latitude: 46.610056, longitude: 24.688825 },
  { name: 'SOGMO', latitude: 46.610278, longitude: 17.684167 },
  { name: 'RAPUL', latitude: 46.611111, longitude: 32.004444 },
  { name: 'SM010', latitude: 46.612167, longitude: 17.18725 },
  { name: 'FQ34', latitude: 46.612706, longitude: 17.187506 },
  { name: 'OOE03', latitude: 46.613056, longitude: 31.188889 },
  { name: 'FN16', latitude: 46.613083, longitude: 26.874306 },
  { name: 'WK833', latitude: 46.615539, longitude: 14.471333 },
  { name: 'D345O', latitude: 46.619139, longitude: 15.72345 },
  { name: 'VP005', latitude: 46.619167, longitude: 14.2525 },
  { name: 'NIKAB', latitude: 46.619167, longitude: 17.545556 },
  { name: 'WK610', latitude: 46.621086, longitude: 14.443781 },
  { name: '40LOC', latitude: 46.621158, longitude: 14.443578 },
  { name: '38THR', latitude: 46.621278, longitude: 14.442981 },
  { name: 'D091B', latitude: 46.622233, longitude: 14.582339 },
  { name: 'GAROC', latitude: 46.624444, longitude: 26.579722 },
  { name: 'LIPTA', latitude: 46.626389, longitude: 28.575 },
  { name: 'ASTOD', latitude: 46.627778, longitude: 27.393611 },
  { name: 'SM007', latitude: 46.631389, longitude: 17.098389 },
  { name: 'D031I', latitude: 46.63215, longitude: 26.953494 },
  { name: 'MOKEG', latitude: 46.633511, longitude: 14.382361 },
  { name: 'WK503', latitude: 46.633744, longitude: 13.770306 },
  { name: 'VOR11', latitude: 46.634183, longitude: 21.168978 },
  { name: '26THR', latitude: 46.634431, longitude: 21.169314 },
  { name: 'BOKSI', latitude: 46.635278, longitude: 19.830833 },
  { name: 'CL074', latitude: 46.635522, longitude: 24.123744 },
  { name: 'WK825', latitude: 46.638703, longitude: 14.662806 },
  { name: 'OO260', latitude: 46.640278, longitude: 30.759722 },
  { name: 'PEQIN', latitude: 46.643333, longitude: 27.227222 },
  { name: 'CN16', latitude: 46.644528, longitude: 26.861889 },
  { name: 'SM603', latitude: 46.645389, longitude: 16.996778 },
  { name: 'RADLY', latitude: 46.646858, longitude: 15.209175 },
  { name: 'TURSA', latitude: 46.648611, longitude: 29.943889 },
  { name: 'VP043', latitude: 46.653611, longitude: 16.075833 },
  { name: '26THR', latitude: 46.657561, longitude: 14.262814 },
  { name: 'OLGIN', latitude: 46.658333, longitude: 37.421667 },
  { name: 'VP001', latitude: 46.660278, longitude: 17.0625 },
  { name: 'OOE01', latitude: 46.664444, longitude: 30.876111 },
  { name: 'BC001', latitude: 46.664889, longitude: 21.165083 },
  { name: 'SM303', latitude: 46.667139, longitude: 16.988444 },
  { name: 'SM001', latitude: 46.667389, longitude: 17.093194 },
  { name: 'WK612', latitude: 46.667475, longitude: 14.218253 },
  { name: 'WK810', latitude: 46.667756, longitude: 14.211897 },
  { name: 'NATIV', latitude: 46.668056, longitude: 29.349444 },
  { name: 'WK831', latitude: 46.668206, longitude: 14.642869 },
  { name: 'GONUS', latitude: 46.675, longitude: 32.508333 },
  { name: 'D283H', latitude: 46.675319, longitude: 14.174047 },
  { name: 'SM011', latitude: 46.6755, longitude: 17.163167 },
  { name: 'BC005', latitude: 46.677553, longitude: 21.163328 },
  { name: 'BUGAC', latitude: 46.677639, longitude: 19.681722 },
  { name: 'WK809', latitude: 46.678039, longitude: 14.160386 },
  { name: 'D018K', latitude: 46.679564, longitude: 26.929594 },
  { name: 'VP007', latitude: 46.680556, longitude: 14.346111 },
  { name: 'VP044', latitude: 46.680833, longitude: 15.652778 },
  { name: 'TISKO', latitude: 46.682494, longitude: 15.991908 },
  { name: 'DIMLO', latitude: 46.683489, longitude: 16.422722 },
  { name: 'OO240', latitude: 46.684444, longitude: 30.388333 },
  { name: 'WK824', latitude: 46.684958, longitude: 14.776292 },
  { name: 'WK808', latitude: 46.685378, longitude: 14.123533 },
  { name: 'OO330', latitude: 46.686667, longitude: 31.053333 },
  { name: 'BUDOP', latitude: 46.6875, longitude: 21.496667 },
  { name: 'D030M', latitude: 46.687503, longitude: 27.007486 },
  { name: 'WK829', latitude: 46.689964, longitude: 14.098378 },
  { name: 'AGMEL', latitude: 46.690556, longitude: 21.571111 },
  { name: 'CL907', latitude: 46.693444, longitude: 23.826317 },
  { name: 'DORIM', latitude: 46.693483, longitude: 26.842575 },
  { name: 'VILAK', latitude: 46.696392, longitude: 13.914644 },
  { name: 'SM005', latitude: 46.69725, longitude: 17.154889 },
  { name: 'GANNA', latitude: 46.701944, longitude: 22.094722 },
  { name: 'WK802', latitude: 46.704372, longitude: 14.0278 },
  { name: 'VP008', latitude: 46.704444, longitude: 14.727222 },
  { name: 'OO280', latitude: 46.705, longitude: 31.047778 },
  { name: 'ML16', latitude: 46.70555, longitude: 17.151728 },
  { name: 'MALUG', latitude: 46.706111, longitude: 12.5975 },
  { name: 'VP002', latitude: 46.706111, longitude: 17.264722 },
  { name: 'MUREG', latitude: 46.706736, longitude: 15.80805 },
  { name: 'KE010', latitude: 46.707333, longitude: 20.071639 },
  { name: 'D026E', latitude: 46.707342, longitude: 32.536506 },
  { name: 'GOLVA', latitude: 46.708769, longitude: 15.652372 },
  { name: 'CL701', latitude: 46.711181, longitude: 23.3484 },
  { name: 'WK830', latitude: 46.713458, longitude: 14.416447 },
  { name: 'OO230', latitude: 46.717222, longitude: 30.547222 },
  { name: 'WK804', latitude: 46.718564, longitude: 13.955931 },
  { name: 'MIRON', latitude: 46.719444, longitude: 27.040556 },
  { name: 'IXORI', latitude: 46.720186, longitude: 23.242333 },
  { name: 'D253W', latitude: 46.721111, longitude: 23.249444 },
  { name: '21THR', latitude: 46.724019, longitude: 21.156864 },
  { name: 'NAKUM', latitude: 46.725025, longitude: 14.351311 },
  { name: 'WK807', latitude: 46.725381, longitude: 14.140361 },
  { name: 'OSLEN', latitude: 46.726667, longitude: 20.3625 },
  { name: 'CONWI', latitude: 46.7275, longitude: 22.5625 },
  { name: '39DME', latitude: 46.728569, longitude: 17.1424 },
  { name: 'VP009', latitude: 46.728611, longitude: 14.526389 },
  { name: 'CL552', latitude: 46.728914, longitude: 23.4261 },
  { name: '2NM', latitude: 46.729486, longitude: 17.142633 },
  { name: 'WK823', latitude: 46.729631, longitude: 14.886322 },
  { name: '22LOC', latitude: 46.729722, longitude: 17.142503 },
  { name: 'CL071', latitude: 46.732164, longitude: 23.322792 },
  { name: 'RITED', latitude: 46.733333, longitude: 32.884722 },
  { name: 'D253S', latitude: 46.733767, longitude: 23.339294 },
  { name: 'VESIK', latitude: 46.734167, longitude: 28.5075 },
  { name: 'CL700', latitude: 46.737422, longitude: 23.463378 },
  { name: 'SALAC', latitude: 46.740833, longitude: 26.735278 },
  { name: 'FF21', latitude: 46.741822, longitude: 32.569142 },
  { name: 'ELROM', latitude: 46.741867, longitude: 23.031864 },
  { name: 'GUXVA', latitude: 46.746667, longitude: 27.068611 },
  { name: 'CL908', latitude: 46.746833, longitude: 23.972094 },
  { name: 'KE002', latitude: 46.749944, longitude: 19.926194 },
  { name: 'VP011', latitude: 46.75, longitude: 12.95 },
  { name: 'CL899', latitude: 46.750914, longitude: 23.522728 },
  { name: 'OO250', latitude: 46.751111, longitude: 30.711389 },
  { name: 'VP010', latitude: 46.751667, longitude: 14.110278 },
  { name: 'FD07', latitude: 46.752111, longitude: 23.457778 },
  { name: 'D258W', latitude: 46.752739, longitude: 23.241358 },
  { name: 'TOMPU', latitude: 46.753189, longitude: 23.452658 },
  { name: 'CL551', latitude: 46.755406, longitude: 23.542564 },
  { name: 'ANTAL', latitude: 46.756111, longitude: 26.729167 },
  { name: 'KE011', latitude: 46.758611, longitude: 20.107694 },
  { name: 'FQ16', latitude: 46.759142, longitude: 17.130467 },
  { name: 'SM014', latitude: 46.759694, longitude: 17.131139 },
  { name: 'REBLA', latitude: 46.76, longitude: 23.744722 },
  { name: 'FI16', latitude: 46.760339, longitude: 17.130808 },
  { name: 'ABIRI', latitude: 46.762503, longitude: 14.967572 },
  { name: 'FQ17L', latitude: 46.764928, longitude: 21.152347 },
  { name: 'WK806', latitude: 46.765411, longitude: 14.157222 },
  { name: 'BC019', latitude: 46.765583, longitude: 21.151056 },
  { name: 'OKORA', latitude: 46.766389, longitude: 18.371389 },
  { name: 'LUVEL', latitude: 46.766667, longitude: 21.336111 },
  { name: '96VOR', latitude: 46.767056, longitude: 23.559556 },
  { name: 'DIKOD', latitude: 46.768333, longitude: 29.284167 },
  { name: 'VP012', latitude: 46.769444, longitude: 14.387778 },
  { name: '30TH2', latitude: 46.769542, longitude: 23.604942 },
  { name: 'CL951', latitude: 46.773025, longitude: 23.5185 },
  { name: 'VAMET', latitude: 46.773867, longitude: 15.3077 },
  { name: 'IRLUP', latitude: 46.77535, longitude: 23.98715 },
  { name: 'OO270', latitude: 46.775556, longitude: 30.827778 },
  { name: 'EVAXI', latitude: 46.776667, longitude: 13.519722 },
  { name: 'MOKAD', latitude: 46.776667, longitude: 31.35 },
  { name: 'WG832', latitude: 46.777158, longitude: 15.382303 },
  { name: 'MD07', latitude: 46.781444, longitude: 23.656083 },
  { name: 'BAVMA', latitude: 46.781667, longitude: 22.060556 },
  { name: 'POGAV', latitude: 46.781667, longitude: 28.166667 },
  { name: 'CL072', latitude: 46.784908, longitude: 23.210828 },
  { name: 'DER25', latitude: 46.785147, longitude: 23.674058 },
  { name: 'BC006', latitude: 46.787722, longitude: 21.148 },
  { name: 'CL774', latitude: 46.787758, longitude: 23.330708 },
  { name: 'D009H', latitude: 46.788719, longitude: 21.206708 },
  { name: 'DER07', latitude: 46.790194, longitude: 23.696461 },
  { name: 'BAPGU', latitude: 46.790833, longitude: 27.295278 },
  { name: 'UGLOR', latitude: 46.793056, longitude: 40.886667 },
  { name: 'INBID', latitude: 46.793611, longitude: 26.209444 },
  { name: 'ML25', latitude: 46.794472, longitude: 23.7155 },
  { name: 'SM002', latitude: 46.797306, longitude: 16.990806 },
  { name: 'DETSA', latitude: 46.8025, longitude: 12.281111 },
  { name: 'D351H', latitude: 46.8026, longitude: 21.147539 },
  { name: 'SM302', latitude: 46.802972, longitude: 16.989167 },
  { name: 'NIDLO', latitude: 46.804175, longitude: 15.9956 },
  { name: '29LOC', latitude: 46.804333, longitude: 23.759333 },
  { name: 'CL971', latitude: 46.804358, longitude: 23.759431 },
  { name: 'GAZDA', latitude: 46.805278, longitude: 19.396944 },
  { name: '30THR', latitude: 46.805747, longitude: 23.765622 },
  { name: 'WG833', latitude: 46.806031, longitude: 15.606383 },
  { name: 'D160I', latitude: 46.807411, longitude: 17.111594 },
  { name: 'CL699', latitude: 46.807464, longitude: 23.773294 },
  { name: 'KIFUZ', latitude: 46.8075, longitude: 33.495833 },
  { name: 'SM012', latitude: 46.807667, longitude: 17.112806 },
  { name: 'FI26', latitude: 46.809, longitude: 23.863611 },
  { name: 'CI26', latitude: 46.809556, longitude: 23.867611 },
  { name: 'TUVNU', latitude: 46.81, longitude: 21.673333 },
  { name: 'NOHAT', latitude: 46.811111, longitude: 16.626389 },
  { name: 'KE003', latitude: 46.813, longitude: 19.985528 },
  { name: 'SM604', latitude: 46.813083, longitude: 16.90925 },
  { name: 'CF30', latitude: 46.814303, longitude: 19.988192 },
  { name: 'CI16', latitude: 46.818342, longitude: 17.108611 },
  { name: 'SM003', latitude: 46.818611, longitude: 17.108639 },
  { name: 'CQ16', latitude: 46.818672, longitude: 17.107186 },
  { name: 'VAGIL', latitude: 46.819661, longitude: 15.486661 },
  { name: 'OKAXA', latitude: 46.819694, longitude: 23.943642 },
  { name: 'EPCAR', latitude: 46.821111, longitude: 26.703611 },
  { name: 'ABIMO', latitude: 46.821444, longitude: 23.835644 },
  { name: 'CL553', latitude: 46.823728, longitude: 23.380272 },
  { name: 'SM301', latitude: 46.824167, longitude: 17.1065 },
  { name: 'BC008', latitude: 46.824833, longitude: 21.142806 },
  { name: 'PIRIG', latitude: 46.825556, longitude: 27.728889 },
  { name: 'CL972', latitude: 46.82845, longitude: 23.866975 },
  { name: 'WK611', latitude: 46.829378, longitude: 14.283192 },
  { name: 'WG508', latitude: 46.829933, longitude: 15.135028 },
  { name: 'KK211', latitude: 46.8315, longitude: 29.039639 },
  { name: 'CI25', latitude: 46.831981, longitude: 23.882781 },
  { name: 'EBAMO', latitude: 46.832222, longitude: 19.5925 },
  { name: 'BC007', latitude: 46.832722, longitude: 21.263639 },
  { name: 'VP013', latitude: 46.833333, longitude: 12.783333 },
  { name: 'LATitudeCU', latitude: 46.833611, longitude: 22.382222 },
  { name: 'ULGAP', latitude: 46.839722, longitude: 27.584167 },
  { name: 'KK202', latitude: 46.839778, longitude: 29.048222 },
  { name: 'CL771', latitude: 46.840906, longitude: 23.9228 },
  { name: 'VIBUD', latitude: 46.841889, longitude: 23.927197 },
  { name: 'WK614', latitude: 46.842819, longitude: 14.053786 },
  { name: 'CL906', latitude: 46.845894, longitude: 23.477642 },
  { name: 'D282I', latitude: 46.846642, longitude: 23.569406 },
  { name: 'HANCU', latitude: 46.847222, longitude: 28.793889 },
  { name: 'TIPOV', latitude: 46.8475, longitude: 23.953056 },
  { name: 'KK145', latitude: 46.851611, longitude: 29.262917 },
  { name: 'CL773', latitude: 46.8534, longitude: 23.440419 },
  { name: 'PUHEW', latitude: 46.855278, longitude: 29.021389 },
  { name: 'EBILO', latitude: 46.856344, longitude: 23.523692 },
  { name: 'FD34C', latitude: 46.857186, longitude: 15.477247 },
  { name: 'D164I', latitude: 46.859303, longitude: 15.475803 },
  { name: 'KE001', latitude: 46.859694, longitude: 19.650556 },
  { name: 'WG604', latitude: 46.860475, longitude: 15.586886 },
  { name: 'D247N', latitude: 46.861272, longitude: 28.577639 },
  { name: 'D164D', latitude: 46.863417, longitude: 15.475172 },
  { name: 'D310E', latitude: 46.864294, longitude: 23.695397 },
  { name: 'LEKRU', latitude: 46.866389, longitude: 40.785 },
  { name: 'KE004', latitude: 46.866861, longitude: 19.863917 },
  { name: 'FI30', latitude: 46.867419, longitude: 19.861256 },
  { name: 'FF30', latitude: 46.867489, longitude: 19.86485 },
  { name: 'CI34C', latitude: 46.869128, longitude: 15.473111 },
  { name: 'NALOX', latitude: 46.869722, longitude: 16.82 },
  { name: 'CL905', latitude: 46.87065, longitude: 23.586886 },
  { name: 'WG607', latitude: 46.870675, longitude: 15.375811 },
  { name: 'D047G', latitude: 46.875922, longitude: 23.928917 },
  { name: 'TEVSA', latitude: 46.876111, longitude: 27.203333 },
  { name: 'KK156', latitude: 46.879056, longitude: 28.594389 },
  { name: 'WG608', latitude: 46.879536, longitude: 15.258947 },
  { name: 'KENEN', latitude: 46.879722, longitude: 38.755 },
  { name: 'CL904', latitude: 46.883778, longitude: 23.645058 },
  { name: 'WG835', latitude: 46.885169, longitude: 15.468719 },
  { name: 'CL253', latitude: 46.888061, longitude: 24.062242 },
  { name: 'FAHAZ', latitude: 46.888611, longitude: 19.048611 },
  { name: 'D059M', latitude: 46.891072, longitude: 24.060272 },
  { name: 'SUDAX', latitude: 46.893333, longitude: 28.9325 },
  { name: 'CL554', latitude: 46.894742, longitude: 23.693753 },
  { name: 'CL903', latitude: 46.895497, longitude: 23.697114 },
  { name: 'BABOX', latitude: 46.895833, longitude: 19.683056 },
  { name: 'KK221', latitude: 46.896389, longitude: 29.340333 },
  { name: 'KE009', latitude: 46.89675, longitude: 20.064861 },
  { name: 'D282U', latitude: 46.901989, longitude: 23.307019 },
  { name: 'CL902', latitude: 46.904097, longitude: 23.735394 },
  { name: 'CL252', latitude: 46.904561, longitude: 23.737464 },
  { name: 'KK163', latitude: 46.905472, longitude: 29.235972 },
  { name: 'KK203', latitude: 46.906028, longitude: 28.932056 },
  { name: 'KE005', latitude: 46.906278, longitude: 19.774639 },
  { name: 'KK150', latitude: 46.906306, longitude: 29.226361 },
  { name: 'KK212', latitude: 46.908028, longitude: 28.926694 },
  { name: 'TISMA', latitude: 46.908814, longitude: 14.159628 },
  { name: 'RUPUG', latitude: 46.909167, longitude: 22.253056 },
  { name: 'XIBAR', latitude: 46.910756, longitude: 15.337167 },
  { name: 'ELBAM', latitude: 46.911111, longitude: 36.845556 },
  { name: 'ENITU', latitude: 46.911753, longitude: 23.627278 },
  { name: '40THR', latitude: 46.912297, longitude: 15.461275 },
  { name: 'KK210', latitude: 46.91325, longitude: 29.147417 },
  { name: 'KK162', latitude: 46.915972, longitude: 29.115361 },
  { name: 'CL698', latitude: 46.917375, longitude: 23.563486 },
  { name: 'KEGAD', latitude: 46.918803, longitude: 24.335486 },
  { name: 'CL08', latitude: 46.920778, longitude: 28.620306 },
  { name: 'CI08', latitude: 46.920972, longitude: 28.620306 },
  { name: 'KK140', latitude: 46.921222, longitude: 28.625861 },
  { name: 'KK133', latitude: 46.921278, longitude: 28.629139 },
  { name: 'AXUTA', latitude: 46.921383, longitude: 23.214292 },
  { name: 'KK240', latitude: 46.922611, longitude: 29.038861 },
  { name: 'KK251', latitude: 46.923528, longitude: 28.725056 },
  { name: 'FF08', latitude: 46.923722, longitude: 28.749361 },
  { name: 'ADUKU', latitude: 46.923889, longitude: 22.0 },
  { name: 'KK132', latitude: 46.924, longitude: 28.750694 },
  { name: 'FI08', latitude: 46.924, longitude: 28.753583 },
  { name: 'KK250', latitude: 46.925583, longitude: 28.822278 },
  { name: 'BUSES', latitude: 46.925833, longitude: 28.106111 },
  { name: 'BINKU', latitude: 46.926019, longitude: 20.459103 },
  { name: 'MD08', latitude: 46.926278, longitude: 28.883056 },
  { name: '20LOC', latitude: 46.926361, longitude: 28.863294 },
  { name: '19VOR', latitude: 46.926583, longitude: 28.857278 },
  { name: 'KE013', latitude: 46.926722, longitude: 19.726833 },
  { name: 'ML08', latitude: 46.92675, longitude: 28.889861 },
  { name: 'CL073', latitude: 46.927058, longitude: 23.837933 },
  { name: 'KK131', latitude: 46.927317, longitude: 28.907364 },
  { name: 'FD08', latitude: 46.927778, longitude: 28.749472 },
  { name: 'KK141', latitude: 46.928269, longitude: 28.954483 },
  { name: 'MD27', latitude: 46.928611, longitude: 28.978111 },
  { name: 'ML26', latitude: 46.92875, longitude: 28.978806 },
  { name: 'CD08', latitude: 46.929139, longitude: 28.612417 },
  { name: '26LOC', latitude: 46.929389, longitude: 29.012833 },
  { name: '40VOR', latitude: 46.929444, longitude: 29.002389 },
  { name: 'MD26', latitude: 46.929556, longitude: 28.97875 },
  { name: 'KK230', latitude: 46.929972, longitude: 29.039583 },
  { name: 'KK161', latitude: 46.930067, longitude: 28.951264 },
  { name: '45VOR', latitude: 46.931167, longitude: 29.012694 },
  { name: 'FI26', latitude: 46.931389, longitude: 29.117139 },
  { name: 'FF26', latitude: 46.931417, longitude: 29.118583 },
  { name: 'KK142', latitude: 46.931472, longitude: 29.120306 },
  { name: 'KK201', latitude: 46.932083, longitude: 29.148972 },
  { name: 'KK151', latitude: 46.932706, longitude: 28.920203 },
  { name: 'KK130', latitude: 46.933194, longitude: 29.214944 },
  { name: 'FD27', latitude: 46.933306, longitude: 29.117611 },
  { name: 'CI26', latitude: 46.933583, longitude: 29.239944 },
  { name: 'CL26', latitude: 46.933583, longitude: 29.240194 },
  { name: 'KK143', latitude: 46.933639, longitude: 29.241917 },
  { name: 'KK220', latitude: 46.935556, longitude: 29.343472 },
  { name: 'FD26', latitude: 46.936056, longitude: 29.118278 },
  { name: 'CL901', latitude: 46.936919, longitude: 23.882133 },
  { name: 'CL251', latitude: 46.936919, longitude: 23.882133 },
  { name: 'KK152', latitude: 46.937139, longitude: 28.86775 },
  { name: 'CD27', latitude: 46.937278, longitude: 29.239083 },
  { name: 'MD09', latitude: 46.938528, longitude: 28.851417 },
  { name: 'KK260', latitude: 46.939861, longitude: 28.835722 },
  { name: 'PIBIP', latitude: 46.941539, longitude: 15.577914 },
  { name: 'CD26', latitude: 46.941583, longitude: 29.239639 },
  { name: 'BP837', latitude: 46.942028, longitude: 19.416861 },
  { name: 'KK153', latitude: 46.946056, longitude: 28.761556 },
  { name: 'KK261', latitude: 46.946944, longitude: 28.751194 },
  { name: 'CL898', latitude: 46.948769, longitude: 23.503264 },
  { name: 'VP014', latitude: 46.951111, longitude: 15.379444 },
  { name: 'LESVO', latitude: 46.951944, longitude: 27.822222 },
  { name: 'WG503', latitude: 46.953322, longitude: 15.709883 },
  { name: 'WW411', latitude: 46.95385, longitude: 16.136936 },
  { name: 'MN34', latitude: 46.954994, longitude: 15.449203 },
  { name: 'KK154', latitude: 46.956056, longitude: 28.64075 },
  { name: 'DUZLA', latitude: 46.956944, longitude: 18.730278 },
  { name: 'KK160', latitude: 46.957361, longitude: 28.624778 },
  { name: 'FD09', latitude: 46.958917, longitude: 28.764639 },
  { name: 'KE008', latitude: 46.959111, longitude: 19.924528 },
  { name: 'SN002', latitude: 46.959625, longitude: 20.245533 },
  { name: 'MI35C', latitude: 46.964119, longitude: 15.447039 },
  { name: 'D073N', latitude: 46.965469, longitude: 29.235222 },
  { name: 'FI12', latitude: 46.967342, longitude: 19.63705 },
  { name: 'ILHAK', latitude: 46.968611, longitude: 19.373889 },
  { name: 'KE006', latitude: 46.969389, longitude: 19.631472 },
  { name: 'VP015', latitude: 46.969444, longitude: 15.491389 },
  { name: 'KERTA', latitude: 46.97, longitude: 36.005 },
  { name: 'NORCU', latitude: 46.973056, longitude: 28.943056 },
  { name: 'VENIL', latitude: 46.974722, longitude: 28.245833 },
  { name: 'XONKO', latitude: 46.975278, longitude: 30.875278 },
  { name: 'WG609', latitude: 46.976892, longitude: 15.323422 },
  { name: 'UDSIG', latitude: 46.977778, longitude: 26.053611 },
  { name: 'SH002', latitude: 46.982194, longitude: 20.15575 },
  { name: 'SN003', latitude: 46.983578, longitude: 20.155953 },
  { name: 'KK155', latitude: 46.984806, longitude: 28.526528 },
  { name: 'CD09', latitude: 46.985861, longitude: 28.649417 },
  { name: 'OLATitudeI', latitude: 46.987222, longitude: 17.479167 },
  { name: 'KE012', latitude: 46.990028, longitude: 19.58375 },
  { name: 'FF04', latitude: 46.992442, longitude: 31.801381 },
  { name: 'SH011', latitude: 46.992583, longitude: 20.318417 },
  { name: 'ULZAK', latitude: 46.994167, longitude: 18.566944 },
  { name: 'ATSOS', latitude: 46.995831, longitude: 22.854192 },
  { name: 'GOTAR', latitude: 46.997881, longitude: 16.224764 },
  { name: 'IDRUP', latitude: 46.998611, longitude: 27.78175 },
  { name: 'OON01', latitude: 46.999167, longitude: 30.596111 },
  { name: 'D298H', latitude: 46.999192, longitude: 19.563456 },
  { name: '4713E', latitude: 47.0, longitude: 13.0 },
  { name: '4714E', latitude: 47.0, longitude: 14.0 },
  { name: '4715E', latitude: 47.0, longitude: 15.0 },
  { name: '4716E', latitude: 47.0, longitude: 16.0 },
  { name: '4717E', latitude: 47.0, longitude: 17.0 },
  { name: '4718E', latitude: 47.0, longitude: 18.0 },
  { name: '4719E', latitude: 47.0, longitude: 19.0 },
  { name: '4720E', latitude: 47.0, longitude: 20.0 },
  { name: '4721E', latitude: 47.0, longitude: 21.0 },
  { name: '4722E', latitude: 47.0, longitude: 22.0 },
  { name: '4723E', latitude: 47.0, longitude: 23.0 },
  { name: '4724E', latitude: 47.0, longitude: 24.0 },
  { name: '4725E', latitude: 47.0, longitude: 25.0 },
  { name: '4726E', latitude: 47.0, longitude: 26.0 },
  { name: '4727E', latitude: 47.0, longitude: 27.0 },
  { name: '4728E', latitude: 47.0, longitude: 28.0 },
  { name: '4729E', latitude: 47.0, longitude: 29.0 },
  { name: '4730E', latitude: 47.0, longitude: 30.0 },
  { name: '4731E', latitude: 47.0, longitude: 31.0 },
  { name: '4732E', latitude: 47.0, longitude: 32.0 },
  { name: '4733E', latitude: 47.0, longitude: 33.0 },
  { name: '4734E', latitude: 47.0, longitude: 34.0 },
  { name: '4735E', latitude: 47.0, longitude: 35.0 },
  { name: 'MELTL', latitude: 47.0, longitude: 35.333333 },
  { name: '4736E', latitude: 47.0, longitude: 36.0 },
  { name: '4737E', latitude: 47.0, longitude: 37.0 },
  { name: '4738E', latitude: 47.0, longitude: 38.0 },
  { name: '4739E', latitude: 47.0, longitude: 39.0 },
  { name: '4740E', latitude: 47.0, longitude: 40.0 },
  { name: 'VP016', latitude: 47.000278, longitude: 15.4075 },
  { name: 'ABOLO', latitude: 47.000306, longitude: 27.332972 },
  { name: 'AKUPO', latitude: 47.001044, longitude: 24.395636 },
  { name: 'CI12', latitude: 47.003514, longitude: 19.555267 },
  { name: 'MD16C', latitude: 47.004369, longitude: 15.435503 },
  { name: 'VP017', latitude: 47.005278, longitude: 16.135 },
  { name: 'CL555', latitude: 47.0057, longitude: 23.291803 },
  { name: 'ODUKI', latitude: 47.008611, longitude: 40.600278 },
  { name: 'BP836', latitude: 47.009361, longitude: 19.308583 },
  { name: 'WG836', latitude: 47.010128, longitude: 15.434381 },
  { name: 'SH001', latitude: 47.010722, longitude: 20.250306 },
  { name: 'RETRO', latitude: 47.012778, longitude: 29.611111 },
  { name: 'WG603', latitude: 47.012867, longitude: 15.553175 },
  { name: 'NOWAS', latitude: 47.013611, longitude: 28.954167 },
  { name: 'NARWE', latitude: 47.014167, longitude: 28.752222 },
  { name: 'INSAX', latitude: 47.015556, longitude: 16.080833 },
  { name: 'KK144', latitude: 47.016361, longitude: 29.256361 },
  { name: 'VP019', latitude: 47.016667, longitude: 15.485833 },
  { name: 'VANIP', latitude: 47.019722, longitude: 28.386944 },
  { name: 'SH004', latitude: 47.028806, longitude: 20.182167 },
  { name: 'D142O', latitude: 47.029167, longitude: 27.771472 },
  { name: 'CL772', latitude: 47.029372, longitude: 23.736692 },
  { name: 'OBARA', latitude: 47.031389, longitude: 22.716389 },
  { name: 'SN004', latitude: 47.033425, longitude: 20.184383 },
  { name: '49VOR', latitude: 47.035439, longitude: 15.426661 },
  { name: 'PEXON', latitude: 47.037222, longitude: 31.878056 },
  { name: 'SH019', latitude: 47.04475, longitude: 20.348167 },
  { name: 'VP020', latitude: 47.045, longitude: 14.7275 },
  { name: 'SH003', latitude: 47.046861, longitude: 20.113972 },
  { name: 'MA19', latitude: 47.046889, longitude: 21.908639 },
  { name: 'SN012', latitude: 47.049489, longitude: 20.168031 },
  { name: 'ERGUZ', latitude: 47.051111, longitude: 19.809722 },
  { name: 'KE007', latitude: 47.051111, longitude: 19.809722 },
  { name: 'D133O', latitude: 47.054667, longitude: 27.820222 },
  { name: 'GITAS', latitude: 47.054722, longitude: 18.174167 },
  { name: 'SH005', latitude: 47.059889, longitude: 20.199806 },
  { name: 'VP021', latitude: 47.061111, longitude: 15.594167 },
  { name: 'SH010', latitude: 47.064833, longitude: 20.359639 },
  { name: '67VOR', latitude: 47.064872, longitude: 15.418275 },
  { name: 'VP022', latitude: 47.066111, longitude: 14.426944 },
  { name: 'VP023', latitude: 47.066667, longitude: 16.316667 },
  { name: 'GODLI', latitude: 47.0675, longitude: 40.272778 },
  { name: 'FQ32', latitude: 47.070639, longitude: 27.718139 },
  { name: 'OKMAN', latitude: 47.071667, longitude: 39.291667 },
  { name: 'BP835', latitude: 47.074167, longitude: 19.203944 },
  { name: 'SH018', latitude: 47.080444, longitude: 20.2115 },
  { name: 'VP024', latitude: 47.080833, longitude: 15.960278 },
  { name: 'RT283', latitude: 47.082544, longitude: 39.088956 },
  { name: 'KOREG', latitude: 47.083333, longitude: 32.941667 },
  { name: 'RT102', latitude: 47.084344, longitude: 38.757172 },
  { name: 'RT284', latitude: 47.086861, longitude: 38.966597 },
  { name: 'WG501', latitude: 47.088925, longitude: 15.537333 },
  { name: 'D128H', latitude: 47.092222, longitude: 39.020278 },
  { name: 'APSAM', latitude: 47.092925, longitude: 23.365458 },
  { name: 'D159G', latitude: 47.093889, longitude: 38.821944 },
  { name: 'JOBST', latitude: 47.094167, longitude: 16.0225 },
  { name: 'IRLIT', latitude: 47.095442, longitude: 23.220156 },
  { name: 'WG817', latitude: 47.096242, longitude: 15.410617 },
  { name: 'BP822', latitude: 47.099944, longitude: 18.827111 },
  { name: 'KK106', latitude: 47.100222, longitude: 29.271083 },
  { name: '43LOC', latitude: 47.100833, longitude: 21.92425 },
  { name: 'VP025', latitude: 47.104167, longitude: 15.713889 },
  { name: 'BP741', latitude: 47.104167, longitude: 19.591639 },
  { name: 'NUNTA', latitude: 47.104167, longitude: 27.858333 },
  { name: 'SH006', latitude: 47.105417, longitude: 20.225667 },
  { name: 'VP026', latitude: 47.105556, longitude: 14.915 },
  { name: 'SH020', latitude: 47.106833, longitude: 20.383667 },
  { name: '88DME', latitude: 47.106972, longitude: 27.685944 },
  { name: 'D118I', latitude: 47.110278, longitude: 39.071389 },
  { name: 'ABTAN', latitude: 47.113611, longitude: 14.495556 },
  { name: 'SN005', latitude: 47.114364, longitude: 20.230669 },
  { name: 'NETED', latitude: 47.114444, longitude: 28.649167 },
  { name: 'WG602', latitude: 47.118125, longitude: 15.565019 },
  { name: 'BUKUR', latitude: 47.120278, longitude: 25.478611 },
  { name: 'FF22', latitude: 47.122189, longitude: 32.035972 },
  { name: 'BOKDO', latitude: 47.122536, longitude: 23.644072 },
  { name: 'VP027', latitude: 47.123611, longitude: 16.207778 },
  { name: 'VP028', latitude: 47.124167, longitude: 16.046111 },
  { name: 'OGURA', latitude: 47.124722, longitude: 40.448333 },
  { name: 'SH009', latitude: 47.126917, longitude: 20.395167 },
  { name: 'D108I', latitude: 47.127778, longitude: 39.079167 },
  { name: '72DME', latitude: 47.129806, longitude: 27.665694 },
  { name: 'ADMEC', latitude: 47.130833, longitude: 26.58 },
  { name: 'SH017', latitude: 47.131083, longitude: 20.240306 },
  { name: 'WG816', latitude: 47.132522, longitude: 15.400589 },
  { name: 'VP029', latitude: 47.132778, longitude: 14.308889 },
  { name: 'D099M', latitude: 47.133333, longitude: 39.193333 },
  { name: 'VP030', latitude: 47.134444, longitude: 15.285 },
  { name: 'IBNIB', latitude: 47.138889, longitude: 40.095833 },
  { name: 'SN011', latitude: 47.139875, longitude: 20.245292 },
  { name: 'VP031', latitude: 47.141667, longitude: 14.742778 },
  { name: 'SUNIS', latitude: 47.141878, longitude: 16.349611 },
  { name: 'RT103', latitude: 47.142711, longitude: 38.669322 },
  { name: 'SH008', latitude: 47.145111, longitude: 20.326917 },
  { name: 'FD16C', latitude: 47.146628, longitude: 15.394931 },
  { name: 'D056I', latitude: 47.147528, longitude: 22.042444 },
  { name: 'VP032', latitude: 47.15, longitude: 14.397222 },
  { name: 'KK105', latitude: 47.150139, longitude: 29.038306 },
  { name: 'PA007', latitude: 47.150361, longitude: 17.482583 },
  { name: '57DME', latitude: 47.151194, longitude: 27.646722 },
  { name: 'ORXIK', latitude: 47.151944, longitude: 22.245278 },
  { name: 'PUSTA', latitude: 47.152222, longitude: 18.742306 },
  { name: 'FUTNA', latitude: 47.152278, longitude: 19.696222 },
  { name: 'RT282', latitude: 47.152342, longitude: 39.114789 },
  { name: 'D217C', latitude: 47.153053, longitude: 38.817969 },
  { name: 'KEZAL', latitude: 47.153611, longitude: 20.231389 },
  { name: 'WONTA', latitude: 47.155278, longitude: 19.511111 },
  { name: 'INSEL', latitude: 47.155556, longitude: 12.405278 },
  { name: 'ODSAN', latitude: 47.157778, longitude: 30.431111 },
  { name: 'D238G', latitude: 47.158333, longitude: 38.616944 },
  { name: 'SN001', latitude: 47.159894, longitude: 20.256775 },
  { name: 'FF19', latitude: 47.1615, longitude: 21.941833 },
  { name: 'WW410', latitude: 47.162503, longitude: 14.779717 },
  { name: 'MQ33', latitude: 47.162528, longitude: 27.63175 },
  { name: 'OFENA', latitude: 47.162778, longitude: 19.710583 },
  { name: 'SH007', latitude: 47.16325, longitude: 20.258583 },
  { name: 'D234F', latitude: 47.163253, longitude: 38.654128 },
  { name: 'RT104', latitude: 47.163286, longitude: 38.550411 },
  { name: 'UNIRA', latitude: 47.168333, longitude: 27.851667 },
  { name: 'XZ829', latitude: 47.168464, longitude: 14.360347 },
  { name: 'WG815', latitude: 47.168797, longitude: 15.390536 },
  { name: 'VERIG', latitude: 47.172222, longitude: 21.724722 },
  { name: 'RT287', latitude: 47.172917, longitude: 38.995856 },
  { name: 'SN007', latitude: 47.173244, longitude: 20.264439 },
  { name: 'FF28', latitude: 47.173333, longitude: 38.996111 },
  { name: 'RT201', latitude: 47.175192, longitude: 38.982725 },
  { name: 'VP033', latitude: 47.176111, longitude: 14.815556 },
  { name: 'XZ622', latitude: 47.176611, longitude: 14.298314 },
  { name: 'CF19', latitude: 47.177656, longitude: 21.946433 },
  { name: 'PA001', latitude: 47.178306, longitude: 17.597611 },
  { name: 'D249H', latitude: 47.178744, longitude: 38.586147 },
  { name: 'D085I', latitude: 47.185556, longitude: 39.101111 },
  { name: 'SH016', latitude: 47.185722, longitude: 20.271444 },
  { name: 'D007E', latitude: 47.187028, longitude: 21.94885 },
  { name: 'XZ828', latitude: 47.187122, longitude: 14.722483 },
  { name: 'LUSAV', latitude: 47.187222, longitude: 28.863611 },
  { name: 'XZ621', latitude: 47.187942, longitude: 14.498067 },
  { name: 'RT107', latitude: 47.188472, longitude: 38.90595 },
  { name: 'VP035', latitude: 47.188889, longitude: 14.601111 },
  { name: 'JOZEP', latitude: 47.189167, longitude: 18.740278 },
  { name: 'BP712', latitude: 47.190417, longitude: 19.016194 },
  { name: 'BP834', latitude: 47.191611, longitude: 19.013278 },
  { name: 'CF34', latitude: 47.193608, longitude: 17.591053 },
  { name: 'RAKFA', latitude: 47.194444, longitude: 18.461111 },
  { name: 'TAGNG', latitude: 47.195, longitude: 38.853889 },
  { name: 'RULES', latitude: 47.196111, longitude: 22.069722 },
  { name: 'ML14', latitude: 47.196361, longitude: 27.606583 },
  { name: 'CI34', latitude: 47.196381, longitude: 17.588294 },
  { name: 'MQ14', latitude: 47.196861, longitude: 27.606139 },
  { name: 'ABDAR', latitude: 47.196944, longitude: 35.255833 },
  { name: 'INLOX', latitude: 47.197764, longitude: 14.755944 },
  { name: 'AMEPU', latitude: 47.197778, longitude: 39.825 },
  { name: 'PA013', latitude: 47.198611, longitude: 17.424639 },
  { name: 'SH014', latitude: 47.198639, longitude: 20.357556 },
  { name: 'UVERA', latitude: 47.2, longitude: 20.429722 },
  { name: 'RT286', latitude: 47.204939, longitude: 38.810469 },
  { name: 'MN10V', latitude: 47.206106, longitude: 38.803817 },
  { name: 'PA008', latitude: 47.206444, longitude: 17.712611 },
  { name: 'SN010', latitude: 47.207061, longitude: 20.283869 },
  { name: 'XZ827', latitude: 47.207697, longitude: 14.789303 },
  { name: 'MEGIK', latitude: 47.208333, longitude: 21.861111 },
  { name: 'VP036', latitude: 47.209722, longitude: 14.458056 },
  { name: 'WG814', latitude: 47.210256, longitude: 15.379028 },
  { name: 'D263H', latitude: 47.211944, longitude: 38.591389 },
  { name: '18LOC', latitude: 47.212056, longitude: 27.592611 },
  { name: 'XZ601', latitude: 47.212639, longitude: 14.832272 },
  { name: 'PH002', latitude: 47.212639, longitude: 17.579722 },
  { name: 'ROPAG', latitude: 47.213622, longitude: 15.799367 },
  { name: 'DODAR', latitude: 47.214444, longitude: 19.5275 },
  { name: 'BP742', latitude: 47.215583, longitude: 19.413889 },
  { name: 'D156K', latitude: 47.216086, longitude: 17.574328 },
  { name: 'BP856', latitude: 47.216667, longitude: 19.593694 },
  { name: 'SH015', latitude: 47.216806, longitude: 20.289194 },
  { name: 'D074J', latitude: 47.216944, longitude: 39.107778 },
  { name: 'VP037', latitude: 47.217222, longitude: 14.758056 },
  { name: 'D155J', latitude: 47.220233, longitude: 17.576886 },
  { name: 'RT381', latitude: 47.221822, longitude: 38.712189 },
  { name: 'VP038', latitude: 47.222222, longitude: 14.580556 },
  { name: 'ATICO', latitude: 47.222861, longitude: 19.402917 },
  { name: 'FF10', latitude: 47.223889, longitude: 38.699444 },
  { name: 'RT106', latitude: 47.223969, longitude: 38.699628 },
  { name: 'KOVIL', latitude: 47.226667, longitude: 36.986944 },
  { name: 'D127T', latitude: 47.226933, longitude: 19.612906 },
  { name: 'BP866', latitude: 47.22725, longitude: 19.607972 },
  { name: 'NELKI', latitude: 47.228611, longitude: 39.213333 },
  { name: 'XZ826', latitude: 47.228747, longitude: 14.857736 },
  { name: 'SETAL', latitude: 47.231667, longitude: 14.258889 },
  { name: 'PA012', latitude: 47.233333, longitude: 17.568917 },
  { name: 'RT281', latitude: 47.233469, longitude: 39.144908 },
  { name: 'SH012', latitude: 47.234917, longitude: 20.220806 },
  { name: 'VP039', latitude: 47.235, longitude: 16.325 },
  { name: 'URNEQ', latitude: 47.238889, longitude: 22.135278 },
  { name: 'CD16C', latitude: 47.239819, longitude: 15.368233 },
  { name: 'D270D', latitude: 47.240028, longitude: 27.482222 },
  { name: 'XZ822', latitude: 47.240497, longitude: 15.193339 },
  { name: 'PH003', latitude: 47.242944, longitude: 17.486028 },
  { name: 'VP041', latitude: 47.243056, longitude: 14.528056 },
  { name: 'RT105', latitude: 47.244422, longitude: 38.580489 },
  { name: 'RP343', latitude: 47.246528, longitude: 40.260222 },
  { name: 'TOKMU', latitude: 47.246667, longitude: 35.79 },
  { name: 'NARKA', latitude: 47.248333, longitude: 21.86 },
  { name: 'LARMU', latitude: 47.250278, longitude: 23.135833 },
  { name: 'KERLO', latitude: 47.250556, longitude: 27.0 },
  { name: 'XZ825', latitude: 47.251006, longitude: 14.930119 },
  { name: 'SIRDU', latitude: 47.254722, longitude: 17.331944 },
  { name: 'BP723', latitude: 47.254972, longitude: 18.894389 },
  { name: 'VP043', latitude: 47.255556, longitude: 14.891389 },
  { name: 'FF34', latitude: 47.25625, longitude: 17.557697 },
  { name: 'PA002', latitude: 47.256806, longitude: 17.556667 },
  { name: 'SN006', latitude: 47.257317, longitude: 20.344108 },
  { name: 'FI14', latitude: 47.258028, longitude: 27.551611 },
  { name: 'FQ14', latitude: 47.258083, longitude: 27.551583 },
  { name: 'FI34', latitude: 47.259158, longitude: 17.555503 },
  { name: 'PH004', latitude: 47.259722, longitude: 17.555167 },
  { name: 'ANAVU', latitude: 47.260833, longitude: 23.3225 },
  { name: 'SH013', latitude: 47.263389, longitude: 20.315833 },
  { name: 'INGID', latitude: 47.268536, longitude: 13.685186 },
  { name: 'BIBOR', latitude: 47.269167, longitude: 25.139167 },
  { name: 'D290H', latitude: 47.269167, longitude: 38.612778 },
  { name: 'ODVAS', latitude: 47.270833, longitude: 19.326306 },
  { name: 'ABONY', latitude: 47.270833, longitude: 19.979167 },
  { name: 'OKLIV', latitude: 47.271667, longitude: 23.5225 },
  { name: 'BP103', latitude: 47.273528, longitude: 19.321667 },
  { name: 'WZ802', latitude: 47.27675, longitude: 12.530703 },
  { name: 'XZ824', latitude: 47.280111, longitude: 15.024822 },
  { name: 'VP046', latitude: 47.280278, longitude: 12.667222 },
  { name: 'VP047', latitude: 47.280556, longitude: 14.483056 },
  { name: 'BUZRA', latitude: 47.280833, longitude: 19.062778 },
  { name: 'SN009', latitude: 47.281817, longitude: 20.326917 },
  { name: 'PH001', latitude: 47.282472, longitude: 17.649306 },
  { name: 'VP048', latitude: 47.283333, longitude: 12.875 },
  { name: 'WZ601', latitude: 47.283489, longitude: 12.699603 },
  { name: 'WZ803', latitude: 47.283567, longitude: 12.685969 },
  { name: 'VP049', latitude: 47.283611, longitude: 12.789444 },
  { name: 'BP855', latitude: 47.284278, longitude: 19.485556 },
  { name: 'SASAL', latitude: 47.284828, longitude: 16.474317 },
  { name: 'WZ602', latitude: 47.285033, longitude: 12.588806 },
  { name: 'XZ602', latitude: 47.285253, longitude: 14.974206 },
  { name: 'WZ603', latitude: 47.285917, longitude: 12.521692 },
  { name: 'RP600', latitude: 47.286194, longitude: 40.637361 },
  { name: 'UTCON', latitude: 47.288611, longitude: 19.690833 },
  { name: 'D318F', latitude: 47.291775, longitude: 38.7009 },
  { name: 'BP865', latitude: 47.294861, longitude: 19.499861 },
  { name: 'CD31R', latitude: 47.296347, longitude: 19.500558 },
  { name: 'WG821', latitude: 47.298308, longitude: 15.22985 },
  { name: 'D135T', latitude: 47.29865, longitude: 19.685944 },
  { name: 'DC014', latitude: 47.299222, longitude: 21.652861 },
  { name: 'SN008', latitude: 47.300056, longitude: 20.258528 },
  { name: 'WZ604', latitude: 47.300872, longitude: 12.400589 },
  { name: 'WZ801', latitude: 47.301172, longitude: 12.398142 },
  { name: 'MILGO', latitude: 47.301711, longitude: 15.09165 },
  { name: 'PH016', latitude: 47.301861, longitude: 17.455111 },
  { name: 'D136N', latitude: 47.302231, longitude: 21.827636 },
  { name: 'VP053', latitude: 47.304167, longitude: 14.941389 },
  { name: '31THR', latitude: 47.305111, longitude: 17.531417 },
  { name: 'PH005', latitude: 47.305583, longitude: 17.531167 },
  { name: 'BP733', latitude: 47.305972, longitude: 19.014556 },
  { name: 'VEBOS', latitude: 47.306389, longitude: 18.637222 },
  { name: 'D308H', latitude: 47.306389, longitude: 38.6425 },
  { name: 'RONOT', latitude: 47.313889, longitude: 15.350181 },
  { name: 'XZ823', latitude: 47.316406, longitude: 15.142961 },
  { name: 'DC032', latitude: 47.316806, longitude: 21.51475 },
  { name: 'BELMU', latitude: 47.317222, longitude: 27.353056 },
  { name: 'PH015', latitude: 47.318667, longitude: 17.524361 },
  { name: 'DC017', latitude: 47.319306, longitude: 21.641444 },
  { name: 'BP328', latitude: 47.321861, longitude: 19.394889 },
  { name: 'RP339', latitude: 47.322611, longitude: 39.825556 },
  { name: 'SUVUR', latitude: 47.323056, longitude: 29.388889 },
  { name: 'RT101', latitude: 47.325547, longitude: 38.610661 },
  { name: 'VP054', latitude: 47.326667, longitude: 12.806667 },
  { name: 'PA014', latitude: 47.326667, longitude: 17.367833 },
  { name: 'PA006', latitude: 47.326667, longitude: 17.367833 },
  { name: 'OBEDI', latitude: 47.327897, longitude: 13.329747 },
  { name: 'RILAS', latitude: 47.328889, longitude: 22.610278 },
  { name: 'CF14', latitude: 47.32925, longitude: 27.488 },
  { name: 'WG822', latitude: 47.32935, longitude: 15.470586 },
  { name: 'DC018', latitude: 47.332833, longitude: 21.50225 },
  { name: 'PASKA', latitude: 47.33425, longitude: 26.498306 },
  { name: 'BP003', latitude: 47.334722, longitude: 19.405444 },
  { name: 'OCRIT', latitude: 47.335028, longitude: 19.945389 },
  { name: 'ARPIG', latitude: 47.335583, longitude: 27.302778 },
  { name: 'BP821', latitude: 47.336417, longitude: 18.988472 },
  { name: 'DC016', latitude: 47.340361, longitude: 21.729333 },
  { name: 'BALUX', latitude: 47.340833, longitude: 19.129444 },
  { name: 'BP844', latitude: 47.341056, longitude: 19.212694 },
  { name: 'VP058', latitude: 47.341111, longitude: 15.011389 },
  { name: 'VP059', latitude: 47.343611, longitude: 15.001667 },
  { name: 'PA003', latitude: 47.344778, longitude: 17.510639 },
  { name: 'PH006', latitude: 47.344806, longitude: 17.510639 },
  { name: 'VAMES', latitude: 47.346667, longitude: 23.561111 },
  { name: 'DC012', latitude: 47.348417, longitude: 21.717889 },
  { name: 'BP772', latitude: 47.349056, longitude: 19.594139 },
  { name: 'BP864', latitude: 47.351139, longitude: 19.409556 },
  { name: 'ROLOP', latitude: 47.351667, longitude: 34.745833 },
  { name: 'FD31R', latitude: 47.352242, longitude: 19.409742 },
  { name: 'VETIK', latitude: 47.352778, longitude: 20.2325 },
  { name: 'NICRA', latitude: 47.356194, longitude: 19.582722 },
  { name: 'BP854', latitude: 47.359833, longitude: 19.364194 },
  { name: 'D312K', latitude: 47.362064, longitude: 27.404128 },
  { name: 'VP060', latitude: 47.363333, longitude: 12.8175 },
  { name: 'BP329', latitude: 47.363639, longitude: 19.451167 },
  { name: 'LEOBE', latitude: 47.363689, longitude: 15.026964 },
  { name: 'BP711', latitude: 47.366278, longitude: 19.354167 },
  { name: 'ARDUB', latitude: 47.368611, longitude: 40.723056 },
  { name: 'NIGSI', latitude: 47.369167, longitude: 16.036111 },
  { name: 'SOMON', latitude: 47.37, longitude: 35.991667 },
  { name: 'BASGA', latitude: 47.370556, longitude: 37.558611 },
  { name: 'VP003', latitude: 47.371111, longitude: 19.092778 },
  { name: 'DC010', latitude: 47.373583, longitude: 21.704389 },
  { name: 'BP734', latitude: 47.373611, longitude: 18.904361 },
  { name: 'VAJDI', latitude: 47.375556, longitude: 18.285833 },
  { name: 'BP331', latitude: 47.375861, longitude: 19.369778 },
  { name: 'DC033', latitude: 47.377444, longitude: 21.433972 },
  { name: 'DC009', latitude: 47.380389, longitude: 21.780556 },
  { name: 'DC031', latitude: 47.381361, longitude: 21.663417 },
  { name: 'PA011', latitude: 47.382028, longitude: 17.491111 },
  { name: 'PH014', latitude: 47.382028, longitude: 17.491111 },
  { name: 'D223M', latitude: 47.382408, longitude: 21.442592 },
  { name: 'ABRUK', latitude: 47.383131, longitude: 15.006631 },
  { name: 'VP061', latitude: 47.384444, longitude: 14.116389 },
  { name: 'OGVUN', latitude: 47.385, longitude: 17.855556 },
  { name: 'KONUG', latitude: 47.385019, longitude: 13.167961 },
  { name: 'D157R', latitude: 47.385278, longitude: 26.488694 },
  { name: 'DC006', latitude: 47.386306, longitude: 21.448833 },
  { name: 'CI04R', latitude: 47.387181, longitude: 21.449686 },
  { name: 'VP063', latitude: 47.387778, longitude: 14.899167 },
  { name: 'BP714', latitude: 47.388306, longitude: 19.384389 },
  { name: 'BP701', latitude: 47.388306, longitude: 19.384389 },
  { name: 'PH008', latitude: 47.390361, longitude: 17.408583 },
  { name: 'BP743', latitude: 47.392, longitude: 19.129917 },
  { name: 'XZ821', latitude: 47.392311, longitude: 15.092506 },
  { name: 'HOPI', latitude: 47.3925, longitude: 21.733056 },
  { name: 'NANIT', latitude: 47.393019, longitude: 12.346436 },
  { name: 'RP311', latitude: 47.394861, longitude: 40.029333 },
  { name: 'VP064', latitude: 47.396667, longitude: 15.296667 },
  { name: 'ZURFA', latitude: 47.397778, longitude: 19.845833 },
  { name: 'BP106', latitude: 47.401278, longitude: 19.346944 },
  { name: 'D127D', latitude: 47.401372, longitude: 19.329669 },
  { name: 'BP763', latitude: 47.401417, longitude: 19.328611 },
  { name: 'BUMUK', latitude: 47.402292, longitude: 13.506569 },
  { name: 'RP302', latitude: 47.403639, longitude: 39.739472 },
  { name: 'RP309', latitude: 47.403917, longitude: 39.743417 },
  { name: 'BEREV', latitude: 47.404139, longitude: 19.505889 },
  { name: 'BP753', latitude: 47.404722, longitude: 19.291861 },
  { name: 'VILIS', latitude: 47.406667, longitude: 27.584167 },
  { name: 'PH007', latitude: 47.407194, longitude: 17.477917 },
  { name: 'FI04R', latitude: 47.409561, longitude: 21.486031 },
  { name: 'EMBUT', latitude: 47.41, longitude: 18.9025 },
  { name: 'BP102', latitude: 47.412472, longitude: 19.099639 },
  { name: 'WIMMI', latitude: 47.415556, longitude: 14.620556 },
  { name: 'PUCOG', latitude: 47.415611, longitude: 18.591889 },
  { name: 'DC021', latitude: 47.416583, longitude: 21.497583 },
  { name: 'VP066', latitude: 47.416667, longitude: 15.006667 },
  { name: 'ML31R', latitude: 47.416919, longitude: 19.303578 },
  { name: 'DC013', latitude: 47.417028, longitude: 21.498583 },
  { name: 'SUBES', latitude: 47.421111, longitude: 17.426667 },
  { name: 'PH013', latitude: 47.421167, longitude: 17.470556 },
  { name: 'ROMAG', latitude: 47.422778, longitude: 22.418611 },
  { name: 'DE13L', latitude: 47.42295, longitude: 19.293856 },
  { name: 'ML31L', latitude: 47.423942, longitude: 19.260811 },
  { name: 'STEIN', latitude: 47.427614, longitude: 16.599708 },
  { name: 'WS811', latitude: 47.428561, longitude: 13.166608 },
  { name: 'VAKOM', latitude: 47.431667, longitude: 30.759444 },
  { name: 'OLMEB', latitude: 47.431944, longitude: 23.451944 },
  { name: 'BIKVA', latitude: 47.431944, longitude: 31.05 },
  { name: 'VP068', latitude: 47.435, longitude: 15.233611 },
  { name: 'DC011', latitude: 47.435111, longitude: 21.528083 },
  { name: 'BP843', latitude: 47.435361, longitude: 19.059917 },
  { name: 'PH009', latitude: 47.435722, longitude: 17.384667 },
  { name: 'ERADA', latitude: 47.439167, longitude: 28.202778 },
  { name: 'PESOD', latitude: 47.439167, longitude: 39.035556 },
  { name: 'VP069', latitude: 47.439444, longitude: 13.901667 },
  { name: 'VP073', latitude: 47.44, longitude: 13.933333 },
  { name: 'D063S', latitude: 47.440269, longitude: 12.040639 },
  { name: 'RP335', latitude: 47.444667, longitude: 40.70675 },
  { name: 'FI16', latitude: 47.445264, longitude: 17.457908 },
  { name: 'BP874', latitude: 47.445361, longitude: 19.439639 },
  { name: 'DE31R', latitude: 47.445422, longitude: 19.25755 },
  { name: 'PA010', latitude: 47.446139, longitude: 17.457417 },
  { name: 'FF16', latitude: 47.447644, longitude: 17.457169 },
  { name: 'RP338', latitude: 47.448472, longitude: 39.830639 },
  { name: 'RP301', latitude: 47.44875, longitude: 39.831861 },
  { name: 'FI05', latitude: 47.44875, longitude: 39.831861 },
  { name: 'BEFAZ', latitude: 47.449722, longitude: 33.000556 },
  { name: 'BP001', latitude: 47.450444, longitude: 19.249444 },
  { name: 'ML13L', latitude: 47.452117, longitude: 19.246717 },
  { name: 'PH012', latitude: 47.452556, longitude: 17.454028 },
  { name: 'RP506', latitude: 47.453806, longitude: 40.517222 },
  { name: 'BP735', latitude: 47.454611, longitude: 18.77225 },
  { name: 'ML13R', latitude: 47.454614, longitude: 19.211192 },
  { name: 'DC001', latitude: 47.456722, longitude: 21.563028 },
  { name: 'RP331', latitude: 47.457861, longitude: 39.804056 },
  { name: 'BP744', latitude: 47.458889, longitude: 19.021444 },
  { name: 'VP074', latitude: 47.46, longitude: 12.375 },
  { name: 'VP004', latitude: 47.461111, longitude: 19.071944 },
  { name: 'PA005', latitude: 47.462028, longitude: 17.279167 },
  { name: 'RP337', latitude: 47.4635, longitude: 39.862139 },
  { name: 'RUPET', latitude: 47.465278, longitude: 15.7325 },
  { name: 'BP885', latitude: 47.466861, longitude: 19.732917 },
  { name: 'BP863', latitude: 47.466944, longitude: 19.222722 },
  { name: 'D307B', latitude: 47.467158, longitude: 19.222058 },
  { name: 'PH011', latitude: 47.469333, longitude: 17.523444 },
  { name: 'MANRO', latitude: 47.469722, longitude: 29.188889 },
  { name: 'WS810', latitude: 47.472106, longitude: 13.165253 },
  { name: 'VP075', latitude: 47.475833, longitude: 15.253333 },
  { name: 'VP076', latitude: 47.476667, longitude: 12.536667 },
  { name: 'EBES', latitude: 47.4775, longitude: 21.487778 },
  { name: 'BP801', latitude: 47.478528, longitude: 19.172444 },
  { name: 'DC022', latitude: 47.481389, longitude: 21.603028 },
  { name: 'GILEP', latitude: 47.483389, longitude: 18.258778 },
  { name: 'PEVIL', latitude: 47.484, longitude: 22.810444 },
  { name: 'RP303', latitude: 47.484778, longitude: 39.653417 },
  { name: 'OSPEN', latitude: 47.485292, longitude: 15.527419 },
  { name: 'RP512', latitude: 47.485361, longitude: 40.238028 },
  { name: '38THR', latitude: 47.488047, longitude: 19.188536 },
  { name: '40LOC', latitude: 47.488117, longitude: 19.188406 },
  { name: '34VOR', latitude: 47.488322, longitude: 19.187347 },
  { name: 'VP078', latitude: 47.488333, longitude: 14.273889 },
  { name: '35VOR', latitude: 47.489436, longitude: 19.185519 },
  { name: 'JIBOU', latitude: 47.491278, longitude: 23.125 },
  { name: 'VP080', latitude: 47.491667, longitude: 15.431667 },
  { name: 'VP005', latitude: 47.493333, longitude: 19.446111 },
  { name: 'DOBOK', latitude: 47.493333, longitude: 27.469444 },
  { name: 'RP334', latitude: 47.494139, longitude: 40.075194 },
  { name: 'DC005', latitude: 47.494639, longitude: 21.624694 },
  { name: 'PA015', latitude: 47.495111, longitude: 17.302139 },
  { name: 'RASTA', latitude: 47.495428, longitude: 13.381367 },
  { name: 'FOGRE', latitude: 47.495833, longitude: 20.122222 },
  { name: 'EPOLA', latitude: 47.496644, longitude: 14.887664 },
  { name: 'TAFRO', latitude: 47.496667, longitude: 23.423361 },
  { name: 'PH010', latitude: 47.499611, longitude: 17.42925 },
  { name: 'PC08B', latitude: 47.499611, longitude: 17.430728 },
  { name: 'ETARO', latitude: 47.5, longitude: 19.0 },
  { name: 'GIRIL', latitude: 47.500361, longitude: 23.048139 },
  { name: 'MQ22L', latitude: 47.502294, longitude: 21.637131 },
  { name: 'PA004', latitude: 47.503083, longitude: 17.427417 },
  { name: 'NERIS', latitude: 47.503889, longitude: 28.466111 },
  { name: 'VP081', latitude: 47.506667, longitude: 15.42 },
  { name: 'D168J', latitude: 47.506667, longitude: 26.386531 },
  { name: 'IJ601', latitude: 47.507264, longitude: 12.337658 },
  { name: 'TOMUC', latitude: 47.507472, longitude: 26.826194 },
  { name: 'EMKOG', latitude: 47.510172, longitude: 16.583489 },
  { name: 'PC08A', latitude: 47.510633, longitude: 17.425114 },
  { name: 'DITIX', latitude: 47.511111, longitude: 34.209167 },
  { name: 'CI16', latitude: 47.511156, longitude: 17.423228 },
  { name: 'LIVNU', latitude: 47.512861, longitude: 26.432028 },
  { name: 'CF16', latitude: 47.513783, longitude: 17.423508 },
  { name: 'HUMOR', latitude: 47.51475, longitude: 25.876444 },
  { name: 'IJ602', latitude: 47.514983, longitude: 12.255442 },
  { name: 'IMVOB', latitude: 47.515669, longitude: 15.586006 },
  { name: 'RP401', latitude: 47.516139, longitude: 39.963528 },
  { name: 'WS809', latitude: 47.518281, longitude: 13.163814 },
  { name: 'IJ804', latitude: 47.518553, longitude: 12.418278 },
  { name: 'BP842', latitude: 47.519083, longitude: 18.9235 },
  { name: 'VP083', latitude: 47.52, longitude: 12.303333 },
  { name: 'IJ803', latitude: 47.520828, longitude: 12.354736 },
  { name: 'PA009', latitude: 47.522972, longitude: 17.416917 },
  { name: 'D146J', latitude: 47.525197, longitude: 26.476289 },
  { name: 'ULPAX', latitude: 47.525611, longitude: 19.310194 },
  { name: 'IJ802', latitude: 47.526233, longitude: 12.205808 },
  { name: 'RP304', latitude: 47.526972, longitude: 39.992778 },
  { name: 'IRGOT', latitude: 47.53, longitude: 15.81 },
  { name: 'IJ801', latitude: 47.530081, longitude: 12.141222 },
  { name: 'D086D', latitude: 47.532014, longitude: 21.788664 },
  { name: 'RP336', latitude: 47.53275, longitude: 40.00475 },
  { name: 'FI23', latitude: 47.53275, longitude: 40.00475 },
  { name: 'TISAD', latitude: 47.532972, longitude: 22.589333 },
  { name: 'DC008', latitude: 47.53325, longitude: 21.688056 },
  { name: 'VERDA', latitude: 47.533333, longitude: 13.333333 },
  { name: 'VP085', latitude: 47.534167, longitude: 14.258333 },
  { name: 'RP313', latitude: 47.53625, longitude: 39.356722 },
  { name: 'ERKIR', latitude: 47.537778, longitude: 12.008889 },
  { name: 'RP345', latitude: 47.53875, longitude: 40.018833 },
  { name: 'RP305', latitude: 47.539278, longitude: 40.018222 },
  { name: 'PR016', latitude: 47.539694, longitude: 17.823472 },
  { name: 'TORNO', latitude: 47.539722, longitude: 18.49 },
  { name: 'CF29', latitude: 47.540867, longitude: 18.033117 },
  { name: 'ETROK', latitude: 47.540881, longitude: 13.380881 },
  { name: 'PR015', latitude: 47.541722, longitude: 18.031778 },
  { name: 'ECMAN', latitude: 47.542083, longitude: 18.885944 },
  { name: 'DC002', latitude: 47.542111, longitude: 21.868083 },
  { name: 'D114N', latitude: 47.542383, longitude: 18.034244 },
  { name: 'DO952', latitude: 47.542553, longitude: 16.210533 },
  { name: 'WS808', latitude: 47.542933, longitude: 13.171822 },
  { name: 'MURKA', latitude: 47.545, longitude: 28.813333 },
  { name: 'VP006', latitude: 47.545556, longitude: 19.044167 },
  { name: 'RP332', latitude: 47.548056, longitude: 39.708222 },
  { name: 'FD13L', latitude: 47.548417, longitude: 19.088544 },
  { name: 'BP764', latitude: 47.549417, longitude: 19.088861 },
  { name: 'DC004', latitude: 47.550361, longitude: 21.715972 },
  { name: 'PA016', latitude: 47.550722, longitude: 17.531806 },
  { name: 'PR115', latitude: 47.553528, longitude: 18.000389 },
  { name: 'BP884', latitude: 47.553611, longitude: 19.590556 },
  { name: 'VP007', latitude: 47.553889, longitude: 19.271944 },
  { name: 'BP754', latitude: 47.554361, longitude: 19.049222 },
  { name: 'BP113', latitude: 47.554417, longitude: 19.0495 },
  { name: 'VP087', latitude: 47.554722, longitude: 13.936389 },
  { name: 'DIVAL', latitude: 47.554908, longitude: 16.129642 },
  { name: 'FASAD', latitude: 47.555, longitude: 38.313333 },
  { name: 'ZOLKU', latitude: 47.557222, longitude: 17.808333 },
  { name: 'KUMOM', latitude: 47.557808, longitude: 12.371708 },
  { name: 'BODZA', latitude: 47.559167, longitude: 18.430278 },
  { name: 'RP341', latitude: 47.560917, longitude: 40.062917 },
  { name: 'FF01', latitude: 47.565111, longitude: 22.838139 },
  { name: 'ARSIN', latitude: 47.567222, longitude: 16.753611 },
  { name: 'BP213', latitude: 47.569778, longitude: 19.055944 },
  { name: 'D114K', latitude: 47.570014, longitude: 17.961211 },
  { name: 'VP088', latitude: 47.570833, longitude: 14.246111 },
  { name: 'D238N', latitude: 47.571472, longitude: 26.049806 },
  { name: 'D106N', latitude: 47.572664, longitude: 18.055303 },
  { name: 'BADOR', latitude: 47.573611, longitude: 22.108056 },
  { name: 'VP008', latitude: 47.573889, longitude: 19.158056 },
  { name: 'VOLVO', latitude: 47.574444, longitude: 29.066667 },
  { name: 'VP089', latitude: 47.575278, longitude: 13.195278 },
  { name: 'RP312', latitude: 47.575306, longitude: 39.838611 },
  { name: 'VP090', latitude: 47.576667, longitude: 12.48 },
  { name: 'DC007', latitude: 47.576833, longitude: 21.759611 },
  { name: 'VP091', latitude: 47.579167, longitude: 14.146944 },
  { name: 'D112L', latitude: 47.579833, longitude: 26.622944 },
  { name: 'RP501', latitude: 47.581028, longitude: 40.06725 },
  { name: 'RP340', latitude: 47.581028, longitude: 40.068222 },
  { name: 'VP092', latitude: 47.581667, longitude: 12.39 },
  { name: 'RP511', latitude: 47.581778, longitude: 40.205167 },
  { name: 'FI30', latitude: 47.582006, longitude: 17.926125 },
  { name: 'PR133', latitude: 47.582222, longitude: 17.925667 },
  { name: 'DC034', latitude: 47.582778, longitude: 21.769389 },
  { name: 'FD29', latitude: 47.583003, longitude: 17.926819 },
  { name: 'D043I', latitude: 47.5848, longitude: 21.772319 },
  { name: 'WS806', latitude: 47.585883, longitude: 13.222211 },
  { name: 'D238L', latitude: 47.585944, longitude: 26.094167 },
  { name: 'EWUKE', latitude: 47.587217, longitude: 16.686069 },
  { name: 'ATLIP', latitude: 47.587222, longitude: 34.704444 },
  { name: 'SOPRO', latitude: 47.587778, longitude: 16.8025 },
  { name: 'BP702', latitude: 47.588056, longitude: 19.718528 },
  { name: 'RP308', latitude: 47.588861, longitude: 39.783972 },
  { name: 'D122H', latitude: 47.589333, longitude: 26.515056 },
  { name: 'IJ805', latitude: 47.590214, longitude: 12.447781 },
  { name: 'BP872', latitude: 47.590722, longitude: 19.204667 },
  { name: 'JOZA', latitude: 47.5925, longitude: 21.557222 },
  { name: 'BP774', latitude: 47.592639, longitude: 19.201583 },
  { name: 'PR022', latitude: 47.593528, longitude: 17.896 },
  { name: 'TADID', latitude: 47.594444, longitude: 33.923056 },
  { name: 'D112J', latitude: 47.595278, longitude: 26.579333 },
  { name: 'VP093', latitude: 47.596111, longitude: 13.314722 },
  { name: 'BP736', latitude: 47.596278, longitude: 18.539278 },
  { name: 'DC015', latitude: 47.597528, longitude: 21.707111 },
  { name: 'DC003', latitude: 47.597917, longitude: 21.794111 },
  { name: 'RELVU', latitude: 47.598667, longitude: 26.393778 },
  { name: 'VATET', latitude: 47.600953, longitude: 14.033119 },
  { name: 'PR117', latitude: 47.601056, longitude: 17.676 },
  { name: 'BP841', latitude: 47.602028, longitude: 18.787722 },
  { name: 'WS807', latitude: 47.603167, longitude: 13.160969 },
  { name: 'BP755', latitude: 47.603833, longitude: 18.969167 },
  { name: 'HUZTA', latitude: 47.608056, longitude: 18.7775 },
  { name: 'WS630', latitude: 47.609722, longitude: 13.156111 },
  { name: 'BP783', latitude: 47.611361, longitude: 19.426583 },
  { name: '58VOR', latitude: 47.612183, longitude: 17.849369 },
  { name: 'CD13L', latitude: 47.612867, longitude: 18.982164 },
  { name: '70VOR', latitude: 47.613797, longitude: 22.855069 },
  { name: 'BP765', latitude: 47.614306, longitude: 18.983083 },
  { name: 'NORAH', latitude: 47.61625, longitude: 19.808167 },
  { name: 'BITRU', latitude: 47.616667, longitude: 31.702778 },
  { name: '40LOC', latitude: 47.618056, longitude: 26.385111 },
  { name: 'RP510', latitude: 47.618722, longitude: 39.538361 },
  { name: 'PEMES', latitude: 47.619444, longitude: 37.607222 },
  { name: 'RP514', latitude: 47.621556, longitude: 40.476083 },
  { name: 'RP315', latitude: 47.623083, longitude: 39.936806 },
  { name: 'TEKNO', latitude: 47.623778, longitude: 17.408917 },
  { name: 'PEJKO', latitude: 47.625, longitude: 19.86 },
  { name: 'RADIZ', latitude: 47.626286, longitude: 12.538625 },
  { name: 'RIMDA', latitude: 47.628333, longitude: 31.846667 },
  { name: 'IJ806', latitude: 47.62945, longitude: 12.328267 },
  { name: 'WW406', latitude: 47.629722, longitude: 16.541667 },
  { name: 'BP883', latitude: 47.634194, longitude: 19.457667 },
  { name: 'REPTO', latitude: 47.636389, longitude: 24.0 },
  { name: 'WW405', latitude: 47.636667, longitude: 16.518056 },
  { name: 'D090N', latitude: 47.637661, longitude: 18.077892 },
  { name: 'PR014', latitude: 47.638167, longitude: 18.073806 },
  { name: 'WS504', latitude: 47.639644, longitude: 13.243594 },
  { name: 'MODSA', latitude: 47.641667, longitude: 12.232222 },
  { name: 'D257G', latitude: 47.64275, longitude: 17.553453 },
  { name: 'RP342', latitude: 47.645111, longitude: 40.138472 },
  { name: 'PR118', latitude: 47.645306, longitude: 17.559417 },
  { name: 'RP333', latitude: 47.646167, longitude: 39.910083 },
  { name: 'D114B', latitude: 47.646778, longitude: 17.756583 },
  { name: 'PR026', latitude: 47.647111, longitude: 17.9265 },
  { name: 'WS805', latitude: 47.647267, longitude: 13.1486 },
  { name: 'PR025', latitude: 47.648417, longitude: 17.752278 },
  { name: 'D307R', latitude: 47.6506, longitude: 18.919686 },
  { name: 'UBASA', latitude: 47.651389, longitude: 23.726944 },
  { name: 'BP802', latitude: 47.653417, longitude: 18.957778 },
  { name: 'ETNOG', latitude: 47.660556, longitude: 21.97 },
  { name: 'SAT27', latitude: 47.660556, longitude: 23.551667 },
  { name: 'ML09', latitude: 47.661444, longitude: 23.431472 },
  { name: 'WS822', latitude: 47.662725, longitude: 13.269725 },
  { name: 'DC030', latitude: 47.664194, longitude: 21.677722 },
  { name: 'RP330', latitude: 47.664417, longitude: 40.606444 },
  { name: 'WW976', latitude: 47.665017, longitude: 16.6389 },
  { name: 'NURMI', latitude: 47.669444, longitude: 14.936111 },
  { name: 'BP756', latitude: 47.671056, longitude: 18.85975 },
  { name: 'FD11', latitude: 47.674428, longitude: 17.683425 },
  { name: 'PR028', latitude: 47.674861, longitude: 17.682972 },
  { name: 'CATUZ', latitude: 47.675833, longitude: 19.066139 },
  { name: 'ABETI', latitude: 47.677714, longitude: 17.012842 },
  { name: 'BP784', latitude: 47.678472, longitude: 19.318139 },
  { name: 'BP766', latitude: 47.681333, longitude: 18.873361 },
  { name: 'LATitudeLO', latitude: 47.683889, longitude: 12.806667 },
  { name: 'WS629', latitude: 47.684694, longitude: 13.102267 },
  { name: 'PR07', latitude: 47.685083, longitude: 18.036389 },
  { name: 'PEMUT', latitude: 47.685333, longitude: 23.214639 },
  { name: 'VP094', latitude: 47.686111, longitude: 13.098611 },
  { name: 'EPARI', latitude: 47.686389, longitude: 18.978056 },
  { name: 'MA01', latitude: 47.686833, longitude: 22.879889 },
  { name: 'WW974', latitude: 47.687406, longitude: 16.757717 },
  { name: 'OKIGO', latitude: 47.688444, longitude: 23.183972 },
  { name: 'ADNET', latitude: 47.689167, longitude: 13.123611 },
  { name: 'BP840', latitude: 47.689222, longitude: 18.644222 },
  { name: 'WS821', latitude: 47.690928, longitude: 13.054733 },
  { name: 'DIFOX', latitude: 47.692778, longitude: 33.297222 },
  { name: 'RP513', latitude: 47.693472, longitude: 40.625333 },
  { name: 'SOMET', latitude: 47.694444, longitude: 23.160917 },
  { name: 'LUCLA', latitude: 47.696194, longitude: 19.542222 },
  { name: 'MD16', latitude: 47.697833, longitude: 26.349389 },
  { name: 'MEREC', latitude: 47.700278, longitude: 23.100833 },
  { name: 'BP871', latitude: 47.700306, longitude: 19.026167 },
  { name: 'KEKEB', latitude: 47.702222, longitude: 38.150278 },
  { name: 'PR119', latitude: 47.703417, longitude: 17.607694 },
  { name: 'BP811', latitude: 47.703694, longitude: 19.320333 },
  { name: 'WS804', latitude: 47.706097, longitude: 13.117442 },
  { name: 'WW975', latitude: 47.709631, longitude: 16.876672 },
  { name: 'D110J', latitude: 47.711222, longitude: 23.224253 },
  { name: 'D303R', latitude: 47.71135, longitude: 19.031194 },
  { name: 'PESAT', latitude: 47.714931, longitude: 17.053158 },
  { name: 'D294G', latitude: 47.715522, longitude: 17.573294 },
  { name: 'RP329', latitude: 47.716194, longitude: 40.253222 },
  { name: 'RP314', latitude: 47.716667, longitude: 40.166972 },
  { name: 'NIPUR', latitude: 47.717222, longitude: 20.013056 },
  { name: 'WS628', latitude: 47.717356, longitude: 13.076303 },
  { name: 'PITAR', latitude: 47.7175, longitude: 12.426111 },
  { name: 'MA19', latitude: 47.719694, longitude: 22.891194 },
  { name: 'D119J', latitude: 47.724925, longitude: 23.232183 },
  { name: 'VP095', latitude: 47.726111, longitude: 16.106667 },
  { name: 'ASMIN', latitude: 47.727778, longitude: 29.223056 },
  { name: 'VP096', latitude: 47.730556, longitude: 13.079167 },
  { name: 'WS803', latitude: 47.730781, longitude: 13.085606 },
  { name: 'RP505', latitude: 47.730917, longitude: 40.449139 },
  { name: 'TOBAD', latitude: 47.732714, longitude: 12.208197 },
  { name: 'WW401', latitude: 47.732778, longitude: 16.636667 },
  { name: 'ERANI', latitude: 47.733858, longitude: 12.953033 },
  { name: 'ROMOL', latitude: 47.735556, longitude: 25.0475 },
  { name: 'TORAZ', latitude: 47.736028, longitude: 18.751639 },
  { name: 'RP507', latitude: 47.736528, longitude: 40.469639 },
  { name: 'ALAMU', latitude: 47.736944, longitude: 18.33 },
  { name: 'VP097', latitude: 47.74, longitude: 13.053333 },
  { name: 'VOZNK', latitude: 47.741667, longitude: 31.073333 },
  { name: 'WS802', latitude: 47.742186, longitude: 13.053261 },
  { name: 'ALZUR', latitude: 47.7425, longitude: 18.957222 },
  { name: 'NUBRA', latitude: 47.743069, longitude: 13.937914 },
  { name: 'WW978', latitude: 47.745258, longitude: 16.605658 },
  { name: 'GIFRA', latitude: 47.746556, longitude: 18.766194 },
  { name: 'NATEX', latitude: 47.746944, longitude: 17.5 },
  { name: 'WS801', latitude: 47.74865, longitude: 13.039361 },
  { name: 'RAVID', latitude: 47.748889, longitude: 37.225278 },
  { name: 'BM399', latitude: 47.75625, longitude: 28.135722 },
  { name: 'XOMBA', latitude: 47.756667, longitude: 18.061944 },
  { name: 'WW403', latitude: 47.757142, longitude: 16.620047 },
  { name: '30LOC', latitude: 47.760306, longitude: 22.905167 },
  { name: 'WS800', latitude: 47.761058, longitude: 13.024333 },
  { name: 'BP785', latitude: 47.761111, longitude: 19.180278 },
  { name: 'WS837', latitude: 47.762656, longitude: 13.023325 },
  { name: 'WS643', latitude: 47.762856, longitude: 13.029442 },
  { name: 'KESAM', latitude: 47.766667, longitude: 32.833333 },
  { name: 'BALAD', latitude: 47.766725, longitude: 16.234044 },
  { name: 'WW973', latitude: 47.767675, longitude: 16.724642 },
  { name: 'D153B', latitude: 47.769786, longitude: 13.018806 },
  { name: 'WS838', latitude: 47.769989, longitude: 13.048781 },
  { name: 'VP098', latitude: 47.770833, longitude: 13.077778 },
  { name: 'VP099', latitude: 47.771389, longitude: 12.934722 },
  { name: 'WS820', latitude: 47.772336, longitude: 13.063608 },
  { name: 'ZUREX', latitude: 47.7725, longitude: 12.240833 },
  { name: '28VOR', latitude: 47.772861, longitude: 22.9095 },
  { name: 'WS836', latitude: 47.773747, longitude: 13.075903 },
  { name: 'SOVEP', latitude: 47.776028, longitude: 26.314222 },
  { name: 'WS818', latitude: 47.776306, longitude: 13.014706 },
  { name: 'FF02', latitude: 47.780647, longitude: 35.260592 },
  { name: 'VP100', latitude: 47.781944, longitude: 16.191944 },
  { name: 'RW33', latitude: 47.784047, longitude: 13.009817 },
  { name: 'VP101', latitude: 47.785556, longitude: 16.315 },
  { name: 'VAMOG', latitude: 47.787222, longitude: 17.662583 },
  { name: 'PERIT', latitude: 47.788333, longitude: 21.622778 },
  { name: 'BP703', latitude: 47.788472, longitude: 19.395972 },
  { name: 'D308J', latitude: 47.788944, longitude: 26.184528 },
  { name: 'WW977', latitude: 47.789931, longitude: 16.843753 },
  { name: 'D116C', latitude: 47.793603, longitude: 13.051894 },
  { name: 'BM304', latitude: 47.793611, longitude: 27.921028 },
  { name: 'KARIL', latitude: 47.793889, longitude: 22.442222 },
  { name: 'VP102', latitude: 47.7975, longitude: 16.261944 },
  { name: 'WL501', latitude: 47.800775, longitude: 13.987317 },
  { name: 'WS501', latitude: 47.801561, longitude: 13.706561 },
  { name: 'DORIR', latitude: 47.801667, longitude: 40.849722 },
  { name: 'BP803', latitude: 47.802528, longitude: 19.164306 },
  { name: 'GUNKA', latitude: 47.803333, longitude: 23.345 },
  { name: 'ABLOG', latitude: 47.805556, longitude: 40.376111 },
  { name: 'VP103', latitude: 47.806944, longitude: 16.16 },
  { name: 'FF19', latitude: 47.808111, longitude: 22.921639 },
  { name: 'ERGOM', latitude: 47.808333, longitude: 18.733056 },
  { name: 'VP104', latitude: 47.809444, longitude: 16.329167 },
  { name: 'PODOL', latitude: 47.81, longitude: 36.283333 },
  { name: 'D308L', latitude: 47.812231, longitude: 26.149175 },
  { name: 'WW966', latitude: 47.814194, longitude: 16.773356 },
  { name: 'RP508', latitude: 47.816417, longitude: 40.390944 },
  { name: 'BP812', latitude: 47.817472, longitude: 19.479417 },
  { name: 'BM305', latitude: 47.821306, longitude: 28.037667 },
  { name: 'UNKEN', latitude: 47.821667, longitude: 12.601111 },
  { name: 'BM313', latitude: 47.822306, longitude: 28.041833 },
  { name: 'BM308', latitude: 47.822944, longitude: 28.425806 },
  { name: 'WS610', latitude: 47.823067, longitude: 12.985147 },
  { name: 'BP870', latitude: 47.823694, longitude: 18.823667 },
  { name: 'VP105', latitude: 47.824167, longitude: 12.944722 },
  { name: 'ML15', latitude: 47.824925, longitude: 12.983967 },
  { name: 'WW980', latitude: 47.825481, longitude: 16.572303 },
  { name: 'WS835', latitude: 47.825664, longitude: 13.060072 },
  { name: 'WS644', latitude: 47.825878, longitude: 13.047061 },
  { name: 'BEGLA', latitude: 47.830833, longitude: 17.114444 },
  { name: 'VP106', latitude: 47.831111, longitude: 16.241389 },
  { name: 'LAHOR', latitude: 47.831667, longitude: 19.728056 },
  { name: 'VP107', latitude: 47.834444, longitude: 16.360556 },
  { name: 'BM398', latitude: 47.8345, longitude: 28.094583 },
  { name: 'WW954', latitude: 47.834514, longitude: 17.065306 },
  { name: 'WS505', latitude: 47.835208, longitude: 13.055292 },
  { name: 'GESGI', latitude: 47.835428, longitude: 16.435158 },
  { name: 'AN800', latitude: 47.837108, longitude: 16.397292 },
  { name: 'D310N', latitude: 47.839833, longitude: 26.12025 },
  { name: 'INPUL', latitude: 47.840278, longitude: 12.741667 },
  { name: 'VP108', latitude: 47.840556, longitude: 13.039722 },
  { name: 'AN803', latitude: 47.840897, longitude: 16.310672 },
  { name: 'AN802', latitude: 47.841561, longitude: 16.295364 },
  { name: 'VEDER', latitude: 47.846389, longitude: 17.980556 },
  { name: 'KOVEK', latitude: 47.847222, longitude: 20.502778 },
  { name: 'WW972', latitude: 47.847925, longitude: 16.691469 },
  { name: 'BM309', latitude: 47.850722, longitude: 28.544028 },
  { name: 'VP109', latitude: 47.852778, longitude: 16.140278 },
  { name: 'BABLA', latitude: 47.853056, longitude: 32.279444 },
  { name: 'VP110', latitude: 47.854167, longitude: 12.811111 },
  { name: 'ANEXA', latitude: 47.854722, longitude: 18.486111 },
  { name: 'RP503', latitude: 47.855389, longitude: 40.263778 },
  { name: 'WW054', latitude: 47.856136, longitude: 16.689889 },
  { name: 'VP111', latitude: 47.856667, longitude: 16.278333 },
  { name: 'TAMAK', latitude: 47.856667, longitude: 39.218333 },
  { name: 'VP112', latitude: 47.856944, longitude: 13.126111 },
  { name: 'VP113', latitude: 47.858056, longitude: 16.350833 },
  { name: 'BM306', latitude: 47.858889, longitude: 28.197028 },
  { name: 'WS817', latitude: 47.860472, longitude: 12.961467 },
  { name: 'WW964', latitude: 47.861403, longitude: 16.638467 },
  { name: 'VP114', latitude: 47.8625, longitude: 16.3675 },
  { name: 'ROXAV', latitude: 47.863583, longitude: 26.274722 },
  { name: 'VP115', latitude: 47.864722, longitude: 16.328056 },
  { name: 'BM312', latitude: 47.866333, longitude: 28.228722 },
  { name: 'RUBES', latitude: 47.866667, longitude: 35.316667 },
  { name: 'VP116', latitude: 47.867222, longitude: 16.253889 },
  { name: 'WW686', latitude: 47.868967, longitude: 16.495522 },
  { name: 'RP509', latitude: 47.86925, longitude: 40.272083 },
  { name: 'WW979', latitude: 47.870225, longitude: 16.810742 },
  { name: 'WW991', latitude: 47.870361, longitude: 16.978539 },
  { name: 'WS617', latitude: 47.871461, longitude: 12.671217 },
  { name: 'KUSIS', latitude: 47.871667, longitude: 22.383889 },
  { name: 'RP502', latitude: 47.871694, longitude: 40.132306 },
  { name: 'WW172', latitude: 47.872203, longitude: 15.962408 },
  { name: 'BM303', latitude: 47.872278, longitude: 27.880306 },
  { name: 'WS645', latitude: 47.873253, longitude: 13.023453 },
  { name: 'VP117', latitude: 47.875, longitude: 16.288333 },
  { name: 'MQ15', latitude: 47.875303, longitude: 12.95225 },
  { name: 'MORED', latitude: 47.876353, longitude: 13.015456 },
  { name: 'WS834', latitude: 47.877892, longitude: 13.044131 },
  { name: 'WW370', latitude: 47.879889, longitude: 16.421994 },
  { name: 'BP786', latitude: 47.881778, longitude: 18.986917 },
  { name: 'WS502', latitude: 47.882661, longitude: 13.381797 },
  { name: 'VP118', latitude: 47.882778, longitude: 16.187778 },
  { name: 'BM301', latitude: 47.882806, longitude: 28.300528 },
  { name: 'WW988', latitude: 47.883097, longitude: 16.419614 },
  { name: 'WS619', latitude: 47.883128, longitude: 12.831747 },
  { name: 'D195G', latitude: 47.884822, longitude: 12.835883 },
  { name: 'ATREK', latitude: 47.886667, longitude: 29.233056 },
  { name: 'VP119', latitude: 47.887778, longitude: 13.131111 },
  { name: 'CF19', latitude: 47.889667, longitude: 22.949861 },
  { name: 'WW450', latitude: 47.894444, longitude: 15.480833 },
  { name: 'BARUG', latitude: 47.896825, longitude: 15.355536 },
  { name: 'BM302', latitude: 47.899639, longitude: 27.997306 },
  { name: 'GASNA', latitude: 47.899722, longitude: 17.133056 },
  { name: 'D130S', latitude: 47.899767, longitude: 17.628814 },
  { name: 'GONED', latitude: 47.9, longitude: 39.0 },
  { name: 'BM311', latitude: 47.903028, longitude: 28.38575 },
  { name: 'WW982', latitude: 47.905689, longitude: 16.538844 },
  { name: 'GAMLI', latitude: 47.906667, longitude: 14.778889 },
  { name: 'WW697', latitude: 47.908333, longitude: 16.666389 },
  { name: 'KUVEX', latitude: 47.908333, longitude: 17.4375 },
  { name: 'WW956', latitude: 47.908758, longitude: 16.843614 },
  { name: 'BAMUD', latitude: 47.908889, longitude: 24.209722 },
  { name: 'LIMRA', latitude: 47.910981, longitude: 14.447783 },
  { name: 'WW262', latitude: 47.911011, longitude: 16.576497 },
  { name: 'MOVOS', latitude: 47.911278, longitude: 16.437244 },
  { name: 'ODRIB', latitude: 47.911389, longitude: 39.970556 },
  { name: 'VP120', latitude: 47.914167, longitude: 16.256944 },
  { name: 'WS616', latitude: 47.915561, longitude: 12.745517 },
  { name: 'BM307', latitude: 47.915861, longitude: 28.440806 },
  { name: 'D197J', latitude: 47.916056, longitude: 33.108067 },
  { name: 'NIMDU', latitude: 47.916525, longitude: 13.821647 },
  { name: 'NEMAL', latitude: 47.918056, longitude: 13.498333 },
  { name: 'VP121', latitude: 47.918333, longitude: 13.761667 },
  { name: 'CF36', latitude: 47.918631, longitude: 33.190633 },
  { name: 'DOTEL', latitude: 47.920556, longitude: 33.578611 },
  { name: 'AV806', latitude: 47.921736, longitude: 16.39425 },
  { name: 'FI15', latitude: 47.922975, longitude: 12.921783 },
  { name: 'D188I', latitude: 47.923511, longitude: 33.150819 },
  { name: 'WW953', latitude: 47.923933, longitude: 17.131775 },
  { name: 'WS816', latitude: 47.925067, longitude: 12.920464 },
  { name: 'WW363', latitude: 47.925819, longitude: 16.456833 },
  { name: 'TEDRU', latitude: 47.926111, longitude: 40.689444 },
  { name: 'WS627', latitude: 47.926347, longitude: 13.221836 },
  { name: 'WW971', latitude: 47.927933, longitude: 16.659028 },
  { name: 'UMSOT', latitude: 47.928056, longitude: 34.644167 },
  { name: 'WS503', latitude: 47.928592, longitude: 13.195764 },
  { name: 'WW669', latitude: 47.929556, longitude: 16.444372 },
  { name: 'WS626', latitude: 47.929842, longitude: 13.098031 },
  { name: 'BM310', latitude: 47.93025, longitude: 28.502889 },
  { name: 'WW286', latitude: 47.932881, longitude: 16.499172 },
  { name: 'VAVOR', latitude: 47.934167, longitude: 12.154547 },
  { name: 'VP122', latitude: 47.935, longitude: 16.210833 },
  { name: 'MUGGU', latitude: 47.936631, longitude: 15.911564 },
  { name: 'BP705', latitude: 47.938222, longitude: 19.600472 },
  { name: 'VP123', latitude: 47.938333, longitude: 13.798333 },
  { name: 'ATLOL', latitude: 47.938611, longitude: 12.200556 },
  { name: 'VP124', latitude: 47.941667, longitude: 16.127778 },
  { name: 'AV807', latitude: 47.942003, longitude: 16.310681 },
  { name: 'VP125', latitude: 47.9425, longitude: 16.3025 },
  { name: 'URUMU', latitude: 47.942722, longitude: 17.492083 },
  { name: 'WS506', latitude: 47.946219, longitude: 12.801456 },
  { name: 'WW362', latitude: 47.948997, longitude: 16.677236 },
  { name: 'NALEM', latitude: 47.95, longitude: 39.808333 },
  { name: 'WW981', latitude: 47.950503, longitude: 16.777628 },
  { name: 'D200O', latitude: 47.951111, longitude: 17.396944 },
  { name: 'WS823', latitude: 47.951331, longitude: 12.903764 },
  { name: 'BUKOV', latitude: 47.951667, longitude: 25.958333 },
  { name: 'FF36', latitude: 47.951806, longitude: 33.195233 },
  { name: 'FF20', latitude: 47.951889, longitude: 35.367892 },
  { name: 'FN20', latitude: 47.951906, longitude: 35.368453 },
  { name: 'WW397', latitude: 47.951983, longitude: 16.581669 },
  { name: 'EROMO', latitude: 47.953611, longitude: 23.946389 },
  { name: 'VP126', latitude: 47.955, longitude: 13.721667 },
  { name: 'BOGMA', latitude: 47.955, longitude: 31.598333 },
  { name: 'REDBU', latitude: 47.955833, longitude: 12.818333 },
  { name: 'D159J', latitude: 47.956992, longitude: 16.647517 },
  { name: 'D136Q', latitude: 47.957719, longitude: 17.816903 },
  { name: 'VP127', latitude: 47.959444, longitude: 16.270556 },
  { name: 'WW668', latitude: 47.959461, longitude: 16.645778 },
  { name: 'WW383', latitude: 47.960122, longitude: 16.319625 },
  { name: 'WW384', latitude: 47.960228, longitude: 16.447039 },
  { name: 'VP128', latitude: 47.9625, longitude: 16.456667 },
  { name: 'TUREC', latitude: 47.965, longitude: 24.583056 },
  { name: 'WS813', latitude: 47.965822, longitude: 12.759586 },
  { name: 'BULIG', latitude: 47.966667, longitude: 36.216667 },
  { name: 'VP129', latitude: 47.967778, longitude: 16.171667 },
  { name: 'BP704', latitude: 47.968083, longitude: 19.103528 },
  { name: 'WW970', latitude: 47.969625, longitude: 16.641508 },
  { name: 'WW375', latitude: 47.969903, longitude: 16.491856 },
  { name: 'FD34', latitude: 47.969919, longitude: 16.642042 },
  { name: 'VP131', latitude: 47.97, longitude: 13.888333 },
  { name: 'D159V', latitude: 47.971522, longitude: 16.641361 },
  { name: 'AKONA', latitude: 47.971667, longitude: 34.136667 },
  { name: 'FI34', latitude: 47.972406, longitude: 16.640336 },
  { name: 'VP130', latitude: 47.973889, longitude: 16.245833 },
  { name: 'ARFOX', latitude: 47.974444, longitude: 18.574167 },
  { name: 'TRAUN', latitude: 47.974722, longitude: 12.5875 },
  { name: 'D209N', latitude: 47.976667, longitude: 17.354444 },
  { name: 'D259K', latitude: 47.976853, longitude: 12.62345 },
  { name: 'ROTAX', latitude: 47.97745, longitude: 12.384081 },
  { name: 'WW377', latitude: 47.978117, longitude: 16.444614 },
  { name: 'WW989', latitude: 47.978194, longitude: 16.926483 },
  { name: 'WS833', latitude: 47.979069, longitude: 13.013117 },
  { name: 'VP132', latitude: 47.982222, longitude: 13.273611 },
  { name: 'ABOLA', latitude: 47.982222, longitude: 38.416111 },
  { name: 'VP133', latitude: 47.984444, longitude: 16.319444 },
  { name: 'WW396', latitude: 47.985833, longitude: 16.416389 },
  { name: 'WW268', latitude: 47.987006, longitude: 16.634386 },
  { name: 'ADAMA', latitude: 47.987778, longitude: 17.341389 },
  { name: 'VP134', latitude: 47.988333, longitude: 16.223333 },
  { name: 'WW380', latitude: 47.990489, longitude: 16.126278 },
  { name: 'WS831', latitude: 47.991114, longitude: 12.890331 },
  { name: 'RIXOS', latitude: 47.993056, longitude: 29.142222 },
  { name: 'VP135', latitude: 47.993333, longitude: 16.198056 },
  { name: 'WS507', latitude: 47.993694, longitude: 12.794381 },
  { name: 'WW387', latitude: 47.996344, longitude: 16.774472 },
  { name: 'WW952', latitude: 47.998303, longitude: 16.909783 },
  { name: 'WW955', latitude: 47.998414, longitude: 17.187328 },
  { name: 'WS815', latitude: 47.998603, longitude: 12.873658 },
  { name: '4813E', latitude: 48.0, longitude: 13.0 },
  { name: 'VP197', latitude: 48.0, longitude: 13.798333 },
  { name: '4814E', latitude: 48.0, longitude: 14.0 },
  { name: '4815E', latitude: 48.0, longitude: 15.0 },
  { name: '4816E', latitude: 48.0, longitude: 16.0 },
  { name: 'VP198', latitude: 48.0, longitude: 16.298889 },
  { name: '4817E', latitude: 48.0, longitude: 17.0 },
  { name: '4818E', latitude: 48.0, longitude: 18.0 },
  { name: '4819E', latitude: 48.0, longitude: 19.0 },
  { name: '4820E', latitude: 48.0, longitude: 20.0 },
  { name: '4821E', latitude: 48.0, longitude: 21.0 },
  { name: '4822E', latitude: 48.0, longitude: 22.0 },
  { name: '4823E', latitude: 48.0, longitude: 23.0 },
  { name: '4824E', latitude: 48.0, longitude: 24.0 },
  { name: '4825E', latitude: 48.0, longitude: 25.0 },
  { name: '4826E', latitude: 48.0, longitude: 26.0 },
  { name: '4827E', latitude: 48.0, longitude: 27.0 },
  { name: '4828E', latitude: 48.0, longitude: 28.0 },
  { name: '4829E', latitude: 48.0, longitude: 29.0 },
  { name: '4830E', latitude: 48.0, longitude: 30.0 },
  { name: '4831E', latitude: 48.0, longitude: 31.0 },
  { name: 'BAREN', latitude: 48.0, longitude: 31.271667 },
  { name: '4832E', latitude: 48.0, longitude: 32.0 },
  { name: '4833E', latitude: 48.0, longitude: 33.0 },
  { name: '4834E', latitude: 48.0, longitude: 34.0 },
  { name: '4835E', latitude: 48.0, longitude: 35.0 },
  { name: '4836E', latitude: 48.0, longitude: 36.0 },
  { name: '4837E', latitude: 48.0, longitude: 37.0 },
  { name: '4838E', latitude: 48.0, longitude: 38.0 },
  { name: '4839E', latitude: 48.0, longitude: 39.0 },
  { name: '4840E', latitude: 48.0, longitude: 40.0 },
  { name: 'WW684', latitude: 48.000439, longitude: 16.835525 },
  { name: 'VP136', latitude: 48.0025, longitude: 12.743889 },
  { name: 'VP137', latitude: 48.0025, longitude: 13.075833 },
  { name: 'VP138', latitude: 48.0025, longitude: 13.265278 },
  { name: 'VP139', latitude: 48.008333, longitude: 13.913333 },
  { name: 'ASPIB', latitude: 48.011222, longitude: 17.036528 },
  { name: 'WW390', latitude: 48.011231, longitude: 17.036533 },
  { name: 'VP140', latitude: 48.012778, longitude: 16.164444 },
  { name: 'INROM', latitude: 48.012831, longitude: 13.190625 },
  { name: 'CF20', latitude: 48.013319, longitude: 35.406306 },
  { name: 'TABIN', latitude: 48.015278, longitude: 18.084444 },
  { name: 'VP001', latitude: 48.016389, longitude: 17.303333 },
  { name: 'WS508', latitude: 48.018953, longitude: 12.796514 },
  { name: 'WW665', latitude: 48.019325, longitude: 16.509881 },
  { name: 'VOR19', latitude: 48.019581, longitude: 16.620972 },
  { name: 'ML36', latitude: 48.020686, longitude: 33.2048 },
  { name: 'BADOV', latitude: 48.021111, longitude: 18.815833 },
  { name: 'D199J', latitude: 48.021444, longitude: 17.442528 },
  { name: 'RAJKA', latitude: 48.0225, longitude: 17.241944 },
  { name: 'WS832', latitude: 48.022722, longitude: 13.118833 },
  { name: 'MA36', latitude: 48.024367, longitude: 33.205675 },
  { name: 'WW101', latitude: 48.024708, longitude: 16.612831 },
  { name: 'WW364', latitude: 48.025575, longitude: 16.547831 },
  { name: 'WW379', latitude: 48.026094, longitude: 16.369661 },
  { name: 'WW413', latitude: 48.026119, longitude: 16.707283 },
  { name: 'EKLIP', latitude: 48.026164, longitude: 17.435167 },
  { name: 'WW417', latitude: 48.026658, longitude: 16.611233 },
  { name: 'RUMUK', latitude: 48.026667, longitude: 23.343333 },
  { name: 'TOPKU', latitude: 48.026667, longitude: 32.874444 },
  { name: '40LO4', latitude: 48.026903, longitude: 16.617389 },
  { name: 'WW416', latitude: 48.027417, longitude: 16.600986 },
  { name: 'WW275', latitude: 48.027539, longitude: 16.241167 },
  { name: 'MAKAK', latitude: 48.028056, longitude: 37.054722 },
  { name: 'WW414', latitude: 48.028478, longitude: 16.627728 },
  { name: 'VP141', latitude: 48.028611, longitude: 16.354722 },
  { name: 'WW415', latitude: 48.029297, longitude: 16.592153 },
  { name: 'VP142', latitude: 48.030278, longitude: 16.498889 },
  { name: 'WS814', latitude: 48.031269, longitude: 12.987875 },
  { name: 'WW233', latitude: 48.032642, longitude: 16.325175 },
  { name: '34THR', latitude: 48.034139, longitude: 16.614339 },
  { name: 'WW420', latitude: 48.034258, longitude: 16.634183 },
  { name: 'VYDRA', latitude: 48.034722, longitude: 17.5455 },
  { name: 'ODSUD', latitude: 48.035278, longitude: 15.498889 },
  { name: 'WW951', latitude: 48.035322, longitude: 16.798556 },
  { name: 'DD307', latitude: 48.036111, longitude: 35.852778 },
  { name: 'WW163', latitude: 48.037314, longitude: 16.612986 },
  { name: 'WW391', latitude: 48.0375, longitude: 16.211389 },
  { name: 'WW404', latitude: 48.038261, longitude: 16.659514 },
  { name: 'WW162', latitude: 48.041758, longitude: 16.839875 },
  { name: 'DD210', latitude: 48.043056, longitude: 35.866389 },
  { name: 'VAPEL', latitude: 48.043889, longitude: 30.975278 },
  { name: 'TAGAS', latitude: 48.043986, longitude: 15.653972 },
  { name: 'SOVIL', latitude: 48.046389, longitude: 15.375556 },
  { name: 'D111J', latitude: 48.048531, longitude: 16.760322 },
  { name: 'WW648', latitude: 48.049778, longitude: 16.756853 },
  { name: 'WW261', latitude: 48.0502, longitude: 16.753628 },
  { name: 'ARASA', latitude: 48.050225, longitude: 14.202364 },
  { name: 'SITNI', latitude: 48.054228, longitude: 14.834614 },
  { name: 'WW950', latitude: 48.055183, longitude: 16.740369 },
  { name: 'FI29', latitude: 48.056022, longitude: 16.737797 },
  { name: 'WW395', latitude: 48.056667, longitude: 16.485278 },
  { name: 'WW408', latitude: 48.057319, longitude: 16.685211 },
  { name: 'BAGSI', latitude: 48.057819, longitude: 14.289717 },
  { name: 'MATIG', latitude: 48.058592, longitude: 13.541494 },
  { name: 'TITIG', latitude: 48.058889, longitude: 12.559444 },
  { name: 'WW421', latitude: 48.059969, longitude: 16.611531 },
  { name: 'VP143', latitude: 48.061389, longitude: 14.952222 },
  { name: '65LOC', latitude: 48.062608, longitude: 16.717711 },
  { name: 'WW361', latitude: 48.062631, longitude: 16.716131 },
  { name: 'NOLDU', latitude: 48.064722, longitude: 29.687778 },
  { name: 'WW267', latitude: 48.066869, longitude: 16.600211 },
  { name: 'WW402', latitude: 48.067356, longitude: 16.600319 },
  { name: 'ABLOM', latitude: 48.067567, longitude: 17.087703 },
  { name: 'BALAP', latitude: 48.068056, longitude: 19.25 },
  { name: 'WW407', latitude: 48.069683, longitude: 16.599342 },
  { name: 'WW269', latitude: 48.070078, longitude: 16.598869 },
  { name: 'LAKOL', latitude: 48.071133, longitude: 12.014789 },
  { name: 'DD407', latitude: 48.071389, longitude: 35.936944 },
  { name: 'CL31', latitude: 48.072472, longitude: 17.363653 },
  { name: 'VP144', latitude: 48.072778, longitude: 16.446389 },
  { name: 'WW957', latitude: 48.072886, longitude: 16.965111 },
  { name: 'PATAK', latitude: 48.073056, longitude: 19.127222 },
  { name: 'ML34', latitude: 48.073383, longitude: 16.597778 },
  { name: 'IDKOG', latitude: 48.073889, longitude: 25.488056 },
  { name: 'BEDBA', latitude: 48.074722, longitude: 37.682778 },
  { name: 'WW274', latitude: 48.075242, longitude: 16.269061 },
  { name: 'D110O', latitude: 48.076047, longitude: 17.885008 },
  { name: 'WW296', latitude: 48.076897, longitude: 16.472122 },
  { name: 'WW418', latitude: 48.078219, longitude: 16.657719 },
  { name: 'WW663', latitude: 48.078356, longitude: 16.362078 },
  { name: 'WW419', latitude: 48.078756, longitude: 16.647169 },
  { name: 'DD107', latitude: 48.078889, longitude: 35.955278 },
  { name: 'WW412', latitude: 48.078956, longitude: 16.666606 },
  { name: 'VP145', latitude: 48.079444, longitude: 14.920556 },
  { name: '40THR', latitude: 48.079858, longitude: 16.665031 },
  { name: '40LO2', latitude: 48.080886, longitude: 16.661886 },
  { name: 'BP813', latitude: 48.081528, longitude: 19.555333 },
  { name: 'WW386', latitude: 48.0832, longitude: 16.772544 },
  { name: 'BETED', latitude: 48.083333, longitude: 20.233333 },
  { name: 'FF31', latitude: 48.084056, longitude: 17.345861 },
  { name: 'IB31F', latitude: 48.084069, longitude: 17.345886 },
  { name: 'FQ31', latitude: 48.084083, longitude: 17.345917 },
  { name: 'WW398', latitude: 48.084722, longitude: 15.796111 },
  { name: 'DIBRO', latitude: 48.085, longitude: 40.158889 },
  { name: 'VP146', latitude: 48.085833, longitude: 14.909167 },
  { name: 'GOBLI', latitude: 48.088056, longitude: 30.661667 },
  { name: 'WW381', latitude: 48.089133, longitude: 15.881594 },
  { name: 'WW100', latitude: 48.089817, longitude: 16.633603 },
  { name: 'WW009', latitude: 48.089964, longitude: 16.736967 },
  { name: 'D136G', latitude: 48.090075, longitude: 17.654189 },
  { name: 'DDS02', latitude: 48.090556, longitude: 34.996667 },
  { name: 'LEDRI', latitude: 48.090833, longitude: 17.182778 },
  { name: 'AMADI', latitude: 48.091317, longitude: 12.913792 },
  { name: 'AMRAX', latitude: 48.091389, longitude: 19.366111 },
  { name: 'WW426', latitude: 48.094628, longitude: 16.619839 },
  { name: 'WW295', latitude: 48.096339, longitude: 16.454061 },
  { name: 'AMDID', latitude: 48.097222, longitude: 12.396944 },
  { name: 'WW232', latitude: 48.097978, longitude: 16.371492 },
  { name: 'AGMIM', latitude: 48.0985, longitude: 16.987544 },
  { name: 'DDS01', latitude: 48.099444, longitude: 35.018611 },
  { name: 'WW231', latitude: 48.100825, longitude: 16.206492 },
  { name: 'WW422', latitude: 48.101047, longitude: 16.600169 },
  { name: 'GELKA', latitude: 48.101389, longitude: 20.233056 },
  { name: 'WW423', latitude: 48.101942, longitude: 16.641261 },
  { name: 'VP147', latitude: 48.103056, longitude: 15.0075 },
  { name: 'MI29', latitude: 48.104239, longitude: 16.590369 },
  { name: 'VP002', latitude: 48.106111, longitude: 17.226944 },
  { name: 'TIRPU', latitude: 48.108889, longitude: 33.55 },
  { name: 'DD212', latitude: 48.109167, longitude: 34.723611 },
  { name: 'LN405', latitude: 48.109306, longitude: 26.094111 },
  { name: 'VP148', latitude: 48.11, longitude: 16.847778 },
  { name: 'WW959', latitude: 48.110531, longitude: 16.852056 },
  { name: 'WW983', latitude: 48.110597, longitude: 16.711139 },
  { name: 'WW682', latitude: 48.1115, longitude: 16.750117 },
  { name: 'OM31', latitude: 48.114722, longitude: 17.298389 },
  { name: 'WW293', latitude: 48.115522, longitude: 16.474089 },
  { name: 'WW389', latitude: 48.118056, longitude: 16.883333 },
  { name: 'WW273', latitude: 48.118106, longitude: 16.27745 },
  { name: 'REPLI', latitude: 48.118333, longitude: 32.888333 },
  { name: 'VP003', latitude: 48.118889, longitude: 17.42 },
  { name: 'XENAK', latitude: 48.119444, longitude: 17.299722 },
  { name: 'WW007', latitude: 48.120769, longitude: 16.837794 },
  { name: 'DD310', latitude: 48.120833, longitude: 34.723611 },
  { name: 'WW661', latitude: 48.120867, longitude: 16.255128 },
  { name: 'VP149', latitude: 48.121389, longitude: 14.974167 },
  { name: '30THR', latitude: 48.122619, longitude: 17.286272 },
  { name: 'D270G', latitude: 48.123392, longitude: 17.130578 },
  { name: 'D110H', latitude: 48.124719, longitude: 17.724108 },
  { name: 'LAVDA', latitude: 48.125, longitude: 28.730556 },
  { name: 'DM466', latitude: 48.125342, longitude: 12.087558 },
  { name: 'KOXER', latitude: 48.1275, longitude: 17.048333 },
  { name: 'WW424', latitude: 48.12755, longitude: 16.61945 },
  { name: 'VP150', latitude: 48.127778, longitude: 16.629444 },
  { name: 'VP151', latitude: 48.127778, longitude: 16.708333 },
  { name: 'VP152', latitude: 48.128889, longitude: 15.010278 },
  { name: 'WW393', latitude: 48.130556, longitude: 16.039722 },
  { name: 'ML11', latitude: 48.131103, longitude: 16.507872 },
  { name: 'WW392', latitude: 48.132222, longitude: 15.942778 },
  { name: 'GEMTO', latitude: 48.133333, longitude: 22.594444 },
  { name: 'UBUXI', latitude: 48.134444, longitude: 16.611667 },
  { name: 'FF18', latitude: 48.134444, longitude: 33.220664 },
  { name: 'DM467', latitude: 48.134817, longitude: 12.216344 },
  { name: 'WL807', latitude: 48.134983, longitude: 13.779939 },
  { name: 'VP153', latitude: 48.135, longitude: 14.836667 },
  { name: 'WW304', latitude: 48.135756, longitude: 16.571297 },
  { name: 'OSKAR', latitude: 48.137778, longitude: 14.265556 },
  { name: 'ML16', latitude: 48.137978, longitude: 16.570461 },
  { name: 'GOMIG', latitude: 48.138889, longitude: 12.694722 },
  { name: 'WL802', latitude: 48.139656, longitude: 13.904194 },
  { name: 'DD111', latitude: 48.14, longitude: 35.416667 },
  { name: 'D220C', latitude: 48.141028, longitude: 17.171 },
  { name: 'IBM22', latitude: 48.141042, longitude: 17.170992 },
  { name: 'D221C', latitude: 48.141389, longitude: 17.171667 },
  { name: 'WW230', latitude: 48.143597, longitude: 16.206489 },
  { name: 'NAPSA', latitude: 48.144167, longitude: 12.345556 },
  { name: 'VP154', latitude: 48.144722, longitude: 14.146389 },
  { name: 'EBEVO', latitude: 48.145556, longitude: 12.019114 },
  { name: 'VP155', latitude: 48.145833, longitude: 14.909444 },
  { name: 'VP156', latitude: 48.146111, longitude: 14.972778 },
  { name: 'LNS05', latitude: 48.147, longitude: 26.205778 },
  { name: 'WITRI', latitude: 48.148333, longitude: 20.12 },
  { name: 'WW382', latitude: 48.148775, longitude: 15.925797 },
  { name: 'BELOL', latitude: 48.15, longitude: 38.579167 },
  { name: 'WL812', latitude: 48.150056, longitude: 14.194644 },
  { name: '40LOC', latitude: 48.150731, longitude: 16.447419 },
  { name: 'OSMOD', latitude: 48.151667, longitude: 15.514722 },
  { name: '40TH2', latitude: 48.1519, longitude: 16.443811 },
  { name: 'WW160', latitude: 48.153458, longitude: 16.792519 },
  { name: 'DM468', latitude: 48.154219, longitude: 12.486986 },
  { name: 'CI33', latitude: 48.154803, longitude: 26.059817 },
  { name: 'WW659', latitude: 48.157183, longitude: 16.143119 },
  { name: 'LAMIV', latitude: 48.158056, longitude: 36.975278 },
  { name: 'WL822', latitude: 48.159764, longitude: 14.486347 },
  { name: 'EBEDA', latitude: 48.160278, longitude: 12.126944 },
  { name: 'WW388', latitude: 48.163036, longitude: 16.7074 },
  { name: 'WW164', latitude: 48.163036, longitude: 16.707406 },
  { name: 'WL827', latitude: 48.163681, longitude: 14.610719 },
  { name: 'DM469', latitude: 48.164214, longitude: 12.630269 },
  { name: 'WW425', latitude: 48.164267, longitude: 16.804961 },
  { name: 'BADIT', latitude: 48.164444, longitude: 12.834444 },
  { name: 'RIGSA', latitude: 48.164444, longitude: 20.751667 },
  { name: 'LAMEX', latitude: 48.165, longitude: 30.088333 },
  { name: 'D291N', latitude: 48.166497, longitude: 16.397947 },
  { name: 'CF18', latitude: 48.167586, longitude: 33.225297 },
  { name: 'WW001', latitude: 48.167869, longitude: 16.557797 },
  { name: 'VP004', latitude: 48.168056, longitude: 17.275556 },
  { name: 'DM476', latitude: 48.168144, longitude: 12.080467 },
  { name: 'VP157', latitude: 48.168333, longitude: 14.0775 },
  { name: 'VP005', latitude: 48.169167, longitude: 17.144444 },
  { name: 'DME11', latitude: 48.169572, longitude: 16.378472 },
  { name: 'LUGEM', latitude: 48.172222, longitude: 15.392222 },
  { name: 'VP158', latitude: 48.173889, longitude: 15.845278 },
  { name: 'EDEMU', latitude: 48.174444, longitude: 19.808056 },
  { name: 'DEMOP', latitude: 48.174722, longitude: 20.056944 },
  { name: 'VP159', latitude: 48.175278, longitude: 16.123056 },
  { name: 'IBM31', latitude: 48.176578, longitude: 17.202608 },
  { name: 'D310B', latitude: 48.176583, longitude: 17.202611 },
  { name: 'VP160', latitude: 48.176944, longitude: 14.249444 },
  { name: 'VP161', latitude: 48.177222, longitude: 14.483333 },
  { name: 'VP162', latitude: 48.177778, longitude: 13.991944 },
  { name: 'WW011', latitude: 48.179317, longitude: 16.618706 },
  { name: 'WW373', latitude: 48.180553, longitude: 16.795267 },
  { name: '40LO3', latitude: 48.181242, longitude: 16.552122 },
  { name: 'WW948', latitude: 48.181731, longitude: 16.351628 },
  { name: 'VALPA', latitude: 48.181944, longitude: 38.940556 },
  { name: 'D291J', latitude: 48.182728, longitude: 16.34855 },
  { name: 'WW961', latitude: 48.183786, longitude: 16.630583 },
  { name: '40TH3', latitude: 48.183878, longitude: 16.551006 },
  { name: 'FF33', latitude: 48.184028, longitude: 26.037667 },
  { name: 'MM22', latitude: 48.186417, longitude: 17.23625 },
  { name: 'LN101', latitude: 48.188389, longitude: 26.033889 },
  { name: 'WW165', latitude: 48.189794, longitude: 16.620119 },
  { name: 'MAREG', latitude: 48.190489, longitude: 16.969089 },
  { name: 'TEKVI', latitude: 48.191111, longitude: 18.549444 },
  { name: 'KUROR', latitude: 48.191667, longitude: 32.966667 },
  { name: 'WW657', latitude: 48.193392, longitude: 16.030956 },
  { name: 'GUKOL', latitude: 48.195, longitude: 39.943333 },
  { name: '80VOR', latitude: 48.195758, longitude: 16.545883 },
  { name: 'VP163', latitude: 48.197222, longitude: 16.455556 },
  { name: 'D229I', latitude: 48.198314, longitude: 17.867367 },
  { name: '20THR', latitude: 48.203583, longitude: 17.261033 },
  { name: 'DD209', latitude: 48.203611, longitude: 35.116944 },
  { name: 'MIZOL', latitude: 48.204167, longitude: 20.242222 },
  { name: 'WW005', latitude: 48.205858, longitude: 16.718783 },
  { name: 'WW002', latitude: 48.206067, longitude: 16.569667 },
  { name: 'VP006', latitude: 48.206667, longitude: 17.206111 },
  { name: 'MEDEL', latitude: 48.207222, longitude: 13.670278 },
  { name: 'XT802', latitude: 48.207317, longitude: 15.836744 },
  { name: 'KIRDI', latitude: 48.207678, longitude: 12.821653 },
  { name: 'WW004', latitude: 48.212519, longitude: 16.57755 },
  { name: 'D040C', latitude: 48.213333, longitude: 17.275278 },
  { name: 'FF11', latitude: 48.215931, longitude: 16.245581 },
  { name: 'WL808', latitude: 48.218153, longitude: 13.77285 },
  { name: 'AMKAB', latitude: 48.218333, longitude: 31.505556 },
  { name: 'WW650', latitude: 48.219181, longitude: 16.235472 },
  { name: 'VP007', latitude: 48.219444, longitude: 17.394722 },
  { name: 'WW003', latitude: 48.220333, longitude: 16.634269 },
  { name: 'VELOM', latitude: 48.2211, longitude: 13.499408 },
  { name: 'ROMKA', latitude: 48.221944, longitude: 21.840278 },
  { name: 'WW680', latitude: 48.222503, longitude: 16.664356 },
  { name: 'LIDSI', latitude: 48.222831, longitude: 13.897306 },
  { name: 'OM22', latitude: 48.223917, longitude: 17.290361 },
  { name: 'D263K', latitude: 48.224533, longitude: 13.943664 },
  { name: 'VP164', latitude: 48.225, longitude: 16.629444 },
  { name: 'D263E', latitude: 48.225283, longitude: 13.978694 },
  { name: 'LNS01', latitude: 48.226417, longitude: 26.146472 },
  { name: 'KOSIR', latitude: 48.226667, longitude: 36.328333 },
  { name: 'WL803', latitude: 48.227197, longitude: 14.016806 },
  { name: 'CI08', latitude: 48.227247, longitude: 14.018333 },
  { name: '60VOR', latitude: 48.227786, longitude: 16.532178 },
  { name: 'WL824', latitude: 48.229053, longitude: 14.068675 },
  { name: 'WW655', latitude: 48.229492, longitude: 15.918633 },
  { name: 'SIMBA', latitude: 48.230153, longitude: 13.015817 },
  { name: 'FI08', latitude: 48.230267, longitude: 14.102969 },
  { name: 'VP165', latitude: 48.230278, longitude: 15.641389 },
  { name: 'LITKU', latitude: 48.230528, longitude: 19.598528 },
  { name: '19THR', latitude: 48.230986, longitude: 14.123439 },
  { name: 'OTGAR', latitude: 48.231389, longitude: 15.525556 },
  { name: 'MEBEK', latitude: 48.231908, longitude: 12.565506 },
  { name: 'WW651', latitude: 48.233622, longitude: 16.190533 },
  { name: 'RATGI', latitude: 48.234694, longitude: 12.337419 },
  { name: 'D277R', latitude: 48.235278, longitude: 17.111389 },
  { name: 'WW266', latitude: 48.236922, longitude: 16.137486 },
  { name: 'WL623', latitude: 48.2371, longitude: 14.321783 },
  { name: '40THR', latitude: 48.237225, longitude: 14.305228 },
  { name: 'VP166', latitude: 48.2375, longitude: 16.397222 },
  { name: 'WL823', latitude: 48.239078, longitude: 14.360814 },
  { name: 'WW013', latitude: 48.239278, longitude: 16.528489 },
  { name: 'WW371', latitude: 48.241294, longitude: 16.666756 },
  { name: 'ETOKA', latitude: 48.241389, longitude: 32.357778 },
  { name: 'CI26', latitude: 48.241442, longitude: 14.433161 },
  { name: 'PEROL', latitude: 48.242969, longitude: 14.480386 },
  { name: 'DDW03', latitude: 48.245, longitude: 34.775 },
  { name: 'WL828', latitude: 48.246892, longitude: 14.604958 },
  { name: 'D159E', latitude: 48.248603, longitude: 16.523258 },
  { name: 'WW968', latitude: 48.248911, longitude: 16.523392 },
  { name: 'WW688', latitude: 48.249403, longitude: 16.808478 },
  { name: 'D339J', latitude: 48.251594, longitude: 16.521586 },
  { name: 'VP167', latitude: 48.252778, longitude: 16.088889 },
  { name: 'VP168', latitude: 48.255556, longitude: 16.011111 },
  { name: 'IB22F', latitude: 48.255581, longitude: 17.336139 },
  { name: 'FQ22', latitude: 48.255583, longitude: 17.336139 },
  { name: 'FI22', latitude: 48.255583, longitude: 17.336139 },
  { name: 'ABVAM', latitude: 48.258889, longitude: 33.573333 },
  { name: 'LURIK', latitude: 48.26, longitude: 25.981667 },
  { name: 'WW006', latitude: 48.263956, longitude: 16.579958 },
  { name: 'CI22', latitude: 48.267528, longitude: 17.353472 },
  { name: 'WW652', latitude: 48.268425, longitude: 16.085106 },
  { name: 'TOVKA', latitude: 48.270156, longitude: 16.926322 },
  { name: 'BESPI', latitude: 48.270278, longitude: 38.337222 },
  { name: 'WW985', latitude: 48.271033, longitude: 16.643972 },
  { name: 'WW394', latitude: 48.271389, longitude: 16.058333 },
  { name: 'VP008', latitude: 48.271944, longitude: 17.008889 },
  { name: 'DD205', latitude: 48.273111, longitude: 34.79575 },
  { name: 'DD404', latitude: 48.273194, longitude: 35.394306 },
  { name: 'VP169', latitude: 48.275556, longitude: 14.369722 },
  { name: 'DM457', latitude: 48.277225, longitude: 12.062275 },
  { name: '29VOR', latitude: 48.277422, longitude: 16.510897 },
  { name: 'WW400', latitude: 48.280106, longitude: 16.849294 },
  { name: 'DD103', latitude: 48.286944, longitude: 35.470556 },
  { name: 'DM458', latitude: 48.290317, longitude: 12.240322 },
  { name: 'WL602', latitude: 48.293133, longitude: 14.040592 },
  { name: 'D306F', latitude: 48.293231, longitude: 13.987578 },
  { name: 'ALONgitudeU', latitude: 48.293889, longitude: 12.027778 },
  { name: 'MEDIX', latitude: 48.294167, longitude: 15.408611 },
  { name: 'WW681', latitude: 48.294828, longitude: 16.222214 },
  { name: 'WW963', latitude: 48.296158, longitude: 16.503725 },
  { name: 'ESEGA', latitude: 48.296444, longitude: 13.181469 },
  { name: 'WL601', latitude: 48.297092, longitude: 14.153244 },
  { name: 'VP170', latitude: 48.297222, longitude: 16.347222 },
  { name: 'XT803', latitude: 48.298389, longitude: 15.821969 },
  { name: 'WL621', latitude: 48.299158, longitude: 14.213031 },
  { name: 'WL806', latitude: 48.301314, longitude: 13.765739 },
  { name: 'DM459', latitude: 48.302456, longitude: 12.409128 },
  { name: 'WL624', latitude: 48.302994, longitude: 14.325217 },
  { name: 'DM092', latitude: 48.303819, longitude: 12.104114 },
  { name: 'D271J', latitude: 48.304031, longitude: 17.814283 },
  { name: 'WW653', latitude: 48.304575, longitude: 15.972661 },
  { name: 'WL801', latitude: 48.306, longitude: 13.890392 },
  { name: 'VP171', latitude: 48.306944, longitude: 16.629444 },
  { name: 'WW658', latitude: 48.307119, longitude: 16.251825 },
  { name: 'XT804', latitude: 48.307292, longitude: 15.946072 },
  { name: 'WW008', latitude: 48.311261, longitude: 16.528883 },
  { name: 'IBLIZ', latitude: 48.312222, longitude: 20.774722 },
  { name: 'LN410', latitude: 48.314417, longitude: 26.392083 },
  { name: 'ASTON', latitude: 48.315444, longitude: 17.422886 },
  { name: 'VP172', latitude: 48.315833, longitude: 14.151944 },
  { name: 'WW965', latitude: 48.316386, longitude: 16.650675 },
  { name: 'WL811', latitude: 48.316428, longitude: 14.181711 },
  { name: '10THR', latitude: 48.316878, longitude: 16.079911 },
  { name: 'VP009', latitude: 48.318056, longitude: 17.297778 },
  { name: 'LUKOL', latitude: 48.318333, longitude: 32.019444 },
  { name: 'WW664', latitude: 48.318389, longitude: 16.439367 },
  { name: 'D356H', latitude: 48.318517, longitude: 17.544722 },
  { name: 'XT805', latitude: 48.321269, longitude: 16.142006 },
  { name: 'WW679', latitude: 48.321514, longitude: 16.363167 },
  { name: 'BANUV', latitude: 48.323333, longitude: 24.500556 },
  { name: 'CIFER', latitude: 48.324583, longitude: 17.436056 },
  { name: 'PITOK', latitude: 48.324722, longitude: 20.371667 },
  { name: 'VP173', latitude: 48.325, longitude: 14.330556 },
  { name: 'WL821', latitude: 48.326169, longitude: 14.474403 },
  { name: 'WL826', latitude: 48.330097, longitude: 14.599175 },
  { name: 'FI16', latitude: 48.330239, longitude: 16.488719 },
  { name: 'XENDI', latitude: 48.330917, longitude: 17.92475 },
  { name: 'LN301', latitude: 48.332556, longitude: 25.924417 },
  { name: 'ABITU', latitude: 48.333333, longitude: 18.324722 },
  { name: 'FF15', latitude: 48.336056, longitude: 25.921139 },
  { name: 'TULLN', latitude: 48.338333, longitude: 16.028611 },
  { name: 'WW670', latitude: 48.338853, longitude: 16.485047 },
  { name: 'MUSSU', latitude: 48.339167, longitude: 40.824722 },
  { name: 'LONgitudeLA', latitude: 48.34, longitude: 22.319722 },
  { name: 'DM072', latitude: 48.340731, longitude: 12.034464 },
  { name: 'WW468', latitude: 48.3425, longitude: 16.742778 },
  { name: 'WW656', latitude: 48.343431, longitude: 16.139414 },
  { name: 'DM094', latitude: 48.344233, longitude: 12.030731 },
  { name: 'DM093', latitude: 48.344569, longitude: 12.076928 },
  { name: 'WW010', latitude: 48.345603, longitude: 16.505561 },
  { name: 'GETBO', latitude: 48.348333, longitude: 37.1 },
  { name: 'FD08', latitude: 48.349033, longitude: 34.92035 },
  { name: 'WW055', latitude: 48.349706, longitude: 16.561511 },
  { name: 'WW440', latitude: 48.35, longitude: 16.716667 },
  { name: 'FD26', latitude: 48.350133, longitude: 35.267342 },
  { name: 'VP174', latitude: 48.355556, longitude: 16.241944 },
  { name: 'ASLOT', latitude: 48.356361, longitude: 17.730833 },
  { name: 'DD203', latitude: 48.356583, longitude: 34.669667 },
  { name: 'DD202', latitude: 48.356833, longitude: 34.79525 },
  { name: 'DD402', latitude: 48.356889, longitude: 35.394778 },
  { name: 'CF26', latitude: 48.356978, longitude: 35.317683 },
  { name: 'CI08', latitude: 48.356981, longitude: 34.869292 },
  { name: 'DD201', latitude: 48.357083, longitude: 34.919167 },
  { name: 'DD401', latitude: 48.357083, longitude: 35.267639 },
  { name: 'DD302', latitude: 48.357222, longitude: 34.775 },
  { name: 'DD301', latitude: 48.357222, longitude: 34.900556 },
  { name: 'MM08', latitude: 48.357222, longitude: 35.069167 },
  { name: 'MM26', latitude: 48.357222, longitude: 35.133444 },
  { name: 'DD101', latitude: 48.357222, longitude: 35.305556 },
  { name: '27LOC', latitude: 48.357231, longitude: 35.170406 },
  { name: 'WW012', latitude: 48.357269, longitude: 16.404228 },
  { name: 'MD08', latitude: 48.357472, longitude: 35.062722 },
  { name: 'MD26', latitude: 48.357706, longitude: 35.139403 },
  { name: 'GODOD', latitude: 48.357778, longitude: 32.673056 },
  { name: '90DME', latitude: 48.359158, longitude: 12.001194 },
  { name: 'ODNIV', latitude: 48.359722, longitude: 35.103056 },
  { name: 'WW678', latitude: 48.361472, longitude: 16.605889 },
  { name: 'KENIN', latitude: 48.361667, longitude: 21.927222 },
  { name: 'TURIS', latitude: 48.362222, longitude: 18.511667 },
  { name: 'NELBI', latitude: 48.365233, longitude: 12.07565 },
  { name: 'CI15', latitude: 48.365569, longitude: 25.899328 },
  { name: 'VP175', latitude: 48.366667, longitude: 16.3 },
  { name: 'LN210', latitude: 48.370333, longitude: 25.754 },
  { name: 'WW671', latitude: 48.370897, longitude: 16.471358 },
  { name: 'LNN10', latitude: 48.371028, longitude: 26.03775 },
  { name: 'D291L', latitude: 48.376097, longitude: 17.774133 },
  { name: 'DM448', latitude: 48.376292, longitude: 12.226256 },
  { name: 'VP176', latitude: 48.376667, longitude: 15.857778 },
  { name: 'DD102', latitude: 48.377222, longitude: 35.4275 },
  { name: 'VP177', latitude: 48.378333, longitude: 16.308333 },
  { name: 'DD206', latitude: 48.378889, longitude: 34.321389 },
  { name: 'WW654', latitude: 48.379633, longitude: 16.026842 },
  { name: 'D267N', latitude: 48.380167, longitude: 34.766981 },
  { name: '91DME', latitude: 48.38165, longitude: 12.020317 },
  { name: 'VP178', latitude: 48.383333, longitude: 16.183333 },
  { name: 'ME011', latitude: 48.384231, longitude: 12.626656 },
  { name: 'GUDEG', latitude: 48.387431, longitude: 12.093969 },
  { name: 'PETOG', latitude: 48.387778, longitude: 38.739444 },
  { name: 'DM449', latitude: 48.38845, longitude: 12.395344 },
  { name: 'XT801', latitude: 48.389461, longitude: 15.807186 },
  { name: 'D289P', latitude: 48.392158, longitude: 17.689144 },
  { name: 'ME023', latitude: 48.392833, longitude: 12.625508 },
  { name: 'DETEX', latitude: 48.394444, longitude: 12.331389 },
  { name: 'DD305', latitude: 48.395278, longitude: 34.195556 },
  { name: 'VP179', latitude: 48.396667, longitude: 15.626667 },
  { name: 'VP180', latitude: 48.396667, longitude: 16.251667 },
  { name: 'BIBAG', latitude: 48.39705, longitude: 12.749781 },
  { name: 'D040O', latitude: 48.397769, longitude: 17.542922 },
  { name: 'KREMS', latitude: 48.397778, longitude: 15.625 },
  { name: 'VP181', latitude: 48.398333, longitude: 15.71 },
  { name: 'DM438', latitude: 48.398358, longitude: 12.243011 },
  { name: 'D356M', latitude: 48.398458, longitude: 17.542919 },
  { name: 'ME010', latitude: 48.3993, longitude: 12.825011 },
  { name: 'HAFEN', latitude: 48.400556, longitude: 15.6475 },
  { name: 'VP182', latitude: 48.401667, longitude: 16.168333 },
  { name: 'EKLEN', latitude: 48.402639, longitude: 12.895167 },
  { name: 'WW666', latitude: 48.405833, longitude: 16.404444 },
  { name: 'ME022', latitude: 48.405872, longitude: 12.981728 },
  { name: 'WW014', latitude: 48.408028, longitude: 16.463078 },
  { name: 'LN205', latitude: 48.408278, longitude: 25.866056 },
  { name: 'DM439', latitude: 48.409075, longitude: 12.392025 },
  { name: 'INSUM', latitude: 48.409167, longitude: 37.771667 },
  { name: 'VP185', latitude: 48.41, longitude: 16.141667 },
  { name: 'DNP36', latitude: 48.412144, longitude: 34.201294 },
  { name: 'WW016', latitude: 48.412294, longitude: 16.578189 },
  { name: 'CF01', latitude: 48.412639, longitude: 17.751194 },
  { name: 'NIGER', latitude: 48.412639, longitude: 17.751208 },
  { name: 'AKIMA', latitude: 48.413056, longitude: 13.310278 },
  { name: 'INROG', latitude: 48.414722, longitude: 26.812778 },
  { name: 'VP183', latitude: 48.415, longitude: 16.223333 },
  { name: 'PETEN', latitude: 48.416247, longitude: 14.173911 },
  { name: 'VP184', latitude: 48.417222, longitude: 16.018611 },
  { name: 'DM021', latitude: 48.417244, longitude: 12.037592 },
  { name: 'WW677', latitude: 48.417644, longitude: 16.322072 },
  { name: 'LUGAT', latitude: 48.418333, longitude: 39.375 },
  { name: 'D289U', latitude: 48.424278, longitude: 17.573744 },
  { name: 'ME015', latitude: 48.424447, longitude: 12.630667 },
  { name: 'D356O', latitude: 48.426772, longitude: 17.542686 },
  { name: 'WL500', latitude: 48.429942, longitude: 14.592208 },
  { name: 'VP186', latitude: 48.431667, longitude: 16.193333 },
  { name: 'VP187', latitude: 48.432222, longitude: 16.291667 },
  { name: 'VAPIT', latitude: 48.43325, longitude: 17.542639 },
  { name: 'ME014', latitude: 48.434603, longitude: 12.799808 },
  { name: 'VP188', latitude: 48.435, longitude: 16.233333 },
  { name: 'RUBOB', latitude: 48.435278, longitude: 18.995556 },
  { name: 'SUBEN', latitude: 48.436389, longitude: 13.336667 },
  { name: 'ME024', latitude: 48.436822, longitude: 12.640511 },
  { name: 'KOLUM', latitude: 48.437778, longitude: 21.074806 },
  { name: 'DOSEL', latitude: 48.438611, longitude: 12.480833 },
  { name: 'WW672', latitude: 48.440425, longitude: 16.442453 },
  { name: 'DD204', latitude: 48.440528, longitude: 34.79475 },
  { name: 'DD403', latitude: 48.440611, longitude: 35.395306 },
  { name: 'VP010', latitude: 48.441389, longitude: 17.758889 },
  { name: 'VP189', latitude: 48.441667, longitude: 16.163333 },
  { name: 'WW470', latitude: 48.4425, longitude: 16.664722 },
  { name: 'D036T', latitude: 48.444897, longitude: 12.139017 },
  { name: 'VP190', latitude: 48.445, longitude: 15.713333 },
  { name: 'GANRA', latitude: 48.445278, longitude: 37.186944 },
  { name: 'LNN05', latitude: 48.446306, longitude: 25.978083 },
  { name: 'KOSNA', latitude: 48.448333, longitude: 31.433333 },
  { name: 'WW967', latitude: 48.448975, longitude: 16.670872 },
  { name: 'ANAGI', latitude: 48.451944, longitude: 40.558056 },
  { name: 'UNDOL', latitude: 48.452222, longitude: 27.721667 },
  { name: 'D191J', latitude: 48.456944, longitude: 17.767222 },
  { name: 'SIRSI', latitude: 48.457222, longitude: 22.827222 },
  { name: 'FF33', latitude: 48.457814, longitude: 32.314917 },
  { name: 'WW460', latitude: 48.4625, longitude: 16.470833 },
  { name: 'WW676', latitude: 48.462947, longitude: 16.562989 },
  { name: 'WW987', latitude: 48.463083, longitude: 16.562944 },
  { name: 'LN215', latitude: 48.467, longitude: 25.672222 },
  { name: 'DM427', latitude: 48.468053, longitude: 12.030256 },
  { name: 'ME020', latitude: 48.468567, longitude: 12.876281 },
  { name: 'DDW01', latitude: 48.469722, longitude: 34.775 },
  { name: 'OSLAN', latitude: 48.471389, longitude: 32.984444 },
  { name: 'ME021', latitude: 48.472306, longitude: 12.976181 },
  { name: 'WW692', latitude: 48.4725, longitude: 16.7275 },
  { name: 'VP191', latitude: 48.473333, longitude: 16.141667 },
  { name: 'CF01', latitude: 48.481167, longitude: 21.177806 },
  { name: 'GONPI', latitude: 48.481175, longitude: 21.177817 },
  { name: 'NERDU', latitude: 48.481497, longitude: 16.099261 },
  { name: 'TEGPA', latitude: 48.4825, longitude: 40.241111 },
  { name: 'DM428', latitude: 48.482686, longitude: 12.229178 },
  { name: 'ABULI', latitude: 48.484167, longitude: 20.486667 },
  { name: 'VP192', latitude: 48.486111, longitude: 15.990278 },
  { name: 'SELAR', latitude: 48.487778, longitude: 13.1425 },
  { name: 'DD207', latitude: 48.488333, longitude: 34.491111 },
  { name: 'DD105', latitude: 48.489722, longitude: 35.052778 },
  { name: 'DD104', latitude: 48.490278, longitude: 35.397222 },
  { name: 'D219P', latitude: 48.491117, longitude: 20.959903 },
  { name: 'HOLXA', latitude: 48.4914, longitude: 16.045489 },
  { name: 'DM429', latitude: 48.493422, longitude: 12.378447 },
  { name: 'FF01', latitude: 48.493583, longitude: 17.780611 },
  { name: 'PP01F', latitude: 48.493592, longitude: 17.780606 },
  { name: 'ALUTU', latitude: 48.496111, longitude: 12.1025 },
  { name: 'VP193', latitude: 48.496667, longitude: 15.618333 },
  { name: 'WW675', latitude: 48.497739, longitude: 16.287711 },
  { name: 'VP194', latitude: 48.498333, longitude: 16.23 },
  { name: 'TUMBI', latitude: 48.502778, longitude: 26.602222 },
  { name: 'SOMID', latitude: 48.505278, longitude: 17.717222 },
  { name: 'KALIF', latitude: 48.505833, longitude: 19.571944 },
  { name: 'TEMTA', latitude: 48.5075, longitude: 15.663611 },
  { name: 'WW469', latitude: 48.507778, longitude: 16.791944 },
  { name: 'DM015', latitude: 48.515547, longitude: 12.037639 },
  { name: 'TOROS', latitude: 48.518333, longitude: 39.863333 },
  { name: 'MASUR', latitude: 48.520097, longitude: 15.439292 },
  { name: 'WW673', latitude: 48.520553, longitude: 16.408269 },
  { name: 'KEKED', latitude: 48.523056, longitude: 21.291389 },
  { name: 'LORDU', latitude: 48.524444, longitude: 32.601389 },
  { name: 'LNE01', latitude: 48.526556, longitude: 26.2145 },
  { name: 'VP195', latitude: 48.529722, longitude: 15.703056 },
  { name: 'WW192', latitude: 48.532353, longitude: 15.214964 },
  { name: 'KUFIK', latitude: 48.533333, longitude: 18.005 },
  { name: 'EKTIN', latitude: 48.533333, longitude: 32.283333 },
  { name: 'TAKON', latitude: 48.536667, longitude: 23.188333 },
  { name: 'NENUM', latitude: 48.538611, longitude: 12.947778 },
  { name: 'WW674', latitude: 48.543233, longitude: 16.528919 },
  { name: 'WW191', latitude: 48.544186, longitude: 14.990519 },
  { name: 'DIMAB', latitude: 48.544444, longitude: 37.037222 },
  { name: 'DD208', latitude: 48.553056, longitude: 34.794167 },
  { name: '40DME', latitude: 48.554139, longitude: 17.802611 },
  { name: 'WW190', latitude: 48.555581, longitude: 14.765986 },
  { name: 'FF01', latitude: 48.562306, longitude: 21.206028 },
  { name: 'KZ01F', latitude: 48.562319, longitude: 21.206019 },
  { name: 'VENEN', latitude: 48.566553, longitude: 14.541344 },
  { name: 'ADLET', latitude: 48.5676, longitude: 14.299283 },
  { name: 'WW171', latitude: 48.569597, longitude: 15.889206 },
  { name: 'ANKER', latitude: 48.572625, longitude: 12.002328 },
  { name: 'WW471', latitude: 48.573333, longitude: 16.132222 },
  { name: 'MABOD', latitude: 48.574558, longitude: 16.690097 },
  { name: 'BOJNA', latitude: 48.575833, longitude: 18.056111 },
  { name: 'DM412', latitude: 48.577156, longitude: 12.011833 },
  { name: 'VP196', latitude: 48.578056, longitude: 15.83 },
  { name: 'D192C', latitude: 48.578333, longitude: 17.811389 },
  { name: 'UPIVA', latitude: 48.578611, longitude: 18.816944 },
  { name: 'TAKOS', latitude: 48.5825, longitude: 20.266667 },
  { name: 'LNN01', latitude: 48.583528, longitude: 25.978972 },
  { name: 'RENKA', latitude: 48.584842, longitude: 13.505225 },
  { name: 'D187F', latitude: 48.585833, longitude: 21.214222 },
  { name: 'KZM19', latitude: 48.585836, longitude: 21.214208 },
  { name: 'REKLU', latitude: 48.5875, longitude: 16.937778 },
  { name: 'KSC06', latitude: 48.588056, longitude: 21.215 },
  { name: 'LADAG', latitude: 48.588981, longitude: 15.041106 },
  { name: 'DIDUR', latitude: 48.593611, longitude: 27.838889 },
  { name: 'LANDU', latitude: 48.596361, longitude: 12.273928 },
  { name: '30THR', latitude: 48.600769, longitude: 21.219414 },
  { name: 'DEGIN', latitude: 48.601389, longitude: 12.706111 },
  { name: 'D323W', latitude: 48.605617, longitude: 17.741642 },
  { name: 'PNY', latitude: 48.608125, longitude: 17.822253 },
  { name: 'TUSTO', latitude: 48.608333, longitude: 12.441667 },
  { name: 'OBENO', latitude: 48.608333, longitude: 22.643056 },
  { name: 'NEPAK', latitude: 48.610278, longitude: 20.529167 },
  { name: 'VP011', latitude: 48.6125, longitude: 21.000833 },
  { name: 'BERVA', latitude: 48.6175, longitude: 17.541111 },
  { name: '40VOR', latitude: 48.618194, longitude: 21.2255 },
  { name: 'FF15', latitude: 48.620003, longitude: 32.231417 },
  { name: 'BINGU', latitude: 48.620278, longitude: 12.6175 },
  { name: 'DM017', latitude: 48.623656, longitude: 12.037942 },
  { name: 'ROSAB', latitude: 48.633333, longitude: 12.815 },
  { name: 'LASOT', latitude: 48.634722, longitude: 22.245833 },
  { name: 'D256S', latitude: 48.636283, longitude: 20.780847 },
  { name: 'MA10Z', latitude: 48.639242, longitude: 22.232133 },
  { name: 'MM01', latitude: 48.641111, longitude: 21.2335 },
  { name: 'ORSUS', latitude: 48.646111, longitude: 34.277222 },
  { name: 'RULUT', latitude: 48.648333, longitude: 23.638889 },
  { name: 'DD110', latitude: 48.648611, longitude: 35.354444 },
  { name: 'TAGAN', latitude: 48.65, longitude: 36.441667 },
  { name: 'LAMSI', latitude: 48.653333, longitude: 13.583333 },
  { name: 'TOKAJ', latitude: 48.655833, longitude: 21.832778 },
  { name: 'VP012', latitude: 48.657778, longitude: 21.3125 },
  { name: 'DDW02', latitude: 48.658889, longitude: 35.185 },
  { name: 'TIVON', latitude: 48.660833, longitude: 19.528611 },
  { name: 'EVULA', latitude: 48.6625, longitude: 21.693056 },
  { name: 'KRAMK', latitude: 48.666667, longitude: 37.616667 },
  { name: 'EXIDA', latitude: 48.6675, longitude: 21.091111 },
  { name: 'FF10', latitude: 48.668975, longitude: 22.093414 },
  { name: 'IRBAT', latitude: 48.673056, longitude: 37.842778 },
  { name: 'VP013', latitude: 48.675, longitude: 21.170833 },
  { name: 'OLEKA', latitude: 48.679444, longitude: 33.186111 },
  { name: 'VP014', latitude: 48.679722, longitude: 17.563889 },
  { name: 'VP015', latitude: 48.680278, longitude: 21.431389 },
  { name: 'MOMEP', latitude: 48.686944, longitude: 18.571111 },
  { name: 'GONBA', latitude: 48.687778, longitude: 13.075833 },
  { name: 'AKULO', latitude: 48.688333, longitude: 32.741944 },
  { name: 'PINQI', latitude: 48.689167, longitude: 13.982778 },
  { name: 'VABGU', latitude: 48.6923, longitude: 16.737608 },
  { name: 'VESIX', latitude: 48.693333, longitude: 12.311111 },
  { name: 'TEPNI', latitude: 48.696944, longitude: 19.853333 },
  { name: 'D010E', latitude: 48.699722, longitude: 17.855833 },
  { name: 'PPM01', latitude: 48.6998, longitude: 17.855931 },
  { name: 'D009E', latitude: 48.699806, longitude: 17.855944 },
  { name: 'EMGEP', latitude: 48.700244, longitude: 12.090267 },
  { name: 'UMSUM', latitude: 48.700903, longitude: 15.597081 },
  { name: 'KOVEL', latitude: 48.700903, longitude: 15.597083 },
  { name: 'WW181', latitude: 48.701111, longitude: 15.597222 },
  { name: 'MITNU', latitude: 48.701944, longitude: 12.494722 },
  { name: 'OSTES', latitude: 48.706111, longitude: 12.678889 },
  { name: 'UPALI', latitude: 48.708889, longitude: 18.520833 },
  { name: 'ETIPA', latitude: 48.7125, longitude: 17.554444 },
  { name: 'TUTPI', latitude: 48.715833, longitude: 17.386111 },
  { name: 'GUPLU', latitude: 48.718611, longitude: 17.239167 },
  { name: 'POBED', latitude: 48.720556, longitude: 25.457222 },
  { name: '26VOR', latitude: 48.725278, longitude: 21.262944 },
  { name: 'WW472', latitude: 48.725286, longitude: 15.598286 },
  { name: '30TH2', latitude: 48.725286, longitude: 21.262942 },
  { name: 'GIMBO', latitude: 48.725364, longitude: 14.775831 },
  { name: 'LIDNO', latitude: 48.726944, longitude: 29.946944 },
  { name: 'LEDVA', latitude: 48.728789, longitude: 16.789194 },
  { name: 'TIVAP', latitude: 48.729097, longitude: 14.393953 },
  { name: 'AMPUL', latitude: 48.730556, longitude: 38.766667 },
  { name: 'BAMTA', latitude: 48.732222, longitude: 12.8875 },
  { name: 'D007C', latitude: 48.733333, longitude: 21.265833 },
  { name: 'HATIP', latitude: 48.743889, longitude: 21.722222 },
  { name: 'AKINI', latitude: 48.749722, longitude: 12.124167 },
  { name: 'RIMOS', latitude: 48.758333, longitude: 25.98 },
  { name: 'DEXIT', latitude: 48.762778, longitude: 13.709167 },
  { name: 'KUNOL', latitude: 48.762778, longitude: 20.469444 },
  { name: 'KZ19F', latitude: 48.763136, longitude: 21.276219 },
  { name: 'FD19', latitude: 48.763139, longitude: 21.276222 },
  { name: 'KUFAZ', latitude: 48.765278, longitude: 12.526111 },
  { name: 'NAVTI', latitude: 48.769611, longitude: 16.205058 },
  { name: 'MS459', latitude: 48.772031, longitude: 12.822139 },
  { name: 'ULPUK', latitude: 48.776667, longitude: 19.624444 },
  { name: 'D007F', latitude: 48.781861, longitude: 21.282806 },
  { name: 'KZM01', latitude: 48.781872, longitude: 21.282797 },
  { name: 'PEPIK', latitude: 48.782778, longitude: 17.079167 },
  { name: 'KREMI', latitude: 48.783889, longitude: 18.883889 },
  { name: 'MIKOV', latitude: 48.784744, longitude: 16.621003 },
  { name: 'STAUB', latitude: 48.785739, longitude: 12.658047 },
  { name: 'GOMAX', latitude: 48.790278, longitude: 12.348611 },
  { name: 'VP016', latitude: 48.790833, longitude: 17.901389 },
  { name: 'MS092', latitude: 48.794561, longitude: 12.620008 },
  { name: 'UNKOM', latitude: 48.796667, longitude: 12.548333 },
  { name: 'KANTE', latitude: 48.796667, longitude: 32.058333 },
  { name: 'KOLMA', latitude: 48.801389, longitude: 24.906667 },
  { name: 'BUWUT', latitude: 48.805075, longitude: 15.313058 },
  { name: 'NITKO', latitude: 48.806389, longitude: 20.8975 },
  { name: 'D064N', latitude: 48.808422, longitude: 24.354619 },
  { name: 'MAVOR', latitude: 48.821394, longitude: 17.537503 },
  { name: 'MALBE', latitude: 48.823889, longitude: 22.375 },
  { name: 'D031K', latitude: 48.828483, longitude: 21.408289 },
  { name: 'VP017', latitude: 48.8325, longitude: 21.075833 },
  { name: 'SKODA', latitude: 48.834167, longitude: 14.482222 },
  { name: 'LEMPI', latitude: 48.84425, longitude: 21.304739 },
  { name: 'D007J', latitude: 48.84425, longitude: 21.30475 },
  { name: 'UNGUT', latitude: 48.844697, longitude: 15.653289 },
  { name: 'FD28', latitude: 48.849317, longitude: 24.853369 },
  { name: 'ODNEM', latitude: 48.8532, longitude: 17.167844 },
  { name: 'FF28', latitude: 48.855528, longitude: 24.856169 },
  { name: 'MS091', latitude: 48.856861, longitude: 12.667953 },
  { name: 'D093J', latitude: 48.857297, longitude: 24.945778 },
  { name: 'KU605', latitude: 48.861769, longitude: 16.801544 },
  { name: 'MS274', latitude: 48.862114, longitude: 12.326339 },
  { name: 'LALES', latitude: 48.865172, longitude: 17.709514 },
  { name: 'D089J', latitude: 48.869067, longitude: 24.948031 },
  { name: 'NIDOK', latitude: 48.873333, longitude: 18.820833 },
  { name: 'EPEDA', latitude: 48.873611, longitude: 19.957222 },
  { name: 'MS439', latitude: 48.873953, longitude: 12.84185 },
  { name: 'LOKVU', latitude: 48.874717, longitude: 15.834997 },
  { name: 'VP018', latitude: 48.874722, longitude: 21.243333 },
  { name: 'NESLO', latitude: 48.876944, longitude: 37.374722 },
  { name: 'MD28', latitude: 48.878047, longitude: 24.719914 },
  { name: 'LALIN', latitude: 48.88, longitude: 13.242222 },
  { name: 'NEVRU', latitude: 48.88, longitude: 29.305556 },
  { name: 'PENAK', latitude: 48.88, longitude: 36.523333 },
  { name: 'NEGOB', latitude: 48.881303, longitude: 12.754261 },
  { name: 'MS093', latitude: 48.885731, longitude: 12.701094 },
  { name: 'MAMOR', latitude: 48.885833, longitude: 12.222222 },
  { name: 'MS095', latitude: 48.887692, longitude: 12.677417 },
  { name: 'TOMKA', latitude: 48.888056, longitude: 37.612222 },
  { name: 'LANUX', latitude: 48.888106, longitude: 15.615789 },
  { name: 'MARKA', latitude: 48.889444, longitude: 20.677222 },
  { name: 'MD10', latitude: 48.889478, longitude: 24.649892 },
  { name: 'GISID', latitude: 48.89, longitude: 40.326667 },
  { name: 'MS090', latitude: 48.890725, longitude: 12.640697 },
  { name: 'PISAM', latitude: 48.892914, longitude: 15.387128 },
  { name: 'ROBTU', latitude: 48.894167, longitude: 21.815 },
  { name: 'TT565', latitude: 48.894692, longitude: 20.551083 },
  { name: '34VOR', latitude: 48.894772, longitude: 24.608386 },
  { name: 'TT522', latitude: 48.897642, longitude: 20.405158 },
  { name: 'DITIS', latitude: 48.898022, longitude: 15.116361 },
  { name: 'BEPAS', latitude: 48.898333, longitude: 13.509722 },
  { name: 'VAPUS', latitude: 48.898333, longitude: 18.349167 },
  { name: 'LIPSO', latitude: 48.9, longitude: 37.883333 },
  { name: 'D079K', latitude: 48.900344, longitude: 24.978081 },
  { name: 'D086T', latitude: 48.903839, longitude: 24.192189 },
  { name: 'TT523', latitude: 48.90405, longitude: 20.268236 },
  { name: 'TT564', latitude: 48.905742, longitude: 20.268428 },
  { name: 'NETES', latitude: 48.906389, longitude: 12.626389 },
  { name: 'FD10', latitude: 48.907017, longitude: 24.511956 },
  { name: 'TT524', latitude: 48.909925, longitude: 20.142236 },
  { name: 'TT563', latitude: 48.910911, longitude: 20.129678 },
  { name: 'MS491', latitude: 48.911992, longitude: 12.378881 },
  { name: 'MS273', latitude: 48.913322, longitude: 12.310956 },
  { name: 'GOTRA', latitude: 48.913333, longitude: 25.598333 },
  { name: 'D075K', latitude: 48.913497, longitude: 24.975708 },
  { name: 'VP019', latitude: 48.917778, longitude: 20.269722 },
  { name: 'INDUL', latitude: 48.917778, longitude: 40.693333 },
  { name: 'MS094', latitude: 48.918553, longitude: 12.724506 },
  { name: 'KU607', latitude: 48.91935, longitude: 17.500458 },
  { name: 'TONPA', latitude: 48.921667, longitude: 22.119722 },
  { name: 'TT562', latitude: 48.924092, longitude: 20.029506 },
  { name: 'KU702', latitude: 48.930992, longitude: 17.368983 },
  { name: 'KU606', latitude: 48.938131, longitude: 17.210414 },
  { name: 'MS270', latitude: 48.940958, longitude: 12.360453 },
  { name: 'MS492', latitude: 48.940961, longitude: 12.364481 },
  { name: 'D284J', latitude: 48.942039, longitude: 24.44895 },
  { name: 'MS271', latitude: 48.945939, longitude: 12.34165 },
  { name: 'BUDEX', latitude: 48.948328, longitude: 14.336028 },
  { name: 'OTRAK', latitude: 48.948889, longitude: 26.686111 },
  { name: 'DEKAD', latitude: 48.95, longitude: 33.366667 },
  { name: 'LADOB', latitude: 48.950278, longitude: 22.448611 },
  { name: 'INTOZ', latitude: 48.950833, longitude: 32.085556 },
  { name: 'TT691', latitude: 48.952725, longitude: 20.149108 },
  { name: 'TT617', latitude: 48.953692, longitude: 20.610744 },
  { name: 'DUFES', latitude: 48.956389, longitude: 21.318889 },
  { name: 'KU701', latitude: 48.961092, longitude: 17.390625 },
  { name: 'TT621', latitude: 48.961786, longitude: 19.948728 },
  { name: 'LITLI', latitude: 48.964444, longitude: 12.863889 },
  { name: 'BABNI', latitude: 48.964722, longitude: 18.253333 },
  { name: 'NIROV', latitude: 48.966389, longitude: 28.849167 },
  { name: 'TAKET', latitude: 48.97, longitude: 27.841667 },
  { name: 'TT681', latitude: 48.973017, longitude: 20.590697 },
  { name: 'VP001', latitude: 48.974167, longitude: 17.268056 },
  { name: 'KOJOT', latitude: 48.975, longitude: 21.1425 },
  { name: 'MS272', latitude: 48.9753, longitude: 12.356169 },
  { name: 'TT616', latitude: 48.97545, longitude: 20.542444 },
  { name: 'TT682', latitude: 48.979308, longitude: 20.464569 },
  { name: 'TEKLA', latitude: 48.9794, longitude: 18.745319 },
  { name: 'KEDFO', latitude: 48.98, longitude: 29.882222 },
  { name: 'TB400', latitude: 48.980314, longitude: 17.301253 },
  { name: 'TT683', latitude: 48.985331, longitude: 20.341164 },
  { name: 'TT615', latitude: 48.987783, longitude: 20.290128 },
  { name: 'KU703', latitude: 48.989575, longitude: 17.299153 },
  { name: 'D298K', latitude: 48.990017, longitude: 24.452372 },
  { name: 'TT684', latitude: 48.990158, longitude: 20.240211 },
  { name: 'KU881', latitude: 48.992392, longitude: 17.413161 },
  { name: 'TT571', latitude: 48.995261, longitude: 20.131419 },
  { name: 'NIKAD', latitude: 48.996111, longitude: 35.921944 },
  { name: 'VP002', latitude: 48.996389, longitude: 16.688889 },
  { name: 'VP020', latitude: 48.996667, longitude: 19.502778 },
  { name: 'VP003', latitude: 48.997778, longitude: 16.862222 },
  { name: 'TT614', latitude: 48.998411, longitude: 20.062933 },
  { name: 'TT521', latitude: 48.998653, longitude: 20.416536 },
  { name: '4913E', latitude: 49.0, longitude: 13.0 },
  { name: '4914E', latitude: 49.0, longitude: 14.0 },
  { name: '4915E', latitude: 49.0, longitude: 15.0 },
  { name: '4916E', latitude: 49.0, longitude: 16.0 },
  { name: '4917E', latitude: 49.0, longitude: 17.0 },
  { name: '4918E', latitude: 49.0, longitude: 18.0 },
  { name: '4919E', latitude: 49.0, longitude: 19.0 },
  { name: '4920E', latitude: 49.0, longitude: 20.0 },
  { name: '4921E', latitude: 49.0, longitude: 21.0 },
  { name: '4922E', latitude: 49.0, longitude: 22.0 },
  { name: '4923E', latitude: 49.0, longitude: 23.0 },
  { name: '4924E', latitude: 49.0, longitude: 24.0 },
  { name: '4925E', latitude: 49.0, longitude: 25.0 },
  { name: '4926E', latitude: 49.0, longitude: 26.0 },
  { name: '4927E', latitude: 49.0, longitude: 27.0 },
  { name: '4928E', latitude: 49.0, longitude: 28.0 },
  { name: '4929E', latitude: 49.0, longitude: 29.0 },
  { name: '4930E', latitude: 49.0, longitude: 30.0 },
  { name: '4931E', latitude: 49.0, longitude: 31.0 },
  { name: '4932E', latitude: 49.0, longitude: 32.0 },
  { name: '4933E', latitude: 49.0, longitude: 33.0 },
  { name: '4934E', latitude: 49.0, longitude: 34.0 },
  { name: '4935E', latitude: 49.0, longitude: 35.0 },
  { name: '4936E', latitude: 49.0, longitude: 36.0 },
  { name: '4937E', latitude: 49.0, longitude: 37.0 },
  { name: '4938E', latitude: 49.0, longitude: 38.0 },
  { name: '4939E', latitude: 49.0, longitude: 39.0 },
  { name: '4940E', latitude: 49.0, longitude: 40.0 },
  { name: 'TT685', latitude: 49.000125, longitude: 20.025631 },
  { name: 'VP021', latitude: 49.001667, longitude: 20.4675 },
  { name: 'D101M', latitude: 49.003406, longitude: 20.652689 },
  { name: 'TT613', latitude: 49.00525, longitude: 19.911422 },
  { name: 'TT561', latitude: 49.005889, longitude: 19.897347 },
  { name: 'LUPEV', latitude: 49.009728, longitude: 14.560008 },
  { name: 'UDREL', latitude: 49.010278, longitude: 20.836111 },
  { name: 'KU608', latitude: 49.010711, longitude: 17.566525 },
  { name: 'TT612', latitude: 49.0108, longitude: 19.785131 },
  { name: 'BAVRI', latitude: 49.015675, longitude: 13.512797 },
  { name: 'TT671', latitude: 49.019292, longitude: 20.790306 },
  { name: 'TT541', latitude: 49.019672, longitude: 20.79035 },
  { name: 'KATQA', latitude: 49.02, longitude: 17.241944 },
  { name: 'VP004', latitude: 49.023056, longitude: 17.640833 },
  { name: 'TOVPU', latitude: 49.023056, longitude: 34.900556 },
  { name: 'VALPI', latitude: 49.024639, longitude: 17.950231 },
  { name: 'TB503', latitude: 49.029758, longitude: 17.118411 },
  { name: 'ZI616', latitude: 49.031614, longitude: 18.659994 },
  { name: 'UMAN', latitude: 49.0325, longitude: 30.046389 },
  { name: 'VP005', latitude: 49.033056, longitude: 16.1075 },
  { name: 'UPEGU', latitude: 49.034914, longitude: 14.476483 },
  { name: 'MIMKO', latitude: 49.040833, longitude: 31.209444 },
  { name: 'TB410', latitude: 49.041033, longitude: 16.992969 },
  { name: 'TB502', latitude: 49.041033, longitude: 16.992969 },
  { name: 'D094H', latitude: 49.042222, longitude: 20.553056 },
  { name: 'FF36', latitude: 49.044419, longitude: 33.475719 },
  { name: 'NA30I', latitude: 49.047778, longitude: 16.348611 },
  { name: 'OKRIK', latitude: 49.048889, longitude: 27.209444 },
  { name: 'URFAN', latitude: 49.049444, longitude: 27.344167 },
  { name: 'VADIM', latitude: 49.051944, longitude: 22.989167 },
  { name: 'VP022', latitude: 49.055278, longitude: 19.581944 },
  { name: 'TT513', latitude: 49.055389, longitude: 20.613528 },
  { name: 'TB205', latitude: 49.055742, longitude: 16.69105 },
  { name: 'GOROL', latitude: 49.056039, longitude: 20.600375 },
  { name: 'TB411', latitude: 49.056372, longitude: 16.819736 },
  { name: 'TB501', latitude: 49.056372, longitude: 16.819736 },
  { name: 'TTM09', latitude: 49.056586, longitude: 20.589769 },
  { name: 'ZI615', latitude: 49.057678, longitude: 18.617294 },
  { name: 'TT512', latitude: 49.059192, longitude: 20.537731 },
  { name: 'D090D', latitude: 49.059833, longitude: 20.451111 },
  { name: 'VP025', latitude: 49.06, longitude: 19.889444 },
  { name: 'VP023', latitude: 49.062222, longitude: 18.323056 },
  { name: 'ABRAG', latitude: 49.062342, longitude: 20.474036 },
  { name: 'TT27F', latitude: 49.062342, longitude: 20.474036 },
  { name: 'TT511', latitude: 49.064806, longitude: 20.424011 },
  { name: 'VP024', latitude: 49.065278, longitude: 19.435833 },
  { name: 'D165J', latitude: 49.067103, longitude: 18.653447 },
  { name: 'TT641', latitude: 49.067167, longitude: 20.083697 },
  { name: '20THR', latitude: 49.067572, longitude: 17.467589 },
  { name: '40THR', latitude: 49.067911, longitude: 20.360003 },
  { name: 'KU751', latitude: 49.069067, longitude: 17.580775 },
  { name: '10LOC', latitude: 49.069556, longitude: 20.32575 },
  { name: 'NA30A', latitude: 49.071111, longitude: 16.373056 },
  { name: 'MA27', latitude: 49.071389, longitude: 20.287472 },
  { name: 'LOPNU', latitude: 49.074167, longitude: 26.681389 },
  { name: 'TB432', latitude: 49.074372, longitude: 16.612431 },
  { name: 'TT686', latitude: 49.074656, longitude: 19.728017 },
  { name: 'ODUDA', latitude: 49.074722, longitude: 12.747222 },
  { name: 'KU609', latitude: 49.075211, longitude: 17.613328 },
  { name: 'ZI611', latitude: 49.078028, longitude: 18.410614 },
  { name: '31THR', latitude: 49.078097, longitude: 20.145008 },
  { name: 'SVITA', latitude: 49.078333, longitude: 23.185 },
  { name: 'D268J', latitude: 49.078611, longitude: 20.110139 },
  { name: 'NA30F', latitude: 49.078889, longitude: 16.291944 },
  { name: 'TTM27', latitude: 49.079711, longitude: 20.110297 },
  { name: 'D270J', latitude: 49.079722, longitude: 20.110306 },
  { name: 'KU601', latitude: 49.082139, longitude: 17.337642 },
  { name: 'VP006', latitude: 49.083056, longitude: 16.435556 },
  { name: 'TT09F', latitude: 49.084164, longitude: 20.012742 },
  { name: 'TB412', latitude: 49.085097, longitude: 16.486753 },
  { name: 'NA502', latitude: 49.086378, longitude: 15.98785 },
  { name: 'TT551', latitude: 49.088967, longitude: 19.905894 },
  { name: 'BREST', latitude: 49.089822, longitude: 19.886264 },
  { name: 'NA30C', latitude: 49.09, longitude: 16.387222 },
  { name: 'RAJEC', latitude: 49.09, longitude: 18.636944 },
  { name: 'GIVIP', latitude: 49.091081, longitude: 16.516578 },
  { name: 'D350I', latitude: 49.09275, longitude: 28.676231 },
  { name: 'NA30H', latitude: 49.093056, longitude: 16.305556 },
  { name: 'TB413', latitude: 49.093581, longitude: 16.386169 },
  { name: 'TT611', latitude: 49.093883, longitude: 19.793475 },
  { name: 'TT631', latitude: 49.096611, longitude: 19.730211 },
  { name: 'TT552', latitude: 49.096631, longitude: 19.730214 },
  { name: 'KU750', latitude: 49.0977, longitude: 17.489211 },
  { name: 'LINDU', latitude: 49.099444, longitude: 31.830556 },
  { name: 'UMARY', latitude: 49.101111, longitude: 19.620833 },
  { name: 'D073M', latitude: 49.103967, longitude: 20.661328 },
  { name: 'VP026', latitude: 49.105278, longitude: 19.5125 },
  { name: 'VP007', latitude: 49.106389, longitude: 17.315278 },
  { name: 'KOPAT', latitude: 49.106389, longitude: 18.654444 },
  { name: 'USEMI', latitude: 49.108611, longitude: 12.968611 },
  { name: 'VP008', latitude: 49.110833, longitude: 16.520833 },
  { name: 'D327I', latitude: 49.111103, longitude: 28.737844 },
  { name: 'TB514', latitude: 49.112222, longitude: 17.135689 },
  { name: 'KU20F', latitude: 49.113067, longitude: 17.500772 },
  { name: 'ZI612', latitude: 49.113797, longitude: 18.525119 },
  { name: 'VP009', latitude: 49.1175, longitude: 16.703333 },
  { name: 'RAZEC', latitude: 49.118056, longitude: 21.090278 },
  { name: 'DOLUP', latitude: 49.119197, longitude: 13.394519 },
  { name: 'ILTUK', latitude: 49.12, longitude: 25.501667 },
  { name: 'ZI621', latitude: 49.121964, longitude: 18.256517 },
  { name: 'BARIX', latitude: 49.1225, longitude: 13.176389 },
  { name: 'BUKAP', latitude: 49.123517, longitude: 17.010047 },
  { name: 'KU752', latitude: 49.126261, longitude: 17.397542 },
  { name: 'ROMIS', latitude: 49.127114, longitude: 18.11095 },
  { name: 'CI27', latitude: 49.130258, longitude: 16.934436 },
  { name: 'TT581', latitude: 49.131597, longitude: 20.146083 },
  { name: 'CF31', latitude: 49.133583, longitude: 28.763486 },
  { name: 'TT642', latitude: 49.13415, longitude: 20.090831 },
  { name: 'TB27F', latitude: 49.134697, longitude: 16.884106 },
  { name: 'FF27', latitude: 49.134703, longitude: 16.884136 },
  { name: 'VP027', latitude: 49.135278, longitude: 20.432222 },
  { name: 'TT516', latitude: 49.136389, longitude: 19.993661 },
  { name: 'LUSIG', latitude: 49.136667, longitude: 37.953333 },
  { name: 'FD27', latitude: 49.138872, longitude: 16.884742 },
  { name: 'VP010', latitude: 49.139722, longitude: 16.0625 },
  { name: 'TT619', latitude: 49.141325, longitude: 20.227014 },
  { name: 'GAWOR', latitude: 49.142222, longitude: 22.281389 },
  { name: 'TB103', latitude: 49.143644, longitude: 16.782133 },
  { name: 'TT572', latitude: 49.144147, longitude: 20.330472 },
  { name: '25THR', latitude: 49.144283, longitude: 16.774808 },
  { name: '20LOC', latitude: 49.145744, longitude: 16.758089 },
  { name: '25DME', latitude: 49.145792, longitude: 16.755644 },
  { name: '25VOR', latitude: 49.146417, longitude: 16.7558 },
  { name: 'TB101', latitude: 49.146478, longitude: 16.749639 },
  { name: 'MD27', latitude: 49.147881, longitude: 16.730514 },
  { name: 'MM27', latitude: 49.148564, longitude: 16.726147 },
  { name: 'KOROP', latitude: 49.15, longitude: 27.845 },
  { name: 'KU610', latitude: 49.150342, longitude: 17.668017 },
  { name: 'LIKSA', latitude: 49.152767, longitude: 16.014758 },
  { name: 'SAGAN', latitude: 49.153181, longitude: 18.355997 },
  { name: 'MOCON', latitude: 49.153611, longitude: 19.215278 },
  { name: 'MD09', latitude: 49.154506, longitude: 16.657686 },
  { name: 'TT618', latitude: 49.154822, longitude: 20.740772 },
  { name: 'TT514', latitude: 49.155011, longitude: 20.6252 },
  { name: 'TB200', latitude: 49.156378, longitude: 16.635217 },
  { name: 'TT651', latitude: 49.157325, longitude: 20.612225 },
  { name: '24THR', latitude: 49.158069, longitude: 16.61545 },
  { name: 'FF31', latitude: 49.158339, longitude: 28.729522 },
  { name: '35DME', latitude: 49.159625, longitude: 16.604919 },
  { name: 'D241G', latitude: 49.160117, longitude: 28.501872 },
  { name: 'TT661', latitude: 49.161219, longitude: 20.730997 },
  { name: '37VOR', latitude: 49.161783, longitude: 16.600353 },
  { name: 'TT652', latitude: 49.163639, longitude: 20.485631 },
  { name: 'TB471', latitude: 49.163667, longitude: 16.550011 },
  { name: 'LANKU', latitude: 49.164167, longitude: 26.357778 },
  { name: 'TB202', latitude: 49.166344, longitude: 16.518672 },
  { name: 'ZI622', latitude: 49.167014, longitude: 18.2236 },
  { name: 'TB09F', latitude: 49.167661, longitude: 16.503025 },
  { name: 'TT515', latitude: 49.168669, longitude: 20.348233 },
  { name: 'FQ09', latitude: 49.168747, longitude: 16.504283 },
  { name: 'VP012', latitude: 49.17, longitude: 15.925 },
  { name: 'VP011', latitude: 49.170278, longitude: 17.4725 },
  { name: 'ZI613', latitude: 49.171, longitude: 18.709336 },
  { name: 'RUTKI', latitude: 49.173297, longitude: 18.940092 },
  { name: 'FD09', latitude: 49.173742, longitude: 16.505586 },
  { name: 'TT653', latitude: 49.174033, longitude: 20.270844 },
  { name: 'ZI06F', latitude: 49.174689, longitude: 18.424758 },
  { name: 'FI06', latitude: 49.174694, longitude: 18.42475 },
  { name: 'NA501', latitude: 49.175947, longitude: 16.355006 },
  { name: 'ROGAD', latitude: 49.176161, longitude: 16.402261 },
  { name: 'FQ06', latitude: 49.177028, longitude: 18.423083 },
  { name: 'VP013', latitude: 49.181667, longitude: 16.7125 },
  { name: 'NAPAG', latitude: 49.186208, longitude: 17.553422 },
  { name: 'D258L', latitude: 49.186567, longitude: 28.369397 },
  { name: 'D111F', latitude: 49.186628, longitude: 18.749283 },
  { name: 'VP028', latitude: 49.187222, longitude: 18.862778 },
  { name: 'TT531', latitude: 49.187783, longitude: 20.690381 },
  { name: 'ZI515', latitude: 49.188636, longitude: 18.852147 },
  { name: 'D231J', latitude: 49.192028, longitude: 28.36155 },
  { name: 'VP014', latitude: 49.193889, longitude: 16.22 },
  { name: 'RUBOS', latitude: 49.194167, longitude: 30.684722 },
  { name: 'TB512', latitude: 49.194678, longitude: 17.153028 },
  { name: 'ZI623', latitude: 49.198261, longitude: 18.323147 },
  { name: '40THR', latitude: 49.202689, longitude: 18.514567 },
  { name: 'VP015', latitude: 49.203611, longitude: 16.886111 },
  { name: 'ZNA09', latitude: 49.204444, longitude: 18.385 },
  { name: 'LUGOL', latitude: 49.205556, longitude: 22.738056 },
  { name: 'TB401', latitude: 49.205997, longitude: 17.027183 },
  { name: 'TB511', latitude: 49.205997, longitude: 17.027183 },
  { name: 'TB204', latitude: 49.210233, longitude: 16.979642 },
  { name: 'ZI614', latitude: 49.210283, longitude: 18.836633 },
  { name: 'ARBAX', latitude: 49.211111, longitude: 13.043056 },
  { name: 'TB520', latitude: 49.2172, longitude: 16.901042 },
  { name: 'VP070', latitude: 49.2175, longitude: 12.024167 },
  { name: 'ZI531', latitude: 49.219006, longitude: 18.505658 },
  { name: 'LOLKA', latitude: 49.22, longitude: 20.1 },
  { name: 'VP016', latitude: 49.222222, longitude: 16.293056 },
  { name: 'EXUSI', latitude: 49.222778, longitude: 12.657222 },
  { name: 'BAGMI', latitude: 49.226667, longitude: 12.857222 },
  { name: 'MM06', latitude: 49.227667, longitude: 18.594694 },
  { name: 'ZI712', latitude: 49.228308, longitude: 18.301214 },
  { name: 'TB419', latitude: 49.229086, longitude: 16.355272 },
  { name: 'TB402', latitude: 49.229181, longitude: 16.764333 },
  { name: 'TB510', latitude: 49.229181, longitude: 16.764333 },
  { name: 'ESETO', latitude: 49.229944, longitude: 17.718278 },
  { name: 'D249K', latitude: 49.232306, longitude: 28.305783 },
  { name: 'REDSI', latitude: 49.233333, longitude: 19.639444 },
  { name: 'LIPTY', latitude: 49.235556, longitude: 19.331667 },
  { name: 'TB472', latitude: 49.235931, longitude: 16.439122 },
  { name: 'NA12C', latitude: 49.237778, longitude: 15.861667 },
  { name: 'NA12H', latitude: 49.238889, longitude: 15.945556 },
  { name: 'TB403', latitude: 49.239256, longitude: 16.647853 },
  { name: 'D081G', latitude: 49.239806, longitude: 18.797839 },
  { name: 'RUDAP', latitude: 49.241258, longitude: 13.265586 },
  { name: 'TABEM', latitude: 49.243314, longitude: 15.221472 },
  { name: 'ZI514', latitude: 49.2441, longitude: 18.812367 },
  { name: 'MAKAL', latitude: 49.246008, longitude: 18.165717 },
  { name: 'TB203', latitude: 49.248903, longitude: 16.535022 },
  { name: 'VADEX', latitude: 49.250836, longitude: 18.884811 },
  { name: 'NA12F', latitude: 49.252222, longitude: 15.961667 },
  { name: 'TT573', latitude: 49.258889, longitude: 20.485011 },
  { name: 'VP029', latitude: 49.259167, longitude: 18.16 },
  { name: 'BAGSA', latitude: 49.261944, longitude: 26.000833 },
  { name: 'NA12A', latitude: 49.262222, longitude: 15.879167 },
  { name: 'ZI511', latitude: 49.264336, longitude: 18.713447 },
  { name: 'PIGUM', latitude: 49.264722, longitude: 24.011667 },
  { name: 'TB404', latitude: 49.264931, longitude: 16.522231 },
  { name: 'NA12G', latitude: 49.267778, longitude: 15.977778 },
  { name: 'GAMLA', latitude: 49.27, longitude: 30.422778 },
  { name: 'ZI532', latitude: 49.278633, longitude: 18.462358 },
  { name: 'D061G', latitude: 49.279583, longitude: 18.772 },
  { name: 'NELPA', latitude: 49.281406, longitude: 14.956169 },
  { name: 'D060G', latitude: 49.281889, longitude: 18.770333 },
  { name: 'PEMIR', latitude: 49.282222, longitude: 25.555 },
  { name: 'ZI624', latitude: 49.283256, longitude: 18.37705 },
  { name: 'NA12I', latitude: 49.284167, longitude: 15.899444 },
  { name: 'TB405', latitude: 49.284983, longitude: 16.42355 },
  { name: 'ORLAN', latitude: 49.286389, longitude: 20.887222 },
  { name: 'D039G', latitude: 49.287569, longitude: 28.786233 },
  { name: 'ZIM06', latitude: 49.288094, longitude: 18.790517 },
  { name: 'TB102', latitude: 49.288711, longitude: 16.657778 },
  { name: 'LUZKA', latitude: 49.291389, longitude: 36.298611 },
  { name: 'VP017', latitude: 49.292222, longitude: 16.560278 },
  { name: 'ZI541', latitude: 49.296272, longitude: 18.326 },
  { name: 'SOLNU', latitude: 49.296667, longitude: 25.398333 },
  { name: 'D094J', latitude: 49.297325, longitude: 28.392931 },
  { name: 'LABOD', latitude: 49.297778, longitude: 30.324167 },
  { name: 'VAKLA', latitude: 49.300456, longitude: 14.734169 },
  { name: 'VP018', latitude: 49.303056, longitude: 16.061944 },
  { name: 'SUPAK', latitude: 49.303611, longitude: 19.799722 },
  { name: 'BITSI', latitude: 49.308278, longitude: 16.236314 },
  { name: 'ORTYN', latitude: 49.310278, longitude: 19.232222 },
  { name: 'PEKIT', latitude: 49.313333, longitude: 33.616667 },
  { name: 'KELEL', latitude: 49.313611, longitude: 20.175278 },
  { name: 'ZI521', latitude: 49.318261, longitude: 18.589992 },
  { name: 'RAVTO', latitude: 49.318889, longitude: 36.048611 },
  { name: 'UPLAV', latitude: 49.322853, longitude: 17.528397 },
  { name: 'SOVOK', latitude: 49.324444, longitude: 31.438889 },
  { name: 'TB418', latitude: 49.324619, longitude: 16.172025 },
  { name: 'FF13', latitude: 49.326342, longitude: 28.497631 },
  { name: 'NA12R', latitude: 49.326667, longitude: 15.951111 },
  { name: 'ZI533', latitude: 49.327686, longitude: 18.455939 },
  { name: 'NIRGO', latitude: 49.330944, longitude: 13.132219 },
  { name: 'EDUGO', latitude: 49.333333, longitude: 38.75 },
  { name: 'RUDNO', latitude: 49.335833, longitude: 12.541397 },
  { name: 'LENOV', latitude: 49.336389, longitude: 21.010278 },
  { name: 'D118J', latitude: 49.336969, longitude: 28.429961 },
  { name: 'VP030', latitude: 49.338611, longitude: 18.816111 },
  { name: 'ZI512', latitude: 49.339628, longitude: 18.659083 },
  { name: 'D050G', latitude: 49.341447, longitude: 28.708389 },
  { name: 'FAVAR', latitude: 49.3425, longitude: 35.835278 },
  { name: 'KUSAK', latitude: 49.345, longitude: 24.501667 },
  { name: 'KEFIR', latitude: 49.351944, longitude: 21.923611 },
  { name: 'TEPLO', latitude: 49.351944, longitude: 31.315556 },
  { name: 'PO06X', latitude: 49.354644, longitude: 17.160081 },
  { name: 'ZI522', latitude: 49.356147, longitude: 18.518283 },
  { name: 'BEVRO', latitude: 49.356944, longitude: 34.593611 },
  { name: 'ZI513', latitude: 49.360953, longitude: 18.728236 },
  { name: 'PEVUK', latitude: 49.361667, longitude: 26.935 },
  { name: 'ZI711', latitude: 49.363422, longitude: 18.736242 },
  { name: 'LULAR', latitude: 49.365833, longitude: 12.758889 },
  { name: 'MAKOV', latitude: 49.37, longitude: 18.476111 },
  { name: 'PO06F', latitude: 49.373661, longitude: 17.225125 },
  { name: 'LECAD', latitude: 49.376111, longitude: 35.1075 },
  { name: 'GOTIL', latitude: 49.376667, longitude: 40.705278 },
  { name: 'TB106', latitude: 49.377156, longitude: 16.221417 },
  { name: 'ODUKO', latitude: 49.385189, longitude: 15.878939 },
  { name: 'ROKOX', latitude: 49.388056, longitude: 35.448333 },
  { name: 'NALAD', latitude: 49.388333, longitude: 26.451667 },
  { name: 'SITBA', latitude: 49.390833, longitude: 27.846944 },
  { name: 'BILNA', latitude: 49.393553, longitude: 18.4473 },
  { name: 'VP091', latitude: 49.396944, longitude: 12.003611 },
  { name: 'REGTO', latitude: 49.403611, longitude: 20.664722 },
  { name: 'TABAV', latitude: 49.404722, longitude: 31.076944 },
  { name: 'TUMKA', latitude: 49.407894, longitude: 15.947267 },
  { name: 'PODAN', latitude: 49.412778, longitude: 21.439167 },
  { name: 'NIKIG', latitude: 49.416667, longitude: 31.996667 },
  { name: 'VESUB', latitude: 49.419258, longitude: 12.997417 },
  { name: 'IPDUL', latitude: 49.420892, longitude: 13.820325 },
  { name: 'TIVDA', latitude: 49.435983, longitude: 12.656547 },
  { name: 'SKARY', latitude: 49.444722, longitude: 18.976667 },
  { name: 'MEBAN', latitude: 49.455, longitude: 19.646667 },
  { name: 'GOROS', latitude: 49.456667, longitude: 29.758333 },
  { name: 'BODAL', latitude: 49.464108, longitude: 15.782703 },
  { name: 'MT716', latitude: 49.4722, longitude: 17.972858 },
  { name: 'MT523', latitude: 49.479936, longitude: 18.152531 },
  { name: 'IPRUX', latitude: 49.495378, longitude: 13.223319 },
  { name: 'REVMA', latitude: 49.497222, longitude: 18.700278 },
  { name: 'LABUK', latitude: 49.498525, longitude: 17.410789 },
  { name: 'MYROD', latitude: 49.5, longitude: 33.5 },
  { name: 'TEPNA', latitude: 49.504722, longitude: 22.656111 },
  { name: 'POLOM', latitude: 49.510419, longitude: 17.817411 },
  { name: 'BETGI', latitude: 49.5116, longitude: 12.072828 },
  { name: 'MT522', latitude: 49.5131, longitude: 18.038414 },
  { name: 'USUPA', latitude: 49.520544, longitude: 15.128769 },
  { name: 'TERBU', latitude: 49.525, longitude: 25.7 },
  { name: 'KOKUP', latitude: 49.528333, longitude: 23.655 },
  { name: 'MORUV', latitude: 49.529744, longitude: 18.065142 },
  { name: 'KANON', latitude: 49.533056, longitude: 40.465 },
  { name: 'MT718', latitude: 49.535942, longitude: 17.878914 },
  { name: 'TOVNI', latitude: 49.5375, longitude: 24.669167 },
  { name: 'VP019', latitude: 49.538889, longitude: 18.023611 },
  { name: 'DEMER', latitude: 49.54, longitude: 31.613611 },
  { name: 'MT604', latitude: 49.544236, longitude: 17.866667 },
  { name: 'MT04I', latitude: 49.546775, longitude: 17.870725 },
  { name: 'LL105', latitude: 49.547, longitude: 24.171528 },
  { name: 'POGER', latitude: 49.55, longitude: 29.183333 },
  { name: 'MT714', latitude: 49.553061, longitude: 18.270444 },
  { name: 'D140J', latitude: 49.557553, longitude: 12.106131 },
  { name: 'SULUM', latitude: 49.557778, longitude: 30.6925 },
  { name: 'FF27', latitude: 49.560367, longitude: 34.524811 },
  { name: 'AGNAV', latitude: 49.567917, longitude: 12.614503 },
  { name: 'PALTA', latitude: 49.57, longitude: 34.395 },
  { name: 'PEMEL', latitude: 49.570333, longitude: 13.344825 },
  { name: 'ERKEB', latitude: 49.573333, longitude: 20.806944 },
  { name: 'FF09', latitude: 49.578325, longitude: 34.263189 },
  { name: 'LINKU', latitude: 49.579256, longitude: 12.193067 },
  { name: 'OGMAM', latitude: 49.582167, longitude: 12.176167 },
  { name: 'LL116', latitude: 49.584222, longitude: 23.936306 },
  { name: 'VP020', latitude: 49.585, longitude: 17.799722 },
  { name: 'MT521', latitude: 49.585194, longitude: 17.932231 },
  { name: 'KK624', latitude: 49.586667, longitude: 20.220194 },
  { name: 'KT874', latitude: 49.588167, longitude: 20.559167 },
  { name: 'FD04', latitude: 49.592217, longitude: 17.963381 },
  { name: 'D227J', latitude: 49.594797, longitude: 17.907208 },
  { name: 'LL106', latitude: 49.594889, longitude: 24.100167 },
  { name: 'FQ04', latitude: 49.597822, longitude: 17.954303 },
  { name: 'MT04G', latitude: 49.598244, longitude: 17.95315 },
  { name: 'KK844', latitude: 49.600083, longitude: 20.393611 },
  { name: 'IC204', latitude: 49.6023, longitude: 12.213839 },
  { name: 'MT603', latitude: 49.604194, longitude: 17.777931 },
  { name: 'VP001', latitude: 49.605, longitude: 21.806944 },
  { name: 'LL104', latitude: 49.605278, longitude: 24.264167 },
  { name: 'GORKU', latitude: 49.605278, longitude: 25.072222 },
  { name: 'IC201', latitude: 49.606492, longitude: 12.216967 },
  { name: 'BABKO', latitude: 49.611667, longitude: 19.469444 },
  { name: 'MT715', latitude: 49.614594, longitude: 18.201839 },
  { name: 'PR627', latitude: 49.614808, longitude: 14.673931 },
  { name: 'MT22R', latitude: 49.619608, longitude: 17.985583 },
  { name: 'MT713', latitude: 49.624947, longitude: 18.3869 },
  { name: 'ABSON', latitude: 49.630556, longitude: 23.756111 },
  { name: 'LL109', latitude: 49.632, longitude: 23.864611 },
  { name: 'BAKOK', latitude: 49.633889, longitude: 29.898889 },
  { name: 'D243J', latitude: 49.634869, longitude: 17.871361 },
  { name: 'D151K', latitude: 49.641764, longitude: 24.062592 },
  { name: 'ARKOK', latitude: 49.6425, longitude: 40.713056 },
  { name: 'LL107', latitude: 49.642722, longitude: 24.028667 },
  { name: '40VOR', latitude: 49.647972, longitude: 18.040433 },
  { name: 'APRAQ', latitude: 49.648333, longitude: 14.1225 },
  { name: '40DME', latitude: 49.649953, longitude: 18.037208 },
  { name: 'VP021', latitude: 49.65, longitude: 18.141111 },
  { name: 'VESEL', latitude: 49.65, longitude: 29.695278 },
  { name: 'TIMOS', latitude: 49.650556, longitude: 31.706944 },
  { name: '30THR', latitude: 49.650789, longitude: 18.037597 },
  { name: 'IC200', latitude: 49.652497, longitude: 12.066142 },
  { name: 'ETVIS', latitude: 49.653828, longitude: 12.817447 },
  { name: 'KK623', latitude: 49.653944, longitude: 20.054917 },
  { name: 'EMKUX', latitude: 49.654167, longitude: 19.690556 },
  { name: 'MT511', latitude: 49.654272, longitude: 18.043206 },
  { name: 'D150J', latitude: 49.658997, longitude: 24.054292 },
  { name: 'ODRAN', latitude: 49.661894, longitude: 17.870247 },
  { name: 'IC205', latitude: 49.663086, longitude: 12.031297 },
  { name: 'VP022', latitude: 49.663333, longitude: 18.320278 },
  { name: 'ROSUD', latitude: 49.663333, longitude: 29.396944 },
  { name: 'LL110', latitude: 49.663444, longitude: 24.357056 },
  { name: 'LL103', latitude: 49.665389, longitude: 24.174444 },
  { name: 'VP002', latitude: 49.665556, longitude: 21.610833 },
  { name: 'PR404', latitude: 49.667494, longitude: 14.544947 },
  { name: 'VP003', latitude: 49.670278, longitude: 21.889167 },
  { name: 'D244Y', latitude: 49.672025, longitude: 23.351856 },
  { name: 'MT709', latitude: 49.672142, longitude: 18.603347 },
  { name: 'ABUGA', latitude: 49.675, longitude: 36.83 },
  { name: 'UMOGA', latitude: 49.675278, longitude: 29.075278 },
  { name: 'MT597', latitude: 49.675483, longitude: 18.077431 },
  { name: 'MD04', latitude: 49.67645, longitude: 18.079886 },
  { name: 'LL403', latitude: 49.677361, longitude: 23.976694 },
  { name: 'HHS10', latitude: 49.678722, longitude: 36.070806 },
  { name: 'SUKAV', latitude: 49.679286, longitude: 15.569581 },
  { name: 'PIMEK', latitude: 49.684919, longitude: 15.747786 },
  { name: 'BABSU', latitude: 49.686111, longitude: 28.774722 },
  { name: 'ORTEX', latitude: 49.689444, longitude: 35.000833 },
  { name: 'LL108', latitude: 49.6905, longitude: 23.957028 },
  { name: 'KK843', latitude: 49.691444, longitude: 20.2665 },
  { name: 'PR808', latitude: 49.692331, longitude: 14.621725 },
  { name: 'NETIR', latitude: 49.694664, longitude: 18.708997 },
  { name: 'ENITA', latitude: 49.702136, longitude: 12.473886 },
  { name: 'OGDAS', latitude: 49.702242, longitude: 17.9769 },
  { name: 'OTKUS', latitude: 49.704167, longitude: 28.228889 },
  { name: 'VP023', latitude: 49.704722, longitude: 18.051111 },
  { name: 'CI31', latitude: 49.707564, longitude: 24.111789 },
  { name: 'AKOSI', latitude: 49.707778, longitude: 12.086111 },
  { name: 'VP126', latitude: 49.708056, longitude: 12.038611 },
  { name: 'LL111', latitude: 49.711444, longitude: 24.285694 },
  { name: 'KT873', latitude: 49.711694, longitude: 20.488056 },
  { name: 'PR412', latitude: 49.715206, longitude: 14.723361 },
  { name: 'EDIMI', latitude: 49.715556, longitude: 31.760833 },
  { name: 'MT708', latitude: 49.715953, longitude: 18.535125 },
  { name: 'MD22', latitude: 49.716056, longitude: 18.142022 },
  { name: 'MT712', latitude: 49.716164, longitude: 18.362947 },
  { name: 'MQ22', latitude: 49.7167, longitude: 18.142758 },
  { name: 'D142M', latitude: 49.718467, longitude: 15.625717 },
  { name: 'ARMUT', latitude: 49.7225, longitude: 12.323333 },
  { name: 'EKMIT', latitude: 49.723233, longitude: 18.377953 },
  { name: 'KK628', latitude: 49.724472, longitude: 19.518333 },
  { name: 'MT805', latitude: 49.726783, longitude: 18.160225 },
  { name: 'LL102', latitude: 49.731389, longitude: 24.075833 },
  { name: 'PR903', latitude: 49.731894, longitude: 14.801433 },
  { name: 'MT701', latitude: 49.732708, longitude: 18.169733 },
  { name: 'APSOK', latitude: 49.733333, longitude: 39.566667 },
  { name: 'FD31', latitude: 49.7334, longitude: 24.081214 },
  { name: 'LL401', latitude: 49.735778, longitude: 24.069444 },
  { name: 'LLW01', latitude: 49.737639, longitude: 23.672333 },
  { name: '30TH2', latitude: 49.741603, longitude: 18.184211 },
  { name: 'MT602', latitude: 49.741828, longitude: 17.960692 },
  { name: 'ODRAP', latitude: 49.741944, longitude: 21.841111 },
  { name: 'KK640', latitude: 49.744167, longitude: 30.465278 },
  { name: 'PR582', latitude: 49.744683, longitude: 14.268486 },
  { name: 'KONIP', latitude: 49.745, longitude: 31.443333 },
  { name: 'KK841', latitude: 49.745111, longitude: 19.565472 },
  { name: 'MODGU', latitude: 49.746111, longitude: 20.416111 },
  { name: '45VOR', latitude: 49.746617, longitude: 18.196344 },
  { name: 'HHS03', latitude: 49.748222, longitude: 36.169833 },
  { name: 'VP024', latitude: 49.749167, longitude: 17.871944 },
  { name: 'MT04L', latitude: 49.750442, longitude: 18.200822 },
  { name: '40LOC', latitude: 49.751242, longitude: 18.199811 },
  { name: '48DME', latitude: 49.751728, longitude: 18.199633 },
  { name: 'MT823', latitude: 49.757561, longitude: 18.433864 },
  { name: 'LL112', latitude: 49.759389, longitude: 24.214222 },
  { name: 'KK622', latitude: 49.76, longitude: 19.792222 },
  { name: 'NAXAR', latitude: 49.760556, longitude: 19.8125 },
  { name: 'EKNEV', latitude: 49.76125, longitude: 15.304411 },
  { name: 'PR721', latitude: 49.761333, longitude: 14.952517 },
  { name: 'SORON', latitude: 49.761667, longitude: 27.85 },
  { name: 'VP004', latitude: 49.7625, longitude: 21.684722 },
  { name: 'D133O', latitude: 49.768014, longitude: 15.626403 },
  { name: 'DOBEN', latitude: 49.773339, longitude: 13.561542 },
  { name: 'KK850', latitude: 49.774444, longitude: 30.316944 },
  { name: 'MT512', latitude: 49.780203, longitude: 17.870275 },
  { name: 'FD22', latitude: 49.781511, longitude: 18.258511 },
  { name: 'KK567', latitude: 49.783861, longitude: 19.719361 },
  { name: 'MT22F', latitude: 49.784392, longitude: 18.253586 },
  { name: 'FI22', latitude: 49.784539, longitude: 18.253808 },
  { name: 'FQ22', latitude: 49.785236, longitude: 18.253331 },
  { name: 'VP005', latitude: 49.785833, longitude: 19.067778 },
  { name: 'VP025', latitude: 49.786111, longitude: 18.374722 },
  { name: 'VP026', latitude: 49.786667, longitude: 15.818611 },
  { name: 'MT702', latitude: 49.7874, longitude: 18.258703 },
  { name: 'ML31', latitude: 49.790292, longitude: 23.987633 },
  { name: 'MD31', latitude: 49.790475, longitude: 23.986939 },
  { name: 'VP006', latitude: 49.791944, longitude: 19.778056 },
  { name: 'LL402', latitude: 49.794111, longitude: 24.162389 },
  { name: 'TIBLA', latitude: 49.795844, longitude: 16.678858 },
  { name: 'PR904', latitude: 49.79795, longitude: 14.879667 },
  { name: 'MT807', latitude: 49.798992, longitude: 18.053772 },
  { name: 'PEPIL', latitude: 49.8, longitude: 25.700556 },
  { name: 'ADBAN', latitude: 49.802222, longitude: 25.391111 },
  { name: 'LLE01', latitude: 49.803889, longitude: 24.903056 },
  { name: 'VP007', latitude: 49.805278, longitude: 18.891389 },
  { name: 'LL113', latitude: 49.807306, longitude: 24.142583 },
  { name: 'D048K', latitude: 49.807331, longitude: 18.319022 },
  { name: 'CI22', latitude: 49.807486, longitude: 18.291089 },
  { name: 'VEMUT', latitude: 49.810744, longitude: 12.461247 },
  { name: 'PR522', latitude: 49.815889, longitude: 14.843386 },
  { name: 'LL115', latitude: 49.817611, longitude: 24.307306 },
  { name: 'KOKUM', latitude: 49.818611, longitude: 30.921944 },
  { name: 'HHS05', latitude: 49.819361, longitude: 36.007944 },
  { name: 'MT827', latitude: 49.822764, longitude: 18.338406 },
  { name: 'VASON', latitude: 49.824444, longitude: 33.768056 },
  { name: 'D035J', latitude: 49.824928, longitude: 18.274517 },
  { name: 'VEBIX', latitude: 49.826111, longitude: 31.8525 },
  { name: 'MD13', latitude: 49.826564, longitude: 23.933083 },
  { name: 'ML13', latitude: 49.826681, longitude: 23.932872 },
  { name: 'FF31', latitude: 49.8275, longitude: 15.542222 },
  { name: 'GIXOL', latitude: 49.829167, longitude: 19.351944 },
  { name: 'MT22A', latitude: 49.829911, longitude: 18.264642 },
  { name: 'TIPAM', latitude: 49.83, longitude: 12.2525 },
  { name: 'MT825', latitude: 49.830014, longitude: 18.327769 },
  { name: 'D032J', latitude: 49.830369, longitude: 18.263989 },
  { name: 'HHS02', latitude: 49.831167, longitude: 36.153306 },
  { name: 'KT868', latitude: 49.833306, longitude: 19.411444 },
  { name: 'TETNA', latitude: 49.833333, longitude: 26.416667 },
  { name: 'MT705', latitude: 49.838922, longitude: 18.342664 },
  { name: 'EKVAT', latitude: 49.840556, longitude: 20.291389 },
  { name: 'KK582', latitude: 49.844222, longitude: 19.410944 },
  { name: 'PR718', latitude: 49.844628, longitude: 14.400311 },
  { name: 'PR950', latitude: 49.847231, longitude: 14.877053 },
  { name: 'TUSIN', latitude: 49.847494, longitude: 18.572836 },
  { name: 'HH431', latitude: 49.847583, longitude: 36.351972 },
  { name: 'VP008', latitude: 49.848333, longitude: 19.030278 },
  { name: 'D019J', latitude: 49.849558, longitude: 18.213814 },
  { name: 'CV225', latitude: 49.851892, longitude: 15.345564 },
  { name: 'KK541', latitude: 49.851917, longitude: 20.041139 },
  { name: 'VP027', latitude: 49.851944, longitude: 15.345556 },
  { name: 'D016J', latitude: 49.852906, longitude: 18.201364 },
  { name: 'HH106', latitude: 49.856278, longitude: 36.458583 },
  { name: 'PR403', latitude: 49.856519, longitude: 14.159344 },
  { name: 'IC030', latitude: 49.856794, longitude: 12.111125 },
  { name: 'VP009', latitude: 49.856944, longitude: 20.141667 },
  { name: 'KENOK', latitude: 49.857225, longitude: 14.759444 },
  { name: 'BB644', latitude: 49.858611, longitude: 30.988333 },
  { name: 'KK621', latitude: 49.858833, longitude: 19.576833 },
  { name: 'MT806', latitude: 49.859092, longitude: 17.964819 },
  { name: 'MT703', latitude: 49.8597, longitude: 18.152275 },
  { name: 'CV13R', latitude: 49.859792, longitude: 15.495753 },
  { name: 'D285H', latitude: 49.860789, longitude: 23.756942 },
  { name: 'KK838', latitude: 49.860972, longitude: 20.028833 },
  { name: 'PR722', latitude: 49.861406, longitude: 14.890417 },
  { name: 'PR901', latitude: 49.862247, longitude: 14.190386 },
  { name: 'KK583', latitude: 49.862278, longitude: 19.536861 },
  { name: 'BB642', latitude: 49.8625, longitude: 30.858611 },
  { name: 'VP028', latitude: 49.862778, longitude: 15.066667 },
  { name: 'D284I', latitude: 49.863058, longitude: 23.730853 },
  { name: 'LL204', latitude: 49.863583, longitude: 23.715056 },
  { name: 'PR807', latitude: 49.864036, longitude: 14.272239 },
  { name: 'ASGID', latitude: 49.864944, longitude: 20.553722 },
  { name: 'LL114', latitude: 49.865611, longitude: 24.235639 },
  { name: 'BB660', latitude: 49.866389, longitude: 30.728889 },
  { name: 'PR913', latitude: 49.866422, longitude: 14.527886 },
  { name: 'BOGTU', latitude: 49.867942, longitude: 18.165411 },
  { name: 'HH421', latitude: 49.867944, longitude: 36.60475 },
  { name: 'LL301', latitude: 49.868056, longitude: 23.870833 },
  { name: 'KK627', latitude: 49.868806, longitude: 19.194972 },
  { name: 'LL303', latitude: 49.871722, longitude: 23.6835 },
  { name: 'FD13', latitude: 49.871944, longitude: 23.876111 },
  { name: 'BAXEV', latitude: 49.873244, longitude: 17.441492 },
  { name: 'PIVAK', latitude: 49.873333, longitude: 31.125556 },
  { name: 'KK584', latitude: 49.880361, longitude: 19.662889 },
  { name: 'D095K', latitude: 49.881625, longitude: 36.56175 },
  { name: 'VP029', latitude: 49.883056, longitude: 18.054722 },
  { name: 'MIPMI', latitude: 49.883056, longitude: 39.638333 },
  { name: 'DIBED', latitude: 49.888333, longitude: 23.058333 },
  { name: 'PR906', latitude: 49.888686, longitude: 14.695486 },
  { name: 'KK566', latitude: 49.890167, longitude: 19.280194 },
  { name: 'HH215', latitude: 49.891111, longitude: 35.862778 },
  { name: 'UVRET', latitude: 49.891361, longitude: 19.743306 },
  { name: 'CI13', latitude: 49.891894, longitude: 23.834889 },
  { name: 'VP030', latitude: 49.897222, longitude: 15.640556 },
  { name: 'PR707', latitude: 49.897875, longitude: 13.556028 },
  { name: 'VAREN', latitude: 49.899444, longitude: 22.668333 },
  { name: 'RIFMO', latitude: 49.899444, longitude: 26.028056 },
  { name: 'PR511', latitude: 49.900644, longitude: 13.48205 },
  { name: 'VP031', latitude: 49.901389, longitude: 15.935833 },
  { name: 'LL205', latitude: 49.901778, longitude: 23.45575 },
  { name: 'HH204', latitude: 49.901833, longitude: 35.991194 },
  { name: 'MT821', latitude: 49.902375, longitude: 18.221356 },
  { name: 'HH203', latitude: 49.902917, longitude: 36.003611 },
  { name: 'ELMEK', latitude: 49.9039, longitude: 14.029875 },
  { name: 'LL201', latitude: 49.904278, longitude: 23.785139 },
  { name: 'FD07', latitude: 49.9055, longitude: 36.120778 },
  { name: 'MT804', latitude: 49.905531, longitude: 17.776028 },
  { name: 'PR855', latitude: 49.907275, longitude: 13.882994 },
  { name: 'CI07', latitude: 49.908364, longitude: 36.068372 },
  { name: 'LOMKI', latitude: 49.909336, longitude: 13.241222 },
  { name: 'VP010', latitude: 49.910833, longitude: 19.412778 },
  { name: 'RZ491', latitude: 49.911611, longitude: 22.176222 },
  { name: 'FF07', latitude: 49.912619, longitude: 36.118997 },
  { name: 'HHW01', latitude: 49.914139, longitude: 36.137056 },
  { name: 'KK581', latitude: 49.917944, longitude: 18.792083 },
  { name: 'ATKEK', latitude: 49.921667, longitude: 21.683889 },
  { name: 'ML07', latitude: 49.923867, longitude: 36.2543 },
  { name: 'MD07', latitude: 49.923928, longitude: 36.254133 },
  { name: 'ELPON', latitude: 49.925011, longitude: 14.617303 },
  { name: 'BB646', latitude: 49.925278, longitude: 30.993056 },
  { name: 'MT706', latitude: 49.926036, longitude: 18.196308 },
  { name: 'NATIX', latitude: 49.926667, longitude: 21.383611 },
  { name: 'LLE02', latitude: 49.927667, longitude: 24.218111 },
  { name: 'BB640', latitude: 49.929167, longitude: 30.863333 },
  { name: 'ML25', latitude: 49.929456, longitude: 36.322297 },
  { name: 'MD25', latitude: 49.929756, longitude: 36.328025 },
  { name: 'CV288', latitude: 49.93, longitude: 15.233528 },
  { name: 'VP032', latitude: 49.93, longitude: 15.233611 },
  { name: 'LUXAR', latitude: 49.93, longitude: 21.175278 },
  { name: 'PR902', latitude: 49.930075, longitude: 14.399392 },
  { name: 'PR740', latitude: 49.930231, longitude: 13.962553 },
  { name: 'LL302', latitude: 49.930361, longitude: 23.776194 },
  { name: 'KK587', latitude: 49.930389, longitude: 19.381472 },
  { name: 'SOPAV', latitude: 49.930922, longitude: 18.052378 },
  { name: '17LOC', latitude: 49.931375, longitude: 36.345842 },
  { name: 'LLN01', latitude: 49.932278, longitude: 24.237028 },
  { name: 'FD25', latitude: 49.932644, longitude: 36.461494 },
  { name: 'BB662', latitude: 49.933056, longitude: 30.733611 },
  { name: 'PADKA', latitude: 49.933883, longitude: 18.283269 },
  { name: 'GEVNA', latitude: 49.935, longitude: 20.724722 },
  { name: 'LAVPI', latitude: 49.935556, longitude: 18.450833 },
  { name: 'ODOMO', latitude: 49.937283, longitude: 12.344 },
  { name: 'RZ523', latitude: 49.9385, longitude: 21.675056 },
  { name: 'HH105', latitude: 49.93925, longitude: 36.442528 },
  { name: 'MOKMU', latitude: 49.939444, longitude: 20.140833 },
  { name: 'NOMIX', latitude: 49.939722, longitude: 18.923056 },
  { name: 'XEVBA', latitude: 49.939722, longitude: 20.081389 },
  { name: 'USALO', latitude: 49.940278, longitude: 19.064722 },
  { name: 'SKAVI', latitude: 49.940278, longitude: 19.9 },
  { name: 'CF25', latitude: 49.940536, longitude: 36.458978 },
  { name: 'FF25', latitude: 49.940658, longitude: 36.460514 },
  { name: 'PD414', latitude: 49.946069, longitude: 16.019156 },
  { name: 'HH405', latitude: 49.946694, longitude: 36.535139 },
  { name: 'KK586', latitude: 49.948472, longitude: 19.507389 },
  { name: 'BAROX', latitude: 49.948547, longitude: 13.72085 },
  { name: 'PD413', latitude: 49.949472, longitude: 15.916078 },
  { name: 'ERPOK', latitude: 49.95, longitude: 20.115278 },
  { name: 'HH402', latitude: 49.950972, longitude: 36.588917 },
  { name: 'POBUV', latitude: 49.951111, longitude: 27.489444 },
  { name: 'ASVEN', latitude: 49.951667, longitude: 31.406111 },
  { name: 'RIBSI', latitude: 49.953767, longitude: 17.66175 },
  { name: 'PR912', latitude: 49.95405, longitude: 14.562372 },
  { name: 'PR719', latitude: 49.95435, longitude: 14.347353 },
  { name: 'KK539', latitude: 49.954833, longitude: 18.805833 },
  { name: 'AKEVA', latitude: 49.954878, longitude: 14.043775 },
  { name: 'PR905', latitude: 49.954961, longitude: 14.773664 },
  { name: 'VP033', latitude: 49.955278, longitude: 15.518889 },
  { name: 'PR860', latitude: 49.955775, longitude: 14.558722 },
  { name: 'PR854', latitude: 49.956219, longitude: 14.138561 },
  { name: 'KK542', latitude: 49.956556, longitude: 19.769472 },
  { name: 'RZ729', latitude: 49.957917, longitude: 21.429639 },
  { name: 'PD412', latitude: 49.9582, longitude: 15.637692 },
  { name: 'UBEPE', latitude: 49.958889, longitude: 21.326389 },
  { name: 'LL202', latitude: 49.959944, longitude: 23.846167 },
  { name: 'VP034', latitude: 49.961667, longitude: 14.082778 },
  { name: 'BEKTO', latitude: 49.965822, longitude: 12.712044 },
  { name: 'KK579', latitude: 49.966528, longitude: 19.633361 },
  { name: 'LL203', latitude: 49.968028, longitude: 23.89925 },
  { name: 'PR817', latitude: 49.968333, longitude: 13.980833 },
  { name: 'VP011', latitude: 49.972778, longitude: 22.033889 },
  { name: 'D119N', latitude: 49.973072, longitude: 14.557911 },
  { name: 'VP035', latitude: 49.974167, longitude: 15.870278 },
  { name: 'PR31I', latitude: 49.975131, longitude: 14.518944 },
  { name: 'VP036', latitude: 49.975278, longitude: 15.643056 },
  { name: 'PR411', latitude: 49.975392, longitude: 14.264369 },
  { name: 'RZ739', latitude: 49.976944, longitude: 22.941056 },
  { name: 'PR953', latitude: 49.979086, longitude: 14.296597 },
  { name: 'VP037', latitude: 49.980833, longitude: 14.606111 },
  { name: 'CV314', latitude: 49.982194, longitude: 15.320806 },
  { name: 'VP012', latitude: 49.983333, longitude: 19.816667 },
  { name: 'PD514', latitude: 49.983431, longitude: 16.603111 },
  { name: 'VP013', latitude: 49.983889, longitude: 21.944444 },
  { name: 'VP014', latitude: 49.983889, longitude: 22.401389 },
  { name: 'PR635', latitude: 49.985086, longitude: 14.660817 },
  { name: 'CI30', latitude: 49.985161, longitude: 14.498475 },
  { name: 'PR626', latitude: 49.985919, longitude: 14.496969 },
  { name: 'VP038', latitude: 49.986111, longitude: 14.361389 },
  { name: 'LL304', latitude: 49.988944, longitude: 23.869083 },
  { name: 'ARSOG', latitude: 49.990556, longitude: 22.699722 },
  { name: 'KIKZA', latitude: 49.991111, longitude: 21.4975 },
  { name: 'PR573', latitude: 49.991406, longitude: 14.470825 },
  { name: 'BB648', latitude: 49.991944, longitude: 30.997778 },
  { name: 'RZ461', latitude: 49.993944, longitude: 21.316167 },
  { name: 'ORLIX', latitude: 49.994958, longitude: 16.292722 },
  { name: 'VP039', latitude: 49.995, longitude: 16.109167 },
  { name: 'BB638', latitude: 49.995833, longitude: 30.868056 },
  { name: 'PR571', latitude: 49.997317, longitude: 14.799803 },
  { name: 'BABIS', latitude: 49.998333, longitude: 26.358056 },
  { name: 'KK578', latitude: 49.998528, longitude: 19.225778 },
  { name: 'ARVEG', latitude: 49.998992, longitude: 14.683958 },
  { name: 'BB664', latitude: 49.999722, longitude: 30.738333 },
  { name: '5013E', latitude: 50.0, longitude: 13.0 },
  { name: '5014E', latitude: 50.0, longitude: 14.0 },
  { name: '5015E', latitude: 50.0, longitude: 15.0 },
  { name: '5016E', latitude: 50.0, longitude: 16.0 },
  { name: '5017E', latitude: 50.0, longitude: 17.0 },
  { name: '5018E', latitude: 50.0, longitude: 18.0 },
  { name: '5019E', latitude: 50.0, longitude: 19.0 },
  { name: '5020E', latitude: 50.0, longitude: 20.0 },
  { name: '5021E', latitude: 50.0, longitude: 21.0 },
  { name: '5022E', latitude: 50.0, longitude: 22.0 },
  { name: '5023E', latitude: 50.0, longitude: 23.0 },
  { name: '5024E', latitude: 50.0, longitude: 24.0 },
  { name: '5025E', latitude: 50.0, longitude: 25.0 },
  { name: '5026E', latitude: 50.0, longitude: 26.0 },
  { name: '5027E', latitude: 50.0, longitude: 27.0 },
  { name: '5028E', latitude: 50.0, longitude: 28.0 },
  { name: '5029E', latitude: 50.0, longitude: 29.0 },
  { name: '5030E', latitude: 50.0, longitude: 30.0 },
  { name: '5031E', latitude: 50.0, longitude: 31.0 },
  { name: '5032E', latitude: 50.0, longitude: 32.0 },
  { name: '5033E', latitude: 50.0, longitude: 33.0 },
  { name: '5034E', latitude: 50.0, longitude: 34.0 },
  { name: '5035E', latitude: 50.0, longitude: 35.0 },
  { name: '5036E', latitude: 50.0, longitude: 36.0 },
  { name: '5037E', latitude: 50.0, longitude: 37.0 },
  { name: '5038E', latitude: 50.0, longitude: 38.0 },
  { name: 'ABODO', latitude: 50.0, longitude: 38.233333 },
  { name: '5039E', latitude: 50.0, longitude: 39.0 },
  { name: '5040E', latitude: 50.0, longitude: 40.0 },
  { name: 'PR956', latitude: 50.001422, longitude: 13.808506 },
  { name: 'PD435', latitude: 50.002742, longitude: 16.069197 },
  { name: 'BAVOK', latitude: 50.002778, longitude: 18.195278 },
  { name: 'PD415', latitude: 50.004267, longitude: 16.023836 },
  { name: 'CF27', latitude: 50.004275, longitude: 16.022836 },
  { name: 'PR30F', latitude: 50.005019, longitude: 14.458119 },
  { name: 'FI30', latitude: 50.005242, longitude: 14.457267 },
  { name: 'PR742', latitude: 50.005531, longitude: 13.907528 },
  { name: 'KK568', latitude: 50.007056, longitude: 19.889278 },
  { name: 'PD27F', latitude: 50.007161, longitude: 15.93585 },
  { name: 'D285I', latitude: 50.007814, longitude: 15.130606 },
  { name: '40THR', latitude: 50.009658, longitude: 15.859214 },
  { name: 'OM27', latitude: 50.011092, longitude: 15.813019 },
  { name: 'PR521', latitude: 50.011339, longitude: 14.230392 },
  { name: 'FD30', latitude: 50.011353, longitude: 14.467133 },
  { name: 'PR951', latitude: 50.013272, longitude: 14.654689 },
  { name: 'ETUDA', latitude: 50.013281, longitude: 16.507322 },
  { name: 'PR31H', latitude: 50.014219, longitude: 14.703469 },
  { name: 'D269C', latitude: 50.01555, longitude: 15.674542 },
  { name: '20THR', latitude: 50.015578, longitude: 15.669528 },
  { name: 'KK543', latitude: 50.016333, longitude: 19.993028 },
  { name: 'PD411', latitude: 50.016414, longitude: 15.641914 },
  { name: 'EHETE', latitude: 50.016556, longitude: 19.351889 },
  { name: 'RZ709', latitude: 50.017583, longitude: 21.394861 },
  { name: 'CV13C', latitude: 50.01825, longitude: 15.114069 },
  { name: 'OTPAK', latitude: 50.018333, longitude: 31.830278 },
  { name: 'PD431', latitude: 50.018342, longitude: 15.579175 },
  { name: 'PD09F', latitude: 50.019397, longitude: 15.541678 },
  { name: 'VP015', latitude: 50.019444, longitude: 22.259444 },
  { name: 'D269H', latitude: 50.019817, longitude: 15.545497 },
  { name: 'KK396', latitude: 50.020361, longitude: 19.495639 },
  { name: 'VP016', latitude: 50.021389, longitude: 20.151944 },
  { name: 'RZ731', latitude: 50.021639, longitude: 22.032028 },
  { name: 'GOTIX', latitude: 50.021667, longitude: 23.211944 },
  { name: 'PD954', latitude: 50.021714, longitude: 15.461661 },
  { name: 'RZ733', latitude: 50.022167, longitude: 22.322 },
  { name: 'RZ522', latitude: 50.024722, longitude: 22.160667 },
  { name: 'CI06', latitude: 50.026964, longitude: 13.978117 },
  { name: 'IBEKA', latitude: 50.027778, longitude: 18.941667 },
  { name: 'RZ642', latitude: 50.028639, longitude: 21.864139 },
  { name: 'REGLI', latitude: 50.030278, longitude: 17.995556 },
  { name: 'RZ492', latitude: 50.030889, longitude: 21.710333 },
  { name: 'KV291', latitude: 50.032025, longitude: 13.176458 },
  { name: 'KK548', latitude: 50.032778, longitude: 19.465444 },
  { name: 'CV13F', latitude: 50.033236, longitude: 15.246411 },
  { name: 'KK544', latitude: 50.033583, longitude: 20.119444 },
  { name: 'VP040', latitude: 50.033611, longitude: 15.406944 },
  { name: 'KK383', latitude: 50.033667, longitude: 19.471694 },
  { name: 'RZ469', latitude: 50.034472, longitude: 21.495083 },
  { name: 'IMNAZ', latitude: 50.034583, longitude: 19.478056 },
  { name: 'AKFAG', latitude: 50.035194, longitude: 19.378056 },
  { name: 'HHN04', latitude: 50.035556, longitude: 35.963944 },
  { name: 'PR402', latitude: 50.038281, longitude: 14.015469 },
  { name: 'DOBIL', latitude: 50.038689, longitude: 15.937392 },
  { name: 'VP017', latitude: 50.038889, longitude: 22.068889 },
  { name: 'PR06F', latitude: 50.040886, longitude: 14.024086 },
  { name: 'FI06', latitude: 50.041136, longitude: 14.024903 },
  { name: 'BUDUK', latitude: 50.041667, longitude: 28.962222 },
  { name: 'D072J', latitude: 50.041933, longitude: 16.0061 },
  { name: 'VP018', latitude: 50.041944, longitude: 20.097778 },
  { name: 'KK387', latitude: 50.044333, longitude: 19.467417 },
  { name: 'D295K', latitude: 50.044992, longitude: 15.100942 },
  { name: 'KT869', latitude: 50.047139, longitude: 18.538361 },
  { name: 'HHN02', latitude: 50.047861, longitude: 36.109944 },
  { name: 'VP041', latitude: 50.049722, longitude: 14.272778 },
  { name: 'KT667', latitude: 50.049972, longitude: 18.771333 },
  { name: 'VP021', latitude: 50.05, longitude: 19.8 },
  { name: 'KK547', latitude: 50.050778, longitude: 20.246167 },
  { name: 'PR523', latitude: 50.051006, longitude: 14.363139 },
  { name: 'VP042', latitude: 50.051389, longitude: 15.033056 },
  { name: 'KK591', latitude: 50.051389, longitude: 19.59675 },
  { name: 'D260R', latitude: 50.051747, longitude: 30.928231 },
  { name: 'BASOR', latitude: 50.051944, longitude: 30.833333 },
  { name: 'VP019', latitude: 50.052778, longitude: 18.645278 },
  { name: 'D164N', latitude: 50.052878, longitude: 30.958239 },
  { name: 'VP020', latitude: 50.053056, longitude: 20.329444 },
  { name: 'KT864', latitude: 50.054417, longitude: 18.765222 },
  { name: 'PR915', latitude: 50.054453, longitude: 14.570528 },
  { name: 'KK599', latitude: 50.057139, longitude: 19.637778 },
  { name: 'KK388', latitude: 50.057306, longitude: 19.595139 },
  { name: 'PR06N', latitude: 50.057914, longitude: 13.774864 },
  { name: 'BB650', latitude: 50.058611, longitude: 31.0025 },
  { name: 'GOLIN', latitude: 50.059314, longitude: 14.984964 },
  { name: 'KOZLI', latitude: 50.059444, longitude: 15.353611 },
  { name: 'KT752', latitude: 50.061861, longitude: 19.688833 },
  { name: 'BB636', latitude: 50.0625, longitude: 30.872778 },
  { name: 'EKROT', latitude: 50.062778, longitude: 14.886944 },
  { name: '32THR', latitude: 50.064097, longitude: 19.687194 },
  { name: 'HH441', latitude: 50.064361, longitude: 36.309472 },
  { name: 'VP022', latitude: 50.064444, longitude: 21.731111 },
  { name: 'UDVIR', latitude: 50.065278, longitude: 35.187778 },
  { name: 'VP023', latitude: 50.065833, longitude: 21.927222 },
  { name: '40VOR', latitude: 50.066389, longitude: 19.68575 },
  { name: 'VAVEL', latitude: 50.066389, longitude: 19.961944 },
  { name: 'BB665', latitude: 50.066389, longitude: 30.743056 },
  { name: 'RUNUD', latitude: 50.066389, longitude: 40.501389 },
  { name: 'PR574', latitude: 50.06695, longitude: 14.416519 },
  { name: 'CV13I', latitude: 50.067475, longitude: 15.197219 },
  { name: 'ABERU', latitude: 50.069194, longitude: 12.093719 },
  { name: 'PR723', latitude: 50.069503, longitude: 14.760211 },
  { name: 'D099I', latitude: 50.070042, longitude: 22.356417 },
  { name: 'VP043', latitude: 50.071667, longitude: 14.773333 },
  { name: 'PR572', latitude: 50.073014, longitude: 14.746006 },
  { name: 'HHN03', latitude: 50.073056, longitude: 36.416528 },
  { name: 'BEKVI', latitude: 50.073356, longitude: 14.722358 },
  { name: 'D057J', latitude: 50.074594, longitude: 15.991419 },
  { name: 'INDAD', latitude: 50.074722, longitude: 21.268333 },
  { name: 'BEMBI', latitude: 50.074722, longitude: 29.933056 },
  { name: 'KV29S', latitude: 50.074892, longitude: 12.761408 },
  { name: 'MASOL', latitude: 50.075, longitude: 38.226667 },
  { name: 'PR512', latitude: 50.077231, longitude: 13.840125 },
  { name: 'KK607', latitude: 50.077361, longitude: 19.141694 },
  { name: 'KT674', latitude: 50.078028, longitude: 19.459167 },
  { name: 'PR741', latitude: 50.080803, longitude: 13.852325 },
  { name: 'DONAD', latitude: 50.080814, longitude: 13.0 },
  { name: 'ML25', latitude: 50.081861, longitude: 19.814611 },
  { name: 'VP024', latitude: 50.081944, longitude: 18.663333 },
  { name: 'MD30', latitude: 50.082258, longitude: 14.298158 },
  { name: 'KV29H', latitude: 50.082325, longitude: 13.181411 },
  { name: 'VP044', latitude: 50.083889, longitude: 12.84 },
  { name: 'VP045', latitude: 50.084167, longitude: 13.126667 },
  { name: '25THR', latitude: 50.084217, longitude: 14.167675 },
  { name: 'KK577', latitude: 50.084694, longitude: 19.196194 },
  { name: 'HH411', latitude: 50.084833, longitude: 36.563306 },
  { name: '25DME', latitude: 50.085603, longitude: 14.172381 },
  { name: '25LOC', latitude: 50.085656, longitude: 14.172453 },
  { name: 'KV118', latitude: 50.086578, longitude: 12.953144 },
  { name: 'ODEKU', latitude: 50.087425, longitude: 17.296719 },
  { name: 'VP025', latitude: 50.087778, longitude: 18.586944 },
  { name: 'PD434', latitude: 50.089061, longitude: 16.076242 },
  { name: 'BALTU', latitude: 50.089461, longitude: 13.326522 },
  { name: 'KV29C', latitude: 50.092156, longitude: 13.147681 },
  { name: 'PD433', latitude: 50.093361, longitude: 15.947014 },
  { name: 'XEVLU', latitude: 50.095556, longitude: 19.912778 },
  { name: 'EROKA', latitude: 50.097058, longitude: 13.690717 },
  { name: 'PD952', latitude: 50.097458, longitude: 15.819708 },
  { name: 'KK801', latitude: 50.097944, longitude: 19.930806 },
  { name: 'PR954', latitude: 50.098889, longitude: 14.048386 },
  { name: 'MA06', latitude: 50.09975, longitude: 14.21935 },
  { name: 'MQ06', latitude: 50.099783, longitude: 14.2195 },
  { name: 'RZ742', latitude: 50.1015, longitude: 22.569444 },
  { name: 'ORTEB', latitude: 50.102222, longitude: 20.569722 },
  { name: 'VP046', latitude: 50.1025, longitude: 15.856944 },
  { name: 'KK576', latitude: 50.10275, longitude: 19.322528 },
  { name: 'D238C', latitude: 50.1028, longitude: 14.491239 },
  { name: 'RZ752', latitude: 50.103306, longitude: 22.459222 },
  { name: 'KK362', latitude: 50.103667, longitude: 19.972167 },
  { name: 'PD432', latitude: 50.104636, longitude: 15.585339 },
  { name: 'RZ771', latitude: 50.104861, longitude: 22.365194 },
  { name: 'KV29A', latitude: 50.10505, longitude: 13.161367 },
  { name: 'RZ744', latitude: 50.1055, longitude: 22.32475 },
  { name: 'KUVIX', latitude: 50.105531, longitude: 13.933742 },
  { name: 'KT863', latitude: 50.105972, longitude: 18.693861 },
  { name: 'RZ394', latitude: 50.106444, longitude: 22.299194 },
  { name: 'GOVRI', latitude: 50.107028, longitude: 21.031667 },
  { name: 'RZ773', latitude: 50.107556, longitude: 22.19075 },
  { name: 'RZ501', latitude: 50.107944, longitude: 22.163583 },
  { name: 'PEXAS', latitude: 50.108167, longitude: 21.315722 },
  { name: 'VOZ35', latitude: 50.108364, longitude: 14.890297 },
  { name: 'RZ392', latitude: 50.108611, longitude: 22.134028 },
  { name: 'VP026', latitude: 50.109167, longitude: 19.779444 },
  { name: 'PD953', latitude: 50.109236, longitude: 15.426242 },
  { name: 'ML27', latitude: 50.109389, longitude: 22.062056 },
  { name: 'KK392', latitude: 50.110889, longitude: 19.969222 },
  { name: 'ML12', latitude: 50.1114, longitude: 14.238347 },
  { name: 'RZ601', latitude: 50.111861, longitude: 21.866417 },
  { name: 'RZ426', latitude: 50.111889, longitude: 21.842028 },
  { name: 'VP027', latitude: 50.111944, longitude: 20.235556 },
  { name: 'PR952', latitude: 50.112028, longitude: 14.452214 },
  { name: 'RZ436', latitude: 50.112167, longitude: 21.842028 },
  { name: 'RZ374', latitude: 50.112472, longitude: 21.815472 },
  { name: 'RZ421', latitude: 50.113278, longitude: 21.693139 },
  { name: 'RZ431', latitude: 50.113611, longitude: 21.712583 },
  { name: 'RZ413', latitude: 50.114167, longitude: 21.585194 },
  { name: 'KV112', latitude: 50.114239, longitude: 12.843908 },
  { name: 'MD12', latitude: 50.115522, longitude: 14.230431 },
  { name: 'VP047', latitude: 50.115556, longitude: 15.652778 },
  { name: 'KV292', latitude: 50.115619, longitude: 13.232475 },
  { name: 'D324K', latitude: 50.115706, longitude: 15.196125 },
  { name: 'RZ623', latitude: 50.116083, longitude: 21.415444 },
  { name: 'RATEV', latitude: 50.117589, longitude: 14.586847 },
  { name: 'OFFUK', latitude: 50.119944, longitude: 20.091194 },
  { name: 'VP028', latitude: 50.120278, longitude: 20.378889 },
  { name: 'KK574', latitude: 50.120806, longitude: 19.448917 },
  { name: 'GIDNO', latitude: 50.121389, longitude: 26.037778 },
  { name: 'RZ741', latitude: 50.123028, longitude: 22.746472 },
  { name: 'PR814', latitude: 50.123075, longitude: 13.9738 },
  { name: 'BB652', latitude: 50.125278, longitude: 31.007222 },
  { name: 'OTSEV', latitude: 50.125556, longitude: 34.663611 },
  { name: 'PR856', latitude: 50.126239, longitude: 14.658919 },
  { name: 'KV29I', latitude: 50.127656, longitude: 13.187044 },
  { name: 'VP048', latitude: 50.127778, longitude: 15.536667 },
  { name: 'BRODY', latitude: 50.129722, longitude: 25.172222 },
  { name: 'KT661', latitude: 50.131056, longitude: 18.241611 },
  { name: 'VO281', latitude: 50.131514, longitude: 14.888556 },
  { name: 'RUNOZ', latitude: 50.131667, longitude: 30.889722 },
  { name: 'LANED', latitude: 50.132222, longitude: 30.865556 },
  { name: 'VP029', latitude: 50.1325, longitude: 19.025 },
  { name: 'BB668', latitude: 50.133056, longitude: 30.747778 },
  { name: 'OSBUG', latitude: 50.133333, longitude: 21.014444 },
  { name: 'OSGAS', latitude: 50.133333, longitude: 25.5 },
  { name: 'KK384', latitude: 50.134278, longitude: 20.0935 },
  { name: 'CI29', latitude: 50.135289, longitude: 13.161172 },
  { name: 'KV299', latitude: 50.135556, longitude: 12.800417 },
  { name: 'VP030', latitude: 50.136389, longitude: 20.114444 },
  { name: 'PR530', latitude: 50.136544, longitude: 14.650919 },
  { name: 'BAWZI', latitude: 50.137139, longitude: 20.217917 },
  { name: 'VP049', latitude: 50.138056, longitude: 14.243889 },
  { name: 'KK606', latitude: 50.138833, longitude: 19.61 },
  { name: 'KK573', latitude: 50.138889, longitude: 19.575333 },
  { name: 'VP050', latitude: 50.139167, longitude: 15.291944 },
  { name: 'VP051', latitude: 50.139167, longitude: 15.788611 },
  { name: 'D072I', latitude: 50.1392, longitude: 22.359556 },
  { name: 'PR914', latitude: 50.139528, longitude: 14.395506 },
  { name: 'KOVUS', latitude: 50.142222, longitude: 24.241389 },
  { name: 'KV123', latitude: 50.142744, longitude: 13.435544 },
  { name: 'PR619', latitude: 50.144039, longitude: 14.367311 },
  { name: 'SOMIS', latitude: 50.144044, longitude: 13.954217 },
  { name: 'BBE01', latitude: 50.144167, longitude: 31.056111 },
  { name: 'D090G', latitude: 50.144428, longitude: 14.805033 },
  { name: '40LOC', latitude: 50.146372, longitude: 14.165831 },
  { name: '40THR', latitude: 50.148019, longitude: 14.162539 },
  { name: 'KK597', latitude: 50.148056, longitude: 20.299111 },
  { name: 'FI29', latitude: 50.14845, longitude: 13.113575 },
  { name: 'KV29F', latitude: 50.148547, longitude: 13.113494 },
  { name: 'VP031', latitude: 50.15, longitude: 19.633333 },
  { name: '50VOR', latitude: 50.150442, longitude: 14.167825 },
  { name: 'KT753', latitude: 50.150444, longitude: 19.658806 },
  { name: 'AJWER', latitude: 50.150556, longitude: 20.180556 },
  { name: 'VP052', latitude: 50.152778, longitude: 13.983056 },
  { name: 'KK562', latitude: 50.15425, longitude: 20.344694 },
  { name: 'KK552', latitude: 50.154583, longitude: 19.682333 },
  { name: 'RZ372', latitude: 50.154917, longitude: 22.014278 },
  { name: 'KV29G', latitude: 50.157461, longitude: 13.081222 },
  { name: 'D101L', latitude: 50.164528, longitude: 14.695728 },
  { name: 'VO282', latitude: 50.165047, longitude: 14.696081 },
  { name: 'VP053', latitude: 50.165278, longitude: 15.795 },
  { name: 'VP032', latitude: 50.166667, longitude: 22.033333 },
  { name: 'D101K', latitude: 50.167233, longitude: 14.683456 },
  { name: '50DME', latitude: 50.167519, longitude: 13.044614 },
  { name: 'KK571', latitude: 50.16825, longitude: 19.7865 },
  { name: 'KK808', latitude: 50.170528, longitude: 20.467194 },
  { name: 'VP054', latitude: 50.170833, longitude: 12.891667 },
  { name: 'KV113', latitude: 50.171744, longitude: 12.632556 },
  { name: 'KK553', latitude: 50.171806, longitude: 19.809139 },
  { name: 'PR625', latitude: 50.172042, longitude: 14.461356 },
  { name: 'KT742', latitude: 50.17375, longitude: 18.59975 },
  { name: 'KV116', latitude: 50.174106, longitude: 13.020653 },
  { name: 'FF24', latitude: 50.174469, longitude: 14.70185 },
  { name: 'D111D', latitude: 50.174819, longitude: 13.021186 },
  { name: 'KT862', latitude: 50.175444, longitude: 18.878694 },
  { name: 'VP033', latitude: 50.176667, longitude: 20.995833 },
  { name: 'SOPGA', latitude: 50.177008, longitude: 12.718786 },
  { name: 'VP055', latitude: 50.177222, longitude: 14.855278 },
  { name: 'PR24F', latitude: 50.177442, longitude: 14.479519 },
  { name: 'FI24', latitude: 50.177672, longitude: 14.480303 },
  { name: 'VO284', latitude: 50.177717, longitude: 14.623497 },
  { name: 'KK549', latitude: 50.178, longitude: 19.343 },
  { name: 'PR861', latitude: 50.178214, longitude: 14.8163 },
  { name: 'PR960', latitude: 50.178319, longitude: 13.882503 },
  { name: 'PR840', latitude: 50.178708, longitude: 14.256194 },
  { name: 'VP034', latitude: 50.178889, longitude: 18.906944 },
  { name: '32DME', latitude: 50.179442, longitude: 13.001592 },
  { name: 'BB315', latitude: 50.179722, longitude: 30.869167 },
  { name: 'D003H', latitude: 50.180608, longitude: 30.769217 },
  { name: '30LOC', latitude: 50.180614, longitude: 12.996853 },
  { name: 'ARMOV', latitude: 50.180767, longitude: 13.2807 },
  { name: '28THR', latitude: 50.181022, longitude: 12.9958 },
  { name: 'PEVOT', latitude: 50.181944, longitude: 27.043889 },
  { name: 'PR513', latitude: 50.182828, longitude: 14.189733 },
  { name: 'ROSOG', latitude: 50.183056, longitude: 39.606667 },
  { name: 'D141M', latitude: 50.183628, longitude: 14.820664 },
  { name: 'CF28', latitude: 50.184525, longitude: 14.583306 },
  { name: 'ERUSO', latitude: 50.185853, longitude: 15.271108 },
  { name: 'BEDLO', latitude: 50.1859, longitude: 15.316 },
  { name: 'BB110', latitude: 50.186389, longitude: 30.893611 },
  { name: 'PR631', latitude: 50.186847, longitude: 14.511222 },
  { name: 'HHN01', latitude: 50.187167, longitude: 35.845222 },
  { name: 'VP056', latitude: 50.187778, longitude: 14.185833 },
  { name: 'RZ713', latitude: 50.188194, longitude: 22.329528 },
  { name: 'RZ532', latitude: 50.188278, longitude: 22.325306 },
  { name: 'VP057', latitude: 50.188333, longitude: 14.041111 },
  { name: 'BB325', latitude: 50.188611, longitude: 30.765556 },
  { name: 'KK556', latitude: 50.189028, longitude: 19.935972 },
  { name: 'VP058', latitude: 50.189444, longitude: 12.758056 },
  { name: 'FF36L', latitude: 50.190161, longitude: 30.869933 },
  { name: 'RAPET', latitude: 50.190489, longitude: 12.338639 },
  { name: 'RZ706', latitude: 50.191111, longitude: 22.167111 },
  { name: 'BBE02', latitude: 50.191111, longitude: 31.3775 },
  { name: 'RZ512', latitude: 50.191194, longitude: 22.1665 },
  { name: 'CI24', latitude: 50.191639, longitude: 14.527383 },
  { name: 'LURAD', latitude: 50.191944, longitude: 18.574444 },
  { name: 'BB654', latitude: 50.191944, longitude: 31.011944 },
  { name: 'RZ406', latitude: 50.192778, longitude: 22.03275 },
  { name: 'VO28F', latitude: 50.193119, longitude: 14.533331 },
  { name: 'FF28', latitude: 50.193139, longitude: 14.533206 },
  { name: 'RZ712', latitude: 50.193556, longitude: 21.981444 },
  { name: 'NIMUL', latitude: 50.193914, longitude: 14.975 },
  { name: 'FF36R', latitude: 50.194975, longitude: 30.894364 },
  { name: 'RZ632', latitude: 50.195111, longitude: 21.868694 },
  { name: 'FI12', latitude: 50.195247, longitude: 14.064122 },
  { name: 'IVMEM', latitude: 50.195361, longitude: 18.612417 },
  { name: 'RZ711', latitude: 50.195361, longitude: 21.846306 },
  { name: 'PR12F', latitude: 50.195603, longitude: 14.063694 },
  { name: 'VP035', latitude: 50.195833, longitude: 21.276944 },
  { name: 'CF24', latitude: 50.195992, longitude: 14.766258 },
  { name: 'RZ442', latitude: 50.196861, longitude: 21.714611 },
  { name: 'PR813', latitude: 50.197244, longitude: 14.059947 },
  { name: 'PR405', latitude: 50.199383, longitude: 13.842208 },
  { name: 'BB670', latitude: 50.199722, longitude: 30.7525 },
  { name: 'BB656', latitude: 50.201667, longitude: 31.138611 },
  { name: 'D174J', latitude: 50.201825, longitude: 14.630325 },
  { name: 'RZ412', latitude: 50.202111, longitude: 21.468222 },
  { name: 'FD12', latitude: 50.202756, longitude: 14.073744 },
  { name: 'KV114', latitude: 50.2041, longitude: 12.512839 },
  { name: 'BBE05', latitude: 50.205833, longitude: 31.095 },
  { name: '20DME', latitude: 50.205881, longitude: 14.457947 },
  { name: 'VO104', latitude: 50.205989, longitude: 14.458333 },
  { name: 'KK558', latitude: 50.206278, longitude: 20.062833 },
  { name: 'MOSIR', latitude: 50.206944, longitude: 22.775 },
  { name: 'PR958', latitude: 50.207253, longitude: 14.255428 },
  { name: 'VP059', latitude: 50.2075, longitude: 14.696389 },
  { name: 'EMBIK', latitude: 50.208056, longitude: 18.103056 },
  { name: 'ADADO', latitude: 50.209481, longitude: 17.747931 },
  { name: 'UTEVO', latitude: 50.209928, longitude: 17.511914 },
  { name: 'QM211', latitude: 50.211347, longitude: 12.026017 },
  { name: 'PR532', latitude: 50.212172, longitude: 14.596731 },
  { name: 'MN28', latitude: 50.212367, longitude: 14.420364 },
  { name: 'ML28', latitude: 50.212439, longitude: 14.420408 },
  { name: 'IZERA', latitude: 50.212778, longitude: 33.885278 },
  { name: 'KV11M', latitude: 50.213961, longitude: 12.875222 },
  { name: 'QM210', latitude: 50.214097, longitude: 12.103917 },
  { name: 'CI12', latitude: 50.215172, longitude: 14.02255 },
  { name: 'KK632', latitude: 50.216972, longitude: 18.726889 },
  { name: 'PR518', latitude: 50.217994, longitude: 14.307286 },
  { name: 'PR633', latitude: 50.218142, longitude: 14.616953 },
  { name: 'KT668', latitude: 50.221833, longitude: 19.194694 },
  { name: 'KK572', latitude: 50.223417, longitude: 19.546194 },
  { name: 'KK559', latitude: 50.223472, longitude: 20.189556 },
  { name: 'KV296', latitude: 50.226339, longitude: 12.829847 },
  { name: 'HAFAF', latitude: 50.226667, longitude: 16.212778 },
  { name: 'D291D', latitude: 50.226961, longitude: 12.830231 },
  { name: 'D141J', latitude: 50.227344, longitude: 14.773208 },
  { name: 'PR634', latitude: 50.228917, longitude: 13.687233 },
  { name: 'LUVOD', latitude: 50.229167, longitude: 19.094167 },
  { name: '24THR', latitude: 50.229794, longitude: 14.318403 },
  { name: '36DME', latitude: 50.229986, longitude: 14.317839 },
  { name: 'PR815', latitude: 50.230494, longitude: 13.990511 },
  { name: 'RZ751', latitude: 50.230944, longitude: 22.479417 },
  { name: 'BADOK', latitude: 50.233611, longitude: 21.675278 },
  { name: 'VO283', latitude: 50.233875, longitude: 14.647075 },
  { name: 'CD12', latitude: 50.236503, longitude: 14.012875 },
  { name: 'VP060', latitude: 50.237778, longitude: 12.928889 },
  { name: 'RZ513', latitude: 50.24025, longitude: 21.705722 },
  { name: 'VO10F', latitude: 50.240572, longitude: 14.254764 },
  { name: 'KK561', latitude: 50.240583, longitude: 20.316333 },
  { name: 'FQ10', latitude: 50.240756, longitude: 14.254883 },
  { name: 'D141I', latitude: 50.241, longitude: 14.758358 },
  { name: 'OSMAT', latitude: 50.242778, longitude: 23.045556 },
  { name: 'PR962', latitude: 50.245108, longitude: 13.959939 },
  { name: 'VP036', latitude: 50.246111, longitude: 21.786667 },
  { name: 'KK802', latitude: 50.247056, longitude: 19.934889 },
  { name: 'TELDU', latitude: 50.248056, longitude: 22.737778 },
  { name: 'VP037', latitude: 50.249167, longitude: 22.121944 },
  { name: 'D075I', latitude: 50.249233, longitude: 14.611772 },
  { name: 'PR13I', latitude: 50.252628, longitude: 13.944194 },
  { name: 'GOTAP', latitude: 50.255, longitude: 31.82 },
  { name: 'LULAP', latitude: 50.255278, longitude: 32.353056 },
  { name: 'VO103', latitude: 50.255814, longitude: 14.164364 },
  { name: 'FQ11', latitude: 50.256631, longitude: 12.717314 },
  { name: 'KV11G', latitude: 50.256642, longitude: 12.718306 },
  { name: 'PR637', latitude: 50.261289, longitude: 14.763447 },
  { name: 'CELOV', latitude: 50.263056, longitude: 15.932222 },
  { name: 'VP038', latitude: 50.263333, longitude: 21.459167 },
  { name: 'NURGO', latitude: 50.264444, longitude: 12.016389 },
  { name: 'VP039', latitude: 50.264722, longitude: 19.434722 },
  { name: 'KV297', latitude: 50.265319, longitude: 12.686456 },
  { name: 'RZ533', latitude: 50.266722, longitude: 22.376 },
  { name: 'XENGO', latitude: 50.267972, longitude: 20.464028 },
  { name: 'VP061', latitude: 50.268333, longitude: 14.239167 },
  { name: 'D086S', latitude: 50.268567, longitude: 31.381311 },
  { name: 'ERASU', latitude: 50.268753, longitude: 14.478142 },
  { name: 'DESEN', latitude: 50.27, longitude: 17.516944 },
  { name: 'KKE02', latitude: 50.270139, longitude: 31.368056 },
  { name: 'KK554', latitude: 50.275389, longitude: 19.907583 },
  { name: 'BALOS', latitude: 50.275556, longitude: 19.658333 },
  { name: 'VP040', latitude: 50.276111, longitude: 21.605556 },
  { name: 'VO430', latitude: 50.276583, longitude: 14.398119 },
  { name: 'UTORO', latitude: 50.276642, longitude: 14.551158 },
  { name: 'EVEMI', latitude: 50.277461, longitude: 14.109411 },
  { name: 'RZ633', latitude: 50.281139, longitude: 22.069722 },
  { name: 'VP062', latitude: 50.281667, longitude: 12.938611 },
  { name: 'RZ468', latitude: 50.281889, longitude: 21.257889 },
  { name: 'KV110', latitude: 50.282028, longitude: 12.890997 },
  { name: 'PR712', latitude: 50.283286, longitude: 14.525558 },
  { name: 'KT514', latitude: 50.285278, longitude: 20.182278 },
  { name: 'NAVEK', latitude: 50.287092, longitude: 12.964581 },
  { name: 'PR531', latitude: 50.287769, longitude: 14.542372 },
  { name: 'INSAB', latitude: 50.289722, longitude: 18.853333 },
  { name: 'NIDIP', latitude: 50.290556, longitude: 21.612778 },
  { name: 'VARIK', latitude: 50.291111, longitude: 12.381111 },
  { name: 'PR517', latitude: 50.293461, longitude: 14.252483 },
  { name: 'ROTVA', latitude: 50.293531, longitude: 12.582131 },
  { name: 'SULOV', latitude: 50.295628, longitude: 14.568292 },
  { name: 'QM200', latitude: 50.297222, longitude: 12.096856 },
  { name: 'KT516', latitude: 50.298361, longitude: 19.961389 },
  { name: 'EPLUZ', latitude: 50.299167, longitude: 22.326667 },
  { name: 'ELVEP', latitude: 50.3, longitude: 19.161389 },
  { name: 'KT517', latitude: 50.30025, longitude: 19.640306 },
  { name: 'ULNOD', latitude: 50.300389, longitude: 19.770667 },
  { name: 'LIGDI', latitude: 50.300833, longitude: 19.553611 },
  { name: 'KT518', latitude: 50.301056, longitude: 19.509861 },
  { name: 'KT519', latitude: 50.301639, longitude: 19.379667 },
  { name: 'GOBUN', latitude: 50.301667, longitude: 37.64 },
  { name: 'KT751', latitude: 50.303278, longitude: 18.905583 },
  { name: 'KKS01', latitude: 50.303889, longitude: 30.515556 },
  { name: 'RZ411', latitude: 50.304917, longitude: 21.336667 },
  { name: 'RZ441', latitude: 50.305722, longitude: 22.01 },
  { name: 'KK834', latitude: 50.307083, longitude: 19.161194 },
  { name: 'KK840', latitude: 50.307222, longitude: 30.203889 },
  { name: 'BB672', latitude: 50.307778, longitude: 30.719444 },
  { name: 'PR406', latitude: 50.311008, longitude: 13.933269 },
  { name: 'MD36R', latitude: 50.311739, longitude: 30.902778 },
  { name: 'PR961', latitude: 50.311839, longitude: 14.037583 },
  { name: 'VO102', latitude: 50.312175, longitude: 14.18745 },
  { name: 'NALEG', latitude: 50.313333, longitude: 36.485 },
  { name: 'ROXUG', latitude: 50.314167, longitude: 20.7725 },
  { name: 'NOVGA', latitude: 50.315, longitude: 18.461667 },
  { name: 'D270O', latitude: 50.315506, longitude: 30.513569 },
  { name: 'RANOM', latitude: 50.316667, longitude: 27.816667 },
  { name: 'ELPIN', latitude: 50.316667, longitude: 36.408333 },
  { name: 'KUBOK', latitude: 50.316667, longitude: 36.828333 },
  { name: 'KT659', latitude: 50.316861, longitude: 18.40025 },
  { name: 'KK611', latitude: 50.317861, longitude: 19.768583 },
  { name: 'VP042', latitude: 50.318056, longitude: 19.237222 },
  { name: 'ORSOX', latitude: 50.319722, longitude: 18.537222 },
  { name: 'KK630', latitude: 50.329167, longitude: 30.714722 },
  { name: 'KV293', latitude: 50.332139, longitude: 13.009267 },
  { name: 'QM017', latitude: 50.332469, longitude: 12.108647 },
  { name: 'CEPRO', latitude: 50.333333, longitude: 15.724167 },
  { name: 'KV298', latitude: 50.336431, longitude: 12.761889 },
  { name: 'PR858', latitude: 50.337119, longitude: 14.507617 },
  { name: 'KT672', latitude: 50.339, longitude: 18.476861 },
  { name: 'MODEZ', latitude: 50.339444, longitude: 35.368333 },
  { name: 'ENORU', latitude: 50.340239, longitude: 16.370231 },
  { name: 'KT662', latitude: 50.341944, longitude: 18.872639 },
  { name: 'PR407', latitude: 50.343489, longitude: 14.041164 },
  { name: 'KV294', latitude: 50.343797, longitude: 13.359494 },
  { name: 'PEROX', latitude: 50.344808, longitude: 12.199581 },
  { name: 'LOKSO', latitude: 50.345278, longitude: 33.955556 },
  { name: 'ADVEX', latitude: 50.348333, longitude: 17.869722 },
  { name: 'VP044', latitude: 50.35, longitude: 20.033333 },
  { name: 'UPKIS', latitude: 50.350278, longitude: 17.998333 },
  { name: 'PR816', latitude: 50.351561, longitude: 13.981633 },
  { name: 'KV119', latitude: 50.352869, longitude: 12.989075 },
  { name: 'VP043', latitude: 50.353889, longitude: 18.866389 },
  { name: 'KV111', latitude: 50.354881, longitude: 12.622508 },
  { name: 'PLAUN', latitude: 50.355, longitude: 12.0075 },
  { name: 'FORMA', latitude: 50.355, longitude: 35.698333 },
  { name: 'RZ749', latitude: 50.356389, longitude: 22.499361 },
  { name: 'PR621', latitude: 50.365972, longitude: 14.139917 },
  { name: 'INVAD', latitude: 50.366667, longitude: 31.270556 },
  { name: 'EPOPA', latitude: 50.368056, longitude: 17.799722 },
  { name: 'KK315', latitude: 50.368611, longitude: 30.255278 },
  { name: 'MD18L', latitude: 50.369206, longitude: 30.907181 },
  { name: 'D317F', latitude: 50.370092, longitude: 30.803675 },
  { name: 'PR957', latitude: 50.370425, longitude: 14.447294 },
  { name: 'SUPIL', latitude: 50.373247, longitude: 12.813511 },
  { name: 'PR818', latitude: 50.374622, longitude: 14.171006 },
  { name: 'PR516', latitude: 50.375475, longitude: 14.529125 },
  { name: 'NUMBA', latitude: 50.375556, longitude: 18.108611 },
  { name: 'KKE01', latitude: 50.379444, longitude: 30.910556 },
  { name: 'D317G', latitude: 50.379464, longitude: 30.792842 },
  { name: 'VP045', latitude: 50.382778, longitude: 21.458889 },
  { name: 'KT761', latitude: 50.383806, longitude: 19.579194 },
  { name: 'KT838', latitude: 50.385361, longitude: 19.282111 },
  { name: 'ODSOB', latitude: 50.386083, longitude: 19.032194 },
  { name: 'KT783', latitude: 50.386222, longitude: 19.354028 },
  { name: 'PR711', latitude: 50.386244, longitude: 14.6394 },
  { name: 'KT526', latitude: 50.386833, longitude: 19.641722 },
  { name: 'KT524', latitude: 50.387639, longitude: 19.511278 },
  { name: 'BBE03', latitude: 50.388056, longitude: 31.353611 },
  { name: 'KT784', latitude: 50.388194, longitude: 19.128861 },
  { name: 'KT523', latitude: 50.388222, longitude: 19.381083 },
  { name: 'PEKOT', latitude: 50.388333, longitude: 17.443333 },
  { name: 'KT522', latitude: 50.388944, longitude: 19.245917 },
  { name: 'KT521', latitude: 50.389333, longitude: 19.115694 },
  { name: 'KT788', latitude: 50.389611, longitude: 18.644611 },
  { name: 'KT787', latitude: 50.38975, longitude: 18.775056 },
  { name: 'KT786', latitude: 50.389861, longitude: 18.90525 },
  { name: 'KK820', latitude: 50.390694, longitude: 30.195 },
  { name: 'VEXIL', latitude: 50.392308, longitude: 12.633511 },
  { name: 'CF08', latitude: 50.393931, longitude: 30.272856 },
  { name: 'KVW12', latitude: 50.393947, longitude: 12.781814 },
  { name: 'VP046', latitude: 50.394722, longitude: 18.629167 },
  { name: 'KK105', latitude: 50.395, longitude: 30.297778 },
  { name: 'FF08', latitude: 50.395092, longitude: 30.298839 },
  { name: 'ETITI', latitude: 50.395833, longitude: 21.219722 },
  { name: 'BBN02', latitude: 50.3975, longitude: 30.909167 },
  { name: 'PR13B', latitude: 50.399428, longitude: 14.18575 },
  { name: 'BOBLU', latitude: 50.399722, longitude: 21.909722 },
  { name: 'D083I', latitude: 50.400203, longitude: 30.642286 },
  { name: 'MA08', latitude: 50.400839, longitude: 30.429547 },
  { name: 'KEDUB', latitude: 50.401667, longitude: 30.451667 },
  { name: 'MA26', latitude: 50.402683, longitude: 30.472581 },
  { name: 'VP047', latitude: 50.403611, longitude: 19.055 },
  { name: '15LOC', latitude: 50.403892, longitude: 30.501172 },
  { name: 'ROLKA', latitude: 50.404167, longitude: 23.825556 },
  { name: 'BBW01', latitude: 50.404722, longitude: 30.288333 },
  { name: 'PR622', latitude: 50.406106, longitude: 14.098528 },
  { name: 'FF26', latitude: 50.408061, longitude: 30.601258 },
  { name: 'KT373', latitude: 50.408667, longitude: 18.626778 },
  { name: 'PR632', latitude: 50.40915, longitude: 14.284286 },
  { name: 'KK305', latitude: 50.409722, longitude: 30.634722 },
  { name: 'KK620', latitude: 50.412639, longitude: 30.706111 },
  { name: 'BULEK', latitude: 50.413239, longitude: 15.065306 },
  { name: 'D034J', latitude: 50.415678, longitude: 31.077994 },
  { name: 'SL32A', latitude: 50.415811, longitude: 30.312561 },
  { name: 'RZ438', latitude: 50.419889, longitude: 22.327028 },
  { name: 'SL32C', latitude: 50.420814, longitude: 30.329033 },
  { name: 'APSUD', latitude: 50.426667, longitude: 37.987222 },
  { name: 'VP048', latitude: 50.4275, longitude: 21.330556 },
  { name: 'NUKVO', latitude: 50.429722, longitude: 28.206944 },
  { name: 'BAGRU', latitude: 50.431839, longitude: 14.337092 },
  { name: 'PR819', latitude: 50.431994, longitude: 14.654433 },
  { name: 'VP049', latitude: 50.434722, longitude: 21.06 },
  { name: 'KONAR', latitude: 50.445139, longitude: 13.065578 },
  { name: 'VP050', latitude: 50.453889, longitude: 19.081389 },
  { name: 'LAPVA', latitude: 50.456389, longitude: 28.6925 },
  { name: 'AJPIF', latitude: 50.456944, longitude: 19.480056 },
  { name: 'OB293', latitude: 50.460092, longitude: 36.826872 },
  { name: 'KT586', latitude: 50.461861, longitude: 19.389111 },
  { name: 'AKNUC', latitude: 50.462667, longitude: 18.676333 },
  { name: 'INPIV', latitude: 50.465278, longitude: 20.045556 },
  { name: 'DORER', latitude: 50.465833, longitude: 27.207778 },
  { name: 'KT764', latitude: 50.466139, longitude: 18.767444 },
  { name: 'YUJKI', latitude: 50.468333, longitude: 13.566389 },
  { name: 'KT571', latitude: 50.468722, longitude: 19.259111 },
  { name: 'KT771', latitude: 50.470972, longitude: 18.897667 },
  { name: 'KT529', latitude: 50.473389, longitude: 19.643139 },
  { name: '45VOR', latitude: 50.473528, longitude: 18.967889 },
  { name: 'KT417', latitude: 50.473861, longitude: 19.575056 },
  { name: 'VAKNU', latitude: 50.474167, longitude: 19.150278 },
  { name: 'KK830', latitude: 50.474167, longitude: 30.186111 },
  { name: 'PABWO', latitude: 50.474222, longitude: 19.512694 },
  { name: 'BB456', latitude: 50.474722, longitude: 31.166389 },
  { name: 'TOZIZ', latitude: 50.474944, longitude: 19.38225 },
  { name: 'KT837', latitude: 50.475389, longitude: 19.282972 },
  { name: 'KT536', latitude: 50.475472, longitude: 19.259639 },
  { name: 'KT779', latitude: 50.475889, longitude: 18.513833 },
  { name: 'ML27', latitude: 50.475917, longitude: 19.113389 },
  { name: 'MA27', latitude: 50.475917, longitude: 19.119861 },
  { name: 'KT739', latitude: 50.476056, longitude: 18.592139 },
  { name: 'MA09', latitude: 50.476083, longitude: 19.039833 },
  { name: 'EZWOP', latitude: 50.476167, longitude: 18.644278 },
  { name: 'KT398', latitude: 50.476278, longitude: 18.764 },
  { name: 'KT644', latitude: 50.476278, longitude: 18.874694 },
  { name: 'KT736', latitude: 50.476278, longitude: 18.897333 },
  { name: 'ONZIL', latitude: 50.476306, longitude: 18.774722 },
  { name: 'KT411', latitude: 50.47675, longitude: 18.751361 },
  { name: 'KILNU', latitude: 50.476944, longitude: 12.911667 },
  { name: 'SL32B', latitude: 50.478453, longitude: 30.279128 },
  { name: 'BB454', latitude: 50.478611, longitude: 31.035278 },
  { name: 'FF18R', latitude: 50.481722, longitude: 30.891 },
  { name: 'BBN04', latitude: 50.484444, longitude: 31.101111 },
  { name: 'D307Q', latitude: 50.485328, longitude: 30.576225 },
  { name: 'VELAX', latitude: 50.488889, longitude: 22.520556 },
  { name: 'FF18L', latitude: 50.490983, longitude: 30.916103 },
  { name: 'D325N', latitude: 50.491942, longitude: 30.728958 },
  { name: 'KT581', latitude: 50.492083, longitude: 18.793694 },
  { name: 'CF33', latitude: 50.492108, longitude: 30.272475 },
  { name: 'BB510', latitude: 50.495, longitude: 30.891944 },
  { name: 'KK650', latitude: 50.496111, longitude: 30.6975 },
  { name: 'BB674', latitude: 50.496944, longitude: 30.555 },
  { name: 'VP051', latitude: 50.497222, longitude: 19.079167 },
  { name: 'KUZBO', latitude: 50.501111, longitude: 32.886111 },
  { name: 'BB515', latitude: 50.501389, longitude: 30.775556 },
  { name: 'BB710', latitude: 50.501389, longitude: 30.916667 },
  { name: 'TALOB', latitude: 50.505833, longitude: 34.041389 },
  { name: 'GAVLI', latitude: 50.506167, longitude: 13.138242 },
  { name: 'MOKOS', latitude: 50.507222, longitude: 19.356111 },
  { name: 'SLV45', latitude: 50.513733, longitude: 30.637061 },
  { name: 'D151E', latitude: 50.515733, longitude: 26.22685 },
  { name: 'ODPAL', latitude: 50.517131, longitude: 13.774389 },
  { name: 'FF33', latitude: 50.52235, longitude: 30.250428 },
  { name: 'MAPIK', latitude: 50.526111, longitude: 18.2475 },
  { name: 'BBE04', latitude: 50.531389, longitude: 31.307778 },
  { name: 'BBW02', latitude: 50.532778, longitude: 30.662778 },
  { name: 'BODRO', latitude: 50.533056, longitude: 35.764722 },
  { name: 'MYSKO', latitude: 50.536389, longitude: 19.279444 },
  { name: 'D130E', latitude: 50.540653, longitude: 26.258075 },
  { name: 'D304W', latitude: 50.540961, longitude: 30.438517 },
  { name: 'BB452', latitude: 50.545, longitude: 31.040278 },
  { name: 'WR824', latitude: 50.546806, longitude: 17.163833 },
  { name: 'LESOF', latitude: 50.548056, longitude: 30.920278 },
  { name: 'RISVI', latitude: 50.548611, longitude: 30.895833 },
  { name: 'KT507', latitude: 50.54875, longitude: 18.697167 },
  { name: 'LEMBI', latitude: 50.550794, longitude: 14.881281 },
  { name: 'BBN03', latitude: 50.551389, longitude: 31.023611 },
  { name: 'BB468', latitude: 50.552778, longitude: 30.775833 },
  { name: 'VENOX', latitude: 50.554986, longitude: 14.133992 },
  { name: 'KK631', latitude: 50.555167, longitude: 19.81675 },
  { name: 'GILEV', latitude: 50.556667, longitude: 30.644722 },
  { name: 'SOPIM', latitude: 50.5575, longitude: 32.486944 },
  { name: 'GOSAK', latitude: 50.557778, longitude: 20.304444 },
  { name: 'OB292', latitude: 50.559036, longitude: 36.847283 },
  { name: 'KT508', latitude: 50.559917, longitude: 18.9325 },
  { name: 'KT542', latitude: 50.560778, longitude: 19.514111 },
  { name: 'KT759', latitude: 50.561583, longitude: 19.353639 },
  { name: 'KT534', latitude: 50.561583, longitude: 19.383444 },
  { name: 'KT839', latitude: 50.561861, longitude: 19.283833 },
  { name: 'KT533', latitude: 50.562389, longitude: 19.252778 },
  { name: 'KT763', latitude: 50.562417, longitude: 19.132056 },
  { name: 'KT778', latitude: 50.562472, longitude: 18.5135 },
  { name: 'KT769', latitude: 50.562611, longitude: 18.644167 },
  { name: 'KT768', latitude: 50.562722, longitude: 18.774861 },
  { name: 'KT767', latitude: 50.562861, longitude: 18.905556 },
  { name: 'KT509', latitude: 50.563167, longitude: 19.122083 },
  { name: 'PR409', latitude: 50.563186, longitude: 14.783869 },
  { name: 'CD29', latitude: 50.563206, longitude: 36.834878 },
  { name: 'ELGOX', latitude: 50.564722, longitude: 18.300833 },
  { name: 'CQ29', latitude: 50.565244, longitude: 36.831683 },
  { name: 'KONZI', latitude: 50.566111, longitude: 20.617778 },
  { name: 'GOPSI', latitude: 50.566306, longitude: 13.569061 },
  { name: 'VP052', latitude: 50.566667, longitude: 21.875 },
  { name: 'FQ29', latitude: 50.569275, longitude: 26.254478 },
  { name: 'CF29', latitude: 50.5756, longitude: 36.797233 },
  { name: 'CQ29Y', latitude: 50.576411, longitude: 36.797936 },
  { name: 'LULIN', latitude: 50.578056, longitude: 21.129444 },
  { name: 'VP053', latitude: 50.579167, longitude: 22.119722 },
  { name: 'KK832', latitude: 50.581306, longitude: 19.924611 },
  { name: 'WEXLE', latitude: 50.581389, longitude: 12.133333 },
  { name: 'POBOK', latitude: 50.583056, longitude: 20.218889 },
  { name: 'VP054', latitude: 50.583333, longitude: 19.058333 },
  { name: 'GOLKU', latitude: 50.583889, longitude: 29.414722 },
  { name: 'GOLOP', latitude: 50.585761, longitude: 14.495731 },
  { name: '27DME', latitude: 50.585772, longitude: 26.205844 },
  { name: 'LUKOR', latitude: 50.586667, longitude: 20.204444 },
  { name: 'FQ14', latitude: 50.586697, longitude: 30.265483 },
  { name: 'OB301', latitude: 50.587461, longitude: 36.761386 },
  { name: 'VP055', latitude: 50.593889, longitude: 23.174444 },
  { name: 'FN29', latitude: 50.596706, longitude: 36.735236 },
  { name: 'FD29', latitude: 50.596944, longitude: 36.730278 },
  { name: 'FQ29', latitude: 50.597778, longitude: 36.730833 },
  { name: 'FI29', latitude: 50.598056, longitude: 36.728611 },
  { name: 'GUMSI', latitude: 50.598056, longitude: 39.616667 },
  { name: 'OB298', latitude: 50.598183, longitude: 36.728706 },
  { name: 'NOKSI', latitude: 50.598333, longitude: 12.961389 },
  { name: 'OB291', latitude: 50.598397, longitude: 36.728239 },
  { name: 'OB109', latitude: 50.600297, longitude: 36.722525 },
  { name: 'OB403', latitude: 50.603228, longitude: 36.343436 },
  { name: 'BB470', latitude: 50.603611, longitude: 30.579722 },
  { name: 'NIBIM', latitude: 50.605556, longitude: 40.770278 },
  { name: 'RODAK', latitude: 50.606389, longitude: 20.162778 },
  { name: 'KAFEL', latitude: 50.606944, longitude: 29.280556 },
  { name: 'OSNON', latitude: 50.609722, longitude: 17.949722 },
  { name: 'BB450', latitude: 50.611389, longitude: 31.045278 },
  { name: 'ELVOT', latitude: 50.611725, longitude: 16.409208 },
  { name: 'D086R', latitude: 50.611983, longitude: 31.362386 },
  { name: 'DODOD', latitude: 50.614722, longitude: 20.158611 },
  { name: 'BB436', latitude: 50.615278, longitude: 30.913056 },
  { name: 'OBOSO', latitude: 50.616667, longitude: 21.7625 },
  { name: 'BB466', latitude: 50.619167, longitude: 30.780833 },
  { name: 'OB107', latitude: 50.619803, longitude: 36.663211 },
  { name: 'VP056', latitude: 50.623611, longitude: 18.836111 },
  { name: '22DME', latitude: 50.623675, longitude: 26.093747 },
  { name: 'D003U', latitude: 50.625136, longitude: 30.995069 },
  { name: 'D086C', latitude: 50.625978, longitude: 30.970356 },
  { name: 'OB294', latitude: 50.626403, longitude: 36.96295 },
  { name: 'UREKO', latitude: 50.629444, longitude: 22.708611 },
  { name: 'ABKIS', latitude: 50.629694, longitude: 13.057186 },
  { name: 'D266B', latitude: 50.6304, longitude: 30.838117 },
  { name: 'KURON', latitude: 50.631667, longitude: 20.148056 },
  { name: 'OB296', latitude: 50.634333, longitude: 36.61905 },
  { name: 'SLV50', latitude: 50.635811, longitude: 30.669817 },
  { name: 'OLKOM', latitude: 50.642778, longitude: 34.114444 },
  { name: 'FQ11', latitude: 50.645581, longitude: 26.028733 },
  { name: 'KT503', latitude: 50.646389, longitude: 19.646472 },
  { name: 'KOTEK', latitude: 50.647222, longitude: 20.251111 },
  { name: 'KT504', latitude: 50.647333, longitude: 19.515556 },
  { name: 'KT531', latitude: 50.648139, longitude: 19.384861 },
  { name: 'KT532', latitude: 50.648944, longitude: 19.254194 },
  { name: 'KT762', latitude: 50.65075, longitude: 18.565222 },
  { name: 'MD11', latitude: 50.651994, longitude: 36.561861 },
  { name: 'OB106', latitude: 50.652817, longitude: 36.562747 },
  { name: 'SUDOL', latitude: 50.653333, longitude: 18.411667 },
  { name: 'KT651', latitude: 50.654472, longitude: 18.503722 },
  { name: 'KT741', latitude: 50.656167, longitude: 18.5595 },
  { name: 'KT861', latitude: 50.656361, longitude: 18.642861 },
  { name: 'KT648', latitude: 50.656639, longitude: 18.696028 },
  { name: 'KT766', latitude: 50.657222, longitude: 18.905194 },
  { name: 'WR732', latitude: 50.657361, longitude: 17.541833 },
  { name: 'VETEP', latitude: 50.658333, longitude: 30.186111 },
  { name: 'LEGAZ', latitude: 50.660192, longitude: 14.801339 },
  { name: 'KT844', latitude: 50.661139, longitude: 19.114667 },
  { name: 'KT843', latitude: 50.662583, longitude: 19.314472 },
  { name: 'VP057', latitude: 50.663333, longitude: 23.195556 },
  { name: 'OB103', latitude: 50.66495, longitude: 36.202281 },
  { name: 'VP058', latitude: 50.665278, longitude: 21.868333 },
  { name: 'VP059', latitude: 50.671667, longitude: 19.205 },
  { name: 'D304G', latitude: 50.671797, longitude: 26.032292 },
  { name: 'OB297', latitude: 50.675508, longitude: 36.493342 },
  { name: 'OB295', latitude: 50.675786, longitude: 36.812072 },
  { name: 'D289E', latitude: 50.677633, longitude: 36.482458 },
  { name: 'KK660', latitude: 50.677778, longitude: 30.55 },
  { name: 'BB448', latitude: 50.677778, longitude: 31.050278 },
  { name: 'PISOK', latitude: 50.68, longitude: 29.666667 },
  { name: 'D289F', latitude: 50.6814, longitude: 36.470775 },
  { name: 'BB438', latitude: 50.681667, longitude: 30.918056 },
  { name: 'OB302', latitude: 50.683203, longitude: 36.839575 },
  { name: 'FF15', latitude: 50.684039, longitude: 30.130167 },
  { name: 'ARTUP', latitude: 50.685236, longitude: 14.905103 },
  { name: 'BB464', latitude: 50.685556, longitude: 30.785833 },
  { name: 'OB101', latitude: 50.687389, longitude: 36.457108 },
  { name: 'FD11', latitude: 50.688333, longitude: 36.451389 },
  { name: 'OB108', latitude: 50.688664, longitude: 36.453061 },
  { name: 'FQ11', latitude: 50.689167, longitude: 36.452222 },
  { name: 'FN11', latitude: 50.689339, longitude: 36.450139 },
  { name: 'UPMAK', latitude: 50.690278, longitude: 19.723611 },
  { name: 'D321G', latitude: 50.694667, longitude: 26.072847 },
  { name: 'VP060', latitude: 50.698056, longitude: 22.083611 },
  { name: 'OB401', latitude: 50.699194, longitude: 36.420917 },
  { name: 'OLMUK', latitude: 50.700278, longitude: 16.942222 },
  { name: 'SLV36', latitude: 50.700981, longitude: 30.057542 },
  { name: 'LUTIK', latitude: 50.703333, longitude: 30.395 },
  { name: 'CD11Y', latitude: 50.704722, longitude: 36.398292 },
  { name: 'OKROT', latitude: 50.706944, longitude: 27.348889 },
  { name: 'CQ11Y', latitude: 50.709531, longitude: 36.387439 },
  { name: 'OBALU', latitude: 50.710833, longitude: 20.109444 },
  { name: 'WR636', latitude: 50.713056, longitude: 17.35775 },
  { name: 'CF15', latitude: 50.714011, longitude: 30.107325 },
  { name: 'KT502', latitude: 50.715083, longitude: 19.819972 },
  { name: 'MAREM', latitude: 50.715467, longitude: 13.628831 },
  { name: 'CQ11', latitude: 50.716069, longitude: 36.367097 },
  { name: 'VP061', latitude: 50.718056, longitude: 23.032778 },
  { name: 'KT654', latitude: 50.720639, longitude: 19.0405 },
  { name: 'VP062', latitude: 50.721944, longitude: 23.133611 },
  { name: 'CD11', latitude: 50.727222, longitude: 36.331667 },
  { name: 'SLV50', latitude: 50.729064, longitude: 30.567117 },
  { name: 'KT649', latitude: 50.7325, longitude: 18.670139 },
  { name: 'OB102', latitude: 50.732925, longitude: 36.317283 },
  { name: 'ATMEV', latitude: 50.733056, longitude: 17.503056 },
  { name: 'BRP38', latitude: 50.737558, longitude: 30.210261 },
  { name: 'BB446', latitude: 50.744167, longitude: 31.055278 },
  { name: 'OB303', latitude: 50.745267, longitude: 36.651008 },
  { name: 'EKRUL', latitude: 50.746111, longitude: 21.361111 },
  { name: 'KT506', latitude: 50.747389, longitude: 18.824056 },
  { name: 'BB440', latitude: 50.748056, longitude: 30.923056 },
  { name: 'KUROS', latitude: 50.748333, longitude: 31.796667 },
  { name: 'VP063', latitude: 50.750833, longitude: 23.240833 },
  { name: 'BB462', latitude: 50.751944, longitude: 30.790833 },
  { name: 'ASDES', latitude: 50.753611, longitude: 37.430278 },
  { name: 'VP064', latitude: 50.756944, longitude: 16.539722 },
  { name: 'VABOD', latitude: 50.758333, longitude: 24.795 },
  { name: 'IRGAM', latitude: 50.761667, longitude: 21.781389 },
  { name: 'KT846', latitude: 50.765972, longitude: 19.019361 },
  { name: 'RAVKU', latitude: 50.766814, longitude: 14.428853 },
  { name: 'DC428', latitude: 50.766858, longitude: 13.105731 },
  { name: 'SOGPI', latitude: 50.769444, longitude: 17.563333 },
  { name: 'SABAB', latitude: 50.770278, longitude: 17.899167 },
  { name: 'LOBMU', latitude: 50.770861, longitude: 19.628444 },
  { name: 'OLKOL', latitude: 50.782222, longitude: 22.9 },
  { name: 'OB105', latitude: 50.782361, longitude: 36.487947 },
  { name: 'NEVAP', latitude: 50.786944, longitude: 22.566111 },
  { name: 'LEMBO', latitude: 50.789722, longitude: 35.871389 },
  { name: 'AC430', latitude: 50.790653, longitude: 12.258353 },
  { name: 'ELRIS', latitude: 50.791667, longitude: 37.733889 },
  { name: 'ALEBU', latitude: 50.793206, longitude: 13.745764 },
  { name: 'OB402', latitude: 50.795128, longitude: 36.498664 },
  { name: 'LAGAR', latitude: 50.795275, longitude: 15.367089 },
  { name: 'VP065', latitude: 50.801944, longitude: 20.806111 },
  { name: 'EDOLI', latitude: 50.803611, longitude: 36.190833 },
  { name: 'LALUK', latitude: 50.804408, longitude: 13.880214 },
  { name: 'KUKAM', latitude: 50.806111, longitude: 20.060556 },
  { name: 'BB444', latitude: 50.810556, longitude: 31.060278 },
  { name: 'VP066', latitude: 50.811667, longitude: 16.641389 },
  { name: 'BB442', latitude: 50.814444, longitude: 30.928056 },
  { name: 'VP067', latitude: 50.8175, longitude: 16.497222 },
  { name: 'BB460', latitude: 50.818333, longitude: 30.795833 },
  { name: 'KUKID', latitude: 50.818611, longitude: 35.662222 },
  { name: 'DOGIG', latitude: 50.823611, longitude: 22.086111 },
  { name: 'LIBGA', latitude: 50.825278, longitude: 22.211667 },
  { name: 'UTALU', latitude: 50.825278, longitude: 36.740833 },
  { name: 'MOGRI', latitude: 50.828333, longitude: 34.216667 },
  { name: 'VP068', latitude: 50.828611, longitude: 19.289722 },
  { name: 'DC450', latitude: 50.82905, longitude: 13.569003 },
  { name: 'VP069', latitude: 50.829444, longitude: 15.707222 },
  { name: 'OB104', latitude: 50.831936, longitude: 36.336753 },
  { name: 'USTIL', latitude: 50.833333, longitude: 24.1 },
  { name: 'NANIR', latitude: 50.833333, longitude: 30.261667 },
  { name: 'POBIG', latitude: 50.835, longitude: 21.719167 },
  { name: 'KK833', latitude: 50.835472, longitude: 19.916694 },
  { name: 'XELET', latitude: 50.8375, longitude: 17.125833 },
  { name: 'OMELO', latitude: 50.837914, longitude: 14.000758 },
  { name: 'ETNEL', latitude: 50.840833, longitude: 17.156944 },
  { name: 'GAVDU', latitude: 50.841111, longitude: 22.069722 },
  { name: 'VP070', latitude: 50.841389, longitude: 20.641944 },
  { name: 'MOTAB', latitude: 50.842217, longitude: 12.324978 },
  { name: 'AC420', latitude: 50.843325, longitude: 12.156539 },
  { name: 'BBN01', latitude: 50.844722, longitude: 31.378611 },
  { name: 'WR722', latitude: 50.845444, longitude: 17.744333 },
  { name: 'VP071', latitude: 50.845556, longitude: 20.857222 },
  { name: 'FF08', latitude: 50.847586, longitude: 34.624094 },
  { name: 'LUMAT', latitude: 50.85, longitude: 35.433333 },
  { name: 'WR731', latitude: 50.851861, longitude: 17.366333 },
  { name: 'SUMKA', latitude: 50.853333, longitude: 34.76 },
  { name: 'DEVEG', latitude: 50.8575, longitude: 17.510556 },
  { name: 'BALBA', latitude: 50.861667, longitude: 23.023333 },
  { name: 'PEPOR', latitude: 50.863333, longitude: 40.901389 },
  { name: 'ASTEL', latitude: 50.86445, longitude: 14.860456 },
  { name: 'KKN01', latitude: 50.864722, longitude: 30.556667 },
  { name: 'LUMUL', latitude: 50.866389, longitude: 37.681667 },
  { name: 'FF26', latitude: 50.867722, longitude: 34.890989 },
  { name: 'IPSOD', latitude: 50.868333, longitude: 17.432778 },
  { name: 'ALUGU', latitude: 50.868333, longitude: 40.088611 },
  { name: 'NATEV', latitude: 50.868889, longitude: 22.171111 },
  { name: 'VAGDU', latitude: 50.872222, longitude: 21.364444 },
  { name: 'VP072', latitude: 50.873056, longitude: 16.613056 },
  { name: 'WR823', latitude: 50.873861, longitude: 16.577139 },
  { name: 'VP073', latitude: 50.874722, longitude: 15.92 },
  { name: 'DC451', latitude: 50.879525, longitude: 13.637733 },
  { name: '80DME', latitude: 50.880806, longitude: 12.376547 },
  { name: 'WR841', latitude: 50.881333, longitude: 17.444833 },
  { name: 'OGVET', latitude: 50.883333, longitude: 22.477778 },
  { name: 'VP074', latitude: 50.883889, longitude: 15.811111 },
  { name: 'AC225', latitude: 50.885514, longitude: 12.381078 },
  { name: 'WR741', latitude: 50.888722, longitude: 17.248361 },
  { name: 'PIKIB', latitude: 50.893333, longitude: 13.709167 },
  { name: 'VP075', latitude: 50.894444, longitude: 16.772778 },
  { name: 'BAREX', latitude: 50.894444, longitude: 18.738333 },
  { name: 'DC430', latitude: 50.897181, longitude: 13.443828 },
  { name: 'DEKOV', latitude: 50.897353, longitude: 14.216386 },
  { name: 'AC222', latitude: 50.900131, longitude: 12.312569 },
  { name: 'VP076', latitude: 50.9025, longitude: 19.0675 },
  { name: 'AC221', latitude: 50.903103, longitude: 12.403903 },
  { name: 'WR821', latitude: 50.905167, longitude: 16.865889 },
  { name: 'VP077', latitude: 50.908611, longitude: 20.579722 },
  { name: 'LB717', latitude: 50.909167, longitude: 22.220556 },
  { name: 'APTAS', latitude: 50.911389, longitude: 17.695833 },
  { name: 'WR743', latitude: 50.912028, longitude: 16.490583 },
  { name: 'NEGUV', latitude: 50.914722, longitude: 20.115833 },
  { name: 'ABALU', latitude: 50.919167, longitude: 37.285556 },
  { name: 'LB474', latitude: 50.919444, longitude: 22.276472 },
  { name: 'AC427', latitude: 50.919914, longitude: 12.42575 },
  { name: 'LB377', latitude: 50.920361, longitude: 22.233111 },
  { name: 'VP078', latitude: 50.921667, longitude: 19.356667 },
  { name: 'WR742', latitude: 50.925444, longitude: 17.130222 },
  { name: 'DC431', latitude: 50.927533, longitude: 13.485058 },
  { name: 'DINOV', latitude: 50.928611, longitude: 16.085556 },
  { name: 'VP079', latitude: 50.929722, longitude: 16.903056 },
  { name: 'DC452', latitude: 50.929958, longitude: 13.706617 },
  { name: 'RIKLU', latitude: 50.931111, longitude: 13.811111 },
  { name: '40DM2', latitude: 50.932639, longitude: 12.442731 },
  { name: 'WR721', latitude: 50.933917, longitude: 17.430583 },
  { name: 'USEGI', latitude: 50.935194, longitude: 22.675472 },
  { name: 'BESIP', latitude: 50.935278, longitude: 13.485278 },
  { name: 'RELNO', latitude: 50.937222, longitude: 26.080833 },
  { name: 'BEFRE', latitude: 50.938333, longitude: 14.698889 },
  { name: 'VP080', latitude: 50.938333, longitude: 15.909167 },
  { name: 'WR642', latitude: 50.938861, longitude: 16.422083 },
  { name: 'DC231', latitude: 50.940864, longitude: 13.606456 },
  { name: 'ARGIX', latitude: 50.945, longitude: 21.972222 },
  { name: 'TUVOG', latitude: 50.946389, longitude: 30.977778 },
  { name: 'NIMOM', latitude: 50.946667, longitude: 17.505278 },
  { name: 'LB476', latitude: 50.950278, longitude: 22.444944 },
  { name: 'WR634', latitude: 50.950972, longitude: 17.047778 },
  { name: 'TOMTI', latitude: 50.953056, longitude: 15.293333 },
  { name: 'VP081', latitude: 50.955, longitude: 16.616944 },
  { name: 'LB513', latitude: 50.958056, longitude: 22.250556 },
  { name: 'VP082', latitude: 50.961111, longitude: 15.796389 },
  { name: 'VP083', latitude: 50.963611, longitude: 15.645 },
  { name: 'DC420', latitude: 50.964283, longitude: 13.319828 },
  { name: 'EKPEK', latitude: 50.964494, longitude: 13.32135 },
  { name: 'AC224', latitude: 50.965828, longitude: 12.341069 },
  { name: 'ROTOL', latitude: 50.965833, longitude: 16.290833 },
  { name: 'VP084', latitude: 50.966667, longitude: 19.277222 },
  { name: 'ARPOD', latitude: 50.97, longitude: 30.641944 },
  { name: 'ROROL', latitude: 50.97, longitude: 35.650278 },
  { name: 'D090Y', latitude: 50.970406, longitude: 13.171236 },
  { name: 'AC226', latitude: 50.970636, longitude: 13.165356 },
  { name: 'WR704', latitude: 50.970833, longitude: 17.312444 },
  { name: 'WR822', latitude: 50.970889, longitude: 16.651139 },
  { name: 'MQ04', latitude: 50.971603, longitude: 12.492608 },
  { name: 'RUDUB', latitude: 50.973056, longitude: 16.067222 },
  { name: 'LB621', latitude: 50.97325, longitude: 22.573417 },
  { name: 'VP085', latitude: 50.974722, longitude: 20.660833 },
  { name: 'VP086', latitude: 50.977778, longitude: 16.790833 },
  { name: 'LB713', latitude: 50.980694, longitude: 22.548278 },
  { name: 'DC493', latitude: 50.981764, longitude: 13.385006 },
  { name: 'DC230', latitude: 50.982425, longitude: 13.643464 },
  { name: 'LB477', latitude: 50.984111, longitude: 22.631722 },
  { name: 'DC229', latitude: 50.984564, longitude: 13.763319 },
  { name: 'ORTUL', latitude: 50.985278, longitude: 25.003889 },
  { name: 'ROKBU', latitude: 50.985556, longitude: 36.279722 },
  { name: 'WR653', latitude: 50.985639, longitude: 17.264806 },
  { name: 'CF04', latitude: 50.985933, longitude: 13.564592 },
  { name: 'ROGAL', latitude: 50.986944, longitude: 19.485556 },
  { name: 'LB387', latitude: 50.987083, longitude: 22.308028 },
  { name: 'VP087', latitude: 50.989444, longitude: 16.834167 },
  { name: 'D254G', latitude: 50.989983, longitude: 13.4225 },
  { name: 'LB714', latitude: 50.992194, longitude: 22.350028 },
  { name: 'D113P', latitude: 50.993364, longitude: 17.240069 },
  { name: 'PIRAP', latitude: 50.993839, longitude: 13.981786 },
  { name: 'DC460', latitude: 50.997719, longitude: 13.988608 },
  { name: 'GOMPA', latitude: 50.998278, longitude: 13.581453 },
  { name: 'MONOV', latitude: 50.998333, longitude: 22.371944 },
  { name: 'FD04', latitude: 50.999461, longitude: 13.575939 },
  { name: '5113E', latitude: 51.0, longitude: 13.0 },
  { name: '5114E', latitude: 51.0, longitude: 14.0 },
  { name: '5115E', latitude: 51.0, longitude: 15.0 },
  { name: '5116E', latitude: 51.0, longitude: 16.0 },
  { name: '5117E', latitude: 51.0, longitude: 17.0 },
  { name: '5118E', latitude: 51.0, longitude: 18.0 },
  { name: '5119E', latitude: 51.0, longitude: 19.0 },
  { name: '5120E', latitude: 51.0, longitude: 20.0 },
  { name: '5121E', latitude: 51.0, longitude: 21.0 },
  { name: '5122E', latitude: 51.0, longitude: 22.0 },
  { name: '5123E', latitude: 51.0, longitude: 23.0 },
  { name: '5124E', latitude: 51.0, longitude: 24.0 },
  { name: '5125E', latitude: 51.0, longitude: 25.0 },
  { name: 'LUTSK', latitude: 51.0, longitude: 25.5 },
  { name: '5126E', latitude: 51.0, longitude: 26.0 },
  { name: '5127E', latitude: 51.0, longitude: 27.0 },
  { name: '5128E', latitude: 51.0, longitude: 28.0 },
  { name: '5129E', latitude: 51.0, longitude: 29.0 },
  { name: '5130E', latitude: 51.0, longitude: 30.0 },
  { name: '5131E', latitude: 51.0, longitude: 31.0 },
  { name: '5132E', latitude: 51.0, longitude: 32.0 },
  { name: '5133E', latitude: 51.0, longitude: 33.0 },
  { name: '5134E', latitude: 51.0, longitude: 34.0 },
  { name: '5135E', latitude: 51.0, longitude: 35.0 },
  { name: '5136E', latitude: 51.0, longitude: 36.0 },
  { name: '5137E', latitude: 51.0, longitude: 37.0 },
  { name: '5138E', latitude: 51.0, longitude: 38.0 },
  { name: '5139E', latitude: 51.0, longitude: 39.0 },
  { name: '5140E', latitude: 51.0, longitude: 40.0 },
  { name: 'DC225', latitude: 51.003289, longitude: 13.664531 },
  { name: 'GODLO', latitude: 51.003889, longitude: 16.504167 },
  { name: 'LB716', latitude: 51.005556, longitude: 22.112222 },
  { name: 'WR393', latitude: 51.006333, longitude: 17.198472 },
  { name: 'WR703', latitude: 51.007611, longitude: 17.194139 },
  { name: 'BAKSU', latitude: 51.007778, longitude: 39.662778 },
  { name: 'NAVUR', latitude: 51.013056, longitude: 19.911111 },
  { name: 'DP552', latitude: 51.013458, longitude: 12.262444 },
  { name: 'RASAN', latitude: 51.013753, longitude: 15.094667 },
  { name: 'DC421', latitude: 51.014906, longitude: 13.388489 },
  { name: 'AC046', latitude: 51.015061, longitude: 13.099336 },
  { name: 'IXURO', latitude: 51.017778, longitude: 15.543889 },
  { name: 'VP088', latitude: 51.018611, longitude: 23.370833 },
  { name: 'OBOVI', latitude: 51.021417, longitude: 16.48475 },
  { name: 'WR751', latitude: 51.022639, longitude: 16.814667 },
  { name: 'WR633', latitude: 51.023889, longitude: 16.810694 },
  { name: 'DC453', latitude: 51.025078, longitude: 13.837103 },
  { name: 'BRP47', latitude: 51.027208, longitude: 31.294064 },
  { name: 'VP089', latitude: 51.028611, longitude: 23.220833 },
  { name: 'WR832', latitude: 51.031444, longitude: 16.35375 },
  { name: 'LB514', latitude: 51.031667, longitude: 22.364722 },
  { name: 'D141U', latitude: 51.035139, longitude: 17.440064 },
  { name: '40DME', latitude: 51.036072, longitude: 12.575378 },
  { name: 'WR702', latitude: 51.036333, longitude: 17.101389 },
  { name: 'WR392', latitude: 51.036444, longitude: 17.101333 },
  { name: 'GOT27', latitude: 51.038842, longitude: 12.113142 },
  { name: 'LB388', latitude: 51.041111, longitude: 22.370917 },
  { name: 'LODBO', latitude: 51.042778, longitude: 21.880833 },
  { name: 'VP173', latitude: 51.045, longitude: 13.815 },
  { name: 'KUSUL', latitude: 51.046389, longitude: 17.069167 },
  { name: 'LB608', latitude: 51.046944, longitude: 22.208333 },
  { name: 'AC223', latitude: 51.046992, longitude: 12.077831 },
  { name: 'FD29Y', latitude: 51.047306, longitude: 17.066389 },
  { name: 'WR701', latitude: 51.047333, longitude: 17.065778 },
  { name: 'WR391', latitude: 51.047444, longitude: 17.065778 },
  { name: 'DC226', latitude: 51.0478, longitude: 13.644781 },
  { name: 'VP090', latitude: 51.048333, longitude: 16.613333 },
  { name: 'AC422', latitude: 51.048408, longitude: 12.593339 },
  { name: 'CY059', latitude: 51.048747, longitude: 31.173608 },
  { name: 'ORTOG', latitude: 51.05, longitude: 35.21 },
  { name: 'DC224', latitude: 51.050211, longitude: 13.557975 },
  { name: 'WR802', latitude: 51.052611, longitude: 17.048694 },
  { name: 'WR706', latitude: 51.052917, longitude: 17.376778 },
  { name: 'DC454', latitude: 51.056842, longitude: 13.880844 },
  { name: 'WR752', latitude: 51.058889, longitude: 16.695833 },
  { name: 'LB607', latitude: 51.060056, longitude: 22.359694 },
  { name: 'ETESI', latitude: 51.060556, longitude: 14.1525 },
  { name: 'NITMO', latitude: 51.060556, longitude: 39.82 },
  { name: 'DC491', latitude: 51.062878, longitude: 13.593961 },
  { name: 'DC429', latitude: 51.063458, longitude: 13.211719 },
  { name: 'LB383', latitude: 51.063833, longitude: 22.583611 },
  { name: 'DC422', latitude: 51.065489, longitude: 13.457303 },
  { name: 'ESLOR', latitude: 51.065833, longitude: 14.179444 },
  { name: '50DME', latitude: 51.066369, longitude: 13.671547 },
  { name: 'LB386', latitude: 51.067528, longitude: 22.152806 },
  { name: 'WR801', latitude: 51.069778, longitude: 16.993 },
  { name: '40LOC', latitude: 51.070997, longitude: 16.989006 },
  { name: '70DME', latitude: 51.074867, longitude: 12.625336 },
  { name: 'VP091', latitude: 51.075, longitude: 16.883889 },
  { name: '42LOC', latitude: 51.075428, longitude: 13.687042 },
  { name: 'ABDON', latitude: 51.075833, longitude: 37.406389 },
  { name: 'LB606', latitude: 51.077056, longitude: 22.559417 },
  { name: 'GIGAL', latitude: 51.078611, longitude: 16.491944 },
  { name: 'AC041', latitude: 51.082106, longitude: 12.637572 },
  { name: '14VO2', latitude: 51.082444, longitude: 16.952194 },
  { name: '14VOR', latitude: 51.082722, longitude: 16.951417 },
  { name: 'WR753', latitude: 51.088806, longitude: 16.558472 },
  { name: 'OSKEX', latitude: 51.089444, longitude: 17.569722 },
  { name: 'VP092', latitude: 51.090278, longitude: 22.287778 },
  { name: '30DME', latitude: 51.091086, longitude: 13.706964 },
  { name: 'ML29', latitude: 51.092556, longitude: 16.918861 },
  { name: 'TALEG', latitude: 51.092778, longitude: 12.619722 },
  { name: 'DC492', latitude: 51.093872, longitude: 13.537703 },
  { name: 'GEPTO', latitude: 51.094444, longitude: 30.831389 },
  { name: 'ULGIL', latitude: 51.098611, longitude: 16.02 },
  { name: 'UNBIB', latitude: 51.099444, longitude: 13.529444 },
  { name: 'ENOMO', latitude: 51.099722, longitude: 36.174722 },
  { name: 'VP093', latitude: 51.100833, longitude: 22.582778 },
  { name: 'DC221', latitude: 51.102342, longitude: 13.722708 },
  { name: 'WR632', latitude: 51.103944, longitude: 16.547667 },
  { name: 'OVBOR', latitude: 51.104167, longitude: 20.019167 },
  { name: 'LB384', latitude: 51.105417, longitude: 22.479917 },
  { name: 'RUBLU', latitude: 51.107778, longitude: 39.9625 },
  { name: 'MEBUP', latitude: 51.108056, longitude: 36.884722 },
  { name: 'AC045', latitude: 51.108536, longitude: 12.517228 },
  { name: 'RIVSA', latitude: 51.108539, longitude: 13.366739 },
  { name: 'LASID', latitude: 51.110217, longitude: 12.674492 },
  { name: 'AC044', latitude: 51.1103, longitude: 12.6746 },
  { name: 'LB712', latitude: 51.11275, longitude: 22.683806 },
  { name: 'MD04', latitude: 51.115792, longitude: 13.742417 },
  { name: 'VP094', latitude: 51.115833, longitude: 22.761944 },
  { name: 'VP179', latitude: 51.116667, longitude: 13.564722 },
  { name: 'ML04', latitude: 51.117106, longitude: 13.744278 },
  { name: 'IVNER', latitude: 51.118056, longitude: 23.857222 },
  { name: 'LB389', latitude: 51.118278, longitude: 22.461056 },
  { name: 'LOBMA', latitude: 51.119625, longitude: 14.559769 },
  { name: 'BUSIR', latitude: 51.120789, longitude: 14.330883 },
  { name: 'VP095', latitude: 51.121389, longitude: 23.229167 },
  { name: 'CI22', latitude: 51.122814, longitude: 12.691039 },
  { name: 'WR901', latitude: 51.123639, longitude: 16.817278 },
  { name: 'DP540', latitude: 51.124925, longitude: 12.000419 },
  { name: 'VP096', latitude: 51.125833, longitude: 16.903056 },
  { name: 'EKSEK', latitude: 51.128333, longitude: 15.610833 },
  { name: 'WR362', latitude: 51.129806, longitude: 17.438194 },
  { name: 'VP097', latitude: 51.13, longitude: 22.836944 },
  { name: 'DC222', latitude: 51.131514, longitude: 13.620161 },
  { name: 'WR621', latitude: 51.134861, longitude: 17.112639 },
  { name: 'DP473', latitude: 51.136475, longitude: 12.040383 },
  { name: 'WR601', latitude: 51.13725, longitude: 16.772694 },
  { name: 'WR491', latitude: 51.137333, longitude: 16.772778 },
  { name: 'WR631', latitude: 51.139944, longitude: 16.428444 },
  { name: 'KEPED', latitude: 51.141389, longitude: 39.345556 },
  { name: 'LB516', latitude: 51.141667, longitude: 22.536667 },
  { name: 'AKASO', latitude: 51.141681, longitude: 12.578878 },
  { name: 'AB458', latitude: 51.143139, longitude: 14.714892 },
  { name: 'RASUT', latitude: 51.145556, longitude: 16.259167 },
  { name: 'DP474', latitude: 51.14765, longitude: 12.274864 },
  { name: 'WR602', latitude: 51.148139, longitude: 16.736972 },
  { name: 'WR492', latitude: 51.148194, longitude: 16.737028 },
  { name: 'VENES', latitude: 51.148889, longitude: 21.910556 },
  { name: 'MAKOV', latitude: 51.149167, longitude: 19.111944 },
  { name: 'D018K', latitude: 51.150069, longitude: 12.616453 },
  { name: 'ML22', latitude: 51.150167, longitude: 13.789789 },
  { name: 'AC042', latitude: 51.150172, longitude: 12.605564 },
  { name: 'MD22', latitude: 51.151503, longitude: 13.791631 },
  { name: 'DP475', latitude: 51.152797, longitude: 12.386439 },
  { name: 'VP098', latitude: 51.155556, longitude: 16.958889 },
  { name: 'LUXPU', latitude: 51.155556, longitude: 19.693611 },
  { name: 'OBIPO', latitude: 51.1575, longitude: 35.635556 },
  { name: 'DP476', latitude: 51.158281, longitude: 12.507967 },
  { name: 'VP185', latitude: 51.158333, longitude: 13.971389 },
  { name: 'AB430', latitude: 51.159436, longitude: 14.339786 },
  { name: 'VP099', latitude: 51.160556, longitude: 22.945278 },
  { name: 'DC423', latitude: 51.160892, longitude: 13.587656 },
  { name: 'ANIGI', latitude: 51.161389, longitude: 37.473056 },
  { name: 'AC439', latitude: 51.161542, longitude: 12.742053 },
  { name: 'DC455', latitude: 51.161922, longitude: 14.026136 },
  { name: 'SULED', latitude: 51.161944, longitude: 12.23 },
  { name: 'DP477', latitude: 51.162947, longitude: 12.613683 },
  { name: 'BEVOS', latitude: 51.163611, longitude: 21.238611 },
  { name: 'DC458', latitude: 51.163625, longitude: 13.983825 },
  { name: 'LB421', latitude: 51.163667, longitude: 22.060528 },
  { name: 'NUDRO', latitude: 51.165278, longitude: 17.325556 },
  { name: 'REMVU', latitude: 51.165556, longitude: 15.2325 },
  { name: 'AB459', latitude: 51.166989, longitude: 14.843006 },
  { name: 'DP478', latitude: 51.167517, longitude: 12.719422 },
  { name: 'VP100', latitude: 51.167778, longitude: 23.35 },
  { name: 'NOSKU', latitude: 51.168611, longitude: 15.871389 },
  { name: 'LB479', latitude: 51.170528, longitude: 22.882083 },
  { name: 'TORPU', latitude: 51.170789, longitude: 13.210525 },
  { name: 'TEXTI', latitude: 51.171111, longitude: 14.605278 },
  { name: 'LB471', latitude: 51.171389, longitude: 22.086972 },
  { name: 'AB022', latitude: 51.171483, longitude: 14.403178 },
  { name: 'YAWOY', latitude: 51.171944, longitude: 12.825278 },
  { name: 'WR754', latitude: 51.174111, longitude: 16.233639 },
  { name: 'IVQOQ', latitude: 51.174444, longitude: 13.337222 },
  { name: 'LB509', latitude: 51.175, longitude: 22.138333 },
  { name: 'VP101', latitude: 51.177778, longitude: 22.528333 },
  { name: 'OSGAD', latitude: 51.177778, longitude: 40.014444 },
  { name: 'LB711', latitude: 51.178167, longitude: 22.860306 },
  { name: 'VP188', latitude: 51.178333, longitude: 13.67 },
  { name: 'D009J', latitude: 51.178369, longitude: 13.653953 },
  { name: 'LB422', latitude: 51.179583, longitude: 22.224528 },
  { name: 'VP102', latitude: 51.18, longitude: 17.1925 },
  { name: 'GOMOR', latitude: 51.183333, longitude: 17.617222 },
  { name: 'RIRAB', latitude: 51.183333, longitude: 40.192222 },
  { name: 'LULED', latitude: 51.184722, longitude: 36.043056 },
  { name: 'MISKA', latitude: 51.185833, longitude: 16.934444 },
  { name: 'AB021', latitude: 51.185894, longitude: 14.479294 },
  { name: 'WR603', latitude: 51.186444, longitude: 16.610806 },
  { name: 'WR493', latitude: 51.186472, longitude: 16.610833 },
  { name: 'LB423', latitude: 51.1905, longitude: 22.338556 },
  { name: 'SEZIN', latitude: 51.191111, longitude: 17.914444 },
  { name: 'EPILA', latitude: 51.192753, longitude: 13.631353 },
  { name: '42DME', latitude: 51.192806, longitude: 13.8505 },
  { name: 'DC480', latitude: 51.193011, longitude: 13.850031 },
  { name: '42LO2', latitude: 51.193389, longitude: 13.849422 },
  { name: 'LB391', latitude: 51.194833, longitude: 22.400472 },
  { name: 'DC041', latitude: 51.195383, longitude: 13.9304 },
  { name: 'LIPVO', latitude: 51.195606, longitude: 12.362489 },
  { name: 'LB511', latitude: 51.198333, longitude: 22.342778 },
  { name: 'LB394', latitude: 51.199306, longitude: 22.431111 },
  { name: 'SUTUS', latitude: 51.199722, longitude: 35.281389 },
  { name: 'DOTEX', latitude: 51.200278, longitude: 21.862222 },
  { name: 'WR902', latitude: 51.202556, longitude: 16.557583 },
  { name: 'NAROX', latitude: 51.203056, longitude: 15.010556 },
  { name: 'LB481', latitude: 51.206917, longitude: 22.971278 },
  { name: 'DC495', latitude: 51.207369, longitude: 13.663414 },
  { name: 'MAXEB', latitude: 51.207425, longitude: 12.23125 },
  { name: 'WR711', latitude: 51.209917, longitude: 17.481556 },
  { name: 'VP191', latitude: 51.211667, longitude: 13.733333 },
  { name: 'DC232', latitude: 51.212083, longitude: 13.364764 },
  { name: 'DC456', latitude: 51.212122, longitude: 14.095875 },
  { name: 'LB472', latitude: 51.212861, longitude: 22.418222 },
  { name: 'LB398', latitude: 51.212972, longitude: 22.525028 },
  { name: 'LB392', latitude: 51.214361, longitude: 22.534361 },
  { name: 'AC429', latitude: 51.214642, longitude: 12.639972 },
  { name: 'ULGOS', latitude: 51.215278, longitude: 21.371667 },
  { name: 'LB611', latitude: 51.217056, longitude: 22.553139 },
  { name: 'GIMAP', latitude: 51.219167, longitude: 23.095833 },
  { name: '44THR', latitude: 51.221111, longitude: 22.582283 },
  { name: 'LB361', latitude: 51.22125, longitude: 22.582167 },
  { name: 'VP103', latitude: 51.221667, longitude: 22.741944 },
  { name: 'LB512', latitude: 51.222222, longitude: 22.558333 },
  { name: 'WR762', latitude: 51.222333, longitude: 16.492222 },
  { name: 'WR604', latitude: 51.222528, longitude: 16.491389 },
  { name: 'WR361', latitude: 51.222944, longitude: 17.269611 },
  { name: 'LUBEN', latitude: 51.224167, longitude: 19.9575 },
  { name: 'LB487', latitude: 51.225722, longitude: 22.613139 },
  { name: 'DC459', latitude: 51.226381, longitude: 14.070953 },
  { name: 'ABGEG', latitude: 51.226389, longitude: 16.431667 },
  { name: 'BATIX', latitude: 51.227997, longitude: 14.703472 },
  { name: 'DC410', latitude: 51.228972, longitude: 13.564447 },
  { name: 'FD22', latitude: 51.229736, longitude: 13.903267 },
  { name: 'OKILO', latitude: 51.230861, longitude: 13.901247 },
  { name: 'LB601', latitude: 51.231222, longitude: 22.651278 },
  { name: 'AB071', latitude: 51.234456, longitude: 14.738108 },
  { name: 'DP463', latitude: 51.236108, longitude: 12.028103 },
  { name: 'VAGNI', latitude: 51.238778, longitude: 15.984139 },
  { name: 'CF22', latitude: 51.243317, longitude: 13.918836 },
  { name: 'GUPMA', latitude: 51.244444, longitude: 17.018333 },
  { name: 'ML25', latitude: 51.245361, longitude: 22.749917 },
  { name: 'LB612', latitude: 51.245667, longitude: 22.377139 },
  { name: 'DC494', latitude: 51.246928, longitude: 13.781375 },
  { name: 'AB439', latitude: 51.24695, longitude: 14.805297 },
  { name: 'VP104', latitude: 51.247222, longitude: 21.238889 },
  { name: 'DP464', latitude: 51.247306, longitude: 12.263089 },
  { name: 'VP106', latitude: 51.25, longitude: 16.841667 },
  { name: 'VP105', latitude: 51.250833, longitude: 21.079167 },
  { name: 'LB707', latitude: 51.250889, longitude: 22.16675 },
  { name: 'DP465', latitude: 51.252467, longitude: 12.374908 },
  { name: 'LB701', latitude: 51.257056, longitude: 22.831917 },
  { name: 'LB486', latitude: 51.257944, longitude: 22.838 },
  { name: 'FI25', latitude: 51.257944, longitude: 22.838 },
  { name: 'DP466', latitude: 51.257961, longitude: 12.4967 },
  { name: 'WR904', latitude: 51.258167, longitude: 17.078333 },
  { name: 'D104R', latitude: 51.258683, longitude: 12.061683 },
  { name: 'LB702', latitude: 51.2605, longitude: 22.856222 },
  { name: 'MAG45', latitude: 51.262097, longitude: 12.01455 },
  { name: 'DC457', latitude: 51.262281, longitude: 14.165767 },
  { name: 'CI25Z', latitude: 51.263439, longitude: 22.876706 },
  { name: 'CI25Y', latitude: 51.263475, longitude: 22.876964 },
  { name: 'DP962', latitude: 51.263536, longitude: 12.958047 },
  { name: '74VOR', latitude: 51.263719, longitude: 22.876786 },
  { name: 'AC043', latitude: 51.264703, longitude: 12.013778 },
  { name: 'D116U', latitude: 51.26555, longitude: 12.960956 },
  { name: 'WR607', latitude: 51.268917, longitude: 16.674167 },
  { name: 'LB396', latitude: 51.271778, longitude: 22.935972 },
  { name: 'VP107', latitude: 51.273056, longitude: 21.460278 },
  { name: 'LB483', latitude: 51.273389, longitude: 22.947528 },
  { name: 'DC044', latitude: 51.274733, longitude: 13.936808 },
  { name: 'LB484', latitude: 51.276389, longitude: 22.968 },
  { name: 'RENDO', latitude: 51.278056, longitude: 13.884722 },
  { name: 'VP108', latitude: 51.280278, longitude: 22.703889 },
  { name: 'LB613', latitude: 51.280889, longitude: 22.264389 },
  { name: 'DC436', latitude: 51.281119, longitude: 13.970942 },
  { name: 'LOGDA', latitude: 51.284444, longitude: 20.693889 },
  { name: 'DP453', latitude: 51.285936, longitude: 12.021939 },
  { name: 'VP193', latitude: 51.288333, longitude: 12.133333 },
  { name: 'VADOL', latitude: 51.289722, longitude: 22.1025 },
  { name: 'LB706', latitude: 51.290333, longitude: 22.39575 },
  { name: 'OSTRA', latitude: 51.290833, longitude: 12.905278 },
  { name: 'PIDAT', latitude: 51.293333, longitude: 18.483333 },
  { name: 'OSKAR', latitude: 51.298056, longitude: 16.909167 },
  { name: 'DC425', latitude: 51.298144, longitude: 13.776503 },
  { name: 'VP109', latitude: 51.299444, longitude: 19.658611 },
  { name: 'WR811', latitude: 51.299639, longitude: 17.596917 },
  { name: 'SOPUK', latitude: 51.3, longitude: 19.083333 },
  { name: 'VP110', latitude: 51.301389, longitude: 17.0625 },
  { name: 'TOLPA', latitude: 51.302778, longitude: 23.645833 },
  { name: 'VP111', latitude: 51.303889, longitude: 22.870278 },
  { name: 'LB603', latitude: 51.30425, longitude: 22.455306 },
  { name: 'WR606', latitude: 51.305028, longitude: 16.554583 },
  { name: 'D186O', latitude: 51.305667, longitude: 19.095389 },
  { name: 'EVGUR', latitude: 51.306111, longitude: 19.6125 },
  { name: 'LB473', latitude: 51.306111, longitude: 22.563083 },
  { name: 'VAGON', latitude: 51.308056, longitude: 17.114444 },
  { name: 'VP112', latitude: 51.310556, longitude: 21.252778 },
  { name: 'DP991', latitude: 51.310617, longitude: 12.148028 },
  { name: 'DP457', latitude: 51.312497, longitude: 12.597111 },
  { name: 'TOGRO', latitude: 51.3125, longitude: 12.408333 },
  { name: 'WR712', latitude: 51.315556, longitude: 17.5525 },
  { name: 'KURAV', latitude: 51.315833, longitude: 20.746667 },
  { name: 'LB704', latitude: 51.316056, longitude: 22.579028 },
  { name: 'OLBEG', latitude: 51.316944, longitude: 40.600833 },
  { name: 'DP458', latitude: 51.317081, longitude: 12.703194 },
  { name: 'RODKO', latitude: 51.318, longitude: 13.828736 },
  { name: 'LB602', latitude: 51.318083, longitude: 22.564 },
  { name: 'DP459', latitude: 51.321567, longitude: 12.8093 },
  { name: 'VP113', latitude: 51.328333, longitude: 22.996944 },
  { name: 'DC437', latitude: 51.331375, longitude: 14.040844 },
  { name: 'VAGSA', latitude: 51.333056, longitude: 17.555833 },
  { name: 'DP858', latitude: 51.333703, longitude: 12.701383 },
  { name: 'DP548', latitude: 51.337453, longitude: 12.219828 },
  { name: 'LB482', latitude: 51.339806, longitude: 22.923722 },
  { name: 'LB703', latitude: 51.341639, longitude: 22.826889 },
  { name: 'VP114', latitude: 51.343333, longitude: 19.376389 },
  { name: 'VP115', latitude: 51.345556, longitude: 22.640556 },
  { name: 'DC426', latitude: 51.348497, longitude: 13.846172 },
  { name: 'VP116', latitude: 51.349722, longitude: 22.345 },
  { name: 'WR608', latitude: 51.351889, longitude: 16.735889 },
  { name: 'IDOKA', latitude: 51.353333, longitude: 39.851667 },
  { name: 'BEBEX', latitude: 51.353717, longitude: 13.194264 },
  { name: 'WR803', latitude: 51.354694, longitude: 17.210306 },
  { name: 'NUBLI', latitude: 51.358611, longitude: 19.887778 },
  { name: 'DP547', latitude: 51.362253, longitude: 12.28285 },
  { name: 'VP117', latitude: 51.363056, longitude: 22.294167 },
  { name: 'VP197', latitude: 51.363333, longitude: 12.19 },
  { name: 'DP554', latitude: 51.363475, longitude: 12.064208 },
  { name: 'VP118', latitude: 51.366111, longitude: 21.490278 },
  { name: 'TOFRI', latitude: 51.366667, longitude: 37.017778 },
  { name: 'IXOGA', latitude: 51.366944, longitude: 22.460833 },
  { name: 'DP539', latitude: 51.367947, longitude: 12.297342 },
  { name: 'OSLAK', latitude: 51.371667, longitude: 38.884444 },
  { name: 'WR903', latitude: 51.371722, longitude: 16.705944 },
  { name: 'D102N', latitude: 51.375175, longitude: 12.833275 },
  { name: 'VP119', latitude: 51.376667, longitude: 19.819444 },
  { name: 'ABLOX', latitude: 51.380789, longitude: 14.520047 },
  { name: 'PEXUL', latitude: 51.381667, longitude: 19.612778 },
  { name: 'OMAVA', latitude: 51.383889, longitude: 21.688056 },
  { name: 'GARKI', latitude: 51.384406, longitude: 14.116117 },
  { name: 'DP519', latitude: 51.385853, longitude: 12.087317 },
  { name: 'LUGUG', latitude: 51.386944, longitude: 23.003611 },
  { name: 'EVINA', latitude: 51.387778, longitude: 20.36 },
  { name: 'IXUMI', latitude: 51.394722, longitude: 12.850278 },
  { name: 'DP538', latitude: 51.395464, longitude: 12.313506 },
  { name: 'DC427', latitude: 51.398806, longitude: 13.915997 },
  { name: 'VP131', latitude: 51.4, longitude: 20.185833 },
  { name: 'VP120', latitude: 51.403333, longitude: 21.088889 },
  { name: 'DP527', latitude: 51.405794, longitude: 12.082281 },
  { name: '50LOC', latitude: 51.406539, longitude: 12.097839 },
  { name: 'LASIS', latitude: 51.406944, longitude: 14.961667 },
  { name: 'DP553', latitude: 51.407153, longitude: 12.109717 },
  { name: '35LOC', latitude: 51.408439, longitude: 12.137647 },
  { name: 'DP520', latitude: 51.408919, longitude: 12.147733 },
  { name: 'VP123', latitude: 51.41, longitude: 19.634722 },
  { name: 'DP499', latitude: 51.410611, longitude: 12.183447 },
  { name: 'MM08R', latitude: 51.411083, longitude: 12.191594 },
  { name: 'VP121', latitude: 51.411111, longitude: 21.928333 },
  { name: 'VP122', latitude: 51.412222, longitude: 22.053333 },
  { name: 'IRBON', latitude: 51.413889, longitude: 21.59 },
  { name: 'DP546', latitude: 51.414019, longitude: 12.256247 },
  { name: 'MM26L', latitude: 51.414731, longitude: 12.271283 },
  { name: 'SOWEX', latitude: 51.415833, longitude: 21.85 },
  { name: 'DP541', latitude: 51.415867, longitude: 12.296061 },
  { name: 'EGLONgitude', latitude: 51.416667, longitude: 21.984722 },
  { name: 'RETSU', latitude: 51.416667, longitude: 23.490833 },
  { name: '35LO2', latitude: 51.417117, longitude: 12.323264 },
  { name: '49LOC', latitude: 51.418819, longitude: 12.360436 },
  { name: 'GATPO', latitude: 51.4205, longitude: 16.860806 },
  { name: 'MOKUL', latitude: 51.421175, longitude: 12.005144 },
  { name: 'WR707', latitude: 51.422583, longitude: 17.161528 },
  { name: 'D090O', latitude: 51.422586, longitude: 12.86445 },
  { name: 'DP543', latitude: 51.423175, longitude: 12.456769 },
  { name: 'TAKOR', latitude: 51.423403, longitude: 12.461886 },
  { name: 'RAVAD', latitude: 51.424028, longitude: 21.422917 },
  { name: 'UNBEK', latitude: 51.424722, longitude: 20.015278 },
  { name: '38LOC', latitude: 51.426714, longitude: 12.120064 },
  { name: 'DP544', latitude: 51.427175, longitude: 12.546939 },
  { name: 'DP518', latitude: 51.427333, longitude: 12.133033 },
  { name: 'DP515', latitude: 51.427944, longitude: 12.145881 },
  { name: '28LOC', latitude: 51.427978, longitude: 12.146614 },
  { name: 'DP545', latitude: 51.428039, longitude: 12.566561 },
  { name: 'DP447', latitude: 51.428111, longitude: 12.568217 },
  { name: 'AMKAT', latitude: 51.429722, longitude: 36.876389 },
  { name: 'TADUV', latitude: 51.43, longitude: 12.548056 },
  { name: '07LOC', latitude: 51.430033, longitude: 12.202544 },
  { name: 'DP510', latitude: 51.4302, longitude: 12.193667 },
  { name: 'D263A', latitude: 51.4302, longitude: 12.193669 },
  { name: 'D257A', latitude: 51.430431, longitude: 12.194389 },
  { name: 'DP448', latitude: 51.433403, longitude: 12.6905 },
  { name: 'DP537', latitude: 51.433647, longitude: 12.267444 },
  { name: 'MM26R', latitude: 51.434072, longitude: 12.280517 },
  { name: '40LO2', latitude: 51.436633, longitude: 12.332156 },
  { name: 'JOGGA', latitude: 51.4379, longitude: 12.796875 },
  { name: 'CT30Y', latitude: 51.437917, longitude: 22.155139 },
  { name: '53LOC', latitude: 51.438211, longitude: 12.366689 },
  { name: 'SOVAM', latitude: 51.440278, longitude: 16.714444 },
  { name: 'DP963', latitude: 51.441236, longitude: 12.877281 },
  { name: 'IRBOS', latitude: 51.4425, longitude: 19.844167 },
  { name: 'VP124', latitude: 51.443056, longitude: 21.2 },
  { name: 'KOSAM', latitude: 51.443122, longitude: 12.475642 },
  { name: 'DP534', latitude: 51.443436, longitude: 12.482728 },
  { name: 'VP125', latitude: 51.443611, longitude: 19.723333 },
  { name: 'D116L', latitude: 51.443775, longitude: 19.449303 },
  { name: 'REPTU', latitude: 51.444722, longitude: 15.986944 },
  { name: 'VP126', latitude: 51.445, longitude: 20.460278 },
  { name: 'DP535', latitude: 51.445222, longitude: 12.522964 },
  { name: 'DP536', latitude: 51.446183, longitude: 12.544797 },
  { name: 'DP437', latitude: 51.447817, longitude: 12.582019 },
  { name: 'LUNUK', latitude: 51.448889, longitude: 18.0575 },
  { name: 'ROPIK', latitude: 51.450556, longitude: 16.176667 },
  { name: 'DP438', latitude: 51.452414, longitude: 12.688419 },
  { name: 'DP812', latitude: 51.452433, longitude: 12.688453 },
  { name: 'DP521', latitude: 51.453333, longitude: 12.081267 },
  { name: 'LOFTO', latitude: 51.456914, longitude: 12.794839 },
  { name: 'BEBKU', latitude: 51.456944, longitude: 13.870556 },
  { name: 'VP127', latitude: 51.458056, longitude: 18.8375 },
  { name: 'SAWAZ', latitude: 51.458611, longitude: 13.075833 },
  { name: 'UWBAZ', latitude: 51.458611, longitude: 13.168611 },
  { name: 'SORIK', latitude: 51.459722, longitude: 28.743333 },
  { name: 'OSKAN', latitude: 51.460847, longitude: 13.627669 },
  { name: 'GINEX', latitude: 51.463333, longitude: 22.359167 },
  { name: 'CI30Z', latitude: 51.464108, longitude: 22.125658 },
  { name: 'VP206', latitude: 51.465, longitude: 12.216667 },
  { name: 'DP542', latitude: 51.465272, longitude: 12.335983 },
  { name: 'VP128', latitude: 51.465556, longitude: 19.218889 },
  { name: 'BORAT', latitude: 51.466389, longitude: 29.586667 },
  { name: 'WR609', latitude: 51.466556, longitude: 16.828694 },
  { name: 'ZABEL', latitude: 51.466667, longitude: 12.671667 },
  { name: 'SOMOX', latitude: 51.468056, longitude: 22.443056 },
  { name: 'WR611', latitude: 51.468833, longitude: 17.297139 },
  { name: 'DP531', latitude: 51.4694, longitude: 12.316236 },
  { name: 'VELAB', latitude: 51.469722, longitude: 16.748889 },
  { name: 'DP516', latitude: 51.470347, longitude: 12.125292 },
  { name: 'CF30', latitude: 51.470972, longitude: 22.107078 },
  { name: 'RITUN', latitude: 51.471111, longitude: 40.443889 },
  { name: 'AGALU', latitude: 51.471389, longitude: 17.385556 },
  { name: 'VP129', latitude: 51.474722, longitude: 20.006111 },
  { name: 'LASDA', latitude: 51.476389, longitude: 30.261667 },
  { name: 'VP130', latitude: 51.481389, longitude: 21.911389 },
  { name: 'WA821', latitude: 51.48175, longitude: 20.799556 },
  { name: 'ASVEM', latitude: 51.482222, longitude: 21.227222 },
  { name: 'FT30Y', latitude: 51.486389, longitude: 22.046667 },
  { name: 'ENUBU', latitude: 51.486944, longitude: 18.680278 },
  { name: 'LEPKU', latitude: 51.4875, longitude: 40.209722 },
  { name: 'DP481', latitude: 51.488297, longitude: 12.296631 },
  { name: 'MIXAT', latitude: 51.488333, longitude: 18.008056 },
  { name: 'WR612', latitude: 51.489611, longitude: 17.398472 },
  { name: 'CI28Y', latitude: 51.491583, longitude: 19.484444 },
  { name: 'ARMEX', latitude: 51.495556, longitude: 17.123889 },
  { name: 'ORVOG', latitude: 51.495556, longitude: 20.229167 },
  { name: 'NAKER', latitude: 51.496944, longitude: 21.580278 },
  { name: 'NETAB', latitude: 51.4975, longitude: 21.911389 },
  { name: 'D115D', latitude: 51.497797, longitude: 22.034731 },
  { name: 'FF30', latitude: 51.498389, longitude: 22.033861 },
  { name: 'FT30Z', latitude: 51.498889, longitude: 22.018333 },
  { name: 'OGSEN', latitude: 51.499722, longitude: 13.471667 },
  { name: 'CHERV', latitude: 51.5, longitude: 31.5 },
  { name: 'KONTP', latitude: 51.5, longitude: 33.0 },
  { name: 'TIMSO', latitude: 51.500833, longitude: 22.121667 },
  { name: 'CT28', latitude: 51.502083, longitude: 19.489111 },
  { name: 'DP533', latitude: 51.505892, longitude: 12.294986 },
  { name: 'DP971', latitude: 51.506036, longitude: 12.162119 },
  { name: 'NIVNO', latitude: 51.510278, longitude: 19.672222 },
  { name: 'CI28Z', latitude: 51.511883, longitude: 19.381775 },
  { name: 'VP132', latitude: 51.5125, longitude: 19.025556 },
  { name: 'KOBUS', latitude: 51.513333, longitude: 14.2525 },
  { name: 'IRBOL', latitude: 51.517778, longitude: 32.508611 },
  { name: 'VATUP', latitude: 51.519667, longitude: 12.430167 },
  { name: 'VIDEV', latitude: 51.521944, longitude: 19.8025 },
  { name: 'FI28', latitude: 51.522, longitude: 19.330444 },
  { name: 'VP133', latitude: 51.522222, longitude: 20.062778 },
  { name: 'D065N', latitude: 51.522836, longitude: 12.819964 },
  { name: 'VP134', latitude: 51.523611, longitude: 21.866111 },
  { name: 'VP135', latitude: 51.523611, longitude: 22.315833 },
  { name: 'FT28', latitude: 51.526306, longitude: 19.332417 },
  { name: 'VP136', latitude: 51.527222, longitude: 20.226389 },
  { name: 'VP137', latitude: 51.529444, longitude: 20.429167 },
  { name: 'CN29', latitude: 51.530842, longitude: 20.274544 },
  { name: 'DP528', latitude: 51.531914, longitude: 12.082219 },
  { name: 'VP138', latitude: 51.533056, longitude: 19.172222 },
  { name: 'VP139', latitude: 51.535, longitude: 21.761111 },
  { name: 'VP140', latitude: 51.5375, longitude: 21.398889 },
  { name: 'ADIKA', latitude: 51.538333, longitude: 21.329444 },
  { name: 'LUXBO', latitude: 51.539167, longitude: 12.866389 },
  { name: 'DRN42', latitude: 51.541036, longitude: 12.836586 },
  { name: 'MA30', latitude: 51.5415, longitude: 21.918 },
  { name: 'UMKEL', latitude: 51.541944, longitude: 37.308889 },
  { name: 'VP211', latitude: 51.543333, longitude: 12.193333 },
  { name: 'DP974', latitude: 51.543661, longitude: 12.860031 },
  { name: 'NIRIK', latitude: 51.546667, longitude: 37.777222 },
  { name: 'MT30Z', latitude: 51.54725, longitude: 21.909222 },
  { name: 'ROMEG', latitude: 51.5475, longitude: 21.538056 },
  { name: 'FF29', latitude: 51.548333, longitude: 20.203611 },
  { name: 'VP141', latitude: 51.548611, longitude: 21.692222 },
  { name: 'DP424', latitude: 51.548692, longitude: 12.227172 },
  { name: 'DP828', latitude: 51.552111, longitude: 12.677478 },
  { name: 'ETNUS', latitude: 51.552222, longitude: 14.244722 },
  { name: 'DP425', latitude: 51.553886, longitude: 12.339731 },
  { name: 'GOBAX', latitude: 51.556622, longitude: 12.784131 },
  { name: 'MT12Z', latitude: 51.558583, longitude: 21.878167 },
  { name: 'DP426', latitude: 51.559417, longitude: 12.462328 },
  { name: 'DP427', latitude: 51.564125, longitude: 12.568978 },
  { name: 'VP142', latitude: 51.564444, longitude: 19.519167 },
  { name: 'ARFEP', latitude: 51.565556, longitude: 20.445306 },
  { name: 'AKUDI', latitude: 51.566944, longitude: 13.656944 },
  { name: 'D080L', latitude: 51.567303, longitude: 22.214211 },
  { name: 'VP143', latitude: 51.5675, longitude: 22.131111 },
  { name: 'DP428', latitude: 51.568733, longitude: 12.675647 },
  { name: 'OTVUT', latitude: 51.568889, longitude: 40.343611 },
  { name: 'OKENO', latitude: 51.569722, longitude: 18.593889 },
  { name: 'VP144', latitude: 51.57, longitude: 19.188056 },
  { name: 'MILGU', latitude: 51.571944, longitude: 13.029722 },
  { name: 'VIXLO', latitude: 51.572056, longitude: 20.253833 },
  { name: 'DP429', latitude: 51.573244, longitude: 12.782342 },
  { name: 'GOGUS', latitude: 51.58, longitude: 19.633056 },
  { name: 'AGAVA', latitude: 51.581417, longitude: 19.96075 },
  { name: 'UPLEG', latitude: 51.582831, longitude: 12.244033 },
  { name: 'KORUP', latitude: 51.583056, longitude: 14.738056 },
  { name: 'FT10', latitude: 51.584833, longitude: 19.037167 },
  { name: 'RUTUK', latitude: 51.585556, longitude: 22.124444 },
  { name: 'VP145', latitude: 51.586667, longitude: 21.938889 },
  { name: 'UVIVI', latitude: 51.5875, longitude: 17.883333 },
  { name: 'VP146', latitude: 51.589167, longitude: 21.541111 },
  { name: 'ABROK', latitude: 51.593056, longitude: 29.031667 },
  { name: 'FT12Z', latitude: 51.594167, longitude: 21.756667 },
  { name: 'VP147', latitude: 51.598611, longitude: 20.298333 },
  { name: 'TUKPU', latitude: 51.6, longitude: 25.866667 },
  { name: 'VP148', latitude: 51.601111, longitude: 19.921944 },
  { name: 'FT12Y', latitude: 51.603333, longitude: 21.725278 },
  { name: 'ABEXA', latitude: 51.604722, longitude: 20.921667 },
  { name: 'D204K', latitude: 51.604967, longitude: 13.060906 },
  { name: 'SUMAM', latitude: 51.610556, longitude: 22.040833 },
  { name: 'FF11', latitude: 51.612222, longitude: 19.984167 },
  { name: 'KACBU', latitude: 51.614167, longitude: 21.182694 },
  { name: 'POZEL', latitude: 51.615556, longitude: 40.465556 },
  { name: 'TUMIT', latitude: 51.616389, longitude: 37.148056 },
  { name: 'INKOK', latitude: 51.616667, longitude: 16.556389 },
  { name: 'TUFIM', latitude: 51.616667, longitude: 19.372778 },
  { name: 'DP973', latitude: 51.618467, longitude: 12.753433 },
  { name: 'D295E', latitude: 51.619022, longitude: 21.709603 },
  { name: 'CT10', latitude: 51.623417, longitude: 18.880056 },
  { name: 'DP972', latitude: 51.624258, longitude: 12.58045 },
  { name: 'NIMAB', latitude: 51.626111, longitude: 12.0575 },
  { name: 'DIMEX', latitude: 51.630556, longitude: 16.128611 },
  { name: 'GOT40', latitude: 51.634594, longitude: 12.571156 },
  { name: 'VP149', latitude: 51.637778, longitude: 19.271389 },
  { name: 'DP529', latitude: 51.638058, longitude: 12.733614 },
  { name: 'CT12Y', latitude: 51.638583, longitude: 21.604056 },
  { name: 'VP150', latitude: 51.638889, longitude: 19.498611 },
  { name: 'VP151', latitude: 51.639722, longitude: 21.983611 },
  { name: 'DESAR', latitude: 51.64, longitude: 12.042778 },
  { name: 'KULUV', latitude: 51.647778, longitude: 16.339167 },
  { name: 'DP414', latitude: 51.648344, longitude: 12.215192 },
  { name: 'VP152', latitude: 51.650278, longitude: 19.801944 },
  { name: 'NIPUS', latitude: 51.653, longitude: 20.399944 },
  { name: 'DP415', latitude: 51.65355, longitude: 12.327997 },
  { name: 'ELNIK', latitude: 51.653889, longitude: 32.9825 },
  { name: 'XIMBA', latitude: 51.655833, longitude: 21.427778 },
  { name: 'FOJGO', latitude: 51.656944, longitude: 21.538056 },
  { name: 'VP153', latitude: 51.657222, longitude: 20.160556 },
  { name: 'DP416', latitude: 51.659094, longitude: 12.450861 },
  { name: 'D118L', latitude: 51.66055, longitude: 13.471328 },
  { name: 'D231K', latitude: 51.661447, longitude: 12.957781 },
  { name: 'OLBIK', latitude: 51.6625, longitude: 13.341111 },
  { name: 'DP417', latitude: 51.663811, longitude: 12.557744 },
  { name: 'CD07', latitude: 51.664889, longitude: 19.155167 },
  { name: 'VEVAS', latitude: 51.668889, longitude: 12.719167 },
  { name: 'BINPO', latitude: 51.669722, longitude: 16.616667 },
  { name: 'LL721', latitude: 51.670417, longitude: 19.163444 },
  { name: 'CF30', latitude: 51.670764, longitude: 36.486353 },
  { name: 'LL404', latitude: 51.673889, longitude: 19.696667 },
  { name: 'ABAKU', latitude: 51.676944, longitude: 19.081389 },
  { name: 'VP154', latitude: 51.676944, longitude: 20.219444 },
  { name: 'EVMIL', latitude: 51.677222, longitude: 18.763056 },
  { name: 'MN21', latitude: 51.679869, longitude: 39.289606 },
  { name: 'NIKOL', latitude: 51.683333, longitude: 39.748333 },
  { name: 'EMCEL', latitude: 51.685556, longitude: 20.909722 },
  { name: 'FN30', latitude: 51.688056, longitude: 36.444722 },
  { name: 'LORBO', latitude: 51.689444, longitude: 12.205 },
  { name: 'FI30', latitude: 51.689444, longitude: 36.441944 },
  { name: 'VP158', latitude: 51.69, longitude: 18.980833 },
  { name: 'LEGNO', latitude: 51.69, longitude: 21.785556 },
  { name: 'VP155', latitude: 51.690278, longitude: 19.443333 },
  { name: 'VP156', latitude: 51.690556, longitude: 19.133889 },
  { name: 'LL528', latitude: 51.692, longitude: 19.6435 },
  { name: 'LOLKO', latitude: 51.6925, longitude: 15.935 },
  { name: 'LL731', latitude: 51.692611, longitude: 19.264417 },
  { name: 'LL601', latitude: 51.694083, longitude: 19.271111 },
  { name: 'FD07', latitude: 51.69475, longitude: 19.280278 },
  { name: 'LL503', latitude: 51.695083, longitude: 19.274861 },
  { name: 'D118H', latitude: 51.696, longitude: 13.380667 },
  { name: 'VP157', latitude: 51.697222, longitude: 19.888611 },
  { name: 'D025J', latitude: 51.6975, longitude: 22.029361 },
  { name: 'SH420', latitude: 51.698181, longitude: 13.535086 },
  { name: 'LL804', latitude: 51.698194, longitude: 19.727611 },
  { name: 'GIPOS', latitude: 51.698333, longitude: 19.71 },
  { name: 'ODRUX', latitude: 51.698889, longitude: 21.363333 },
  { name: 'XIDNA', latitude: 51.7, longitude: 16.9975 },
  { name: 'VUKLE', latitude: 51.700556, longitude: 40.688333 },
  { name: 'VP219', latitude: 51.702778, longitude: 13.286944 },
  { name: 'PENEM', latitude: 51.709178, longitude: 12.088469 },
  { name: 'VP159', latitude: 51.712222, longitude: 20.036667 },
  { name: 'POZUM', latitude: 51.716389, longitude: 14.681667 },
  { name: 'KIBER', latitude: 51.716389, longitude: 30.631667 },
  { name: 'PETUK', latitude: 51.718333, longitude: 40.189444 },
  { name: 'LL527', latitude: 51.71925, longitude: 19.770083 },
  { name: 'OO302', latitude: 51.720489, longitude: 39.405772 },
  { name: 'LATitudeAG', latitude: 51.721667, longitude: 15.746667 },
  { name: 'BIGLU', latitude: 51.723056, longitude: 23.543056 },
  { name: 'LL706', latitude: 51.7235, longitude: 19.292778 },
  { name: 'PILOK', latitude: 51.723611, longitude: 39.992222 },
  { name: 'ETIPO', latitude: 51.724469, longitude: 12.131336 },
  { name: 'CF30', latitude: 51.725469, longitude: 39.398367 },
  { name: 'ML25', latitude: 51.728028, longitude: 19.426278 },
  { name: 'D243F', latitude: 51.72815, longitude: 12.938092 },
  { name: 'VP160', latitude: 51.728611, longitude: 19.810556 },
  { name: 'BESTO', latitude: 51.73, longitude: 17.398889 },
  { name: 'LELMA', latitude: 51.730567, longitude: 12.789972 },
  { name: 'FF21', latitude: 51.731667, longitude: 39.353333 },
  { name: 'VP161', latitude: 51.731944, longitude: 18.953611 },
  { name: 'UMSIS', latitude: 51.732222, longitude: 39.626667 },
  { name: 'D105M', latitude: 51.733333, longitude: 39.538333 },
  { name: 'SISGO', latitude: 51.735278, longitude: 13.951667 },
  { name: 'LL711', latitude: 51.735917, longitude: 19.126 },
  { name: 'OO201', latitude: 51.737072, longitude: 39.374989 },
  { name: 'VP220', latitude: 51.739444, longitude: 12.949167 },
  { name: 'VP162', latitude: 51.739722, longitude: 21.674722 },
  { name: 'DP522', latitude: 51.741933, longitude: 12.377567 },
  { name: 'ESEGO', latitude: 51.743056, longitude: 38.859444 },
  { name: 'BUKIG', latitude: 51.744167, longitude: 14.463889 },
  { name: 'VP163', latitude: 51.744444, longitude: 19.12 },
  { name: 'LIMVI', latitude: 51.745, longitude: 21.685556 },
  { name: 'WA564', latitude: 51.746611, longitude: 20.878972 },
  { name: 'D340L', latitude: 51.747594, longitude: 21.816622 },
  { name: 'D254F', latitude: 51.748425, longitude: 12.932358 },
  { name: 'VP164', latitude: 51.748611, longitude: 19.35 },
  { name: 'LL532', latitude: 51.748611, longitude: 19.521028 },
  { name: 'TISTA', latitude: 51.748889, longitude: 21.147361 },
  { name: 'BADNO', latitude: 51.75, longitude: 17.678333 },
  { name: 'FF25', latitude: 51.750333, longitude: 19.528972 },
  { name: 'FN30', latitude: 51.751181, longitude: 39.348031 },
  { name: 'FD25', latitude: 51.75125, longitude: 19.521944 },
  { name: 'D089N', latitude: 51.753922, longitude: 13.573919 },
  { name: 'CI27', latitude: 51.756306, longitude: 13.461181 },
  { name: 'LL801', latitude: 51.756667, longitude: 19.558194 },
  { name: 'SH411', latitude: 51.756764, longitude: 13.460811 },
  { name: 'D089J', latitude: 51.7577, longitude: 13.466808 },
  { name: 'CF21', latitude: 51.758369, longitude: 39.386439 },
  { name: 'CF25', latitude: 51.758556, longitude: 19.566972 },
  { name: 'LL751', latitude: 51.759139, longitude: 19.568556 },
  { name: 'FI27', latitude: 51.759319, longitude: 13.386236 },
  { name: 'LL531', latitude: 51.760667, longitude: 19.57675 },
  { name: 'CF27', latitude: 51.761653, longitude: 13.327344 },
  { name: 'SH412', latitude: 51.761925, longitude: 13.326956 },
  { name: 'D090E', latitude: 51.762283, longitude: 13.332897 },
  { name: 'FT27', latitude: 51.762286, longitude: 13.332894 },
  { name: 'FF27', latitude: 51.763017, longitude: 13.292542 },
  { name: 'OO301', latitude: 51.764639, longitude: 39.323661 },
  { name: 'OO305', latitude: 51.765544, longitude: 39.321939 },
  { name: 'FF30', latitude: 51.765556, longitude: 39.321944 },
  { name: 'DIDGI', latitude: 51.765833, longitude: 12.457222 },
  { name: 'XENOM', latitude: 51.765972, longitude: 20.748472 },
  { name: 'SH510', latitude: 51.766717, longitude: 13.198956 },
  { name: 'MT09', latitude: 51.768447, longitude: 13.145369 },
  { name: 'MN09', latitude: 51.768831, longitude: 13.145406 },
  { name: 'OO303', latitude: 51.768944, longitude: 39.546328 },
  { name: 'D269C', latitude: 51.769667, longitude: 13.118667 },
  { name: 'SH500', latitude: 51.770386, longitude: 13.097911 },
  { name: 'FN09', latitude: 51.77145, longitude: 13.065025 },
  { name: 'KURER', latitude: 51.771667, longitude: 38.7975 },
  { name: 'D270F', latitude: 51.771833, longitude: 13.038194 },
  { name: 'D269D', latitude: 51.772544, longitude: 13.030922 },
  { name: 'LL602', latitude: 51.772583, longitude: 19.226444 },
  { name: 'FT09', latitude: 51.772667, longitude: 13.011394 },
  { name: 'SH402', latitude: 51.773625, longitude: 13.007475 },
  { name: 'CN09', latitude: 51.774014, longitude: 12.984639 },
  { name: 'LL701', latitude: 51.774194, longitude: 19.121611 },
  { name: 'OO106', latitude: 51.774722, longitude: 39.304844 },
  { name: 'D269K', latitude: 51.775931, longitude: 12.904194 },
  { name: 'LL501', latitude: 51.776083, longitude: 19.624806 },
  { name: 'FOLFA', latitude: 51.776972, longitude: 20.957611 },
  { name: 'SH403', latitude: 51.778269, longitude: 12.873519 },
  { name: 'RUDAK', latitude: 51.778286, longitude: 12.916167 },
  { name: 'LL608', latitude: 51.779417, longitude: 19.542139 },
  { name: 'DAHOW', latitude: 51.78, longitude: 22.012222 },
  { name: 'D269O', latitude: 51.780722, longitude: 12.797167 },
  { name: 'OLLEW', latitude: 51.787222, longitude: 17.578056 },
  { name: 'LL603', latitude: 51.788167, longitude: 19.084722 },
  { name: 'D091J', latitude: 51.793333, longitude: 39.466667 },
  { name: 'WA537', latitude: 51.793583, longitude: 21.289389 },
  { name: 'BOLBO', latitude: 51.794722, longitude: 13.151111 },
  { name: 'LL606', latitude: 51.79475, longitude: 19.327667 },
  { name: 'D091I', latitude: 51.796667, longitude: 39.453333 },
  { name: 'LL522', latitude: 51.797917, longitude: 19.72625 },
  { name: 'VP167', latitude: 51.8, longitude: 19.636111 },
  { name: 'POLONgitude', latitude: 51.8, longitude: 19.656111 },
  { name: 'LL702', latitude: 51.802, longitude: 19.248111 },
  { name: 'OO307', latitude: 51.802367, longitude: 39.253281 },
  { name: 'UMBAL', latitude: 51.804114, longitude: 12.172928 },
  { name: 'EBASA', latitude: 51.804444, longitude: 14.193611 },
  { name: 'ROKUB', latitude: 51.804444, longitude: 37.985 },
  { name: 'BAXIS', latitude: 51.808611, longitude: 18.863889 },
  { name: 'VAXUR', latitude: 51.809167, longitude: 22.098611 },
  { name: 'VP165', latitude: 51.809444, longitude: 19.286111 },
  { name: 'IRDEB', latitude: 51.810556, longitude: 21.892222 },
  { name: 'GESDU', latitude: 51.810833, longitude: 20.503611 },
  { name: 'OO204', latitude: 51.811781, longitude: 39.479464 },
  { name: 'ERMIV', latitude: 51.811944, longitude: 20.738333 },
  { name: 'KUVAL', latitude: 51.813056, longitude: 31.007222 },
  { name: 'IRLIB', latitude: 51.813333, longitude: 39.951111 },
  { name: 'D083G', latitude: 51.816667, longitude: 39.413333 },
  { name: 'LL523', latitude: 51.819639, longitude: 19.827806 },
  { name: 'FN12', latitude: 51.820278, longitude: 36.129167 },
  { name: 'TIMTU', latitude: 51.826111, longitude: 37.137778 },
  { name: 'MAVOT', latitude: 51.83, longitude: 17.231111 },
  { name: 'GODUR', latitude: 51.830556, longitude: 13.279722 },
  { name: 'TOPDI', latitude: 51.8375, longitude: 12.199722 },
  { name: 'VP222', latitude: 51.8375, longitude: 13.283333 },
  { name: 'OO304', latitude: 51.839369, longitude: 39.414478 },
  { name: 'SH410', latitude: 51.839822, longitude: 13.469267 },
  { name: 'IRMAL', latitude: 51.841111, longitude: 40.651667 },
  { name: 'SH415', latitude: 51.844981, longitude: 13.335164 },
  { name: 'POGAB', latitude: 51.845278, longitude: 13.983889 },
  { name: 'DERAM', latitude: 51.846944, longitude: 19.280833 },
  { name: 'BURAK', latitude: 51.846944, longitude: 26.395833 },
  { name: 'TEDMO', latitude: 51.846944, longitude: 40.086111 },
  { name: 'LL401', latitude: 51.847222, longitude: 19.182778 },
  { name: 'EREDA', latitude: 51.848361, longitude: 20.634444 },
  { name: 'VASOK', latitude: 51.849444, longitude: 23.941944 },
  { name: 'XALUR', latitude: 51.850833, longitude: 19.995278 },
  { name: 'MUVQE', latitude: 51.851389, longitude: 17.421111 },
  { name: 'WA536', latitude: 51.852361, longitude: 21.238889 },
  { name: 'LL704', latitude: 51.852611, longitude: 19.076611 },
  { name: 'VP223', latitude: 51.853333, longitude: 13.085278 },
  { name: 'SH404', latitude: 51.856703, longitude: 13.0151 },
  { name: 'LL802', latitude: 51.857194, longitude: 19.501556 },
  { name: 'FALNE', latitude: 51.857361, longitude: 19.630778 },
  { name: 'SH405', latitude: 51.861369, longitude: 12.8809 },
  { name: 'OK40B', latitude: 51.864411, longitude: 19.998969 },
  { name: 'UMDAN', latitude: 51.865278, longitude: 35.578056 },
  { name: 'VAMPU', latitude: 51.865833, longitude: 19.125 },
  { name: 'GOMAD', latitude: 51.866389, longitude: 29.965 },
  { name: 'OO105', latitude: 51.866853, longitude: 39.132539 },
  { name: 'OO306', latitude: 51.866942, longitude: 39.132361 },
  { name: 'FF12', latitude: 51.866944, longitude: 39.1325 },
  { name: 'UNRIG', latitude: 51.8675, longitude: 13.593056 },
  { name: 'OO101', latitude: 51.867867, longitude: 39.130717 },
  { name: 'SUFLU', latitude: 51.869722, longitude: 20.111111 },
  { name: 'NEGEN', latitude: 51.871861, longitude: 21.069667 },
  { name: 'LL712', latitude: 51.872167, longitude: 19.570833 },
  { name: 'LL526', latitude: 51.874361, longitude: 19.569611 },
  { name: 'LL803', latitude: 51.877056, longitude: 19.191833 },
  { name: 'FN12U', latitude: 51.877692, longitude: 39.112956 },
  { name: 'LL703', latitude: 51.880472, longitude: 19.203278 },
  { name: 'FN12V', latitude: 51.881114, longitude: 39.106572 },
  { name: 'MAWBI', latitude: 51.883417, longitude: 20.869389 },
  { name: 'OBESI', latitude: 51.888056, longitude: 36.944722 },
  { name: 'LEMBU', latitude: 51.891444, longitude: 21.357611 },
  { name: 'VP166', latitude: 51.891667, longitude: 19.635 },
  { name: 'OO401', latitude: 51.892808, longitude: 39.083861 },
  { name: 'DIBON', latitude: 51.893056, longitude: 26.121389 },
  { name: 'D143S', latitude: 51.894806, longitude: 21.226806 },
  { name: 'LL524', latitude: 51.896222, longitude: 19.67125 },
  { name: 'OLUMA', latitude: 51.896667, longitude: 15.756111 },
  { name: 'UTOLU', latitude: 51.896944, longitude: 18.8675 },
  { name: 'LUROS', latitude: 51.898597, longitude: 14.174472 },
  { name: 'KOSIX', latitude: 51.899167, longitude: 12.456111 },
  { name: 'TADUN', latitude: 51.901389, longitude: 24.688056 },
  { name: 'EVEPU', latitude: 51.901667, longitude: 19.606389 },
  { name: 'EVGAG', latitude: 51.906389, longitude: 15.2525 },
  { name: 'AGNOP', latitude: 51.907778, longitude: 16.616667 },
  { name: 'WA534', latitude: 51.911139, longitude: 21.188278 },
  { name: 'OLARA', latitude: 51.913056, longitude: 40.355278 },
  { name: 'CI12', latitude: 51.9146, longitude: 39.043147 },
  { name: 'MO696', latitude: 51.914722, longitude: 21.637222 },
  { name: 'MOSEX', latitude: 51.919164, longitude: 12.970733 },
  { name: 'LL402', latitude: 51.919167, longitude: 19.573611 },
  { name: 'AMIRI', latitude: 51.919722, longitude: 25.886389 },
  { name: 'AKAPI', latitude: 51.92, longitude: 16.472778 },
  { name: 'WA697', latitude: 51.9205, longitude: 21.180167 },
  { name: 'VP168', latitude: 51.922222, longitude: 15.444444 },
  { name: 'ATGUP', latitude: 51.923589, longitude: 13.788086 },
  { name: 'MALUT', latitude: 51.926389, longitude: 37.133056 },
  { name: 'ABSEL', latitude: 51.926722, longitude: 20.5255 },
  { name: 'SOMAT', latitude: 51.928889, longitude: 25.370833 },
  { name: 'OO205', latitude: 51.929511, longitude: 39.259886 },
  { name: 'OTMUL', latitude: 51.93075, longitude: 21.018611 },
  { name: 'OO102', latitude: 51.932928, longitude: 39.008378 },
  { name: 'ROSNO', latitude: 51.936447, longitude: 12.203286 },
  { name: 'SERFU', latitude: 51.938, longitude: 20.969083 },
  { name: 'ELSUM', latitude: 51.943056, longitude: 22.438056 },
  { name: 'LL604', latitude: 51.943611, longitude: 19.463278 },
  { name: 'LL403', latitude: 51.943889, longitude: 19.818889 },
  { name: 'EVDEX', latitude: 51.94625, longitude: 20.444611 },
  { name: 'MAGEP', latitude: 51.946389, longitude: 29.049722 },
  { name: 'NITKI', latitude: 51.948917, longitude: 20.572306 },
  { name: 'SISLU', latitude: 51.95025, longitude: 21.307083 },
  { name: 'KUBOG', latitude: 51.951111, longitude: 12.924167 },
  { name: 'EKDEL', latitude: 51.951111, longitude: 24.085278 },
  { name: 'NILPU', latitude: 51.952778, longitude: 17.175 },
  { name: 'KUMUT', latitude: 51.954222, longitude: 21.390083 },
  { name: 'OO104', latitude: 51.955758, longitude: 39.197492 },
  { name: 'VP169', latitude: 51.956667, longitude: 19.181667 },
  { name: 'NAHPA', latitude: 51.962139, longitude: 21.040222 },
  { name: 'OTPOP', latitude: 51.965556, longitude: 19.575 },
  { name: 'D143N', latitude: 51.966278, longitude: 21.157833 },
  { name: 'VP170', latitude: 51.966389, longitude: 15.533333 },
  { name: 'OO403', latitude: 51.967744, longitude: 39.188267 },
  { name: 'WA533', latitude: 51.969861, longitude: 21.137528 },
  { name: 'VP171', latitude: 51.970556, longitude: 20.833611 },
  { name: 'D098E', latitude: 51.971881, longitude: 19.358978 },
  { name: 'WA884', latitude: 51.974472, longitude: 21.620111 },
  { name: 'RATGA', latitude: 51.975, longitude: 40.096111 },
  { name: 'VP172', latitude: 51.976944, longitude: 19.375278 },
  { name: 'MAPIX', latitude: 51.982111, longitude: 21.2925 },
  { name: 'KITEQ', latitude: 51.983944, longitude: 20.972389 },
  { name: 'KUGEB', latitude: 51.986389, longitude: 20.783611 },
  { name: 'D128J', latitude: 51.988889, longitude: 24.082778 },
  { name: 'D335K', latitude: 51.989628, longitude: 39.137947 },
  { name: 'VP173', latitude: 51.990833, longitude: 21.234722 },
  { name: 'D126J', latitude: 51.993333, longitude: 24.089444 },
  { name: 'FF28', latitude: 51.996389, longitude: 19.241667 },
  { name: 'INDIG', latitude: 51.996667, longitude: 18.0 },
  { name: 'VP174', latitude: 51.997222, longitude: 15.466667 },
  { name: 'VP175', latitude: 51.997778, longitude: 18.802778 },
  { name: 'VP176', latitude: 51.999722, longitude: 15.566667 },
  { name: '5213E', latitude: 52.0, longitude: 13.0 },
  { name: '5214E', latitude: 52.0, longitude: 14.0 },
  { name: '5215E', latitude: 52.0, longitude: 15.0 },
  { name: '5216E', latitude: 52.0, longitude: 16.0 },
  { name: '5217E', latitude: 52.0, longitude: 17.0 },
  { name: '5218E', latitude: 52.0, longitude: 18.0 },
  { name: '5219E', latitude: 52.0, longitude: 19.0 },
  { name: '5220E', latitude: 52.0, longitude: 20.0 },
  { name: '5221E', latitude: 52.0, longitude: 21.0 },
  { name: '5222E', latitude: 52.0, longitude: 22.0 },
  { name: '5223E', latitude: 52.0, longitude: 23.0 },
  { name: '5224E', latitude: 52.0, longitude: 24.0 },
  { name: '5225E', latitude: 52.0, longitude: 25.0 },
  { name: '5226E', latitude: 52.0, longitude: 26.0 },
  { name: '5227E', latitude: 52.0, longitude: 27.0 },
  { name: '5228E', latitude: 52.0, longitude: 28.0 },
  { name: '5229E', latitude: 52.0, longitude: 29.0 },
  { name: '5230E', latitude: 52.0, longitude: 30.0 },
  { name: '5231E', latitude: 52.0, longitude: 31.0 },
  { name: '5232E', latitude: 52.0, longitude: 32.0 },
  { name: '5233E', latitude: 52.0, longitude: 33.0 },
  { name: '5234E', latitude: 52.0, longitude: 34.0 },
  { name: '5235E', latitude: 52.0, longitude: 35.0 },
  { name: '5236E', latitude: 52.0, longitude: 36.0 },
  { name: '5237E', latitude: 52.0, longitude: 37.0 },
  { name: '5238E', latitude: 52.0, longitude: 38.0 },
  { name: '5239E', latitude: 52.0, longitude: 39.0 },
  { name: '5240E', latitude: 52.0, longitude: 40.0 },
  { name: 'VP177', latitude: 52.002778, longitude: 15.416667 },
  { name: 'D115L', latitude: 52.003197, longitude: 24.162142 },
  { name: 'B132', latitude: 52.004444, longitude: 24.063611 },
  { name: 'AZ111', latitude: 52.006394, longitude: 13.155631 },
  { name: 'VP178', latitude: 52.006667, longitude: 19.543611 },
  { name: 'DISKI', latitude: 52.0075, longitude: 39.956111 },
  { name: 'ADINI', latitude: 52.00925, longitude: 21.256278 },
  { name: 'ABAPA', latitude: 52.01, longitude: 21.194444 },
  { name: 'PO661', latitude: 52.010528, longitude: 16.503722 },
  { name: 'MASIV', latitude: 52.012472, longitude: 17.856639 },
  { name: 'ERLEG', latitude: 52.013111, longitude: 21.100056 },
  { name: 'WA403', latitude: 52.013972, longitude: 21.041278 },
  { name: 'ARVAL', latitude: 52.016278, longitude: 21.109417 },
  { name: 'TOSPO', latitude: 52.016389, longitude: 23.664722 },
  { name: 'YUMME', latitude: 52.02, longitude: 16.8275 },
  { name: 'WA656', latitude: 52.020333, longitude: 21.079917 },
  { name: 'FF10', latitude: 52.024722, longitude: 19.050556 },
  { name: 'DB070', latitude: 52.024769, longitude: 12.816664 },
  { name: 'OO103', latitude: 52.026333, longitude: 39.0653 },
  { name: 'ERNOX', latitude: 52.026667, longitude: 16.020556 },
  { name: 'GILAL', latitude: 52.026806, longitude: 20.547889 },
  { name: 'D113R', latitude: 52.027333, longitude: 21.381611 },
  { name: 'OKDAL', latitude: 52.028889, longitude: 24.184444 },
  { name: 'WA732', latitude: 52.029694, longitude: 21.446861 },
  { name: 'WA609', latitude: 52.030694, longitude: 20.953222 },
  { name: 'VP179', latitude: 52.031667, longitude: 15.626944 },
  { name: 'INKIN', latitude: 52.032222, longitude: 38.583889 },
  { name: 'TITQE', latitude: 52.0325, longitude: 16.303056 },
  { name: 'VEKPE', latitude: 52.032778, longitude: 20.928611 },
  { name: 'DUFSI', latitude: 52.033333, longitude: 17.653333 },
  { name: 'WA529', latitude: 52.035222, longitude: 21.080833 },
  { name: 'WA402', latitude: 52.035778, longitude: 20.924556 },
  { name: 'WA606', latitude: 52.037194, longitude: 20.932167 },
  { name: 'FD33', latitude: 52.037722, longitude: 21.088639 },
  { name: 'DEBZU', latitude: 52.037806, longitude: 21.096389 },
  { name: 'PO721', latitude: 52.040778, longitude: 16.743528 },
  { name: 'AZ255', latitude: 52.041131, longitude: 13.181917 },
  { name: 'CI29', latitude: 52.041136, longitude: 24.139989 },
  { name: 'SOXER', latitude: 52.043833, longitude: 19.934972 },
  { name: 'NEDKU', latitude: 52.043889, longitude: 40.899167 },
  { name: 'D105J', latitude: 52.046667, longitude: 24.138056 },
  { name: 'DB593', latitude: 52.046911, longitude: 13.704369 },
  { name: 'ROKMU', latitude: 52.047186, longitude: 12.976381 },
  { name: 'NUKRO', latitude: 52.047631, longitude: 14.416517 },
  { name: 'VP180', latitude: 52.053056, longitude: 19.168611 },
  { name: 'DB249', latitude: 52.053772, longitude: 14.305933 },
  { name: 'WA731', latitude: 52.057722, longitude: 21.348917 },
  { name: 'GOVEN', latitude: 52.058333, longitude: 14.755556 },
  { name: 'BADUP', latitude: 52.059167, longitude: 17.421111 },
  { name: 'DB582', latitude: 52.061569, longitude: 13.472892 },
  { name: 'VP181', latitude: 52.063333, longitude: 20.743333 },
  { name: 'FI29', latitude: 52.063333, longitude: 24.059722 },
  { name: 'WA608', latitude: 52.063417, longitude: 21.034667 },
  { name: 'DISBI', latitude: 52.066389, longitude: 22.978056 },
  { name: 'VP182', latitude: 52.066667, longitude: 15.86 },
  { name: 'D095L', latitude: 52.067406, longitude: 24.2047 },
  { name: 'D113M', latitude: 52.0675, longitude: 21.263389 },
  { name: 'DB567', latitude: 52.067733, longitude: 12.691375 },
  { name: 'FQ29', latitude: 52.06775, longitude: 24.043761 },
  { name: 'ROZAT', latitude: 52.067778, longitude: 21.625833 },
  { name: 'FD29', latitude: 52.069444, longitude: 24.047222 },
  { name: 'NEMIP', latitude: 52.070278, longitude: 32.386389 },
  { name: 'FILOZ', latitude: 52.071028, longitude: 20.978639 },
  { name: 'AZ260', latitude: 52.072494, longitude: 13.078347 },
  { name: 'LOGDO', latitude: 52.073311, longitude: 12.321783 },
  { name: 'PO732', latitude: 52.074583, longitude: 17.555917 },
  { name: 'PO633', latitude: 52.074861, longitude: 17.555917 },
  { name: 'ETANI', latitude: 52.075278, longitude: 40.270556 },
  { name: 'AZ104', latitude: 52.076019, longitude: 13.208161 },
  { name: 'ADURU', latitude: 52.079167, longitude: 21.216111 },
  { name: 'AZ108', latitude: 52.080314, longitude: 13.105872 },
  { name: 'LOVIK', latitude: 52.083333, longitude: 31.015 },
  { name: 'BUROK', latitude: 52.084322, longitude: 12.397392 },
  { name: 'ZG462', latitude: 52.085111, longitude: 15.615972 },
  { name: 'UBZAZ', latitude: 52.085278, longitude: 16.331389 },
  { name: 'WA729', latitude: 52.085667, longitude: 21.250861 },
  { name: 'FAFEN', latitude: 52.089444, longitude: 18.372778 },
  { name: '29DME', latitude: 52.090833, longitude: 23.959722 },
  { name: '29VOR', latitude: 52.091111, longitude: 23.961111 },
  { name: 'VP183', latitude: 52.091389, longitude: 21.2375 },
  { name: 'DB566', latitude: 52.092453, longitude: 12.791781 },
  { name: 'ZG561', latitude: 52.095028, longitude: 15.665861 },
  { name: 'VIBAT', latitude: 52.095611, longitude: 21.180417 },
  { name: 'WA404', latitude: 52.095639, longitude: 21.167917 },
  { name: 'ZG426', latitude: 52.096444, longitude: 15.654639 },
  { name: 'VP184', latitude: 52.0975, longitude: 16.095278 },
  { name: 'WA651', latitude: 52.097833, longitude: 21.169806 },
  { name: 'DB592', latitude: 52.098269, longitude: 13.630297 },
  { name: 'VP185', latitude: 52.100833, longitude: 18.862222 },
  { name: 'INGES', latitude: 52.101389, longitude: 23.513889 },
  { name: 'WA514', latitude: 52.102694, longitude: 21.025472 },
  { name: 'ADOXO', latitude: 52.103056, longitude: 19.507222 },
  { name: 'TEBRU', latitude: 52.10375, longitude: 21.187 },
  { name: 'WA501', latitude: 52.104611, longitude: 21.020472 },
  { name: 'RIKNO', latitude: 52.10525, longitude: 21.503639 },
  { name: 'DB481', latitude: 52.106697, longitude: 13.666611 },
  { name: 'FD29', latitude: 52.107611, longitude: 21.144861 },
  { name: 'VP186', latitude: 52.107778, longitude: 21.827778 },
  { name: 'RODAT', latitude: 52.107778, longitude: 39.530833 },
  { name: 'KUNIN', latitude: 52.108056, longitude: 26.856389 },
  { name: 'KAMNA', latitude: 52.108333, longitude: 38.748333 },
  { name: 'VP187', latitude: 52.108611, longitude: 20.830833 },
  { name: 'ZG463', latitude: 52.112278, longitude: 15.708694 },
  { name: 'VP188', latitude: 52.113333, longitude: 21.585278 },
  { name: 'WA728', latitude: 52.114167, longitude: 21.150139 },
  { name: 'ZG562', latitude: 52.115972, longitude: 15.727306 },
  { name: 'MD11', latitude: 52.116667, longitude: 23.864444 },
  { name: 'DB565', latitude: 52.117086, longitude: 12.892297 },
  { name: 'D147D', latitude: 52.118806, longitude: 21.008111 },
  { name: 'DB572', latitude: 52.119633, longitude: 13.347867 },
  { name: 'RONUM', latitude: 52.12, longitude: 39.219167 },
  { name: 'VP189', latitude: 52.121667, longitude: 15.810278 },
  { name: 'BEXON', latitude: 52.123139, longitude: 21.157722 },
  { name: 'SOSIN', latitude: 52.123278, longitude: 20.792194 },
  { name: 'ESIKA', latitude: 52.123761, longitude: 12.671308 },
  { name: 'VP190', latitude: 52.123889, longitude: 22.022222 },
  { name: 'ZG502', latitude: 52.124417, longitude: 15.813389 },
  { name: '20VOR', latitude: 52.124444, longitude: 23.839722 },
  { name: 'PEPOL', latitude: 52.126111, longitude: 13.6825 },
  { name: 'D308J', latitude: 52.126114, longitude: 13.370483 },
  { name: 'MOBGI', latitude: 52.126389, longitude: 24.211111 },
  { name: 'WA401', latitude: 52.126667, longitude: 21.00125 },
  { name: 'MD06', latitude: 52.128306, longitude: 15.763556 },
  { name: 'DIBLO', latitude: 52.129722, longitude: 19.344167 },
  { name: 'ADVAB', latitude: 52.130556, longitude: 16.727222 },
  { name: 'OKLOT', latitude: 52.132222, longitude: 38.876944 },
  { name: 'WA707', latitude: 52.13275, longitude: 20.679778 },
  { name: 'TIGTU', latitude: 52.133278, longitude: 21.405528 },
  { name: 'OLBIL', latitude: 52.133472, longitude: 21.081833 },
  { name: 'WA441', latitude: 52.133583, longitude: 21.066667 },
  { name: '36DME', latitude: 52.134444, longitude: 23.800833 },
  { name: 'VP191', latitude: 52.135, longitude: 20.889444 },
  { name: 'NEBDU', latitude: 52.136667, longitude: 24.263889 },
  { name: 'MD33', latitude: 52.138139, longitude: 20.990917 },
  { name: 'VP192', latitude: 52.138611, longitude: 16.265556 },
  { name: 'PO941', latitude: 52.139778, longitude: 16.685306 },
  { name: 'AZ254', latitude: 52.139806, longitude: 13.2755 },
  { name: 'D160L', latitude: 52.14035, longitude: 17.051683 },
  { name: 'ASTEX', latitude: 52.141222, longitude: 21.087167 },
  { name: 'DB564', latitude: 52.141633, longitude: 12.992922 },
  { name: 'UXOSA', latitude: 52.141667, longitude: 16.778056 },
  { name: 'DB482', latitude: 52.143242, longitude: 13.824775 },
  { name: 'DB557', latitude: 52.145028, longitude: 12.641064 },
  { name: 'D212N', latitude: 52.146989, longitude: 16.739714 },
  { name: 'ML33', latitude: 52.147056, longitude: 20.983444 },
  { name: 'ML24', latitude: 52.147083, longitude: 15.828 },
  { name: 'PO806', latitude: 52.147139, longitude: 16.663361 },
  { name: 'KUKOP', latitude: 52.147778, longitude: 20.410556 },
  { name: 'AZ107', latitude: 52.147931, longitude: 13.080572 },
  { name: 'D110C', latitude: 52.148333, longitude: 21.029083 },
  { name: 'MD24', latitude: 52.148722, longitude: 15.833556 },
  { name: 'FQ11', latitude: 52.149025, longitude: 23.747144 },
  { name: 'WA522', latitude: 52.149028, longitude: 21.021833 },
  { name: 'DB484', latitude: 52.14905, longitude: 14.262625 },
  { name: 'VP195', latitude: 52.15, longitude: 21.666389 },
  { name: 'SOFUZ', latitude: 52.1525, longitude: 21.131111 },
  { name: 'BEPLU', latitude: 52.1525, longitude: 38.268056 },
  { name: 'MAKUS', latitude: 52.152778, longitude: 20.500833 },
  { name: 'FD11', latitude: 52.152778, longitude: 23.748611 },
  { name: 'WA602', latitude: 52.153194, longitude: 21.011833 },
  { name: 'KS22', latitude: 52.154283, longitude: 17.508914 },
  { name: 'GERVI', latitude: 52.155278, longitude: 22.200833 },
  { name: 'PO731', latitude: 52.155694, longitude: 17.156028 },
  { name: 'VP193', latitude: 52.156944, longitude: 20.949722 },
  { name: 'WA921', latitude: 52.156972, longitude: 20.7105 },
  { name: 'VP194', latitude: 52.158056, longitude: 15.52 },
  { name: 'NEPOX', latitude: 52.159167, longitude: 21.999194 },
  { name: 'WA754', latitude: 52.159694, longitude: 20.333 },
  { name: 'DB951', latitude: 52.161044, longitude: 13.213269 },
  { name: 'ZG461', latitude: 52.161722, longitude: 15.556583 },
  { name: 'MO861', latitude: 52.163472, longitude: 20.827667 },
  { name: 'LULUL', latitude: 52.163992, longitude: 12.956036 },
  { name: 'VP196', latitude: 52.164722, longitude: 15.811944 },
  { name: 'DB563', latitude: 52.166097, longitude: 13.093658 },
  { name: 'SONTI', latitude: 52.166389, longitude: 26.859722 },
  { name: 'VP197', latitude: 52.166667, longitude: 17.166667 },
  { name: 'WA607', latitude: 52.166833, longitude: 21.197556 },
  { name: 'DILVI', latitude: 52.169167, longitude: 15.459167 },
  { name: 'DB556', latitude: 52.169789, longitude: 12.741614 },
  { name: 'AZ103', latitude: 52.170297, longitude: 13.272292 },
  { name: 'EDIZE', latitude: 52.171389, longitude: 21.271583 },
  { name: 'XETED', latitude: 52.172222, longitude: 16.731389 },
  { name: 'DB546', latitude: 52.172575, longitude: 12.678181 },
  { name: 'ZG521', latitude: 52.173444, longitude: 15.938389 },
  { name: 'ZG501', latitude: 52.174139, longitude: 15.984667 },
  { name: 'NEDNA', latitude: 52.174972, longitude: 21.112667 },
  { name: 'AZ253', latitude: 52.174992, longitude: 13.393789 },
  { name: 'ML11', latitude: 52.175111, longitude: 20.933778 },
  { name: 'LOLSI', latitude: 52.177472, longitude: 19.871389 },
  { name: 'ZG424', latitude: 52.178167, longitude: 15.934972 },
  { name: 'ZG464', latitude: 52.178444, longitude: 15.936167 },
  { name: 'WA902', latitude: 52.179333, longitude: 20.835111 },
  { name: 'BASEK', latitude: 52.1795, longitude: 20.59 },
  { name: 'BUWAS', latitude: 52.179722, longitude: 21.785222 },
  { name: 'WA901', latitude: 52.180417, longitude: 20.914778 },
  { name: '22VO2', latitude: 52.180814, longitude: 20.902028 },
  { name: 'BABUN', latitude: 52.181389, longitude: 28.781389 },
  { name: 'VP198', latitude: 52.181667, longitude: 20.986111 },
  { name: 'VP199', latitude: 52.184167, longitude: 20.613889 },
  { name: 'D290C', latitude: 52.185306, longitude: 20.897306 },
  { name: 'AZ106', latitude: 52.185444, longitude: 13.100625 },
  { name: 'WA702', latitude: 52.186556, longitude: 20.786167 },
  { name: 'D334A', latitude: 52.186775, longitude: 20.949069 },
  { name: 'WA657', latitude: 52.187278, longitude: 21.24775 },
  { name: 'WA802', latitude: 52.188, longitude: 20.68925 },
  { name: 'LETKI', latitude: 52.188056, longitude: 23.404444 },
  { name: 'ROMQI', latitude: 52.188722, longitude: 21.339028 },
  { name: 'DIVIM', latitude: 52.189722, longitude: 25.048056 },
  { name: 'DB562', latitude: 52.190472, longitude: 13.194506 },
  { name: 'IBIKI', latitude: 52.191111, longitude: 13.464167 },
  { name: 'DB262', latitude: 52.191617, longitude: 13.349606 },
  { name: 'BB06', latitude: 52.191667, longitude: 24.242222 },
  { name: 'CI24', latitude: 52.192597, longitude: 15.984753 },
  { name: 'WA703', latitude: 52.192694, longitude: 20.721889 },
  { name: 'D084O', latitude: 52.1935, longitude: 22.071639 },
  { name: 'DB901', latitude: 52.193503, longitude: 13.131069 },
  { name: 'OBOLA', latitude: 52.194444, longitude: 16.211389 },
  { name: 'DB555', latitude: 52.194467, longitude: 12.842275 },
  { name: 'CI26', latitude: 52.194653, longitude: 21.885431 },
  { name: 'AZ259', latitude: 52.194789, longitude: 13.1016 },
  { name: 'FI26', latitude: 52.194889, longitude: 21.831389 },
  { name: 'MT26', latitude: 52.195278, longitude: 21.692528 },
  { name: 'FN26', latitude: 52.195361, longitude: 21.831189 },
  { name: 'ML26Y', latitude: 52.195417, longitude: 21.691833 },
  { name: 'MT08', latitude: 52.195528, longitude: 21.614028 },
  { name: 'DB545', latitude: 52.197306, longitude: 12.778814 },
  { name: 'VP200', latitude: 52.1975, longitude: 21.051667 },
  { name: 'WA903', latitude: 52.19775, longitude: 20.704 },
  { name: 'FT08', latitude: 52.198333, longitude: 21.475833 },
  { name: 'DB483', latitude: 52.198847, longitude: 14.067733 },
  { name: 'GISUB', latitude: 52.199722, longitude: 23.598056 },
  { name: 'FT26', latitude: 52.2, longitude: 21.838611 },
  { name: 'LUPAK', latitude: 52.201167, longitude: 13.224167 },
  { name: 'ENZAM', latitude: 52.201389, longitude: 21.554722 },
  { name: 'VAWKU', latitude: 52.201667, longitude: 21.308056 },
  { name: 'PO706', latitude: 52.201722, longitude: 16.917167 },
  { name: 'DIPKI', latitude: 52.202778, longitude: 17.462778 },
  { name: 'ROMAL', latitude: 52.203056, longitude: 30.521667 },
  { name: 'CT26', latitude: 52.203639, longitude: 21.963111 },
  { name: 'AZ102', latitude: 52.204956, longitude: 13.249769 },
  { name: 'VP201', latitude: 52.205278, longitude: 16.206944 },
  { name: 'LUGEL', latitude: 52.206806, longitude: 20.491139 },
  { name: 'KS15', latitude: 52.2094, longitude: 17.341931 },
  { name: 'PO632', latitude: 52.210333, longitude: 16.875861 },
  { name: 'VP202', latitude: 52.211389, longitude: 17.285833 },
  { name: 'C312', latitude: 52.211389, longitude: 23.728889 },
  { name: 'XELTO', latitude: 52.211778, longitude: 20.927 },
  { name: 'VP203', latitude: 52.2125, longitude: 16.244167 },
  { name: 'LUPOG', latitude: 52.212556, longitude: 20.918194 },
  { name: 'RAVOK', latitude: 52.213056, longitude: 24.093056 },
  { name: 'WA801', latitude: 52.213083, longitude: 20.925694 },
  { name: 'ZG423', latitude: 52.213611, longitude: 16.057639 },
  { name: 'KUTEV', latitude: 52.214722, longitude: 20.924444 },
  { name: 'ZG421', latitude: 52.216167, longitude: 15.742667 },
  { name: 'VP204', latitude: 52.2175, longitude: 21.097222 },
  { name: 'EXFOR', latitude: 52.217889, longitude: 20.605417 },
  { name: 'FD11Z', latitude: 52.218667, longitude: 20.740361 },
  { name: 'WA931', latitude: 52.218667, longitude: 20.878611 },
  { name: 'DB554', latitude: 52.219058, longitude: 12.94305 },
  { name: 'FEZUM', latitude: 52.221389, longitude: 22.193611 },
  { name: 'TRIBY', latitude: 52.221544, longitude: 13.273611 },
  { name: 'DB544', latitude: 52.22195, longitude: 12.879558 },
  { name: 'VP205', latitude: 52.222778, longitude: 21.25 },
  { name: 'SUPID', latitude: 52.223333, longitude: 21.7445 },
  { name: 'ZEBWI', latitude: 52.224389, longitude: 21.474333 },
  { name: 'VALOL', latitude: 52.224722, longitude: 23.466389 },
  { name: 'REKRA', latitude: 52.225194, longitude: 17.334806 },
  { name: 'ROPUX', latitude: 52.225306, longitude: 21.615611 },
  { name: 'ABMOM', latitude: 52.225556, longitude: 39.106667 },
  { name: 'ASDAG', latitude: 52.227111, longitude: 20.702083 },
  { name: 'WA409', latitude: 52.227389, longitude: 20.746472 },
  { name: 'ABERO', latitude: 52.227778, longitude: 23.208889 },
  { name: 'ARSAP', latitude: 52.228056, longitude: 14.7125 },
  { name: 'VP206', latitude: 52.228889, longitude: 18.060278 },
  { name: 'DINVA', latitude: 52.229778, longitude: 21.064917 },
  { name: 'D116L', latitude: 52.230083, longitude: 17.246472 },
  { name: 'VP207', latitude: 52.230833, longitude: 15.805556 },
  { name: 'FD11Y', latitude: 52.231194, longitude: 20.749167 },
  { name: 'LUWSO', latitude: 52.231333, longitude: 21.246944 },
  { name: 'D311J', latitude: 52.231389, longitude: 23.705556 },
  { name: 'D304U', latitude: 52.231661, longitude: 13.119114 },
  { name: 'TENVO', latitude: 52.231694, longitude: 17.774194 },
  { name: 'D308S', latitude: 52.232556, longitude: 13.176633 },
  { name: 'VP208', latitude: 52.233333, longitude: 16.833333 },
  { name: 'KELOD', latitude: 52.233889, longitude: 15.883333 },
  { name: 'PIBAR', latitude: 52.233944, longitude: 20.392417 },
  { name: 'DB250', latitude: 52.235033, longitude: 12.148203 },
  { name: 'PO612', latitude: 52.235556, longitude: 17.162444 },
  { name: 'MO944', latitude: 52.236, longitude: 20.193083 },
  { name: 'AZ250', latitude: 52.236253, longitude: 13.368206 },
  { name: 'DB541', latitude: 52.236844, longitude: 13.311869 },
  { name: 'GOSIT', latitude: 52.237694, longitude: 20.709417 },
  { name: 'D113L', latitude: 52.238222, longitude: 17.253667 },
  { name: 'VP209', latitude: 52.238333, longitude: 16.341111 },
  { name: 'DB553', latitude: 52.243561, longitude: 13.043933 },
  { name: 'DB263', latitude: 52.243806, longitude: 13.272006 },
  { name: 'VP210', latitude: 52.245, longitude: 21.651111 },
  { name: 'WA803', latitude: 52.245528, longitude: 20.781167 },
  { name: 'OBANI', latitude: 52.245556, longitude: 14.103056 },
  { name: 'DB543', latitude: 52.246508, longitude: 12.980414 },
  { name: 'D284N', latitude: 52.246833, longitude: 20.612722 },
  { name: 'KS10', latitude: 52.248625, longitude: 17.222406 },
  { name: 'NISGA', latitude: 52.249444, longitude: 12.876111 },
  { name: 'ZG513', latitude: 52.249444, longitude: 15.926944 },
  { name: 'DB536', latitude: 52.249864, longitude: 12.627728 },
  { name: 'GAGVI', latitude: 52.250681, longitude: 13.722069 },
  { name: 'OLBUV', latitude: 52.250778, longitude: 17.205806 },
  { name: 'IRCEN', latitude: 52.250819, longitude: 17.588394 },
  { name: 'BB02', latitude: 52.250833, longitude: 24.188889 },
  { name: 'OBOKU', latitude: 52.252222, longitude: 18.0 },
  { name: 'WA698', latitude: 52.253583, longitude: 21.695167 },
  { name: 'DB261', latitude: 52.255344, longitude: 13.293433 },
  { name: 'WA411', latitude: 52.255361, longitude: 20.645639 },
  { name: 'DB471', latitude: 52.255564, longitude: 13.843681 },
  { name: 'MIPLO', latitude: 52.256111, longitude: 15.591944 },
  { name: 'RILSU', latitude: 52.257389, longitude: 20.732667 },
  { name: 'NIMIS', latitude: 52.25825, longitude: 20.962389 },
  { name: 'SUKIP', latitude: 52.25885, longitude: 12.471478 },
  { name: 'VP211', latitude: 52.259167, longitude: 20.657222 },
  { name: 'MULZA', latitude: 52.25925, longitude: 20.657167 },
  { name: 'PO922', latitude: 52.259583, longitude: 17.100861 },
  { name: 'MO818', latitude: 52.259639, longitude: 21.024056 },
  { name: 'VP212', latitude: 52.260833, longitude: 20.735556 },
  { name: 'WA612', latitude: 52.261556, longitude: 20.883167 },
  { name: 'DUTFU', latitude: 52.262583, longitude: 21.340139 },
  { name: 'POBAM', latitude: 52.262883, longitude: 13.221439 },
  { name: 'ZG512', latitude: 52.262917, longitude: 15.70375 },
  { name: 'MOVOM', latitude: 52.263053, longitude: 13.478 },
  { name: 'WA513', latitude: 52.264, longitude: 20.891639 },
  { name: 'VP236', latitude: 52.265, longitude: 13.576667 },
  { name: 'WA932', latitude: 52.265167, longitude: 20.799167 },
  { name: 'PO631', latitude: 52.265611, longitude: 16.591556 },
  { name: 'VP213', latitude: 52.265833, longitude: 16.26 },
  { name: 'DB552', latitude: 52.267981, longitude: 13.144928 },
  { name: 'WA706', latitude: 52.268361, longitude: 20.787472 },
  { name: 'VP214', latitude: 52.269444, longitude: 21.912222 },
  { name: 'SONUD', latitude: 52.269722, longitude: 13.7325 },
  { name: 'DB542', latitude: 52.270981, longitude: 13.081381 },
  { name: 'BAREP', latitude: 52.271111, longitude: 15.564444 },
  { name: 'REBSO', latitude: 52.271111, longitude: 21.028806 },
  { name: 'CI29Z', latitude: 52.271269, longitude: 17.1533 },
  { name: 'PO707', latitude: 52.2715, longitude: 16.556389 },
  { name: 'LUGIR', latitude: 52.274167, longitude: 23.703889 },
  { name: 'DB535', latitude: 52.274636, longitude: 12.728508 },
  { name: 'D284S', latitude: 52.274833, longitude: 20.485 },
  { name: 'PIMEN', latitude: 52.275, longitude: 32.673333 },
  { name: 'CI29Y', latitude: 52.275175, longitude: 17.141322 },
  { name: 'WA613', latitude: 52.281306, longitude: 20.865806 },
  { name: 'WA412', latitude: 52.282694, longitude: 20.546667 },
  { name: 'FT29', latitude: 52.2835, longitude: 17.102583 },
  { name: 'FD15', latitude: 52.2845, longitude: 20.876722 },
  { name: 'FN29', latitude: 52.285569, longitude: 17.108889 },
  { name: 'FI29', latitude: 52.286806, longitude: 17.105278 },
  { name: 'IBGAL', latitude: 52.287672, longitude: 12.931958 },
  { name: 'CI2LY', latitude: 52.288556, longitude: 18.317389 },
  { name: 'D103S', latitude: 52.288794, longitude: 18.317522 },
  { name: 'GOBSU', latitude: 52.288889, longitude: 17.011861 },
  { name: 'PO931', latitude: 52.289944, longitude: 17.488583 },
  { name: 'VP215', latitude: 52.290278, longitude: 21.010833 },
  { name: 'DOPOX', latitude: 52.290389, longitude: 21.121222 },
  { name: 'ZG422', latitude: 52.290444, longitude: 15.998833 },
  { name: 'PO923', latitude: 52.291056, longitude: 16.940083 },
  { name: 'TEBGO', latitude: 52.292308, longitude: 13.246033 },
  { name: 'VP216', latitude: 52.292778, longitude: 16.25 },
  { name: 'ETABI', latitude: 52.293833, longitude: 16.443917 },
  { name: 'ODIDO', latitude: 52.295367, longitude: 13.182458 },
  { name: 'VAVIV', latitude: 52.298772, longitude: 13.117544 },
  { name: 'REMDI', latitude: 52.299139, longitude: 20.817 },
  { name: 'DB534', latitude: 52.299322, longitude: 12.8294 },
  { name: 'PO481', latitude: 52.302472, longitude: 17.284389 },
  { name: 'OSNUT', latitude: 52.303306, longitude: 20.846444 },
  { name: 'D099R', latitude: 52.303583, longitude: 18.324361 },
  { name: 'VP217', latitude: 52.304167, longitude: 16.131389 },
  { name: 'VP218', latitude: 52.304444, longitude: 21.55 },
  { name: 'DB062', latitude: 52.304678, longitude: 13.611014 },
  { name: 'VP219', latitude: 52.304722, longitude: 21.083889 },
  { name: 'XERTU', latitude: 52.307306, longitude: 20.860083 },
  { name: 'LUNOM', latitude: 52.3075, longitude: 40.148333 },
  { name: 'PO821', latitude: 52.307528, longitude: 16.916361 },
  { name: 'KINFU', latitude: 52.307611, longitude: 20.996861 },
  { name: 'OL019', latitude: 52.308333, longitude: 39.702778 },
  { name: 'OLDOX', latitude: 52.308889, longitude: 17.636389 },
  { name: 'MO852', latitude: 52.308972, longitude: 20.197694 },
  { name: 'PEVUM', latitude: 52.309444, longitude: 18.6575 },
  { name: 'BAKPA', latitude: 52.309919, longitude: 12.811186 },
  { name: 'WA413', latitude: 52.309944, longitude: 20.447583 },
  { name: 'VP220', latitude: 52.310833, longitude: 17.816667 },
  { name: 'PO704', latitude: 52.312278, longitude: 17.380833 },
  { name: 'IDOBA', latitude: 52.316569, longitude: 14.088147 },
  { name: 'IDMAV', latitude: 52.318056, longitude: 16.466389 },
  { name: 'ASLUX', latitude: 52.320556, longitude: 22.160556 },
  { name: 'BB01', latitude: 52.321667, longitude: 24.044167 },
  { name: 'RONIT', latitude: 52.321667, longitude: 33.898333 },
  { name: 'MO851', latitude: 52.321972, longitude: 20.330889 },
  { name: 'FI07R', latitude: 52.322342, longitude: 13.371483 },
  { name: 'PO924', latitude: 52.322361, longitude: 16.778917 },
  { name: 'DB064', latitude: 52.322531, longitude: 13.7614 },
  { name: '32VOR', latitude: 52.322897, longitude: 13.3729 },
  { name: 'DB533', latitude: 52.323922, longitude: 12.930403 },
  { name: 'DB260', latitude: 52.325839, longitude: 13.424758 },
  { name: 'DB247', latitude: 52.327236, longitude: 13.209772 },
  { name: 'DB241', latitude: 52.327989, longitude: 13.221694 },
  { name: 'WA571', latitude: 52.328889, longitude: 21.442222 },
  { name: 'DB850', latitude: 52.328894, longitude: 14.111981 },
  { name: 'VP221', latitude: 52.329444, longitude: 17.050833 },
  { name: 'ARGUX', latitude: 52.329539, longitude: 13.736822 },
  { name: 'PO713', latitude: 52.33, longitude: 17.291389 },
  { name: '27LO1', latitude: 52.330253, longitude: 13.404644 },
  { name: 'MO832', latitude: 52.330361, longitude: 20.328722 },
  { name: 'DB532', latitude: 52.331044, longitude: 13.017983 },
  { name: 'VP222', latitude: 52.331389, longitude: 17.171111 },
  { name: 'AGESI', latitude: 52.331389, longitude: 20.701611 },
  { name: 'DB061', latitude: 52.332061, longitude: 13.5663 },
  { name: 'OL020', latitude: 52.332083, longitude: 39.802806 },
  { name: 'GENTI', latitude: 52.3325, longitude: 12.343889 },
  { name: 'AMUTO', latitude: 52.333333, longitude: 18.945833 },
  { name: 'VP223', latitude: 52.333333, longitude: 20.883333 },
  { name: 'DB461', latitude: 52.333336, longitude: 13.795161 },
  { name: 'PO482', latitude: 52.335583, longitude: 17.159806 },
  { name: 'BAMSO', latitude: 52.335861, longitude: 21.909583 },
  { name: 'CI2LZ', latitude: 52.336064, longitude: 18.074183 },
  { name: 'DB240', latitude: 52.336183, longitude: 13.352742 },
  { name: 'PO804', latitude: 52.337667, longitude: 16.760972 },
  { name: 'PO703', latitude: 52.337889, longitude: 17.251611 },
  { name: 'D245A', latitude: 52.338447, longitude: 13.438817 },
  { name: 'VP226', latitude: 52.34, longitude: 17.556389 },
  { name: 'WA614', latitude: 52.341361, longitude: 20.812833 },
  { name: 'EPOGI', latitude: 52.341944, longitude: 40.624167 },
  { name: 'PO641', latitude: 52.342306, longitude: 16.187389 },
  { name: 'ML07R', latitude: 52.342311, longitude: 13.455294 },
  { name: 'VP224', latitude: 52.343333, longitude: 21.227778 },
  { name: 'TISEX', latitude: 52.34375, longitude: 21.15525 },
  { name: 'PO611', latitude: 52.344472, longitude: 17.219583 },
  { name: 'FI07L', latitude: 52.344483, longitude: 13.387894 },
  { name: 'D091R', latitude: 52.344694, longitude: 18.336639 },
  { name: 'DB860', latitude: 52.345433, longitude: 13.468422 },
  { name: 'FN28L', latitude: 52.345697, longitude: 18.028836 },
  { name: 'BITVA', latitude: 52.346111, longitude: 21.086472 },
  { name: 'FI28L', latitude: 52.346167, longitude: 18.022389 },
  { name: 'WA791', latitude: 52.346778, longitude: 20.773889 },
  { name: 'WA798', latitude: 52.347222, longitude: 20.885556 },
  { name: 'DOSIX', latitude: 52.348056, longitude: 19.2275 },
  { name: 'TIRAS', latitude: 52.35, longitude: 33.31 },
  { name: 'PO926', latitude: 52.350611, longitude: 16.632167 },
  { name: 'KL30C', latitude: 52.351917, longitude: 12.957442 },
  { name: 'DB441', latitude: 52.352333, longitude: 13.7982 },
  { name: '27LOC', latitude: 52.352411, longitude: 13.420769 },
  { name: 'FT2LY', latitude: 52.35275, longitude: 18.0 },
  { name: 'FT2LZ', latitude: 52.35275, longitude: 18.008389 },
  { name: 'JACOW', latitude: 52.353675, longitude: 14.244833 },
  { name: 'CELWO', latitude: 52.354878, longitude: 16.895658 },
  { name: 'DB462', latitude: 52.357111, longitude: 13.896767 },
  { name: 'PO921', latitude: 52.3575, longitude: 17.152111 },
  { name: 'DB960', latitude: 52.358425, longitude: 13.523158 },
  { name: 'DB060', latitude: 52.358425, longitude: 13.523161 },
  { name: 'TABET', latitude: 52.358611, longitude: 20.602444 },
  { name: 'MAPAT', latitude: 52.359722, longitude: 24.984722 },
  { name: 'LUXIB', latitude: 52.360278, longitude: 20.641611 },
  { name: 'D330M', latitude: 52.360556, longitude: 20.821194 },
  { name: 'ZIXNE', latitude: 52.360611, longitude: 21.442861 },
  { name: 'WA572', latitude: 52.360611, longitude: 21.483167 },
  { name: 'MD25L', latitude: 52.361072, longitude: 13.535264 },
  { name: 'ML25L', latitude: 52.361539, longitude: 13.5363 },
  { name: 'DB810', latitude: 52.361878, longitude: 13.460558 },
  { name: 'NUNBI', latitude: 52.363389, longitude: 17.122222 },
  { name: 'OFIFE', latitude: 52.363583, longitude: 20.475889 },
  { name: 'ML07L', latitude: 52.364464, longitude: 13.471447 },
  { name: 'FT28R', latitude: 52.366472, longitude: 18.012389 },
  { name: 'PO803', latitude: 52.367583, longitude: 16.605417 },
  { name: 'DB242', latitude: 52.367708, longitude: 13.161922 },
  { name: 'PO483', latitude: 52.368583, longitude: 17.035028 },
  { name: 'PO712', latitude: 52.368944, longitude: 16.47075 },
  { name: 'PO702', latitude: 52.369056, longitude: 17.093333 },
  { name: 'DB063', latitude: 52.370003, longitude: 13.698972 },
  { name: 'KL30A', latitude: 52.371025, longitude: 12.986578 },
  { name: 'D200C', latitude: 52.371794, longitude: 14.101831 },
  { name: 'DB851', latitude: 52.372778, longitude: 13.887008 },
  { name: '27LO2', latitude: 52.3736, longitude: 13.587275 },
  { name: 'BIMPA', latitude: 52.374389, longitude: 19.774722 },
  { name: 'DB059', latitude: 52.374433, longitude: 13.767308 },
  { name: 'MT2RZ', latitude: 52.375278, longitude: 17.877194 },
  { name: 'PO414', latitude: 52.375556, longitude: 17.147583 },
  { name: 'VP227', latitude: 52.375833, longitude: 16.62 },
  { name: 'MT2RY', latitude: 52.375972, longitude: 17.866028 },
  { name: 'DB442', latitude: 52.376106, longitude: 13.89985 },
  { name: 'DB910', latitude: 52.377431, longitude: 13.526094 },
  { name: 'FT11', latitude: 52.377667, longitude: 16.812389 },
  { name: 'VP228', latitude: 52.377778, longitude: 16.825556 },
  { name: 'VP229', latitude: 52.377778, longitude: 20.186944 },
  { name: 'VP230', latitude: 52.377778, longitude: 20.916667 },
  { name: 'HALFI', latitude: 52.378556, longitude: 20.687306 },
  { name: 'PO701', latitude: 52.379194, longitude: 17.041528 },
  { name: 'SUBIX', latitude: 52.379722, longitude: 14.585556 },
  { name: 'ML25R', latitude: 52.379986, longitude: 13.536889 },
  { name: 'DB531', latitude: 52.380119, longitude: 13.007386 },
  { name: '65VOR', latitude: 52.380667, longitude: 13.619092 },
  { name: 'DB463', latitude: 52.3808, longitude: 13.998481 },
  { name: 'SUBEG', latitude: 52.380917, longitude: 20.9325 },
  { name: 'FI25L', latitude: 52.381392, longitude: 13.620258 },
  { name: 'D023Y', latitude: 52.382303, longitude: 13.865939 },
  { name: 'D200B', latitude: 52.382439, longitude: 14.109569 },
  { name: 'MO859', latitude: 52.382861, longitude: 21.277861 },
  { name: 'MT10L', latitude: 52.383778, longitude: 17.825306 },
  { name: 'PO901', latitude: 52.387944, longitude: 16.996694 },
  { name: 'MENCI', latitude: 52.388583, longitude: 20.697222 },
  { name: 'GIGDO', latitude: 52.389167, longitude: 38.931111 },
  { name: 'OKEKI', latitude: 52.389194, longitude: 16.492333 },
  { name: 'UBONA', latitude: 52.389722, longitude: 37.109722 },
  { name: 'LUHSA', latitude: 52.390028, longitude: 20.8845 },
  { name: 'MO943', latitude: 52.390861, longitude: 20.330139 },
  { name: '26LOC', latitude: 52.391989, longitude: 13.587631 },
  { name: 'SOBLU', latitude: 52.392222, longitude: 12.272778 },
  { name: 'OSBIG', latitude: 52.3925, longitude: 31.227222 },
  { name: 'DB800', latitude: 52.394361, longitude: 13.979539 },
  { name: 'VP231', latitude: 52.394722, longitude: 20.540556 },
  { name: 'PENEK', latitude: 52.395833, longitude: 12.646667 },
  { name: 'PO802', latitude: 52.397306, longitude: 16.449611 },
  { name: 'TUVAK', latitude: 52.399789, longitude: 14.070761 },
  { name: 'WA616', latitude: 52.399944, longitude: 20.761167 },
  { name: 'DB058', latitude: 52.400228, longitude: 13.622472 },
  { name: 'ADLIR', latitude: 52.400278, longitude: 13.621944 },
  { name: 'FI25R', latitude: 52.400344, longitude: 13.623189 },
  { name: 'DEXUG', latitude: 52.400414, longitude: 12.260194 },
  { name: 'ERTIM', latitude: 52.401111, longitude: 17.410278 },
  { name: 'PO927', latitude: 52.403028, longitude: 16.356917 },
  { name: 'D008M', latitude: 52.404356, longitude: 21.750969 },
  { name: 'DB464', latitude: 52.404403, longitude: 14.1003 },
  { name: 'MO951', latitude: 52.405083, longitude: 20.409944 },
  { name: 'PO484', latitude: 52.405972, longitude: 16.892833 },
  { name: 'PO699', latitude: 52.406611, longitude: 16.900778 },
  { name: 'KUXEN', latitude: 52.407694, longitude: 20.198444 },
  { name: 'KY10', latitude: 52.408319, longitude: 16.704644 },
  { name: 'DOZAL', latitude: 52.410278, longitude: 30.287222 },
  { name: 'XANIM', latitude: 52.411089, longitude: 13.746475 },
  { name: 'MO884', latitude: 52.411528, longitude: 20.142194 },
  { name: 'VP232', latitude: 52.4125, longitude: 17.8675 },
  { name: 'FT10R', latitude: 52.412778, longitude: 17.697778 },
  { name: 'FI10R', latitude: 52.413083, longitude: 17.673778 },
  { name: 'FN10R', latitude: 52.413531, longitude: 17.665456 },
  { name: 'GUBNU', latitude: 52.413833, longitude: 16.362389 },
  { name: 'ML28', latitude: 52.414361, longitude: 16.860806 },
  { name: 'KLF28', latitude: 52.41675, longitude: 13.954553 },
  { name: 'FT10L', latitude: 52.4175, longitude: 17.701111 },
  { name: 'EKTIZ', latitude: 52.4178, longitude: 18.0 },
  { name: 'OGRAR', latitude: 52.418167, longitude: 20.305972 },
  { name: 'MD28', latitude: 52.419333, longitude: 16.841806 },
  { name: 'SOSUD', latitude: 52.419722, longitude: 24.964722 },
  { name: 'MO853', latitude: 52.420944, longitude: 19.996972 },
  { name: 'MO883', latitude: 52.422056, longitude: 20.277194 },
  { name: 'UPKOF', latitude: 52.42225, longitude: 20.769306 },
  { name: 'CI10R', latitude: 52.423667, longitude: 17.617647 },
  { name: 'GODIL', latitude: 52.424694, longitude: 20.373194 },
  { name: 'PO301', latitude: 52.424972, longitude: 16.806 },
  { name: 'RERMU', latitude: 52.425, longitude: 17.297583 },
  { name: 'MD10', latitude: 52.425833, longitude: 16.806389 },
  { name: 'XESNA', latitude: 52.425833, longitude: 22.295833 },
  { name: 'DB465', latitude: 52.427914, longitude: 14.202228 },
  { name: 'D271L', latitude: 52.428289, longitude: 13.805103 },
  { name: 'MO823', latitude: 52.428583, longitude: 20.413722 },
  { name: 'FF08', latitude: 52.428586, longitude: 20.413725 },
  { name: 'VP233', latitude: 52.429167, longitude: 20.654167 },
  { name: 'MO882', latitude: 52.429333, longitude: 20.37175 },
  { name: 'ERDUX', latitude: 52.431139, longitude: 12.818861 },
  { name: 'ULNER', latitude: 52.431389, longitude: 36.188056 },
  { name: 'VP261', latitude: 52.431667, longitude: 13.42 },
  { name: 'VP234', latitude: 52.432222, longitude: 17.496944 },
  { name: 'MO881', latitude: 52.432444, longitude: 20.412278 },
  { name: 'MO856', latitude: 52.432667, longitude: 21.169278 },
  { name: 'D228K', latitude: 52.432778, longitude: 30.756667 },
  { name: 'DB452', latitude: 52.434906, longitude: 13.848231 },
  { name: 'DB066', latitude: 52.435714, longitude: 13.773514 },
  { name: 'VP235', latitude: 52.436111, longitude: 18.082778 },
  { name: 'DB521', latitude: 52.436281, longitude: 12.939825 },
  { name: 'CT10R', latitude: 52.436344, longitude: 17.596125 },
  { name: 'D330R', latitude: 52.436528, longitude: 20.765556 },
  { name: 'ERUNI', latitude: 52.4375, longitude: 21.737222 },
  { name: 'MO993', latitude: 52.437778, longitude: 20.50975 },
  { name: 'TEVGU', latitude: 52.438056, longitude: 21.584444 },
  { name: 'D290O', latitude: 52.438917, longitude: 16.594944 },
  { name: 'DB068', latitude: 52.439128, longitude: 13.788103 },
  { name: 'MO824', latitude: 52.439333, longitude: 20.526028 },
  { name: 'ELGEL', latitude: 52.4395, longitude: 20.880917 },
  { name: 'DB069', latitude: 52.440036, longitude: 13.791975 },
  { name: 'ARVUD', latitude: 52.4425, longitude: 18.18 },
  { name: 'KY15', latitude: 52.443264, longitude: 16.581097 },
  { name: '41VOR', latitude: 52.444133, longitude: 20.566914 },
  { name: 'CT10L', latitude: 52.444406, longitude: 17.601536 },
  { name: 'NIBOK', latitude: 52.444722, longitude: 39.599167 },
  { name: 'PO413', latitude: 52.445667, longitude: 16.669778 },
  { name: 'ML08', latitude: 52.447694, longitude: 20.614389 },
  { name: 'UBURU', latitude: 52.448706, longitude: 13.829058 },
  { name: 'PATFO', latitude: 52.449861, longitude: 20.670167 },
  { name: 'PO601', latitude: 52.450722, longitude: 16.672083 },
  { name: 'DB466', latitude: 52.451342, longitude: 14.304267 },
  { name: 'VEMAL', latitude: 52.451667, longitude: 19.171667 },
  { name: 'VP236', latitude: 52.453056, longitude: 16.98 },
  { name: 'MO806', latitude: 52.454361, longitude: 20.704806 },
  { name: 'DB243', latitude: 52.456922, longitude: 13.189494 },
  { name: 'MO833', latitude: 52.457389, longitude: 20.717806 },
  { name: 'VP237', latitude: 52.457778, longitude: 17.891111 },
  { name: 'DB453', latitude: 52.458633, longitude: 13.950097 },
  { name: 'PO602', latitude: 52.460694, longitude: 16.620111 },
  { name: 'D249N', latitude: 52.461389, longitude: 30.693333 },
  { name: 'D105K', latitude: 52.461667, longitude: 31.273333 },
  { name: 'KLF40', latitude: 52.462072, longitude: 12.753431 },
  { name: 'ROMLU', latitude: 52.462222, longitude: 26.234167 },
  { name: 'D289H', latitude: 52.463183, longitude: 13.929925 },
  { name: 'WA444', latitude: 52.464694, longitude: 20.079444 },
  { name: 'PO412', latitude: 52.464722, longitude: 16.537194 },
  { name: 'VP238', latitude: 52.465278, longitude: 16.859444 },
  { name: '27VOR', latitude: 52.466033, longitude: 20.747869 },
  { name: 'MO866', latitude: 52.466361, longitude: 20.814028 },
  { name: 'D103K', latitude: 52.466389, longitude: 31.276389 },
  { name: 'BANPI', latitude: 52.466778, longitude: 17.085083 },
  { name: 'XANAR', latitude: 52.467778, longitude: 20.726667 },
  { name: 'ETUBO', latitude: 52.469167, longitude: 22.9625 },
  { name: 'DOMUV', latitude: 52.469667, longitude: 20.197028 },
  { name: 'MO998', latitude: 52.470583, longitude: 20.85975 },
  { name: 'DB443', latitude: 52.47245, longitude: 13.930944 },
  { name: 'MEZLA', latitude: 52.473611, longitude: 30.298333 },
  { name: 'VP239', latitude: 52.474444, longitude: 20.648611 },
  { name: 'CI28', latitude: 52.474556, longitude: 31.242269 },
  { name: 'DB467', latitude: 52.474678, longitude: 14.406411 },
  { name: 'VP240', latitude: 52.474722, longitude: 18.884722 },
  { name: 'MO997', latitude: 52.475056, longitude: 21.120722 },
  { name: 'OBELI', latitude: 52.476583, longitude: 16.536861 },
  { name: 'PO711', latitude: 52.478111, longitude: 16.529278 },
  { name: 'VP241', latitude: 52.479167, longitude: 17.277222 },
  { name: 'KLF31', latitude: 52.479558, longitude: 13.917172 },
  { name: 'MO994', latitude: 52.480639, longitude: 20.967528 },
  { name: 'PUMQA', latitude: 52.482222, longitude: 16.811389 },
  { name: 'DB454', latitude: 52.482275, longitude: 14.052069 },
  { name: 'PO411', latitude: 52.483667, longitude: 16.404472 },
  { name: 'PO801', latitude: 52.484722, longitude: 16.494083 },
  { name: 'FD26', latitude: 52.486583, longitude: 20.853917 },
  { name: 'UROBI', latitude: 52.486667, longitude: 39.139444 },
  { name: 'D297Q', latitude: 52.487072, longitude: 13.056764 },
  { name: 'PO486', latitude: 52.487528, longitude: 16.579472 },
  { name: 'KL40A', latitude: 52.487614, longitude: 12.792267 },
  { name: 'VP242', latitude: 52.488611, longitude: 20.923611 },
  { name: 'KUBUM', latitude: 52.489406, longitude: 13.192097 },
  { name: 'MO803', latitude: 52.489889, longitude: 20.176861 },
  { name: 'FI28', latitude: 52.490278, longitude: 31.174167 },
  { name: 'ORBUZ', latitude: 52.490833, longitude: 21.438333 },
  { name: 'FD28', latitude: 52.491111, longitude: 31.160833 },
  { name: 'D288M', latitude: 52.491308, longitude: 13.804464 },
  { name: 'INZUB', latitude: 52.491472, longitude: 20.517583 },
  { name: 'KY22', latitude: 52.491964, longitude: 16.407806 },
  { name: 'DB244', latitude: 52.493292, longitude: 13.246383 },
  { name: 'FN28', latitude: 52.493344, longitude: 31.160656 },
  { name: 'DB245', latitude: 52.493767, longitude: 13.277611 },
  { name: 'XENIS', latitude: 52.494111, longitude: 20.892917 },
  { name: 'DB444', latitude: 52.496108, longitude: 14.032939 },
  { name: 'INSON', latitude: 52.496944, longitude: 20.958056 },
  { name: 'TUKMO', latitude: 52.498889, longitude: 40.4775 },
  { name: 'INRAS', latitude: 52.498972, longitude: 20.792167 },
  { name: 'AQALE', latitude: 52.5, longitude: 21.183333 },
  { name: 'GUWEN', latitude: 52.5, longitude: 22.058333 },
  { name: 'MO804', latitude: 52.5005, longitude: 20.284556 },
  { name: 'DUDCO', latitude: 52.500586, longitude: 17.79585 },
  { name: 'OL011', latitude: 52.500667, longitude: 39.484861 },
  { name: 'PO603', latitude: 52.501278, longitude: 16.406694 },
  { name: 'DB067', latitude: 52.502414, longitude: 13.784775 },
  { name: 'DB915', latitude: 52.502561, longitude: 13.513183 },
  { name: 'DB246', latitude: 52.502639, longitude: 13.114708 },
  { name: 'AY100', latitude: 52.50335, longitude: 13.770736 },
  { name: 'PO851', latitude: 52.503889, longitude: 16.140139 },
  { name: 'DB511', latitude: 52.505347, longitude: 12.856439 },
  { name: 'DB455', latitude: 52.505831, longitude: 14.154153 },
  { name: 'VP243', latitude: 52.505833, longitude: 19.836667 },
  { name: 'MO926', latitude: 52.506611, longitude: 20.957944 },
  { name: 'MO854', latitude: 52.509222, longitude: 20.991472 },
  { name: 'PO852', latitude: 52.509611, longitude: 16.030694 },
  { name: 'D300K', latitude: 52.510667, longitude: 13.888611 },
  { name: 'XIGRI', latitude: 52.510833, longitude: 14.613611 },
  { name: 'VP244', latitude: 52.511389, longitude: 16.740278 },
  { name: 'ERAKU', latitude: 52.512222, longitude: 13.036389 },
  { name: 'KEWLU', latitude: 52.5125, longitude: 20.3425 },
  { name: 'DB853', latitude: 52.512583, longitude: 13.799581 },
  { name: 'MO842', latitude: 52.513361, longitude: 21.334694 },
  { name: 'UNGES', latitude: 52.513889, longitude: 30.695556 },
  { name: 'D008T', latitude: 52.518278, longitude: 21.791278 },
  { name: 'DB445', latitude: 52.519681, longitude: 14.135044 },
  { name: 'UBOMI', latitude: 52.519722, longitude: 15.671389 },
  { name: 'VP245', latitude: 52.521389, longitude: 17.136111 },
  { name: 'MO996', latitude: 52.521806, longitude: 20.262917 },
  { name: 'NAVDI', latitude: 52.525278, longitude: 14.994167 },
  { name: 'PINOB', latitude: 52.525528, longitude: 14.209678 },
  { name: 'D085J', latitude: 52.526111, longitude: 31.268611 },
  { name: 'GINOK', latitude: 52.526389, longitude: 16.478889 },
  { name: 'MO927', latitude: 52.526583, longitude: 21.062056 },
  { name: 'M078', latitude: 52.527222, longitude: 31.258611 },
  { name: 'DB065', latitude: 52.527847, longitude: 13.719694 },
  { name: 'VP246', latitude: 52.528889, longitude: 17.753333 },
  { name: 'D084J', latitude: 52.528889, longitude: 31.270278 },
  { name: 'DB456', latitude: 52.529297, longitude: 14.256342 },
  { name: 'VP247', latitude: 52.533056, longitude: 21.081389 },
  { name: 'VP248', latitude: 52.533333, longitude: 19.137222 },
  { name: 'PAVZA', latitude: 52.533611, longitude: 20.631111 },
  { name: 'SUTIK', latitude: 52.533889, longitude: 21.401667 },
  { name: 'OL010', latitude: 52.534861, longitude: 39.638444 },
  { name: 'D288T', latitude: 52.534944, longitude: 13.624467 },
  { name: 'ELSUP', latitude: 52.54, longitude: 15.411389 },
  { name: 'AY620', latitude: 52.540608, longitude: 13.869322 },
  { name: '15VOR', latitude: 52.541111, longitude: 30.958611 },
  { name: 'MERWU', latitude: 52.541514, longitude: 13.842883 },
  { name: 'INDAB', latitude: 52.541667, longitude: 31.320833 },
  { name: 'DB446', latitude: 52.543161, longitude: 14.237256 },
  { name: 'VABAT', latitude: 52.543333, longitude: 16.193611 },
  { name: '21DME', latitude: 52.543611, longitude: 30.945278 },
  { name: 'ZEKNA', latitude: 52.544167, longitude: 37.703889 },
  { name: 'DB433', latitude: 52.545525, longitude: 13.862008 },
  { name: 'MASAD', latitude: 52.546389, longitude: 27.133056 },
  { name: 'MO831', latitude: 52.548028, longitude: 20.785083 },
  { name: 'ANEBO', latitude: 52.548056, longitude: 13.830556 },
  { name: 'VP249', latitude: 52.549167, longitude: 20.501111 },
  { name: 'VP272', latitude: 52.55, longitude: 13.675 },
  { name: 'DB802', latitude: 52.550433, longitude: 13.882231 },
  { name: 'DOMAN', latitude: 52.551389, longitude: 24.841389 },
  { name: 'DB457', latitude: 52.552675, longitude: 14.358642 },
  { name: 'AY110', latitude: 52.553644, longitude: 13.699222 },
  { name: 'TITLU', latitude: 52.554889, longitude: 20.755861 },
  { name: 'GINSO', latitude: 52.556944, longitude: 24.454722 },
  { name: 'OVCAB', latitude: 52.557222, longitude: 18.183333 },
  { name: 'FD10', latitude: 52.5575, longitude: 30.868611 },
  { name: 'SURUG', latitude: 52.559722, longitude: 24.221389 },
  { name: 'PO902', latitude: 52.560667, longitude: 17.03075 },
  { name: 'FQ10', latitude: 52.560778, longitude: 30.871844 },
  { name: 'EMFED', latitude: 52.562222, longitude: 17.560278 },
  { name: 'IMWEG', latitude: 52.56275, longitude: 20.947389 },
  { name: 'PENEX', latitude: 52.563056, longitude: 19.277222 },
  { name: 'FI10', latitude: 52.563611, longitude: 30.858333 },
  { name: 'FWE39', latitude: 52.563856, longitude: 13.097058 },
  { name: 'NEPSU', latitude: 52.563944, longitude: 16.581583 },
  { name: 'DB447', latitude: 52.566556, longitude: 14.339575 },
  { name: 'BAPOV', latitude: 52.566667, longitude: 12.083889 },
  { name: 'SIRLU', latitude: 52.566667, longitude: 12.233611 },
  { name: 'UVROB', latitude: 52.566944, longitude: 21.021667 },
  { name: 'OL012', latitude: 52.568889, longitude: 39.792278 },
  { name: 'VP250', latitude: 52.570833, longitude: 19.611667 },
  { name: 'MO834', latitude: 52.571556, longitude: 20.710639 },
  { name: 'PO841', latitude: 52.572111, longitude: 16.538722 },
  { name: 'VP275', latitude: 52.573611, longitude: 13.261389 },
  { name: 'DB434', latitude: 52.573953, longitude: 13.984492 },
  { name: 'AY631', latitude: 52.573983, longitude: 13.737725 },
  { name: 'TALID', latitude: 52.574722, longitude: 36.164722 },
  { name: 'VP251', latitude: 52.576111, longitude: 17.009444 },
  { name: 'CI10', latitude: 52.578853, longitude: 30.791606 },
  { name: 'DIGOS', latitude: 52.579444, longitude: 30.541944 },
  { name: 'VP252', latitude: 52.58, longitude: 20.773611 },
  { name: 'PO831', latitude: 52.580889, longitude: 16.258806 },
  { name: 'D279J', latitude: 52.581944, longitude: 30.735833 },
  { name: 'VECNU', latitude: 52.585278, longitude: 21.197278 },
  { name: 'FN33', latitude: 52.588164, longitude: 39.6066 },
  { name: 'DIDPU', latitude: 52.588694, longitude: 16.451194 },
  { name: 'D282J', latitude: 52.590556, longitude: 30.740556 },
  { name: 'OL007', latitude: 52.59075, longitude: 39.605333 },
  { name: 'NAVNU', latitude: 52.591111, longitude: 24.144722 },
  { name: 'UVNUL', latitude: 52.592222, longitude: 17.631389 },
  { name: 'MOLIL', latitude: 52.592222, longitude: 18.0 },
  { name: 'VP278', latitude: 52.593333, longitude: 13.230833 },
  { name: 'D300T', latitude: 52.5966, longitude: 13.677408 },
  { name: 'DB435', latitude: 52.597564, longitude: 14.08675 },
  { name: 'BIKPO', latitude: 52.59805, longitude: 13.539183 },
  { name: 'VP281', latitude: 52.6025, longitude: 13.198333 },
  { name: 'GERGA', latitude: 52.602503, longitude: 14.028136 },
  { name: 'RODEV', latitude: 52.603389, longitude: 20.557556 },
  { name: 'VP253', latitude: 52.603889, longitude: 18.886944 },
  { name: 'GOGER', latitude: 52.604722, longitude: 30.321389 },
  { name: 'EMGIP', latitude: 52.605, longitude: 18.574722 },
  { name: 'VP254', latitude: 52.605833, longitude: 20.399444 },
  { name: 'MO826', latitude: 52.606333, longitude: 20.511 },
  { name: 'VP255', latitude: 52.606389, longitude: 19.796111 },
  { name: 'AY510', latitude: 52.6079, longitude: 13.968889 },
  { name: 'BEREG', latitude: 52.608056, longitude: 30.048056 },
  { name: 'VP282', latitude: 52.608333, longitude: 13.071111 },
  { name: 'OL014', latitude: 52.61275, longitude: 39.592056 },
  { name: 'TOXAR', latitude: 52.613333, longitude: 22.538889 },
  { name: 'OGBER', latitude: 52.613889, longitude: 12.724722 },
  { name: 'D304H', latitude: 52.6175, longitude: 30.841389 },
  { name: 'D327O', latitude: 52.618578, longitude: 13.941703 },
  { name: 'PO832', latitude: 52.621083, longitude: 16.159861 },
  { name: 'DB436', latitude: 52.621089, longitude: 14.189117 },
  { name: 'G307', latitude: 52.624722, longitude: 30.8375 },
  { name: 'D308H', latitude: 52.628889, longitude: 30.843333 },
  { name: 'AY120', latitude: 52.630733, longitude: 13.845372 },
  { name: 'MO827', latitude: 52.630889, longitude: 20.773028 },
  { name: 'VP256', latitude: 52.631389, longitude: 18.535 },
  { name: 'GILAS', latitude: 52.631667, longitude: 14.525833 },
  { name: 'VP257', latitude: 52.6325, longitude: 17.428611 },
  { name: 'OCHUS', latitude: 52.632928, longitude: 14.016558 },
  { name: 'DB423', latitude: 52.634744, longitude: 13.791097 },
  { name: 'ERPAB', latitude: 52.635, longitude: 18.319167 },
  { name: 'AY330', latitude: 52.636672, longitude: 13.870928 },
  { name: 'OL006', latitude: 52.638528, longitude: 39.576639 },
  { name: 'VP258', latitude: 52.643056, longitude: 20.941667 },
  { name: 'DB437', latitude: 52.644525, longitude: 14.291592 },
  { name: 'MO828', latitude: 52.645056, longitude: 20.927139 },
  { name: 'VP284', latitude: 52.648333, longitude: 13.568333 },
  { name: 'MABIR', latitude: 52.65, longitude: 31.548333 },
  { name: 'AY131', latitude: 52.650931, longitude: 13.932417 },
  { name: 'BAISA', latitude: 52.651417, longitude: 16.626528 },
  { name: 'PO741', latitude: 52.651417, longitude: 16.976806 },
  { name: 'PO903', latitude: 52.651639, longitude: 16.973111 },
  { name: 'SUTQO', latitude: 52.651719, longitude: 16.972186 },
  { name: 'OL013', latitude: 52.653361, longitude: 39.741917 },
  { name: 'VP259', latitude: 52.656944, longitude: 19.133889 },
  { name: 'YODQA', latitude: 52.657928, longitude: 16.455194 },
  { name: 'ACTAF', latitude: 52.660836, longitude: 16.241006 },
  { name: 'POBIM', latitude: 52.666111, longitude: 30.789167 },
  { name: 'KONCE', latitude: 52.670833, longitude: 18.7625 },
  { name: 'AY400', latitude: 52.670942, longitude: 14.089133 },
  { name: 'VP260', latitude: 52.673889, longitude: 17.152778 },
  { name: 'PO842', latitude: 52.674278, longitude: 16.608556 },
  { name: 'VP261', latitude: 52.677222, longitude: 18.0075 },
  { name: 'ATKUN', latitude: 52.677222, longitude: 18.07 },
  { name: 'MO829', latitude: 52.682972, longitude: 20.491333 },
  { name: 'RIMKO', latitude: 52.685833, longitude: 12.681944 },
  { name: 'MO836', latitude: 52.687417, longitude: 20.760833 },
  { name: 'OL017', latitude: 52.688861, longitude: 39.683861 },
  { name: 'OGARA', latitude: 52.689722, longitude: 27.238056 },
  { name: 'DER15', latitude: 52.692664, longitude: 39.544142 },
  { name: 'SORIX', latitude: 52.695278, longitude: 19.82 },
  { name: 'IDEXU', latitude: 52.697222, longitude: 17.036111 },
  { name: 'MAKOD', latitude: 52.698056, longitude: 35.508056 },
  { name: 'AY420', latitude: 52.703825, longitude: 14.097622 },
  { name: 'OLILA', latitude: 52.704444, longitude: 20.328333 },
  { name: 'RATMO', latitude: 52.705278, longitude: 12.429722 },
  { name: 'D216P', latitude: 52.707003, longitude: 12.854572 },
  { name: 'UPCET', latitude: 52.709722, longitude: 16.911389 },
  { name: 'DER33', latitude: 52.712439, longitude: 39.532244 },
  { name: 'PO928', latitude: 52.718222, longitude: 15.913028 },
  { name: 'DB413', latitude: 52.719042, longitude: 13.7238 },
  { name: 'BUSUG', latitude: 52.719722, longitude: 33.675 },
  { name: 'ODMIQ', latitude: 52.722892, longitude: 17.002283 },
  { name: 'PO833', latitude: 52.725833, longitude: 15.900139 },
  { name: 'AY121', latitude: 52.730433, longitude: 13.886008 },
  { name: 'VP262', latitude: 52.731944, longitude: 18.269722 },
  { name: 'UVOGE', latitude: 52.733333, longitude: 20.9 },
  { name: 'PULWI', latitude: 52.738111, longitude: 16.919167 },
  { name: 'PO742', latitude: 52.738111, longitude: 16.919167 },
  { name: 'EVOKI', latitude: 52.745556, longitude: 12.934722 },
  { name: 'OL009', latitude: 52.746306, longitude: 39.511833 },
  { name: 'DUCGO', latitude: 52.75, longitude: 20.616667 },
  { name: 'PO904', latitude: 52.751194, longitude: 16.899278 },
  { name: 'GOWDA', latitude: 52.751667, longitude: 28.955 },
  { name: 'AY231', latitude: 52.758761, longitude: 14.111836 },
  { name: 'OL015', latitude: 52.769083, longitude: 39.498111 },
  { name: 'GIKSU', latitude: 52.769722, longitude: 31.594722 },
  { name: 'BULEP', latitude: 52.771111, longitude: 22.418889 },
  { name: 'OL008', latitude: 52.773222, longitude: 39.634 },
  { name: 'PO743', latitude: 52.779139, longitude: 16.367583 },
  { name: 'CT05', latitude: 52.784128, longitude: 18.220581 },
  { name: 'BAGAM', latitude: 52.786389, longitude: 29.423056 },
  { name: 'ATMIR', latitude: 52.787778, longitude: 17.463333 },
  { name: 'OL005', latitude: 52.7945, longitude: 39.482778 },
  { name: 'FT05', latitude: 52.795556, longitude: 18.249722 },
  { name: 'MEZPO', latitude: 52.799722, longitude: 21.344722 },
  { name: 'DB403', latitude: 52.805875, longitude: 13.654178 },
  { name: 'IRFIL', latitude: 52.809167, longitude: 24.363611 },
  { name: 'MEBIV', latitude: 52.81, longitude: 20.133889 },
  { name: 'ABSAG', latitude: 52.812222, longitude: 14.224722 },
  { name: 'VP263', latitude: 52.814167, longitude: 18.471667 },
  { name: 'OL016', latitude: 52.815611, longitude: 39.470056 },
  { name: 'DENKO', latitude: 52.816861, longitude: 15.8325 },
  { name: 'RUPIR', latitude: 52.818056, longitude: 12.690278 },
  { name: 'FN15', latitude: 52.819083, longitude: 39.468114 },
  { name: 'OL004', latitude: 52.821, longitude: 39.642361 },
  { name: 'IPRIG', latitude: 52.828889, longitude: 40.1675 },
  { name: 'UBAKA', latitude: 52.829, longitude: 12.255833 },
  { name: 'MT23', latitude: 52.833333, longitude: 18.342833 },
  { name: 'IVDIR', latitude: 52.833333, longitude: 21.75 },
  { name: 'D216F', latitude: 52.8335, longitude: 13.028631 },
  { name: 'VP264', latitude: 52.833889, longitude: 18.653611 },
  { name: 'OL002', latitude: 52.837222, longitude: 39.281778 },
  { name: 'MAGAN', latitude: 52.85, longitude: 17.616667 },
  { name: 'RUDKA', latitude: 52.851389, longitude: 23.921944 },
  { name: 'UNIGA', latitude: 52.853056, longitude: 34.653056 },
  { name: 'VP265', latitude: 52.856111, longitude: 18.198889 },
  { name: 'EKBUT', latitude: 52.863889, longitude: 15.004722 },
  { name: 'FT23', latitude: 52.866667, longitude: 18.404444 },
  { name: 'OL001', latitude: 52.871444, longitude: 39.436556 },
  { name: 'BIRMO', latitude: 52.872222, longitude: 12.068611 },
  { name: 'FN23Z', latitude: 52.875756, longitude: 18.43045 },
  { name: 'CT23', latitude: 52.879494, longitude: 18.427547 },
  { name: 'FN23', latitude: 52.8857, longitude: 18.451608 },
  { name: 'WODZU', latitude: 52.8875, longitude: 22.141667 },
  { name: 'VP266', latitude: 52.894167, longitude: 18.316111 },
  { name: 'ALUKA', latitude: 52.899167, longitude: 14.306667 },
  { name: 'VP267', latitude: 52.903333, longitude: 18.141111 },
  { name: 'OL003', latitude: 52.905444, longitude: 39.591667 },
  { name: 'ERGON', latitude: 52.906111, longitude: 14.156667 },
  { name: 'NAPUT', latitude: 52.906667, longitude: 20.559167 },
  { name: 'TACEV', latitude: 52.908611, longitude: 20.358333 },
  { name: 'URUKO', latitude: 52.914722, longitude: 33.966389 },
  { name: 'RENKI', latitude: 52.914842, longitude: 13.961678 },
  { name: 'BESOT', latitude: 52.921667, longitude: 22.944444 },
  { name: 'USUNU', latitude: 52.921944, longitude: 12.850556 },
  { name: 'RONEX', latitude: 52.9225, longitude: 16.225278 },
  { name: 'DISEK', latitude: 52.924444, longitude: 14.867778 },
  { name: 'PINUG', latitude: 52.9275, longitude: 25.697778 },
  { name: 'KETAP', latitude: 52.927853, longitude: 13.654947 },
  { name: 'NELIM', latitude: 52.943056, longitude: 24.163056 },
  { name: 'TABOK', latitude: 52.943333, longitude: 13.268056 },
  { name: 'RADEL', latitude: 52.949444, longitude: 13.105278 },
  { name: 'INDOK', latitude: 52.951389, longitude: 12.132222 },
  { name: 'VP268', latitude: 52.951667, longitude: 17.920278 },
  { name: 'GANVI', latitude: 52.954722, longitude: 26.403056 },
  { name: 'MAMIN', latitude: 52.958333, longitude: 30.936389 },
  { name: 'PODUS', latitude: 52.959167, longitude: 12.830556 },
  { name: 'UNELO', latitude: 52.959167, longitude: 19.491944 },
  { name: 'TIKRA', latitude: 52.966389, longitude: 37.079722 },
  { name: 'TOBLO', latitude: 52.968333, longitude: 31.359722 },
  { name: 'VP269', latitude: 52.9725, longitude: 17.722778 },
  { name: 'VIBIS', latitude: 52.975206, longitude: 12.338581 },
  { name: 'VP270', latitude: 52.976111, longitude: 18.099444 },
  { name: 'VIKAH', latitude: 52.986389, longitude: 23.180833 },
  { name: 'BY634', latitude: 52.986583, longitude: 17.866778 },
  { name: 'ABUTO', latitude: 52.988056, longitude: 33.873056 },
  { name: 'BY643', latitude: 52.991139, longitude: 17.450639 },
  { name: 'BY802', latitude: 52.991972, longitude: 17.733917 },
  { name: 'BY642', latitude: 52.994972, longitude: 17.71425 },
  { name: 'PABGA', latitude: 52.999722, longitude: 33.226389 },
  { name: '5313E', latitude: 53.0, longitude: 13.0 },
  { name: '5314E', latitude: 53.0, longitude: 14.0 },
  { name: '5315E', latitude: 53.0, longitude: 15.0 },
  { name: '5316E', latitude: 53.0, longitude: 16.0 },
  { name: '5317E', latitude: 53.0, longitude: 17.0 },
  { name: '5318E', latitude: 53.0, longitude: 18.0 },
  { name: '5319E', latitude: 53.0, longitude: 19.0 },
  { name: '5320E', latitude: 53.0, longitude: 20.0 },
  { name: '5321E', latitude: 53.0, longitude: 21.0 },
  { name: '5322E', latitude: 53.0, longitude: 22.0 },
  { name: '5323E', latitude: 53.0, longitude: 23.0 },
  { name: '5324E', latitude: 53.0, longitude: 24.0 },
  { name: '5325E', latitude: 53.0, longitude: 25.0 },
  { name: '5326E', latitude: 53.0, longitude: 26.0 },
  { name: '5327E', latitude: 53.0, longitude: 27.0 },
  { name: '5328E', latitude: 53.0, longitude: 28.0 },
  { name: '5329E', latitude: 53.0, longitude: 29.0 },
  { name: '5330E', latitude: 53.0, longitude: 30.0 },
  { name: '5331E', latitude: 53.0, longitude: 31.0 },
  { name: '5332E', latitude: 53.0, longitude: 32.0 },
  { name: '5333E', latitude: 53.0, longitude: 33.0 },
  { name: '5334E', latitude: 53.0, longitude: 34.0 },
  { name: '5335E', latitude: 53.0, longitude: 35.0 },
  { name: '5336E', latitude: 53.0, longitude: 36.0 },
  { name: '5337E', latitude: 53.0, longitude: 37.0 },
  { name: '5338E', latitude: 53.0, longitude: 38.0 },
  { name: '5339E', latitude: 53.0, longitude: 39.0 },
  { name: '5340E', latitude: 53.0, longitude: 40.0 },
  { name: 'BY646', latitude: 53.006278, longitude: 17.851694 },
  { name: 'GIBRA', latitude: 53.016389, longitude: 32.398056 },
  { name: 'NETOT', latitude: 53.016389, longitude: 34.364722 },
  { name: 'BY831', latitude: 53.020972, longitude: 17.676139 },
  { name: 'GRINA', latitude: 53.021389, longitude: 24.151389 },
  { name: 'BY636', latitude: 53.022833, longitude: 18.301389 },
  { name: 'GUDOP', latitude: 53.029722, longitude: 13.170833 },
  { name: 'VP271', latitude: 53.03, longitude: 23.123611 },
  { name: 'BP004', latitude: 53.030917, longitude: 34.053306 },
  { name: 'XERBI', latitude: 53.032222, longitude: 15.671111 },
  { name: 'LOLPA', latitude: 53.041389, longitude: 13.6025 },
  { name: 'BP002', latitude: 53.045861, longitude: 34.2185 },
  { name: 'BY647', latitude: 53.046278, longitude: 18.324722 },
  { name: 'FN34V', latitude: 53.048508, longitude: 34.219381 },
  { name: 'VP272', latitude: 53.052222, longitude: 23.335556 },
  { name: 'VP273', latitude: 53.0525, longitude: 17.996111 },
  { name: 'PO843', latitude: 53.056222, longitude: 16.872694 },
  { name: 'BP003', latitude: 53.060806, longitude: 34.383722 },
  { name: 'RILAB', latitude: 53.061111, longitude: 19.633056 },
  { name: 'BY822', latitude: 53.063778, longitude: 17.691833 },
  { name: 'D097I', latitude: 53.064097, longitude: 18.216678 },
  { name: 'D353F', latitude: 53.066183, longitude: 34.17745 },
  { name: 'PO651', latitude: 53.067111, longitude: 16.848472 },
  { name: 'BP015', latitude: 53.067694, longitude: 34.072611 },
  { name: 'D004F', latitude: 53.0691, longitude: 34.144972 },
  { name: 'POTAP', latitude: 53.069722, longitude: 24.268056 },
  { name: 'NASMI', latitude: 53.073889, longitude: 12.957778 },
  { name: 'BY801', latitude: 53.074333, longitude: 17.714333 },
  { name: 'BP014', latitude: 53.080306, longitude: 34.209889 },
  { name: 'DELIR', latitude: 53.080556, longitude: 17.113611 },
  { name: 'BY819', latitude: 53.080611, longitude: 17.827139 },
  { name: 'VP274', latitude: 53.081944, longitude: 23.101667 },
  { name: 'BY806', latitude: 53.084028, longitude: 17.826917 },
  { name: 'BY641', latitude: 53.084556, longitude: 17.833278 },
  { name: 'BY633', latitude: 53.085444, longitude: 17.843583 },
  { name: 'OTMAX', latitude: 53.089722, longitude: 21.195 },
  { name: 'NASOK', latitude: 53.091944, longitude: 19.006389 },
  { name: 'BY632', latitude: 53.093639, longitude: 17.847556 },
  { name: 'CF34', latitude: 53.094547, longitude: 34.206183 },
  { name: 'BP010', latitude: 53.097167, longitude: 34.208306 },
  { name: 'SUSER', latitude: 53.098333, longitude: 17.971389 },
  { name: 'BY832', latitude: 53.098611, longitude: 18.457778 },
  { name: 'ML26', latitude: 53.099833, longitude: 18.013722 },
  { name: '30VOR', latitude: 53.101528, longitude: 18.0545 },
  { name: 'DAKWI', latitude: 53.101944, longitude: 28.676944 },
  { name: '30LOC', latitude: 53.104861, longitude: 18.074028 },
  { name: 'BY631', latitude: 53.105778, longitude: 18.170611 },
  { name: 'BY823', latitude: 53.106611, longitude: 17.58325 },
  { name: 'ABIBO', latitude: 53.1075, longitude: 17.537778 },
  { name: 'BAVNU', latitude: 53.107778, longitude: 20.463333 },
  { name: 'MOTFI', latitude: 53.107778, longitude: 28.374167 },
  { name: 'VP275', latitude: 53.109722, longitude: 23.235833 },
  { name: 'BY814', latitude: 53.110139, longitude: 18.138083 },
  { name: 'TAZIP', latitude: 53.111944, longitude: 28.130278 },
  { name: 'LUXUD', latitude: 53.1125, longitude: 17.265833 },
  { name: 'BY637', latitude: 53.112806, longitude: 18.169694 },
  { name: 'BP006', latitude: 53.113722, longitude: 34.034167 },
  { name: 'ADOBU', latitude: 53.114444, longitude: 24.373889 },
  { name: 'DEKUT', latitude: 53.116111, longitude: 17.035278 },
  { name: 'CF26', latitude: 53.117281, longitude: 18.224383 },
  { name: 'LUPAN', latitude: 53.118056, longitude: 27.676389 },
  { name: 'BY824', latitude: 53.121472, longitude: 18.161472 },
  { name: 'BY623', latitude: 53.121778, longitude: 18.279167 },
  { name: 'ABIKA', latitude: 53.124167, longitude: 13.787222 },
  { name: 'FI34', latitude: 53.127456, longitude: 34.197903 },
  { name: 'VP276', latitude: 53.128056, longitude: 16.648889 },
  { name: 'PEMIP', latitude: 53.128056, longitude: 32.244722 },
  { name: 'BP001', latitude: 53.1285, longitude: 34.197667 },
  { name: 'FN34Z', latitude: 53.128636, longitude: 34.197103 },
  { name: 'FN34', latitude: 53.128747, longitude: 34.198325 },
  { name: 'TUPUR', latitude: 53.129722, longitude: 15.815 },
  { name: 'BEKBA', latitude: 53.131944, longitude: 13.675278 },
  { name: 'BP022', latitude: 53.132361, longitude: 34.073111 },
  { name: 'FN34W', latitude: 53.136264, longitude: 34.19635 },
  { name: 'BY809', latitude: 53.138278, longitude: 17.372472 },
  { name: 'VP277', latitude: 53.138889, longitude: 23.058333 },
  { name: 'VP278', latitude: 53.139722, longitude: 17.591667 },
  { name: 'BY804', latitude: 53.140222, longitude: 17.698611 },
  { name: 'BP009', latitude: 53.140472, longitude: 34.030944 },
  { name: 'TEBHE', latitude: 53.143333, longitude: 29.583333 },
  { name: 'NUDMO', latitude: 53.144722, longitude: 18.452222 },
  { name: 'OGREL', latitude: 53.146667, longitude: 24.450278 },
  { name: 'NEWOD', latitude: 53.148611, longitude: 28.751944 },
  { name: 'BP016', latitude: 53.149694, longitude: 34.051667 },
  { name: 'VP279', latitude: 53.153333, longitude: 18.001944 },
  { name: 'BP019', latitude: 53.1545, longitude: 34.366056 },
  { name: 'ASBIM', latitude: 53.156667, longitude: 21.738889 },
  { name: 'VP280', latitude: 53.156944, longitude: 17.888611 },
  { name: 'XECKO', latitude: 53.158333, longitude: 16.285833 },
  { name: 'BY807', latitude: 53.161278, longitude: 17.945278 },
  { name: 'UVCOV', latitude: 53.161944, longitude: 16.446111 },
  { name: 'OLNED', latitude: 53.165833, longitude: 16.6275 },
  { name: 'INTUN', latitude: 53.1675, longitude: 18.696667 },
  { name: 'VP281', latitude: 53.176111, longitude: 18.056389 },
  { name: 'GORAT', latitude: 53.178056, longitude: 23.894167 },
  { name: 'RAKIT', latitude: 53.178592, longitude: 13.911028 },
  { name: 'OL018', latitude: 53.180861, longitude: 39.321056 },
  { name: 'BY629', latitude: 53.18225, longitude: 17.994722 },
  { name: 'VP282', latitude: 53.185278, longitude: 23.262222 },
  { name: 'BY627', latitude: 53.186417, longitude: 18.411306 },
  { name: 'BIBKA', latitude: 53.190833, longitude: 18.955 },
  { name: 'TITUV', latitude: 53.191111, longitude: 22.0275 },
  { name: 'VP283', latitude: 53.193611, longitude: 16.65 },
  { name: 'VP284', latitude: 53.193889, longitude: 16.154444 },
  { name: 'MM34', latitude: 53.1955, longitude: 34.18075 },
  { name: 'BETUP', latitude: 53.198056, longitude: 20.0 },
  { name: 'DER16', latitude: 53.203778, longitude: 34.178639 },
  { name: 'BY628', latitude: 53.204222, longitude: 18.260583 },
  { name: 'VP285', latitude: 53.207778, longitude: 18.378611 },
  { name: 'LUHEZ', latitude: 53.210556, longitude: 29.489444 },
  { name: 'LASGU', latitude: 53.211111, longitude: 19.177222 },
  { name: 'BP018', latitude: 53.211806, longitude: 34.596667 },
  { name: 'IBZEM', latitude: 53.2125, longitude: 18.658333 },
  { name: 'BUGOR', latitude: 53.216667, longitude: 32.464722 },
  { name: 'VP287', latitude: 53.22, longitude: 16.786944 },
  { name: 'BP021', latitude: 53.220944, longitude: 34.050472 },
  { name: 'FORFE', latitude: 53.222222, longitude: 15.586944 },
  { name: 'VP286', latitude: 53.223611, longitude: 18.036944 },
  { name: 'DER34', latitude: 53.225111, longitude: 34.17325 },
  { name: 'SOMOR', latitude: 53.226944, longitude: 19.35 },
  { name: 'GAFUL', latitude: 53.228889, longitude: 28.881667 },
  { name: 'BP017', latitude: 53.230389, longitude: 34.64125 },
  { name: 'BP008', latitude: 53.238389, longitude: 34.635306 },
  { name: 'BY808', latitude: 53.239333, longitude: 18.146778 },
  { name: 'VUXDU', latitude: 53.24, longitude: 15.029167 },
  { name: 'BP020', latitude: 53.243111, longitude: 34.344 },
  { name: 'PEPOX', latitude: 53.244722, longitude: 16.622778 },
  { name: 'BAVGO', latitude: 53.245833, longitude: 18.251111 },
  { name: 'BP007', latitude: 53.247583, longitude: 34.562694 },
  { name: 'LINVO', latitude: 53.249722, longitude: 13.155 },
  { name: 'REWED', latitude: 53.251944, longitude: 25.424722 },
  { name: 'BP005', latitude: 53.254417, longitude: 34.165833 },
  { name: 'UNPEG', latitude: 53.256944, longitude: 22.595556 },
  { name: 'APNEZ', latitude: 53.260556, longitude: 15.283333 },
  { name: 'UDROV', latitude: 53.260833, longitude: 20.833333 },
  { name: 'BESAK', latitude: 53.2625, longitude: 24.989167 },
  { name: 'EMLES', latitude: 53.2725, longitude: 16.468333 },
  { name: 'VP288', latitude: 53.274167, longitude: 16.1225 },
  { name: 'BUSIN', latitude: 53.274722, longitude: 26.514722 },
  { name: 'BODLA', latitude: 53.275833, longitude: 14.41 },
  { name: 'VP289', latitude: 53.276944, longitude: 16.463333 },
  { name: 'OSTEB', latitude: 53.280833, longitude: 38.648333 },
  { name: 'TADAK', latitude: 53.2825, longitude: 16.898889 },
  { name: 'PIXOR', latitude: 53.288611, longitude: 15.476389 },
  { name: 'GEPRI', latitude: 53.290833, longitude: 23.936389 },
  { name: 'FN16X', latitude: 53.291442, longitude: 34.155792 },
  { name: 'EVODO', latitude: 53.294167, longitude: 22.929167 },
  { name: 'FN16Z', latitude: 53.295933, longitude: 34.154603 },
  { name: 'FN16Y', latitude: 53.295981, longitude: 34.155117 },
  { name: 'BP011', latitude: 53.296139, longitude: 34.155222 },
  { name: 'VP290', latitude: 53.298333, longitude: 15.905833 },
  { name: 'SY373', latitude: 53.300889, longitude: 20.836806 },
  { name: 'SY374', latitude: 53.301056, longitude: 20.835722 },
  { name: 'ZAWIK', latitude: 53.301944, longitude: 15.526389 },
  { name: 'SY367', latitude: 53.306778, longitude: 20.840111 },
  { name: 'CF30', latitude: 53.306781, longitude: 16.294278 },
  { name: 'TISNI', latitude: 53.308333, longitude: 34.898333 },
  { name: 'RORSA', latitude: 53.310833, longitude: 24.173611 },
  { name: 'SY369', latitude: 53.324667, longitude: 20.850028 },
  { name: 'VP291', latitude: 53.328889, longitude: 16.040833 },
  { name: 'FN30', latitude: 53.329986, longitude: 16.238047 },
  { name: 'FT30', latitude: 53.331278, longitude: 16.226833 },
  { name: 'VP292', latitude: 53.340278, longitude: 16.245 },
  { name: 'SY811', latitude: 53.345056, longitude: 20.861361 },
  { name: 'FF30', latitude: 53.345194, longitude: 16.202389 },
  { name: 'BP013', latitude: 53.346833, longitude: 34.001 },
  { name: 'KAWAD', latitude: 53.349444, longitude: 30.133056 },
  { name: 'DELON', latitude: 53.349722, longitude: 28.648056 },
  { name: 'PITWI', latitude: 53.35, longitude: 29.079722 },
  { name: 'SC527', latitude: 53.358611, longitude: 14.933861 },
  { name: 'BP012', latitude: 53.359139, longitude: 34.139167 },
  { name: 'SY366', latitude: 53.36375, longitude: 20.652806 },
  { name: 'VP293', latitude: 53.366944, longitude: 16.338889 },
  { name: 'VP294', latitude: 53.368056, longitude: 16.081111 },
  { name: 'VP295', latitude: 53.370833, longitude: 16.427778 },
  { name: 'GOBNI', latitude: 53.371389, longitude: 18.491389 },
  { name: 'VP296', latitude: 53.3725, longitude: 14.863611 },
  { name: 'CF01', latitude: 53.372978, longitude: 20.876886 },
  { name: 'VP297', latitude: 53.374444, longitude: 14.596944 },
  { name: 'BERUN', latitude: 53.374722, longitude: 35.421389 },
  { name: 'KOMEN', latitude: 53.376389, longitude: 25.003056 },
  { name: 'SC533', latitude: 53.378639, longitude: 15.336722 },
  { name: 'ROVEK', latitude: 53.378889, longitude: 14.881667 },
  { name: 'EBOGU', latitude: 53.378889, longitude: 18.149722 },
  { name: 'SC526', latitude: 53.381528, longitude: 15.081861 },
  { name: 'UMVIT', latitude: 53.383333, longitude: 20.666667 },
  { name: 'MIRVO', latitude: 53.388056, longitude: 15.920833 },
  { name: 'VP298', latitude: 53.390278, longitude: 14.695278 },
  { name: 'SY371', latitude: 53.396444, longitude: 20.89 },
  { name: 'SY376', latitude: 53.396472, longitude: 20.889472 },
  { name: 'SY368', latitude: 53.396556, longitude: 20.890028 },
  { name: 'DOSAL', latitude: 53.398611, longitude: 15.392222 },
  { name: 'VP299', latitude: 53.400278, longitude: 14.576944 },
  { name: 'VP300', latitude: 53.405833, longitude: 14.665278 },
  { name: 'DIKBU', latitude: 53.407778, longitude: 24.05 },
  { name: 'SC523', latitude: 53.408833, longitude: 15.029333 },
  { name: 'LAJQU', latitude: 53.411111, longitude: 16.066389 },
  { name: 'VP301', latitude: 53.4125, longitude: 16.099444 },
  { name: 'NEGLE', latitude: 53.412778, longitude: 39.605556 },
  { name: 'SY511', latitude: 53.414, longitude: 20.89975 },
  { name: 'VP302', latitude: 53.420278, longitude: 21.118056 },
  { name: 'WIKUW', latitude: 53.420833, longitude: 19.825 },
  { name: 'W149', latitude: 53.426944, longitude: 24.161111 },
  { name: 'RIMAK', latitude: 53.428056, longitude: 35.856389 },
  { name: 'PIKIK', latitude: 53.430556, longitude: 24.211111 },
  { name: 'LENLO', latitude: 53.433889, longitude: 23.890278 },
  { name: 'ARZIM', latitude: 53.437222, longitude: 29.170278 },
  { name: 'VP303', latitude: 53.438056, longitude: 18.944167 },
  { name: 'OSPER', latitude: 53.439167, longitude: 38.918333 },
  { name: 'MS412', latitude: 53.439333, longitude: 28.121694 },
  { name: 'OTPES', latitude: 53.439444, longitude: 20.349167 },
  { name: 'SC723', latitude: 53.439944, longitude: 14.9385 },
  { name: 'SC614', latitude: 53.439972, longitude: 14.938528 },
  { name: 'VP304', latitude: 53.441944, longitude: 16.166667 },
  { name: 'SY791', latitude: 53.442944, longitude: 20.427889 },
  { name: 'VP305', latitude: 53.443056, longitude: 16.2675 },
  { name: 'SY512', latitude: 53.443667, longitude: 20.750111 },
  { name: 'ZOCEK', latitude: 53.44425, longitude: 15.173361 },
  { name: 'D153J', latitude: 53.451389, longitude: 24.148611 },
  { name: 'FT12', latitude: 53.452944, longitude: 15.928222 },
  { name: 'SY361', latitude: 53.453, longitude: 20.353833 },
  { name: 'MD01', latitude: 53.455444, longitude: 20.922861 },
  { name: 'KOLOS', latitude: 53.456389, longitude: 26.956389 },
  { name: 'VP306', latitude: 53.458333, longitude: 15.945556 },
  { name: 'FN35', latitude: 53.458858, longitude: 24.062064 },
  { name: 'ML01', latitude: 53.461417, longitude: 20.92625 },
  { name: 'SC524', latitude: 53.463361, longitude: 14.924111 },
  { name: 'MS122', latitude: 53.465944, longitude: 28.908111 },
  { name: 'UGNAL', latitude: 53.469722, longitude: 25.001389 },
  { name: 'SY414', latitude: 53.471333, longitude: 20.407333 },
  { name: 'SC522', latitude: 53.471611, longitude: 15.120806 },
  { name: 'VP307', latitude: 53.473889, longitude: 20.9675 },
  { name: 'UNORI', latitude: 53.474722, longitude: 36.248056 },
  { name: 'VAVUN', latitude: 53.475278, longitude: 15.333056 },
  { name: 'VP308', latitude: 53.482778, longitude: 16.345278 },
  { name: 'VP309', latitude: 53.483889, longitude: 18.739722 },
  { name: 'KEDUG', latitude: 53.488333, longitude: 30.798056 },
  { name: 'RIZNI', latitude: 53.491111, longitude: 29.229167 },
  { name: 'PIROS', latitude: 53.491667, longitude: 30.149722 },
  { name: 'VP310', latitude: 53.492222, longitude: 15.860556 },
  { name: 'FF24', latitude: 53.493547, longitude: 12.023981 },
  { name: 'FQ24', latitude: 53.493608, longitude: 12.023492 },
  { name: 'BN708', latitude: 53.494589, longitude: 13.200592 },
  { name: 'GONTU', latitude: 53.495556, longitude: 19.318611 },
  { name: 'ODLOT', latitude: 53.496111, longitude: 17.403889 },
  { name: 'MS121', latitude: 53.499722, longitude: 28.592278 },
  { name: 'CF24', latitude: 53.500517, longitude: 12.049319 },
  { name: 'BN306', latitude: 53.500814, longitude: 13.269964 },
  { name: 'VP311', latitude: 53.501944, longitude: 20.898611 },
  { name: 'MACZU', latitude: 53.504167, longitude: 20.108333 },
  { name: 'FD35', latitude: 53.505, longitude: 24.053056 },
  { name: 'KOLAK', latitude: 53.506389, longitude: 27.688056 },
  { name: 'ARKUG', latitude: 53.506667, longitude: 16.838333 },
  { name: 'SC528', latitude: 53.506917, longitude: 15.265111 },
  { name: 'PESEL', latitude: 53.507778, longitude: 14.325833 },
  { name: 'MA19', latitude: 53.508278, longitude: 20.952528 },
  { name: 'BN603', latitude: 53.510883, longitude: 13.034022 },
  { name: 'BN501', latitude: 53.511811, longitude: 12.982947 },
  { name: 'SC611', latitude: 53.511944, longitude: 15.043083 },
  { name: 'UNVOX', latitude: 53.514167, longitude: 15.526389 },
  { name: 'MG04', latitude: 53.520278, longitude: 24.418889 },
  { name: 'BN709', latitude: 53.520325, longitude: 13.372381 },
  { name: 'BN502', latitude: 53.520992, longitude: 13.318053 },
  { name: 'GRUDA', latitude: 53.521111, longitude: 18.781389 },
  { name: 'VP312', latitude: 53.523333, longitude: 18.955556 },
  { name: 'VP313', latitude: 53.525556, longitude: 16.005833 },
  { name: 'D048F', latitude: 53.525578, longitude: 12.031333 },
  { name: 'SC521', latitude: 53.526194, longitude: 15.015528 },
  { name: 'TIRMI', latitude: 53.527933, longitude: 13.597131 },
  { name: 'BN305', latitude: 53.529244, longitude: 13.403278 },
  { name: 'BIGTI', latitude: 53.53, longitude: 12.896667 },
  { name: 'SY363', latitude: 53.531528, longitude: 20.970278 },
  { name: 'OLFID', latitude: 53.533056, longitude: 34.414722 },
  { name: 'D063O', latitude: 53.534164, longitude: 12.170428 },
  { name: 'SY372', latitude: 53.539694, longitude: 20.970111 },
  { name: 'BN707', latitude: 53.540189, longitude: 13.133461 },
  { name: 'VP314', latitude: 53.540556, longitude: 14.787222 },
  { name: 'SY808', latitude: 53.541028, longitude: 20.971278 },
  { name: 'SC421', latitude: 53.541083, longitude: 14.987333 },
  { name: '39VOR', latitude: 53.542222, longitude: 24.053889 },
  { name: 'VP315', latitude: 53.544444, longitude: 20.820278 },
  { name: 'D034F', latitude: 53.544994, longitude: 12.005575 },
  { name: 'SY517', latitude: 53.545389, longitude: 20.973278 },
  { name: 'KEZDO', latitude: 53.546111, longitude: 15.584167 },
  { name: 'ORHIM', latitude: 53.548333, longitude: 26.604444 },
  { name: 'SY411', latitude: 53.548806, longitude: 20.97525 },
  { name: 'SLEDA', latitude: 53.551667, longitude: 30.433056 },
  { name: 'KULEK', latitude: 53.552778, longitude: 20.5 },
  { name: 'BN602', latitude: 53.553178, longitude: 13.633558 },
  { name: 'SC532', latitude: 53.553306, longitude: 15.309056 },
  { name: 'SY792', latitude: 53.554917, longitude: 20.603861 },
  { name: 'SC422', latitude: 53.555361, longitude: 14.506056 },
  { name: 'VP316', latitude: 53.555556, longitude: 18.765833 },
  { name: 'MS225', latitude: 53.558194, longitude: 28.77 },
  { name: 'SY513', latitude: 53.558917, longitude: 20.440639 },
  { name: 'SY364', latitude: 53.562361, longitude: 20.798917 },
  { name: 'IBINO', latitude: 53.562861, longitude: 19.980611 },
  { name: 'VEKAV', latitude: 53.564444, longitude: 19.425556 },
  { name: 'SY413', latitude: 53.565917, longitude: 20.446361 },
  { name: 'ML31', latitude: 53.566472, longitude: 14.937583 },
  { name: 'VP317', latitude: 53.567778, longitude: 14.846944 },
  { name: 'PADKU', latitude: 53.569722, longitude: 13.983056 },
  { name: 'HOFTI', latitude: 53.572222, longitude: 25.823333 },
  { name: 'RORGU', latitude: 53.573333, longitude: 37.055 },
  { name: 'SC722', latitude: 53.574278, longitude: 14.677611 },
  { name: 'SC612', latitude: 53.57475, longitude: 15.134694 },
  { name: 'SY514', latitude: 53.575167, longitude: 20.82325 },
  { name: 'UNDUK', latitude: 53.576111, longitude: 17.976389 },
  { name: 'SC414', latitude: 53.577917, longitude: 14.701389 },
  { name: 'MUHNA', latitude: 53.581667, longitude: 29.273333 },
  { name: 'GOBRA', latitude: 53.584722, longitude: 15.7025 },
  { name: 'LOFOX', latitude: 53.586667, longitude: 16.080833 },
  { name: 'MS402', latitude: 53.590111, longitude: 28.200556 },
  { name: 'ETEDI', latitude: 53.592222, longitude: 19.871389 },
  { name: 'ENOBI', latitude: 53.592778, longitude: 23.629722 },
  { name: 'GODMA', latitude: 53.594444, longitude: 18.434444 },
  { name: 'BN500', latitude: 53.595414, longitude: 12.979442 },
  { name: 'SEFIF', latitude: 53.595417, longitude: 14.8815 },
  { name: 'LAMLO', latitude: 53.597844, longitude: 13.091258 },
  { name: 'BN706', latitude: 53.599636, longitude: 13.176892 },
  { name: 'BN700', latitude: 53.600775, longitude: 13.232819 },
  { name: 'GOVIK', latitude: 53.601389, longitude: 26.823056 },
  { name: 'VP318', latitude: 53.601667, longitude: 14.964167 },
  { name: 'BN300', latitude: 53.603631, longitude: 13.379303 },
  { name: 'EBAWE', latitude: 53.604667, longitude: 13.308667 },
  { name: 'SY803', latitude: 53.605722, longitude: 21.00725 },
  { name: 'GEMGU', latitude: 53.606186, longitude: 13.518617 },
  { name: 'BN304', latitude: 53.606197, longitude: 13.519156 },
  { name: 'SC418', latitude: 53.607528, longitude: 15.071417 },
  { name: 'BN600', latitude: 53.608128, longitude: 13.630511 },
  { name: 'SY516', latitude: 53.610222, longitude: 21.151833 },
  { name: 'VP319', latitude: 53.610833, longitude: 18.946667 },
  { name: 'D153T', latitude: 53.613333, longitude: 12.505167 },
  { name: 'NASKA', latitude: 53.616389, longitude: 28.914722 },
  { name: 'SY412', latitude: 53.616417, longitude: 20.787139 },
  { name: 'NEZGI', latitude: 53.616944, longitude: 29.760556 },
  { name: 'RUDEN', latitude: 53.618056, longitude: 27.689722 },
  { name: 'NOZBA', latitude: 53.621944, longitude: 28.4325 },
  { name: 'MD17', latitude: 53.623333, longitude: 24.053056 },
  { name: 'ADEPO', latitude: 53.625278, longitude: 13.925278 },
  { name: 'BN301', latitude: 53.6295, longitude: 13.431983 },
  { name: 'SC413', latitude: 53.632083, longitude: 14.595222 },
  { name: 'OSDEL', latitude: 53.633056, longitude: 37.54 },
  { name: 'BN307', latitude: 53.634642, longitude: 13.440592 },
  { name: 'MASUN', latitude: 53.636667, longitude: 15.276667 },
  { name: 'ASGUL', latitude: 53.638611, longitude: 16.947222 },
  { name: 'BN505', latitude: 53.639417, longitude: 12.897517 },
  { name: 'LOZON', latitude: 53.640278, longitude: 28.126944 },
  { name: 'SC411', latitude: 53.640972, longitude: 14.792639 },
  { name: 'SC531', latitude: 53.644333, longitude: 14.786361 },
  { name: 'NIVON', latitude: 53.644722, longitude: 20.041667 },
  { name: 'KOPON', latitude: 53.645, longitude: 30.758056 },
  { name: 'BN701', latitude: 53.646128, longitude: 13.166144 },
  { name: 'SC721', latitude: 53.6465, longitude: 14.782056 },
  { name: 'MS151', latitude: 53.650278, longitude: 28.373056 },
  { name: 'VP320', latitude: 53.651111, longitude: 15.066944 },
  { name: 'VP321', latitude: 53.651389, longitude: 16.027778 },
  { name: 'D153R', latitude: 53.651661, longitude: 12.477936 },
  { name: 'MS411', latitude: 53.652389, longitude: 27.830194 },
  { name: 'MG03', latitude: 53.657778, longitude: 24.438611 },
  { name: 'SC416', latitude: 53.659167, longitude: 14.542056 },
  { name: '33VOR', latitude: 53.661667, longitude: 24.046389 },
  { name: 'D299F', latitude: 53.663611, longitude: 23.929444 },
  { name: 'BN302', latitude: 53.669406, longitude: 13.422333 },
  { name: 'IRGET', latitude: 53.669722, longitude: 14.614722 },
  { name: 'MS401', latitude: 53.672111, longitude: 28.358917 },
  { name: 'ELTUB', latitude: 53.6725, longitude: 15.4225 },
  { name: 'BN601', latitude: 53.674639, longitude: 13.636111 },
  { name: 'BN506', latitude: 53.678408, longitude: 12.974139 },
  { name: 'TOZEW', latitude: 53.679167, longitude: 21.758333 },
  { name: 'SY416', latitude: 53.681, longitude: 21.121806 },
  { name: 'IXIXI', latitude: 53.684444, longitude: 19.891111 },
  { name: 'BN705', latitude: 53.685639, longitude: 13.035106 },
  { name: 'RODEP', latitude: 53.686111, longitude: 13.731389 },
  { name: 'SY802', latitude: 53.693889, longitude: 21.058278 },
  { name: 'SC412', latitude: 53.69525, longitude: 14.686444 },
  { name: 'TOMKO', latitude: 53.695278, longitude: 15.850556 },
  { name: 'VP322', latitude: 53.695833, longitude: 20.412778 },
  { name: 'FD17', latitude: 53.697778, longitude: 24.04 },
  { name: 'ELKAR', latitude: 53.698333, longitude: 20.5 },
  { name: 'VP323', latitude: 53.700278, longitude: 15.818889 },
  { name: 'MS421', latitude: 53.703639, longitude: 28.591167 },
  { name: 'MAPEX', latitude: 53.706111, longitude: 17.003611 },
  { name: 'FI17', latitude: 53.706111, longitude: 24.048056 },
  { name: 'MS102', latitude: 53.706917, longitude: 28.085111 },
  { name: 'RITEV', latitude: 53.708056, longitude: 13.508333 },
  { name: 'SC613', latitude: 53.712667, longitude: 15.167861 },
  { name: 'SY357', latitude: 53.712694, longitude: 20.9105 },
  { name: 'OSKUD', latitude: 53.7175, longitude: 19.761944 },
  { name: 'ORBOF', latitude: 53.722333, longitude: 14.63325 },
  { name: 'LAWEK', latitude: 53.729722, longitude: 28.292222 },
  { name: 'KIZIR', latitude: 53.731111, longitude: 26.667778 },
  { name: 'BN702', latitude: 53.731742, longitude: 13.191253 },
  { name: 'MS403', latitude: 53.734389, longitude: 27.988806 },
  { name: 'GOMEX', latitude: 53.734444, longitude: 15.242778 },
  { name: 'VP324', latitude: 53.736111, longitude: 16.35 },
  { name: 'BEDAM', latitude: 53.739722, longitude: 15.192222 },
  { name: 'SY801', latitude: 53.739972, longitude: 20.873778 },
  { name: 'NUVEN', latitude: 53.741389, longitude: 12.125278 },
  { name: 'PEPAR', latitude: 53.741389, longitude: 25.099722 },
  { name: 'FN17', latitude: 53.744175, longitude: 24.045503 },
  { name: 'SY417', latitude: 53.744667, longitude: 21.192806 },
  { name: 'EKTUT', latitude: 53.744722, longitude: 38.496111 },
  { name: 'CI29', latitude: 53.745575, longitude: 16.025467 },
  { name: 'VP325', latitude: 53.747222, longitude: 20.235556 },
  { name: 'MG01', latitude: 53.747222, longitude: 23.741389 },
  { name: 'KUBAB', latitude: 53.748889, longitude: 12.47 },
  { name: 'IRLUN', latitude: 53.750583, longitude: 18.614861 },
  { name: 'RAVAG', latitude: 53.750833, longitude: 16.748889 },
  { name: 'TIMIB', latitude: 53.752222, longitude: 12.699722 },
  { name: 'LEGSA', latitude: 53.753325, longitude: 12.789622 },
  { name: 'MS209', latitude: 53.753833, longitude: 27.099833 },
  { name: 'MS422', latitude: 53.753861, longitude: 28.517472 },
  { name: 'GIZCA', latitude: 53.754722, longitude: 17.498056 },
  { name: 'GEFUR', latitude: 53.754833, longitude: 28.255222 },
  { name: 'FN29', latitude: 53.757244, longitude: 15.973081 },
  { name: 'FI29', latitude: 53.757472, longitude: 15.973361 },
  { name: 'VP326', latitude: 53.758333, longitude: 15.538889 },
  { name: 'BN303', latitude: 53.759203, longitude: 13.265467 },
  { name: 'BINKA', latitude: 53.759444, longitude: 14.275556 },
  { name: 'VP320', latitude: 53.76, longitude: 14.23 },
  { name: 'FT29', latitude: 53.76, longitude: 15.986944 },
  { name: 'RAMUT', latitude: 53.76, longitude: 39.773333 },
  { name: 'GEVNI', latitude: 53.761978, longitude: 13.481522 },
  { name: 'TORLO', latitude: 53.762222, longitude: 13.796667 },
  { name: 'UDAXI', latitude: 53.7625, longitude: 13.704167 },
  { name: 'D010J', latitude: 53.762778, longitude: 24.140833 },
  { name: 'OSLOG', latitude: 53.765806, longitude: 18.7535 },
  { name: 'VP327', latitude: 53.765833, longitude: 15.819444 },
  { name: 'MS404', latitude: 53.765917, longitude: 27.344139 },
  { name: 'MS101', latitude: 53.767278, longitude: 28.201222 },
  { name: 'D087U', latitude: 53.767383, longitude: 14.152858 },
  { name: 'SC529', latitude: 53.767722, longitude: 14.759833 },
  { name: 'D007J', latitude: 53.768056, longitude: 24.126111 },
  { name: 'OLKIN', latitude: 53.769167, longitude: 20.5 },
  { name: 'REDLO', latitude: 53.772778, longitude: 15.969444 },
  { name: 'SC724', latitude: 53.773917, longitude: 14.962444 },
  { name: 'NORNO', latitude: 53.776944, longitude: 22.789167 },
  { name: 'VP321', latitude: 53.778333, longitude: 13.961667 },
  { name: 'GILIP', latitude: 53.778889, longitude: 23.882778 },
  { name: 'MS103', latitude: 53.779556, longitude: 27.632528 },
  { name: 'G011', latitude: 53.779722, longitude: 24.141389 },
  { name: 'PENAT', latitude: 53.781667, longitude: 30.184722 },
  { name: 'D122J', latitude: 53.782428, longitude: 14.402544 },
  { name: 'GIFLI', latitude: 53.785, longitude: 28.945556 },
  { name: 'SC417', latitude: 53.785444, longitude: 14.724722 },
  { name: 'AMLUL', latitude: 53.786389, longitude: 24.205833 },
  { name: 'TETKO', latitude: 53.792167, longitude: 12.6065 },
  { name: 'FF31R', latitude: 53.792778, longitude: 28.199444 },
  { name: 'MT11', latitude: 53.795917, longitude: 15.800222 },
  { name: 'NINKI', latitude: 53.798611, longitude: 29.829444 },
  { name: 'ELDOK', latitude: 53.801389, longitude: 25.079722 },
  { name: 'KAFTU', latitude: 53.804167, longitude: 28.443611 },
  { name: 'D342L', latitude: 53.804269, longitude: 23.997367 },
  { name: 'D113J', latitude: 53.804306, longitude: 14.422842 },
  { name: 'D145J', latitude: 53.804444, longitude: 30.220278 },
  { name: 'D346L', latitude: 53.805833, longitude: 24.021667 },
  { name: 'D357L', latitude: 53.806553, longitude: 24.085344 },
  { name: 'NEPUD', latitude: 53.807222, longitude: 24.0425 },
  { name: 'TURAT', latitude: 53.808056, longitude: 32.142222 },
  { name: 'MS208', latitude: 53.810056, longitude: 26.86275 },
  { name: 'VP328', latitude: 53.810833, longitude: 15.861667 },
  { name: 'SY362', latitude: 53.815194, longitude: 20.987083 },
  { name: 'LEKOV', latitude: 53.815556, longitude: 15.674444 },
  { name: 'TINIB', latitude: 53.816389, longitude: 30.3425 },
  { name: 'SY793', latitude: 53.816972, longitude: 20.928389 },
  { name: 'DULEB', latitude: 53.818333, longitude: 29.389722 },
  { name: 'D152H', latitude: 53.820556, longitude: 30.187222 },
  { name: 'MS104', latitude: 53.823194, longitude: 27.294583 },
  { name: 'LUSUL', latitude: 53.825278, longitude: 21.283056 },
  { name: 'SC419', latitude: 53.825806, longitude: 14.798028 },
  { name: 'FT11', latitude: 53.828333, longitude: 15.670833 },
  { name: 'MS100', latitude: 53.829167, longitude: 28.109778 },
  { name: 'SOTET', latitude: 53.829722, longitude: 23.553056 },
  { name: 'BARDO', latitude: 53.830556, longitude: 16.105556 },
  { name: 'CI28', latitude: 53.831228, longitude: 14.43925 },
  { name: 'NULNA', latitude: 53.8325, longitude: 38.971667 },
  { name: 'D106O', latitude: 53.833839, longitude: 12.682931 },
  { name: 'FN28', latitude: 53.834858, longitude: 14.411911 },
  { name: 'VP329', latitude: 53.835, longitude: 20.475 },
  { name: 'MS111', latitude: 53.835528, longitude: 28.625611 },
  { name: 'LOBGI', latitude: 53.835833, longitude: 23.824722 },
  { name: 'GD532', latitude: 53.839083, longitude: 18.556278 },
  { name: 'VP333', latitude: 53.84, longitude: 12.289444 },
  { name: 'MS200', latitude: 53.84, longitude: 28.130111 },
  { name: 'ZULCU', latitude: 53.841389, longitude: 16.398611 },
  { name: 'MS105', latitude: 53.842417, longitude: 27.143278 },
  { name: 'D163K', latitude: 53.844722, longitude: 19.191389 },
  { name: 'VP331', latitude: 53.846667, longitude: 13.928333 },
  { name: '50DM2', latitude: 53.853361, longitude: 14.3038 },
  { name: 'FI28', latitude: 53.853733, longitude: 14.303811 },
  { name: 'D103D', latitude: 53.856267, longitude: 14.286764 },
  { name: 'KOMAP', latitude: 53.856389, longitude: 28.713056 },
  { name: 'D096T', latitude: 53.858, longitude: 12.84 },
  { name: 'ARDUT', latitude: 53.858056, longitude: 21.167222 },
  { name: 'TUROL', latitude: 53.858611, longitude: 20.5 },
  { name: 'DIFUS', latitude: 53.863333, longitude: 15.269444 },
  { name: 'ROVSI', latitude: 53.864167, longitude: 30.84 },
  { name: '25DME', latitude: 53.864878, longitude: 14.236181 },
  { name: 'GIRUZ', latitude: 53.866111, longitude: 19.640833 },
  { name: 'MS224', latitude: 53.872972, longitude: 28.34175 },
  { name: 'UDAPO', latitude: 53.873056, longitude: 39.212778 },
  { name: 'OSGIG', latitude: 53.873667, longitude: 12.756 },
  { name: 'IDPIS', latitude: 53.875833, longitude: 40.354167 },
  { name: 'AKOSA', latitude: 53.878611, longitude: 37.6275 },
  { name: 'TIVAN', latitude: 53.881389, longitude: 28.506389 },
  { name: 'FD31', latitude: 53.881389, longitude: 30.224722 },
  { name: 'D251H', latitude: 53.882225, longitude: 12.070003 },
  { name: 'BOHES', latitude: 53.883056, longitude: 29.058333 },
  { name: 'D094P', latitude: 53.883381, longitude: 12.704492 },
  { name: 'FQ31', latitude: 53.884636, longitude: 30.206317 },
  { name: 'CT27', latitude: 53.884725, longitude: 12.619258 },
  { name: 'OSMOV', latitude: 53.885556, longitude: 17.481944 },
  { name: 'VP330', latitude: 53.885833, longitude: 16.011944 },
  { name: 'MN10', latitude: 53.886606, longitude: 14.107875 },
  { name: 'CF27', latitude: 53.890333, longitude: 12.620817 },
  { name: 'OKWET', latitude: 53.891111, longitude: 25.122778 },
  { name: 'NL021', latitude: 53.892022, longitude: 12.600525 },
  { name: 'MS405', latitude: 53.892611, longitude: 27.170583 },
  { name: 'FARCU', latitude: 53.893889, longitude: 14.212778 },
  { name: 'OSMUS', latitude: 53.894722, longitude: 26.7225 },
  { name: '30DME', latitude: 53.895778, longitude: 14.053425 },
  { name: 'LEBGI', latitude: 53.9, longitude: 34.655 },
  { name: 'BOGTO', latitude: 53.901667, longitude: 35.909444 },
  { name: 'FT27', latitude: 53.902408, longitude: 12.452908 },
  { name: 'FF27', latitude: 53.903383, longitude: 12.461758 },
  { name: 'ESERO', latitude: 53.9035, longitude: 12.461167 },
  { name: '50DME', latitude: 53.904881, longitude: 13.999225 },
  { name: 'D096E', latitude: 53.907269, longitude: 12.425592 },
  { name: 'D096D', latitude: 53.909544, longitude: 12.397903 },
  { name: '30LOC', latitude: 53.910842, longitude: 12.369628 },
  { name: '35DME', latitude: 53.911111, longitude: 30.165 },
  { name: 'D283H', latitude: 53.911147, longitude: 13.961811 },
  { name: '30TAC', latitude: 53.911164, longitude: 12.369681 },
  { name: 'NL042', latitude: 53.912092, longitude: 12.354131 },
  { name: 'VP331', latitude: 53.913056, longitude: 18.886667 },
  { name: 'VP332', latitude: 53.913889, longitude: 19.35 },
  { name: 'D087O', latitude: 53.914761, longitude: 12.709128 },
  { name: 'MM27', latitude: 53.915283, longitude: 12.3122 },
  { name: 'D091C', latitude: 53.915481, longitude: 12.37065 },
  { name: 'LUXAL', latitude: 53.915556, longitude: 16.543056 },
  { name: 'MT27', latitude: 53.916967, longitude: 12.314175 },
  { name: 'PIKFI', latitude: 53.9175, longitude: 14.823333 },
  { name: '30VOR', latitude: 53.919167, longitude: 30.156389 },
  { name: 'MM09', latitude: 53.920706, longitude: 12.246494 },
  { name: 'MT09', latitude: 53.921308, longitude: 12.258336 },
  { name: 'D270B', latitude: 53.921494, longitude: 12.230022 },
  { name: 'ABABO', latitude: 53.922222, longitude: 39.478056 },
  { name: 'FN10', latitude: 53.923014, longitude: 13.89075 },
  { name: 'NL022', latitude: 53.923239, longitude: 12.214483 },
  { name: 'D272C', latitude: 53.923797, longitude: 12.202189 },
  { name: 'MASOR', latitude: 53.924167, longitude: 13.645833 },
  { name: '35TAC', latitude: 53.924894, longitude: 12.188122 },
  { name: '35LOC', latitude: 53.925314, longitude: 12.188119 },
  { name: 'D283K', latitude: 53.925633, longitude: 13.875014 },
  { name: 'MS427', latitude: 53.927611, longitude: 28.448472 },
  { name: 'FT09', latitude: 53.928444, longitude: 12.117897 },
  { name: 'D276D', latitude: 53.931367, longitude: 12.175347 },
  { name: 'FF09', latitude: 53.932122, longitude: 12.101483 },
  { name: 'ROLAX', latitude: 53.932167, longitude: 12.1005 },
  { name: '22LOC', latitude: 53.932778, longitude: 27.992222 },
  { name: 'MS302', latitude: 53.934611, longitude: 26.927028 },
  { name: 'VP333', latitude: 53.935833, longitude: 22.978611 },
  { name: 'OBDEJ', latitude: 53.936389, longitude: 15.0 },
  { name: 'MS300', latitude: 53.937639, longitude: 27.948778 },
  { name: 'GIDKA', latitude: 53.938333, longitude: 30.203056 },
  { name: 'GD446', latitude: 53.939611, longitude: 18.6165 },
  { name: 'ERZAR', latitude: 53.940278, longitude: 18.731111 },
  { name: 'GD933', latitude: 53.9405, longitude: 18.761889 },
  { name: 'MS207', latitude: 53.941611, longitude: 27.191472 },
  { name: 'VP334', latitude: 53.943611, longitude: 15.619444 },
  { name: 'AGODU', latitude: 53.944444, longitude: 16.116389 },
  { name: 'MS400', latitude: 53.945528, longitude: 27.973333 },
  { name: 'TUMKI', latitude: 53.948333, longitude: 23.898889 },
  { name: 'D290L', latitude: 53.949939, longitude: 13.877475 },
  { name: 'USEDU', latitude: 53.953056, longitude: 13.966667 },
  { name: 'VP335', latitude: 53.953333, longitude: 21.371389 },
  { name: 'LIMPI', latitude: 53.954722, longitude: 35.337778 },
  { name: 'AKPOZ', latitude: 53.958333, longitude: 25.040833 },
  { name: 'NEHIM', latitude: 53.959444, longitude: 15.227778 },
  { name: 'RATOR', latitude: 53.963667, longitude: 17.880444 },
  { name: 'MS710', latitude: 53.966111, longitude: 28.202222 },
  { name: 'D245K', latitude: 53.968019, longitude: 18.842317 },
  { name: 'VP335', latitude: 53.973333, longitude: 14.1 },
  { name: 'D298L', latitude: 53.973828, longitude: 13.899481 },
  { name: 'BEGDA', latitude: 53.974444, longitude: 24.548056 },
  { name: 'RAFMA', latitude: 53.978056, longitude: 29.333056 },
  { name: 'GD841', latitude: 53.982056, longitude: 18.423306 },
  { name: 'D296H', latitude: 53.984922, longitude: 12.089583 },
  { name: 'RORME', latitude: 53.985, longitude: 15.620556 },
  { name: 'VP336', latitude: 53.986667, longitude: 19.156389 },
  { name: 'BADSU', latitude: 53.987, longitude: 12.652833 },
  { name: 'MS431', latitude: 53.989167, longitude: 28.167722 },
  { name: 'MS301', latitude: 53.991444, longitude: 27.868611 },
  { name: 'D104G', latitude: 53.991856, longitude: 19.338258 },
  { name: 'RIWEN', latitude: 53.9925, longitude: 28.681944 },
  { name: 'FF13L', latitude: 53.993889, longitude: 27.901111 },
  { name: 'BARGU', latitude: 53.997222, longitude: 21.253056 },
  { name: '5413E', latitude: 54.0, longitude: 13.0 },
  { name: '5414E', latitude: 54.0, longitude: 14.0 },
  { name: '5415E', latitude: 54.0, longitude: 15.0 },
  { name: '5416E', latitude: 54.0, longitude: 16.0 },
  { name: '5417E', latitude: 54.0, longitude: 17.0 },
  { name: 'ERPUX', latitude: 54.0, longitude: 18.0 },
  { name: '5418E', latitude: 54.0, longitude: 18.0 },
  { name: '5419E', latitude: 54.0, longitude: 19.0 },
  { name: '5420E', latitude: 54.0, longitude: 20.0 },
  { name: '5421E', latitude: 54.0, longitude: 21.0 },
  { name: '5422E', latitude: 54.0, longitude: 22.0 },
  { name: '5423E', latitude: 54.0, longitude: 23.0 },
  { name: '5424E', latitude: 54.0, longitude: 24.0 },
  { name: '5425E', latitude: 54.0, longitude: 25.0 },
  { name: '5426E', latitude: 54.0, longitude: 26.0 },
  { name: '5427E', latitude: 54.0, longitude: 27.0 },
  { name: '5428E', latitude: 54.0, longitude: 28.0 },
  { name: '5429E', latitude: 54.0, longitude: 29.0 },
  { name: '5430E', latitude: 54.0, longitude: 30.0 },
  { name: '5431E', latitude: 54.0, longitude: 31.0 },
  { name: '5432E', latitude: 54.0, longitude: 32.0 },
  { name: '5433E', latitude: 54.0, longitude: 33.0 },
  { name: '5434E', latitude: 54.0, longitude: 34.0 },
  { name: '5435E', latitude: 54.0, longitude: 35.0 },
  { name: '5436E', latitude: 54.0, longitude: 36.0 },
  { name: '5437E', latitude: 54.0, longitude: 37.0 },
  { name: '5438E', latitude: 54.0, longitude: 38.0 },
  { name: '5439E', latitude: 54.0, longitude: 39.0 },
  { name: '5440E', latitude: 54.0, longitude: 40.0 },
  { name: 'VP336', latitude: 54.001667, longitude: 12.2 },
  { name: 'NL043', latitude: 54.002497, longitude: 12.393406 },
  { name: 'D089K', latitude: 54.005414, longitude: 19.446561 },
  { name: 'MS206', latitude: 54.006472, longitude: 27.355139 },
  { name: 'MS112', latitude: 54.008056, longitude: 28.672917 },
  { name: 'OGRES', latitude: 54.010556, longitude: 17.015278 },
  { name: 'FT07', latitude: 54.010833, longitude: 18.969722 },
  { name: 'OTPAD', latitude: 54.011389, longitude: 36.716389 },
  { name: 'VP337', latitude: 54.011944, longitude: 21.583611 },
  { name: 'D092G', latitude: 54.013622, longitude: 19.346244 },
  { name: 'NL023', latitude: 54.016539, longitude: 12.224578 },
  { name: 'NL024', latitude: 54.016658, longitude: 12.52945 },
  { name: 'D283H', latitude: 54.018889, longitude: 29.868333 },
  { name: 'MT07', latitude: 54.022611, longitude: 19.109611 },
  { name: 'VP338', latitude: 54.025556, longitude: 21.217222 },
  { name: 'FN13', latitude: 54.025842, longitude: 29.982381 },
  { name: 'D288J', latitude: 54.026667, longitude: 29.843889 },
  { name: 'FI13', latitude: 54.028056, longitude: 29.977222 },
  { name: 'MT25', latitude: 54.028972, longitude: 19.164972 },
  { name: 'ML25', latitude: 54.030306, longitude: 19.168806 },
  { name: 'ODPUP', latitude: 54.031111, longitude: 36.600556 },
  { name: 'FD13', latitude: 54.031389, longitude: 29.979444 },
  { name: 'KOPZA', latitude: 54.031556, longitude: 27.844722 },
  { name: 'D290J', latitude: 54.031667, longitude: 29.848333 },
  { name: 'AGNUL', latitude: 54.034444, longitude: 26.793056 },
  { name: 'FI25', latitude: 54.043694, longitude: 19.288333 },
  { name: 'FN25', latitude: 54.046236, longitude: 19.308797 },
  { name: 'CI25', latitude: 54.049958, longitude: 19.343894 },
  { name: 'FT25', latitude: 54.051111, longitude: 19.315833 },
  { name: 'MUHOS', latitude: 54.055556, longitude: 30.412222 },
  { name: 'NIWAZ', latitude: 54.056389, longitude: 27.8075 },
  { name: 'VP339', latitude: 54.061111, longitude: 19.472222 },
  { name: 'MS312', latitude: 54.063028, longitude: 28.220944 },
  { name: 'MS311', latitude: 54.063861, longitude: 28.008417 },
  { name: 'VP340', latitude: 54.063889, longitude: 19.116667 },
  { name: 'MS223', latitude: 54.066056, longitude: 28.055194 },
  { name: 'CT25', latitude: 54.069083, longitude: 19.439417 },
  { name: 'SUWGI', latitude: 54.069722, longitude: 22.900278 },
  { name: 'BIHOL', latitude: 54.071944, longitude: 27.482778 },
  { name: 'SUDOB', latitude: 54.073056, longitude: 28.389722 },
  { name: 'LIWIC', latitude: 54.073056, longitude: 28.908056 },
  { name: 'DEMUR', latitude: 54.08, longitude: 15.007778 },
  { name: 'SALAR', latitude: 54.084722, longitude: 31.859722 },
  { name: 'LAKUB', latitude: 54.086389, longitude: 27.725833 },
  { name: 'VP341', latitude: 54.093333, longitude: 22.650556 },
  { name: 'D069R', latitude: 54.096778, longitude: 19.631833 },
  { name: 'VEKON', latitude: 54.0975, longitude: 17.018333 },
  { name: 'D048R', latitude: 54.0985, longitude: 12.676808 },
  { name: 'KENWE', latitude: 54.098611, longitude: 29.863333 },
  { name: 'PENET', latitude: 54.100278, longitude: 13.728889 },
  { name: 'MATUS', latitude: 54.103056, longitude: 27.338056 },
  { name: 'MS113', latitude: 54.103333, longitude: 28.699222 },
  { name: 'MS201', latitude: 54.103889, longitude: 27.718194 },
  { name: 'NAZUD', latitude: 54.111111, longitude: 23.067778 },
  { name: 'SOMVO', latitude: 54.111944, longitude: 19.742222 },
  { name: 'POMZO', latitude: 54.112778, longitude: 28.861667 },
  { name: 'OLKUD', latitude: 54.113889, longitude: 36.0975 },
  { name: 'DOSAG', latitude: 54.1175, longitude: 40.130278 },
  { name: 'VP342', latitude: 54.118889, longitude: 18.767222 },
  { name: 'GD932', latitude: 54.120944, longitude: 18.770611 },
  { name: 'MS313', latitude: 54.121, longitude: 28.522389 },
  { name: 'VP343', latitude: 54.1225, longitude: 19.295833 },
  { name: 'BAKDO', latitude: 54.123056, longitude: 12.970278 },
  { name: 'D048T', latitude: 54.123833, longitude: 12.732833 },
  { name: 'OO201', latitude: 54.123889, longitude: 29.611944 },
  { name: 'KOSEX', latitude: 54.126222, longitude: 17.536194 },
  { name: 'GD531', latitude: 54.12825, longitude: 18.362972 },
  { name: 'MS205', latitude: 54.128528, longitude: 27.399333 },
  { name: 'RUHAL', latitude: 54.132778, longitude: 29.195 },
  { name: 'BIZIF', latitude: 54.135278, longitude: 27.953333 },
  { name: 'BERNU', latitude: 54.136667, longitude: 19.138333 },
  { name: 'TIWOK', latitude: 54.138889, longitude: 26.829167 },
  { name: 'GD432', latitude: 54.139667, longitude: 19.049972 },
  { name: 'GD906', latitude: 54.141361, longitude: 19.048028 },
  { name: 'VP344', latitude: 54.141667, longitude: 19.008333 },
  { name: 'VP345', latitude: 54.148056, longitude: 18.548889 },
  { name: 'VP346', latitude: 54.148333, longitude: 18.904722 },
  { name: 'TONBU', latitude: 54.152222, longitude: 39.417222 },
  { name: 'MS202', latitude: 54.154111, longitude: 27.643806 },
  { name: 'POBOX', latitude: 54.158889, longitude: 14.094167 },
  { name: 'DIFTO', latitude: 54.161111, longitude: 30.23 },
  { name: 'GD931', latitude: 54.163306, longitude: 18.601556 },
  { name: 'OTRIV', latitude: 54.163611, longitude: 22.197778 },
  { name: 'D323R', latitude: 54.163764, longitude: 12.016172 },
  { name: 'VP347', latitude: 54.17, longitude: 22.931111 },
  { name: 'TADIG', latitude: 54.17, longitude: 30.618056 },
  { name: 'GD907', latitude: 54.174306, longitude: 18.917944 },
  { name: 'MS226', latitude: 54.178194, longitude: 28.075417 },
  { name: 'MS204', latitude: 54.178806, longitude: 27.325 },
  { name: 'OBARO', latitude: 54.180556, longitude: 35.6825 },
  { name: 'ARPEL', latitude: 54.1825, longitude: 16.15 },
  { name: 'SODKO', latitude: 54.183333, longitude: 29.441389 },
  { name: 'VP348', latitude: 54.185, longitude: 18.6825 },
  { name: 'MS222', latitude: 54.185639, longitude: 27.879083 },
  { name: 'GD842', latitude: 54.1905, longitude: 18.247806 },
  { name: 'GD912', latitude: 54.192139, longitude: 18.173417 },
  { name: 'LABES', latitude: 54.193056, longitude: 13.576389 },
  { name: 'GD811', latitude: 54.1935, longitude: 18.112028 },
  { name: 'VABER', latitude: 54.194444, longitude: 23.406944 },
  { name: 'GIGIS', latitude: 54.195278, longitude: 30.961667 },
  { name: 'ELMAM', latitude: 54.196111, longitude: 27.188333 },
  { name: 'BC731', latitude: 54.201139, longitude: 36.802861 },
  { name: 'NIKMI', latitude: 54.201306, longitude: 19.145083 },
  { name: 'GD908', latitude: 54.207139, longitude: 18.787639 },
  { name: 'ROSOK', latitude: 54.21, longitude: 12.076667 },
  { name: 'MS203', latitude: 54.21025, longitude: 27.560778 },
  { name: 'SOGBI', latitude: 54.213056, longitude: 25.195833 },
  { name: 'GD904', latitude: 54.217667, longitude: 19.104389 },
  { name: 'NALWE', latitude: 54.218611, longitude: 29.993056 },
  { name: 'GD911', latitude: 54.219944, longitude: 18.453139 },
  { name: 'DEXIR', latitude: 54.224167, longitude: 17.448889 },
  { name: 'CN27Z', latitude: 54.227569, longitude: 18.871514 },
  { name: 'OLOPI', latitude: 54.233333, longitude: 38.898056 },
  { name: 'FN27X', latitude: 54.233528, longitude: 18.812103 },
  { name: 'VP349', latitude: 54.235278, longitude: 18.476389 },
  { name: 'MS221', latitude: 54.235917, longitude: 27.804639 },
  { name: 'GD909', latitude: 54.239806, longitude: 18.657111 },
  { name: 'MALOX', latitude: 54.244167, longitude: 16.881389 },
  { name: 'GD444', latitude: 54.249222, longitude: 18.619417 },
  { name: 'GD903', latitude: 54.250694, longitude: 18.974083 },
  { name: 'MS114', latitude: 54.251167, longitude: 28.740278 },
  { name: 'VP350', latitude: 54.251667, longitude: 18.981111 },
  { name: 'TEPDU', latitude: 54.253461, longitude: 12.976161 },
  { name: 'GD451', latitude: 54.26, longitude: 18.326417 },
  { name: 'URORA', latitude: 54.260278, longitude: 16.4975 },
  { name: 'KAZAL', latitude: 54.260556, longitude: 27.486111 },
  { name: 'ROBOV', latitude: 54.2625, longitude: 15.055833 },
  { name: 'EVNOB', latitude: 54.264972, longitude: 18.184639 },
  { name: 'BH902', latitude: 54.265717, longitude: 12.915533 },
  { name: 'VP351', latitude: 54.265833, longitude: 18.742222 },
  { name: 'BH450', latitude: 54.268564, longitude: 12.511031 },
  { name: 'GD852', latitude: 54.273, longitude: 18.888 },
  { name: 'GASBO', latitude: 54.274811, longitude: 12.276536 },
  { name: 'VP352', latitude: 54.276111, longitude: 18.489444 },
  { name: 'XELOL', latitude: 54.2775, longitude: 14.895 },
  { name: 'POSTO', latitude: 54.279444, longitude: 36.803056 },
  { name: 'DIMTA', latitude: 54.282528, longitude: 24.335806 },
  { name: 'GD551', latitude: 54.283167, longitude: 18.258222 },
  { name: 'IPLAM', latitude: 54.283556, longitude: 18.843611 },
  { name: 'D149Q', latitude: 54.287572, longitude: 36.540717 },
  { name: 'GD543', latitude: 54.287944, longitude: 17.833333 },
  { name: 'TEDRO', latitude: 54.289722, longitude: 27.863056 },
  { name: 'MOROZ', latitude: 54.29, longitude: 23.835278 },
  { name: 'MS220', latitude: 54.292417, longitude: 27.720667 },
  { name: 'SORFO', latitude: 54.296944, longitude: 29.015278 },
  { name: 'KARTI', latitude: 54.299722, longitude: 18.208056 },
  { name: 'LUBIN', latitude: 54.301667, longitude: 30.583056 },
  { name: 'VP353', latitude: 54.302778, longitude: 16.386111 },
  { name: 'MS321', latitude: 54.303917, longitude: 27.417028 },
  { name: 'VP354', latitude: 54.306667, longitude: 16.196667 },
  { name: 'BOKSU', latitude: 54.3075, longitude: 23.059167 },
  { name: 'GD902', latitude: 54.309583, longitude: 18.739806 },
  { name: 'GD402', latitude: 54.311139, longitude: 18.7335 },
  { name: 'GD952', latitude: 54.311167, longitude: 18.733528 },
  { name: 'DUZIK', latitude: 54.312222, longitude: 27.955278 },
  { name: 'GD513', latitude: 54.314167, longitude: 18.357361 },
  { name: 'VI301', latitude: 54.316278, longitude: 24.652083 },
  { name: 'KOGIM', latitude: 54.316944, longitude: 13.543889 },
  { name: 'GD441', latitude: 54.320944, longitude: 18.694278 },
  { name: 'GD951', latitude: 54.321, longitude: 18.694306 },
  { name: 'GD941', latitude: 54.321028, longitude: 18.693972 },
  { name: 'VP355', latitude: 54.321944, longitude: 18.776111 },
  { name: 'VP357', latitude: 54.325, longitude: 18.183611 },
  { name: 'VP356', latitude: 54.325278, longitude: 17.790556 },
  { name: 'BC831', latitude: 54.3255, longitude: 36.649417 },
  { name: 'ADUKI', latitude: 54.325556, longitude: 13.360278 },
  { name: 'GD421', latitude: 54.325722, longitude: 18.675139 },
  { name: 'GD921', latitude: 54.327028, longitude: 19.030444 },
  { name: 'NOPKA', latitude: 54.329722, longitude: 25.886389 },
  { name: 'IBGEG', latitude: 54.330278, longitude: 36.721944 },
  { name: 'UPLOP', latitude: 54.333056, longitude: 39.255833 },
  { name: 'GOLAD', latitude: 54.333333, longitude: 22.175 },
  { name: 'LAFIZ', latitude: 54.335278, longitude: 29.371111 },
  { name: 'BH439', latitude: 54.336631, longitude: 12.977828 },
  { name: 'BH901', latitude: 54.337025, longitude: 12.917819 },
  { name: 'BASUG', latitude: 54.337356, longitude: 12.864089 },
  { name: 'GD514', latitude: 54.338028, longitude: 18.601639 },
  { name: 'BH440', latitude: 54.338769, longitude: 12.566139 },
  { name: 'BH430', latitude: 54.33895, longitude: 12.511669 },
  { name: 'BH721', latitude: 54.338981, longitude: 12.501614 },
  { name: 'CT04', latitude: 54.339242, longitude: 16.229225 },
  { name: 'CQ04', latitude: 54.339314, longitude: 16.248189 },
  { name: 'BC531', latitude: 54.340639, longitude: 36.105611 },
  { name: 'VIBUL', latitude: 54.341667, longitude: 15.274444 },
  { name: 'GD809', latitude: 54.342861, longitude: 18.240194 },
  { name: 'VP358', latitude: 54.345, longitude: 18.435 },
  { name: 'OHWID', latitude: 54.345278, longitude: 30.014722 },
  { name: 'LINRU', latitude: 54.345556, longitude: 38.238611 },
  { name: 'BC431', latitude: 54.34775, longitude: 36.408889 },
  { name: 'GD512', latitude: 54.349583, longitude: 18.213056 },
  { name: 'VP359', latitude: 54.350278, longitude: 17.903333 },
  { name: 'GD542', latitude: 54.350806, longitude: 18.137333 },
  { name: 'VP360', latitude: 54.354722, longitude: 17.575556 },
  { name: 'FQ04', latitude: 54.357778, longitude: 16.2775 },
  { name: 'VP361', latitude: 54.358333, longitude: 16.515 },
  { name: 'GD922', latitude: 54.359944, longitude: 18.89975 },
  { name: 'SUMOK', latitude: 54.36, longitude: 29.608056 },
  { name: 'FT04', latitude: 54.361389, longitude: 16.272222 },
  { name: 'IO201', latitude: 54.361944, longitude: 30.064167 },
  { name: 'NOLNA', latitude: 54.362944, longitude: 25.098472 },
  { name: 'VI210', latitude: 54.364361, longitude: 25.242972 },
  { name: 'DERFU', latitude: 54.365833, longitude: 34.735278 },
  { name: 'VEDOX', latitude: 54.366667, longitude: 16.65 },
  { name: 'ML29', latitude: 54.367417, longitude: 18.507278 },
  { name: 'MD29', latitude: 54.367889, longitude: 18.505806 },
  { name: 'D137M', latitude: 54.367942, longitude: 36.574794 },
  { name: 'GITOV', latitude: 54.368333, longitude: 20.698333 },
  { name: 'BH438', latitude: 54.368464, longitude: 13.019075 },
  { name: 'VP362', latitude: 54.368611, longitude: 17.023056 },
  { name: 'MS432', latitude: 54.370806, longitude: 27.64075 },
  { name: 'VP363', latitude: 54.374444, longitude: 18.778889 },
  { name: 'VP364', latitude: 54.374722, longitude: 17.151111 },
  { name: 'GD808', latitude: 54.375167, longitude: 18.107472 },
  { name: 'WERHI', latitude: 54.378889, longitude: 28.924444 },
  { name: 'IO203', latitude: 54.3825, longitude: 29.7475 },
  { name: 'VP365', latitude: 54.384722, longitude: 16.396667 },
  { name: 'GD831', latitude: 54.386389, longitude: 18.794306 },
  { name: 'UPASI', latitude: 54.386694, longitude: 24.277028 },
  { name: 'FD05', latitude: 54.386944, longitude: 30.138056 },
  { name: 'FF05', latitude: 54.386944, longitude: 30.138333 },
  { name: 'BEZMI', latitude: 54.387222, longitude: 17.406667 },
  { name: 'BABEN', latitude: 54.388056, longitude: 15.241944 },
  { name: 'BADUS', latitude: 54.388333, longitude: 25.588333 },
  { name: 'TOGMO', latitude: 54.388333, longitude: 39.025278 },
  { name: 'D134L', latitude: 54.390006, longitude: 36.569419 },
  { name: 'GD923', latitude: 54.39275, longitude: 18.768861 },
  { name: 'PASUD', latitude: 54.393333, longitude: 29.931389 },
  { name: 'CT07', latitude: 54.394031, longitude: 17.581822 },
  { name: 'MQ04', latitude: 54.394861, longitude: 16.337472 },
  { name: 'FN07Y', latitude: 54.397222, longitude: 17.633692 },
  { name: 'FQ07Z', latitude: 54.397972, longitude: 17.638889 },
  { name: 'FT07', latitude: 54.398333, longitude: 17.618333 },
  { name: 'MT04', latitude: 54.399361, longitude: 16.347389 },
  { name: '27VOR', latitude: 54.405167, longitude: 18.354389 },
  { name: 'BC016', latitude: 54.405472, longitude: 36.321556 },
  { name: 'BC331', latitude: 54.406972, longitude: 36.54825 },
  { name: 'GD807', latitude: 54.407, longitude: 17.97575 },
  { name: 'BH722', latitude: 54.409964, longitude: 12.493525 },
  { name: 'D247K', latitude: 54.41, longitude: 30.021667 },
  { name: 'MT22', latitude: 54.411556, longitude: 16.366556 },
  { name: 'MT07', latitude: 54.411639, longitude: 17.73575 },
  { name: 'VP366', latitude: 54.411667, longitude: 18.485 },
  { name: 'GD801', latitude: 54.415917, longitude: 18.310389 },
  { name: 'TILAV', latitude: 54.416389, longitude: 15.0 },
  { name: 'MT25Z', latitude: 54.419667, longitude: 17.791028 },
  { name: 'ML25', latitude: 54.421222, longitude: 17.797889 },
  { name: 'VP001', latitude: 54.422778, longitude: 24.970833 },
  { name: 'TIBTA', latitude: 54.423611, longitude: 33.530556 },
  { name: 'VI313', latitude: 54.424083, longitude: 25.29475 },
  { name: 'VP367', latitude: 54.425, longitude: 17.007222 },
  { name: 'GD924', latitude: 54.425417, longitude: 18.63775 },
  { name: 'GD802', latitude: 54.425556, longitude: 18.270944 },
  { name: 'GD511', latitude: 54.426278, longitude: 18.268167 },
  { name: 'DINPU', latitude: 54.426389, longitude: 30.262778 },
  { name: 'ABEZE', latitude: 54.4325, longitude: 23.871944 },
  { name: 'D124I', latitude: 54.433892, longitude: 36.566658 },
  { name: 'D126K', latitude: 54.434036, longitude: 36.566981 },
  { name: 'FT25', latitude: 54.4345, longitude: 17.872194 },
  { name: 'RUBUN', latitude: 54.434722, longitude: 26.799722 },
  { name: 'EMGAR', latitude: 54.435278, longitude: 16.551944 },
  { name: 'FI25', latitude: 54.436722, longitude: 17.907472 },
  { name: 'FQ22', latitude: 54.438333, longitude: 16.405833 },
  { name: 'GD806', latitude: 54.438667, longitude: 17.844 },
  { name: 'FT22', latitude: 54.439722, longitude: 16.401667 },
  { name: 'VP369', latitude: 54.44, longitude: 16.373333 },
  { name: 'FT25Z', latitude: 54.44, longitude: 17.905556 },
  { name: 'CT25', latitude: 54.4405, longitude: 17.908425 },
  { name: 'GD541', latitude: 54.442222, longitude: 18.202861 },
  { name: 'D186L', latitude: 54.443181, longitude: 25.211139 },
  { name: 'VP368', latitude: 54.444722, longitude: 18.544444 },
  { name: 'CF25Y', latitude: 54.447678, longitude: 17.984942 },
  { name: 'GOMED', latitude: 54.447778, longitude: 19.654167 },
  { name: 'CN25', latitude: 54.448686, longitude: 17.987792 },
  { name: 'GD832', latitude: 54.449222, longitude: 18.541222 },
  { name: 'CF25Z', latitude: 54.449675, longitude: 17.999094 },
  { name: 'UVRIK', latitude: 54.451944, longitude: 18.162222 },
  { name: 'CT25Z', latitude: 54.453522, longitude: 17.982197 },
  { name: 'VI205', latitude: 54.454861, longitude: 25.334472 },
  { name: 'VI312', latitude: 54.455306, longitude: 25.162333 },
  { name: 'VP370', latitude: 54.456944, longitude: 17.210278 },
  { name: 'EMGAS', latitude: 54.457778, longitude: 37.838611 },
  { name: 'PEROM', latitude: 54.459656, longitude: 13.137689 },
  { name: 'RUMIK', latitude: 54.46, longitude: 30.538056 },
  { name: 'BANUB', latitude: 54.461944, longitude: 14.297222 },
  { name: 'OKSIL', latitude: 54.4625, longitude: 18.705556 },
  { name: 'D109I', latitude: 54.462756, longitude: 36.607403 },
  { name: 'D114K', latitude: 54.463225, longitude: 36.608181 },
  { name: 'BC631', latitude: 54.465972, longitude: 36.687972 },
  { name: 'D204L', latitude: 54.467522, longitude: 25.112986 },
  { name: 'BC015', latitude: 54.469056, longitude: 36.470889 },
  { name: 'VP371', latitude: 54.470556, longitude: 18.034722 },
  { name: 'VP372', latitude: 54.471111, longitude: 18.309722 },
  { name: 'DUKAT', latitude: 54.471472, longitude: 25.615333 },
  { name: 'BC014', latitude: 54.474306, longitude: 36.464361 },
  { name: 'INSOL', latitude: 54.476944, longitude: 25.350972 },
  { name: 'GD443', latitude: 54.477167, longitude: 18.062583 },

  { name: 'VP373', latitude: 54.4775, longitude: 16.651667 },
  { name: 'VP002', latitude: 54.478611, longitude: 24.840833 },
  { name: 'FN31', latitude: 54.480197, longitude: 36.456844 },
  { name: 'VP374', latitude: 54.480278, longitude: 18.564167 },
  { name: 'NESER', latitude: 54.48125, longitude: 25.052861 },
  { name: 'D070J', latitude: 54.481389, longitude: 30.607222 },
  { name: 'FD31', latitude: 54.481944, longitude: 36.446944 },
  { name: 'GD422', latitude: 54.48325, longitude: 18.787056 },
  { name: 'VI413', latitude: 54.483528, longitude: 25.489944 },
  { name: 'GD803', latitude: 54.483889, longitude: 18.030333 },
  { name: 'FQ31', latitude: 54.483889, longitude: 36.452222 },
  { name: 'BC231', latitude: 54.483917, longitude: 36.452444 },
  { name: 'IRKAL', latitude: 54.484, longitude: 24.219972 },
  { name: 'FF31', latitude: 54.485, longitude: 36.450833 },
  { name: 'BC031', latitude: 54.485083, longitude: 36.450778 },
  { name: 'AGNEF', latitude: 54.485833, longitude: 28.806111 },
  { name: 'IO204', latitude: 54.486111, longitude: 30.814167 },
  { name: 'NEDIK', latitude: 54.486389, longitude: 12.065 },
  { name: 'VI202', latitude: 54.489861, longitude: 25.186194 },
  { name: 'CQ22', latitude: 54.490619, longitude: 16.489917 },
  { name: 'RIFMA', latitude: 54.491306, longitude: 38.677556 },
  { name: 'FF23', latitude: 54.4925, longitude: 30.455278 },
  { name: 'FD23', latitude: 54.492778, longitude: 30.455833 },
  { name: 'CF01', latitude: 54.494825, longitude: 25.189567 },
  { name: 'VP375', latitude: 54.496944, longitude: 17.494444 },
  { name: 'LODKU', latitude: 54.497778, longitude: 38.195 },
  { name: 'PENOX', latitude: 54.498056, longitude: 23.451944 },
  { name: 'CT22', latitude: 54.498969, longitude: 16.475781 },
  { name: 'KAZLA', latitude: 54.499806, longitude: 23.309389 },
  { name: 'FI31', latitude: 54.502833, longitude: 18.642917 },
  { name: 'ASKIL', latitude: 54.503333, longitude: 31.084722 },
  { name: 'VP376', latitude: 54.504722, longitude: 17.639722 },
  { name: 'ARGAD', latitude: 54.506111, longitude: 13.496111 },
  { name: 'D112G', latitude: 54.507447, longitude: 18.725003 },
  { name: 'FT31', latitude: 54.5125, longitude: 18.633333 },
  { name: '28VOR', latitude: 54.513228, longitude: 36.41355 },
  { name: 'VI415', latitude: 54.513389, longitude: 25.202417 },
  { name: 'GD804', latitude: 54.515611, longitude: 17.898389 },
  { name: 'SDF31', latitude: 54.515933, longitude: 36.412122 },
  { name: 'IO202', latitude: 54.517222, longitude: 30.530278 },
  { name: 'PENAL', latitude: 54.518056, longitude: 28.521389 },
  { name: 'ANEPO', latitude: 54.518611, longitude: 38.146694 },
  { name: 'VI201', latitude: 54.520028, longitude: 25.207083 },
  { name: 'FN31', latitude: 54.522056, longitude: 18.6124 },
  { name: 'FF01', latitude: 54.523139, longitude: 25.209167 },
  { name: 'VI311', latitude: 54.523139, longitude: 25.209167 },
  { name: 'D103G', latitude: 54.524494, longitude: 18.738967 },
  { name: 'FI01', latitude: 54.525722, longitude: 25.210944 },
  { name: 'VI302', latitude: 54.527917, longitude: 24.522333 },
  { name: 'FD01', latitude: 54.528194, longitude: 25.190889 },
  { name: 'GD822', latitude: 54.528667, longitude: 18.217139 },
  { name: 'VI204', latitude: 54.53075, longitude: 24.953944 },
  { name: 'VP377', latitude: 54.531111, longitude: 18.375833 },
  { name: 'FR', latitude: 54.532722, longitude: 36.391333 },
  { name: 'BC913', latitude: 54.533361, longitude: 36.604111 },
  { name: 'DIRWE', latitude: 54.533611, longitude: 30.58 },
  { name: 'VP003', latitude: 54.537778, longitude: 24.9275 },
  { name: 'VP378', latitude: 54.54, longitude: 16.545 },
  { name: 'TENRI', latitude: 54.549444, longitude: 37.499444 },
  { name: 'LENOK', latitude: 54.551389, longitude: 27.441389 },
  { name: 'VP379', latitude: 54.552778, longitude: 17.836389 },
  { name: 'ATGEL', latitude: 54.553056, longitude: 25.389167 },
  { name: 'VP380', latitude: 54.553333, longitude: 18.480833 },
  { name: 'VI203', latitude: 54.553361, longitude: 25.062194 },
  { name: 'VI103', latitude: 54.559444, longitude: 25.078778 },
  { name: 'GD821', latitude: 54.560667, longitude: 18.085222 },
  { name: 'WI', latitude: 54.564417, longitude: 36.351278 },
  { name: 'SULIV', latitude: 54.566389, longitude: 12.53 },
  { name: 'BW504', latitude: 54.566667, longitude: 37.925278 },
  { name: 'NELOK', latitude: 54.568333, longitude: 29.808056 },
  { name: 'VI408', latitude: 54.571139, longitude: 25.632222 },
  { name: 'POVDE', latitude: 54.574167, longitude: 38.117222 },
  { name: '39DME', latitude: 54.577111, longitude: 25.246472 },
  { name: 'UDRIK', latitude: 54.578611, longitude: 27.794722 },
  { name: 'BEBEB', latitude: 54.580833, longitude: 14.082222 },
  { name: 'EKRON', latitude: 54.583611, longitude: 14.709444 },
  { name: 'DIVEK', latitude: 54.583889, longitude: 36.383056 },
  { name: 'ODUNI', latitude: 54.586944, longitude: 23.423611 },
  { name: 'BILDI', latitude: 54.587889, longitude: 24.945306 },
  { name: 'KUBIN', latitude: 54.588056, longitude: 27.694722 },
  { name: 'BC034', latitude: 54.591528, longitude: 36.317278 },
  { name: 'MT13', latitude: 54.593889, longitude: 18.490194 },
  { name: 'GD521', latitude: 54.594556, longitude: 18.089639 },
  { name: 'FT26', latitude: 54.595833, longitude: 18.611111 },
  { name: 'ALISI', latitude: 54.596111, longitude: 25.105278 },
  { name: 'EPFIN', latitude: 54.596111, longitude: 26.170833 },
  { name: 'NONSA', latitude: 54.598611, longitude: 13.300278 },
  { name: 'VP004', latitude: 54.599722, longitude: 25.494444 },
  { name: 'SUPUD', latitude: 54.6, longitude: 37.331389 },
  { name: 'BC813', latitude: 54.600722, longitude: 36.520056 },
  { name: 'VI208', latitude: 54.602778, longitude: 25.438083 },
  { name: 'DOSAB', latitude: 54.603889, longitude: 35.948611 },
  { name: 'VP381', latitude: 54.605, longitude: 18.248611 },
  { name: 'UGNUR', latitude: 54.606167, longitude: 18.273 },
  { name: 'ELPOL', latitude: 54.606389, longitude: 15.633889 },
  { name: 'MQ01', latitude: 54.606694, longitude: 25.266833 },
  { name: 'URAMA', latitude: 54.608333, longitude: 19.99 },
  { name: 'NIKDA', latitude: 54.608611, longitude: 12.285556 },
  { name: 'FD13', latitude: 54.61, longitude: 36.289722 },
  { name: 'BC013', latitude: 54.610139, longitude: 36.293806 },
  { name: 'VP382', latitude: 54.610278, longitude: 18.798889 },
  { name: 'FQ13', latitude: 54.611111, longitude: 36.292778 },
  { name: 'BC213', latitude: 54.611139, longitude: 36.292528 },
  { name: 'MD01', latitude: 54.611333, longitude: 25.270028 },
  { name: 'ML01', latitude: 54.612861, longitude: 25.271056 },
  { name: 'VI401', latitude: 54.613889, longitude: 24.849472 },
  { name: 'EPINI', latitude: 54.613889, longitude: 25.431667 },
  { name: 'LEKDO', latitude: 54.614722, longitude: 37.261111 },
  { name: 'FEGRA', latitude: 54.616111, longitude: 38.789972 },
  { name: 'RUTAM', latitude: 54.62, longitude: 20.65 },
  { name: 'BC032', latitude: 54.620944, longitude: 36.280083 },
  { name: 'D314E', latitude: 54.62135, longitude: 36.279972 },
  { name: 'DODTE', latitude: 54.631111, longitude: 38.643333 },
  { name: 'FN13', latitude: 54.631872, longitude: 36.266794 },
  { name: 'VP383', latitude: 54.634722, longitude: 18.513889 },
  { name: 'BC033', latitude: 54.635417, longitude: 36.261861 },
  { name: 'GD423', latitude: 54.637417, longitude: 18.14025 },
  { name: 'LEDVI', latitude: 54.6375, longitude: 25.135 },
  { name: 'IPLIT', latitude: 54.637778, longitude: 19.355556 },
  { name: 'FN13Y', latitude: 54.640625, longitude: 18.415917 },
  { name: 'BC035', latitude: 54.643056, longitude: 36.118444 },
  { name: 'VIZAN', latitude: 54.65, longitude: 17.647778 },
  { name: 'LAKOD', latitude: 54.651667, longitude: 22.755 },
  { name: 'KA303', latitude: 54.65175, longitude: 23.548556 },
  { name: 'LAZIT', latitude: 54.654028, longitude: 37.826111 },
  { name: 'ML19', latitude: 54.655444, longitude: 25.300556 },
  { name: 'KK726', latitude: 54.656917, longitude: 21.181472 },
  { name: 'ASDIN', latitude: 54.658333, longitude: 13.768611 },
  { name: 'FT13', latitude: 54.659722, longitude: 18.398056 },
  { name: 'MD19', latitude: 54.660861, longitude: 25.304306 },
  { name: 'BUKUB', latitude: 54.661111, longitude: 37.015833 },
  { name: 'MQ19', latitude: 54.661472, longitude: 25.30475 },
  { name: 'ANHOM', latitude: 54.665278, longitude: 26.253611 },
  { name: 'APSIK', latitude: 54.666667, longitude: 16.666667 },
  { name: 'IPTET', latitude: 54.666667, longitude: 33.3 },
  { name: 'BC713', latitude: 54.668, longitude: 36.43575 },
  { name: 'MOSON', latitude: 54.668333, longitude: 31.041389 },
  { name: 'BAMDO', latitude: 54.669444, longitude: 35.595 },
  { name: 'GD823', latitude: 54.670139, longitude: 18.16375 },
  { name: 'ZENIT', latitude: 54.673056, longitude: 25.735278 },
  { name: 'GD926', latitude: 54.674333, longitude: 18.164556 },
  { name: 'BC613', latitude: 54.674556, longitude: 36.06675 },
  { name: 'LAPKI', latitude: 54.675, longitude: 29.481389 },
  { name: 'TEKVE', latitude: 54.675, longitude: 34.9 },
  { name: 'KK633', latitude: 54.675139, longitude: 20.980778 },
  { name: 'BC413', latitude: 54.675833, longitude: 36.210889 },
  { name: 'VP384', latitude: 54.678889, longitude: 18.720278 },
  { name: 'RINLO', latitude: 54.680472, longitude: 38.060417 },
  { name: 'ABTOB', latitude: 54.683333, longitude: 31.316667 },
  { name: 'POKKO', latitude: 54.686944, longitude: 31.533333 },
  { name: '35DME', latitude: 54.691111, longitude: 25.325306 },
  { name: 'OKSOV', latitude: 54.691389, longitude: 18.330278 },
  { name: 'D168L', latitude: 54.699686, longitude: 20.634742 },
  { name: 'NEDRA', latitude: 54.7, longitude: 21.145 },
  { name: 'GUGGU', latitude: 54.7, longitude: 32.9 },
  { name: 'NO310', latitude: 54.700411, longitude: 18.315947 },
  { name: 'KK838', latitude: 54.70175, longitude: 20.906861 },
  { name: 'KK520', latitude: 54.706417, longitude: 20.921806 },
  { name: 'D156L', latitude: 54.708106, longitude: 20.703492 },
  { name: 'NEBSU', latitude: 54.709167, longitude: 33.858333 },
  { name: 'RAMUX', latitude: 54.71, longitude: 12.716944 },
  { name: 'VI104', latitude: 54.710583, longitude: 25.667472 },
  { name: 'KA206', latitude: 54.715194, longitude: 23.846 },
  { name: 'BAMOX', latitude: 54.715556, longitude: 17.283333 },
  { name: 'PIKID', latitude: 54.718889, longitude: 38.131389 },
  { name: 'KUTHU', latitude: 54.723333, longitude: 29.714444 },
  { name: 'ENOSI', latitude: 54.725556, longitude: 37.372778 },
  { name: 'KK501', latitude: 54.72575, longitude: 20.381639 },
  { name: 'DEVEL', latitude: 54.730833, longitude: 15.748056 },
  { name: 'OKPEN', latitude: 54.732083, longitude: 38.468667 },
  { name: 'SOTOG', latitude: 54.733333, longitude: 36.648056 },
  { name: 'BC513', latitude: 54.735222, longitude: 36.351139 },
  { name: 'KA207', latitude: 54.736139, longitude: 24.272611 },
  { name: 'GITIK', latitude: 54.736667, longitude: 36.176944 },
  { name: 'VP005', latitude: 54.737222, longitude: 25.556111 },
  { name: 'GODBI', latitude: 54.7375, longitude: 35.221111 },
  { name: 'VI306', latitude: 54.738194, longitude: 25.533111 },
  { name: 'FI19', latitude: 54.739972, longitude: 25.359333 },
  { name: 'VI411', latitude: 54.7425, longitude: 25.361083 },
  { name: 'FF19', latitude: 54.7425, longitude: 25.361083 },
  { name: 'PAFEN', latitude: 54.744194, longitude: 36.90675 },
  { name: 'FD19', latitude: 54.746194, longitude: 25.341111 },
  { name: 'KK724', latitude: 54.751306, longitude: 21.049833 },
  { name: 'D217Q', latitude: 54.751944, longitude: 23.739444 },
  { name: 'VI303', latitude: 54.755861, longitude: 24.626722 },
  { name: 'KK839', latitude: 54.757333, longitude: 20.284833 },
  { name: 'VI314', latitude: 54.757972, longitude: 25.371861 },
  { name: 'REKRO', latitude: 54.758889, longitude: 37.339639 },
  { name: 'OMEZA', latitude: 54.762972, longitude: 38.637139 },
  { name: 'VP385', latitude: 54.769722, longitude: 18.160278 },
  { name: 'CF19', latitude: 54.770833, longitude: 25.380819 },
  { name: 'D225L', latitude: 54.771308, longitude: 20.336817 },
  { name: 'KA204', latitude: 54.772667, longitude: 23.740694 },
  { name: 'BILRA', latitude: 54.774722, longitude: 14.338889 },
  { name: 'KK502', latitude: 54.775139, longitude: 20.153361 },
  { name: 'VI407', latitude: 54.776111, longitude: 25.667278 },
  { name: 'VI101', latitude: 54.776861, longitude: 25.384972 },
  { name: 'BENAL', latitude: 54.778333, longitude: 21.643333 },
  { name: 'KK728', latitude: 54.7795, longitude: 21.415917 },
  { name: 'KOSEB', latitude: 54.78, longitude: 12.597778 },
  { name: 'D229L', latitude: 54.781317, longitude: 20.323739 },
  { name: 'VI315', latitude: 54.781667, longitude: 25.388194 },
  { name: 'KK713', latitude: 54.781833, longitude: 21.026806 },
  { name: 'KA104', latitude: 54.789333, longitude: 24.2655 },
  { name: 'VP386', latitude: 54.790278, longitude: 18.408333 },
  { name: 'IVKUT', latitude: 54.790833, longitude: 38.21 },
  { name: 'OSPIL', latitude: 54.792222, longitude: 32.541944 },
  { name: 'D115L', latitude: 54.792681, longitude: 20.888364 },
  { name: 'DEGAG', latitude: 54.793889, longitude: 39.875 },
  { name: 'VAPOS', latitude: 54.795278, longitude: 17.826667 },
  { name: 'BANKU', latitude: 54.795833, longitude: 12.935278 },
  { name: 'KA403', latitude: 54.796611, longitude: 23.781611 },
  { name: 'VP006', latitude: 54.798056, longitude: 23.821111 },
  { name: 'ELEKA', latitude: 54.800444, longitude: 24.688917 },
  { name: 'OGDAV', latitude: 54.801944, longitude: 17.998194 },
  { name: 'VI307', latitude: 54.801972, longitude: 25.577417 },
  { name: 'VI209', latitude: 54.802944, longitude: 25.579611 },
  { name: 'KA404', latitude: 54.804389, longitude: 23.95625 },
  { name: 'KA105', latitude: 54.8055, longitude: 23.866861 },
  { name: 'GOSOT', latitude: 54.805556, longitude: 14.857778 },
  { name: 'VI102', latitude: 54.807972, longitude: 25.251444 },
  { name: 'LEHNE', latitude: 54.809722, longitude: 30.229444 },
  { name: 'VI412', latitude: 54.810417, longitude: 25.408417 },
  { name: 'CF06', latitude: 54.812217, longitude: 20.294253 },
  { name: 'KOTOV', latitude: 54.813472, longitude: 25.057028 },
  { name: 'KK504', latitude: 54.8165, longitude: 20.310444 },
  { name: 'KA405', latitude: 54.816972, longitude: 24.209806 },
  { name: 'VI304', latitude: 54.817833, longitude: 25.031917 },
  { name: 'POKEN', latitude: 54.819444, longitude: 14.564167 },
  { name: 'D004K', latitude: 54.821458, longitude: 25.361894 },
  { name: 'KOLOB', latitude: 54.823056, longitude: 14.944167 },
  { name: 'OLUZI', latitude: 54.823056, longitude: 37.816583 },
  { name: 'WIKEW', latitude: 54.825833, longitude: 14.009444 },
  { name: 'VI211', latitude: 54.826361, longitude: 25.223583 },
  { name: 'NIFAL', latitude: 54.826667, longitude: 21.214167 },
  { name: 'KK722', latitude: 54.826778, longitude: 21.214167 },
  { name: 'LILGA', latitude: 54.82725, longitude: 36.71475 },
  { name: 'LAFAT', latitude: 54.831111, longitude: 25.778611 },
  { name: 'KA203', latitude: 54.831889, longitude: 23.72275 },
  { name: 'UMSET', latitude: 54.833056, longitude: 14.158611 },
  { name: 'DAHRU', latitude: 54.833333, longitude: 28.859167 },
  { name: 'CI06Z', latitude: 54.837458, longitude: 20.390544 },
  { name: 'ROKAI', latitude: 54.84, longitude: 23.9475 },
  { name: 'EKSAM', latitude: 54.841139, longitude: 25.572917 },
  { name: 'VI414', latitude: 54.841361, longitude: 25.274667 },
  { name: 'VI105', latitude: 54.842278, longitude: 24.893972 },
  { name: 'BUNZI', latitude: 54.842722, longitude: 37.975028 },
  { name: 'KA302', latitude: 54.84275, longitude: 23.676917 },
  { name: 'WIKEE', latitude: 54.842778, longitude: 14.139167 },
  { name: 'KK715', latitude: 54.846611, longitude: 21.167861 },
  { name: 'POZUK', latitude: 54.850806, longitude: 38.365611 },
  { name: 'KK506', latitude: 54.850917, longitude: 20.442056 },
  { name: 'FF06', latitude: 54.851183, longitude: 20.443069 },
  { name: 'FOZLU', latitude: 54.851389, longitude: 30.433889 },
  { name: 'IGGAP', latitude: 54.857778, longitude: 38.490556 },
  { name: 'REGLU', latitude: 54.860333, longitude: 24.456583 },
  { name: 'KA103', latitude: 54.862972, longitude: 24.323222 },
  { name: '30THR', latitude: 54.864947, longitude: 20.495886 },
  { name: '39VOR', latitude: 54.865814, longitude: 20.497656 },
  { name: '38DME', latitude: 54.866397, longitude: 20.500306 },
  { name: 'VAKAL', latitude: 54.869722, longitude: 23.208056 },
  { name: '28LOC', latitude: 54.873025, longitude: 20.526919 },
  { name: 'KA454', latitude: 54.873889, longitude: 24.292694 },
  { name: 'ULKIL', latitude: 54.874722, longitude: 23.991389 },
  { name: 'KK518', latitude: 54.876917, longitude: 21.036056 },
  { name: 'KA301', latitude: 54.878306, longitude: 24.386361 },
  { name: 'D242M', latitude: 54.878386, longitude: 23.734486 },
  { name: 'SONAL', latitude: 54.878889, longitude: 12.780278 },
  { name: 'VI406', latitude: 54.878917, longitude: 24.681444 },
  { name: 'D242L', latitude: 54.87895, longitude: 23.736761 },
  { name: 'KA455', latitude: 54.8795, longitude: 24.410361 },
  { name: 'ML06', latitude: 54.882044, longitude: 20.561644 },
  { name: 'KK837', latitude: 54.883028, longitude: 21.021778 },
  { name: 'GEKBI', latitude: 54.883722, longitude: 25.406833 },
  { name: 'DER24', latitude: 54.885458, longitude: 20.574794 },
  { name: 'RAVPO', latitude: 54.886111, longitude: 24.22 },
  { name: 'KK632', latitude: 54.887472, longitude: 21.110222 },
  { name: 'AMROR', latitude: 54.89, longitude: 15.097222 },
  { name: 'RIREN', latitude: 54.891889, longitude: 37.770889 },
  { name: 'INKUZ', latitude: 54.894167, longitude: 26.233056 },
  { name: 'KK729', latitude: 54.895583, longitude: 20.377806 },
  { name: 'IRBAD', latitude: 54.895833, longitude: 20.309167 },
  { name: 'D265L', latitude: 54.896467, longitude: 20.261933 },
  { name: 'OKVAK', latitude: 54.898975, longitude: 38.153089 },
  { name: 'KK631', latitude: 54.899861, longitude: 20.954389 },
  { name: 'TARUN', latitude: 54.9, longitude: 19.6 },
  { name: 'MATAM', latitude: 54.902472, longitude: 23.622972 },
  { name: 'RILEP', latitude: 54.904722, longitude: 21.256389 },
  { name: 'VI206', latitude: 54.905306, longitude: 25.270667 },
  { name: 'GOTOG', latitude: 54.906858, longitude: 15.038683 },
  { name: 'KK503', latitude: 54.907222, longitude: 20.238944 },
  { name: 'KK041', latitude: 54.907333, longitude: 20.652667 },
  { name: 'D253O', latitude: 54.907778, longitude: 23.64 },
  { name: 'SOFON', latitude: 54.911111, longitude: 37.189444 },
  { name: 'RANOK', latitude: 54.912778, longitude: 18.958333 },
  { name: 'SALLO', latitude: 54.916667, longitude: 13.386194 },
  { name: 'BAKLI', latitude: 54.916667, longitude: 13.560778 },
  { name: 'OKAGA', latitude: 54.916667, longitude: 13.763611 },
  { name: 'UNGAV', latitude: 54.916667, longitude: 13.994722 },
  { name: 'BIKRU', latitude: 54.916667, longitude: 14.166667 },
  { name: 'DETNI', latitude: 54.916667, longitude: 14.344167 },
  { name: 'LUSID', latitude: 54.916667, longitude: 15.296111 },
  { name: 'GORPI', latitude: 54.916667, longitude: 15.655 },
  { name: 'BATNA', latitude: 54.916667, longitude: 30.836111 },
  { name: 'ODZAG', latitude: 54.916894, longitude: 38.150817 },
  { name: 'KK628', latitude: 54.917639, longitude: 20.70025 },
  { name: 'OZLER', latitude: 54.920525, longitude: 37.347667 },
  { name: 'SISPE', latitude: 54.920556, longitude: 37.344444 },
  { name: '49VOR', latitude: 54.922419, longitude: 20.731744 },
  { name: 'VOLIZ', latitude: 54.923056, longitude: 38.301944 },
  { name: '49DME', latitude: 54.925314, longitude: 20.729714 },
  { name: 'ARLIT', latitude: 54.925833, longitude: 23.165833 },
  { name: 'GUFUZ', latitude: 54.927181, longitude: 38.009606 },
  { name: 'FD24', latitude: 54.928289, longitude: 20.758464 },
  { name: 'KK731', latitude: 54.929222, longitude: 20.758139 },
  { name: 'FF24', latitude: 54.932156, longitude: 20.755661 },
  { name: 'KK710', latitude: 54.932222, longitude: 20.755917 },
  { name: 'KK519', latitude: 54.932861, longitude: 20.218306 },
  { name: 'VP007', latitude: 54.936389, longitude: 23.719444 },
  { name: 'NIBLA', latitude: 54.936639, longitude: 37.626167 },
  { name: 'ZOVGO', latitude: 54.938886, longitude: 38.287956 },
  { name: 'KK720', latitude: 54.939583, longitude: 21.291722 },
  { name: 'D070L', latitude: 54.939925, longitude: 20.927667 },
  { name: 'KK730', latitude: 54.940917, longitude: 21.502417 },
  { name: 'KRAKI', latitude: 54.941389, longitude: 22.734722 },
  { name: 'KK717', latitude: 54.943417, longitude: 21.234278 },
  { name: 'KA202', latitude: 54.944472, longitude: 23.704917 },
  { name: 'MONAK', latitude: 54.945556, longitude: 12.313611 },
  { name: 'CI24Z', latitude: 54.945711, longitude: 20.808442 },
  { name: 'CF08', latitude: 54.946667, longitude: 23.745833 },
  { name: 'KA352', latitude: 54.948, longitude: 23.77175 },
  { name: 'KEKOV', latitude: 54.949444, longitude: 14.441111 },
  { name: 'KA453', latitude: 54.950833, longitude: 23.825944 },
  { name: 'OBANO', latitude: 54.951417, longitude: 25.083556 },
  { name: 'KK836', latitude: 54.9515, longitude: 20.720806 },
  { name: 'NEDAM', latitude: 54.952889, longitude: 24.247528 },
  { name: 'KA201', latitude: 54.953944, longitude: 23.886639 },
  { name: 'FF08', latitude: 54.954111, longitude: 23.889833 },
  { name: 'KA351', latitude: 54.954111, longitude: 23.889972 },
  { name: 'FN08', latitude: 54.954139, longitude: 23.889833 },
  { name: 'FI08', latitude: 54.954333, longitude: 23.894111 },
  { name: '30DME', latitude: 54.958722, longitude: 23.980083 },
  { name: 'XERPA', latitude: 54.959167, longitude: 16.761111 },
  { name: 'D069P', latitude: 54.960089, longitude: 21.038061 },
  { name: 'KUDRE', latitude: 54.960556, longitude: 32.734444 },
  { name: 'MQ08', latitude: 54.961003, longitude: 24.028828 },
  { name: 'MD08', latitude: 54.961389, longitude: 24.033583 },
  { name: 'D164D', latitude: 54.961747, longitude: 14.922942 },
  { name: 'MA08', latitude: 54.96175, longitude: 24.041111 },
  { name: 'KK505', latitude: 54.962139, longitude: 20.449306 },
  { name: 'CD24Z', latitude: 54.962622, longitude: 20.915431 },
  { name: 'INVIR', latitude: 54.962808, longitude: 15.154292 },
  { name: 'EE115', latitude: 54.962944, longitude: 37.134861 },
  { name: '30VOR', latitude: 54.962972, longitude: 23.979944 },
  { name: 'D063L', latitude: 54.964358, longitude: 20.914269 },
  { name: 'EE828', latitude: 54.965, longitude: 38.1625 },
  { name: 'KA457', latitude: 54.965306, longitude: 24.497611 },
  { name: 'FD08', latitude: 54.965611, longitude: 23.889444 },
  { name: 'KK712', latitude: 54.965972, longitude: 20.887444 },
  { name: 'MA26', latitude: 54.966083, longitude: 24.128472 },
  { name: 'MD26', latitude: 54.966167, longitude: 24.129917 },
  { name: 'MQ26', latitude: 54.966531, longitude: 24.137992 },
  { name: '40DME', latitude: 54.968667, longitude: 24.181389 },
  { name: 'D265K', latitude: 54.969667, longitude: 23.744972 },
  { name: '40VOR', latitude: 54.970778, longitude: 24.180944 },
  { name: 'CI24X', latitude: 54.971267, longitude: 20.908292 },
  { name: 'D060L', latitude: 54.972056, longitude: 20.908725 },
  { name: 'FI26', latitude: 54.973139, longitude: 24.273778 },
  { name: 'KA451', latitude: 54.973333, longitude: 24.277833 },
  { name: 'FF26', latitude: 54.973333, longitude: 24.278083 },
  { name: 'FN26', latitude: 54.973361, longitude: 24.278083 },
  { name: 'DOMAG', latitude: 54.974444, longitude: 17.685556 },
  { name: 'KA101', latitude: 54.974694, longitude: 24.307139 },
  { name: 'KA353', latitude: 54.974972, longitude: 24.312056 },
  { name: 'DD046', latitude: 54.975556, longitude: 38.218056 },
  { name: 'ULTUZ', latitude: 54.978056, longitude: 38.423889 },
  { name: 'D145D', latitude: 54.978306, longitude: 14.950561 },
  { name: 'KA452', latitude: 54.978944, longitude: 24.396222 },
  { name: 'CF26', latitude: 54.978944, longitude: 24.396222 },
  { name: 'FD26', latitude: 54.979389, longitude: 24.276833 },
  { name: 'NIDOL', latitude: 54.980639, longitude: 36.825639 },
  { name: 'CD24Y', latitude: 54.982619, longitude: 21.007411 },
  { name: 'KK522', latitude: 54.983444, longitude: 21.193639 },
  { name: 'KK516', latitude: 54.987222, longitude: 21.006222 },
  { name: 'D293L', latitude: 54.987283, longitude: 20.303553 },
  { name: 'BUNOS', latitude: 54.989444, longitude: 18.181667 },
  { name: 'IPKED', latitude: 54.989756, longitude: 38.399719 },
  { name: 'ULRED', latitude: 54.990278, longitude: 15.048889 },
  { name: 'KK509', latitude: 54.991056, longitude: 20.944083 },
  { name: 'LUPUR', latitude: 54.991111, longitude: 12.050833 },
  { name: 'VP004', latitude: 54.991111, longitude: 15.083611 },
  { name: 'BATTE', latitude: 54.991389, longitude: 30.951389 },
  { name: 'DD047', latitude: 54.993611, longitude: 38.276667 },
  { name: 'OFIRA', latitude: 54.997917, longitude: 37.726694 },
  { name: 'CI24W', latitude: 54.998489, longitude: 21.015153 },
  { name: '5513E', latitude: 55.0, longitude: 13.0 },
  { name: '5514E', latitude: 55.0, longitude: 14.0 },
  { name: '5515E', latitude: 55.0, longitude: 15.0 },
  { name: '5516E', latitude: 55.0, longitude: 16.0 },
  { name: '5517E', latitude: 55.0, longitude: 17.0 },
  { name: '5518E', latitude: 55.0, longitude: 18.0 },
  { name: '5519E', latitude: 55.0, longitude: 19.0 },
  { name: '5520E', latitude: 55.0, longitude: 20.0 },
  { name: '5521E', latitude: 55.0, longitude: 21.0 },
  { name: '5522E', latitude: 55.0, longitude: 22.0 },
  { name: '5523E', latitude: 55.0, longitude: 23.0 },
  { name: '5524E', latitude: 55.0, longitude: 24.0 },
  { name: '5525E', latitude: 55.0, longitude: 25.0 },
  { name: '5526E', latitude: 55.0, longitude: 26.0 },
  { name: '5527E', latitude: 55.0, longitude: 27.0 },
  { name: '5528E', latitude: 55.0, longitude: 28.0 },
  { name: '5529E', latitude: 55.0, longitude: 29.0 },
  { name: '5530E', latitude: 55.0, longitude: 30.0 },
  { name: '5531E', latitude: 55.0, longitude: 31.0 },
  { name: '5532E', latitude: 55.0, longitude: 32.0 },
  { name: '5533E', latitude: 55.0, longitude: 33.0 },
  { name: '5534E', latitude: 55.0, longitude: 34.0 },
  { name: '5535E', latitude: 55.0, longitude: 35.0 },
  { name: '5536E', latitude: 55.0, longitude: 36.0 },
  { name: '5537E', latitude: 55.0, longitude: 37.0 },
  { name: '5538E', latitude: 55.0, longitude: 38.0 },
  { name: '5539E', latitude: 55.0, longitude: 39.0 },
  { name: '5540E', latitude: 55.0, longitude: 40.0 },
  { name: 'RUDUR', latitude: 55.001194, longitude: 37.888556 },
  { name: 'D050L', latitude: 55.001797, longitude: 20.880339 },
  { name: 'D048L', latitude: 55.006675, longitude: 20.874475 },
  { name: 'CI29', latitude: 55.007086, longitude: 14.981233 },
  { name: 'BETUD', latitude: 55.007222, longitude: 12.5225 },
  { name: 'OLDAP', latitude: 55.008056, longitude: 31.616667 },
  { name: 'KK629', latitude: 55.009556, longitude: 20.240194 },
  { name: 'KK507', latitude: 55.00975, longitude: 20.633111 },
  { name: 'RAMZA', latitude: 55.010306, longitude: 37.352806 },
  { name: 'KK834', latitude: 55.01075, longitude: 20.254417 },
  { name: 'URIMI', latitude: 55.013056, longitude: 34.668056 },
  { name: 'FONAS', latitude: 55.013889, longitude: 23.882222 },
  { name: 'W209', latitude: 55.015, longitude: 30.180556 },
  { name: 'KOSMO', latitude: 55.015278, longitude: 12.730278 },
  { name: 'MATEK', latitude: 55.016389, longitude: 12.800833 },
  { name: 'D217J', latitude: 55.016667, longitude: 30.163056 },
  { name: 'SOTEP', latitude: 55.019347, longitude: 14.933072 },
  { name: 'FF29', latitude: 55.01975, longitude: 14.929286 },
  { name: 'FI29', latitude: 55.020556, longitude: 14.928289 },
  { name: 'DETUS', latitude: 55.022806, longitude: 12.999667 },
  { name: 'D063V', latitude: 55.025661, longitude: 21.189494 },
  { name: 'GIVAR', latitude: 55.026389, longitude: 16.302222 },
  { name: 'TEPTA', latitude: 55.02825, longitude: 37.353833 },
  { name: 'RUMAR', latitude: 55.033611, longitude: 16.070833 },
  { name: 'D227J', latitude: 55.033611, longitude: 30.115278 },
  { name: 'SITGU', latitude: 55.035, longitude: 23.841111 },
  { name: 'BALOX', latitude: 55.035528, longitude: 13.426972 },
  { name: 'KA456', latitude: 55.036028, longitude: 24.52425 },
  { name: 'UKABE', latitude: 55.037694, longitude: 37.5445 },
  { name: 'VEBUS', latitude: 55.038667, longitude: 23.70325 },
  { name: 'NALFI', latitude: 55.040083, longitude: 37.177917 },
  { name: '30LOC', latitude: 55.040714, longitude: 14.848806 },
  { name: 'MOSAT', latitude: 55.041944, longitude: 12.788056 },
  { name: 'DD059', latitude: 55.041944, longitude: 38.306389 },
  { name: 'II603', latitude: 55.044722, longitude: 30.048611 },
  { name: 'BABUT', latitude: 55.04525, longitude: 38.018556 },
  { name: 'KK719', latitude: 55.046389, longitude: 21.207833 },
  { name: 'KA355', latitude: 55.047556, longitude: 23.756056 },
  { name: 'ASBAX', latitude: 55.049697, longitude: 15.149236 },
  { name: 'TUSDA', latitude: 55.050278, longitude: 24.136389 },
  { name: 'RAMBE', latitude: 55.051111, longitude: 27.428889 },
  { name: 'ABNUK', latitude: 55.051389, longitude: 34.976667 },
  { name: 'TEBDI', latitude: 55.053278, longitude: 37.528917 },
  { name: 'KA354', latitude: 55.053556, longitude: 23.874417 },
  { name: 'TELMO', latitude: 55.054611, longitude: 14.116278 },
  { name: 'RONEZ', latitude: 55.055444, longitude: 37.194111 },
  { name: 'D322L', latitude: 55.058214, longitude: 20.419917 },
  { name: 'AGPAB', latitude: 55.059444, longitude: 36.759167 },
  { name: 'KK718', latitude: 55.059611, longitude: 21.261056 },
  { name: 'TUSBU', latitude: 55.059647, longitude: 14.773903 },
  { name: 'GIROR', latitude: 55.06, longitude: 14.406667 },
  { name: 'D300K', latitude: 55.060756, longitude: 23.813469 },
  { name: 'FN05', latitude: 55.063647, longitude: 30.145172 },
  { name: 'PUVVO', latitude: 55.064167, longitude: 38.502778 },
  { name: 'PIDES', latitude: 55.066883, longitude: 14.745214 },
  { name: 'IZVOK', latitude: 55.068872, longitude: 38.472272 },
  { name: 'MA11', latitude: 55.070936, longitude: 14.731547 },
  { name: 'D018L', latitude: 55.071078, longitude: 20.737339 },
  { name: 'LUKAG', latitude: 55.072828, longitude: 14.380544 },
  { name: 'KA205', latitude: 55.073889, longitude: 22.963028 },
  { name: 'VI207', latitude: 55.074444, longitude: 25.37225 },
  { name: 'KK524', latitude: 55.077528, longitude: 21.285361 },
  { name: 'REPMI', latitude: 55.078333, longitude: 20.581389 },
  { name: '17NM', latitude: 55.078361, longitude: 14.700167 },
  { name: '19LOC', latitude: 55.078381, longitude: 14.699536 },
  { name: 'FD05', latitude: 55.08, longitude: 30.18 },
  { name: 'NASAP', latitude: 55.082556, longitude: 24.469611 },
  { name: 'KK511', latitude: 55.082972, longitude: 20.918972 },
  { name: 'D351L', latitude: 55.087639, longitude: 20.581311 },
  { name: 'D275I', latitude: 55.08775, longitude: 14.511872 },
  { name: 'DENZA', latitude: 55.094222, longitude: 37.187806 },
  { name: 'D048U', latitude: 55.094442, longitude: 21.089328 },
  { name: 'ODARU', latitude: 55.095833, longitude: 12.761389 },
  { name: 'DOMFA', latitude: 55.0975, longitude: 38.584444 },
  { name: 'KK514', latitude: 55.097528, longitude: 20.976278 },
  { name: 'D279I', latitude: 55.097547, longitude: 14.515375 },
  { name: 'VEPIS', latitude: 55.097778, longitude: 30.988056 },
  { name: 'DD362', latitude: 55.098556, longitude: 38.278764 },
  { name: 'FF11', latitude: 55.100733, longitude: 14.579953 },
  { name: 'KK835', latitude: 55.101667, longitude: 20.979889 },
  { name: 'UDSAN', latitude: 55.102222, longitude: 35.931111 },
  { name: 'SURZA', latitude: 55.104417, longitude: 37.911111 },
  { name: '33VOR', latitude: 55.104444, longitude: 30.271944 },
  { name: 'ZADAK', latitude: 55.105, longitude: 34.986667 },
  { name: 'KA305', latitude: 55.105667, longitude: 23.299333 },
  { name: 'LAKEG', latitude: 55.106111, longitude: 37.5 },
  { name: 'FI11', latitude: 55.106378, longitude: 14.587914 },
  { name: 'VI107', latitude: 55.107167, longitude: 24.759167 },
  { name: 'LEBLO', latitude: 55.107339, longitude: 14.586047 },
  { name: 'SIDAZ', latitude: 55.108056, longitude: 37.317778 },
  { name: 'MS613', latitude: 55.111639, longitude: 13.495806 },
  { name: 'MEZER', latitude: 55.115194, longitude: 37.050472 },
  { name: 'OKRIL', latitude: 55.116667, longitude: 32.611389 },
  { name: 'CI11', latitude: 55.119672, longitude: 14.534706 },
  { name: 'NIDBE', latitude: 55.121261, longitude: 38.412472 },
  { name: 'ALBOR', latitude: 55.122822, longitude: 38.124564 },
  { name: 'KA102', latitude: 55.123972, longitude: 24.285639 },
  { name: 'TOLKE', latitude: 55.124028, longitude: 37.07775 },
  { name: 'KA401', latitude: 55.124778, longitude: 23.861972 },
  { name: 'KK725', latitude: 55.125444, longitude: 20.758361 },
  { name: 'KK721', latitude: 55.127889, longitude: 21.094972 },
  { name: 'DILNI', latitude: 55.130806, longitude: 37.646333 },
  { name: 'KK727', latitude: 55.131778, longitude: 21.311583 },
  { name: 'ODMEI', latitude: 55.133889, longitude: 14.475278 },
  { name: 'TITAP', latitude: 55.133944, longitude: 36.937361 },
  { name: 'PODIL', latitude: 55.136667, longitude: 26.474167 },
  { name: 'KA402', latitude: 55.13725, longitude: 23.6735 },
  { name: 'KK630', latitude: 55.139806, longitude: 21.21475 },
  { name: 'LIHUD', latitude: 55.140278, longitude: 29.359167 },
  { name: 'ITVUL', latitude: 55.140833, longitude: 21.716667 },
  { name: 'VALTA', latitude: 55.141667, longitude: 29.176389 },
  { name: 'U078', latitude: 55.1425, longitude: 30.601389 },
  { name: '18VOR', latitude: 55.143889, longitude: 30.405278 },
  { name: 'ASNIG', latitude: 55.145833, longitude: 21.216389 },
  { name: 'BENIM', latitude: 55.146389, longitude: 28.221389 },
  { name: '27NDB', latitude: 55.149722, longitude: 30.427222 },
  { name: 'PELUG', latitude: 55.15, longitude: 20.321667 },
  { name: 'D072J', latitude: 55.151111, longitude: 30.633889 },
  { name: 'DISGO', latitude: 55.151583, longitude: 12.733556 },
  { name: 'UTITO', latitude: 55.153333, longitude: 35.140278 },
  { name: 'KA306', latitude: 55.154278, longitude: 23.000389 },
  { name: 'KK716', latitude: 55.154639, longitude: 21.129528 },
  { name: 'KK513', latitude: 55.155306, longitude: 20.816889 },
  { name: 'D069J', latitude: 55.161111, longitude: 30.630833 },
  { name: 'OGMOP', latitude: 55.161389, longitude: 38.684167 },
  { name: 'MALIV', latitude: 55.162722, longitude: 13.036861 },
  { name: 'KK723', latitude: 55.165917, longitude: 20.925806 },
  { name: 'LIFAS', latitude: 55.166667, longitude: 37.798056 },
  { name: 'UMVAP', latitude: 55.168319, longitude: 14.344117 },
  { name: 'BABOT', latitude: 55.17, longitude: 16.690278 },
  { name: 'DD058', latitude: 55.171389, longitude: 38.300556 },
  { name: 'D063I', latitude: 55.173056, longitude: 30.606111 },
  { name: 'KK517', latitude: 55.173639, longitude: 20.504583 },
  { name: 'FI23', latitude: 55.174167, longitude: 30.508889 },
  { name: 'FD23', latitude: 55.18, longitude: 30.507222 },
  { name: 'D059I', latitude: 55.181944, longitude: 30.600556 },
  { name: 'VI402', latitude: 55.182222, longitude: 24.766556 },
  { name: 'KK512', latitude: 55.184389, longitude: 20.853806 },
  { name: 'FQ23', latitude: 55.187328, longitude: 30.550917 },
  { name: 'WW306', latitude: 55.1875, longitude: 36.842778 },
  { name: 'KK515', latitude: 55.188528, longitude: 20.665056 },
  { name: 'UPAGA', latitude: 55.188697, longitude: 37.215258 },
  { name: 'NUTKI', latitude: 55.189722, longitude: 34.891389 },
  { name: 'PEFOS', latitude: 55.189889, longitude: 38.330694 },
  { name: 'D227T', latitude: 55.192717, longitude: 13.119542 },
  { name: 'RT29', latitude: 55.193164, longitude: 38.732689 },
  { name: 'AKUMA', latitude: 55.193833, longitude: 22.752944 },
  { name: 'HASLE', latitude: 55.193889, longitude: 14.71 },
  { name: 'RIVDI', latitude: 55.194167, longitude: 17.072222 },
  { name: 'CI23', latitude: 55.194444, longitude: 30.576389 },
  { name: 'KK714', latitude: 55.199, longitude: 20.932222 },
  { name: 'PEPUT', latitude: 55.199444, longitude: 12.050278 },
  { name: 'VI106', latitude: 55.199528, longitude: 25.393722 },
  { name: 'VI403', latitude: 55.200222, longitude: 25.180667 },
  { name: 'ABNAB', latitude: 55.201389, longitude: 19.634167 },
  { name: 'ALGOI', latitude: 55.203056, longitude: 38.200833 },
  { name: 'BISTA', latitude: 55.203383, longitude: 12.12305 },
  { name: 'KOVOD', latitude: 55.203611, longitude: 34.281944 },
  { name: 'VI404', latitude: 55.205389, longitude: 25.306056 },
  { name: 'KK508', latitude: 55.206417, longitude: 20.47875 },
  { name: 'MOTAD', latitude: 55.209444, longitude: 17.505556 },
  { name: 'VI405', latitude: 55.210278, longitude: 25.427306 },
  { name: 'II601', latitude: 55.214167, longitude: 30.642222 },
  { name: 'BEMAS', latitude: 55.214453, longitude: 37.539253 },
  { name: 'PIZUR', latitude: 55.216667, longitude: 36.998056 },
  { name: 'FIDOT', latitude: 55.216667, longitude: 37.364722 },
  { name: 'TADUT', latitude: 55.216889, longitude: 37.030083 },
  { name: 'OGTET', latitude: 55.218036, longitude: 14.481533 },
  { name: 'BUFEL', latitude: 55.218694, longitude: 38.392556 },
  { name: 'SAZDU', latitude: 55.220694, longitude: 38.150389 },
  { name: 'OVZED', latitude: 55.222222, longitude: 37.984722 },
  { name: 'KUBIS', latitude: 55.223056, longitude: 12.481667 },
  { name: 'OBOPE', latitude: 55.223056, longitude: 37.761111 },
  { name: 'II602', latitude: 55.224167, longitude: 30.631944 },
  { name: 'KK510', latitude: 55.224278, longitude: 20.6715 },
  { name: 'EE114', latitude: 55.225444, longitude: 37.241056 },
  { name: 'KUTAX', latitude: 55.225869, longitude: 12.535 },
  { name: 'RAFFU', latitude: 55.228056, longitude: 38.788611 },
  { name: 'ERMAL', latitude: 55.228333, longitude: 36.608056 },
  { name: 'EVBED', latitude: 55.230833, longitude: 12.627778 },
  { name: 'NEKSO', latitude: 55.231944, longitude: 12.649444 },
  { name: 'RUSNE', latitude: 55.235278, longitude: 21.438056 },
  { name: 'UGLUN', latitude: 55.237056, longitude: 36.844083 },
  { name: 'MS710', latitude: 55.239694, longitude: 13.196917 },
  { name: 'KODON', latitude: 55.24, longitude: 29.181389 },
  { name: 'EE806', latitude: 55.241667, longitude: 37.820833 },
  { name: 'IFUTU', latitude: 55.241889, longitude: 38.086778 },
  { name: 'AMSAL', latitude: 55.242222, longitude: 33.061111 },
  { name: 'MS720', latitude: 55.243556, longitude: 13.103861 },
  { name: 'EE116', latitude: 55.247333, longitude: 37.249972 },
  { name: 'DD324', latitude: 55.249164, longitude: 38.121436 },
  { name: 'SIMEG', latitude: 55.250028, longitude: 13.501194 },
  { name: 'BW317', latitude: 55.250833, longitude: 38.508528 },
  { name: 'RAGSE', latitude: 55.251389, longitude: 38.402778 },
  { name: 'ODVUV', latitude: 55.252083, longitude: 38.112861 },
  { name: 'MS614', latitude: 55.253028, longitude: 13.788889 },
  { name: 'LILBI', latitude: 55.253056, longitude: 12.682778 },
  { name: 'KA406', latitude: 55.253083, longitude: 24.578028 },
  { name: 'DD712', latitude: 55.253861, longitude: 38.253694 },
  { name: 'NAMNA', latitude: 55.257222, longitude: 32.5 },
  { name: 'KUNER', latitude: 55.258611, longitude: 18.448056 },
  { name: 'KUPVE', latitude: 55.258667, longitude: 38.247806 },
  { name: 'ROXUB', latitude: 55.263056, longitude: 14.08 },
  { name: 'VI305', latitude: 55.263694, longitude: 24.729056 },
  { name: 'LERTE', latitude: 55.264444, longitude: 36.620556 },
  { name: 'LEGSI', latitude: 55.265, longitude: 28.926389 },
  { name: 'LIVDA', latitude: 55.267778, longitude: 34.150278 },
  { name: 'ORSIF', latitude: 55.268056, longitude: 36.971111 },
  { name: 'EDASO', latitude: 55.268611, longitude: 37.346944 },
  { name: 'VAGDE', latitude: 55.271667, longitude: 37.5375 },
  { name: 'DD322', latitude: 55.273333, longitude: 37.836028 },
  { name: 'UMUDU', latitude: 55.274167, longitude: 37.661111 },
  { name: 'SOMAG', latitude: 55.274222, longitude: 37.661083 },
  { name: 'KA307', latitude: 55.27425, longitude: 23.650417 },
  { name: 'LARMA', latitude: 55.274444, longitude: 16.501667 },
  { name: 'BADNI', latitude: 55.275, longitude: 36.944722 },
  { name: 'DD700', latitude: 55.276222, longitude: 38.065111 },
  { name: 'MALNE', latitude: 55.278611, longitude: 34.790833 },
  { name: 'RATIN', latitude: 55.28, longitude: 30.861389 },
  { name: 'FF32L', latitude: 55.289225, longitude: 38.028594 },
  { name: 'FQ32L', latitude: 55.289256, longitude: 38.028672 },
  { name: 'DD335', latitude: 55.291583, longitude: 38.070922 },
  { name: 'EE117', latitude: 55.292472, longitude: 37.637778 },
  { name: 'DD347', latitude: 55.293114, longitude: 38.025647 },
  { name: 'DD341', latitude: 55.2938, longitude: 38.062842 },
  { name: 'PREKI', latitude: 55.295222, longitude: 36.380472 },
  { name: 'NERIG', latitude: 55.3, longitude: 20.916667 },
  { name: 'RAFNI', latitude: 55.305111, longitude: 36.997472 },
  { name: 'MS635', latitude: 55.305278, longitude: 13.372 },
  { name: 'DD401', latitude: 55.307608, longitude: 37.959103 },
  { name: 'UNUSA', latitude: 55.310833, longitude: 40.879444 },
  { name: 'BW316', latitude: 55.310944, longitude: 38.390194 },
  { name: 'D110Z', latitude: 55.311617, longitude: 38.812069 },
  { name: 'TURIP', latitude: 55.311667, longitude: 20.56 },
  { name: 'MS611', latitude: 55.311972, longitude: 13.473389 },
  { name: 'SODLE', latitude: 55.313306, longitude: 37.115194 },
  { name: 'RORUK', latitude: 55.316361, longitude: 37.370528 },
  { name: 'GAVBA', latitude: 55.318028, longitude: 13.015278 },
  { name: 'BW318', latitude: 55.318056, longitude: 38.613167 },
  { name: 'TOSLA', latitude: 55.319944, longitude: 38.174722 },
  { name: 'MS825', latitude: 55.320583, longitude: 13.258472 },
  { name: 'NAVUG', latitude: 55.324167, longitude: 34.784444 },
  { name: 'SOROM', latitude: 55.324806, longitude: 13.419444 },
  { name: 'MS652', latitude: 55.327194, longitude: 13.4075 },
  { name: 'BIPSI', latitude: 55.33875, longitude: 37.756722 },
  { name: 'MS612', latitude: 55.341694, longitude: 13.577861 },
  { name: 'SIVNI', latitude: 55.342778, longitude: 33.041389 },
  { name: 'IKAMU', latitude: 55.344528, longitude: 24.636806 },
  { name: 'CH551', latitude: 55.345661, longitude: 12.498036 },
  { name: 'ABTAS', latitude: 55.346944, longitude: 34.156944 },
  { name: 'D218D', latitude: 55.348008, longitude: 37.830261 },
  { name: 'NUKDI', latitude: 55.349944, longitude: 35.915694 },
  { name: 'MINMA', latitude: 55.351944, longitude: 35.114167 },
  { name: 'GILUT', latitude: 55.355, longitude: 21.671944 },
  { name: 'DD400', latitude: 55.355525, longitude: 38.025533 },
  { name: 'MS610', latitude: 55.358722, longitude: 13.412528 },
  { name: 'BW321', latitude: 55.358933, longitude: 38.295772 },
  { name: '21VOR', latitude: 55.3592, longitude: 37.946008 },
  { name: '21DME', latitude: 55.359228, longitude: 37.946083 },
  { name: 'VEGUD', latitude: 55.363056, longitude: 13.200278 },
  { name: 'REMLI', latitude: 55.363889, longitude: 37.546583 },
  { name: 'AMKUK', latitude: 55.366667, longitude: 31.316944 },
  { name: 'CH381', latitude: 55.368347, longitude: 12.531583 },
  { name: 'WW058', latitude: 55.369778, longitude: 37.27225 },
  { name: '16THR', latitude: 55.373153, longitude: 37.92975 },
  { name: 'LEBDA', latitude: 55.373611, longitude: 12.628611 },
  { name: 'CH552', latitude: 55.37515, longitude: 12.955217 },
  { name: 'DOPEM', latitude: 55.375278, longitude: 12.483611 },
  { name: 'CH444', latitude: 55.378236, longitude: 12.125117 },
  { name: 'BW319', latitude: 55.379247, longitude: 38.493239 },
  { name: 'AMNON', latitude: 55.3825, longitude: 36.808889 },
  { name: 'REPRO', latitude: 55.384419, longitude: 13.104914 },
  { name: 'ADVIS', latitude: 55.384722, longitude: 13.006389 },
  { name: 'CH442', latitude: 55.386139, longitude: 12.287419 },
  { name: 'EGABA', latitude: 55.388244, longitude: 12.083814 },
  { name: 'UPERI', latitude: 55.388611, longitude: 37.934444 },
  { name: 'BILRU', latitude: 55.389444, longitude: 35.575 },
  { name: 'TEZUT', latitude: 55.389444, longitude: 37.461389 },
  { name: 'DE14R', latitude: 55.395119, longitude: 37.90335 },
  { name: 'CH366', latitude: 55.396047, longitude: 12.619289 },
  { name: 'DE14L', latitude: 55.396336, longitude: 37.945617 },
  { name: 'EBUKA', latitude: 55.397778, longitude: 21.373056 },
  { name: 'DE14C', latitude: 55.399369, longitude: 37.936475 },
  { name: 'AGONA', latitude: 55.4, longitude: 20.553333 },
  { name: 'CH396', latitude: 55.402056, longitude: 12.789033 },
  { name: 'EE832', latitude: 55.405833, longitude: 36.581944 },
  { name: 'BUPOS', latitude: 55.405833, longitude: 36.997472 },
  { name: 'MS600', latitude: 55.408222, longitude: 13.402417 },
  { name: 'FD35', latitude: 55.408581, longitude: 13.398894 },
  { name: 'DEZAP', latitude: 55.408611, longitude: 37.906389 },
  { name: 'CI35', latitude: 55.408672, longitude: 13.402347 },
  { name: 'CH382', latitude: 55.409856, longitude: 12.878894 },
  { name: 'TIDVU', latitude: 55.411306, longitude: 13.557528 },
  { name: 'MUGKO', latitude: 55.411667, longitude: 36.989167 },
  { name: 'ELVIX', latitude: 55.411944, longitude: 14.094167 },
  { name: 'KUDTI', latitude: 55.413889, longitude: 39.333333 },
  { name: 'D105Q', latitude: 55.416383, longitude: 38.603492 },
  { name: 'DE32C', latitude: 55.416944, longitude: 37.915361 },
  { name: 'WW016', latitude: 55.418086, longitude: 37.183014 },
  { name: 'DD327', latitude: 55.42025, longitude: 37.659089 },
  { name: 'NIVSA', latitude: 55.420278, longitude: 24.549167 },
  { name: 'DE32L', latitude: 55.421061, longitude: 37.872144 },
  { name: 'CH441', latitude: 55.422528, longitude: 12.342914 },
  { name: 'CH377', latitude: 55.423914, longitude: 12.392447 },
  { name: 'DE32R', latitude: 55.424517, longitude: 37.911767 },
  { name: 'ODLOR', latitude: 55.426444, longitude: 38.401 },
  { name: 'D148H', latitude: 55.427439, longitude: 38.235942 },
  { name: 'DENEK', latitude: 55.427722, longitude: 13.041583 },
  { name: 'NAPOT', latitude: 55.428056, longitude: 23.254722 },
  { name: 'BATIG', latitude: 55.431389, longitude: 30.911111 },
  { name: 'D155G', latitude: 55.431844, longitude: 38.206503 },
  { name: 'FOZBE', latitude: 55.4325, longitude: 37.351667 },
  { name: 'FOBUL', latitude: 55.438222, longitude: 37.144833 },
  { name: 'INBOV', latitude: 55.438333, longitude: 23.41 },
  { name: 'KETFI', latitude: 55.441222, longitude: 37.706028 },
  { name: 'VALTI', latitude: 55.441703, longitude: 13.118781 },
  { name: 'TOTRA', latitude: 55.443056, longitude: 22.771111 },
  { name: 'SOTAS', latitude: 55.4434, longitude: 12.305725 },
  { name: 'VAGIS', latitude: 55.444722, longitude: 21.353889 },
  { name: 'BIMME', latitude: 55.445417, longitude: 38.024694 },
  { name: 'MO303', latitude: 55.447222, longitude: 37.313611 },
  { name: 'LATMI', latitude: 55.448611, longitude: 18.167222 },
  { name: 'CH395', latitude: 55.450575, longitude: 12.768556 },
  { name: 'PELUT', latitude: 55.451222, longitude: 23.610306 },
  { name: '50VOR', latitude: 55.451667, longitude: 13.392281 },
  { name: 'D171E', latitude: 55.451667, longitude: 13.392281 },
  { name: 'KOLED', latitude: 55.453056, longitude: 33.766389 },
  { name: 'ETIVE', latitude: 55.453333, longitude: 37.6325 },
  { name: 'MO305', latitude: 55.454, longitude: 37.444528 },
  { name: 'MO310', latitude: 55.454167, longitude: 37.408333 },
  { name: '48DME', latitude: 55.454464, longitude: 37.832425 },
  { name: '48VOR', latitude: 55.454614, longitude: 37.832819 },
  { name: '40THR', latitude: 55.456981, longitude: 13.392456 },
  { name: 'CI30', latitude: 55.458994, longitude: 38.335914 },
  { name: 'BATKU', latitude: 55.459167, longitude: 26.551111 },
  { name: 'FI35', latitude: 55.459897, longitude: 13.391869 },
  { name: 'UTENU', latitude: 55.461889, longitude: 25.443333 },
  { name: 'KIBUR', latitude: 55.46235, longitude: 37.551672 },
  { name: 'UGNIS', latitude: 55.464139, longitude: 23.812361 },
  { name: 'CH443', latitude: 55.466667, longitude: 12.253383 },
  { name: 'BASLO', latitude: 55.466856, longitude: 12.410622 },
  { name: 'DD326', latitude: 55.467278, longitude: 37.779725 },
  { name: 'CH993', latitude: 55.468836, longitude: 12.705856 },
  { name: 'WW014', latitude: 55.470972, longitude: 37.316194 },
  { name: 'TAGOL', latitude: 55.471333, longitude: 25.2215 },
  { name: 'D120G', latitude: 55.471628, longitude: 38.304842 },
  { name: 'ETRUS', latitude: 55.473333, longitude: 15.634722 },
  { name: 'OTVOV', latitude: 55.474444, longitude: 20.291667 },
  { name: 'CD04R', latitude: 55.475714, longitude: 12.444167 },
  { name: 'PAMKE', latitude: 55.477222, longitude: 32.323611 },
  { name: 'CI04L', latitude: 55.477619, longitude: 12.427208 },
  { name: 'DD141', latitude: 55.478083, longitude: 37.841697 },
  { name: 'VP012', latitude: 55.478611, longitude: 12.137778 },
  { name: 'DD144', latitude: 55.478861, longitude: 37.802444 },
  { name: 'FD30X', latitude: 55.47945, longitude: 38.289603 },
  { name: 'MURUN', latitude: 55.479611, longitude: 24.977806 },
  { name: 'FQ14R', latitude: 55.479689, longitude: 37.802431 },
  { name: 'FF14R', latitude: 55.479831, longitude: 37.802794 },
  { name: 'FN30', latitude: 55.480853, longitude: 38.291792 },
  { name: 'VIBUM', latitude: 55.480906, longitude: 12.502889 },
  { name: 'FQ30', latitude: 55.481139, longitude: 38.290675 },
  { name: 'BW333', latitude: 55.481556, longitude: 38.291556 },
  { name: 'MS551', latitude: 55.481778, longitude: 13.387389 },
  { name: 'NISLO', latitude: 55.4825, longitude: 12.884722 },
  { name: 'FD30', latitude: 55.483797, longitude: 38.294622 },
  { name: 'VENOM', latitude: 55.485, longitude: 13.129444 },
  { name: 'WW192', latitude: 55.485083, longitude: 37.117611 },
  { name: 'DD070', latitude: 55.485197, longitude: 37.838675 },
  { name: 'BW144', latitude: 55.485722, longitude: 38.092167 },
  { name: 'DD033', latitude: 55.486111, longitude: 37.622778 },
  { name: 'ARC30', latitude: 55.487528, longitude: 13.475056 },
  { name: 'CI04R', latitude: 55.487786, longitude: 12.455439 },
  { name: 'MS550', latitude: 55.488139, longitude: 13.563333 },
  { name: 'MS651', latitude: 55.489, longitude: 13.385889 },
  { name: 'BW132', latitude: 55.489281, longitude: 38.346667 },
  { name: 'VP001', latitude: 55.489722, longitude: 13.235278 },
  { name: 'D186C', latitude: 55.490639, longitude: 38.130833 },
  { name: 'KOKAK', latitude: 55.491389, longitude: 12.715 },
  { name: 'WW505', latitude: 55.491611, longitude: 37.064861 },
  { name: 'BOPPE', latitude: 55.492222, longitude: 37.947222 },
  { name: 'BW511', latitude: 55.493611, longitude: 38.136389 },
  { name: 'WW500', latitude: 55.49425, longitude: 37.068472 },
  { name: 'ERPUK', latitude: 55.495278, longitude: 12.240833 },
  { name: 'MO084', latitude: 55.497017, longitude: 37.3054 },
  { name: 'DD323', latitude: 55.497181, longitude: 37.864886 },
  { name: 'CH365', latitude: 55.498136, longitude: 12.748603 },
  { name: 'UZMAH', latitude: 55.498333, longitude: 28.750278 },
  { name: 'PIPOM', latitude: 55.5, longitude: 19.9 },
  { name: 'FN07X', latitude: 55.501111, longitude: 37.3825 },
  { name: 'TEGLU', latitude: 55.501667, longitude: 30.428056 },
  { name: 'MO092', latitude: 55.502025, longitude: 37.399956 },
  { name: 'KUFAT', latitude: 55.502694, longitude: 38.817417 },
  { name: 'OLMOR', latitude: 55.502778, longitude: 18.729444 },
  { name: 'VP002', latitude: 55.503889, longitude: 13.532222 },
  { name: 'GEGNA', latitude: 55.504167, longitude: 37.716972 },
  { name: 'DER25', latitude: 55.506778, longitude: 37.487944 },
  { name: 'RK373', latitude: 55.507028, longitude: 12.048542 },
  { name: 'WW238', latitude: 55.507361, longitude: 37.461917 },
  { name: 'DER07', latitude: 55.508439, longitude: 37.520281 },
  { name: 'ULKEK', latitude: 55.509611, longitude: 37.357639 },
  { name: 'BW138', latitude: 55.509656, longitude: 38.235669 },
  { name: 'REPNU', latitude: 55.5125, longitude: 33.643611 },
  { name: 'MO267', latitude: 55.5133, longitude: 37.613367 },
  { name: 'FI25', latitude: 55.513528, longitude: 37.617678 },
  { name: 'FN25', latitude: 55.513611, longitude: 37.623889 },
  { name: 'WW187', latitude: 55.515333, longitude: 37.424 },
  { name: 'KATEZ', latitude: 55.516142, longitude: 37.758297 },
  { name: 'BW137', latitude: 55.516178, longitude: 38.222758 },
  { name: 'RK292', latitude: 55.5182, longitude: 12.371208 },
  { name: 'MO264', latitude: 55.519278, longitude: 37.732222 },
  { name: 'DD043', latitude: 55.52, longitude: 38.972778 },
  { name: 'FD01', latitude: 55.520278, longitude: 37.2257 },
  { name: 'MS650', latitude: 55.520389, longitude: 13.379472 },
  { name: 'DANMI', latitude: 55.520556, longitude: 35.065278 },
  { name: 'OSBOB', latitude: 55.520833, longitude: 35.091667 },
  { name: 'OGPOB', latitude: 55.520833, longitude: 37.657222 },
  { name: 'OKPAN', latitude: 55.521944, longitude: 18.921111 },
  { name: 'WW150', latitude: 55.522972, longitude: 37.209111 },
  { name: 'FQ01', latitude: 55.523039, longitude: 37.209978 },
  { name: 'WW151', latitude: 55.5236, longitude: 37.209589 },
  { name: 'NIZVO', latitude: 55.524889, longitude: 37.608667 },
  { name: 'CH364', latitude: 55.525906, longitude: 12.783925 },
  { name: 'LIZHI', latitude: 55.526056, longitude: 37.783722 },
  { name: 'FD04R', latitude: 55.526767, longitude: 12.51935 },
  { name: 'D218E', latitude: 55.5275, longitude: 12.516944 },
  { name: 'DAZGI', latitude: 55.5275, longitude: 37.741944 },
  { name: 'DD071', latitude: 55.527531, longitude: 37.787519 },
  { name: 'ANKAZ', latitude: 55.527683, longitude: 37.925531 },
  { name: 'FI04R', latitude: 55.527911, longitude: 12.517075 },
  { name: 'NATLO', latitude: 55.528611, longitude: 36.791389 },
  { name: 'NUPVI', latitude: 55.529722, longitude: 16.7875 },
  { name: 'CI30', latitude: 55.530408, longitude: 12.891322 },
  { name: 'FI04L', latitude: 55.531544, longitude: 12.510017 },
  { name: 'IXURI', latitude: 55.531667, longitude: 21.318889 },
  { name: 'GAREK', latitude: 55.533889, longitude: 19.05 },
  { name: 'DER12', latitude: 55.536506, longitude: 38.182286 },
  { name: 'GENKE', latitude: 55.539639, longitude: 37.949611 },
  { name: 'CH374', latitude: 55.539736, longitude: 12.376528 },
  { name: 'WW501', latitude: 55.540611, longitude: 37.035806 },
  { name: 'ULTIS', latitude: 55.540833, longitude: 12.863389 },
  { name: 'MS721', latitude: 55.541472, longitude: 13.170833 },
  { name: 'DD044', latitude: 55.543611, longitude: 38.752222 },
  { name: 'AMRED', latitude: 55.544861, longitude: 37.054056 },
  { name: 'RK291', latitude: 55.546178, longitude: 12.271931 },
  { name: 'WW191', latitude: 55.546222, longitude: 37.202194 },
  { name: 'REBRI', latitude: 55.548222, longitude: 37.026278 },
  { name: 'WW240', latitude: 55.548222, longitude: 37.146083 },
  { name: 'URAGO', latitude: 55.55, longitude: 39.998056 },
  { name: 'IMZUP', latitude: 55.550325, longitude: 37.870511 },
  { name: 'MS500', latitude: 55.550444, longitude: 13.565028 },
  { name: 'DD039', latitude: 55.5525, longitude: 37.816667 },
  { name: 'MS502', latitude: 55.552556, longitude: 13.758194 },
  { name: 'MOSIN', latitude: 55.552778, longitude: 12.798056 },
  { name: 'ENMUR', latitude: 55.552778, longitude: 37.571944 },
  { name: 'MS530', latitude: 55.554694, longitude: 13.561861 },
  { name: 'FQ06', latitude: 55.5605, longitude: 37.125208 },
  { name: 'WW506', latitude: 55.560706, longitude: 37.124553 },
  { name: 'WW502', latitude: 55.560917, longitude: 37.125528 },
  { name: 'FD06', latitude: 55.561439, longitude: 37.124586 },
  { name: 'DUPIZ', latitude: 55.561525, longitude: 37.704417 },
  { name: 'TIPID', latitude: 55.563333, longitude: 40.466111 },
  { name: 'BW151', latitude: 55.563561, longitude: 38.411983 },
  { name: 'BW510', latitude: 55.563889, longitude: 37.996944 },
  { name: 'DER30', latitude: 55.563928, longitude: 38.127764 },
  { name: 'D192B', latitude: 55.564364, longitude: 37.241478 },
  { name: 'LEVDO', latitude: 55.565472, longitude: 12.574944 },
  { name: 'WW952', latitude: 55.565611, longitude: 37.241639 },
  { name: 'D194B', latitude: 55.565633, longitude: 37.235611 },
  { name: 'MS456', latitude: 55.566472, longitude: 13.533917 },
  { name: 'CH445', latitude: 55.566667, longitude: 12.283333 },
  { name: 'BITSA', latitude: 55.566667, longitude: 37.614722 },
  { name: 'RUBAT', latitude: 55.569028, longitude: 12.56775 },
  { name: 'FI30', latitude: 55.569708, longitude: 12.785897 },
  { name: 'TORUP', latitude: 55.57, longitude: 13.220556 },
  { name: 'MO300', latitude: 55.570278, longitude: 37.724444 },
  { name: 'CH992', latitude: 55.570489, longitude: 12.77795 },
  { name: 'ETDAR', latitude: 55.571, longitude: 37.73125 },
  { name: 'DD072', latitude: 55.571825, longitude: 37.733939 },
  { name: '13THR', latitude: 55.573483, longitude: 12.174608 },
  { name: 'WW019', latitude: 55.574097, longitude: 37.491597 },
  { name: 'NIGLI', latitude: 55.574694, longitude: 38.309972 },
  { name: 'DOMEN', latitude: 55.574722, longitude: 13.522778 },
  { name: 'OKUSE', latitude: 55.575, longitude: 37.62 },
  { name: 'ABVIV', latitude: 55.575278, longitude: 38.452778 },
  { name: 'ORVOZ', latitude: 55.575278, longitude: 39.52 },
  { name: 'D235B', latitude: 55.576739, longitude: 37.208619 },
  { name: 'CH363', latitude: 55.577508, longitude: 12.849728 },
  { name: '10VOR', latitude: 55.577775, longitude: 12.594728 },
  { name: 'D057B', latitude: 55.578908, longitude: 37.207097 },
  { name: 'WW452', latitude: 55.579472, longitude: 37.207694 },
  { name: 'MS876', latitude: 55.580444, longitude: 13.196917 },
  { name: 'MS400', latitude: 55.582139, longitude: 13.366778 },
  { name: 'SUPEK', latitude: 55.583333, longitude: 30.948056 },
  { name: 'IVGOR', latitude: 55.584444, longitude: 17.958889 },
  { name: 'ORSOP', latitude: 55.585278, longitude: 32.993333 },
  { name: 'PA205', latitude: 55.586722, longitude: 20.231556 },
  { name: 'MS510', latitude: 55.5875, longitude: 13.544306 },
  { name: 'BW149', latitude: 55.587717, longitude: 38.0802 },
  { name: 'BW146', latitude: 55.587731, longitude: 37.866258 },
  { name: 'D275I', latitude: 55.589486, longitude: 37.905533 },
  { name: 'MS900', latitude: 55.589722, longitude: 13.240528 },
  { name: '40DME', latitude: 55.590667, longitude: 38.073853 },
  { name: '40VOR', latitude: 55.590986, longitude: 38.074375 },
  { name: 'MS540', latitude: 55.591639, longitude: 13.796222 },
  { name: 'WW239', latitude: 55.591972, longitude: 37.502917 },
  { name: 'NUSMO', latitude: 55.592961, longitude: 12.436942 },
  { name: 'BW134', latitude: 55.594389, longitude: 38.300028 },
  { name: 'ASLEK', latitude: 55.595222, longitude: 36.997472 },
  { name: 'RK369', latitude: 55.596081, longitude: 12.093794 },
  { name: 'MQ11', latitude: 55.596328, longitude: 12.094072 },
  { name: 'MS522', latitude: 55.597611, longitude: 13.363583 },
  { name: 'ODMEG', latitude: 55.600044, longitude: 12.704217 },
  { name: 'BW136', latitude: 55.600611, longitude: 38.054778 },
  { name: 'IPIOS', latitude: 55.601667, longitude: 37.753056 },
  { name: 'ANDIF', latitude: 55.60205, longitude: 38.012925 },
  { name: 'D059C', latitude: 55.602453, longitude: 37.323247 },
  { name: 'ARC27', latitude: 55.602778, longitude: 13.442111 },
  { name: 'TIGNU', latitude: 55.603611, longitude: 19.838306 },
  { name: 'EE831', latitude: 55.604167, longitude: 36.527222 },
  { name: 'MUZKE', latitude: 55.605, longitude: 36.785833 },
  { name: 'ALODO', latitude: 55.605278, longitude: 35.081667 },
  { name: 'D237D', latitude: 55.605972, longitude: 37.323897 },
  { name: 'WW652', latitude: 55.606028, longitude: 37.325972 },
  { name: 'OZFUR', latitude: 55.608083, longitude: 37.826639 },
  { name: 'MS455', latitude: 55.608694, longitude: 13.361722 },
  { name: 'ALELI', latitude: 55.611389, longitude: 31.194444 },
  { name: 'AKKUS', latitude: 55.611389, longitude: 36.647778 },
  { name: 'FI17', latitude: 55.611625, longitude: 13.360697 },
  { name: 'DER01', latitude: 55.611789, longitude: 37.276883 },
  { name: 'VP016', latitude: 55.611944, longitude: 12.365556 },
  { name: 'KANEN', latitude: 55.612806, longitude: 37.364028 },
  { name: 'DESAS', latitude: 55.613056, longitude: 13.160556 },
  { name: 'VP017', latitude: 55.613333, longitude: 12.494722 },
  { name: 'D285I', latitude: 55.614131, longitude: 37.921997 },
  { name: 'UNRUM', latitude: 55.614667, longitude: 38.145139 },
  { name: 'UVRUV', latitude: 55.614722, longitude: 38.892222 },
  { name: 'WW451', latitude: 55.616539, longitude: 37.372769 },
  { name: 'DAKLO', latitude: 55.616667, longitude: 38.631389 },
  { name: 'WW450', latitude: 55.61675, longitude: 37.373722 },
  { name: 'D348E', latitude: 55.616889, longitude: 13.359072 },
  { name: 'FQ24', latitude: 55.616925, longitude: 37.373167 },
  { name: 'FD24', latitude: 55.618228, longitude: 37.372164 },
  { name: 'BW148', latitude: 55.619303, longitude: 38.017064 },
  { name: 'FQ12', latitude: 55.619386, longitude: 38.017511 },
  { name: 'D192C', latitude: 55.619397, longitude: 37.282153 },
  { name: 'WW152', latitude: 55.619556, longitude: 37.282806 },
  { name: 'FF12', latitude: 55.620431, longitude: 38.018414 },
  { name: 'D002B', latitude: 55.622286, longitude: 37.268108 },
  { name: 'WW063', latitude: 55.622836, longitude: 37.400944 },
  { name: 'MS521', latitude: 55.626583, longitude: 13.375111 },
  { name: 'CH360', latitude: 55.628069, longitude: 12.6717 },
  { name: 'MD22L', latitude: 55.628733, longitude: 12.6703 },
  { name: 'KORFI', latitude: 55.629333, longitude: 38.61575 },
  { name: 'MS511', latitude: 55.631083, longitude: 13.588528 },
  { name: 'CH367', latitude: 55.631261, longitude: 12.664942 },
  { name: 'POKAG', latitude: 55.632222, longitude: 35.550278 },
  { name: 'NAKFE', latitude: 55.632778, longitude: 37.021944 },
  { name: 'INKIG', latitude: 55.633803, longitude: 12.613 },
  { name: 'WW188', latitude: 55.634511, longitude: 37.455775 },
  { name: 'ISHAJ', latitude: 55.635556, longitude: 12.289167 },
  { name: 'AFISA', latitude: 55.638278, longitude: 37.470556 },
  { name: 'PENOR', latitude: 55.638611, longitude: 17.161389 },
  { name: 'MK720', latitude: 55.63925, longitude: 14.022917 },
  { name: 'OTDUH', latitude: 55.640972, longitude: 38.378722 },
  { name: 'WW241', latitude: 55.642389, longitude: 37.467667 },
  { name: 'KENEP', latitude: 55.642667, longitude: 37.200194 },
  { name: 'LIKNI', latitude: 55.642722, longitude: 37.026194 },
  { name: 'NEMEB', latitude: 55.645, longitude: 33.374722 },
  { name: 'RAFDA', latitude: 55.649444, longitude: 38.233389 },
  { name: 'SOBVE', latitude: 55.649556, longitude: 38.201611 },
  { name: 'GEKLA', latitude: 55.65, longitude: 37.868056 },
  { name: 'BULAT', latitude: 55.650556, longitude: 37.6875 },
  { name: 'TEDNI', latitude: 55.651111, longitude: 32.182778 },
  { name: 'IBZEK', latitude: 55.651667, longitude: 37.836389 },
  { name: 'BW147', latitude: 55.652589, longitude: 37.950756 },
  { name: 'WW018', latitude: 55.654381, longitude: 37.422214 },
  { name: 'WW951', latitude: 55.660525, longitude: 37.314194 },
  { name: 'FF19', latitude: 55.660614, longitude: 37.313861 },
  { name: 'WW950', latitude: 55.660917, longitude: 37.3145 },
  { name: 'MAXUM', latitude: 55.66125, longitude: 13.604 },
  { name: 'NERNO', latitude: 55.661389, longitude: 31.542778 },
  { name: 'CH386', latitude: 55.662708, longitude: 12.108242 },
  { name: 'ODASA', latitude: 55.662889, longitude: 23.40275 },
  { name: 'MS901', latitude: 55.664333, longitude: 13.349778 },
  { name: 'CI17', latitude: 55.664494, longitude: 13.349786 },
  { name: 'FD17', latitude: 55.664658, longitude: 13.347169 },
  { name: 'D012C', latitude: 55.665289, longitude: 12.018558 },
  { name: 'FI12', latitude: 55.66815, longitude: 12.519842 },
  { name: 'APTEL', latitude: 55.67, longitude: 36.966667 },
  { name: 'ULGIV', latitude: 55.67, longitude: 37.428056 },
  { name: 'MS520', latitude: 55.6715, longitude: 13.424944 },
  { name: 'ODLIT', latitude: 55.6725, longitude: 24.904444 },
  { name: 'FI22R', latitude: 55.673131, longitude: 12.728956 },
  { name: 'CH399', latitude: 55.673361, longitude: 12.419444 },
  { name: 'MANUX', latitude: 55.676194, longitude: 22.035611 },
  { name: 'WW017', latitude: 55.677583, longitude: 37.327447 },
  { name: 'OTSUR', latitude: 55.678944, longitude: 38.222083 },
  { name: 'CH362', latitude: 55.682008, longitude: 12.983656 },
  { name: 'PEREK', latitude: 55.682694, longitude: 38.016111 },
  { name: 'FI22L', latitude: 55.6857, longitude: 12.761175 },
  { name: 'EE807', latitude: 55.69, longitude: 38.387778 },
  { name: 'D038H', latitude: 55.691111, longitude: 12.768889 },
  { name: 'CH446', latitude: 55.691667, longitude: 12.225925 },
  { name: 'VALUV', latitude: 55.692139, longitude: 20.132972 },
  { name: 'FD22L', latitude: 55.692367, longitude: 12.765042 },
  { name: 'VALBY', latitude: 55.693333, longitude: 12.133889 },
  { name: 'MS457', latitude: 55.693694, longitude: 13.495028 },
  { name: 'WW504', latitude: 55.694611, longitude: 37.412278 },
  { name: 'JOLCA', latitude: 55.696333, longitude: 23.719167 },
  { name: 'D179N', latitude: 55.696653, longitude: 14.056547 },
  { name: 'DUZEV', latitude: 55.697222, longitude: 38.458889 },
  { name: 'VADRU', latitude: 55.6975, longitude: 17.785556 },
  { name: 'D312I', latitude: 55.698333, longitude: 12.428611 },
  { name: 'PA203', latitude: 55.700472, longitude: 21.521056 },
  { name: 'PA204', latitude: 55.703528, longitude: 20.985917 },
  { name: 'PA305', latitude: 55.705222, longitude: 20.420667 },
  { name: 'MS875', latitude: 55.705889, longitude: 13.282389 },
  { name: 'CD32L', latitude: 55.706, longitude: 23.522 },
  { name: 'MS853', latitude: 55.7065, longitude: 13.208361 },
  { name: 'LUMIT', latitude: 55.706667, longitude: 29.489722 },
  { name: 'CI12', latitude: 55.707133, longitude: 12.413681 },
  { name: 'OKZOP', latitude: 55.708333, longitude: 38.296667 },
  { name: 'WW503', latitude: 55.709306, longitude: 37.351639 },
  { name: 'BAPRO', latitude: 55.709722, longitude: 33.240556 },
  { name: 'ANEDU', latitude: 55.710278, longitude: 34.5875 },
  { name: 'MK560', latitude: 55.711361, longitude: 14.187278 },
  { name: 'D146K', latitude: 55.713144, longitude: 23.553367 },
  { name: 'MS850', latitude: 55.713583, longitude: 13.339583 },
  { name: 'EPOXO', latitude: 55.714917, longitude: 12.392389 },
  { name: 'LOGPU', latitude: 55.715278, longitude: 38.696944 },
  { name: 'FI21', latitude: 55.716, longitude: 12.269928 },
  { name: 'VP020', latitude: 55.716111, longitude: 12.598889 },
  { name: 'CT32L', latitude: 55.717, longitude: 23.545667 },
  { name: 'ADOVI', latitude: 55.720833, longitude: 13.032222 },
  { name: 'PA304', latitude: 55.721222, longitude: 20.784778 },
  { name: 'MK710', latitude: 55.721778, longitude: 14.041111 },
  { name: 'CD32R', latitude: 55.722056, longitude: 23.583417 },
  { name: 'DIRON', latitude: 55.723333, longitude: 36.695 },
  { name: 'CT32R', latitude: 55.724667, longitude: 23.571833 },
  { name: 'CH991', latitude: 55.724958, longitude: 13.018978 },
  { name: 'LOLTE', latitude: 55.725333, longitude: 22.598944 },
  { name: 'CF32R', latitude: 55.726028, longitude: 23.594694 },
  { name: 'D140K', latitude: 55.726306, longitude: 23.595389 },
  { name: 'DUZZU', latitude: 55.726667, longitude: 37.383333 },
  { name: 'CI22R', latitude: 55.726844, longitude: 12.812597 },
  { name: 'MAZIL', latitude: 55.727472, longitude: 37.355417 },
  { name: 'LAPKO', latitude: 55.72775, longitude: 20.937583 },
  { name: 'PA303', latitude: 55.731528, longitude: 21.699639 },
  { name: 'MK730', latitude: 55.732056, longitude: 13.894861 },
  { name: 'NEGMU', latitude: 55.732778, longitude: 35.065278 },
  { name: 'SA452', latitude: 55.732861, longitude: 23.586778 },
  { name: 'MEFED', latitude: 55.733583, longitude: 38.9325 },
  { name: 'KEDDA', latitude: 55.733889, longitude: 36.948333 },
  { name: 'GIGUN', latitude: 55.735278, longitude: 36.958306 },
  { name: 'SUDAB', latitude: 55.7375, longitude: 23.3325 },
  { name: 'CI22L', latitude: 55.7394, longitude: 12.844872 },
  { name: 'ULMIG', latitude: 55.740556, longitude: 36.835833 },
  { name: 'RONUN', latitude: 55.744722, longitude: 19.329167 },
  { name: 'CH361', latitude: 55.745539, longitude: 12.854439 },
  { name: 'NORVI', latitude: 55.746333, longitude: 13.332861 },
  { name: 'CQ01', latitude: 55.746433, longitude: 14.062692 },
  { name: 'D164H', latitude: 55.748389, longitude: 23.461433 },
  { name: 'CD22L', latitude: 55.749575, longitude: 12.850572 },
  { name: 'LAMOX', latitude: 55.750383, longitude: 12.862028 },
  { name: 'LUPTE', latitude: 55.751389, longitude: 38.57 },
  { name: 'ARBOK', latitude: 55.751667, longitude: 37.621389 },
  { name: 'MS546', latitude: 55.75175, longitude: 13.495278 },
  { name: 'MALIX', latitude: 55.753056, longitude: 12.309722 },
  { name: 'LIDRI', latitude: 55.753889, longitude: 35.540833 },
  { name: 'ESARA', latitude: 55.756667, longitude: 21.226944 },
  { name: 'MS851', latitude: 55.76025, longitude: 13.277889 },
  { name: 'D114Y', latitude: 55.76165, longitude: 21.750264 },
  { name: 'ITVIN', latitude: 55.762778, longitude: 36.483611 },
  { name: 'D112Y', latitude: 55.763981, longitude: 21.742439 },
  { name: 'EE819', latitude: 55.764167, longitude: 37.866389 },
  { name: 'ONIFA', latitude: 55.764278, longitude: 21.184 },
  { name: 'ABVUS', latitude: 55.766667, longitude: 37.843056 },
  { name: 'VIVZO', latitude: 55.766944, longitude: 39.400833 },
  { name: 'MS545', latitude: 55.767028, longitude: 13.380444 },
  { name: 'MS512', latitude: 55.767222, longitude: 13.61725 },
  { name: 'SITMU', latitude: 55.768944, longitude: 13.618917 },
  { name: 'PA106', latitude: 55.769, longitude: 21.908722 },
  { name: 'MS539', latitude: 55.76925, longitude: 13.498389 },
  { name: 'DD038', latitude: 55.771944, longitude: 36.575833 },
  { name: 'D238Y', latitude: 55.774417, longitude: 20.425508 },
  { name: 'EKRAL', latitude: 55.776778, longitude: 13.962833 },
  { name: 'GOLMI', latitude: 55.777222, longitude: 12.516389 },
  { name: 'EROKO', latitude: 55.780556, longitude: 36.849722 },
  { name: 'AGASA', latitude: 55.780833, longitude: 20.436389 },
  { name: 'CH368', latitude: 55.780906, longitude: 12.719514 },
  { name: 'AGRUZ', latitude: 55.783139, longitude: 38.104722 },
  { name: 'OKNOD', latitude: 55.784722, longitude: 27.566389 },
  { name: 'LUTGE', latitude: 55.79, longitude: 37.369722 },
  { name: 'PA301', latitude: 55.791083, longitude: 21.330806 },
  { name: 'FD32L', latitude: 55.791667, longitude: 23.469833 },
  { name: 'CH884', latitude: 55.794036, longitude: 12.278736 },
  { name: 'FT32L', latitude: 55.794333, longitude: 23.482 },
  { name: 'DIMGI', latitude: 55.794444, longitude: 39.811111 },
  { name: 'MONIK', latitude: 55.795806, longitude: 38.127333 },
  { name: 'PA404', latitude: 55.796167, longitude: 21.647889 },
  { name: 'FT32R', latitude: 55.798833, longitude: 23.496167 },
  { name: 'CH883', latitude: 55.8, longitude: 12.591667 },
  { name: 'OLAGO', latitude: 55.8, longitude: 30.489722 },
  { name: 'CH389', latitude: 55.800047, longitude: 12.869836 },
  { name: 'FD32R', latitude: 55.800306, longitude: 23.500361 },
  { name: 'EKDUN', latitude: 55.800556, longitude: 36.813333 },
  { name: 'CH881', latitude: 55.801311, longitude: 12.942258 },
  { name: 'SA451', latitude: 55.801694, longitude: 23.505 },
  { name: 'FF32R', latitude: 55.801778, longitude: 23.504806 },
  { name: 'EE802', latitude: 55.801944, longitude: 38.409167 },
  { name: 'FQ01', latitude: 55.802853, longitude: 14.069675 },
  { name: 'PA202', latitude: 55.803278, longitude: 21.025667 },
  { name: 'FI32R', latitude: 55.803472, longitude: 23.502778 },
  { name: 'MK700', latitude: 55.804306, longitude: 14.059389 },
  { name: 'GORAX', latitude: 55.806111, longitude: 13.040556 },
  { name: 'UMDUS', latitude: 55.806667, longitude: 37.386389 },
  { name: 'BALIT', latitude: 55.810278, longitude: 19.091111 },
  { name: 'CH398', latitude: 55.812525, longitude: 12.346519 },
  { name: 'DUBIN', latitude: 55.812778, longitude: 26.978056 },
  { name: 'EE804', latitude: 55.814444, longitude: 36.01 },
  { name: 'PA352', latitude: 55.814861, longitude: 21.030306 },
  { name: 'USEMU', latitude: 55.815556, longitude: 35.054167 },
  { name: 'NUZOR', latitude: 55.817722, longitude: 36.895222 },
  { name: 'NAGGO', latitude: 55.826667, longitude: 37.833333 },
  { name: 'XETRI', latitude: 55.826778, longitude: 13.598194 },
  { name: 'TAREF', latitude: 55.826944, longitude: 38.379722 },
  { name: 'KOLOP', latitude: 55.827222, longitude: 21.186389 },
  { name: 'ARLUM', latitude: 55.829167, longitude: 40.624444 },
  { name: 'EE827', latitude: 55.83, longitude: 37.344444 },
  { name: 'CH369', latitude: 55.830342, longitude: 12.735064 },
  { name: 'MIRMU', latitude: 55.831944, longitude: 35.4575 },
  { name: 'ESEBE', latitude: 55.833333, longitude: 37.394722 },
  { name: 'SOMPU', latitude: 55.834583, longitude: 20.856444 },
  { name: 'PUFIK', latitude: 55.835611, longitude: 36.74475 },
  { name: 'UGNOV', latitude: 55.838556, longitude: 37.865722 },
  { name: 'ABEGI', latitude: 55.841944, longitude: 12.788889 },
  { name: 'RIBKO', latitude: 55.843889, longitude: 33.046111 },
  { name: 'CH882', latitude: 55.846239, longitude: 12.802325 },
  { name: 'ROPSO', latitude: 55.848056, longitude: 33.377778 },
  { name: 'UDZOK', latitude: 55.848889, longitude: 39.15 },
  { name: 'AGLIV', latitude: 55.850833, longitude: 35.725833 },
  { name: 'D238O', latitude: 55.850911, longitude: 20.701064 },
  { name: 'EE245', latitude: 55.852319, longitude: 37.247444 },
  { name: 'DURTI', latitude: 55.854167, longitude: 32.939167 },
  { name: 'D215H', latitude: 55.854206, longitude: 20.940061 },
  { name: 'CH390', latitude: 55.855303, longitude: 12.885492 },
  { name: 'FAPAS', latitude: 55.856694, longitude: 38.714583 },
  { name: 'VP003', latitude: 55.8575, longitude: 14.230833 },
  { name: 'MD32R', latitude: 55.862278, longitude: 23.434306 },
  { name: 'IMIZO', latitude: 55.862333, longitude: 37.062417 },
  { name: '40DME', latitude: 55.862592, longitude: 14.077089 },
  { name: 'CH392', latitude: 55.862836, longitude: 13.037319 },
  { name: '29THR', latitude: 55.863922, longitude: 14.072869 },
  { name: 'RUGEL', latitude: 55.864722, longitude: 37.864722 },
  { name: 'PA406', latitude: 55.86475, longitude: 20.829944 },
  { name: 'MD32L', latitude: 55.866, longitude: 23.424333 },
  { name: 'IKEME', latitude: 55.866111, longitude: 36.815278 },
  { name: 'MT32L', latitude: 55.866667, longitude: 23.422167 },
  { name: 'MT32R', latitude: 55.868, longitude: 23.4255 },
  { name: 'PA454', latitude: 55.868194, longitude: 21.051639 },
  { name: 'ML32R', latitude: 55.86925, longitude: 23.424556 },
  { name: 'ROLAZ', latitude: 55.869361, longitude: 36.454028 },
  { name: 'PA108', latitude: 55.8705, longitude: 21.088722 },
  { name: 'PA402', latitude: 55.871917, longitude: 21.4 },
  { name: 'VP004', latitude: 55.871944, longitude: 13.858611 },
  { name: 'KALLE', latitude: 55.872222, longitude: 36.683889 },
  { name: 'D114J', latitude: 55.877622, longitude: 21.357858 },
  { name: 'CH371', latitude: 55.878161, longitude: 12.106708 },
  { name: 'PA206', latitude: 55.87925, longitude: 20.868833 },
  { name: 'CH370', latitude: 55.879733, longitude: 12.750642 },
  { name: 'UTTON', latitude: 55.881111, longitude: 36.462222 },
  { name: 'EVEGA', latitude: 55.881222, longitude: 23.090833 },
  { name: 'FF01', latitude: 55.881333, longitude: 21.056917 },
  { name: 'PA351', latitude: 55.881389, longitude: 21.056944 },
  { name: 'SAGVE', latitude: 55.883333, longitude: 37.464722 },
  { name: 'D181L', latitude: 55.88375, longitude: 13.182522 },
  { name: 'D189D', latitude: 55.885211, longitude: 21.057761 },
  { name: 'EE063', latitude: 55.886594, longitude: 37.344583 },
  { name: 'MAKSI', latitude: 55.89, longitude: 35.554722 },
  { name: 'EE813', latitude: 55.890278, longitude: 37.177778 },
  { name: 'URUBA', latitude: 55.891667, longitude: 23.147222 },
  { name: 'D187D', latitude: 55.891711, longitude: 21.060289 },
  { name: 'AGLAN', latitude: 55.892222, longitude: 34.355556 },
  { name: 'VESAM', latitude: 55.892778, longitude: 23.645556 },
  { name: 'PA201', latitude: 55.898222, longitude: 21.063694 },
  { name: 'MISBI', latitude: 55.898611, longitude: 12.6725 },
  { name: 'PA107', latitude: 55.900806, longitude: 21.256167 },
  { name: 'PA109', latitude: 55.901222, longitude: 20.919806 },
  { name: 'GIDLI', latitude: 55.904167, longitude: 35.0425 },
  { name: 'MS501', latitude: 55.906222, longitude: 13.565417 },
  { name: 'D149L', latitude: 55.907389, longitude: 13.370133 },
  { name: 'MD14R', latitude: 55.909333, longitude: 23.373 },
  { name: 'MT14R', latitude: 55.909667, longitude: 23.373833 },
  { name: 'KUSIP', latitude: 55.909803, longitude: 37.006889 },
  { name: 'EMBOG', latitude: 55.91, longitude: 37.574722 },
  { name: 'AKFOL', latitude: 55.912028, longitude: 37.004278 },
  { name: 'DEFAL', latitude: 55.912111, longitude: 36.757972 },
  { name: 'MT14L', latitude: 55.912333, longitude: 23.375167 },
  { name: 'PA104', latitude: 55.913639, longitude: 21.709611 },
  { name: 'DIPOP', latitude: 55.913889, longitude: 35.846667 },
  { name: 'PA401', latitude: 55.91625, longitude: 21.253944 },
  { name: 'BESTA', latitude: 55.916667, longitude: 37.741389 },
  { name: 'OGVAG', latitude: 55.916667, longitude: 37.764722 },
  { name: 'PA306', latitude: 55.919111, longitude: 20.882417 },
  { name: 'GITPO', latitude: 55.921111, longitude: 12.627778 },
  { name: 'RILUL', latitude: 55.925556, longitude: 37.841111 },
  { name: '28TAC', latitude: 55.925667, longitude: 23.349667 },
  { name: '36VOR', latitude: 55.926167, longitude: 23.350833 },
  { name: 'MS548', latitude: 55.927472, longitude: 13.383 },
  { name: 'PA405', latitude: 55.927917, longitude: 21.089806 },
  { name: 'BINPI', latitude: 55.927944, longitude: 39.301194 },
  { name: 'MD14L', latitude: 55.928278, longitude: 23.352667 },
  { name: 'ML14L', latitude: 55.928556, longitude: 23.353389 },
  { name: 'NIZUS', latitude: 55.928767, longitude: 36.985506 },
  { name: '29TAC', latitude: 55.929333, longitude: 23.354 },
  { name: 'BABSI', latitude: 55.930111, longitude: 13.199472 },
  { name: 'GISON', latitude: 55.931667, longitude: 17.701667 },
  { name: 'SVD38', latitude: 55.932742, longitude: 13.621472 },
  { name: 'TILDU', latitude: 55.934722, longitude: 20.926667 },
  { name: 'GIGIN', latitude: 55.935278, longitude: 22.665278 },
  { name: 'ODNEL', latitude: 55.935889, longitude: 36.930778 },
  { name: 'ENKOZ', latitude: 55.937389, longitude: 37.188361 },
  { name: 'CH378', latitude: 55.938614, longitude: 12.36065 },
  { name: 'LASRA', latitude: 55.939722, longitude: 36.187028 },
  { name: 'VOTDO', latitude: 55.94, longitude: 37.18825 },
  { name: '10DME', latitude: 55.94025, longitude: 21.080583 },
  { name: 'ML19', latitude: 55.940406, longitude: 14.089717 },
  { name: 'MASUS', latitude: 55.940639, longitude: 38.558972 },
  { name: 'FUKAS', latitude: 55.940889, longitude: 37.521667 },
  { name: 'PIZOT', latitude: 55.941917, longitude: 37.767028 },
  { name: 'OLKAD', latitude: 55.942222, longitude: 37.969722 },
  { name: 'D149J', latitude: 55.944011, longitude: 13.335047 },
  { name: 'D144J', latitude: 55.945072, longitude: 13.366861 },
  { name: 'PA207', latitude: 55.945389, longitude: 20.688306 },
  { name: 'CH885', latitude: 55.946111, longitude: 12.695108 },
  { name: 'D112S', latitude: 55.9472, longitude: 13.716297 },
  { name: 'EE622', latitude: 55.949506, longitude: 37.268728 },
  { name: 'EE612', latitude: 55.949667, longitude: 37.122067 },
  { name: 'FD06L', latitude: 55.951883, longitude: 37.120014 },
  { name: 'EE632', latitude: 55.9521, longitude: 37.268631 },
  { name: 'TL554', latitude: 55.956333, longitude: 13.411639 },
  { name: 'SOPUL', latitude: 55.956944, longitude: 12.338056 },
  { name: 'LAUGA', latitude: 55.958611, longitude: 26.033056 },
  { name: 'GISIN', latitude: 55.961667, longitude: 37.348333 },
  { name: 'IBKUL', latitude: 55.962778, longitude: 37.7675 },
  { name: 'MOLZI', latitude: 55.963611, longitude: 39.117639 },
  { name: '33VOR', latitude: 55.966614, longitude: 37.232636 },
  { name: 'DE24L', latitude: 55.96715, longitude: 37.386542 },
  { name: 'EE057', latitude: 55.967222, longitude: 37.650278 },
  { name: 'DE24C', latitude: 55.969808, longitude: 37.386906 },
  { name: '23DME', latitude: 55.970711, longitude: 37.261392 },
  { name: '22THR', latitude: 55.971475, longitude: 37.265906 },
  { name: 'ELMOD', latitude: 55.971667, longitude: 37.307222 },
  { name: 'EE825', latitude: 55.975278, longitude: 37.661111 },
  { name: 'SA453', latitude: 55.977389, longitude: 23.294694 },
  { name: 'FT14R', latitude: 55.977833, longitude: 23.271333 },
  { name: 'ODNAP', latitude: 55.978056, longitude: 31.918056 },
  { name: 'EE810', latitude: 55.978611, longitude: 37.69 },
  { name: 'EE824', latitude: 55.978889, longitude: 37.603056 },
  { name: 'FD14R', latitude: 55.979833, longitude: 23.275833 },
  { name: 'DE24R', latitude: 55.980969, longitude: 37.328936 },
  { name: 'LIGVA', latitude: 55.983333, longitude: 36.799167 },
  { name: 'FI14L', latitude: 55.983583, longitude: 23.287222 },
  { name: 'FD14L', latitude: 55.984, longitude: 23.280278 },
  { name: 'OVKOZ', latitude: 55.985, longitude: 21.3075 },
  { name: 'D123J', latitude: 55.985078, longitude: 13.441161 },
  { name: 'OGDAT', latitude: 55.985278, longitude: 37.759722 },
  { name: 'FT14L', latitude: 55.985333, longitude: 23.283667 },
  { name: 'SA351', latitude: 55.985694, longitude: 23.284833 },
  { name: 'FF14L', latitude: 55.985722, longitude: 23.284639 },
  { name: 'PA403', latitude: 55.985889, longitude: 21.592778 },
  { name: 'DE06L', latitude: 55.988392, longitude: 37.378464 },
  { name: 'REKMO', latitude: 55.989444, longitude: 12.79 },
  { name: 'KOGOM', latitude: 55.9925, longitude: 39.667778 },
  { name: 'MA19', latitude: 55.993111, longitude: 21.101889 },
  { name: 'ML19', latitude: 55.993139, longitude: 21.101889 },
  { name: 'EE122', latitude: 55.993283, longitude: 37.562072 },
  { name: 'DUFAR', latitude: 55.993889, longitude: 36.9025 },
  { name: 'VALSO', latitude: 55.994167, longitude: 34.338611 },
  { name: '36VOR', latitude: 55.995461, longitude: 37.430706 },
  { name: 'EE142', latitude: 55.995517, longitude: 37.559683 },
  { name: 'VP005', latitude: 55.996111, longitude: 13.886944 },
  { name: '18THR', latitude: 55.996128, longitude: 37.430067 },
  { name: 'TL553', latitude: 55.996694, longitude: 13.541306 },
  { name: 'VP006', latitude: 55.997222, longitude: 13.284167 },
  { name: 'VAPRE', latitude: 55.997556, longitude: 23.073417 },
  { name: 'CH379', latitude: 55.999514, longitude: 12.358794 },
  { name: '5613E', latitude: 56.0, longitude: 13.0 },
  { name: '5614E', latitude: 56.0, longitude: 14.0 },
  { name: '5615E', latitude: 56.0, longitude: 15.0 },
  { name: '5616E', latitude: 56.0, longitude: 16.0 },
  { name: 'KOLJA', latitude: 56.0, longitude: 16.814722 },
  { name: '5617E', latitude: 56.0, longitude: 17.0 },
  { name: '5618E', latitude: 56.0, longitude: 18.0 },
  { name: '5619E', latitude: 56.0, longitude: 19.0 },
  { name: '5620E', latitude: 56.0, longitude: 20.0 },
  { name: '5621E', latitude: 56.0, longitude: 21.0 },
  { name: '5622E', latitude: 56.0, longitude: 22.0 },
  { name: '5623E', latitude: 56.0, longitude: 23.0 },
  { name: '5624E', latitude: 56.0, longitude: 24.0 },
  { name: '5625E', latitude: 56.0, longitude: 25.0 },
  { name: '5626E', latitude: 56.0, longitude: 26.0 },
  { name: '5627E', latitude: 56.0, longitude: 27.0 },
  { name: '5628E', latitude: 56.0, longitude: 28.0 },
  { name: '5629E', latitude: 56.0, longitude: 29.0 },
  { name: '5630E', latitude: 56.0, longitude: 30.0 },
  { name: '5631E', latitude: 56.0, longitude: 31.0 },
  { name: '5632E', latitude: 56.0, longitude: 32.0 },
  { name: '5633E', latitude: 56.0, longitude: 33.0 },
  { name: '5634E', latitude: 56.0, longitude: 34.0 },
  { name: '5635E', latitude: 56.0, longitude: 35.0 },
  { name: 'AGPES', latitude: 56.0, longitude: 35.098611 },
  { name: '5636E', latitude: 56.0, longitude: 36.0 },
  { name: '5637E', latitude: 56.0, longitude: 37.0 },
  { name: '5638E', latitude: 56.0, longitude: 38.0 },
  { name: '5639E', latitude: 56.0, longitude: 39.0 },
  { name: '5640E', latitude: 56.0, longitude: 40.0 },
  { name: 'PA302', latitude: 56.001722, longitude: 21.415056 },
  { name: 'TUMUN', latitude: 56.003333, longitude: 35.557222 },
  { name: 'PANZO', latitude: 56.004667, longitude: 37.968306 },
  { name: 'PA353', latitude: 56.005, longitude: 21.106667 },
  { name: 'D007C', latitude: 56.005147, longitude: 21.107089 },
  { name: 'ZUBHA', latitude: 56.005194, longitude: 37.642667 },
  { name: 'NAROL', latitude: 56.005833, longitude: 12.225 },
  { name: 'UMLOL', latitude: 56.007417, longitude: 37.640278 },
  { name: 'D316J', latitude: 56.008153, longitude: 23.229572 },
  { name: 'VP007', latitude: 56.008611, longitude: 14.255556 },
  { name: 'DF712', latitude: 56.010222, longitude: 15.195333 },
  { name: 'EE306', latitude: 56.011111, longitude: 37.679444 },
  { name: 'DIPAT', latitude: 56.013333, longitude: 38.036667 },
  { name: 'LEZOM', latitude: 56.013519, longitude: 37.099886 },
  { name: 'PA101', latitude: 56.015528, longitude: 21.110917 },
  { name: 'TL551', latitude: 56.017111, longitude: 13.460028 },
  { name: 'FUGVU', latitude: 56.017167, longitude: 25.172917 },
  { name: 'PA307', latitude: 56.017917, longitude: 20.912917 },
  { name: 'KUZUN', latitude: 56.018889, longitude: 37.735889 },
  { name: 'VETAS', latitude: 56.019167, longitude: 25.856111 },
  { name: 'EE805', latitude: 56.019167, longitude: 37.2125 },
  { name: 'PA103', latitude: 56.01925, longitude: 21.454583 },
  { name: 'EE112', latitude: 56.019464, longitude: 37.586667 },
  { name: 'FD24R', latitude: 56.020447, longitude: 37.587789 },
  { name: 'OLZON', latitude: 56.021278, longitude: 37.734667 },
  { name: 'MS860', latitude: 56.021306, longitude: 12.962361 },
  { name: 'D007D', latitude: 56.021353, longitude: 21.113778 },
  { name: '40DME', latitude: 56.021778, longitude: 21.113444 },
  { name: 'D045F', latitude: 56.023994, longitude: 21.226061 },
  { name: 'ERPIP', latitude: 56.024444, longitude: 37.771111 },
  { name: 'SORET', latitude: 56.025472, longitude: 37.770778 },
  { name: 'DESMO', latitude: 56.025972, longitude: 37.1145 },
  { name: 'TEKBO', latitude: 56.026111, longitude: 39.634167 },
  { name: 'CI29L', latitude: 56.027014, longitude: 13.420317 },
  { name: 'EKPIR', latitude: 56.028611, longitude: 39.789722 },
  { name: 'PA407', latitude: 56.030083, longitude: 20.873361 },
  { name: 'MOGRA', latitude: 56.030833, longitude: 29.062778 },
  { name: 'MK500', latitude: 56.030889, longitude: 14.11 },
  { name: 'FF19', latitude: 56.031175, longitude: 14.110058 },
  { name: 'BUKIV', latitude: 56.031389, longitude: 20.965 },
  { name: 'CH886', latitude: 56.032767, longitude: 12.648081 },
  { name: 'MS549', latitude: 56.033583, longitude: 13.412583 },
  { name: 'URRIZ', latitude: 56.034167, longitude: 37.693611 },
  { name: 'EE705', latitude: 56.034472, longitude: 36.540833 },
  { name: 'DAZLA', latitude: 56.037167, longitude: 36.257222 },
  { name: 'D220G', latitude: 56.037253, longitude: 21.236778 },
  { name: 'D009E', latitude: 56.037558, longitude: 21.120472 },
  { name: 'FD29L', latitude: 56.038522, longitude: 13.364153 },
  { name: 'NIGBA', latitude: 56.038889, longitude: 27.821389 },
  { name: 'D227D', latitude: 56.0394, longitude: 13.111408 },
  { name: 'NENVA', latitude: 56.039722, longitude: 40.750278 },
  { name: 'GENSI', latitude: 56.040417, longitude: 37.726903 },
  { name: 'AMTOT', latitude: 56.040556, longitude: 12.180278 },
  { name: 'FF29L', latitude: 56.040575, longitude: 13.366058 },
  { name: 'TL550', latitude: 56.040806, longitude: 13.365083 },
  { name: 'TAMFI', latitude: 56.041389, longitude: 36.333333 },
  { name: 'D030F', latitude: 56.042008, longitude: 21.190272 },
  { name: 'FI19', latitude: 56.043, longitude: 21.122 },
  { name: 'CT14R', latitude: 56.043167, longitude: 23.173167 },
  { name: 'VP008', latitude: 56.043333, longitude: 13.084722 },
  { name: 'ESETU', latitude: 56.043333, longitude: 40.943889 },
  { name: 'PA451', latitude: 56.044333, longitude: 21.122556 },
  { name: 'FF19', latitude: 56.044361, longitude: 21.122556 },
  { name: 'FN19', latitude: 56.044361, longitude: 21.122583 },
  { name: 'D206F', latitude: 56.046725, longitude: 21.187956 },
  { name: 'EE058', latitude: 56.047778, longitude: 37.123889 },
  { name: 'D099J', latitude: 56.048156, longitude: 13.491817 },
  { name: 'CD14R', latitude: 56.049333, longitude: 23.1785 },
  { name: 'SA352', latitude: 56.049528, longitude: 23.207889 },
  { name: 'D322G', latitude: 56.051047, longitude: 20.972961 },
  { name: 'INRER', latitude: 56.052694, longitude: 12.813667 },
  { name: 'NAPUS', latitude: 56.053361, longitude: 21.309083 },
  { name: 'CD14L', latitude: 56.053778, longitude: 23.18925 },
  { name: 'CT14L', latitude: 56.053833, longitude: 23.197833 },
  { name: 'EE829', latitude: 56.055556, longitude: 38.384444 },
  { name: 'D057M', latitude: 56.056744, longitude: 21.42905 },
  { name: 'CF14L', latitude: 56.057333, longitude: 23.198194 },
  { name: 'D198G', latitude: 56.058286, longitude: 21.168164 },
  { name: 'TL600', latitude: 56.059056, longitude: 13.291944 },
  { name: 'D112C', latitude: 56.061208, longitude: 13.281414 },
  { name: 'MISVU', latitude: 56.062222, longitude: 23.442778 },
  { name: 'OKAPE', latitude: 56.062222, longitude: 36.335 },
  { name: 'EE130', latitude: 56.062444, longitude: 37.41875 },
  { name: 'PA102', latitude: 56.062667, longitude: 21.344528 },
  { name: 'TIRIN', latitude: 56.067278, longitude: 20.747833 },
  { name: 'CH447', latitude: 56.068419, longitude: 12.497811 },
  { name: 'PA453', latitude: 56.068972, longitude: 21.203667 },
  { name: 'OVVIM', latitude: 56.071556, longitude: 37.32975 },
  { name: 'D324M', latitude: 56.0716, longitude: 23.240875 },
  { name: 'RUCAV', latitude: 56.073889, longitude: 21.093611 },
  { name: 'EE300', latitude: 56.073944, longitude: 37.748889 },
  { name: 'EE018', latitude: 56.074722, longitude: 37.555833 },
  { name: 'MA29L', latitude: 56.075042, longitude: 13.227525 },
  { name: 'PA452', latitude: 56.076833, longitude: 21.135722 },
  { name: 'OLMUN', latitude: 56.0775, longitude: 39.550833 },
  { name: 'JANDA', latitude: 56.077833, longitude: 13.508583 },
  { name: 'EE803', latitude: 56.078611, longitude: 37.245278 },
  { name: 'IRDEK', latitude: 56.079167, longitude: 37.051944 },
  { name: 'DF550', latitude: 56.079889, longitude: 15.364611 },
  { name: 'BOMGI', latitude: 56.081389, longitude: 31.001111 },
  { name: 'D034J', latitude: 56.082222, longitude: 21.261111 },
  { name: 'CF19', latitude: 56.082336, longitude: 14.121567 },
  { name: 'EE242', latitude: 56.086028, longitude: 36.847472 },
  { name: 'NUBDO', latitude: 56.0875, longitude: 38.322222 },
  { name: 'TL902', latitude: 56.087528, longitude: 12.881556 },
  { name: 'RIZNO', latitude: 56.088694, longitude: 36.512722 },
  { name: 'D350L', latitude: 56.090044, longitude: 23.396156 },
  { name: 'RONUS', latitude: 56.090278, longitude: 20.495556 },
  { name: 'SUXEM', latitude: 56.092444, longitude: 15.217583 },
  { name: 'ESONU', latitude: 56.0925, longitude: 31.6025 },
  { name: 'OBUKI', latitude: 56.092778, longitude: 36.057778 },
  { name: 'ARKUD', latitude: 56.093194, longitude: 37.778389 },
  { name: 'PASAU', latitude: 56.093333, longitude: 37.778333 },
  { name: 'ROSUB', latitude: 56.093889, longitude: 35.261667 },
  { name: 'D348M', latitude: 56.094994, longitude: 23.396142 },
  { name: 'AMSUR', latitude: 56.100556, longitude: 12.563889 },
  { name: 'D288C', latitude: 56.101775, longitude: 13.118467 },
  { name: 'EE700', latitude: 56.102453, longitude: 37.188036 },
  { name: 'MK530', latitude: 56.102917, longitude: 14.276194 },
  { name: 'DF711', latitude: 56.104833, longitude: 15.070444 },
  { name: 'NOVPO', latitude: 56.106667, longitude: 12.241111 },
  { name: 'TL850', latitude: 56.106667, longitude: 13.099556 },
  { name: 'UPMOT', latitude: 56.107222, longitude: 37.032778 },
  { name: 'PA105', latitude: 56.10775, longitude: 22.076639 },
  { name: 'UPLAX', latitude: 56.109667, longitude: 12.959833 },
  { name: 'LASGI', latitude: 56.113333, longitude: 12.454444 },
  { name: 'MK510', latitude: 56.113417, longitude: 14.128556 },
  { name: 'EXENA', latitude: 56.118889, longitude: 13.14975 },
  { name: 'TIRDE', latitude: 56.118889, longitude: 37.048389 },
  { name: 'FD11R', latitude: 56.120383, longitude: 13.035603 },
  { name: 'ASTOS', latitude: 56.120472, longitude: 12.96125 },
  { name: 'ADVOP', latitude: 56.120556, longitude: 12.117222 },
  { name: 'VP009', latitude: 56.120833, longitude: 15.345833 },
  { name: 'RIRLA', latitude: 56.120833, longitude: 31.798056 },
  { name: 'MK840', latitude: 56.123722, longitude: 13.980833 },
  { name: 'TL900', latitude: 56.125028, longitude: 13.024917 },
  { name: 'VP010', latitude: 56.125833, longitude: 13.306667 },
  { name: 'RATKE', latitude: 56.126417, longitude: 37.619889 },
  { name: 'KEMAX', latitude: 56.126444, longitude: 13.453833 },
  { name: 'EE502', latitude: 56.126944, longitude: 36.57925 },
  { name: 'IBUGA', latitude: 56.128583, longitude: 23.412611 },
  { name: 'RIMDE', latitude: 56.129306, longitude: 37.6845 },
  { name: 'SUDER', latitude: 56.129722, longitude: 30.556111 },
  { name: 'SUBOR', latitude: 56.130278, longitude: 22.347222 },
  { name: 'KOTAM', latitude: 56.132778, longitude: 14.836667 },
  { name: 'CF01', latitude: 56.133042, longitude: 15.228558 },
  { name: 'KOPIM', latitude: 56.133889, longitude: 12.498333 },
  { name: 'VEDBY', latitude: 56.137778, longitude: 13.189722 },
  { name: 'LISGO', latitude: 56.138889, longitude: 25.571389 },
  { name: 'D057X', latitude: 56.140039, longitude: 21.717164 },
  { name: 'D064I', latitude: 56.140714, longitude: 13.433839 },
  { name: 'SIFOD', latitude: 56.142056, longitude: 37.870667 },
  { name: 'D002M', latitude: 56.143425, longitude: 14.134658 },
  { name: 'EVBAS', latitude: 56.145556, longitude: 12.477778 },
  { name: 'GARSO', latitude: 56.146111, longitude: 20.082222 },
  { name: 'TL901', latitude: 56.148444, longitude: 12.929444 },
  { name: 'D289J', latitude: 56.148464, longitude: 12.909681 },
  { name: 'SUNUN', latitude: 56.149167, longitude: 36.124444 },
  { name: 'D292K', latitude: 56.156447, longitude: 12.917953 },
  { name: 'KEZVU', latitude: 56.16, longitude: 37.337778 },
  { name: 'D092I', latitude: 56.160711, longitude: 12.841669 },
  { name: 'SONUN', latitude: 56.163056, longitude: 30.2425 },
  { name: 'GOKEN', latitude: 56.164556, longitude: 13.071556 },
  { name: 'RIBGE', latitude: 56.165278, longitude: 36.143889 },
  { name: 'FQ01Z', latitude: 56.165461, longitude: 15.239697 },
  { name: 'DF713', latitude: 56.165583, longitude: 15.237472 },
  { name: 'FF01', latitude: 56.165928, longitude: 15.237558 },
  { name: 'AMDRO', latitude: 56.166667, longitude: 36.35 },
  { name: 'TA605', latitude: 56.167083, longitude: 13.067583 },
  { name: 'TL903', latitude: 56.168444, longitude: 12.847528 },
  { name: 'ERNOV', latitude: 56.168861, longitude: 12.573778 },
  { name: 'LUNIT', latitude: 56.175278, longitude: 25.158611 },
  { name: 'D301J', latitude: 56.175847, longitude: 12.9539 },
  { name: 'LEPVA', latitude: 56.180278, longitude: 21.091389 },
  { name: 'DIPEB', latitude: 56.1825, longitude: 17.976389 },
  { name: 'D120I', latitude: 56.1848, longitude: 13.133422 },
  { name: 'EE701', latitude: 56.18575, longitude: 37.038722 },
  { name: 'ETPIG', latitude: 56.1875, longitude: 14.215 },
  { name: 'DUBBO', latitude: 56.189167, longitude: 37.373333 },
  { name: 'VP011', latitude: 56.191111, longitude: 12.859167 },
  { name: 'MK520', latitude: 56.195917, longitude: 14.147222 },
  { name: 'EE078', latitude: 56.197444, longitude: 37.587194 },
  { name: 'VP012', latitude: 56.198889, longitude: 15.412222 },
  { name: 'OKNIG', latitude: 56.2, longitude: 36.458333 },
  { name: 'D329H', latitude: 56.200522, longitude: 13.089044 },
  { name: 'RAVKA', latitude: 56.207778, longitude: 35.905833 },
  { name: 'TL904', latitude: 56.209333, longitude: 12.9775 },
  { name: '30LOC', latitude: 56.210319, longitude: 15.249733 },
  { name: 'KEDUX', latitude: 56.212778, longitude: 19.573333 },
  { name: 'TA604', latitude: 56.213333, longitude: 12.988611 },
  { name: 'D046L', latitude: 56.213736, longitude: 13.471431 },
  { name: 'XARIN', latitude: 56.215806, longitude: 21.982389 },
  { name: 'FQ32', latitude: 56.216553, longitude: 12.992883 },
  { name: 'VP013', latitude: 56.216667, longitude: 15.099444 },
  { name: 'BEGEZ', latitude: 56.220278, longitude: 36.622778 },
  { name: 'D046M', latitude: 56.224597, longitude: 13.494039 },
  { name: 'DOKOT', latitude: 56.225833, longitude: 21.362778 },
  { name: 'NINTA', latitude: 56.228889, longitude: 18.285556 },
  { name: 'EE822', latitude: 56.230833, longitude: 37.523889 },
  { name: 'D315L', latitude: 56.231181, longitude: 12.961333 },
  { name: 'VP014', latitude: 56.2325, longitude: 12.741111 },
  { name: 'TA603', latitude: 56.235167, longitude: 13.19775 },
  { name: 'DITVA', latitude: 56.235861, longitude: 13.197306 },
  { name: 'D054J', latitude: 56.236231, longitude: 13.453092 },
  { name: '40THR', latitude: 56.236972, longitude: 12.948292 },
  { name: 'IBDAD', latitude: 56.2375, longitude: 37.331389 },
  { name: 'VP015', latitude: 56.239167, longitude: 15.320556 },
  { name: 'ML01', latitude: 56.246492, longitude: 15.259675 },
  { name: 'REMPU', latitude: 56.249167, longitude: 34.995 },
  { name: 'VAGVO', latitude: 56.249722, longitude: 19.285 },
  { name: 'ABRUM', latitude: 56.250278, longitude: 20.716667 },
  { name: 'EE826', latitude: 56.250556, longitude: 37.702222 },
  { name: 'TAFAZ', latitude: 56.252222, longitude: 37.291944 },
  { name: 'EE815', latitude: 56.26, longitude: 36.475278 },
  { name: 'KULUD', latitude: 56.260556, longitude: 12.333056 },
  { name: 'NARIB', latitude: 56.263611, longitude: 40.599444 },
  { name: 'VP016', latitude: 56.264167, longitude: 15.195278 },
  { name: 'AGMUT', latitude: 56.279444, longitude: 29.0725 },
  { name: 'EE801', latitude: 56.279444, longitude: 37.891389 },
  { name: 'URIDO', latitude: 56.280278, longitude: 24.516389 },
  { name: 'IPSES', latitude: 56.283333, longitude: 38.778333 },
  { name: 'RW32', latitude: 56.285167, longitude: 12.865881 },
  { name: 'ML19', latitude: 56.286914, longitude: 15.270786 },
  { name: 'AMDOR', latitude: 56.289444, longitude: 31.043889 },
  { name: 'LONAF', latitude: 56.294639, longitude: 36.707028 },
  { name: 'FAMLA', latitude: 56.296361, longitude: 37.117944 },
  { name: 'ADAXA', latitude: 56.297778, longitude: 18.898333 },
  { name: 'ROLAV', latitude: 56.298889, longitude: 24.031667 },
  { name: 'TA505', latitude: 56.301694, longitude: 13.196278 },
  { name: 'ML14', latitude: 56.305331, longitude: 12.831314 },
  { name: 'SORUK', latitude: 56.307056, longitude: 36.771667 },
  { name: 'VP017', latitude: 56.3125, longitude: 13.078056 },
  { name: 'ROKAM', latitude: 56.316944, longitude: 12.183333 },
  { name: 'LUNZA', latitude: 56.318806, longitude: 36.866611 },
  { name: 'INLAL', latitude: 56.319167, longitude: 34.499167 },
  { name: 'TVING', latitude: 56.321944, longitude: 15.444444 },
  { name: 'EE101', latitude: 56.322361, longitude: 37.478861 },
  { name: 'EKLIN', latitude: 56.325, longitude: 21.608056 },
  { name: '33DME', latitude: 56.328181, longitude: 15.282158 },
  { name: 'TESPO', latitude: 56.337778, longitude: 17.228611 },
  { name: 'NEXIL', latitude: 56.339139, longitude: 13.733111 },
  { name: 'PUDTU', latitude: 56.341667, longitude: 36.360556 },
  { name: 'VP018', latitude: 56.344444, longitude: 15.119444 },
  { name: 'ODSOD', latitude: 56.357222, longitude: 35.1225 },
  { name: 'IGORO', latitude: 56.36, longitude: 28.2 },
  { name: 'VAPUB', latitude: 56.360417, longitude: 12.529 },
  { name: 'TOKNU', latitude: 56.361667, longitude: 36.383333 },
  { name: 'TA801', latitude: 56.362806, longitude: 12.733028 },
  { name: 'BERIL', latitude: 56.363056, longitude: 23.426389 },
  { name: 'ERIVA', latitude: 56.368889, longitude: 24.630278 },
  { name: 'FI14', latitude: 56.369078, longitude: 12.7217 },
  { name: 'TA853', latitude: 56.369222, longitude: 12.721444 },
  { name: 'IRBEX', latitude: 56.369444, longitude: 24.958611 },
  { name: 'IDEXO', latitude: 56.369778, longitude: 12.9685 },
  { name: 'GUNTA', latitude: 56.371389, longitude: 23.755556 },
  { name: 'FQ14', latitude: 56.371428, longitude: 12.725992 },
  { name: 'LITPA', latitude: 56.374722, longitude: 21.944167 },
  { name: 'EE043', latitude: 56.375556, longitude: 37.551389 },
  { name: 'FI19', latitude: 56.377503, longitude: 15.295783 },
  { name: 'DF533', latitude: 56.378944, longitude: 15.296194 },
  { name: 'MADAG', latitude: 56.380556, longitude: 12.013611 },
  { name: 'ASKOR', latitude: 56.382778, longitude: 22.612778 },
  { name: 'IBREK', latitude: 56.391667, longitude: 12.232222 },
  { name: 'ORZIM', latitude: 56.392689, longitude: 37.906231 },
  { name: 'EE045', latitude: 56.392778, longitude: 37.058889 },
  { name: 'CI14', latitude: 56.393108, longitude: 12.680242 },
  { name: 'KOMNI', latitude: 56.393806, longitude: 37.402139 },
  { name: 'EE051', latitude: 56.394167, longitude: 37.590278 },
  { name: 'MOZEN', latitude: 56.408028, longitude: 36.991333 },
  { name: 'EZERI', latitude: 56.408611, longitude: 20.992222 },
  { name: 'CI19', latitude: 56.410381, longitude: 15.304886 },
  { name: 'EE048', latitude: 56.411389, longitude: 37.028889 },
  { name: 'ETNIB', latitude: 56.417778, longitude: 39.013611 },
  { name: 'PELAB', latitude: 56.418028, longitude: 12.637111 },
  { name: 'KARUP', latitude: 56.4225, longitude: 12.955278 },
  { name: 'MIRMA', latitude: 56.4225, longitude: 22.161944 },
  { name: 'ATRIB', latitude: 56.423333, longitude: 12.513333 },
  { name: 'TILTI', latitude: 56.425556, longitude: 21.2225 },
  { name: 'VP019', latitude: 56.4275, longitude: 12.614167 },
  { name: 'DF531', latitude: 56.429444, longitude: 15.461972 },
  { name: 'EE044', latitude: 56.430833, longitude: 37.400278 },
  { name: 'TA504', latitude: 56.432333, longitude: 13.193361 },
  { name: 'LASMA', latitude: 56.435278, longitude: 20.215278 },
  { name: 'SAFRI', latitude: 56.435722, longitude: 37.652417 },
  { name: 'AGMER', latitude: 56.435944, longitude: 37.218639 },
  { name: 'EKLOV', latitude: 56.442111, longitude: 15.313611 },
  { name: 'BAPEG', latitude: 56.443056, longitude: 38.978056 },
  { name: 'RA433', latitude: 56.444406, longitude: 22.871472 },
  { name: 'RA627', latitude: 56.449086, longitude: 24.546306 },
  { name: 'D012G', latitude: 56.449747, longitude: 15.342969 },
  { name: 'D331G', latitude: 56.450294, longitude: 15.194133 },
  { name: 'ABVUR', latitude: 56.450806, longitude: 12.773028 },
  { name: 'STAPA', latitude: 56.453333, longitude: 34.968333 },
  { name: 'DF850', latitude: 56.454611, longitude: 15.165167 },
  { name: 'RA723', latitude: 56.454978, longitude: 24.609833 },
  { name: 'EE050', latitude: 56.459167, longitude: 37.419722 },
  { name: 'IDPAL', latitude: 56.460556, longitude: 14.311389 },
  { name: 'ABREX', latitude: 56.460556, longitude: 20.490833 },
  { name: 'INLEN', latitude: 56.460556, longitude: 40.637222 },
  { name: 'LOZZA', latitude: 56.463611, longitude: 40.148611 },
  { name: 'PIRAG', latitude: 56.464444, longitude: 21.277222 },
  { name: 'EE049', latitude: 56.464722, longitude: 37.210556 },
  { name: 'TEMGU', latitude: 56.466944, longitude: 31.503056 },
  { name: 'RA629', latitude: 56.473211, longitude: 24.195889 },
  { name: 'GOPNI', latitude: 56.474417, longitude: 16.488694 },
  { name: 'GOKPU', latitude: 56.475556, longitude: 12.745556 },
  { name: 'IBEBI', latitude: 56.478, longitude: 12.533083 },
  { name: 'TUSAS', latitude: 56.479444, longitude: 25.171667 },
  { name: 'ALMIK', latitude: 56.483333, longitude: 26.642222 },
  { name: 'VARUS', latitude: 56.485278, longitude: 19.907222 },
  { name: 'MT552', latitude: 56.491389, longitude: 12.932167 },
  { name: 'OTVEB', latitude: 56.491667, longitude: 14.269444 },
  { name: 'D244D', latitude: 56.491683, longitude: 20.980214 },
  { name: 'FD06', latitude: 56.49275, longitude: 20.950083 },
  { name: 'MT580', latitude: 56.493944, longitude: 12.890194 },
  { name: 'RA422', latitude: 56.494069, longitude: 24.619278 },
  { name: 'DIXOT', latitude: 56.494528, longitude: 16.299694 },
  { name: 'RA554', latitude: 56.499778, longitude: 22.826472 },
  { name: 'MT800', latitude: 56.500417, longitude: 12.782722 },
  { name: 'D187I', latitude: 56.503292, longitude: 12.755936 },
  { name: '21VOR', latitude: 56.503694, longitude: 21.023639 },
  { name: 'MT803', latitude: 56.509278, longitude: 12.633194 },
  { name: 'IBKIS', latitude: 56.51, longitude: 36.241667 },
  { name: 'FIGAL', latitude: 56.516472, longitude: 37.1845 },
  { name: 'POGET', latitude: 56.516917, longitude: 37.509889 },
  { name: 'D004P', latitude: 56.517331, longitude: 15.337106 },
  { name: 'LUKED', latitude: 56.518611, longitude: 20.093056 },
  { name: 'DF532', latitude: 56.524306, longitude: 15.336389 },
  { name: 'RA534', latitude: 56.524678, longitude: 23.250361 },
  { name: 'RA720', latitude: 56.525233, longitude: 24.742556 },
  { name: 'D157J', latitude: 56.526247, longitude: 16.372094 },
  { name: 'RA536', latitude: 56.528389, longitude: 22.733139 },
  { name: 'RA730', latitude: 56.528856, longitude: 23.836222 },
  { name: 'VESJA', latitude: 56.529589, longitude: 24.949303 },
  { name: 'VEDAR', latitude: 56.531667, longitude: 12.123611 },
  { name: 'VEDEN', latitude: 56.531667, longitude: 18.876667 },
  { name: 'SULAX', latitude: 56.533306, longitude: 16.43275 },
  { name: '33LOC', latitude: 56.533389, longitude: 21.177667 },
  { name: '34VOR', latitude: 56.5345, longitude: 21.177472 },
  { name: 'SUBOD', latitude: 56.535806, longitude: 37.287778 },
  { name: 'CN01', latitude: 56.53595, longitude: 12.767378 },
  { name: 'AMRIT', latitude: 56.536111, longitude: 21.685 },
  { name: 'RA630', latitude: 56.536392, longitude: 23.468583 },
  { name: 'GISPU', latitude: 56.5398, longitude: 24.799744 },
  { name: 'RA721', latitude: 56.540061, longitude: 24.589528 },
  { name: 'D064E', latitude: 56.541139, longitude: 21.226197 },
  { name: 'TA506', latitude: 56.542278, longitude: 13.069361 },
  { name: 'RA624', latitude: 56.549139, longitude: 24.737861 },
  { name: 'IROHE', latitude: 56.549931, longitude: 24.650525 },
  { name: 'RA722', latitude: 56.552439, longitude: 24.471361 },
  { name: 'RA421', latitude: 56.553728, longitude: 25.015 },
  { name: 'LAGIS', latitude: 56.554944, longitude: 15.937 },
  { name: 'FI24', latitude: 56.562194, longitude: 21.32475 },
  { name: 'FOZDI', latitude: 56.562225, longitude: 24.365361 },
  { name: 'RA432', latitude: 56.562881, longitude: 23.367889 },
  { name: 'FF24', latitude: 56.563083, longitude: 21.32925 },
  { name: 'D131J', latitude: 56.563525, longitude: 16.489547 },
  { name: 'D230M', latitude: 56.563617, longitude: 15.959933 },
  { name: 'GOBEK', latitude: 56.564167, longitude: 22.531944 },
  { name: 'ROXEN', latitude: 56.564444, longitude: 14.033333 },
  { name: 'RA628', latitude: 56.567672, longitude: 24.453417 },
  { name: 'FD24', latitude: 56.569278, longitude: 21.326833 },
  { name: 'RA636', latitude: 56.571378, longitude: 22.971083 },
  { name: 'TESVI', latitude: 56.571944, longitude: 16.566083 },
  { name: 'D230L', latitude: 56.573008, longitude: 15.984794 },
  { name: 'CI24Z', latitude: 56.573278, longitude: 21.381528 },
  { name: 'LOPLO', latitude: 56.574833, longitude: 37.661028 },
  { name: 'GAMVI', latitude: 56.575, longitude: 13.566028 },
  { name: 'SAKTA', latitude: 56.575556, longitude: 25.267222 },
  { name: 'USIDA', latitude: 56.576667, longitude: 35.410833 },
  { name: 'KOMAL', latitude: 56.5775, longitude: 23.093333 },
  { name: 'SOKRO', latitude: 56.581111, longitude: 24.809722 },
  { name: 'CI24Y', latitude: 56.581528, longitude: 21.424111 },
  { name: 'MT801', latitude: 56.583083, longitude: 12.798917 },
  { name: 'RA527', latitude: 56.583303, longitude: 24.8655 },
  { name: 'BEKIP', latitude: 56.583333, longitude: 36.652222 },
  { name: 'MADDE', latitude: 56.584833, longitude: 15.884472 },
  { name: 'CL24', latitude: 56.590722, longitude: 21.471417 },
  { name: 'MQ550', latitude: 56.591111, longitude: 16.37775 },
  { name: 'ATRAK', latitude: 56.591111, longitude: 23.843056 },
  { name: 'FD34', latitude: 56.591669, longitude: 16.382083 },
  { name: 'FN01', latitude: 56.591792, longitude: 12.786989 },
  { name: 'RA631', latitude: 56.599222, longitude: 23.344833 },
  { name: 'UKINE', latitude: 56.6, longitude: 35.322778 },
  { name: 'CD24Y', latitude: 56.601417, longitude: 21.465861 },
  { name: 'SORES', latitude: 56.601944, longitude: 24.824722 },
  { name: 'ROKSO', latitude: 56.602222, longitude: 21.665 },
  { name: 'RA434', latitude: 56.604497, longitude: 23.173194 },
  { name: 'RA625', latitude: 56.606222, longitude: 24.527833 },
  { name: 'VP020', latitude: 56.606944, longitude: 12.920556 },
  { name: 'RA431', latitude: 56.613106, longitude: 23.477667 },
  { name: 'MEDZE', latitude: 56.619722, longitude: 21.180556 },
  { name: 'GA451', latitude: 56.624658, longitude: 24.818986 },
  { name: 'VP021', latitude: 56.625833, longitude: 16.170278 },
  { name: 'D144D', latitude: 56.627703, longitude: 16.344458 },
  { name: 'GORNA', latitude: 56.628583, longitude: 37.835028 },
  { name: 'LEMRO', latitude: 56.628611, longitude: 31.109722 },
  { name: 'SKEDE', latitude: 56.629167, longitude: 21.045833 },
  { name: 'EMKAS', latitude: 56.638611, longitude: 16.146944 },
  { name: 'D151C', latitude: 56.639397, longitude: 16.319492 },
  { name: 'XIRTO', latitude: 56.639694, longitude: 12.803806 },
  { name: 'MISMA', latitude: 56.64125, longitude: 13.202806 },
  { name: 'MT660', latitude: 56.641611, longitude: 12.827361 },
  { name: 'RA626', latitude: 56.643942, longitude: 24.393333 },
  { name: 'AMLIN', latitude: 56.644444, longitude: 35.655278 },
  { name: 'MT802', latitude: 56.64475, longitude: 12.811056 },
  { name: 'IRBEV', latitude: 56.652528, longitude: 12.808389 },
  { name: 'ERAXA', latitude: 56.656389, longitude: 24.978611 },
  { name: '20VOR', latitude: 56.656522, longitude: 16.314306 },
  { name: 'RA708', latitude: 56.656897, longitude: 24.554639 },
  { name: 'SOSEB', latitude: 56.662222, longitude: 34.144722 },
  { name: 'RASOX', latitude: 56.663889, longitude: 24.66 },
  { name: 'RA420', latitude: 56.664092, longitude: 24.696361 },
  { name: 'UTOME', latitude: 56.666667, longitude: 36.758333 },
  { name: 'TAZOL', latitude: 56.669194, longitude: 37.388694 },
  { name: 'VASAB', latitude: 56.674167, longitude: 21.611944 },
  { name: 'MX752', latitude: 56.675361, longitude: 14.621111 },
  { name: 'BREZO', latitude: 56.675556, longitude: 24.628889 },
  { name: 'ABAVA', latitude: 56.676389, longitude: 26.543333 },
  { name: 'MT700', latitude: 56.676528, longitude: 12.817333 },
  { name: 'TEMLI', latitude: 56.678194, longitude: 15.383806 },
  { name: 'ATIBE', latitude: 56.678483, longitude: 24.126094 },
  { name: 'D230Y', latitude: 56.679219, longitude: 23.348492 },
  { name: 'GEMDI', latitude: 56.680833, longitude: 35.79 },
  { name: 'RW01', latitude: 56.681003, longitude: 12.818208 },
  { name: 'GA404', latitude: 56.681886, longitude: 24.831889 },
  { name: 'MT551', latitude: 56.682528, longitude: 12.968056 },
  { name: 'VP022', latitude: 56.685833, longitude: 16.450278 },
  { name: 'VP023', latitude: 56.686944, longitude: 16.121111 },
  { name: 'ADOKU', latitude: 56.688889, longitude: 36.496111 },
  { name: 'OSGAK', latitude: 56.691833, longitude: 37.947 },
  { name: 'RA524', latitude: 56.693583, longitude: 24.93375 },
  { name: 'RA743', latitude: 56.695603, longitude: 23.426583 },
  { name: 'RW19', latitude: 56.701192, longitude: 12.822197 },
  { name: 'RA535', latitude: 56.702022, longitude: 23.069028 },
  { name: 'SAULE', latitude: 56.703611, longitude: 25.126667 },
  { name: 'RA526', latitude: 56.705114, longitude: 24.531722 },
  { name: 'MM16', latitude: 56.705167, longitude: 16.268667 },
  { name: 'IRDAM', latitude: 56.705278, longitude: 39.626944 },
  { name: 'PEVEK', latitude: 56.70825, longitude: 23.813917 },
  { name: 'FILZI', latitude: 56.709972, longitude: 37.67625 },
  { name: 'NAFHO', latitude: 56.710911, longitude: 24.118483 },
  { name: 'BOZAK', latitude: 56.715667, longitude: 37.577639 },
  { name: 'ALLUR', latitude: 56.716667, longitude: 37.358333 },
  { name: 'NAMIN', latitude: 56.718333, longitude: 36.821111 },
  { name: 'VALED', latitude: 56.719722, longitude: 22.461944 },
  { name: 'SAFNE', latitude: 56.719961, longitude: 23.937767 },
  { name: 'MX565', latitude: 56.721417, longitude: 14.795639 },
  { name: 'CF36', latitude: 56.722611, longitude: 23.958889 },
  { name: 'ABURE', latitude: 56.723253, longitude: 23.254139 },
  { name: 'MIHVE', latitude: 56.72875, longitude: 23.756964 },
  { name: 'NILEN', latitude: 56.728972, longitude: 13.321833 },
  { name: 'D195L', latitude: 56.732656, longitude: 23.826908 },
  { name: 'LALUD', latitude: 56.738333, longitude: 36.001667 },
  { name: 'DIMGA', latitude: 56.740194, longitude: 14.648278 },
  { name: '30THR', latitude: 56.740936, longitude: 16.234278 },
  { name: 'VP024', latitude: 56.741111, longitude: 12.97 },
  { name: 'TONTI', latitude: 56.741111, longitude: 24.184444 },
  { name: 'MT502', latitude: 56.742889, longitude: 12.830444 },
  { name: '26THR', latitude: 56.744206, longitude: 12.830711 },
  { name: 'VP025', latitude: 56.744444, longitude: 12.656944 },
  { name: 'D328D', latitude: 56.745114, longitude: 16.231036 },
  { name: 'MT440', latitude: 56.746444, longitude: 13.068056 },
  { name: 'OM16', latitude: 56.747444, longitude: 16.227722 },
  { name: 'MQ900', latitude: 56.7475, longitude: 16.228139 },
  { name: 'RA555', latitude: 56.749008, longitude: 22.833667 },
  { name: 'MT420', latitude: 56.750722, longitude: 12.832 },
  { name: 'OPOKA', latitude: 56.751667, longitude: 27.901667 },
  { name: 'MT460', latitude: 56.753389, longitude: 13.147333 },
  { name: 'ARBIS', latitude: 56.753611, longitude: 20.668333 },
  { name: 'D188K', latitude: 56.757256, longitude: 14.655614 },
  { name: 'RASMU', latitude: 56.758389, longitude: 13.815278 },
  { name: 'MX751', latitude: 56.758778, longitude: 14.50075 },
  { name: 'D285M', latitude: 56.762386, longitude: 15.916667 },
  { name: 'DEKIK', latitude: 56.764444, longitude: 14.307778 },
  { name: 'VP026', latitude: 56.766389, longitude: 16.361389 },
  { name: 'D202K', latitude: 56.766872, longitude: 23.808578 },
  { name: 'TILSA', latitude: 56.771694, longitude: 15.871833 },
  { name: 'RA430', latitude: 56.771697, longitude: 23.7765 },
  { name: 'RA707', latitude: 56.779906, longitude: 24.719861 },
  { name: 'RUMAP', latitude: 56.78, longitude: 31.139444 },
  { name: 'DITBI', latitude: 56.780278, longitude: 29.084722 },
  { name: 'MT421', latitude: 56.7815, longitude: 12.993361 },
  { name: 'RA533', latitude: 56.7831, longitude: 23.220972 },
  { name: 'MT441', latitude: 56.783806, longitude: 13.145528 },
  { name: 'MQ854', latitude: 56.786083, longitude: 16.190806 },
  { name: 'FI16Z', latitude: 56.786117, longitude: 16.190811 },
  { name: 'RA402', latitude: 56.7874, longitude: 24.340722 },
  { name: 'CF01', latitude: 56.789656, longitude: 14.669092 },
  { name: 'OTRAM', latitude: 56.791389, longitude: 30.248611 },
  { name: 'KUGEK', latitude: 56.793056, longitude: 30.704444 },
  { name: 'ETPUP', latitude: 56.796972, longitude: 12.842222 },
  { name: 'OM19', latitude: 56.796972, longitude: 12.84225 },
  { name: 'RA401', latitude: 56.800556, longitude: 24.095667 },
  { name: 'MA18', latitude: 56.800833, longitude: 24.858833 },
  { name: 'FD36', latitude: 56.805806, longitude: 23.961528 },
  { name: 'RA905', latitude: 56.806853, longitude: 23.951992 },
  { name: 'FF36', latitude: 56.806861, longitude: 23.952 },
  { name: 'FI36', latitude: 56.808611, longitude: 23.952278 },
  { name: 'RA632', latitude: 56.810336, longitude: 23.38475 },
  { name: 'KEKAV', latitude: 56.811667, longitude: 24.161111 },
  { name: 'RA913', latitude: 56.812189, longitude: 24.031528 },
  { name: 'RIRKE', latitude: 56.813611, longitude: 35.1 },
  { name: 'NETME', latitude: 56.814303, longitude: 24.148528 },
  { name: 'CF16', latitude: 56.815514, longitude: 16.162447 },
  { name: 'RA912', latitude: 56.817833, longitude: 23.877842 },
  { name: 'OSKAD', latitude: 56.818889, longitude: 36.301667 },
  { name: 'BETAL', latitude: 56.819167, longitude: 26.469444 },
  { name: 'NENEL', latitude: 56.820278, longitude: 39.901944 },
  { name: 'MQ852', latitude: 56.8205, longitude: 15.985806 },
  { name: 'ESOLO', latitude: 56.820833, longitude: 37.052778 },
  { name: 'MX750', latitude: 56.821222, longitude: 14.682361 },
  { name: 'AMURA', latitude: 56.821944, longitude: 23.710833 },
  { name: 'NERAP', latitude: 56.825, longitude: 35.757778 },
  { name: 'SIDRE', latitude: 56.8253, longitude: 23.772417 },
  { name: 'DOLUN', latitude: 56.826667, longitude: 27.468056 },
  { name: 'MT422', latitude: 56.827833, longitude: 13.142917 },
  { name: 'LOKNU', latitude: 56.828056, longitude: 30.152778 },
  { name: 'MT501', latitude: 56.829139, longitude: 12.847583 },
  { name: 'D316K', latitude: 56.829328, longitude: 16.078667 },
  { name: 'CI19', latitude: 56.830164, longitude: 12.847725 },
  { name: 'MX800', latitude: 56.833028, longitude: 14.687333 },
  { name: 'DEMAX', latitude: 56.834722, longitude: 24.200278 },
  { name: 'D035L', latitude: 56.835164, longitude: 16.524147 },
  { name: 'RA737', latitude: 56.838931, longitude: 23.545667 },
  { name: 'RA706', latitude: 56.838956, longitude: 24.616806 },
  { name: 'BEXAM', latitude: 56.843861, longitude: 16.134972 },
  { name: 'VP027', latitude: 56.845278, longitude: 14.830278 },
  { name: 'D035M', latitude: 56.847628, longitude: 16.544236 },
  { name: 'RA440', latitude: 56.849006, longitude: 23.562 },
  { name: 'RA400', latitude: 56.850133, longitude: 23.959092 },
  { name: 'RA921', latitude: 56.850419, longitude: 24.029236 },
  { name: 'EFUNE', latitude: 56.852183, longitude: 24.153528 },
  { name: 'KROGS', latitude: 56.852778, longitude: 24.177778 },
  { name: 'RA911', latitude: 56.853867, longitude: 23.959708 },
  { name: 'BARVA', latitude: 56.853889, longitude: 25.546667 },
  { name: 'RA920', latitude: 56.857422, longitude: 23.887139 },
  { name: 'MQ850', latitude: 56.859556, longitude: 16.119778 },
  { name: 'D337K', latitude: 56.860247, longitude: 16.186467 },
  { name: 'GELDA', latitude: 56.871389, longitude: 19.566667 },
  { name: 'RILPO', latitude: 56.872222, longitude: 38.270556 },
  { name: 'RA525', latitude: 56.874792, longitude: 24.368722 },
  { name: 'GA454', latitude: 56.876858, longitude: 24.876139 },
  { name: 'JAMAR', latitude: 56.877778, longitude: 23.953889 },
  { name: 'AXUVA', latitude: 56.878056, longitude: 25.029167 },
  { name: 'RA532', latitude: 56.881808, longitude: 23.473667 },
  { name: 'RA523', latitude: 56.882583, longitude: 24.472861 },
  { name: 'LATVI', latitude: 56.883611, longitude: 16.602333 },
  { name: 'RA704', latitude: 56.886875, longitude: 24.135806 },
  { name: 'VP028', latitude: 56.887778, longitude: 14.572222 },
  { name: 'IDLIT', latitude: 56.890833, longitude: 36.5725 },
  { name: 'KAZAR', latitude: 56.891833, longitude: 37.692444 },
  { name: 'OMABA', latitude: 56.8925, longitude: 24.691111 },
  { name: 'NOJAN', latitude: 56.893611, longitude: 25.170556 },
  { name: 'KAFEE', latitude: 56.893889, longitude: 24.671389 },
  { name: 'DESON', latitude: 56.894722, longitude: 20.420278 },
  { name: 'KEGUM', latitude: 56.895, longitude: 24.6325 },
  { name: 'ML36', latitude: 56.898167, longitude: 23.967 },
  { name: 'MQ853', latitude: 56.898472, longitude: 16.254 },
  { name: 'RA742', latitude: 56.898908, longitude: 23.772806 },
  { name: 'D358M', latitude: 56.901094, longitude: 16.309403 },
  { name: 'RA623', latitude: 56.902203, longitude: 25.100333 },
  { name: 'ORVIX', latitude: 56.9025, longitude: 22.379167 },
  { name: 'MT503', latitude: 56.902694, longitude: 13.015139 },
  { name: 'OKLAD', latitude: 56.905833, longitude: 20.743056 },
  { name: 'ARNIS', latitude: 56.906944, longitude: 23.770278 },
  { name: 'VAKTA', latitude: 56.908972, longitude: 13.407056 },
  { name: 'NUDKO', latitude: 56.911111, longitude: 34.945833 },
  { name: 'ABOXA', latitude: 56.911667, longitude: 24.213611 },
  { name: 'MT500', latitude: 56.911806, longitude: 12.864083 },
  { name: 'D265Y', latitude: 56.914572, longitude: 23.205083 },
  { name: 'TOLBI', latitude: 56.917778, longitude: 21.3075 },
  { name: 'MQ851', latitude: 56.918306, longitude: 16.06275 },
  { name: 'RA530', latitude: 56.918361, longitude: 23.769917 },
  { name: 'VEPIP', latitude: 56.920278, longitude: 14.519722 },
  { name: 'PUZFE', latitude: 56.920425, longitude: 24.952 },
  { name: 'MT851', latitude: 56.920778, longitude: 12.712917 },
  { name: 'ABAKI', latitude: 56.924444, longitude: 23.727222 },
  { name: 'LASLI', latitude: 56.928333, longitude: 12.011667 },
  { name: 'FI18', latitude: 56.929167, longitude: 24.888111 },
  { name: 'RA441', latitude: 56.930514, longitude: 22.900972 },
  { name: 'GA401', latitude: 56.932472, longitude: 24.888825 },
  { name: 'FF18', latitude: 56.932472, longitude: 24.888861 },
  { name: 'RA531', latitude: 56.933028, longitude: 23.653778 },
  { name: 'ED18', latitude: 56.935333, longitude: 23.969389 },
  { name: 'OSVEM', latitude: 56.935556, longitude: 14.578056 },
  { name: 'RA403', latitude: 56.935731, longitude: 24.4015 },
  { name: 'ML18', latitude: 56.940028, longitude: 23.973917 },
  { name: 'GUBIT', latitude: 56.941389, longitude: 30.116111 },
  { name: 'IBRAL', latitude: 56.941667, longitude: 40.931389 },
  { name: 'RA443', latitude: 56.946608, longitude: 23.313333 },
  { name: 'ELING', latitude: 56.950833, longitude: 23.768889 },
  { name: 'D358P', latitude: 56.950886, longitude: 16.315281 },
  { name: 'TORVO', latitude: 56.952778, longitude: 38.136111 },
  { name: '22LOC', latitude: 56.956833, longitude: 23.976694 },
  { name: '22VOR', latitude: 56.957083, longitude: 23.975694 },
  { name: 'RA522', latitude: 56.958142, longitude: 24.344861 },
  { name: 'ADEKO', latitude: 56.958889, longitude: 24.221667 },
  { name: 'RA622', latitude: 56.961308, longitude: 24.61 },
  { name: 'VP029', latitude: 56.961944, longitude: 14.886111 },
  { name: 'INBUK', latitude: 56.963611, longitude: 36.850833 },
  { name: 'RA640', latitude: 56.964636, longitude: 22.75675 },
  { name: 'RA801', latitude: 56.972447, longitude: 23.979258 },
  { name: 'LATVU', latitude: 56.977222, longitude: 24.898333 },
  { name: 'OSRUK', latitude: 56.977944, longitude: 12.877306 },
  { name: 'RA821', latitude: 56.97905, longitude: 24.05375 },
  { name: 'RA620', latitude: 56.981633, longitude: 25.055 },
  { name: 'VP001', latitude: 56.981667, longitude: 24.179167 },
  { name: 'MAORI', latitude: 56.982222, longitude: 23.783056 },
  { name: 'RA810', latitude: 56.982667, longitude: 23.98095 },
  { name: 'MT510', latitude: 56.983556, longitude: 13.133667 },
  { name: 'RA820', latitude: 56.986536, longitude: 23.902067 },
  { name: 'D275Y', latitude: 56.988061, longitude: 23.214253 },
  { name: 'RA553', latitude: 56.989958, longitude: 23.509917 },
  { name: 'RA442', latitude: 56.990122, longitude: 22.634306 },
  { name: 'SARPS', latitude: 56.991944, longitude: 24.074444 },
  { name: 'D310F', latitude: 56.992681, longitude: 23.842822 },
  { name: 'D312F', latitude: 56.993606, longitude: 23.849253 },
  { name: 'LARNI', latitude: 56.993611, longitude: 23.738056 },
  { name: 'VEKZO', latitude: 56.994989, longitude: 23.351917 },
  { name: 'FI19', latitude: 56.995539, longitude: 14.756144 },
  { name: 'OLATU', latitude: 56.996389, longitude: 40.323333 },
  { name: 'TICAC', latitude: 56.996778, longitude: 23.665056 },
  { name: 'SIGZU', latitude: 56.9975, longitude: 35.203056 },
  { name: 'CEK', latitude: 41.006556, longitude: 28.528611 },
  { name: 'CEK', latitude: 41.006556, longitude: 28.528611 },
  { name: 'GRA', latitude: 41.058194, longitude: 14.085 },
  { name: 'GRA', latitude: 41.060028, longitude: 14.088722 },
  { name: 'FIS', latitude: 41.098147, longitude: 22.991761 },
  { name: 'FSK', latitude: 41.098714, longitude: 22.991489 },
  { name: 'BPL', latitude: 41.110306, longitude: 16.660444 },
  { name: 'BKZ', latitude: 41.126972, longitude: 29.142889 },
  { name: 'CRL', latitude: 41.1315, longitude: 27.910361 },
  { name: 'BAP', latitude: 41.135639, longitude: 16.744333 },
  { name: 'BAR', latitude: 41.14425, longitude: 16.776444 },
  { name: 'CLU', latitude: 41.146444, longitude: 27.926306 },
  { name: 'CRL', latitude: 41.15075, longitude: 27.935 },
  { name: 'OHR', latitude: 41.1725, longitude: 20.736389 },
  { name: 'ART', latitude: 41.180222, longitude: 40.858722 },
  { name: 'ART', latitude: 41.180222, longitude: 40.858722 },
  { name: 'CRM', latitude: 41.2655, longitude: 36.548861 },
  { name: 'CRM', latitude: 41.2655, longitude: 36.548861 },
  { name: 'TEA', latitude: 41.296778, longitude: 13.970667 },
  { name: 'KTM', latitude: 41.304444, longitude: 33.790556 },
  { name: 'UHM', latitude: 41.3095, longitude: 28.725556 },
  { name: 'UHN', latitude: 41.310425, longitude: 28.755786 },
  { name: 'UHL', latitude: 41.312167, longitude: 28.707556 },
  { name: 'PEP', latitude: 41.337778, longitude: 21.448611 },
  { name: 'PEP', latitude: 41.337778, longitude: 21.448611 },
  { name: 'IZD', latitude: 41.343056, longitude: 20.820556 },
  { name: 'IZD', latitude: 41.343056, longitude: 20.820556 },
  { name: 'KST', latitude: 41.350306, longitude: 33.799861 },
  { name: 'KST', latitude: 41.350306, longitude: 33.799861 },
  { name: 'TRN', latitude: 41.416111, longitude: 19.718194 },
  { name: 'ITR', latitude: 41.424194, longitude: 19.720778 },
  { name: 'FGG', latitude: 41.429472, longitude: 15.531361 },
  { name: 'FOG', latitude: 41.430972, longitude: 15.531417 },
  { name: 'CAY', latitude: 41.513778, longitude: 32.044556 },
  { name: 'CAY', latitude: 41.513778, longitude: 32.044556 },
  { name: 'LAT', latitude: 41.541111, longitude: 12.918056 },
  { name: 'LAI', latitude: 41.544417, longitude: 12.9035 },
  { name: 'MNL', latitude: 41.547639, longitude: 15.689806 },
  { name: 'TPR', latitude: 41.621556, longitude: 12.493778 },
  { name: 'FRS', latitude: 41.642528, longitude: 13.292361 },
  { name: 'PRA', latitude: 41.648972, longitude: 12.453861 },
  { name: 'ROZ', latitude: 41.6975, longitude: 24.738333 },
  { name: 'OST', latitude: 41.803778, longitude: 12.237528 },
  { name: 'ROM', latitude: 41.804667, longitude: 12.588 },
  { name: 'OST', latitude: 41.805278, longitude: 12.236389 },
  { name: 'FEE', latitude: 41.810083, longitude: 12.265 },
  { name: 'FSS', latitude: 41.815667, longitude: 12.276333 },
  { name: 'VIE', latitude: 41.912778, longitude: 16.049167 },
  { name: 'VIE', latitude: 41.913333, longitude: 16.051111 },
  { name: 'PT', latitude: 41.914167, longitude: 21.638056 },
  { name: 'SKJ', latitude: 41.935, longitude: 21.630556 },
  { name: 'URB', latitude: 41.944694, longitude: 12.490056 },
  { name: 'INB', latitude: 41.951417, longitude: 33.706278 },
  { name: 'MSK', latitude: 41.953056, longitude: 21.623056 },
  { name: 'SIN', latitude: 41.980833, longitude: 22.365556 },
  { name: 'SIN', latitude: 42.022306, longitude: 35.076806 },
  { name: 'SIN', latitude: 42.022306, longitude: 35.076806 },
  { name: 'PDV', latitude: 42.053417, longitude: 24.876306 },
  { name: 'PD', latitude: 42.054917, longitude: 24.873583 },
  { name: 'CMP', latitude: 42.123833, longitude: 12.381528 },
  { name: 'TAZ', latitude: 42.281972, longitude: 18.800389 },
  { name: 'GO', latitude: 42.298889, longitude: 19.245722 },
  { name: 'POD', latitude: 42.386194, longitude: 19.254611 },
  { name: 'POD', latitude: 42.388583, longitude: 19.254861 },
  { name: 'TIV', latitude: 42.396, longitude: 18.741361 },
  { name: 'RO', latitude: 42.420556, longitude: 18.553639 },
  { name: 'PES', latitude: 42.435833, longitude: 14.184167 },
  { name: 'PES', latitude: 42.436306, longitude: 14.183472 },
  { name: 'IPS', latitude: 42.437194, longitude: 14.186444 },
  { name: 'VIB', latitude: 42.440083, longitude: 12.057056 },
  { name: 'GR', latitude: 42.540628, longitude: 18.320825 },
  { name: 'DAN', latitude: 42.545, longitude: 19.1235 },
  { name: 'DBK', latitude: 42.553844, longitude: 18.277442 },
  { name: 'PRT', latitude: 42.553858, longitude: 21.0359 },
  { name: 'VIT', latitude: 42.565278, longitude: 23.29 },
  { name: 'IDU', latitude: 42.568942, longitude: 18.252211 },
  { name: 'WAK', latitude: 42.575, longitude: 23.704444 },
  { name: 'IBG', latitude: 42.57775, longitude: 27.527361 },
  { name: 'PRS', latitude: 42.582725, longitude: 21.036394 },
  { name: 'CV', latitude: 42.585189, longitude: 18.212642 },
  { name: 'BGS', latitude: 42.587167, longitude: 27.537972 },
  { name: 'KMN', latitude: 42.625917, longitude: 27.588917 },
  { name: 'BUI', latitude: 42.633028, longitude: 20.495833 },
  { name: 'BLO', latitude: 42.667111, longitude: 23.813944 },
  { name: 'KLP', latitude: 42.669283, longitude: 18.020853 },
  { name: 'ISF', latitude: 42.696417, longitude: 23.435806 },
  { name: 'SOF', latitude: 42.698111, longitude: 23.389389 },
  { name: 'ISL', latitude: 42.698361, longitude: 23.403611 },
  { name: 'EMO', latitude: 42.753056, longitude: 27.758611 },
  { name: 'BOZ', latitude: 42.77, longitude: 23.191111 },
  { name: 'NIK', latitude: 42.776417, longitude: 18.924167 },
  { name: 'VRB', latitude: 42.925833, longitude: 26.6675 },
  { name: 'MOJ', latitude: 42.944639, longitude: 19.575528 },
  { name: 'KAL', latitude: 42.949167, longitude: 22.875 },
  { name: 'IPE', latitude: 43.090278, longitude: 12.513389 },
  { name: 'GOL', latitude: 43.095, longitude: 24.220556 },
  { name: 'PRU', latitude: 43.101694, longitude: 12.511889 },
  { name: 'PRU', latitude: 43.101972, longitude: 12.510722 },
  { name: 'DNC', latitude: 43.137456, longitude: 17.847383 },
  { name: 'GAC', latitude: 43.142803, longitude: 18.558075 },
  { name: 'GRN', latitude: 43.153222, longitude: 25.711778 },
  { name: 'GNA', latitude: 43.161722, longitude: 25.614139 },
  { name: 'ZO', latitude: 43.183639, longitude: 21.724639 },
  { name: 'MA', latitude: 43.218233, longitude: 17.854511 },
  { name: 'WRN', latitude: 43.231028, longitude: 27.817167 },
  { name: 'IWN', latitude: 43.234111, longitude: 27.813194 },
  { name: 'BLC', latitude: 43.246722, longitude: 21.362167 },
  { name: 'BLC', latitude: 43.248694, longitude: 21.361639 },
  { name: 'DWN', latitude: 43.25, longitude: 27.654167 },
  { name: 'MSR', latitude: 43.261372, longitude: 17.855886 },
  { name: 'MOS', latitude: 43.273325, longitude: 17.854981 },
  { name: 'BRC', latitude: 43.282481, longitude: 16.622453 },
  { name: 'HUM', latitude: 43.287189, longitude: 16.67845 },
  { name: 'NS', latitude: 43.303444, longitude: 21.825028 },
  { name: 'YNI', latitude: 43.333528, longitude: 21.862222 },
  { name: 'NII', latitude: 43.336778, longitude: 21.850306 },
  { name: 'JST', latitude: 43.424611, longitude: 21.631306 },
  { name: 'IAD', latitude: 43.436194, longitude: 39.947667 },
  { name: 'ISO', latitude: 43.439667, longitude: 39.930139 },
  { name: 'DVN', latitude: 43.446733, longitude: 16.143633 },
  { name: 'ADL', latitude: 43.454472, longitude: 39.962111 },
  { name: 'AD', latitude: 43.454778, longitude: 39.960778 },
  { name: 'SPL', latitude: 43.496581, longitude: 16.304722 },
  { name: 'TRI', latitude: 43.496831, longitude: 16.222439 },
  { name: 'IST', latitude: 43.532669, longitude: 16.289128 },
  { name: 'FAL', latitude: 43.576861, longitude: 13.299528 },
  { name: 'ANC', latitude: 43.586444, longitude: 13.471111 },
  { name: 'IFA', latitude: 43.621667, longitude: 13.372583 },
  { name: 'FAL', latitude: 43.628222, longitude: 13.373528 },
  { name: 'VTN', latitude: 43.7175, longitude: 20.810333 },
  { name: 'AD', latitude: 43.754417, longitude: 20.664278 },
  { name: 'ML', latitude: 43.785417, longitude: 20.626417 },
  { name: 'KRV', latitude: 43.821222, longitude: 20.584444 },
  { name: 'IL', latitude: 43.824461, longitude: 18.334008 },
  { name: 'BHS', latitude: 43.829422, longitude: 18.3218 },
  { name: 'AS', latitude: 43.833161, longitude: 18.308739 },
  { name: 'LA', latitude: 43.856167, longitude: 20.538639 },
  { name: 'UZ', latitude: 43.858139, longitude: 19.88825 },
  { name: 'KG', latitude: 43.881411, longitude: 18.177014 },
  { name: 'LAZ', latitude: 43.909167, longitude: 39.336944 },
  { name: 'LA', latitude: 43.910278, longitude: 39.337778 },
  { name: 'VRL', latitude: 43.933333, longitude: 16.433333 },
  { name: 'SAR', latitude: 43.933756, longitude: 18.456394 },
  { name: 'KEB', latitude: 43.933864, longitude: 18.449828 },
  { name: 'SAL', latitude: 43.937861, longitude: 15.168111 },
  { name: 'BR', latitude: 43.947528, longitude: 20.427806 },
  { name: 'ZRA', latitude: 43.997156, longitude: 15.496475 },
  { name: 'RIM', latitude: 44.015167, longitude: 12.616639 },
  { name: 'RMN', latitude: 44.016972, longitude: 12.621083 },
  { name: 'KIS', latitude: 44.018275, longitude: 18.062997 },
  { name: 'RIM', latitude: 44.078889, longitude: 12.506111 },
  { name: 'ZK', latitude: 44.094569, longitude: 15.365072 },
  { name: 'ZDA', latitude: 44.095322, longitude: 15.364228 },
  { name: 'AGO', latitude: 44.138889, longitude: 39.025 },
  { name: 'AG', latitude: 44.139444, longitude: 39.026667 },
  { name: 'TPL', latitude: 44.139778, longitude: 20.745667 },
  { name: 'TPL', latitude: 44.1435, longitude: 20.744444 },
  { name: 'BO', latitude: 44.169247, longitude: 15.264003 },
  { name: 'FOL', latitude: 44.19375, longitude: 12.067667 },
  { name: 'CEV', latitude: 44.206472, longitude: 12.357167 },
  { name: 'CER', latitude: 44.229278, longitude: 12.295333 },
  { name: 'CND', latitude: 44.285556, longitude: 28.478333 },
  { name: 'DV', latitude: 44.310556, longitude: 38.703889 },
  { name: 'CRV', latitude: 44.318611, longitude: 23.92275 },
  { name: 'ICV', latitude: 44.319417, longitude: 23.900361 },
  { name: 'VAL', latitude: 44.321194, longitude: 19.875056 },
  { name: 'VAL', latitude: 44.323889, longitude: 19.874694 },
  { name: 'ICK', latitude: 44.349167, longitude: 28.488444 },
  { name: 'TZU', latitude: 44.456758, longitude: 18.735425 },
  { name: 'TLA', latitude: 44.458514, longitude: 18.712611 },
  { name: 'ZV', latitude: 44.464744, longitude: 18.648792 },
  { name: 'BSW', latitude: 44.472086, longitude: 25.951997 },
  { name: 'TU', latitude: 44.477281, longitude: 18.483019 },
  { name: 'FLR', latitude: 44.500833, longitude: 25.708056 },
  { name: 'IBS', latitude: 44.501639, longitude: 26.088833 },
  { name: 'IBN', latitude: 44.507417, longitude: 26.117333 },
  { name: 'LSJ', latitude: 44.515897, longitude: 14.491017 },
  { name: 'LOS', latitude: 44.527097, longitude: 14.472847 },
  { name: 'BSE', latitude: 44.529639, longitude: 26.230528 },
  { name: 'OPW', latitude: 44.55775, longitude: 25.984639 },
  { name: 'IOP', latitude: 44.563806, longitude: 26.081333 },
  { name: 'NTL', latitude: 44.566511, longitude: 14.391053 },
  { name: 'IOE', latitude: 44.566556, longitude: 26.116361 },
  { name: 'OTL', latitude: 44.57025, longitude: 26.000611 },
  { name: 'IGN', latitude: 44.571194, longitude: 38.007611 },
  { name: 'GNV', latitude: 44.572528, longitude: 38.012139 },
  { name: 'OPE', latitude: 44.574778, longitude: 26.21125 },
  { name: 'GN', latitude: 44.575111, longitude: 38.013972 },
  { name: 'LL', latitude: 44.575694, longitude: 26.071944 },
  { name: 'ILL', latitude: 44.578028, longitude: 26.08775 },
  { name: 'IRR', latitude: 44.580694, longitude: 26.123833 },
  { name: 'OTR', latitude: 44.588, longitude: 26.235361 },
  { name: 'OPT', latitude: 44.5925, longitude: 26.560278 },
  { name: 'POZ', latitude: 44.62075, longitude: 21.147722 },
  { name: 'OBR', latitude: 44.643833, longitude: 20.138444 },
  { name: 'JA', latitude: 44.742917, longitude: 20.475306 },
  { name: 'SM', latitude: 44.774444, longitude: 38.801111 },
  { name: 'BGD', latitude: 44.819361, longitude: 20.311167 },
  { name: 'YUB', latitude: 44.826028, longitude: 20.295528 },
  { name: 'PLA', latitude: 44.889208, longitude: 13.753517 },
  { name: 'PA', latitude: 44.891722, longitude: 20.641111 },
  { name: 'PUL', latitude: 44.892367, longitude: 13.918119 },
  { name: 'GS', latitude: 44.892467, longitude: 13.853083 },
  { name: 'VL', latitude: 44.892861, longitude: 13.891156 },
  { name: 'KN', latitude: 44.894639, longitude: 20.136139 },
  { name: 'KAV', latitude: 44.895353, longitude: 14.008239 },
  { name: 'CRE', latitude: 44.902881, longitude: 14.416547 },
  { name: 'EK', latitude: 44.903278, longitude: 20.316889 },
  { name: 'STJ', latitude: 44.918611, longitude: 25.976944 },
  { name: 'NA', latitude: 44.922108, longitude: 17.3025 },
  { name: 'LAK', latitude: 44.94165, longitude: 17.295983 },
  { name: 'BTJ', latitude: 44.945056, longitude: 20.24 },
  { name: 'BAL', latitude: 44.950122, longitude: 17.296753 },
  { name: 'PZ', latitude: 44.953111, longitude: 20.226694 },
  { name: 'OMA', latitude: 44.958075, longitude: 16.80435 },
  { name: 'LU', latitude: 44.961039, longitude: 17.292347 },
  { name: 'XT', latitude: 44.962222, longitude: 39.576389 },
  { name: 'AN', latitude: 44.967889, longitude: 37.303722 },
  { name: 'BT', latitude: 44.968333, longitude: 20.196361 },
  { name: 'DER', latitude: 44.984128, longitude: 17.969461 },
  { name: 'N ', latitude: 44.986556, longitude: 37.327278 },
  { name: 'IAN', latitude: 44.994861, longitude: 37.340556 },
  { name: 'IAP', latitude: 45.007306, longitude: 37.356056 },
  { name: 'SMI', latitude: 45.009472, longitude: 19.430778 },
  { name: 'P ', latitude: 45.018889, longitude: 37.368556 },
  { name: 'KR', latitude: 45.020056, longitude: 39.161778 },
  { name: 'KND', latitude: 45.020972, longitude: 39.164 },
  { name: 'IKR', latitude: 45.027056, longitude: 39.177583 },
  { name: 'AP', latitude: 45.035111, longitude: 37.389972 },
  { name: 'ILD', latitude: 45.0395, longitude: 39.2005 },
  { name: 'IA', latitude: 45.043083, longitude: 20.073111 },
  { name: 'ITC', latitude: 45.056528, longitude: 28.718083 },
  { name: 'TGJ', latitude: 45.060833, longitude: 23.334167 },
  { name: 'CHI', latitude: 45.071083, longitude: 12.281444 },
  { name: 'CHI', latitude: 45.071806, longitude: 12.281389 },
  { name: 'TLA', latitude: 45.078694, longitude: 28.7095 },
  { name: 'VRA', latitude: 45.087722, longitude: 21.291222 },
  { name: 'BLK', latitude: 45.095267, longitude: 17.257933 },
  { name: 'SF', latitude: 45.104444, longitude: 33.991667 },
  { name: 'RI', latitude: 45.137511, longitude: 14.652933 },
  { name: 'VAC', latitude: 45.137806, longitude: 21.306778 },
  { name: 'VRS', latitude: 45.210183, longitude: 13.648975 },
  { name: 'NZ', latitude: 45.218611, longitude: 39.673056 },
  { name: 'RJK', latitude: 45.224125, longitude: 14.566961 },
  { name: 'KOS', latitude: 45.233889, longitude: 16.541667 },
  { name: 'MO', latitude: 45.243889, longitude: 33.190278 },
  { name: 'TNJ', latitude: 45.248117, longitude: 15.350347 },
  { name: 'PV', latitude: 45.266306, longitude: 19.7995 },
  { name: 'RF', latitude: 45.286667, longitude: 39.9175 },
  { name: 'KO', latitude: 45.334636, longitude: 14.446764 },
  { name: 'GLT', latitude: 45.416389, longitude: 27.927778 },
  { name: 'BRZ', latitude: 45.42365, longitude: 14.3454 },
  { name: 'OSJ', latitude: 45.455419, longitude: 18.837608 },
  { name: 'ISJ', latitude: 45.464433, longitude: 18.799908 },
  { name: 'KLS', latitude: 45.466183, longitude: 18.792267 },
  { name: 'POR', latitude: 45.474778, longitude: 13.612083 },
  { name: 'PZ', latitude: 45.477472, longitude: 13.614917 },
  { name: 'VTS', latitude: 45.495111, longitude: 12.342 },
  { name: 'TES', latitude: 45.519083, longitude: 12.368194 },
  { name: 'CE', latitude: 45.528425, longitude: 18.56005 },
  { name: 'ILB', latitude: 45.565586, longitude: 14.170864 },
  { name: 'BRV', latitude: 45.5675, longitude: 25.564722 },
  { name: 'PIS', latitude: 45.605028, longitude: 15.843997 },
  { name: 'TRE', latitude: 45.622472, longitude: 12.095639 },
  { name: 'TEV', latitude: 45.647389, longitude: 12.18375 },
  { name: 'ISA', latitude: 45.687528, longitude: 12.117083 },
  { name: 'LUK', latitude: 45.690544, longitude: 15.992472 },
  { name: 'CNI', latitude: 45.716111, longitude: 20.9075 },
  { name: 'LGJ', latitude: 45.719722, longitude: 21.995 },
  { name: 'VG', latitude: 45.725361, longitude: 16.042067 },
  { name: 'IZA', latitude: 45.734939, longitude: 16.05345 },
  { name: 'JAP', latitude: 45.744494, longitude: 15.608181 },
  { name: 'VBA', latitude: 45.7478, longitude: 17.146747 },
  { name: 'ND', latitude: 45.763889, longitude: 40.244167 },
  { name: 'SBI', latitude: 45.780917, longitude: 24.087806 },
  { name: 'ISB', latitude: 45.784528, longitude: 24.0985 },
  { name: 'SIB', latitude: 45.785, longitude: 24.152583 },
  { name: 'TIM', latitude: 45.801306, longitude: 21.371778 },
  { name: 'ITR', latitude: 45.804528, longitude: 21.354417 },
  { name: 'SK', latitude: 45.805822, longitude: 16.164661 },
  { name: 'ITS', latitude: 45.815278, longitude: 21.320944 },
  { name: 'TSR', latitude: 45.817986, longitude: 21.305406 },
  { name: 'DVA', latitude: 45.828056, longitude: 22.968889 },
  { name: 'RON', latitude: 45.828806, longitude: 13.360361 },
  { name: 'RNC', latitude: 45.8295, longitude: 13.45425 },
  { name: 'RCH', latitude: 45.829806, longitude: 13.480278 },
  { name: 'TA', latitude: 45.836833, longitude: 21.231861 },
  { name: 'TRK', latitude: 45.851389, longitude: 40.090278 },
  { name: 'UH', latitude: 45.851944, longitude: 40.09 },
  { name: 'ZAG', latitude: 45.895558, longitude: 16.306697 },
  { name: 'RK', latitude: 45.898183, longitude: 15.391442 },
  { name: 'CL', latitude: 45.899525, longitude: 15.497281 },
  { name: 'ZAG', latitude: 45.900264, longitude: 16.311211 },
  { name: 'CKL', latitude: 45.900817, longitude: 15.514525 },
  { name: 'COK', latitude: 45.901208, longitude: 15.520478 },
  { name: 'AVI', latitude: 45.924336, longitude: 12.428503 },
  { name: 'RIV', latitude: 45.935333, longitude: 12.942 },
  { name: 'PCS', latitude: 45.985111, longitude: 18.243028 },
  { name: 'RIV', latitude: 45.995722, longitude: 13.088389 },
  { name: 'PP', latitude: 46.005833, longitude: 18.232778 },
  { name: 'AVI', latitude: 46.027869, longitude: 12.586878 },
  { name: 'DOL', latitude: 46.084139, longitude: 14.778583 },
  { name: 'MG', latitude: 46.173222, longitude: 14.555944 },
  { name: 'IAD', latitude: 46.177278, longitude: 21.268528 },
  { name: 'CHU', latitude: 46.183056, longitude: 24.820556 },
  { name: 'ARD', latitude: 46.184, longitude: 21.144889 },
  { name: 'ARD', latitude: 46.184083, longitude: 21.143611 },
  { name: 'LJB', latitude: 46.215667, longitude: 14.470639 },
  { name: 'LBL', latitude: 46.2385, longitude: 14.428556 },
  { name: 'SEG', latitude: 46.24005, longitude: 20.089183 },
  { name: 'SEG', latitude: 46.240125, longitude: 20.089692 },
  { name: 'VAR', latitude: 46.310858, longitude: 16.374956 },
  { name: 'MR', latitude: 46.371528, longitude: 15.793306 },
  { name: 'IOE', latitude: 46.416389, longitude: 30.675861 },
  { name: 'ODA', latitude: 46.426306, longitude: 30.66975 },
  { name: 'IOD', latitude: 46.435111, longitude: 30.66775 },
  { name: 'TGM', latitude: 46.446917, longitude: 24.306583 },
  { name: 'D ', latitude: 46.461611, longitude: 24.381278 },
  { name: 'MI', latitude: 46.462667, longitude: 15.702778 },
  { name: 'ITM', latitude: 46.465056, longitude: 24.404556 },
  { name: 'MAR', latitude: 46.471917, longitude: 15.691556 },
  { name: 'BC', latitude: 46.478111, longitude: 26.927417 },
  { name: 'BAI', latitude: 46.491111, longitude: 23.236667 },
  { name: 'BAC', latitude: 46.501389, longitude: 26.918056 },
  { name: 'B ', latitude: 46.501444, longitude: 26.918222 },
  { name: 'BCU', latitude: 46.510917, longitude: 26.825556 },
  { name: 'IBC', latitude: 46.513444, longitude: 26.911806 },
  { name: 'ER', latitude: 46.585278, longitude: 40.659444 },
  { name: 'KFT', latitude: 46.597586, longitude: 14.562322 },
  { name: 'KFT', latitude: 46.625086, longitude: 14.534156 },
  { name: 'KI', latitude: 46.633522, longitude: 14.382381 },
  { name: 'HE', latitude: 46.6375, longitude: 32.470556 },
  { name: 'OEK', latitude: 46.638506, longitude: 14.350044 },
  { name: 'BC', latitude: 46.664889, longitude: 21.165083 },
  { name: 'SME', latitude: 46.665722, longitude: 17.166361 },
  { name: 'SME', latitude: 46.665806, longitude: 17.166861 },
  { name: 'BUG', latitude: 46.677778, longitude: 19.681667 },
  { name: 'SMK', latitude: 46.694611, longitude: 17.157528 },
  { name: 'VIW', latitude: 46.69635, longitude: 13.914892 },
  { name: 'KOR', latitude: 46.787342, longitude: 14.971033 },
  { name: 'ICX', latitude: 46.788222, longitude: 23.693028 },
  { name: 'BKS', latitude: 46.799972, longitude: 21.073889 },
  { name: 'CLJ', latitude: 46.800111, longitude: 23.78725 },
  { name: 'SVR', latitude: 46.828056, longitude: 18.117778 },
  { name: 'STK', latitude: 46.845231, longitude: 15.931942 },
  { name: 'GBG', latitude: 46.886989, longitude: 15.800319 },
  { name: 'TN', latitude: 46.892222, longitude: 19.806556 },
  { name: 'T ', latitude: 46.906278, longitude: 19.774667 },
  { name: 'KEE', latitude: 46.913806, longitude: 19.760333 },
  { name: 'GRZ', latitude: 46.920656, longitude: 15.458953 },
  { name: 'KEW', latitude: 46.922833, longitude: 19.739861 },
  { name: 'KIV', latitude: 46.926028, longitude: 28.9045 },
  { name: 'IRG', latitude: 46.926417, longitude: 28.912028 },
  { name: 'KET', latitude: 46.926722, longitude: 19.726861 },
  { name: 'ILD', latitude: 46.927222, longitude: 28.951222 },
  { name: 'N ', latitude: 46.928556, longitude: 19.724167 },
  { name: 'NT', latitude: 46.944861, longitude: 19.687028 },
  { name: 'GRZ', latitude: 46.955311, longitude: 15.449444 },
  { name: 'OEG', latitude: 46.980203, longitude: 15.440453 },
  { name: 'IOD', latitude: 47.030361, longitude: 21.905528 },
  { name: 'NI', latitude: 47.045139, longitude: 31.896417 },
  { name: 'O ', latitude: 47.045194, longitude: 21.908139 },
  { name: 'AO', latitude: 47.081, longitude: 20.211806 },
  { name: 'ORA', latitude: 47.100361, longitude: 21.924139 },
  { name: 'A ', latitude: 47.105417, longitude: 20.225667 },
  { name: 'O ', latitude: 47.137639, longitude: 20.244028 },
  { name: 'PTB', latitude: 47.152222, longitude: 18.742222 },
  { name: 'ZLU', latitude: 47.152778, longitude: 23.101944 },
  { name: 'IAS', latitude: 47.162944, longitude: 27.636278 },
  { name: 'OA', latitude: 47.16325, longitude: 20.258611 },
  { name: 'IIS', latitude: 47.185861, longitude: 27.613944 },
  { name: 'MS', latitude: 47.193528, longitude: 38.877278 },
  { name: 'ZW', latitude: 47.197758, longitude: 14.755939 },
  { name: 'U ', latitude: 47.203694, longitude: 38.818 },
  { name: 'UF', latitude: 47.209778, longitude: 38.782222 },
  { name: 'ISI', latitude: 47.234278, longitude: 27.579694 },
  { name: 'ISI', latitude: 47.234306, longitude: 27.579417 },
  { name: 'HNU', latitude: 47.2775, longitude: 24.735278 },
  { name: 'RAW', latitude: 47.281839, longitude: 15.773042 },
  { name: 'PC', latitude: 47.318639, longitude: 17.524333 },
  { name: 'BA', latitude: 47.321111, longitude: 40.359444 },
  { name: 'MNR', latitude: 47.334722, longitude: 19.405472 },
  { name: 'P ', latitude: 47.344806, longitude: 17.510639 },
  { name: 'PCD', latitude: 47.356056, longitude: 17.502806 },
  { name: 'PCN', latitude: 47.371972, longitude: 17.498194 },
  { name: 'PCT', latitude: 47.3815, longitude: 17.49075 },
  { name: 'C ', latitude: 47.382028, longitude: 17.491111 },
  { name: 'CP', latitude: 47.407167, longitude: 17.477889 },
  { name: 'BPR', latitude: 47.423833, longitude: 19.289861 },
  { name: 'FHL', latitude: 47.431972, longitude: 19.245306 },
  { name: 'BPL', latitude: 47.444083, longitude: 19.262278 },
  { name: 'FER', latitude: 47.44775, longitude: 19.225 },
  { name: 'BUD', latitude: 47.450444, longitude: 19.249444 },
  { name: 'DC', latitude: 47.45675, longitude: 21.563056 },
  { name: 'C ', latitude: 47.475306, longitude: 21.593111 },
  { name: 'RD', latitude: 47.479306, longitude: 39.894583 },
  { name: 'DCN', latitude: 47.484056, longitude: 21.605167 },
  { name: 'IRD', latitude: 47.486861, longitude: 39.907417 },
  { name: 'TPS', latitude: 47.49325, longitude: 19.446222 },
  { name: 'ROS', latitude: 47.500889, longitude: 39.933556 },
  { name: 'IRS', latitude: 47.50225, longitude: 39.939139 },
  { name: 'EN', latitude: 47.53325, longitude: 21.688028 },
  { name: 'GPR', latitude: 47.624389, longitude: 17.812194 },
  { name: 'IBM', latitude: 47.658528, longitude: 23.453056 },
  { name: 'GYR', latitude: 47.659111, longitude: 17.724361 },
  { name: 'M ', latitude: 47.661472, longitude: 23.435917 },
  { name: 'BMR', latitude: 47.671222, longitude: 23.345972 },
  { name: 'SCV', latitude: 47.672333, longitude: 26.360833 },
  { name: 'ISV', latitude: 47.6815, longitude: 26.354944 },
  { name: 'ISM', latitude: 47.711361, longitude: 22.889917 },
  { name: 'SAT', latitude: 47.727417, longitude: 22.893861 },
  { name: 'SB', latitude: 47.752778, longitude: 39.798611 },
  { name: 'OES', latitude: 47.800942, longitude: 13.001294 },
  { name: 'GSB', latitude: 47.805114, longitude: 13.111611 },
  { name: 'SI', latitude: 47.818608, longitude: 12.987675 },
  { name: 'ZA', latitude: 47.821611, longitude: 35.286389 },
  { name: 'LC', latitude: 47.8565, longitude: 27.767139 },
  { name: 'SNU', latitude: 47.874875, longitude: 16.288436 },
  { name: 'IZP', latitude: 47.875389, longitude: 35.317556 },
  { name: 'LCT', latitude: 47.881278, longitude: 27.748444 },
  { name: 'KL', latitude: 47.885278, longitude: 40.1 },
  { name: 'KLN', latitude: 47.885556, longitude: 40.100833 },
  { name: 'ZP', latitude: 47.886, longitude: 35.326806 },
  { name: 'NY', latitude: 47.911833, longitude: 21.687944 },
  { name: 'SBG', latitude: 47.967533, longitude: 12.894072 },
  { name: 'Y ', latitude: 47.967889, longitude: 21.691833 },
  { name: 'NYR', latitude: 47.991194, longitude: 21.692556 },
  { name: 'PQ', latitude: 48.001306, longitude: 21.692889 },
  { name: 'SBG', latitude: 48.002583, longitude: 12.892761 },
  { name: 'SAG', latitude: 48.008056, longitude: 20.996389 },
  { name: 'KVR', latitude: 48.051, longitude: 33.212139 },
  { name: 'BRK', latitude: 48.062922, longitude: 16.716747 },
  { name: 'RG', latitude: 48.064333, longitude: 33.210889 },
  { name: 'OEN', latitude: 48.091375, longitude: 16.592456 },
  { name: 'FMD', latitude: 48.105114, longitude: 16.629264 },
  { name: 'OEX', latitude: 48.108789, longitude: 16.571494 },
  { name: 'OB', latitude: 48.114833, longitude: 17.298361 },
  { name: 'OEZ', latitude: 48.117542, longitude: 16.581386 },
  { name: 'OEW', latitude: 48.120378, longitude: 16.536 },
  { name: 'B ', latitude: 48.15075, longitude: 17.242694 },
  { name: 'OB', latitude: 48.160278, longitude: 17.230917 },
  { name: 'OKR', latitude: 48.176611, longitude: 17.224833 },
  { name: 'JAN', latitude: 48.178611, longitude: 17.544722 },
  { name: 'STE', latitude: 48.210617, longitude: 16.247136 },
  { name: 'OKR', latitude: 48.223861, longitude: 17.290333 },
  { name: 'LNZ', latitude: 48.229711, longitude: 14.103156 },
  { name: 'OEM', latitude: 48.231439, longitude: 14.175217 },
  { name: 'OEL', latitude: 48.23235, longitude: 14.201658 },
  { name: 'LNZ', latitude: 48.237103, longitude: 14.321789 },
  { name: 'CR', latitude: 48.241167, longitude: 25.993778 },
  { name: 'NIT', latitude: 48.290556, longitude: 18.050556 },
  { name: 'TUN', latitude: 48.308947, longitude: 15.979947 },
  { name: 'TUN', latitude: 48.309333, longitude: 15.9797 },
  { name: 'WGM', latitude: 48.323856, longitude: 16.490953 },
  { name: 'DP', latitude: 48.357139, longitude: 35.026333 },
  { name: 'P ', latitude: 48.357222, longitude: 35.069167 },
  { name: 'R ', latitude: 48.357222, longitude: 35.133444 },
  { name: 'DR', latitude: 48.357444, longitude: 35.174917 },
  { name: 'DNP', latitude: 48.359833, longitude: 35.103056 },
  { name: 'STO', latitude: 48.417136, longitude: 16.018594 },
  { name: 'FRE', latitude: 48.431781, longitude: 14.129831 },
  { name: 'SLC', latitude: 48.45325, longitude: 19.115861 },
  { name: 'SR', latitude: 48.521083, longitude: 22.508861 },
  { name: 'KVH', latitude: 48.544583, longitude: 32.291917 },
  { name: 'KD', latitude: 48.556111, longitude: 32.281667 },
  { name: 'KE', latitude: 48.588111, longitude: 21.215056 },
  { name: 'PNY', latitude: 48.608111, longitude: 17.82225 },
  { name: 'CN', latitude: 48.618639, longitude: 17.827833 },
  { name: 'UZH', latitude: 48.632722, longitude: 22.260639 },
  { name: 'UO', latitude: 48.632972, longitude: 22.261278 },
  { name: 'KE', latitude: 48.652333, longitude: 21.235667 },
  { name: 'KSC', latitude: 48.683083, longitude: 21.248167 },
  { name: 'FN', latitude: 48.878889, longitude: 24.714722 },
  { name: 'IVF', latitude: 48.884167, longitude: 24.691389 },
  { name: 'CRT', latitude: 48.900056, longitude: 19.7375 },
  { name: 'DUB', latitude: 48.922722, longitude: 21.462583 },
  { name: 'VI', latitude: 48.940833, longitude: 23.044444 },
  { name: 'OKF', latitude: 48.969175, longitude: 15.545578 },
  { name: 'RDG', latitude: 49.040269, longitude: 12.526547 },
  { name: 'KNE', latitude: 49.046486, longitude: 17.452153 },
  { name: 'PE', latitude: 49.062722, longitude: 20.442361 },
  { name: 'PPD', latitude: 49.064667, longitude: 20.350111 },
  { name: 'PPD', latitude: 49.068222, longitude: 20.3535 },
  { name: 'PW', latitude: 49.080111, longitude: 20.072528 },
  { name: 'KUN', latitude: 49.113922, longitude: 17.501397 },
  { name: 'KN', latitude: 49.1225, longitude: 33.486111 },
  { name: 'LA', latitude: 49.136506, longitude: 16.179611 },
  { name: 'B ', latitude: 49.148514, longitude: 16.726175 },
  { name: 'BO', latitude: 49.148681, longitude: 16.707614 },
  { name: 'BNO', latitude: 49.150064, longitude: 16.692581 },
  { name: 'L ', latitude: 49.150531, longitude: 16.153561 },
  { name: 'LA', latitude: 49.160008, longitude: 16.133325 },
  { name: 'X ', latitude: 49.180994, longitude: 16.096828 },
  { name: 'XU', latitude: 49.197253, longitude: 16.066789 },
  { name: 'ZLA', latitude: 49.202861, longitude: 18.510444 },
  { name: 'WN', latitude: 49.206056, longitude: 28.664167 },
  { name: 'Z ', latitude: 49.227611, longitude: 18.594639 },
  { name: 'N ', latitude: 49.227861, longitude: 28.634472 },
  { name: 'ZNA', latitude: 49.231139, longitude: 18.61 },
  { name: 'VIN', latitude: 49.239889, longitude: 28.620917 },
  { name: 'I ', latitude: 49.257417, longitude: 28.592889 },
  { name: 'KTN', latitude: 49.265667, longitude: 20.609389 },
  { name: 'WI', latitude: 49.277639, longitude: 28.56475 },
  { name: 'NTA', latitude: 49.3425, longitude: 19.9675 },
  { name: 'STB', latitude: 49.405194, longitude: 31.076778 },
  { name: 'CK', latitude: 49.423461, longitude: 17.392067 },
  { name: 'VOZ', latitude: 49.532328, longitude: 14.874664 },
  { name: 'PO', latitude: 49.571611, longitude: 34.362056 },
  { name: 'R ', latitude: 49.679344, longitude: 18.084067 },
  { name: 'OTA', latitude: 49.697492, longitude: 18.109075 },
  { name: 'VLM', latitude: 49.704272, longitude: 15.066742 },
  { name: 'OSV', latitude: 49.704472, longitude: 18.126561 },
  { name: 'N ', latitude: 49.7132, longitude: 18.138031 },
  { name: 'PSK', latitude: 49.784972, longitude: 14.03475 },
  { name: 'ILV', latitude: 49.801389, longitude: 23.968333 },
  { name: 'LIV', latitude: 49.811944, longitude: 23.951389 },
  { name: 'ILO', latitude: 49.815, longitude: 23.948056 },
  { name: 'CF', latitude: 49.904019, longitude: 15.432844 },
  { name: 'C ', latitude: 49.924786, longitude: 15.403167 },
  { name: 'IHR', latitude: 49.926861, longitude: 36.277639 },
  { name: 'KHR', latitude: 49.928917, longitude: 36.290444 },
  { name: 'IHA', latitude: 49.928917, longitude: 36.302833 },
  { name: 'CF', latitude: 49.932603, longitude: 15.389622 },
  { name: 'F ', latitude: 49.958161, longitude: 15.355347 },
  { name: 'PSZ', latitude: 49.992222, longitude: 18.851667 },
  { name: 'PK', latitude: 50.011092, longitude: 15.813019 },
  { name: 'PK', latitude: 50.011964, longitude: 15.751861 },
  { name: 'P ', latitude: 50.012472, longitude: 15.770506 },
  { name: 'OKG', latitude: 50.065147, longitude: 12.405739 },
  { name: 'CY', latitude: 50.068333, longitude: 31.392222 },
  { name: 'KAK', latitude: 50.076556, longitude: 19.788 },
  { name: 'KRW', latitude: 50.080556, longitude: 19.79525 },
  { name: 'PG', latitude: 50.091253, longitude: 14.276092 },
  { name: 'OKL', latitude: 50.095867, longitude: 14.265608 },
  { name: 'PH', latitude: 50.104239, longitude: 14.230342 },
  { name: 'PA', latitude: 50.105564, longitude: 14.247656 },
  { name: 'RZW', latitude: 50.108583, longitude: 22.041361 },
  { name: 'RSW', latitude: 50.108611, longitude: 22.134028 },
  { name: 'PR', latitude: 50.115564, longitude: 14.268092 },
  { name: 'L ', latitude: 50.119928, longitude: 14.286597 },
  { name: 'KD', latitude: 50.123431, longitude: 14.553389 },
  { name: 'K ', latitude: 50.129683, longitude: 14.568172 },
  { name: 'KD', latitude: 50.152608, longitude: 14.636608 },
  { name: 'SLV', latitude: 50.186806, longitude: 29.570139 },
  { name: 'L ', latitude: 50.195789, longitude: 12.941686 },
  { name: 'KVY', latitude: 50.201219, longitude: 12.925953 },
  { name: 'OLX', latitude: 50.211667, longitude: 20.935556 },
  { name: 'VO', latitude: 50.216031, longitude: 14.408608 },
  { name: 'V ', latitude: 50.220219, longitude: 14.374753 },
  { name: 'YHT', latitude: 50.265111, longitude: 31.794528 },
  { name: 'KE', latitude: 50.282861, longitude: 30.876583 },
  { name: 'BRP', latitude: 50.285694, longitude: 30.900972 },
  { name: 'NO', latitude: 50.287472, longitude: 30.901056 },
  { name: 'E ', latitude: 50.311667, longitude: 30.878667 },
  { name: 'O ', latitude: 50.315694, longitude: 30.903111 },
  { name: 'B ', latitude: 50.362667, longitude: 30.882361 },
  { name: 'NER', latitude: 50.366558, longitude: 14.621364 },
  { name: 'I ', latitude: 50.368889, longitude: 30.907139 },
  { name: 'KB', latitude: 50.386389, longitude: 30.884083 },
  { name: 'BI', latitude: 50.397444, longitude: 30.909111 },
  { name: 'IKI', latitude: 50.399972, longitude: 30.440694 },
  { name: 'ZK', latitude: 50.400417, longitude: 30.419222 },
  { name: 'IKV', latitude: 50.401139, longitude: 30.462361 },
  { name: 'KAX', latitude: 50.477694, longitude: 19.085111 },
  { name: 'W ', latitude: 50.495, longitude: 30.366389 },
  { name: 'GO', latitude: 50.558056, longitude: 30.225 },
  { name: 'O ', latitude: 50.581111, longitude: 30.208056 },
  { name: 'RD', latitude: 50.596972, longitude: 26.172778 },
  { name: 'RVN', latitude: 50.608861, longitude: 26.14525 },
  { name: 'BX', latitude: 50.622472, longitude: 36.655472 },
  { name: 'C ', latitude: 50.625833, longitude: 30.174722 },
  { name: 'BO', latitude: 50.628611, longitude: 30.892222 },
  { name: 'B ', latitude: 50.634417, longitude: 36.619139 },
  { name: 'BL', latitude: 50.636889, longitude: 36.608528 },
  { name: 'JED', latitude: 50.647222, longitude: 20.251111 },
  { name: 'LC', latitude: 50.650833, longitude: 30.155833 },
  { name: 'A ', latitude: 50.652167, longitude: 36.565167 },
  { name: 'SU', latitude: 50.85575, longitude: 34.7285 },
  { name: 'IZB', latitude: 50.8775, longitude: 23.149444 },
  { name: 'WIE', latitude: 50.883056, longitude: 18.495278 },
  { name: 'OKX', latitude: 50.902681, longitude: 15.0319 },
  { name: 'HDO', latitude: 50.92815, longitude: 14.3688 },
  { name: 'KSN', latitude: 50.945972, longitude: 30.977806 },
  { name: 'AGD', latitude: 50.982689, longitude: 12.512283 },
  { name: 'ABU', latitude: 50.993775, longitude: 12.521039 },
  { name: 'DRN', latitude: 51.015547, longitude: 13.598889 },
  { name: 'BAH', latitude: 51.065, longitude: 32.886667 },
  { name: 'WCL', latitude: 51.092667, longitude: 16.918944 },
  { name: 'WRO', latitude: 51.098944, longitude: 16.893083 },
  { name: 'DND', latitude: 51.129503, longitude: 13.757572 },
  { name: 'FS', latitude: 51.193011, longitude: 13.850031 },
  { name: 'SWI', latitude: 51.236167, longitude: 22.685472 },
  { name: 'TBN', latitude: 51.274444, longitude: 17.134167 },
  { name: 'NR', latitude: 51.393833, longitude: 21.241639 },
  { name: 'NRA', latitude: 51.400639, longitude: 21.281889 },
  { name: 'SKZ', latitude: 51.414897, longitude: 12.271278 },
  { name: 'LLD', latitude: 51.414986, longitude: 12.230186 },
  { name: 'ZIG', latitude: 51.434022, longitude: 12.280494 },
  { name: 'LND', latitude: 51.434314, longitude: 12.225706 },
  { name: 'LEG', latitude: 51.436047, longitude: 12.473592 },
  { name: 'NDE', latitude: 51.527833, longitude: 21.954722 },
  { name: 'ND', latitude: 51.5415, longitude: 21.918 },
  { name: 'IDN', latitude: 51.545972, longitude: 21.902639 },
  { name: 'IAS', latitude: 51.546778, longitude: 19.198056 },
  { name: 'TAS', latitude: 51.549944, longitude: 19.178222 },
  { name: 'TDN', latitude: 51.553806, longitude: 21.894472 },
  { name: 'NE', latitude: 51.56075, longitude: 21.866083 },
  { name: 'NPR', latitude: 51.565194, longitude: 20.161806 },
  { name: 'NP', latitude: 51.576583, longitude: 20.124 },
  { name: 'NED', latitude: 51.578778, longitude: 21.817333 },
  { name: 'TTM', latitude: 51.587611, longitude: 20.0935 },
  { name: 'Z ', latitude: 51.667889, longitude: 39.27475 },
  { name: 'ZF', latitude: 51.703139, longitude: 39.317806 },
  { name: 'PE', latitude: 51.718333, longitude: 40.189167 },
  { name: 'PT', latitude: 51.718333, longitude: 40.189444 },
  { name: 'LOD', latitude: 51.723556, longitude: 19.400472 },
  { name: 'WA', latitude: 51.724694, longitude: 36.357694 },
  { name: 'W ', latitude: 51.739028, longitude: 36.323278 },
  { name: 'G ', latitude: 51.761889, longitude: 36.2685 },
  { name: 'HOZ', latitude: 51.766719, longitude: 13.198956 },
  { name: 'HON', latitude: 51.770386, longitude: 13.097911 },
  { name: 'LOZ', latitude: 51.776083, longitude: 19.624806 },
  { name: 'AP', latitude: 51.776944, longitude: 39.301389 },
  { name: 'GE', latitude: 51.777083, longitude: 36.231944 },
  { name: 'A ', latitude: 51.803306, longitude: 39.2515 },
  { name: 'W ', latitude: 51.827667, longitude: 39.206083 },
  { name: 'WR', latitude: 51.843889, longitude: 39.175694 },
  { name: 'LIN', latitude: 51.932778, longitude: 21.158611 },
  { name: 'KRN', latitude: 51.946389, longitude: 20.444722 },
  { name: 'NWT', latitude: 51.993306, longitude: 19.220389 },
  { name: 'NW', latitude: 51.999806, longitude: 19.178139 },
  { name: 'TLY', latitude: 52.001111, longitude: 19.144083 },
  { name: 'KLF', latitude: 52.019353, longitude: 13.563414 },
  { name: 'MZR', latitude: 52.037222, longitude: 29.191667 },
  { name: 'B ', latitude: 52.099611, longitude: 23.927861 },
  { name: 'BRT', latitude: 52.109361, longitude: 23.887694 },
  { name: 'C ', latitude: 52.116639, longitude: 23.865611 },
  { name: 'CMP', latitude: 52.133333, longitude: 16.719167 },
  { name: 'ZLG', latitude: 52.140917, longitude: 15.800694 },
  { name: 'TE', latitude: 52.1525, longitude: 38.268056 },
  { name: 'PNK', latitude: 52.153611, longitude: 26.13 },
  { name: 'SIE', latitude: 52.155278, longitude: 22.200833 },
  { name: 'WA', latitude: 52.156778, longitude: 20.972972 },
  { name: 'OKC', latitude: 52.16975, longitude: 20.960056 },
  { name: 'WAS', latitude: 52.171167, longitude: 20.951639 },
  { name: 'IMM', latitude: 52.194361, longitude: 21.668889 },
  { name: 'TMM', latitude: 52.194444, longitude: 21.665528 },
  { name: 'NF', latitude: 52.195417, longitude: 21.691833 },
  { name: 'WAR', latitude: 52.25925, longitude: 20.657167 },
  { name: 'NK', latitude: 52.322472, longitude: 16.995889 },
  { name: 'IKS', latitude: 52.329139, longitude: 16.979028 },
  { name: 'TKS', latitude: 52.333306, longitude: 16.967667 },
  { name: 'BBI', latitude: 52.342014, longitude: 13.454047 },
  { name: 'NG', latitude: 52.371139, longitude: 17.893389 },
  { name: 'SDD', latitude: 52.374525, longitude: 13.503672 },
  { name: 'NT', latitude: 52.375972, longitude: 17.880972 },
  { name: 'IPW', latitude: 52.37625, longitude: 17.872833 },
  { name: 'TPW', latitude: 52.376972, longitude: 17.850361 },
  { name: 'TS', latitude: 52.389167, longitude: 38.931111 },
  { name: 'FWE', latitude: 52.411331, longitude: 14.130589 },
  { name: 'POZ', latitude: 52.420083, longitude: 16.837556 },
  { name: 'LAW', latitude: 52.422361, longitude: 16.830167 },
  { name: 'MOL', latitude: 52.452389, longitude: 20.677778 },
  { name: 'M ', latitude: 52.519083, longitude: 31.051028 },
  { name: 'GOL', latitude: 52.5335, longitude: 30.997194 },
  { name: 'G ', latitude: 52.534583, longitude: 30.984056 },
  { name: 'I ', latitude: 52.686333, longitude: 39.547944 },
  { name: 'PCK', latitude: 52.711667, longitude: 19.57 },
  { name: 'KY', latitude: 52.714444, longitude: 35.744722 },
  { name: 'D ', latitude: 52.720944, longitude: 39.527083 },
  { name: 'DRE', latitude: 52.816944, longitude: 15.8325 },
  { name: 'TIR', latitude: 52.823861, longitude: 18.325333 },
  { name: 'RUD', latitude: 52.830556, longitude: 23.707222 },
  { name: 'NR', latitude: 52.834806, longitude: 18.34375 },
  { name: 'NRD', latitude: 52.84475, longitude: 18.364889 },
  { name: 'LWB', latitude: 52.910042, longitude: 13.134614 },
  { name: 'UY', latitude: 52.983333, longitude: 36.1 },
  { name: 'DIA', latitude: 53.079444, longitude: 21.175556 },
  { name: 'BYD', latitude: 53.096944, longitude: 17.992139 },
  { name: 'BYZ', latitude: 53.098417, longitude: 17.971722 },
  { name: 'NU', latitude: 53.169222, longitude: 34.186417 },
  { name: 'N ', latitude: 53.1955, longitude: 34.18075 },
  { name: 'O ', latitude: 53.232972, longitude: 34.17125 },
  { name: 'OD', latitude: 53.259861, longitude: 34.164667 },
  { name: 'NA', latitude: 53.383917, longitude: 16.109556 },
  { name: 'IMI', latitude: 53.389583, longitude: 16.0925 },
  { name: 'TMI', latitude: 53.401639, longitude: 16.071611 },
  { name: 'SZY', latitude: 53.474361, longitude: 20.931583 },
  { name: 'CZA', latitude: 53.507778, longitude: 16.301111 },
  { name: 'GRU', latitude: 53.521111, longitude: 18.781389 },
  { name: 'WF', latitude: 53.554056, longitude: 24.056028 },
  { name: 'ZVD', latitude: 53.556028, longitude: 28.130389 },
  { name: 'KU', latitude: 53.573333, longitude: 37.055 },
  { name: 'GD', latitude: 53.573333, longitude: 37.055 },
  { name: 'SZC', latitude: 53.578306, longitude: 14.911611 },
  { name: 'W ', latitude: 53.580083, longitude: 24.054972 },
  { name: 'SCZ', latitude: 53.595417, longitude: 14.8815 },
  { name: 'SYN', latitude: 53.607139, longitude: 21.009222 },
  { name: 'GRD', latitude: 53.607639, longitude: 24.055944 },
  { name: 'G ', latitude: 53.622611, longitude: 24.052667 },
  { name: 'GP', latitude: 53.650278, longitude: 24.050611 },
  { name: 'RV', latitude: 53.681389, longitude: 40.255 },
  { name: 'US', latitude: 53.744722, longitude: 38.496111 },
  { name: 'OSY', latitude: 53.746667, longitude: 20.264167 },
  { name: 'FLD', latitude: 53.762736, longitude: 13.563136 },
  { name: 'TSN', latitude: 53.782889, longitude: 15.852 },
  { name: 'ND', latitude: 53.783417, longitude: 15.858278 },
  { name: 'MRA', latitude: 53.785, longitude: 21.132778 },
  { name: 'ISN', latitude: 53.78875, longitude: 15.83975 },
  { name: 'HC', latitude: 53.8741, longitude: 14.182069 },
  { name: 'IMN', latitude: 53.883194, longitude: 28.068667 },
  { name: 'MNS', latitude: 53.884444, longitude: 28.020639 },
  { name: 'RVS', latitude: 53.914861, longitude: 28.578361 },
  { name: 'KTZ', latitude: 53.915111, longitude: 27.281222 },
  { name: 'LAG', latitude: 53.919861, longitude: 12.286419 },
  { name: 'C ', latitude: 53.940694, longitude: 30.117889 },
  { name: 'MGL', latitude: 53.952806, longitude: 30.095583 },
  { name: 'U ', latitude: 53.971639, longitude: 30.068278 },
  { name: 'TMB', latitude: 54.024944, longitude: 19.137556 },
  { name: 'IMB', latitude: 54.027083, longitude: 19.149778 },
  { name: 'NB', latitude: 54.030306, longitude: 19.168806 },
  { name: 'SUW', latitude: 54.069722, longitude: 22.900278 },
  { name: 'KMI', latitude: 54.201667, longitude: 19.144722 },
  { name: 'TZE', latitude: 54.210556, longitude: 17.073333 },
  { name: 'FE', latitude: 54.240278, longitude: 38.902222 },
  { name: 'OK', latitude: 54.240556, longitude: 38.901944 },
  { name: 'NET', latitude: 54.240639, longitude: 18.745667 },
  { name: 'NE', latitude: 54.245139, longitude: 18.700222 },
  { name: 'DSK', latitude: 54.245833, longitude: 27.480444 },
  { name: 'FV', latitude: 54.345556, longitude: 38.238611 },
  { name: 'WW', latitude: 54.346111, longitude: 38.238611 },
  { name: 'VLK', latitude: 54.350814, longitude: 24.803667 },
  { name: 'GZD', latitude: 54.387806, longitude: 18.425278 },
  { name: 'TDA', latitude: 54.404806, longitude: 16.358167 },
  { name: 'DAR', latitude: 54.410278, longitude: 16.388056 },
  { name: 'S ', latitude: 54.414639, longitude: 16.368639 },
  { name: 'TCW', latitude: 54.414806, longitude: 17.76375 },
  { name: 'ICE', latitude: 54.419306, longitude: 17.77675 },
  { name: 'M ', latitude: 54.421222, longitude: 17.797889 },
  { name: 'ML', latitude: 54.427444, longitude: 17.840972 },
  { name: 'SA', latitude: 54.43225, longitude: 16.396361 },
  { name: 'IRS', latitude: 54.435306, longitude: 30.278861 },
  { name: 'IBS', latitude: 54.445833, longitude: 30.3105 },
  { name: 'ORS', latitude: 54.454056, longitude: 30.339056 },
  { name: 'FR', latitude: 54.532722, longitude: 36.391333 },
  { name: 'NWO', latitude: 54.548583, longitude: 18.567028 },
  { name: 'KLG', latitude: 54.552306, longitude: 36.369944 },
  { name: 'NW', latitude: 54.563917, longitude: 18.542944 },
  { name: 'WI', latitude: 54.564444, longitude: 36.351278 },
  { name: 'IOW', latitude: 54.572917, longitude: 18.525694 },
  { name: 'TOW', latitude: 54.58225, longitude: 18.506444 },
  { name: 'NO', latitude: 54.595222, longitude: 18.491556 },
  { name: 'IN', latitude: 54.608889, longitude: 37.323611 },
  { name: 'NOW', latitude: 54.615889, longitude: 18.457583 },
  { name: 'IAV', latitude: 54.626028, longitude: 25.282167 },
  { name: 'VNO', latitude: 54.636056, longitude: 25.293639 },
  { name: 'IBK', latitude: 54.641389, longitude: 25.292833 },
  { name: 'SML', latitude: 54.675339, longitude: 24.692319 },
  { name: 'WIC', latitude: 54.679444, longitude: 17.675833 },
  { name: 'AVN', latitude: 54.681611, longitude: 25.318694 },
  { name: 'UK', latitude: 54.7375, longitude: 35.221111 },
  { name: 'IFG', latitude: 54.887694, longitude: 20.578167 },
  { name: 'KRD', latitude: 54.893278, longitude: 20.599611 },
  { name: 'IKR', latitude: 54.897639, longitude: 20.6165 },
  { name: 'K ', latitude: 54.901528, longitude: 20.636806 },
  { name: 'KR', latitude: 54.914528, longitude: 20.687111 },
  { name: 'PBZ', latitude: 54.927231, longitude: 25.239725 },
  { name: 'KNA', latitude: 54.960389, longitude: 24.066417 },
  { name: 'IKM', latitude: 54.961889, longitude: 24.069 },
  { name: 'ISE', latitude: 54.963611, longitude: 24.100028 },
  { name: 'KUS', latitude: 54.965722, longitude: 24.121861 },
  { name: 'CDA', latitude: 55.0015, longitude: 12.379211 },
  { name: 'FAU', latitude: 55.028192, longitude: 14.900497 },
  { name: 'IRE', latitude: 55.061719, longitude: 14.770061 },
  { name: 'IAR', latitude: 55.064722, longitude: 14.749167 },
  { name: 'ROE', latitude: 55.065578, longitude: 14.758692 },
  { name: 'SAO', latitude: 55.075278, longitude: 33.307778 },
  { name: 'W ', latitude: 55.115694, longitude: 30.315333 },
  { name: 'VTB', latitude: 55.127889, longitude: 30.359944 },
  { name: 'U ', latitude: 55.135528, longitude: 30.38025 },
  { name: 'GLB', latitude: 55.150833, longitude: 27.669167 },
  { name: 'AO', latitude: 55.152778, longitude: 38.288611 },
  { name: 'DK', latitude: 55.1625, longitude: 37.798889 },
  { name: 'GLW', latitude: 55.162778, longitude: 37.798611 },
  { name: 'DR', latitude: 55.211111, longitude: 37.364444 },
  { name: 'WZ', latitude: 55.221944, longitude: 36.994722 },
  { name: 'IKM', latitude: 55.221944, longitude: 36.995 },
  { name: 'LO', latitude: 55.351111, longitude: 37.526944 },
  { name: 'DO', latitude: 55.386778, longitude: 37.913472 },
  { name: 'DMD', latitude: 55.388306, longitude: 37.911556 },
  { name: 'IDO', latitude: 55.396639, longitude: 37.898083 },
  { name: 'IDM', latitude: 55.417917, longitude: 37.873083 },
  { name: 'DM', latitude: 55.428639, longitude: 37.863083 },
  { name: 'NW', latitude: 55.504167, longitude: 37.436111 },
  { name: 'N ', latitude: 55.506111, longitude: 37.474722 },
  { name: 'P ', latitude: 55.508889, longitude: 37.531111 },
  { name: 'RT', latitude: 55.509667, longitude: 38.235472 },
  { name: 'PS', latitude: 55.511944, longitude: 37.583056 },
  { name: 'SMS', latitude: 55.526167, longitude: 13.38075 },
  { name: 'R ', latitude: 55.530556, longitude: 38.194111 },
  { name: 'SUP', latitude: 55.534528, longitude: 13.379583 },
  { name: 'IRT', latitude: 55.537556, longitude: 38.176889 },
  { name: 'NMS', latitude: 55.545583, longitude: 13.37675 },
  { name: 'RT', latitude: 55.545639, longitude: 38.160306 },
  { name: 'SF', latitude: 55.548333, longitude: 39.996944 },
  { name: 'LP', latitude: 55.552778, longitude: 38.035556 },
  { name: 'GG', latitude: 55.555, longitude: 35.021944 },
  { name: 'FK', latitude: 55.555278, longitude: 35.021944 },
  { name: 'D ', latitude: 55.569861, longitude: 38.116111 },
  { name: 'OE', latitude: 55.578722, longitude: 37.251639 },
  { name: 'GT', latitude: 55.5835, longitude: 37.225472 },
  { name: 'IGT', latitude: 55.586417, longitude: 37.243611 },
  { name: 'SN', latitude: 55.586986, longitude: 12.135178 },
  { name: 'KV', latitude: 55.587753, longitude: 12.119233 },
  { name: 'WNK', latitude: 55.58825, longitude: 37.254222 },
  { name: 'WT', latitude: 55.588889, longitude: 37.785833 },
  { name: 'IWM', latitude: 55.589361, longitude: 37.257444 },
  { name: 'KAS', latitude: 55.590519, longitude: 12.613603 },
  { name: 'CH', latitude: 55.593264, longitude: 12.608292 },
  { name: 'IOB', latitude: 55.595944, longitude: 37.286028 },
  { name: 'OB', latitude: 55.601472, longitude: 37.3055 },
  { name: 'NE', latitude: 55.604617, longitude: 12.637844 },
  { name: 'DM', latitude: 55.604917, longitude: 38.045917 },
  { name: 'KLK', latitude: 55.609731, longitude: 12.633636 },
  { name: 'ITA', latitude: 55.609917, longitude: 37.273278 },
  { name: 'OY', latitude: 55.614192, longitude: 12.661914 },
  { name: 'SX', latitude: 55.619139, longitude: 37.2825 },
  { name: 'KA', latitude: 55.621639, longitude: 12.641625 },
  { name: 'OXS', latitude: 55.622408, longitude: 12.665908 },
  { name: 'RW', latitude: 55.711389, longitude: 38.228056 },
  { name: 'MRN', latitude: 55.711389, longitude: 38.228333 },
  { name: 'KLP', latitude: 55.715556, longitude: 21.243611 },
  { name: 'BEL', latitude: 55.791111, longitude: 12.095833 },
  { name: 'OPH', latitude: 55.825278, longitude: 37.270556 },
  { name: 'KS', latitude: 55.825556, longitude: 37.271111 },
  { name: 'KO', latitude: 55.833333, longitude: 34.859444 },
  { name: 'BG', latitude: 55.833333, longitude: 34.859722 },
  { name: 'BJ', latitude: 55.854167, longitude: 32.938889 },
  { name: 'TU', latitude: 55.854167, longitude: 32.939167 },
  { name: 'UM', latitude: 55.861944, longitude: 36.911111 },
  { name: 'KD', latitude: 55.87125, longitude: 14.078167 },
  { name: 'SAU', latitude: 55.879056, longitude: 23.416389 },
  { name: 'SQQ', latitude: 55.890472, longitude: 23.402389 },
  { name: 'BP', latitude: 55.900556, longitude: 37.6725 },
  { name: 'MK', latitude: 55.928972, longitude: 14.085194 },
  { name: 'DGP', latitude: 55.947222, longitude: 26.675556 },
  { name: 'PN', latitude: 55.956417, longitude: 21.087083 },
  { name: 'PLG', latitude: 55.956528, longitude: 21.087056 },
  { name: 'NL', latitude: 55.958278, longitude: 37.327778 },
  { name: 'MR', latitude: 55.959722, longitude: 37.322583 },
  { name: 'N ', latitude: 55.965028, longitude: 37.372556 },
  { name: 'M ', latitude: 55.967639, longitude: 37.372583 },
  { name: 'IMR', latitude: 55.9695, longitude: 37.392222 },
  { name: 'IAD', latitude: 55.976111, longitude: 37.436667 },
  { name: 'B ', latitude: 55.978417, longitude: 37.461639 },
  { name: 'IAN', latitude: 55.978833, longitude: 21.09825 },
  { name: 'MR', latitude: 55.979, longitude: 37.328 },
  { name: 'A ', latitude: 55.980556, longitude: 37.458972 },
  { name: 'IMA', latitude: 55.982778, longitude: 37.333639 },
  { name: 'AR', latitude: 55.983333, longitude: 36.799167 },
  { name: 'BW', latitude: 55.984722, longitude: 37.506556 },
  { name: 'AD', latitude: 55.986333, longitude: 37.5 },
  { name: 'IBR', latitude: 55.988722, longitude: 37.373306 },
  { name: 'OEM', latitude: 55.98975, longitude: 14.100861 },
  { name: 'ER', latitude: 55.990361, longitude: 37.391694 },
  { name: 'MF', latitude: 56.026111, longitude: 39.634167 },
  { name: 'DA', latitude: 56.081417, longitude: 13.206722 },
  { name: 'LJU', latitude: 56.083111, longitude: 13.20125 },
  { name: 'WR', latitude: 56.123611, longitude: 40.316389 },
  { name: 'SVD', latitude: 56.168917, longitude: 12.573694 },
  { name: 'LF', latitude: 56.195111, longitude: 15.247167 },
  { name: 'RON', latitude: 56.25425, longitude: 15.264306 },
  { name: 'NDF', latitude: 56.25975, longitude: 15.261417 },
  { name: 'VA', latitude: 56.263611, longitude: 40.599444 },
  { name: 'AH', latitude: 56.266167, longitude: 12.90075 },
  { name: 'MBL', latitude: 56.272972, longitude: 16.409028 },
  { name: 'DF', latitude: 56.274028, longitude: 15.265444 },
  { name: 'DB', latitude: 56.296556, longitude: 12.84925 },
  { name: 'KN', latitude: 56.301944, longitude: 37.710833 },
  { name: 'IKO', latitude: 56.302222, longitude: 37.711389 },
  { name: 'LP', latitude: 56.339778, longitude: 15.286028 },
  { name: 'LB', latitude: 56.346556, longitude: 12.766361 },
  { name: 'SWO', latitude: 56.367778, longitude: 37.430833 },
  { name: 'SW', latitude: 56.368056, longitude: 37.431111 },
  { name: 'WKL', latitude: 56.379444, longitude: 30.606667 },
  { name: 'JVL', latitude: 56.504861, longitude: 23.928222 },
  { name: 'LEP', latitude: 56.512778, longitude: 21.08475 },
  { name: 'SC', latitude: 56.517222, longitude: 34.936389 },
  { name: 'AJ', latitude: 56.5175, longitude: 34.936389 },
  { name: 'LPJ', latitude: 56.520611, longitude: 21.106806 },
  { name: 'RDB', latitude: 56.639278, longitude: 21.892778 },
  { name: 'MF', latitude: 56.652472, longitude: 12.808389 },
  { name: 'KAL', latitude: 56.685333, longitude: 16.284111 },
  { name: 'KM', latitude: 56.747417, longitude: 16.227778 },
  { name: 'LBN', latitude: 56.773333, longitude: 26.352222 },
  { name: 'LDE', latitude: 56.787361, longitude: 24.853833 },
  { name: 'LT', latitude: 56.797028, longitude: 12.842278 },
  { name: 'VX', latitude: 56.85725, longitude: 14.697611 },
  { name: 'IRP', latitude: 56.909222, longitude: 23.966833 },
  { name: 'RIA', latitude: 56.920861, longitude: 23.965194 },
  { name: 'TUK', latitude: 56.930583, longitude: 23.239972 },
  { name: 'D ', latitude: 56.931222, longitude: 40.965972 },
  { name: 'IRV', latitude: 56.932306, longitude: 23.970639 },
  { name: 'MX', latitude: 56.935278, longitude: 14.732694 },
  { name: 'A ', latitude: 56.953222, longitude: 40.899639 },
  { name: 'AL', latitude: 56.967639, longitude: 40.855972 },
  { name: 'JX', latitude: 56.996111, longitude: 14.757333 },
  { name: 'LONLA', latitude: 48.34, longitude: 22.319722 },
]
