/*
    node_js
    3/17/2021 3:56 PM
    by Oleksandr
*/

/*
    node_js
    3/17/2021 2:19 PM
    by Oleksandr
*/

import '../../CWP.css'
import React, { useMemo } from 'react'
import { useCwpContext } from '../../../../contexts/CwpContext'
import { getMachfromCAS } from '../../../../calc/geometry/airspeedConverter'

const DataMenu = ({ isCon }) => {
  const {
    callsignMenuFlight,
    tickValue,
    showFlightMenu,
    typeOfFlightMenu,
    startDate,
    dispatchFlightClearance,
  } = useCwpContext()

  const calcInputStep = () => {
    switch (typeOfFlightMenu) {
      case 'mach':
        return 0.01
      case 'spd':
        return 5
      case 'sfl':
      case 'cfl':
        return 10
      default:
        return 1
    }
  }

  const calcInputMinMax = () => {
    switch (typeOfFlightMenu) {
      case 'mach':
        return [0.35, 1.0]
      case 'spd':
        return [10, 390]
      case 'sfl':
      case 'cfl':
        return [25, 660]
      case 'hdg':
        return [0, 360]
      default:
        return [0, 999]
    }
  }

  const calcSelectValues = useMemo(() => {
    const result = []
    switch (typeOfFlightMenu) {
      case 'mach':
        for (let i = 0.6; i < 1.0; i += 0.01) {
          result.push(parseFloat(i.toFixed(2)))
        }
        return result
      case 'spd':
        for (let i = 180; i <= 390; i += 10) {
          result.push(i)
        }
        return result
      case 'sfl':
      case 'cfl':
        for (let i = 660; i >= 30; i -= 10) {
          result.push(i)
        }
        result.push(25)
        return result
      case 'hdg':
        for (let i = 10; i <= 360; i += 10) {
          result.push(i)
        }
        return result
    }
  }, [typeOfFlightMenu])

  const filterSpeed = (spd, isMach = false) => {
    if (!callsignMenuFlight.flight.flight[index].maxS) {
      return true
    }
    const maxSpd = isMach
      ? getMachfromCAS(
          callsignMenuFlight.flight.flight[index].maxS,
          callsignMenuFlight.flight.flight[index].a,
        )
      : callsignMenuFlight.flight.flight[index].maxS
    const minSpd = isMach
      ? getMachfromCAS(
          callsignMenuFlight.flight.flight[index].minS,
          callsignMenuFlight.flight.flight[index].a,
        )
      : callsignMenuFlight.flight.flight[index].minS
    return spd <= maxSpd && spd >= minSpd
  }

  const renderOptions = () => {
    switch (typeOfFlightMenu) {
      case 'mach':
        return calcSelectValues
          .filter((spd) => filterSpeed(spd, true))
          .map((spd) => (
            <option value={spd} key={spd}>
              {spd}
            </option>
          ))
      case 'spd':
        return calcSelectValues
          .filter((spd) => filterSpeed(spd))
          .map((spd) => (
            <option value={spd} key={spd}>
              {spd}
            </option>
          ))
      case 'sfl':
      case 'cfl':
        return calcSelectValues
          .filter((cfl) => {
            if (!callsignMenuFlight.flight.flight[index].maxFL) {
              return true
            }
            return cfl <= callsignMenuFlight.flight.flight[index].maxFL
          })
          .map((cfl) => (
            <option value={cfl} key={cfl}>
              {cfl === 0 ? 'ILS' : cfl < 100 ? `0${cfl}` : cfl}
            </option>
          ))
      case 'hdg':
        return calcSelectValues.map((hdg) => (
          <option value={hdg} key={hdg}>
            {hdg < 100 ? `0${hdg}` : hdg}
          </option>
        ))
    }
  }

  const getDefaultValue = () => {
    switch (typeOfFlightMenu) {
      case 'mach':
        return calcSelectValues.filter(
          (spd) =>
            parseFloat(
              callsignMenuFlight.flight.flight[index].calcM.toFixed(2),
            ) === spd,
        )[0]
      case 'spd':
        return calcSelectValues.filter(
          (spd) => callsignMenuFlight.flight.flight[index].s === spd,
        )[0]
      case 'sfl':
      case 'cfl':
        return calcSelectValues.filter(
          (cfl) => callsignMenuFlight.flight.flight[index].sA / 100 === cfl,
        )[0]
      case 'hdg':
        return calcSelectValues.filter(
          (hdg) => callsignMenuFlight.flight.flight[index].sH === hdg,
        )[0]
    }
  }

  const index =
    isCon || !callsignMenuFlight
      ? 0
      : tickValue + callsignMenuFlight.flight.timeShift

  return (
    callsignMenuFlight &&
    callsignMenuFlight.flight.flight[index] && (
      <div
        className={'callsignMenu'}
        style={{
          left: callsignMenuFlight.clientX + 'px',
          top: callsignMenuFlight.clientY + 'px',
        }}
      >
        <span>{callsignMenuFlight.flight.c}</span>
        <br />
        <select
          style={{ width: '100%' }}
          name={typeOfFlightMenu}
          size="8"
          onInput={(e) => {
            dispatchFlightClearance({
              type: typeOfFlightMenu,
              value: e.target.value,
              flight: callsignMenuFlight.flight,
              index,
              startDate,
            })
            showFlightMenu(null)
          }}
          defaultValue={getDefaultValue()}
        >
          {renderOptions()}
        </select>
        <span
          style={{ width: '100%' }}
          className={'buttonSDD'}
          onClick={() => {
            dispatchFlightClearance({
              type: 'std',
              flight: callsignMenuFlight.flight,
              index,
              startDate,
            })
            showFlightMenu(null)
          }}
        >
          STD
        </span>
        <input
          type="number"
          autoFocus
          style={{ width: '100%' }}
          step={calcInputStep()}
          min={calcInputMinMax()[0]}
          max={calcInputMinMax()[1]}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (!e.target.value) return
              dispatchFlightClearance({
                type: typeOfFlightMenu,
                value: e.target.value,
                flight: callsignMenuFlight.flight,
                index,
                startDate,
              })
              showFlightMenu(null)
            }
          }}
        />
      </div>
    )
  )
}

export default DataMenu
