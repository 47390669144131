/*
    node_js
    3/16/2021 7:57 AM
    by Oleksandr
*/

import Window from './Window'
import { Editor } from 'react-draft-wysiwyg'
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useCwpContext } from '../../../../contexts/CwpContext'

const ExerciseDescriptionWindow = ({ isEpp }) => {
  const { exerciseDescription, setExerciseDescription, setPttWindows } =
    useCwpContext()

  const onEditorStateChange = (editorState) => {
    setExerciseDescription(editorState)
  }

  return (
    <Window
      width={850}
      height={'auto'}
      label="Exercise Description"
      toggleWindow={() =>
        setPttWindows((prev) => ({ ...prev, exerciseDescriptionWindow: false }))
      }
    >
      <div className="editor">
        <Editor
          editorStyle={{
            border: '1px solid',
            overflowY: 'scroll',
            maxHeight: '500px',
            margin: '5px',
            background: 'grey',
          }}
          editorState={exerciseDescription}
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
          toolbarHidden={!isEpp}
          readOnly={!isEpp}
        />
      </div>
    </Window>
  )
}

export default ExerciseDescriptionWindow
