/*
    node_js
    3/23/2021 2:02 AM
    by Oleksandr
*/

import Window from '../Window'
import React from 'react'
import { useCwpContext } from '../../../../../contexts/CwpContext'
import { toggleMap } from '../../../../../reducers/mapSlice'
import { useSelector, useDispatch } from 'react-redux'

const MapWindow = () => {
  const { setPttWindows, map, setMap } = useCwpContext()
  const maps = useSelector((state) => state.map)
  const pointsIsVisible = useSelector((state) => state.map[0].isVisible)
  const dispatch = useDispatch()

  return (
    <>
      <Window
        label="MAP #1"
        width={'max-content'}
        dXY={{ dx: 280, dy: 80 }}
        toggleWindow={() =>
          setPttWindows((prev) => ({ ...prev, mapWindow: false }))
        }
      >
        <div
          key="map1"
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            gridGap: '7px',
            color: 'white',
            textAlign: 'left',
            padding: '5px',
          }}
        >
          {maps.map((map) => {
            console.log(map)
            return (
              <>
                {map.mapId}
                <label htmlFor={map.id}>{map.id}</label>
                <input
                  type="checkbox"
                  defaultChecked={map.isVisible ? 'checked' : ''}
                  name={map.id}
                  value={map.id}
                  onClick={(e) =>
                    dispatch(
                      toggleMap({
                        mapId: e.target.name,
                      }),
                    )
                  }
                />{' '}
              </>
            )
          })}
        </div>
      </Window>
    </>
  )
}

export default MapWindow
