/*
    node_js
    3/13/2021 5:35 PM
    by Oleksandr
*/

import React from 'react'
import { calcApproach, calcDeparture } from '../../../../../calc/calc'

const HistFlightsList = (props) => {
  const {
    historicalFlights,
    columns,
    setFlights,
    flights,
    runways,
    updateFlightForEpp,
  } = props

  const addFlight = (e) => {
    const flight = historicalFlights.filter(
      (flt) => flt.id === e.target.dataset.fltid,
    )[0]
    calcDeparture(flight, runways)
    calcApproach(flight, runways)
    updateFlightForEpp(flight, true)

    flight.dx = 0
    flight.dy = 0
    setFlights([...flights, flight])
  }

  return (
    <tbody>
      {historicalFlights?.length > 0 &&
        historicalFlights.map((row, index) => (
          <tr className="sddTableTr" key={`row-${index}`}>
            {columns.map((column, index) => {
              return (
                <td className="sddTableTd" key={`col-${index}`}>
                  {row[column]}
                </td>
              )
            })}
            <td className="sddTableTd">
              <span
                className="buttonSDD"
                data-fltid={row.id}
                onClick={addFlight}
              >
                Add
              </span>
            </td>
          </tr>
        ))}
    </tbody>
  )
}

export default HistFlightsList
