/*
    node_js
    2/1/2021 8:05 PM
    by Oleksandr
*/

import './AccLabel.css'
import { useEffect, useRef, useState } from 'react'
import { calcFlight } from '../../../../calc/calc'
import { useDrag } from 'react-use-gesture'
import { SELECTED_COLOR } from '../colors/colors'
import Field from './Field'
import { onLabelMove } from '../target/ConnectingLine'
import { useKey } from '../../../../utils/hooks/useKey'

const AccLabel = (props) => {
  const {
    color,
    callsignColor,
    addRbl,
    index,
    left,
    top,
    selectedFlight,
    fltId,
    flight,
    updateFlights,
    isStca,
    isPlt,
    isEpp,
    assumeFlight,
    showCallsignMenu,
    showFlightMenu,
    addTrackPlan,
    isCon,
    conAssumeFlight,
    startDate,
    isMtcd,
    labelSettings,
    setPttWindows,
  } = props

  const randomDelayConst = 0
  const connectingLine = document.getElementById('connectingLine' + fltId)
  const label = useRef()
  const [colorBorder, setColorBorder] = useState('rgba(105,170,170, 0)')
  const [openRON, setOpenRON] = useState(false)
  const [{ dx, dy }, setDxDy] = useState({ dx: flight.dx, dy: flight.dy })
  const [isHover, setIsHover] = useState(false)

  const shift = useKey('shift')
  const f9 = useKey('F9')
  const enter = useKey('enter')
  const control = useKey('Control')

  useEffect(() => {
    if (isHover && !shift && !f9) {
      addRbl(fltId, null)
    }
  }, [shift, f9])

  useEffect(() => {
    if (
      (isPlt || isEpp) &&
      isHover &&
      !enter &&
      !flight.flight[index].isAssumed
    ) {
      assumeFlight(flight, index)
      ///TODO create redraw flights or flight
    }
    if (isCon && isHover && !enter && !flight.isAssumed) {
      conAssumeFlight(flight, index)
      updateFlights()
    }
  }, [enter])

  useEffect(() => {
    if (isHover && !control) {
      setPttWindows((prev) => ({
        ...prev,
        options: { ...prev.options, fpActionWindow: { flightId: flight.id } },
        fpActionWindow: true,
      }))
      console.log('control key', flight.c, flight)
    }
  }, [control])

  const bind = useDrag(({ buttons, delta: [deltaX, deltaY], last }) => {
    if (buttons === 2) {
      setDxDy({ dx: dx + deltaX, dy: dy + deltaY })
      if (label.current)
        onLabelMove(
          {
            left: parseInt(left) + dx,
            top: parseInt(top) + dy,
            clientHeight: label.current.clientHeight,
            clientWidth: label.current.clientWidth,
          },
          { left, top },
          connectingLine,
        )
    }
  })

  useEffect(() => {
    isStca
      ? setColorBorder('rgb(248,2,2)')
      : selectedFlight === fltId || isHover
      ? setColorBorder(SELECTED_COLOR)
      : setColorBorder('rgba(105,170,170, 0)')
  }, [selectedFlight, isHover])
  useEffect(() => {
    isStca
      ? setColorBorder('rgb(248,2,2)')
      : setColorBorder('rgba(105,170,170, 0)')
  }, [isStca])

  const tod = flight.flight[index].tod
    ? [<Field text="TOD" style={{ color: 'yellow' }} key={'tod' + index} />]
    : null
  const mtcd = isMtcd
    ? [<Field text="MC" style={{ color: 'red' }} key={'mc' + index} />]
    : null
  const emrg =
    flight.flight[index].sq === '7700' ||
    flight.flight[index].sq === '7600' ||
    flight.flight[index].sq === '7500' ? (
      <Field
        text={flight.flight[index].sq}
        style={{ color: 'red' }}
        key={'em' + index}
      />
    ) : null

  const getAltitudeText = (alt) =>
    alt === 0 ? 'ILS' : alt < 100 ? `0${alt}` : alt

  return (
    <div
      ref={label}
      {...bind()}
      className="AccLabel"
      id={'acclabel_' + fltId}
      onMouseEnter={() => {
        setIsHover(true)
      }}
      onMouseLeave={() => {
        setIsHover(false)
      }}
      style={{
        left: parseInt(left) + dx + 'px',
        top: parseInt(top) + dy + 'px',
        color: color,
        border: '1px solid ' + colorBorder,
        fontSize: labelSettings.fontSize + 'px',
        zIndex: isHover ? 21 : 20,
      }}
    >
      {emrg} {tod} {mtcd} {(emrg || tod || mtcd) && <br />}
      <Field
        text={flight.flight[index].c}
        style={{ color: callsignColor }}
        onMouseUp={(e) => {
          if (e.button === 0) {
            showCallsignMenu(flight, e.clientX, e.clientY)
          }
          if (e.button === 1) {
            e.nativeEvent.stopPropagation()
            e.nativeEvent.preventDefault()
            addRbl(fltId, null)
          }
        }}
      />
      {(isHover || selectedFlight === fltId) && (
        <Field
          text={flight.flight[index].sq}
          onClick={() => {
            if (!isPlt) return
            let promptSquawk = window.prompt('New Squawk:')
            if (promptSquawk === null) return
            let randDelay = Math.round(Math.random() * randomDelayConst)
            flight.flight[index + randDelay].sq = promptSquawk
            calcFlight(flight, index + randDelay, startDate.getTime())
          }}
        />
      )}
      {isPlt && flight.flight[index].isIAA && <span>ILS</span>}
      <br />
      <Field text={getAltitudeText(Math.round(flight.flight[index].a / 100))} />
      <span style={{ paddingBottom: 2 }}>
        {Math.abs(flight.flight[index].a - flight.flight[index].sA) >= 100
          ? flight.flight[index].isClimb
            ? '\u2191'
            : flight.flight[index].isDesc
            ? '\u2193'
            : ''
          : ''}
      </span>
      {(isPlt || isEpp) && (
        <Field
          text={getAltitudeText(Math.round(flight.flight[index].sA / 100))}
          style={{
            opacity: isHover
              ? 1
              : Math.abs(flight.flight[index].a - flight.flight[index].sA) >=
                100
              ? 1
              : 0,
          }}
          onClick={(e) => {
            if (!isPlt) return
            showFlightMenu(flight, e.clientX, e.clientY, 'sfl')
          }}
        />
      )}
      {isCon && (
        <Field
          text={flight.cfl}
          style={{
            opacity:
              isHover ||
              Math.abs(flight.flight[index].a - flight.cfl * 100) >= 100
                ? 1
                : 0,
          }}
          onClick={(e) => {
            if (!isCon) return
            showFlightMenu(flight, e.clientX, e.clientY, 'cfl')
          }}
        />
      )}
      <Field
        text={flight.flight[index].xfl}
        style={{
          opacity:
            isHover ||
            ((isEpp || isPlt) &&
              Math.abs(
                flight.flight[index].sA - flight.flight[index].xfl * 100,
              ) >= 100) ||
            (isCon && Math.abs(flight.cfl - flight.flight[index].xfl) >= 1)
              ? 1
              : 0,
        }}
      />
      <br />
      {(isHover || selectedFlight === fltId) && (
        <>
          <Field text={Math.round(flight.flight[index].t)} />
          <Field
            text={flight.flight[index].isOO}
            // onMouseUp={(e) => {
            //   flight.flight[index].isOO = true
            //   flight.flight[index].isOSt = false
            //   flight.flight[index].oS = e.button === 0 ? 'L' : 'R'
            //   flight.flight[index].sH = flight.flight[index].h
            //   calcFlight(flight, index, startDate.getTime())
            // }}
            onMouseUp={() => {
              const coords = window.prompt('Coords:')
              const [lat, lon] = coords.split(':')
              const tmpText = flight.fpl[10]
                .map((p, i) => p.name + ':' + i)
                .filter((p, i) => i >= flight.flight[index].nP)
                .join('\n')
              const nextPointIndex = window.prompt(tmpText)
              flight.fpl[10].splice(
                flight.flight[index].nP,
                nextPointIndex - flight.flight[index].nP,
              )
              flight.fpl[10].splice(flight.flight[index].nP, 0, {
                dist: 0,
                heading: 0,
                latitude: flight.flight[index].cP['latitude'],
                longitude: flight.flight[index].cP['longitude'],
                name: `${parseInt(
                  flight.flight[index].cP['latitude'],
                )}N0${parseInt(flight.flight[index].cP['longitude'])}E`,
              })
              flight.flight[index].nP++
              flight.fpl[10].splice(flight.flight[index].nP, 0, {
                dist: 0,
                heading: 0,
                latitude: lat,
                longitude: lon,
                name: `${lat}N0${lon}E`,
              })

              // flight.flight[index].nP++
              calcFlight(flight, index, startDate.getTime())
            }}
          />
          <Field
            text={flight.flight[index].nPN}
            onClick={(e) => {
              if (!isPlt) return
              showFlightMenu(flight, e.clientX, e.clientY, 'dct')
              // setOpenRON(true);
            }}
            onMouseUp={(e) => {
              if (e.button === 2) {
                addTrackPlan(fltId)
              }
            }}
          />
          <span>{flight.fpl[3].substr(0, 4)}</span>
          <br />
        </>
      )}
      {(isHover || selectedFlight === fltId) && !isCon && (
        <>
          <Field
            text={
              'h' +
              Math.round(flight.flight[index].h) +
              (Math.round(flight.flight[index].h) ===
              Math.round(flight.flight[index].sH)
                ? ''
                : '>' + parseInt(flight.flight[index].sH))
            }
            onClick={(e) => {
              if (!isPlt) return
              showFlightMenu(flight, e.clientX, e.clientY, 'hdg')
            }}
            onMouseUp={(e) => {
              if (e.button === 2) {
                addTrackPlan(fltId)
              }
            }}
          />
          <Field text={'r' + parseInt(flight.flight[index].r * 1.5) * 10} />
          {flight.flight[index].a < 25000 ? (
            <Field
              text={
                's' +
                Math.round(flight.flight[index].s) +
                (Math.abs(flight.flight[index].s - flight.flight[index].sS) > 1
                  ? '>' + Math.round(flight.flight[index].sS)
                  : '')
              }
              onClick={(e) => {
                if (!isPlt) return
                showFlightMenu(flight, e.clientX, e.clientY, 'spd')
              }}
            />
          ) : (
            <Field
              text={
                'M' +
                parseFloat(flight.flight[index].calcM).toFixed(2) +
                (Math.abs(flight.flight[index].M - flight.flight[index].sM) >=
                0.01
                  ? flight.flight[index].M
                  : '')
              }
              onClick={(e) => {
                if (!isPlt) return
                showFlightMenu(flight, e.clientX, e.clientY, 'mach')
              }}
            />
          )}
          <br />
        </>
      )}
    </div>
  )
}
export default AccLabel
