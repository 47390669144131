import { xmlToLat, xmlToLon } from './convertors'

export const getPoints = (points, projection) =>
  points
    .map((point) => {
      const result = []
      const coordinates = projection([point.longitude, point.latitude])
      result.push(
        <use
          xlinkHref={'#myDot'}
          x={coordinates[0] - 2}
          y={coordinates[1] - 2}
          stroke={'darkgrey'}
          fill={'darkgrey'}
          key={'use' + point.name}
        />,
        <text
          style={{ fontSize: '10px' }}
          fill={'darkgrey'}
          x={coordinates[0] + 3}
          y={coordinates[1]}
          key={'text' + point.name}
        >
          {point.name}
        </text>,
      )
      return result
    })
    .flat()

export const getStarLines = (stars, projection) =>
  Object.entries(stars)
    .map(([name, star]) => {
      const editedStar = []
      for (let i = 0; i < star.length - 1; i++) {
        const currentPoint = star[i]
        const nextPoint = star[i + 1]

        if (currentPoint.lat === '#' || nextPoint.lat === '#') {
          continue
        }
        if (currentPoint.first === '#' || nextPoint.first === '#') {
          const coordFirst = projection([currentPoint.lon, currentPoint.lat])
          editedStar.push(
            <use
              xlinkHref={'#myDot'}
              x={coordFirst[0] - 2}
              y={coordFirst[1] - 2}
              stroke={'#72b4ff'}
              fill={'#72b4ff'}
              key={'use' + name + i}
            />,
          )
          continue
        }

        let coord1 = projection([currentPoint.lon, currentPoint.lat])
        let coord2 = projection([nextPoint.lon, nextPoint.lat])

        editedStar.push(
          <line
            x1={coord1[0]}
            y1={coord1[1]}
            x2={coord2[0]}
            y2={coord2[1]}
            stroke={'#72b4ff'}
            key={'line' + name + i}
          />,
        )
        editedStar.push(
          <use
            xlinkHref={'#myDot'}
            x={coord2[0] - 2}
            y={coord2[1] - 2}
            stroke={'#72b4ff'}
            fill={'#72b4ff'}
            key={'use' + name + i}
          />,
        )
      }
      return { [name]: editedStar }
    })
    .reduce((acc, current) => {
      return { ...acc, ...current }
    }, {})

export const getIlsLines = (ils, projection) =>
  Object.entries(ils)
    .map(([name, line]) => {
      const editedLine = []
      for (let i = 0; i < line.length - 1; i++) {
        const currentPoint = line[i]
        const nextPoint = line[i + 1]

        if (currentPoint.lat === '#' || nextPoint.lat === '#') continue

        const coord1 = projection([currentPoint.lon, currentPoint.lat])
        const coord2 = projection([nextPoint.lon, nextPoint.lat])

        editedLine.push(
          <line
            x1={coord1[0]}
            y1={coord1[1]}
            x2={coord2[0]}
            y2={coord2[1]}
            stroke={'black'}
            key={name + i}
          />,
        )
      }
      return { [name]: editedLine }
    })
    .reduce((acc, current) => {
      return { ...acc, ...current }
    }, {})

export const getFrequencies = (frequencies, projection) =>
  frequencies.map((frequency, index) => {
    const coordinate = projection([frequency.longitude, frequency.latitude])
    return (
      <text
        key={index}
        style={{ fontSize: '10px' }}
        fill="#64c8ff"
        x={coordinate[0] + 3}
        y={coordinate[1]}
      >
        {frequency.name.split('\n').map((text) => (
          <tspan dy="1.2em" x={coordinate[0] + 3} key={text}>
            {text}
          </tspan>
        ))}
      </text>
    )
  })
