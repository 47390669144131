/*
    node_js
    3/5/2021 7:07 PM
    by Oleksandr
*/
import '../../CWP.css'
import React from 'react'
import { useCwpContext } from '../../../../contexts/CwpContext'

const CallsignMenu = ({ isCon, isPlt }) => {
  const {
    callsignMenuFlight,
    assumeFlight,
    conAssumeFlight,
    tickValue,
    showCallsignMenu,
    runways,
    startDate,
    dispatchFlightClearance,
  } = useCwpContext()

  const index = isCon ? 0 : tickValue + callsignMenuFlight.flight.timeShift

  return (
    <div
      className={'callsignMenu'}
      style={{
        left: callsignMenuFlight.clientX + 'px',
        top: callsignMenuFlight.clientY + 'px',
      }}
    >
      {callsignMenuFlight.flight.c}
      <span
        className={'buttonSDD'}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (isPlt) {
            assumeFlight(callsignMenuFlight.flight, index)
          }

          if (isCon) conAssumeFlight(callsignMenuFlight.flight)
          showCallsignMenu(null)
          // updateFlights();
        }}
      >
        {((isPlt && callsignMenuFlight.flight.flight[index].isAdvanced) ||
          (isCon && callsignMenuFlight.flight.isAdvanced)) &&
          'Assume'}
        {((isPlt && callsignMenuFlight.flight.flight[index].isAssumed) ||
          (isCon && callsignMenuFlight.flight.isAssumed)) &&
          'Decontrol'}
        {((isPlt &&
          !callsignMenuFlight.flight.flight[index].isAdvanced &&
          !callsignMenuFlight.flight.flight[index].isAssumed) ||
          (isCon &&
            !callsignMenuFlight.flight.isAdvanced &&
            !callsignMenuFlight.flight.isAssumed)) &&
          'Force Assume'}
      </span>

      {isPlt && callsignMenuFlight.flight.ades !== 'UKLL' && (
        <>
          <span
            className={'buttonSDD'}
            onClick={() => {
              dispatchFlightClearance({
                type: 'ukll',
                value: runways,
                flight: callsignMenuFlight.flight,
                index,
                startDate,
              })
              showCallsignMenu(null)
            }}
          >
            Divert to UKLL
          </span>
        </>
      )}
      {callsignMenuFlight.flight.flight[index].isIAA &&
        !callsignMenuFlight.flight.flight[index].isGA && (
          <span
            className={'buttonSDD'}
            onClick={() => {
              dispatchFlightClearance({
                type: 'ga',
                value: runways,
                flight: callsignMenuFlight.flight,
                index,
                startDate,
              })
              showCallsignMenu(null)
            }}
          >
            Go Around
          </span>
        )}
      <span
        className={'buttonSDD'}
        onClick={() => {
          showCallsignMenu(null)
        }}
      >
        Close
      </span>
    </div>
  )
}

export default CallsignMenu
