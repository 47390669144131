/*
    node_js
    1/31/2021 9:40 PM
    by Oleksandr
*/

import TrackLayer from '../graphics/layers/trackLayer/TrackLayer'
import MapLayer from '../graphics/layers/MapLayer'
import AswWindowLayer from '../graphics/layers/AswWindowLayer'

export function ASW(props) {
  return (
    <>
      <MapLayer {...props} />
      <TrackLayer {...props} />
      <AswWindowLayer {...props} />
    </>
  )
}
