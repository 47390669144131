/*
    node_js
    1/31/2021 7:14 PM
    by Oleksandr
*/
export const uklv = [
  { lat: 47.7333333333333, lon: 24.8833333333333 },
  { lat: 47.7355, lon: 25.0475 },
  { lat: 47.8666666666667, lon: 25.1666666666667 },
  { lat: 47.9188333333333, lon: 25.6533333333333 },
  { lat: 47.9321666666667, lon: 25.7166666666667 },
  { lat: 47.9405, lon: 25.7775 },
  { lat: 47.9516666666667, lon: 25.9583333333333 },
  { lat: 48.2205, lon: 26.4696666666667 },
  { lat: 48.2505, lon: 26.6236666666667 },
  { lat: 48.3228333333333, lon: 26.7095 },
  { lat: 48.4146666666667, lon: 26.8128333333333 },
  { lat: 48.418, lon: 27.3041666666667 },
  { lat: 48.4521666666667, lon: 27.7216666666667 },
  { lat: 48.4216666666667, lon: 27.828 },
  { lat: 48.4155, lon: 27.838 },
  { lat: 48.97, lon: 27.8416666666667 },
  { lat: 49.05, lon: 27.8383333333333 },
  { lat: 49.15, lon: 27.845 },
  { lat: 49.7616666666667, lon: 27.85 },
  { lat: 50.182, lon: 27.0438333333333 },
  { lat: 50.5416666666667, lon: 27.255 },
  { lat: 50.85, lon: 27.4333333333333 },
  { lat: 51.3761666666667, lon: 27.7458333333333 },
  { lat: 51.3833333333333, lon: 27.75 },
  { lat: 51.4713333333333, lon: 27.7596666666667 },
  { lat: 51.4796666666667, lon: 27.6995 },
  { lat: 51.7663333333333, lon: 26.7983333333333 },
  { lat: 51.847, lon: 26.3958333333333 },
  { lat: 51.893, lon: 26.1213333333333 },
  { lat: 51.9196666666667, lon: 25.8863333333333 },
  { lat: 51.8833333333333, lon: 24.9666666666667 },
  { lat: 51.9013333333333, lon: 24.688 },
  { lat: 51.5, lon: 23.6166666666667 },
  { lat: 51.3028333333333, lon: 23.6458333333333 },
  { lat: 50.8333333333333, lon: 24.1 },
  { lat: 50.668, lon: 24.0503333333333 },
  { lat: 50.5033333333333, lon: 24.065 },
  { lat: 50.4041666666667, lon: 23.8255 },
  { lat: 50.2211666666667, lon: 23.4853333333333 },
  { lat: 50.0216666666667, lon: 23.212 },
  { lat: 49.9658333333333, lon: 23.1383333333333 },
  { lat: 49.8883333333333, lon: 23.0583333333333 },
  { lat: 49.8088333333333, lon: 22.94 },
  { lat: 49.5678333333333, lon: 22.6545 },
  { lat: 49.21, lon: 22.7366666666667 },
  { lat: 49.0888333333333, lon: 22.8333333333333 },
  { lat: 49, lon: 22.7666666666667 },
  { lat: 49.0888333333333, lon: 22.5666666666667 },
  { lat: 48.9503333333333, lon: 22.4486666666667 },
  { lat: 48.7716666666667, lon: 22.3505 },
  { lat: 48.6516666666667, lon: 22.2613333333333 },
  { lat: 48.6346666666667, lon: 22.2458333333333 },
  { lat: 48.54, lon: 22.16 },
  { lat: 48.4666666666667, lon: 22.15 },
  { lat: 48.4641666666667, lon: 22.1471666666667 },
  { lat: 48.4333333333333, lon: 22.1666666666667 },
  { lat: 48.4083333333333, lon: 22.2333333333333 },
  { lat: 48.34, lon: 22.3196666666667 },
  { lat: 48.1333333333333, lon: 22.5945 },
  { lat: 48.0966666666667, lon: 22.6833333333333 },
  { lat: 48.1166666666667, lon: 22.8333333333333 },
  { lat: 47.95, lon: 22.8666666666667 },
  { lat: 48.0166666666667, lon: 23.1 },
  { lat: 48.0266666666667, lon: 23.3433333333333 },
  { lat: 47.9088333333333, lon: 24.2096666666667 },
  { lat: 47.9583333333333, lon: 24.5833333333333 },
  { lat: 47.7333333333333, lon: 24.8833333333333 },
]

export const lvt = [
  { lon: 23.655, lat: 49.5283333333333 },
  { lon: 24.3111111111111, lat: 49.1236111111111 },
  { lon: 24.6691666666667, lat: 49.5375 },
  { lon: 24.7925, lat: 49.6777777777778 },
  { lon: 24.6508333333333, lat: 49.88 },
  { lon: 24.3119444444444, lat: 50.0619444444444 },
  { lon: 24.2413888888889, lat: 50.1422222222222 },
  { lon: 23.8966666666667, lat: 50.075 },
  { lon: 23.7833333333333, lat: 50.05 },
  { lon: 23.6333333333333, lat: 49.9833333333333 },
  { lon: 23.4166666666667, lat: 50 },
  { lon: 23.2119444444444, lat: 50.0216666666667 },
  { lon: 23.2088888888889, lat: 50.0208333333333 },
  { lon: 23.0583333333333, lat: 49.8883333333333 },
  { lon: 23.655, lat: 49.5283333333333 },
]

export const lve = [
  { lat: 49.05, lon: 27.8383333333333 },
  { lat: 49.15, lon: 27.845 },
  { lat: 49.7616666666667, lon: 27.85 },
  { lat: 50.182, lon: 27.0438333333333 },
  { lat: 50.5416666666667, lon: 27.255 },
  { lat: 50.85, lon: 27.4333333333333 },
  { lat: 51.3761666666667, lon: 27.7458333333333 },
  { lat: 51.3833333333333, lon: 27.75 },
  { lat: 51.4713333333333, lon: 27.7596666666667 },
  { lat: 51.4796666666667, lon: 27.6995 },
  { lat: 51.7663333333333, lon: 26.7983333333333 },
  { lat: 51.847, lon: 26.3958333333333 },
  { lat: 51.893, lon: 26.1213333333333 },
  { lat: 51.9196666666667, lon: 25.8863333333333 },
  { lat: 51.8833333333333, lon: 24.9666666666667 },
  { lat: 51.9013333333333, lon: 24.688 },
  { lat: 51.5, lon: 23.6166666666667 },
  { lat: 51.3028333333333, lon: 23.6458333333333 },
  { lat: 50.8333333333333, lon: 24.1 },
  { lat: 50.668, lon: 24.0503333333333 },
  { lat: 50.5033333333333, lon: 24.065 },
  { lat: 50.4041666666667, lon: 23.8255 },
  { lat: 50.2211666666667, lon: 23.4853333333333 },
  { lat: 50.0208333333333, lon: 23.2088888888889 },
  { lat: 50.0216666666667, lon: 23.2119444444444 },
  { lat: 50, lon: 23.4166666666667 },
  { lat: 49.9833333333333, lon: 23.6333333333333 },
  { lat: 50.05, lon: 23.7833333333333 },
  { lat: 50.075, lon: 23.8966666666667 },
  { lat: 50.1422222222222, lon: 24.2413888888889 },
  { lat: 50.0619444444444, lon: 24.3119444444444 },
  { lat: 49.88, lon: 24.6508333333333 },
  { lat: 49.6777777777778, lon: 24.7925 },
  { lat: 49.5375, lon: 24.6691666666667 },
  { lat: 49.2966666666667, lon: 25.3983333333333 },
  { lat: 49.2619444444444, lon: 26.0008333333333 },
  { lat: 49.0461111111111, lon: 26.7816666666667 },
  { lat: 49.05, lon: 27.8383333333333 },
]

export const lvw = [
  { lat: 49.0461111111111, lon: 26.7816666666667 },
  { lat: 49.2619444444444, lon: 26.0008333333333 },
  { lat: 49.2966666666667, lon: 25.3983333333333 },
  { lat: 49.5375, lon: 24.6691666666667 },
  { lat: 49.6777777777778, lon: 24.7925 },
  { lat: 49.88, lon: 24.6508333333333 },
  { lat: 50.0619444444444, lon: 24.3119444444444 },
  { lat: 50.1422222222222, lon: 24.2413888888889 },
  { lat: 50.075, lon: 23.8966666666667 },
  { lat: 50.05, lon: 23.7833333333333 },
  { lat: 49.9833333333333, lon: 23.6333333333333 },
  { lat: 50, lon: 23.4166666666667 },
  { lat: 50.0216666666667, lon: 23.212 },
  { lat: 49.9658333333333, lon: 23.1383333333333 },
  { lat: 49.8883333333333, lon: 23.0583333333333 },
  { lat: 49.8088333333333, lon: 22.94 },
  { lat: 49.5678333333333, lon: 22.6545 },
  { lat: 49.21, lon: 22.7366666666667 },
  { lat: 49.0888333333333, lon: 22.8333333333333 },
  { lat: 49, lon: 22.7666666666667 },
  { lat: 49.0888333333333, lon: 22.5666666666667 },
  { lat: 48.9503333333333, lon: 22.4486666666667 },
  { lat: 48.7716666666667, lon: 22.3505 },
  { lat: 48.6516666666667, lon: 22.2613333333333 },
  { lat: 48.6346666666667, lon: 22.2458333333333 },
  { lat: 48.54, lon: 22.16 },
  { lat: 48.4666666666667, lon: 22.15 },
  { lat: 48.4641666666667, lon: 22.1471666666667 },
  { lat: 48.4333333333333, lon: 22.1666666666667 },
  { lat: 48.4083333333333, lon: 22.2333333333333 },
  { lat: 48.34, lon: 22.3196666666667 },
  { lat: 48.1333333333333, lon: 22.5945 },
  { lat: 48.0966666666667, lon: 22.6833333333333 },
  { lat: 48.1166666666667, lon: 22.8333333333333 },
  { lat: 47.95, lon: 22.8666666666667 },
  { lat: 48.0166666666667, lon: 23.1 },
  { lat: 48.0266666666667, lon: 23.3433333333333 },
  { lat: 47.9088333333333, lon: 24.2096666666667 },
  { lat: 47.9583333333333, lon: 24.5833333333333 },
  { lat: 47.7333333333333, lon: 24.8833333333333 },
  { lat: 47.7333333333333, lon: 24.8833333333333 },
  { lat: 47.7355, lon: 25.0475 },
  { lat: 47.8666666666667, lon: 25.1666666666667 },
  { lat: 47.9188333333333, lon: 25.6533333333333 },
  { lat: 47.9321666666667, lon: 25.7166666666667 },
  { lat: 47.9405, lon: 25.7775 },
  { lat: 47.9516666666667, lon: 25.9583333333333 },
  { lat: 48.2205, lon: 26.4696666666667 },
  { lat: 48.2505, lon: 26.6236666666667 },
  { lat: 48.3228333333333, lon: 26.7095 },
  { lat: 48.4146666666667, lon: 26.8128333333333 },
  { lat: 48.418, lon: 27.3041666666667 },
  { lat: 48.4521666666667, lon: 27.7216666666667 },
  { lat: 48.4216666666667, lon: 27.828 },
  { lat: 48.4155, lon: 27.838 },
  { lat: 48.97, lon: 27.8416666666667 },
  { lat: 49.05, lon: 27.8383333333333 },
]

export const uklvArray = [
  {
    type: 'Feature',
    name: 'uklv',
    color: 'rgb(73, 70, 70)',
    geometry: {
      type: 'LineString',
      coordinates: [
        [24.8833333333333, 47.7333333333333],
        [25.0475, 47.7355],
        [25.1666666666667, 47.8666666666667],
        [25.6533333333333, 47.9188333333333],
        [25.7166666666667, 47.9321666666667],
        [25.7775, 47.9405],
        [25.9583333333333, 47.9516666666667],
        [26.4696666666667, 48.2205],
        [26.6236666666667, 48.2505],
        [26.7095, 48.3228333333333],
        [26.8128333333333, 48.4146666666667],
        [27.3041666666667, 48.418],
        [27.7216666666667, 48.4521666666667],
        [27.828, 48.4216666666667],
        [27.838, 48.4155],
        [27.8416666666667, 48.97],
        [27.8383333333333, 49.05],
        [27.845, 49.15],
        [27.85, 49.7616666666667],
        [27.0438333333333, 50.182],
        [27.255, 50.5416666666667],
        [27.4333333333333, 50.85],
        [27.7458333333333, 51.3761666666667],
        [27.75, 51.3833333333333],
        [27.7596666666667, 51.4713333333333],
        [27.6995, 51.4796666666667],
        [26.7983333333333, 51.7663333333333],
        [26.3958333333333, 51.847],
        [26.1213333333333, 51.893],
        [25.8863333333333, 51.9196666666667],
        [24.9666666666667, 51.8833333333333],
        [24.688, 51.9013333333333],
        [23.6166666666667, 51.5],
        [23.6458333333333, 51.3028333333333],
        [24.1, 50.8333333333333],
        [24.0503333333333, 50.668],
        [24.065, 50.5033333333333],
        [23.8255, 50.4041666666667],
        [23.4853333333333, 50.2211666666667],
        [23.212, 50.0216666666667],
        [23.1383333333333, 49.9658333333333],
        [23.0583333333333, 49.8883333333333],
        [22.94, 49.8088333333333],
        [22.6545, 49.5678333333333],
        [22.7366666666667, 49.21],
        [22.8333333333333, 49.0888333333333],
        [22.7666666666667, 49],
        [22.5666666666667, 49.0888333333333],
        [22.4486666666667, 48.9503333333333],
        [22.3505, 48.7716666666667],
        [22.2613333333333, 48.6516666666667],
        [22.2458333333333, 48.6346666666667],
        [22.16, 48.54],
        [22.15, 48.4666666666667],
        [22.1471666666667, 48.4641666666667],
        [22.1666666666667, 48.4333333333333],
        [22.2333333333333, 48.4083333333333],
        [22.3196666666667, 48.34],
        [22.5945, 48.1333333333333],
        [22.6833333333333, 48.0966666666667],
        [22.8333333333333, 48.1166666666667],
        [22.8666666666667, 47.95],
        [23.1, 48.0166666666667],
        [23.3433333333333, 48.0266666666667],
        [24.2096666666667, 47.9088333333333],
        [24.5833333333333, 47.9583333333333],
        [24.8833333333333, 47.7333333333333],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'lve',
    color: 'rgb(78, 74, 74)',
    geometry: {
      type: 'LineString',
      coordinates: [
        [27.8383333333333, 49.05],
        [27.845, 49.15],
        [27.85, 49.7616666666667],
        [27.0438333333333, 50.182],
        [27.255, 50.5416666666667],
        [27.4333333333333, 50.85],
        [27.7458333333333, 51.3761666666667],
        [27.75, 51.3833333333333],
        [27.7596666666667, 51.4713333333333],
        [27.6995, 51.4796666666667],
        [26.7983333333333, 51.7663333333333],
        [26.3958333333333, 51.847],
        [26.1213333333333, 51.893],
        [25.8863333333333, 51.9196666666667],
        [24.9666666666667, 51.8833333333333],
        [24.688, 51.9013333333333],
        [23.6166666666667, 51.5],
        [23.6458333333333, 51.3028333333333],
        [24.1, 50.8333333333333],
        [24.0503333333333, 50.668],
        [24.065, 50.5033333333333],
        [23.8255, 50.4041666666667],
        [23.4853333333333, 50.2211666666667],
        [23.2088888888889, 50.0208333333333],
        [23.2119444444444, 50.0216666666667],
        [23.4166666666667, 50],
        [23.6333333333333, 49.9833333333333],
        [23.7833333333333, 50.05],
        [23.8966666666667, 50.075],
        [24.2413888888889, 50.1422222222222],
        [24.3119444444444, 50.0619444444444],
        [24.6508333333333, 49.88],
        [24.7925, 49.6777777777778],
        [24.6691666666667, 49.5375],
        [25.3983333333333, 49.2966666666667],
        [26.0008333333333, 49.2619444444444],
        [26.7816666666667, 49.0461111111111],
        [27.8383333333333, 49.05],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'lvw',
    color: 'rgb(78, 74, 74)',
    geometry: {
      type: 'LineString',
      coordinates: [
        [26.7816666666667, 49.0461111111111],
        [26.0008333333333, 49.2619444444444],
        [25.3983333333333, 49.2966666666667],
        [24.6691666666667, 49.5375],
        [24.7925, 49.6777777777778],
        [24.6508333333333, 49.88],
        [24.3119444444444, 50.0619444444444],
        [24.2413888888889, 50.1422222222222],
        [23.8966666666667, 50.075],
        [23.7833333333333, 50.05],
        [23.6333333333333, 49.9833333333333],
        [23.4166666666667, 50],
        [23.212, 50.0216666666667],
        [23.1383333333333, 49.9658333333333],
        [23.0583333333333, 49.8883333333333],
        [22.94, 49.8088333333333],
        [22.6545, 49.5678333333333],
        [22.7366666666667, 49.21],
        [22.8333333333333, 49.0888333333333],
        [22.7666666666667, 49],
        [22.5666666666667, 49.0888333333333],
        [22.4486666666667, 48.9503333333333],
        [22.3505, 48.7716666666667],
        [22.2613333333333, 48.6516666666667],
        [22.2458333333333, 48.6346666666667],
        [22.16, 48.54],
        [22.15, 48.4666666666667],
        [22.1471666666667, 48.4641666666667],
        [22.1666666666667, 48.4333333333333],
        [22.2333333333333, 48.4083333333333],
        [22.3196666666667, 48.34],
        [22.5945, 48.1333333333333],
        [22.6833333333333, 48.0966666666667],
        [22.8333333333333, 48.1166666666667],
        [22.8666666666667, 47.95],
        [23.1, 48.0166666666667],
        [23.3433333333333, 48.0266666666667],
        [24.2096666666667, 47.9088333333333],
        [24.5833333333333, 47.9583333333333],
        [24.8833333333333, 47.7333333333333],
        [24.8833333333333, 47.7333333333333],
        [25.0475, 47.7355],
        [25.1666666666667, 47.8666666666667],
        [25.6533333333333, 47.9188333333333],
        [25.7166666666667, 47.9321666666667],
        [25.7775, 47.9405],
        [25.9583333333333, 47.9516666666667],
        [26.4696666666667, 48.2205],
        [26.6236666666667, 48.2505],
        [26.7095, 48.3228333333333],
        [26.8128333333333, 48.4146666666667],
        [27.3041666666667, 48.418],
        [27.7216666666667, 48.4521666666667],
        [27.828, 48.4216666666667],
        [27.838, 48.4155],
        [27.8416666666667, 48.97],
        [27.8383333333333, 49.05],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'ukln',
    color: 'rgb(73, 70, 70)',
    geometry: {
      type: 'LineString',
      coordinates: [
        [26.47, 48.2202777777778],
        [26.1213888888889, 48.6372222222222],
        [25.5388888888889, 48.4161111111111],
        [25.7636111111111, 47.9683333333333],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'lvt',
    color: 'rgb(78, 74, 74)',
    geometry: {
      type: 'LineString',
      coordinates: [
        [23.655, 49.5283333333333],
        [24.3111111111111, 49.1236111111111],
        [24.6691666666667, 49.5375],
        [24.7925, 49.6777777777778],
        [24.6508333333333, 49.88],
        [24.3119444444444, 50.0619444444444],
        [24.2413888888889, 50.1422222222222],
        [23.8966666666667, 50.075],
        [23.7833333333333, 50.05],
        [23.6333333333333, 49.9833333333333],
        [23.4166666666667, 50],
        [23.2119444444444, 50.0216666666667],
        [23.2088888888889, 50.0208333333333],
        [23.0583333333333, 49.8883333333333],
        [23.655, 49.5283333333333],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'bvl',
    color: 'rgb(78, 74, 74)',
    geometry: {
      type: 'LineString',
      coordinates: [
        [27.7597222222222, 51.4713888888889],
        [28.4666666666667, 51.55],
        [28.6797222222222, 51.5647222222222],
        [28.6786111111111, 51.4438888888889],
        [28.7433333333333, 51.4597222222222],
        [28.7844444444444, 51.4191666666667],
        [28.7877777777778, 51.4966666666667],
        [28.835, 51.5586111111111],
        [28.9116666666667, 51.5905555555556],
        [29.0316666666667, 51.5930555555556],
        [29.0833333333333, 51.6666666666667],
        [29.3333333333333, 51.5666666666667],
        [29.3869444444444, 51.4],
        [29.5866666666667, 51.4663888888889],
        [29.9280555555556, 51.4583333333333],
        [30.2616666666667, 51.4763888888889],
        [30.3794444444444, 51.3205555555556],
        [30.5572222222222, 51.2622222222222],
        [30.6066666666667, 51.3186111111111],
        [30.9777777777778, 50.9463888888889],
        [31.19, 50.8888888888889],
        [31.7966666666667, 50.7483333333333],
        [31.8027777777778, 50.6486111111111],
        [31.8333333333333, 49.95],
        [31.8525, 49.8261111111111],
        [31.6136111111111, 49.54],
        [31.4388888888889, 49.3244444444444],
        [31.0769444444444, 49.4047222222222],
        [30.8355555555556, 49.5008333333333],
        [30.6925, 49.5577777777778],
        [30.1305555555556, 49.6113888888889],
        [30.0136111111111, 49.6225],
        [29.7497222222222, 49.6475],
        [28.2288888888889, 49.7041666666667],
        [27.85, 49.7616666666667],
        [27.4894444444444, 49.9511111111111],
        [27.0438888888889, 50.1819444444444],
        [27.2077777777778, 50.4658333333333],
        [27.255, 50.5416666666667],
        [27.3488888888889, 50.7069444444444],
        [27.4333333333333, 50.85],
        [27.7458333333333, 51.3761111111111],
        [27.75, 51.3833333333333],
        [27.7544444444444, 51.4122222222222],
        [27.7597222222222, 51.4713888888889],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'bvo',
    color: 'rgb(78, 74, 74)',
    geometry: {
      type: 'LineString',
      coordinates: [
        [27.85, 49.7616666666667],
        [28.2288888888889, 49.7041666666667],
        [29.7497222222222, 49.6475],
        [30.0136111111111, 49.6225],
        [30.1305555555556, 49.6113888888889],
        [30.6925, 49.5577777777778],
        [30.8355555555556, 49.5008333333333],
        [31.0769444444444, 49.4047222222222],
        [31.4388888888889, 49.3244444444444],
        [32.6694444444444, 48.6055555555556],
        [32.6013888888889, 48.5244444444444],
        [32.65, 48.3833333333333],
        [32.6730555555555, 48.3577777777778],
        [32.8883333333333, 48.1183333333333],
        [32.8744444444444, 48.0266666666667],
        [32.8333333333333, 47.7666666666667],
        [31.5983333333333, 47.955],
        [31.2716666666667, 48],
        [30.9752777777778, 48.0438888888889],
        [30.6616666666667, 48.0880555555556],
        [30.0883333333333, 48.165],
        [29.8844444444444, 48.1913888888889],
        [29.6877777777778, 48.0647222222222],
        [29.415, 47.8875],
        [29.2894444444444, 47.8052777777778],
        [29.2788888888889, 47.8044444444444],
        [29.2330555555556, 47.8866666666667],
        [29.1422222222222, 47.9930555555556],
        [28.7305555555556, 48.125],
        [27.8380555555556, 48.4155555555556],
        [27.8388888888889, 48.5936111111111],
        [27.8416666666667, 48.97],
        [27.8383333333333, 49.05],
        [27.845, 49.15],
        [27.8469444444444, 49.3908333333333],
        [27.85, 49.7616666666667],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'bv1',
    color: 'rgb(78, 74, 74)',
    geometry: {
      type: 'LineString',
      coordinates: [
        [29.3869444444444, 51.4],
        [29.5866666666667, 51.4663888888889],
        [29.9280555555556, 51.4583333333333],
        [30.2616666666667, 51.4763888888889],
        [30.3794444444444, 51.3205555555556],
        [30.5572222222222, 51.2622222222222],
        [30.6066666666667, 51.3186111111111],
        [30.9777777777778, 50.9463888888889],
        [31.19, 50.8888888888889],
        [31.7966666666667, 50.7483333333333],
        [31.8027777777778, 50.6486111111111],
        [31.8333333333333, 49.95],
        [31.8525, 49.8261111111111],
        [31.6136111111111, 49.54],
        [31.4388888888889, 49.3244444444444],
        [31.2094444444444, 49.0408333333333],
        [30.6236111111111, 49.2094444444445],
        [30.4227777777778, 49.27],
        [30.3241666666667, 49.2977777777778],
        [29.7583333333333, 49.4566666666667],
        [29.7497222222222, 49.6475],
        [29.7333333333333, 50],
        [29.5702777777778, 50.1869444444444],
        [29.6666666666667, 50.68],
        [29.5666666666667, 50.8333333333333],
        [29.3869444444444, 51.4],
      ],
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [30.900972, 50.285694],
    },
    // properties: {
    //   subType: 'Circle',
    //   radius: 1,
    // },
  },
  {
    type: 'Feature',
    name: 'ukr',
    color: 'rgb(78, 74, 74)',
    geometry: {
      type: 'LineString',
      coordinates: [
        [23.6166666666667, 51.5],
        [24.6880555555556, 51.9013888888889],
        [24.9666666666667, 51.8833333333333],
        [25.3708333333333, 51.9288888888889],
        [25.8863888888889, 51.9197222222222],
        [26.1213888888889, 51.8930555555556],
        [26.3958333333333, 51.8469444444444],
        [26.7983333333333, 51.7663888888889],
        [27.6994444444444, 51.4797222222222],
        [27.7597222222222, 51.4713888888889],
        [28.4666666666667, 51.55],
        [28.6797222222222, 51.5647222222222],
        [28.6786111111111, 51.4438888888889],
        [28.7433333333333, 51.4597222222222],
        [28.7844444444444, 51.4191666666667],
        [28.7877777777778, 51.4966666666667],
        [28.835, 51.5586111111111],
        [28.9116666666667, 51.5905555555556],
        [29.0316666666667, 51.5930555555556],
        [29.0833333333333, 51.6666666666667],
        [29.3333333333333, 51.5666666666667],
        [29.3869444444444, 51.4],
        [29.5866666666667, 51.4663888888889],
        [29.9280555555556, 51.4583333333333],
        [30.2616666666667, 51.4763888888889],
        [30.3794444444444, 51.3205555555556],
        [30.5572222222222, 51.2622222222222],
        [30.6066666666667, 51.3186111111111],
        [30.5733333333333, 51.5113888888889],
        [30.5572222222222, 51.6455555555556],
        [30.6316666666667, 51.7163888888889],
        [31.015, 52.0833333333333],
        [31.4152777777778, 52.1402777777778],
        [31.6833333333333, 52.1333333333333],
        [31.7833333333333, 52.1333333333333],
        [31.7916666666667, 52.115],
        [31.9513888888889, 52.0813888888889],
        [32.25, 52.1],
        [32.3333333333333, 52.3],
        [32.6733333333333, 52.275],
        [33.31, 52.35],
        [33.8983333333333, 52.3216666666667],
        [34.1166666666667, 52],
        [34.1666666666667, 51.9666666666667],
        [34.5, 51.7],
        [34.0833333333333, 51.65],
        [34.45, 51.2333333333333],
        [35.33, 51.0716666666667],
        [35.4333333333333, 50.85],
        [35.4486111111111, 50.8158333333333],
        [35.6466666666667, 50.3716666666667],
        [35.6983333333333, 50.355],
        [36.2933333333333, 50.3213888888889],
        [36.4083333333333, 50.3166666666667],
        [36.485, 50.3133333333333],
        [36.8283333333333, 50.3166666666667],
        [37.4666666666667, 50.4333333333333],
        [37.64, 50.3016666666667],
        [37.6408333333333, 50.3008333333333],
        [37.76, 50.0919444444444],
        [38.0427777777778, 49.9086111111111],
        [38.1466666666667, 49.9302777777778],
        [38.1786111111111, 49.95],
        [38.1925, 50.0661111111111],
        [38.2266666666667, 50.075],
        [39.5, 49.7416666666667],
        [40.1516666666667, 49.6125],
        [40.2075, 49.5630555555556],
        [40.07, 49.5319444444444],
        [40.1666666666667, 49.3666666666667],
        [39.8633333333333, 48.5183333333333],
        [39.9433333333333, 48.195],
        [39.8916666666667, 48.1283333333333],
        [39.8083333333333, 47.95],
        [39.675, 47.8333333333333],
        [39.2183333333333, 47.8566666666667],
        [38.9166666666667, 47.8166666666667],
        [38.3133333333333, 47.555],
        [38.2233333333333, 47.1116666666667],
        [38.3333333333333, 46.9833333333333],
        [37.5, 46.7833333333333],
        [37.4216666666667, 46.6583333333333],
        [37.3683333333333, 46.5733333333333],
        [36.7966666666667, 45.6383333333333],
        [36.7161111111111, 45.5044444444444],
        [36.6833333333333, 45.45],
        [36.6833333333333, 45.3783333333333],
        [36.6466666666667, 45.35],
        [36.59, 45.3066666666667],
        [36.595, 45.245],
        [36.5333333333333, 45.205],
        [36.5438888888889, 45.1591666666667],
        [36.545, 45.1533333333333],
        [36.5716666666667, 45.0716666666667],
        [36.5916666666667, 45],
        [36.61, 44.9366666666667],
        [36.6244444444444, 44.8291666666667],
        [36.6666666666667, 44.5166666666667],
        [36.6333333333333, 44.35],
        [36.5583333333333, 44.0366666666667],
        [36.4916666666667, 43.7416666666667],
        [36.3833333333333, 43.25],
        [36.2663888888889, 42.7322222222222],
        [35, 42.75],
        [34.0877777777778, 42.7805555555556],
        [33.8944444444444, 42.7855555555556],
        [33.1811111111111, 42.7988888888889],
        [31.9477777777778, 42.7988888888889],
        [31, 42.8],
        [30.75, 42.8],
        [30.64, 43.2533333333333],
        [30.5333333333333, 43.6833333333333],
        [30.505, 43.8033333333333],
        [30.4386111111111, 44.0863888888889],
        [30.42, 44.1666666666667],
        [30.4, 44.25],
        [30.2666666666667, 44.5],
        [30.2277777777778, 44.6069444444444],
        [30.1694444444444, 44.7122222222222],
        [30.15, 44.7666666666667],
        [30.08, 44.9130555555556],
        [30.05, 44.9763888888889],
        [29.9666666666667, 45.15],
        [29.8033333333333, 45.1866666666667],
        [29.6666666666667, 45.2166666666667],
        [29.5, 45.4166666666667],
        [29.1108333333333, 45.3775],
        [28.7230555555556, 45.2252777777778],
        [28.3333333333333, 45.3333333333333],
        [28.3222222222222, 45.3480555555556],
        [28.3047222222222, 45.3655555555556],
        [28.205, 45.4733333333333],
        [28.3133333333333, 45.5444444444444],
        [28.4358333333333, 45.4880555555556],
        [28.5097222222222, 45.5002777777778],
        [28.5086111111111, 45.5697222222222],
        [28.5466666666667, 45.5969444444444],
        [28.5066666666667, 45.6716666666667],
        [28.7341666666667, 45.8219444444444],
        [28.865, 45.975],
        [28.9555555555556, 46.1],
        [29.0513888888889, 46.1786111111111],
        [29.0888888888889, 46.5202777777778],
        [29.3280555555556, 46.4691666666667],
        [29.6919444444444, 46.3633333333333],
        [29.6758333333333, 46.4044444444444],
        [29.755, 46.4566666666667],
        [29.7930555555556, 46.4363888888889],
        [29.8294444444444, 46.3819444444444],
        [29.8855555555556, 46.3644444444444],
        [29.9475, 46.4027777777778],
        [30, 46.3855555555556],
        [30.0661111111111, 46.4016666666667],
        [30.1352777777778, 46.4172222222222],
        [30.1266666666667, 46.4244444444444],
        [30.1158333333333, 46.4333333333333],
        [30.0772222222222, 46.4338888888889],
        [30.0716666666667, 46.4516666666667],
        [29.9783333333333, 46.5166666666667],
        [29.9, 46.5447222222222],
        [29.9438888888889, 46.6486111111111],
        [30, 46.8333333333333],
        [29.8883333333333, 46.8780555555556],
        [29.8761111111111, 46.8786111111111],
        [29.7619444444444, 46.8608333333333],
        [29.7291666666667, 46.9222222222222],
        [29.5786111111111, 46.9508333333333],
        [29.6288888888889, 47.0036111111111],
        [29.6111111111111, 47.0127777777778],
        [29.6227777777778, 47.0755555555556],
        [29.5325, 47.1127777777778],
        [29.6008333333333, 47.2880555555556],
        [29.5855555555556, 47.3716666666667],
        [29.5097222222222, 47.3677777777778],
        [29.4836111111111, 47.3122222222222],
        [29.4416666666667, 47.3],
        [29.3888888888889, 47.3230555555556],
        [29.3322222222222, 47.4438888888889],
        [29.1888888888889, 47.4697222222222],
        [29.2047222222222, 47.5713888888889],
        [29.2230555555556, 47.7277777777778],
        [29.2788888888889, 47.8044444444444],
        [29.2330555555556, 47.8866666666667],
        [29.1422222222222, 47.9930555555556],
        [28.7305555555556, 48.125],
        [27.8380555555556, 48.4155555555556],
        [27.8280555555556, 48.4216666666667],
        [27.7216666666667, 48.4522222222222],
        [27.3041666666667, 48.4180555555556],
        [26.8127777777778, 48.4147222222222],
        [26.7094444444444, 48.3227777777778],
        [26.6236111111111, 48.2505555555556],
        [26.4697222222222, 48.2205555555556],
        [26.2552777777778, 48.1077777777778],
        [26.1808333333333, 48.0688888888889],
        [26.1561111111111, 48.0558333333333],
        [26.0577777777778, 48.0041666666667],
        [25.9583333333333, 47.9516666666667],
        [25.7775, 47.9405555555556],
        [25.7166666666667, 47.9322222222222],
        [25.6533333333333, 47.9188888888889],
        [25.1666666666667, 47.8666666666667],
        [25.0475, 47.7355555555556],
        [24.8833333333333, 47.7333333333333],
        [24.5833333333333, 47.9583333333333],
        [24.2097222222222, 47.9088888888889],
        [23.9463888888889, 47.9536111111111],
        [23.3433333333333, 48.0266666666667],
        [23.1, 48.0166666666667],
        [22.9061111111111, 47.9591666666667],
        [22.8333333333333, 48.1166666666667],
        [22.6833333333333, 48.0966666666667],
        [22.5944444444444, 48.1333333333333],
        [22.3197222222222, 48.34],
        [22.2333333333333, 48.4083333333333],
        [22.1666666666667, 48.4333333333333],
        [22.1472222222222, 48.4641666666667],
        [22.15, 48.4666666666667],
        [22.16, 48.54],
        [22.2458333333333, 48.6347222222222],
        [22.2613888888889, 48.6516666666667],
        [22.3505555555556, 48.7716666666667],
        [22.375, 48.8238888888889],
        [22.4486111111111, 48.9502777777778],
        [22.5658333333333, 49.0880555555556],
        [22.5666666666667, 49.0886111111111],
        [22.7666666666667, 49],
        [22.8333333333333, 49.0888888888889],
        [22.7380555555556, 49.2055555555556],
        [22.7366666666667, 49.21],
        [22.6561111111111, 49.5047222222222],
        [22.6544444444444, 49.5677777777778],
        [22.94, 49.8088888888889],
        [23.0583333333333, 49.8883333333333],
        [23.1383333333333, 49.9658333333333],
        [23.2119444444444, 50.0216666666667],
        [23.4852777777778, 50.2211111111111],
        [23.8255555555556, 50.4041666666667],
        [24.065, 50.5033333333333],
        [24.0583333333333, 50.5772222222222],
        [24.0502777777778, 50.6677777777778],
        [24.1, 50.8333333333333],
        [23.7880555555556, 51.1552777777778],
        [23.6458333333333, 51.3027777777778],
        [23.6208333333333, 51.4716666666667],
        [23.6166666666667, 51.5],
      ],
    },
  },
]

export const borderPoints = [
  {
    name: 'AMIRI',
    longitude: 25.886388888888888,
    latitude: 51.91972222222222,
  },
  {
    name: 'BAMUD',
    longitude: 24.209722222222222,
    latitude: 47.90888888888889,
  },
  {
    name: 'BUKOV',
    longitude: 25.958333333333332,
    latitude: 47.95166666666667,
  },
  {
    name: 'BURAK',
    longitude: 26.395833333333332,
    latitude: 51.846944444444446,
  },
  {
    name: 'DIBED',
    longitude: 23.058333333333334,
    latitude: 49.888333333333335,
  },
  {
    name: 'DIBON',
    longitude: 26.12138888888889,
    latitude: 51.893055555555556,
  },
  {
    name: 'DIDUR',
    longitude: 27.83888888888889,
    latitude: 48.593611111111116,
  },
  {
    name: 'DORER',
    longitude: 27.20777777777778,
    latitude: 50.465833333333336,
  },
  {
    name: 'GEMTO',
    longitude: 22.594444444444445,
    latitude: 48.13333333333333,
  },
  {
    name: 'GOTIX',
    longitude: 23.211944444444445,
    latitude: 50.02166666666667,
  },
  {
    name: 'INROG',
    longitude: 26.81277777777778,
    latitude: 48.414722222222224,
  },
  {
    name: 'KOROP',
    longitude: 27.845,
    latitude: 49.15,
  },
  {
    name: 'LADOB',
    longitude: 22.448611111111113,
    latitude: 48.95027777777778,
  },
  {
    name: 'LASOT',
    longitude: 22.245833333333334,
    latitude: 48.63472222222222,
  },
  {
    name: 'LONLA',
    longitude: 22.31972222222222,
    latitude: 48.34,
  },
  {
    name: 'LUGOL',
    longitude: 22.738055555555558,
    latitude: 49.205555555555556,
  },
  {
    name: 'MALBE',
    longitude: 22.375,
    latitude: 48.823888888888895,
  },
  {
    name: 'OKROT',
    longitude: 27.348888888888887,
    latitude: 50.706944444444446,
  },
  {
    name: 'POBUV',
    longitude: 27.489444444444445,
    latitude: 49.95111111111111,
  },
  {
    name: 'ROLKA',
    longitude: 23.825555555555557,
    latitude: 50.40416666666667,
  },
  {
    name: 'ROMOL',
    longitude: 25.047500000000003,
    latitude: 47.73555555555556,
  },
  {
    name: 'RUMUK',
    longitude: 23.343333333333334,
    latitude: 48.026666666666664,
  },
  {
    name: 'SITBA',
    longitude: 27.846944444444443,
    latitude: 49.39083333333333,
  },
  {
    name: 'SOMAT',
    longitude: 25.370833333333334,
    latitude: 51.928888888888885,
  },
  {
    name: 'SORON',
    longitude: 27.85,
    latitude: 49.76166666666666,
  },
  {
    name: 'PEVOT',
    longitude: 27.04388888888889,
    latitude: 50.18194444444444,
  },
  {
    name: 'TADUN',
    longitude: 24.688055555555557,
    latitude: 51.90138888888889,
  },
  {
    name: 'TAKET',
    longitude: 27.841666666666665,
    latitude: 48.97,
  },
  {
    name: 'TEPNA',
    longitude: 22.65611111111111,
    latitude: 49.50472222222222,
  },
  {
    name: 'TOLPA',
    longitude: 23.645833333333332,
    latitude: 51.30277777777778,
  },
  {
    name: 'UNDOL',
    longitude: 27.721666666666664,
    latitude: 48.452222222222225,
  },
  {
    name: 'USTIL',
    longitude: 24.1,
    latitude: 50.833333333333336,
  },
]

export const extFreq = [
  {
    latitude: 50.3,
    longitude: 22.7,
    name: 'WARSZAWA 1B\n125.45',
  },
  {
    latitude: 49.0,
    longitude: 21.0,
    name: 'BRATISLAVA 2VB\nW - 135.965 F340^\nL - 134.475',
  },
  {
    latitude: 48.0,
    longitude: 21.5,
    name: 'BUDAPEST E1\nT - 132.790 F370^\nL - 120.37',
  },
  {
    latitude: 48.18,
    longitude: 27.4,
    name: 'CHISINAU C 1A\n130.975',
  },
  {
    latitude: 47.5,
    longitude: 24.45,
    name: 'BUCURESTI R\n127.075',
  },
  {
    latitude: 50.5,
    longitude: 27.6,
    name: 'KYIV R LIV\n135.825',
  },
  {
    latitude: 48.9,
    longitude: 28.0,
    name: 'KYIV R ODS\n131.375',
  },
  {
    latitude: 52.22,
    longitude: 25.2,
    name: 'MINSK C 1\n124.825',
  },
]

export const bvl = [
  { lat: 51.4713888888889, lon: 27.7597222222222 },
  { lat: 51.55, lon: 28.4666666666667 },
  { lat: 51.5647222222222, lon: 28.6797222222222 },
  { lat: 51.4438888888889, lon: 28.6786111111111 },
  { lat: 51.4597222222222, lon: 28.7433333333333 },
  { lat: 51.4191666666667, lon: 28.7844444444444 },
  { lat: 51.4966666666667, lon: 28.7877777777778 },
  { lat: 51.5586111111111, lon: 28.835 },
  { lat: 51.5905555555556, lon: 28.9116666666667 },
  { lat: 51.5930555555556, lon: 29.0316666666667 },
  { lat: 51.6666666666667, lon: 29.0833333333333 },
  { lat: 51.5666666666667, lon: 29.3333333333333 },
  { lat: 51.4, lon: 29.3869444444444 },
  { lat: 51.4663888888889, lon: 29.5866666666667 },
  { lat: 51.4583333333333, lon: 29.9280555555556 },
  { lat: 51.4763888888889, lon: 30.2616666666667 },
  { lat: 51.3205555555556, lon: 30.3794444444444 },
  { lat: 51.2622222222222, lon: 30.5572222222222 },
  { lat: 51.3186111111111, lon: 30.6066666666667 },
  { lat: 50.9463888888889, lon: 30.9777777777778 },
  { lat: 50.8888888888889, lon: 31.19 },
  { lat: 50.7483333333333, lon: 31.7966666666667 },
  { lat: 50.6486111111111, lon: 31.8027777777778 },
  { lat: 49.95, lon: 31.8333333333333 },
  { lat: 49.8261111111111, lon: 31.8525 },
  { lat: 49.54, lon: 31.6136111111111 },
  { lat: 49.3244444444444, lon: 31.4388888888889 },
  { lat: 49.4047222222222, lon: 31.0769444444444 },
  { lat: 49.5008333333333, lon: 30.8355555555556 },
  { lat: 49.5577777777778, lon: 30.6925 },
  { lat: 49.6113888888889, lon: 30.1305555555556 },
  { lat: 49.6225, lon: 30.0136111111111 },
  { lat: 49.6475, lon: 29.7497222222222 },
  { lat: 49.7041666666667, lon: 28.2288888888889 },
  { lat: 49.7616666666667, lon: 27.85 },
  { lat: 49.9511111111111, lon: 27.4894444444444 },
  { lat: 50.1819444444444, lon: 27.0438888888889 },
  { lat: 50.4658333333333, lon: 27.2077777777778 },
  { lat: 50.5416666666667, lon: 27.255 },
  { lat: 50.7069444444444, lon: 27.3488888888889 },
  { lat: 50.85, lon: 27.4333333333333 },
  { lat: 51.3761111111111, lon: 27.7458333333333 },
  { lat: 51.3833333333333, lon: 27.75 },
  { lat: 51.4122222222222, lon: 27.7544444444444 },
  { lat: 51.4713888888889, lon: 27.7597222222222 },
]
export const bvo = [
  { lat: 49.7616666666667, lon: 27.85 },
  { lat: 49.7041666666667, lon: 28.2288888888889 },
  { lat: 49.6475, lon: 29.7497222222222 },
  { lat: 49.6225, lon: 30.0136111111111 },
  { lat: 49.6113888888889, lon: 30.1305555555556 },
  { lat: 49.5577777777778, lon: 30.6925 },
  { lat: 49.5008333333333, lon: 30.8355555555556 },
  { lat: 49.4047222222222, lon: 31.0769444444444 },
  { lat: 49.3244444444444, lon: 31.4388888888889 },
  { lat: 48.6055555555556, lon: 32.6694444444444 },
  { lat: 48.5244444444444, lon: 32.6013888888889 },
  { lat: 48.3833333333333, lon: 32.65 },
  { lat: 48.3577777777778, lon: 32.6730555555555 },
  { lat: 48.1183333333333, lon: 32.8883333333333 },
  { lat: 48.0266666666667, lon: 32.8744444444444 },
  { lat: 47.7666666666667, lon: 32.8333333333333 },
  { lat: 47.955, lon: 31.5983333333333 },
  { lat: 48, lon: 31.2716666666667 },
  { lat: 48.0438888888889, lon: 30.9752777777778 },
  { lat: 48.0880555555556, lon: 30.6616666666667 },
  { lat: 48.165, lon: 30.0883333333333 },
  { lat: 48.1913888888889, lon: 29.8844444444444 },
  { lat: 48.0647222222222, lon: 29.6877777777778 },
  { lat: 47.8875, lon: 29.415 },
  { lat: 47.8052777777778, lon: 29.2894444444444 },
  { lat: 47.8044444444444, lon: 29.2788888888889 },
  { lat: 47.8866666666667, lon: 29.2330555555556 },
  { lat: 47.9930555555556, lon: 29.1422222222222 },
  { lat: 48.125, lon: 28.7305555555556 },
  { lat: 48.4155555555556, lon: 27.8380555555556 },
  { lat: 48.5936111111111, lon: 27.8388888888889 },
  { lat: 48.97, lon: 27.8416666666667 },
  { lat: 49.05, lon: 27.8383333333333 },
  { lat: 49.15, lon: 27.845 },
  { lat: 49.3908333333333, lon: 27.8469444444444 },
  { lat: 49.7616666666667, lon: 27.85 },
]
export const bv1 = [
  { lat: 51.4, lon: 29.3869444444444 },
  { lat: 51.4663888888889, lon: 29.5866666666667 },
  { lat: 51.4583333333333, lon: 29.9280555555556 },
  { lat: 51.4763888888889, lon: 30.2616666666667 },
  { lat: 51.3205555555556, lon: 30.3794444444444 },
  { lat: 51.2622222222222, lon: 30.5572222222222 },
  { lat: 51.3186111111111, lon: 30.6066666666667 },
  { lat: 50.9463888888889, lon: 30.9777777777778 },
  { lat: 50.8888888888889, lon: 31.19 },
  { lat: 50.7483333333333, lon: 31.7966666666667 },
  { lat: 50.6486111111111, lon: 31.8027777777778 },
  { lat: 49.95, lon: 31.8333333333333 },
  { lat: 49.8261111111111, lon: 31.8525 },
  { lat: 49.54, lon: 31.6136111111111 },
  { lat: 49.3244444444444, lon: 31.4388888888889 },
  { lat: 49.0408333333333, lon: 31.2094444444444 },
  { lat: 49.2094444444445, lon: 30.6236111111111 },
  { lat: 49.27, lon: 30.4227777777778 },
  { lat: 49.2977777777778, lon: 30.3241666666667 },
  { lat: 49.4566666666667, lon: 29.7583333333333 },
  { lat: 49.6475, lon: 29.7497222222222 },
  { lat: 50, lon: 29.7333333333333 },
  { lat: 50.1869444444444, lon: 29.5702777777778 },
  { lat: 50.68, lon: 29.6666666666667 },
  { lat: 50.8333333333333, lon: 29.5666666666667 },
  { lat: 51.4, lon: 29.3869444444444 },
]
export const basicSectors = [
  {
    name: 'lvt',
    sector: 'LVTM',
    vLimit: { lower: 2500, upper: 16500 },
  },
  {
    name: 'lve',
    sector: 'E325',
    vLimit: { lower: 6000, upper: 32500 },
  },
  {
    name: 'lve',
    sector: 'E345',
    vLimit: { lower: 32500, upper: 34500 },
  },
  {
    name: 'lvw',
    sector: 'W325',
    vLimit: { lower: 6000, upper: 32500 },
  },
  {
    name: 'lvw',
    sector: 'W345',
    vLimit: { lower: 32500, upper: 34500 },
  },
  {
    name: 'uklv',
    sector: 'C355',
    vLimit: { lower: 34500, upper: 35500 },
  },
  {
    name: 'uklv',
    sector: 'C365',
    vLimit: { lower: 35500, upper: 36500 },
  },
  {
    name: 'uklv',
    sector: 'C375',
    vLimit: { lower: 36500, upper: 37500 },
  },
  {
    name: 'uklv',
    sector: 'C385',
    vLimit: { lower: 37500, upper: 38500 },
  },
  {
    name: 'uklv',
    sector: 'C660',
    vLimit: { lower: 38500, upper: 66000 },
  },
  {
    name: 'bv1',
    sector: 'BV1',
    vLimit: { lower: 3000, upper: 27500 },
  },
  {
    name: 'bvl',
    sector: 'BVL',
    vLimit: { lower: 6000, upper: 66000 },
  },
  {
    name: 'bvo',
    sector: 'BVO',
    vLimit: { lower: 6000, upper: 66000 },
  },
]
