/*
    node_js
    5/19/2021 12:08 PM
    by Oleksandr
*/

import Window from './Window'
import { calcExerciseWorkload } from '../../../../calc/calc'
import React, { useEffect, useRef, useState } from 'react'
import { Chart } from 'react-charts'
import { useCwpContext } from '../../../../contexts/CwpContext'

const WorkloadWindow = () => {
  const {
    setPttWindows,
    flights,
    workloadArray,
    sectors,
    setExerciseWorkload,
    setTickValue,
    setTimeValue,
    conflictNumberList,
    inSectorFlights,
    setInSectorFights,
    updateFlightsTime,
    timeOfUpdate,
  } = useCwpContext()

  const [textArr, setTextArr] = useState([])
  const timeout = useRef(null)

  useEffect(() => {
    clearTimeout(timeout.current)

    timeout.current = setTimeout(() => {
      let calExerciseData = calcExerciseWorkload(flights, sectors)
      setInSectorFights(calExerciseData.inSectorFlights)
      setExerciseWorkload({
        ops: sectors.ops,
        numberOfFlights: calExerciseData.uniqueFlights.size,
        trafficVolume: calExerciseData.inSectorFlights,
      })
    }, 1000)
  }, [flights, sectors, updateFlightsTime, timeOfUpdate])

  useEffect(() => {
    const tempTextArr = []
    // const tempConflict = new Array(61).fill(0)
    // Array.from(conflictInfoList).forEach((conflict) => {
    //   let tStart = parseInt(
    //     (conflict.flight1StartIndex - conflict.flight1.timeShift) / 15,
    //   )
    //   let tEnd = parseInt(
    //     (conflict.flight1EndIndex - conflict.flight1.timeShift) / 15,
    //   )
    //   for (let i = tStart; i <= tEnd; i++) {
    //     tempConflict[i] += 1
    //   }
    // })

    const data = [
      {
        label: 'Workload',
        data: workloadArray
          .map((val, ind) => {
            return [ind, val[1]]
          })
          .filter((val) => val[1]),
        color: '#00ff22',
      },
      {
        label: 'Flights',
        data: inSectorFlights
          .map((val, ind) => {
            return [ind, val]
          })
          .filter((val) => val[0]),
        color: '#0055ff',
      },
      {
        label: 'Conflicts',
        data: conflictNumberList
          .map((val, ind) => {
            return [ind, val]
          })
          .filter((val) => val[0]),
        color: '#ff0000',
        secondaryAxisId: 1,
      },
    ]
    tempTextArr.unshift(
      <div style={{ width: '950px', height: '300px', paddingLeft: '5px' }}>
        <Chart
          options={{
            data,
            tooltip: true,
            onClickDatum: (evt) => {
              if (evt && evt.primaryValue >= 0) {
                setTimeValue(evt.primaryValue * 60)
                setTickValue(evt.primaryValue * 15)
              }
            },
            primaryAxis: {
              elementType: 'line',
              position: 'bottom',
              getValue: (element) => element[0],
            },
            secondaryAxes: [
              {
                elementType: 'line',
                position: 'left',
                getValue: (element) => element[1],
              },
              {
                elementType: 'bar',
                position: 'left',
                getValue: (element) => element[1],
                id: 1,
              },
            ],
          }}
        />
      </div>,
    )

    setTextArr(tempTextArr)
  }, [workloadArray, inSectorFlights, conflictNumberList])

  return (
    <Window
      toggleWindow={() => {
        setPttWindows((prev) => ({ ...prev, workloadWindow: false }))
      }}
      label={'Workload'}
      width={1000}
      maxContentHeight={'auto'}
    >
      {textArr}
    </Window>
  )
}

export default WorkloadWindow
