import * as React from 'react'
import Window from './Window'
import { useCwpContext } from '../../../../contexts/CwpContext'

const SystemTimeWindow = ({ showRadios }) => {
  const {
    setPttWindows,
    setTickPause,
    setTickSpeed,
    tickValue,
    setTickValue,
    setTimeValue,
    tickSpeed,
    startDate,
    timeValue,
    tickPause,
  } = useCwpContext()

  const updateTickPause = () => {
    setTickPause((prev) => !prev)
  }

  const onSpeedChange = (e) => {
    setTickSpeed(parseInt(e.target.value))
  }

  return (
    <Window
      isVisible={true}
      toggleWindow={() => {
        setPttWindows((prev) => ({ ...prev, systemTimeWindow: false }))
      }}
      label={'System Time'}
      width={305}
    >
      <>
        <div
          style={{
            width: '294px',
            background: 'black',
            color: 'white',
            fontSize: '70px',
            margin: '3px',
          }}
        >
          {new Date(startDate.getTime() + timeValue * 1000).toLocaleTimeString(
            'es-ES',
          )}
        </div>

        {showRadios && (
          <>
            <div>
              <span
                className={'buttonSDD'}
                style={{ float: 'left', margin: '3px' }}
                onClick={updateTickPause}
              >
                {tickPause ? 'Start' : 'Pause'}
              </span>

              <div style={{ fontSize: '15px', padding: '4px' }}>
                <input
                  type="radio"
                  value={1000}
                  name="speed"
                  checked={tickSpeed === 1000}
                  onChange={onSpeedChange}
                />{' '}
                x1
                <input
                  type="radio"
                  value={500}
                  name="speed"
                  checked={tickSpeed === 500}
                  onChange={onSpeedChange}
                />{' '}
                x2
                <input
                  type="radio"
                  value={250}
                  name="speed"
                  checked={tickSpeed === 250}
                  onChange={onSpeedChange}
                />{' '}
                x4
              </div>
            </div>
            <div>
              <input
                style={{ width: '98%' }}
                value={tickValue}
                type="range"
                id="time"
                name="time"
                min="0"
                max="900"
                onChange={(e) => {
                  setTimeValue(parseInt(e.target.value) * 4)
                  setTickValue(parseInt(e.target.value))
                }}
              />
            </div>
          </>
        )}
      </>
    </Window>
  )
}

export default SystemTimeWindow
