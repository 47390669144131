import React from 'react'
import {
  addCandidate,
  createOffer,
  initiateLocalStream,
  listenToConnectionEvents,
  sendAnswer,
  startCall,
} from './modules/RTCModule'

import { doAnswer, doCandidate, doOffer } from './modules/DataModule'
import 'webrtc-adapter'
import VideoChat from './VideoChat'
import ClientSocket from './modules/ClientSocket'
import Window from '../cwp/graphics/windows/Window'
import Video from './Video'

class VideoChatContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      database: null,
      connectedUser: null,
      localStream: null,
      localConnection: null,
      createOfferRes: null,
      answerRes: null,
      candidates: [],
      peerConnections: {},
      remoteStreams: [],
      isLocalAudioMuted: true,
    }
    this.localVideoRef = React.createRef()
    this.remoteVideoRef = React.createRef()
  }

  componentDidMount = async () => {
    window.addEventListener('keydown', this.onKeydown)

    window.addEventListener('keyup', this.onKeyup)
  }
  setRemoteStreams = (remoteStreams) => {
    this.setState({
      ...this.state,
      remoteStreams: remoteStreams,
    })
  }

  getLocalStream = async () => {
    // getting local video stream
    const localStream = await initiateLocalStream()
    // console.log(this.localVideoRef, localStream)
    if (this.localVideoRef) {
      this.localVideoRef.srcObject = localStream
      window.localVideo = localStream

      this.setState({
        ...this.state,
        localStream: localStream,
      })
    } else {
      try {
        localStream.getTracks().forEach((track) => {
          track.stop()
        })
      } catch (e) {
        console.log(e)
      }
    }
    // const localConnection = await initiateConnection()
    // this.setState({
    //     ...this.state,
    //     localConnection: localConnection
    // })
  }

  setPeerConnections = (socketID, pc) => {
    const peerConnections = { ...this.state.peerConnections, [socketID]: pc }
    this.setState({
      peerConnections,
    })
  }

  onKeydown = (e) => {
    if (!this.state.localStream || e.code !== 'Space') return
    this.toggleAudio(true)
  }

  toggleAudio = (e) => {
    // if (this.state.localStream.getVideoTracks()[0]) this.state.localStream.getVideoTracks()[0].enabled = e
    // if (this.state.localStream.getAudioTracks()[0])
    this.state.localStream.getAudioTracks()[0].enabled = e
    this.setState({
      isLocalAudioMuted: e,
    })
  }

  onKeyup = (e) => {
    if (!this.state.localStream || e.code !== 'Space') return
    this.toggleAudio(false)
  }

  startCall = async (username, userToCall) => {
    const { localConnection, localStream } = this.state

    listenToConnectionEvents(
      localConnection,
      username,
      userToCall,
      this.remoteVideoRef,
      doCandidate,
      this.pushCandidate,
    )
    // create an offer
    this.setState({
      ...this.state,
      createOfferRes: await createOffer(
        localConnection,
        localStream,
        userToCall,
        doOffer,
        username,
      ),
    })
  }

  setLocalVideoRef = (ref) => {
    // console.log(this.localVideoRef)
    this.localVideoRef = ref
  }

  setRemoteVideoRef = (ref) => {
    this.remoteVideoRef = ref
  }

  setAnswer = async (e) => {
    const { localConnection, localStream } = this.state
    this.setState({
      connectedUser: e.from,
    })
    listenToConnectionEvents(
      localConnection,
      'username',
      e.from,
      this.remoteVideoRef,
      doCandidate,
      this.pushCandidate,
    )

    this.setState({
      ...this.state,
      answerRes: await sendAnswer(
        localConnection,
        localStream,
        e,
        doAnswer,
        'username',
      ),
    })
  }

  setStartCall = async (e) => {
    const { localConnection } = this.state
    // this.setState({
    //   connectedUser: e.from
    // })
    startCall(localConnection, e)
  }

  pushCandidate = (e) => {
    console.log(this.state.candidates.push(e))
    this.setState({ ...this.state, candidates: this.state.candidates })
  }

  setCandidate = (e) => {
    if (!e) return
    const { localConnection } = this.state
    addCandidate(localConnection, JSON.parse(e))
  }
  setLabel = (e) => {
    this.setState({ ...this.state, label: e })
  }
  setUsers = (e) => {
    this.setState({ ...this.state, users: e })
  }

  render() {
    return (
      <Window label={this.state.label} hideCloseButton={true}>
        <div
          style={{ overflowY: 'scroll', maxHeight: '500px', diplay: 'table' }}
        >
          <VideoChat
            localConnection={this.state.localConnection}
            startCall={this.startCall}
            setLocalVideoRef={this.setLocalVideoRef}
            setRemoteVideoRef={this.setRemoteVideoRef}
            connectedUser={this.state.connectedUser}
            toggleAudio={this.toggleAudio}
            localStream={this.state.localStream}
            isLocalAudioMuted={this.state.isLocalAudioMuted}
          />
          {this.localVideoRef && (
            <>
              <ClientSocket
                // createOfferRes={this.state.createOfferRes}
                // onOffer={(e) => this.setAnswer(e)}
                // onAnswer={(e) => this.setStartCall(e)}
                // setCandidate={this.setCandidate}
                {...this.props}
                answerRes={this.state.answerRes}
                candidates={this.state.candidates}
                getLocalStream={this.getLocalStream}
                localStream={this.state.localStream}
                setPeerConnections={this.setPeerConnections}
                peerConnections={this.state.peerConnections}
                remoteVideoRef={this.remoteVideoRef}
                remoteStreams={this.state.remoteStreams}
                setRemoteStreams={this.setRemoteStreams}
                setLabel={this.setLabel}
                setUsers={this.setUsers}
              />
              {this.state.remoteStreams.map((rVideo) => (
                <Video
                  videoStream={rVideo.stream}
                  rVideo={rVideo}
                  users={this.state.users}
                  userName={this.state.users.filter(
                    (us) => us.id === rVideo.id,
                  )}
                />
              ))}
            </>
          )}
        </div>
      </Window>
    )
  }
}

export default VideoChatContainer
