import { createSlice } from '@reduxjs/toolkit'

const initialState = [
  { id: 'POINTS', isVisible: true },
  { id: 'ILS13', isVisible: false },
  { id: 'RNAV13', isVisible: false },
  { id: 'ILS31', isVisible: false },
  { id: 'RNAV31', isVisible: false },
  { id: 'RNAV15', isVisible: false },
  { id: 'RNAV33', isVisible: false },
  { id: 'RTE', isVisible: true },
  { id: 'External Freq', isVisible: true },
]

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    toggleMap: (state, action) => {
      const { mapId } = action.payload
      const activeMap = state.find((map) => map.id === mapId)
      if (activeMap) activeMap.isVisible = !activeMap.isVisible
    },
  },
})

export const { toggleMap } = mapSlice.actions

export default mapSlice.reducer
