/*
    node_js
    3/15/2021 11:08 AM
    by Oleksandr
*/

import React from 'react'
import { calcPassedPoints, getFlightColor } from '../../../../../calc/calc'
import { ASSUMED_COLOR, SSR_COLOR } from '../../colors/colors'
import { columns } from '../../../../../utils/constants'

const ActiveFlightsList = ({
  flights,
  startDate,
  isEpp,
  isPlt,
  tickValue,
  isCon,
  updateFlightForEpp,
  setFlights,
}) => {
  const deleteFlight = (e) => {
    setFlights((prev) =>
      prev.filter((flight) => flight.id !== e.target.dataset.fltid),
    )
  }

  const getCallsignColor = (flt) => {
    if (
      isPlt &&
      flt.flight[tickValue + flt.timeShift] &&
      (flt.flight[tickValue + flt.timeShift].isAdvanced ||
        flt.flight[tickValue + flt.timeShift].isAssumed)
    ) {
      return ASSUMED_COLOR
    } else if ((isCon && flt && flt.isAdvanced) || flt.isAssumed) {
      return ASSUMED_COLOR
    } else {
      return SSR_COLOR
    }
  }

  return (
    <tbody>
      {flights.map((row, index) => (
        <tr
          className="sddTableTr"
          style={{
            color: isCon
              ? getFlightColor(row)
              : getFlightColor(row.flight[tickValue + row.timeShift]),
          }}
          key={index}
        >
          {columns.map((column, index) => {
            let onclick = () => {}
            let style
            if (column === 'c') {
              style = { color: getCallsignColor(row) }
            }
            if (column === 'rfl')
              onclick = (e) => {
                if (!isEpp) {
                  return
                }

                const promptRFL = window.prompt('New RFL:')
                if (promptRFL === null) {
                  return
                }
                const flight = flights.filter((flight) => {
                  return flight.id === e.target.dataset.fltid
                })[0]
                flight.rfl = promptRFL
                flight.flight[0].sA = parseInt(promptRFL) * 100
                updateFlightForEpp(flight, isEpp)
              }
            if (column === 'entryTime' || column === 'exitTime')
              onclick = (e) => {
                if (isPlt) {
                  return
                }
                const promptTime = window.prompt('New time:')
                if (promptTime === null) {
                  return
                }
                const flight = flights.filter(
                  (flight) => flight.id === e.target.dataset.fltid,
                )[0]
                const cop =
                  e.target.dataset.cop === 'entryTime'
                    ? 'entryCop'
                    : e.target.dataset.cop === 'exitTime'
                    ? 'exitCop'
                    : ''
                if (flight[cop]) {
                  if (flight.passedPoints) {
                    let entryPointIndex = flight.passedPoints
                      .map((e) => e.pp.pP)
                      .indexOf(flight[cop])
                    const t1 = new Date(startDate.getTime()).setHours(
                      parseInt(promptTime.substr(0, 2)),
                      promptTime.substr(2, 2),
                    )
                    const newShift =
                      flight.passedPoints[entryPointIndex].index -
                      (t1 - startDate.getTime()) / 4000
                    flight.timeShift = parseInt(newShift)
                    calcPassedPoints(flight, startDate.getTime())
                  }
                }
              }
            return (
              <td
                className="sddTableTd"
                data-fltid={row.id}
                data-cop={column}
                onClick={onclick}
                style={style}
                key={`d-${index}`}
              >
                {row[column]}
              </td>
            )
          })}
          {isEpp && (
            <>
              <td
                className="sddTableTd"
                data-fltid={row.id}
                onClick={(e) => {
                  const promptXFL = window.prompt('New XFL:')
                  if (promptXFL === null) {
                    return
                  }
                  const flight = flights.filter(
                    (flight) => flight.id === e.target.dataset.fltid,
                  )[0]
                  flight.xfl = promptXFL
                }}
              >
                {row.xfl}
              </td>
              <td className="sddTableTd">
                <span
                  className="buttonSDD"
                  data-fltid={row.id}
                  onClick={deleteFlight}
                >
                  Delete
                </span>
              </td>
            </>
          )}
        </tr>
      ))}
    </tbody>
  )
}

export default ActiveFlightsList
