/*
    node_js
    3/5/2021 11:10 PM
    by Oleksandr
*/

import '../../CWP.css'
import React from 'react'
import { useCwpContext } from '../../../../contexts/CwpContext'

const DctMenu = ({ isCon }) => {
  const {
    callsignMenuFlight,
    typeOfFlightMenu,
    tickValue,
    showFlightMenu,
    startDate,
    dispatchFlightClearance,
  } = useCwpContext()

  const index =
    isCon || !callsignMenuFlight
      ? 0
      : tickValue + callsignMenuFlight.flight.timeShift

  return (
    <>
      {callsignMenuFlight &&
        typeOfFlightMenu &&
        callsignMenuFlight.flight.flight[index] && (
          <div
            className={'callsignMenu'}
            style={{
              left: callsignMenuFlight.clientX + 'px',
              top: callsignMenuFlight.clientY + 'px',
            }}
          >
            <fieldset
              style={{ paddingInline: '2px', border: '1px solid #545454' }}
            >
              <legend style={{ width: '90px' }}>DCT to:</legend>
              {callsignMenuFlight.flight.fpl[10]
                .filter((p, index) => {
                  return (
                    callsignMenuFlight.flight.flight[
                      tickValue + callsignMenuFlight.flight.timeShift
                    ].nP <= index
                  )
                })
                .map((p) => {
                  return (
                    <span
                      style={{ width: '80px' }}
                      className={'buttonSDD'}
                      onClick={(e) => {
                        // console.log(e, e.target);
                        dispatchFlightClearance({
                          type: 'dct',
                          value: e.target.outerText.toUpperCase(),
                          flight: callsignMenuFlight.flight,
                          index,
                          startDate,
                        })
                        showFlightMenu(null)
                      }}
                    >
                      {p.name}
                    </span>
                  )
                })}
            </fieldset>
          </div>
        )}
    </>
  )
}

export default DctMenu
