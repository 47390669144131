/*
    node_js
    2/19/2021 3:10 PM
    by Oleksandr
*/

import '../../CWP.css'
import React from 'react'
import HistoricalWindow from '../windows/HistoricalWindow'
import ActiveWindow from '../windows/ActiveWindow'
import ExerciseDescriptionWindow from '../windows/ExerciseDescriptionWindow'
import RwyWindow from '../windows/RwyWindow'
import ExerciseWindow from '../windows/ExerciseWindow'
import SectorWindow from '../windows/SectorWindow'
import MtcdList from '../windows/lists/MtcdList'
import { useCwpContext } from '../../../../contexts/CwpContext'
import AswToolbox from '../windows/asw/AswToolbox'
import FpAction from '../windows/asw/FpAction'
import CommunicationWindow from '../windows/CommunicationWindow'

const WindowLayer = ({ isEpp, isCon, isDemo, isPlt }) => {
  const { pttWindows } = useCwpContext()

  return (
    <>
      {pttWindows.aswToolbox1Window && <AswToolbox />}
      {pttWindows.mtcdWindow && <MtcdList />}
      {!isCon && !isDemo && pttWindows.exerciseDescriptionWindow && (
        <ExerciseDescriptionWindow isEpp={isEpp} />
      )}
      {isEpp && pttWindows.historicalWindow && <HistoricalWindow />}
      {(pttWindows.activeWindow || pttWindows.plannerWindow) && (
        <ActiveWindow isEpp={isEpp} isCon={isCon} isPlt={isPlt} />
      )}
      {(isEpp || isPlt) && pttWindows.rwyWindow && <RwyWindow />}
      {pttWindows.exerciseWindow && (
        <ExerciseWindow isPlt={isPlt} isEpp={isEpp} isDemo={isDemo} />
      )}
      {pttWindows.sectorWindow && <SectorWindow isEpp={isEpp} />}
      {pttWindows.fpActionWindow && <FpAction />}
      {pttWindows.communicationWindow && <CommunicationWindow />}
    </>
  )
}

export default WindowLayer
