/*
    node_js
    2/22/2021 7:43 PM
    by Oleksandr
*/
import React, { useRef, useState } from 'react'

import Window from '../cwp/graphics/windows/Window'
import Input from '../Input/Input'

const Login = ({ auth, setLogin }) => {
  const [formData, setFormData] = useState({})

  const handleInputChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const errorRef = useRef(null)

  const handleLogin = (event) => {
    event.preventDefault()
    auth.login(formData.username, formData.password, (resp) => {
      if (resp.success) {
        setFormData({})
        setLogin(true)
      } else {
        errorRef.current.innerHTML = 'Invalid Login or Password'
        errorRef.current.style.color = 'red'
      }
    })
  }

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Window
        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        maxContentHeight="auto"
        label="Login"
        hideCloseButton={true}
      >
        <form onSubmit={handleLogin} style={{ textAlign: 'middle' }}>
          <div style={{ padding: '24px 18px 0', minWidth: 474 }}>
            <div
              id="dialogContentText"
              style={{
                marginBottom: '24px',
              }}
              ref={errorRef}
            >
              To login to this App, please enter your <b>lvivacc.site</b> login
              and password here.
            </div>

            <Input
              name="username"
              label="Login"
              value={formData.username}
              onChange={handleInputChange}
              required
            />
            <Input
              name="password"
              type="password"
              label="Password"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </div>
          <div style={{ marginBottom: '12px' }}>
            <button
              type="submit"
              className={'buttonSDD'}
              style={{
                margin: '0 auto',
                position: 'relative',
                display: 'block',
                height: '40px',
              }}
            >
              Login
            </button>
          </div>
        </form>
      </Window>
    </div>
  )
}
export default Login
