/*
    node_js
    1/31/2021 7:17 PM
    by Oleksandr
*/
import Flight from './aircraft/Flight'
import { geoMercator } from 'd3-geo'
import { airports } from '../config/adap/nav/sidStar/ukll'
import {
  basicSectors,
  bv1,
  bvl,
  bvo,
  lve,
  lvt,
  lvw,
  uklv,
} from '../config/adap/map'
import { cops } from '../config/adap/cops'
import * as geolib from 'geolib'
import {
  ADVANCED_COLOR,
  ASSUMED_COLOR,
  SSR_COLOR,
  UNCONCERNED_COLOR,
} from '../components/cwp/graphics/colors/colors'

export const getProjection = (zoom, lonLatCenter) => {
  return geoMercator().scale(zoom).center(lonLatCenter)
}

export const distanceLatLon = (lat1, lon1, lat2, lon2) => {
  const R = 6372.797560856
  const dLat = ((lat2 - lat1) * Math.PI) / 180
  const dLon = ((lon2 - lon1) * Math.PI) / 180
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c
  return d / 1.852 // nm / kilometers
}

export const calcDeparture = (flight, runways) => {
  let adep = flight.adep
  let tempRunway = runways.filter((r) => r.ICAOcode === adep)
  if (tempRunway.length === 0) return
  let adepRunway = tempRunway[0].Runways
  if (adepRunway === '') return
  let tempAirport = airports.filter((a) => a.ICAOcode === adep)
  if (tempAirport.length === 0) return

  flight.adepRunway = adepRunway

  let tempSid = tempAirport[0].Sid.filter(
    (s) =>
      s.Name.slice(0, -2) === flight.fpl[10][1].name.substr(0, 4) &&
      s.Runways === adepRunway,
  )
  let AllDepartureWaypoints = []

  if (tempSid.length > 0) {
    AllDepartureWaypoints = AllDepartureWaypoints.concat(
      tempSid[0].Sid_Waypoint,
    )
    flight.fpl[10].splice(0, 2)

    const tempPoints = []
    AllDepartureWaypoints.forEach((wp, index) => {
      const length = AllDepartureWaypoints.length
      let latLongNext

      let tempWp
      if (wp.Type === 'DmeIntc') {
        let dmeIntc = geolib.computeDestinationPoint(
          {
            latitude: wp.Latitude,
            longitude: wp.Longitude,
          },
          parseInt(wp.DMEtoIntercept) * 1.852 * 1000,
          parseInt(wp.Hdg_Crs_value) + 6,
        )
        tempWp = {
          Name: wp.Name + wp.DMEtoIntercept,
          Latitude: dmeIntc.latitude,
          Longitude: dmeIntc.longitude,
        }
      } else if (wp.Type === 'ConstHdgtoAlt') {
        return
      } else {
        tempWp = wp
      }

      latLongNext =
        length > index + 1
          ? {
              latitude: parseFloat(AllDepartureWaypoints[index + 1].Latitude),
              longitude: parseFloat(AllDepartureWaypoints[index + 1].Longitude),
            }
          : {
              latitude: parseFloat(flight.fpl[10][0].latitude),
              longitude: parseFloat(flight.fpl[10][0].longitude),
            }

      const dist = distanceLatLon(
        parseFloat(tempWp.Latitude),
        parseFloat(tempWp.Longitude),
        latLongNext.latitude,
        latLongNext.longitude,
      )

      const tempRoutePoint = {
        name: tempWp.Name,
        isCop: cops.includes(tempWp.Name),
        latitude: parseFloat(tempWp.Latitude),
        longitude: parseFloat(tempWp.Longitude),
        dist,
      }
      tempPoints.push(tempRoutePoint)
    })

    flight.fpl[10] = tempPoints.concat(flight.fpl[10])
    flight.flight[0].h =
      geolib.getGreatCircleBearing(flight.flight[0].cP, flight.fpl[10][0]) - 6
    flight.fpl[10].unshift({})
  }
}

export const calcApproach = (flight, runways) => {
  // let adep = flight.adep
  let ades = flight.ades
  let tempRunway = runways.filter((r) => r.ICAOcode === ades)
  if (tempRunway.length === 0) return
  let adesRunway = tempRunway[0].Runways
  if (adesRunway === '') return
  let tempAirport = airports.filter((a) => a.ICAOcode === ades)
  if (tempAirport.length === 0) return

  flight.adesRunway = adesRunway
  flight.adesRunwayDetails = tempAirport[0].Runways[adesRunway]
  let tempApproach = tempAirport[0].Approach.filter(
    (a) => a.Name === 'ILS' + tempRunway[0].Runways,
  )
  if (tempApproach.length === 0) return

  let tempStar = tempAirport[0].Star.filter((s) => {
    console.log(
      s.Name.slice(0, -2),
      flight.fpl[10][flight.fpl[10].length - 2].name.substr(0, 4),
      s.Runways === adesRunway,
    )
    return (
      s.Name.slice(0, -2) ===
        flight.fpl[10][flight.fpl[10].length - 2].name.substr(0, 4) &&
      s.Runways === adesRunway
    )
  })
  let AllApproachWaypoints = []

  if (tempStar.length > 0) {
    if (
      tempApproach[0].App_Waypoint[0].Name ===
      tempStar[0].Star_Waypoint[tempStar[0].Star_Waypoint.length - 1].Name
    ) {
      tempStar[0].Star_Waypoint.pop()
    }
    AllApproachWaypoints = AllApproachWaypoints.concat(
      tempStar[0].Star_Waypoint,
    )
    flight.fpl[10].splice(flight.fpl[10].length - 2, 1)
  }

  let indexOfRunway = tempApproach[0].App_Waypoint.map((e) => e.Type).indexOf(
    'Runway',
  )
  if (tempApproach[0].App_Waypoint.length > indexOfRunway)
    tempApproach[0].App_Waypoint.splice(
      indexOfRunway + 1,
      tempApproach[0].App_Waypoint.length - indexOfRunway,
    )

  AllApproachWaypoints = AllApproachWaypoints.concat(
    tempApproach[0].App_Waypoint,
  )
  let tempPoints = []
  AllApproachWaypoints.forEach((wp, index) => {
    let dist = 0
    let length = AllApproachWaypoints.length
    let latLongNext

    latLongNext =
      length > index + 1
        ? {
            latitude: parseFloat(AllApproachWaypoints[index + 1].Latitude),
            longitude: parseFloat(AllApproachWaypoints[index + 1].Longitude),
          }
        : {
            latitude: parseFloat(wp.Latitude),
            longitude: parseFloat(wp.Longitude),
          }
    dist = distanceLatLon(
      parseFloat(wp.Latitude),
      parseFloat(wp.Longitude),
      latLongNext.latitude,
      latLongNext.longitude,
    )

    let tempRoutePoint = {
      name: wp.Name,
      isCop: cops.includes(wp.Name),
      isRunway: wp.Type === 'Runway',
      latitude: parseFloat(wp.Latitude),
      longitude: parseFloat(wp.Longitude),
      dist: dist,
    }
    tempPoints.push(tempRoutePoint)
  })
  if (flight.fpl[10][flight.fpl[10].length - 1].isAdes) flight.fpl[10].pop()
  flight.fpl[10] = flight.fpl[10].concat(tempPoints)

  return flight
}

export const calcFlight = (flight, tickValue, startDate, calcInterval = 4) => {
  const tst = new Flight({
    flight: flight.flight[tickValue],
    rfl: flight.rfl,
    fpl: flight.fpl,
    id: flight.id,
    cas: flight.cas,
    squawk: flight.flight[tickValue].sq || flight.squawk,
    adesRunway: flight.adesRunway,
    adesRunwayDetails: flight.adesRunwayDetails,
    type: flight.type,
    exC: flight.exitCop,
    ades: flight.ades,
    xfl: flight.xfl,
  })
  let pointsArray = []
  if (tickValue === 0) {
    flight.sectors = new Set()
    flight.sectorsTime = []
  } else {
    flight.sectorsTime = flight.sectorsTime.filter(
      (time) => time[0] <= tickValue,
    )
  }
  let tempTickValue = tickValue
  if (flight.passedPoints) {
    flight.passedPoints = flight.passedPoints.filter(
      (flight) => flight.index <= tempTickValue,
    )
  }
  let prevIBSN = null
  tst.iBSN = null
  while (tst.isActive) {
    tst.calculateNextPoint(calcInterval)
    let passedPoint, nextPoint, currentPosition
    if (tst.isTerminated) {
      passedPoint = tst.route[tst.route.length - 1].name
      nextPoint = passedPoint
      currentPosition = tst.route[tst.route.length - 1]
    } else {
      passedPoint = tst.passedPoint ? tst.route[tst.prevPoint - 1].name : null
      nextPoint = tst.route[tst.nextPoint].name
      currentPosition = tst.currentPosition
    }
    if (tempTickValue % 5 === 0 || calcInterval > 4) {
      prevIBSN = tst.iBSN
      tst.iBSN =
        currentPosition.latitude > 47.74 &&
        currentPosition.longitude > 22.15 &&
        currentPosition.latitude < 51.92 &&
        currentPosition.longitude < 33
          ? getInBasicSectorName(
              currentPosition.latitude,
              currentPosition.longitude,
              tst.altitude,
            )
          : null
      if (tst.iBSN && prevIBSN !== tst.iBSN) {
        // console.log(tst.iBSN, prevIBSN)
        flight.sectorsTime.push([tempTickValue - flight.timeShift, tst.iBSN])
      }
    }

    tempTickValue++
    tst.iBSN && flight.sectors.add(tst.iBSN)
    // if (tst.callsign === 'WRC7754' && calcInterval > 4) {
    //   console.log(
    //     calcInterval,
    //     tst.passedPoint,
    //     tst.currentPosition,
    //     tst.nextPoint,
    //     nextPoint,
    //     tst.altitude,
    //     tst.selectedAltitude,
    //     tst.iBSN,
    //     flight.sectors,
    //   )
    // }
    const tempPoint = {
      cP: currentPosition,
      c: tst.callsign,
      a: tst.altitude,
      sA: tst.selectedAltitude,
      h: tst.heading,
      sH: tst.bearing,
      isOH: tst.isOnHeading,
      isOO: tst.isOnOrbit,
      isOSt: tst.isOrbitStarted,
      oS: tst.orbitSide,
      s: tst.speed,
      sS: tst.selectedSpeed,
      t: tst.tas,
      gs: tst.gs,
      M: tst.Mach,
      isOS: tst.isOnSpeed,
      r: tst.roc,
      sR: tst.selectedRoc,
      isOR: false,
      nP: tst.nextPoint,
      nPN: nextPoint,
      prP: tst.prevPoint,
      pP: passedPoint,
      sq: tst.squawk,
      isAssumed: tst.isAssumed,
      isAdvanced: tst.isAdvanced,
      isCorrelated: tst.isCorrelated,
      tod: tst.TOD,
      toc: tst.TOC,
      isApp: tst.isApproach,
      isCILS: tst.isClearedILS,
      isGA: tst.isGA,
      isIAA: tst.isInApproachArea,
      minS: tst.minSpeed,
      maxS: tst.maxSpeed,
      maxFL: tst.maxFL,
      calcM: tst.calculatedMach,
      distToAdes: tst.distanceToDestination,
      distToNp: tst.distanceToNextPoint,
      isDesc: tst.isDescending,
      isClimb: tst.isClimbing,
      xfl: tst.xfl,
      exC: tst.exC,
      iBSN: tst.iBSN,
    }
    if (calcInterval <= 4 && tst.passedPoint) {
      flight.passedPoints.push({
        index: tempTickValue,
        pp: tempPoint,
      })
    }
    pointsArray.push(tempPoint)
  }
  flight.flight.length = tickValue + 1
  flight.flight = flight.flight.concat(pointsArray)
  if (calcInterval <= 4) {
    calcPassedPoints(flight, startDate)
    calcWorkload(flight)
  }
  return flight
}

export const calcWorkload = (flt) => {
  // let index = flt.timeShift
  for (let tickValue = 0; tickValue <= 180; tickValue++) {
    let index = tickValue * 5 + flt.timeShift
    if (!flt.workload) {
      flt.workload = []
      flt.workload.length = 180
      flt.workload.fill(0)
    }

    if (flt.flight[index]) {
      if (flt.flight[index].isAssumed) {
        flt.workload[tickValue] = 2
        if (Math.abs(flt.flight[index].sA - flt.flight[index].a) > 100) {
          flt.workload[tickValue] += 5
        }
        if (flt.flight[index + 5] && !flt.flight[index + 5].isAssumed) {
          flt.workload[tickValue] += 5
        }
      }
    }
  }
}

export const calcPassedPoints = (flight, startDate) => {
  flight.passedPoints = flight.passedPoints.map(({ index, pp }) => {
    const pointTime = new Date(startDate + index * 4000).toLocaleTimeString(
      'es-ES',
    )
    if (flight.entryCop === pp.pP) flight.entryTime = pointTime.substr(0, 5)
    if (flight.exitCop === pp.pP) flight.exitTime = pointTime.substr(0, 5)
    return { index: index, pp: pp, pt: pointTime }
  })
}
export const calcPointsDistance = (flight) => {
  flight.fpl[10] = flight.fpl[10].map((point, index, arr) => {
    if (arr[index + 1]) {
      point.dist = geolib.convertDistance(
        geolib.getDistance(
          { latitude: point.latitude, longitude: point.longitude },
          {
            latitude: arr[index + 1].latitude,
            longitude: arr[index + 1].longitude,
          },
        ),
        'sm',
      )
      point.heading = geolib.getGreatCircleBearing(
        { latitude: point.latitude, longitude: point.longitude },
        {
          latitude: arr[index + 1].latitude,
          longitude: arr[index + 1].longitude,
        },
      )
    }
    return point
  })
  return flight
}

// const LatDAndB = (lat1, lon1, brng, d) => {
//   let R = 6372.797560856
//   let lat1inRadians = (lat1 * Math.PI) / 180
//   let lon1inRadians = (lon1 * Math.PI) / 180
//   let lat2 = Math.asin(
//     Math.sin(lat1inRadians) * Math.cos(d / R) +
//       Math.cos(lat1inRadians) *
//         Math.sin(d / R) *
//         Math.cos((brng * Math.PI) / 180),
//   )
//   let lon2 =
//     lon1inRadians +
//     Math.atan2(
//       Math.sin((brng * Math.PI) / 180) *
//         Math.sin(d / R) *
//         Math.cos(lat1inRadians),
//       Math.cos(d / R) - Math.sin(lat1inRadians) * Math.sin(lat2),
//     )
//   return (lat2 * 180) / Math.PI
// }
// const LonDAndB = (lat1, lon1, brng, d) => {
//   let R = 6372.797560856
//   let lat1inRadians = (lat1 * Math.PI) / 180
//   let lon1inRadians = (lon1 * Math.PI) / 180
//   let lat2 = Math.asin(
//     Math.sin(lat1inRadians) * Math.cos(d / R) +
//       Math.cos(lat1inRadians) *
//         Math.sin(d / R) *
//         Math.cos((brng * Math.PI) / 180),
//   )
//   let lon2 =
//     lon1inRadians +
//     Math.atan2(
//       Math.sin((brng * Math.PI) / 180) *
//         Math.sin(d / R) *
//         Math.cos(lat1inRadians),
//       Math.cos(d / R) - Math.sin(lat1inRadians) * Math.sin(lat2),
//     )
//   return (lon2 * 180) / Math.PI
// }

export const minSep = (lat1, lon1, brng1, spd1, lat2, lon2, brng2, spd2) => {
  let i, geo1, geo2
  let minD = 100000
  let minDCoord = {}
  let minTime, tempminTime, dTemp, dTemp2, dTemp3

  for (i = 30; i < 1830; i += 30) {
    geo1 = geolib.computeDestinationPoint(
      { lat: lat1, lon: lon1 },
      ((spd1 * 1.852 * 1000) / 60 / 60) * i,
      brng1 + 6,
    )
    geo2 = geolib.computeDestinationPoint(
      { lat: lat2, lon: lon2 },
      ((spd2 * 1.852 * 1000) / 60 / 60) * i,
      brng2 + 6,
    )
    dTemp = geolib.convertDistance(geolib.getDistance(geo1, geo2), 'sm')

    if (dTemp < minD) {
      minD = dTemp
      minDCoord = { geo1: geo1, geo2: geo2 }
      minTime = i
    }
  }
  geo1 = geolib.computeDestinationPoint(
    { lat: lat1, lon: lon1 },
    ((spd1 * 1.852 * 1000) / 60 / 60) * (minTime + 1),
    brng1 + 6,
  )
  geo2 = geolib.computeDestinationPoint(
    { lat: lat2, lon: lon2 },
    ((spd2 * 1.852 * 1000) / 60 / 60) * (minTime + 1),
    brng2 + 6,
  )
  dTemp2 = geolib.convertDistance(geolib.getDistance(geo1, geo2), 'sm')

  geo1 = geolib.computeDestinationPoint(
    { lat: lat1, lon: lon1 },
    ((spd1 * 1.852 * 1000) / 60 / 60) * (minTime - 1),
    brng1 + 6,
  )
  geo2 = geolib.computeDestinationPoint(
    { lat: lat2, lon: lon2 },
    ((spd2 * 1.852 * 1000) / 60 / 60) * (minTime - 1),
    brng2 + 6,
  )
  dTemp3 = geolib.convertDistance(geolib.getDistance(geo1, geo2), 'sm')
  tempminTime = minTime
  if (dTemp2 < dTemp3) {
    for (i = minTime; i < tempminTime + 29; i += 1) {
      geo1 = geolib.computeDestinationPoint(
        { lat: lat1, lon: lon1 },
        ((spd1 * 1.852 * 1000) / 60 / 60) * i,
        brng1 + 6,
      )
      geo2 = geolib.computeDestinationPoint(
        { lat: lat2, lon: lon2 },
        ((spd2 * 1.852 * 1000) / 60 / 60) * i,
        brng2 + 6,
      )
      dTemp = geolib.convertDistance(geolib.getDistance(geo1, geo2), 'sm')

      if (dTemp < minD) {
        minD = dTemp
        minDCoord = { geo1: geo1, geo2: geo2 }
        minTime = i
      }
    }
  }
  if (dTemp2 > dTemp3) {
    for (i = minTime; i > tempminTime - 29; i -= 1) {
      geo1 = geolib.computeDestinationPoint(
        { lat: lat1, lon: lon1 },
        ((spd1 * 1.852 * 1000) / 60 / 60) * i,
        brng1 + 6,
      )
      geo2 = geolib.computeDestinationPoint(
        { lat: lat2, lon: lon2 },
        ((spd2 * 1.852 * 1000) / 60 / 60) * i,
        brng2 + 6,
      )
      dTemp = geolib.convertDistance(geolib.getDistance(geo1, geo2), 'sm')
      //trace("i:"+i);
      if (dTemp < minD) {
        minD = dTemp
        minDCoord = { geo1: geo1, geo2: geo2 }
        minTime = i
      }
    }
  }
  geo1 = geolib.computeDestinationPoint(
    { lat: lat1, lon: lon1 },
    ((spd1 * 1.852 * 1000) / 60 / 60) * (minTime + 60),
    brng1 + 6,
  )
  geo2 = geolib.computeDestinationPoint(
    { lat: lat2, lon: lon2 },
    ((spd2 * 1.852 * 1000) / 60 / 60) * (minTime + 60),
    brng2 + 6,
  )
  let minDCoord2 = { geo1: geo1, geo2: geo2 }
  let minSepDist = (parseInt(minD * 10) / 10).toString()
  let minSepTimeSec = minTime
  let minSepTime
  if (minTime < 3) {
    minSepTime = ''
  } else {
    let tempSec = parseInt((minTime / 60 - parseInt(minTime / 60)) * 60)
    if (tempSec < 10) {
      minSepTime = parseInt(minTime / 60).toString() + "'0" + tempSec.toString()
    }
    if (tempSec > 9) {
      minSepTime = parseInt(minTime / 60).toString() + "'" + tempSec.toString()
    }
  }
  return {
    minSepDist: minSepDist,
    minSepTimeSec: minSepTimeSec,
    minSepTime: minSepTime,
    minDCoord: minDCoord,
    minDCoord2: minDCoord2,
  }
}

export const download = (filename, text) => {
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text),
  )
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

export const checkIls = (flight) => {}

export const getFlightColor = (flight) => {
  return flight
    ? flight.isAssumed
      ? ASSUMED_COLOR
      : flight.isAdvanced
      ? ADVANCED_COLOR
      : flight.isCorrelated
      ? UNCONCERNED_COLOR
      : SSR_COLOR
    : SSR_COLOR
}

export const getIsInSector = (latitude, longitude, altitude, sectors) => {
  return (
    geolib.isPointInPolygon(
      {
        latitude: latitude,
        longitude: longitude,
      },
      sectors.ops.name === 'lvt'
        ? lvt
        : sectors.ops.name === 'lve'
        ? lve
        : sectors.ops.name === 'lvw'
        ? lvw
        : uklv,
    ) &&
    altitude > sectors.ops.vLimit.lower &&
    altitude <= sectors.ops.vLimit.upper
  )
}

export const calcExerciseWorkload = (flights, sectors) => {
  let inSectorFlights = []
  let uniqueFlights = new Set()
  for (let i = 0; i <= 900; i += 15) {
    let inSector = flights.filter((flt) => {
      const ind = i + flt.timeShift
      if (flt.flight[ind]) {
        let inBasicSectorName = getInBasicSectorName(
          flt.flight[ind].cP.latitude,
          flt.flight[ind].cP.longitude,
          flt.flight[ind].a,
        )
        if (inBasicSectorName) {
          if (sectors.ops.basicSectors.includes(inBasicSectorName)) {
            uniqueFlights.add(flt.c)
            return inBasicSectorName
          }
        }
      }
      return null
    })
    inSectorFlights.push(inSector.length)
  }
  return { inSectorFlights, uniqueFlights }
}

export const getInBasicSectorName = (latitude, longitude, altitude) => {
  let tmp = {
    lvt: lvt,
    lve: lve,
    lvw: lvw,
    uklv: uklv,
    bvl: bvl,
    bvo: bvo,
    bv1: bv1,
  }
  let inSectorName = basicSectors
    .filter((bs) => altitude > bs.vLimit.lower && altitude <= bs.vLimit.upper)
    .filter((ts) =>
      geolib.isPointInPolygon(
        {
          latitude: latitude,
          longitude: longitude,
        },
        tmp[ts.name],
      ),
    )
  return inSectorName[0] ? inSectorName[0].sector : null
}

export const getPolygonPoints = (pos) => {
  return (
    pos[0] -
    3 +
    ', ' +
    pos[1] +
    ' ' +
    pos[0] +
    ', ' +
    (3 + pos[1]) +
    ' ' +
    (pos[0] + 3) +
    ', ' +
    pos[1] +
    ' ' +
    pos[0] +
    ', ' +
    (pos[1] - 3)
  )
}
