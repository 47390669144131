import React, { useEffect, useState } from 'react'
import Window from './Window'
import { useCwpContext } from '../../../../contexts/CwpContext'
import { useSpeechSynthesis, useSpeechRecognition } from 'react-speech-kit'
import airlines from '../../../../app/airline'
import { useDispatch } from 'react-redux'
import { toggleADF, setCP } from '../../../../reducers/commSlice'

const alphabet = {
  A: 'Alfa',
  B: 'Bravo',
  C: 'Charlie',
  D: 'Delta',
  E: 'Echo',
  F: 'Foxtrot',
  G: 'Golf',
  H: 'Hotel',
  I: 'India',
  J: 'Juliett',
  K: 'Kilo',
  L: 'Lima',
  M: 'Mike',
  N: 'November',
  O: 'Oscar',
  P: 'Papa',
  Q: 'Quebec',
  R: 'Romeo',
  S: 'Sierra',
  T: 'Tango',
  U: 'Uniform',
  V: 'Victo',
  W: 'Whiskey',
  X: 'X-ray',
  Y: 'Yankee',
  Z: 'Zulu',
  0: 'zero',
}

const getCallsign = (callsignClearance) => {
  const callsign = airlines.find(
    (airline) => airline.code === callsignClearance.substr(0, 3),
  )?.callsign
  return callsign
    ? callsign.toLowerCase() +
        ' ' +
        callsignClearance
          .substr(3, callsignClearance.length)
          .split('')
          .map((letter) => alphabet[letter] || letter)
          .join(' ')
    : callsignClearance
        .split('')
        .map((letter) => alphabet[letter] || letter)
        .join(' ')
}

const getLevel = (afl, sfl) => {
  if (Math.abs(afl / 100 - sfl) < 5) return ''
  let levelClearance = ''
  if (afl / 100 < +sfl) {
    levelClearance += 'climbing to '
  } else {
    levelClearance += 'descending to '
  }
  if (+sfl < 110) {
    levelClearance += 'altitude ' + sfl * 100 + ' feet'
  } else {
    levelClearance +=
      'flight level' +
      sfl
        .split('')
        .map((letter) => alphabet[letter] || letter)
        .join(' ')
  }
  return levelClearance
}

const CommunicationWindow = () => {
  const { setPttWindows, flightClearances, sectors, flights, tickValue } =
    useCwpContext()
  const { speak, speaking, voices } = useSpeechSynthesis()
  const [voiceId, setVoiceId] = useState(6)
  const [messages, setMessages] = useState([])
  const dispatch = useDispatch()

  const isOnFrequency = (freq) => {
    return freq === sectors.ops.freq
  }

  const getFrequency = () => {
    return sectors.ops.freq
  }
  const getAtcCallsign = () => {
    return sectors.ops.callsign || 'Lviv Radar'
  }

  useEffect(() => {
    dispatch(toggleADF({ speaking }))
    if (!speaking && messages.length) {
      speak({
        text: messages[0].text,
        voice: voices[voiceId],
        pitch: messages[0].pitch,
      })
      dispatch(setCP({ cP: messages[0]?.cP }))
      setMessages((prev) => {
        return [...prev.slice(1)]
      })
    }
  }, [dispatch, speaking, messages])

  useEffect(() => {
    // setFlights((prev) =>
    flights.forEach((flt) => {
      // flt.comm = {}
      const comm = flt.sectorsTime.filter((time) =>
        sectors.ops.basicSectors.includes(time[1]),
      )[0]

      // const initialCallTime = comm ? (comm[0] < 16 ? 1 : comm[0] - 15) : null
      // console.log('initialCallTime', initialCallTime)
      // if (initialCallTime) flt.flight[initialCallTime].initialCall = true
      flt.comm.initialCall = comm
        ? comm[0] < 16
          ? 1
          : Math.abs(flt.timeShift) > comm[0] - 15
          ? Math.abs(flt.timeShift) + 2
          : comm[0] - 15
        : null
      return flt
    })
    // )
  }, [sectors, flights /*setFlights, tickValue*/])

  useEffect(() => {
    flights.forEach((flt) => {
      if (!flt.flight[tickValue + flt.timeShift]) return
      // console.log(flt.comm)
      if (flt.comm.initialCall === tickValue) {
        flt.comm.freq = getFrequency()
        const atcCallsign = getAtcCallsign()
        const r = /\d+/
        let inbound = ''
        if (
          flt.flight[tickValue + flt.timeShift].nPN.match(r) ||
          flt.flight[tickValue + flt.timeShift].nPN.length < 5
        ) {
          inbound =
            'inbound ' +
            flt.flight[tickValue + flt.timeShift].nPN
              .split('')
              .map((letter) => alphabet[letter] || letter)
              .join(' ')
        } else {
          inbound =
            'inbound ' + flt.flight[tickValue + flt.timeShift].nPN.toLowerCase()
        }
        setMessages((prev) => [
          ...prev,
          {
            text:
              atcCallsign +
              ', ' +
              getCallsign(flt.c) +
              ', good morning ' +
              inbound +
              getLevel(
                flt.flight[tickValue + flt.timeShift].a,
                parseInt(flt.flight[tickValue + flt.timeShift].sA / 100) + '',
              ),
            cP: flt.flight[tickValue + flt.timeShift].cP,
            pitch: flt.pitch,
          },
        ])
      }
      if (
        flt.flight[tickValue + flt.timeShift].tod &&
        !flt.comm.tod &&
        isOnFrequency(flt.comm.freq)
      ) {
        flt.comm.tod = tickValue
        setMessages((prev) => [
          ...prev,
          {
            text: 'Request descend, ' + getCallsign(flt.c),
            cP: flt.flight[tickValue + flt.timeShift].cP,
            pitch: flt.pitch,
          },
        ])

        // console.log('TOD', tickValue)
      } else if (!flt.flight[tickValue + flt.timeShift].tod) {
        flt.comm.tod = null
      }
      if (
        flt.flight[tickValue + flt.timeShift].isIAA &&
        !flt.comm.isIAA &&
        isOnFrequency(flt.comm.freq)
      ) {
        flt.comm.isIAA = tickValue
        setMessages((prev) => [
          ...prev,
          {
            text:
              'Established on localizer runway ' +
              flt.adesRunway
                .split('')
                .map((letter) => alphabet[letter] || letter)
                .join(' ') +
              getCallsign(flt.c),
            cP: flt.flight[tickValue + flt.timeShift].cP,
            pitch: flt.pitch,
          },
        ])
      } else if (!flt.flight[tickValue + flt.timeShift].isIAA) {
        flt.comm.isIAA = null
      }
    })
  }, [tickValue, flights])

  useEffect(() => {
    if (
      !flightClearances.lastClearance ||
      !isOnFrequency(flightClearances.lastClearance[10])
    )
      return
    console.log(
      flightClearances.lastClearance[10],
      isOnFrequency(flightClearances.lastClearance[10]),
      flightClearances.lastClearance,
    )
    const callsignClearance = flightClearances.lastClearance[4]

    const callsign = airlines.find(
      (airline) => airline.code === callsignClearance.substr(0, 3),
    )?.callsign
    const textToSpeech = callsign
      ? callsign.toLowerCase() +
        ' ' +
        callsignClearance
          .substr(3, callsignClearance.length)
          .split('')
          .map((letter) => alphabet[letter] || letter)
          .join(' ')
      : callsignClearance
          .split('')
          .map((letter) => alphabet[letter] || letter)
          .join(' ')

    let levelClearance = ''
    if (flightClearances.lastClearance[3] === 'sfl') {
      levelClearance = getLevel(
        flightClearances.lastClearance[6],
        flightClearances.lastClearance[2],
      )
    }
    const headingClearance =
      flightClearances.lastClearance[3] === 'hdg'
        ? 'turning on heading' +
          (flightClearances.lastClearance[2].length === 2
            ? '0' + flightClearances.lastClearance[2]
            : flightClearances.lastClearance[2]
          )
            .split('')
            .map((letter) => alphabet[letter] || letter)
            .join(' ')
        : ''
    let directClearance = ''
    if (flightClearances.lastClearance[3] === 'dct') {
      const r = /\d+/
      if (
        flightClearances.lastClearance[2].match(r) ||
        flightClearances.lastClearance[2].length < 5
      ) {
        directClearance =
          'direct to ' +
          flightClearances.lastClearance[2]
            .split('')
            .map((letter) => alphabet[letter] || letter)
            .join(' ')
      } else {
        directClearance =
          'direct to ' + flightClearances.lastClearance[2].toLowerCase()
      }
    }
    let assumeClearance = ''
    if (flightClearances.lastClearance[3] === 'assume') {
      if (flightClearances.lastClearance[5]) {
        return
      } else {
        assumeClearance = '1 2 5 decimal 4 5 zero, goodbye, '
        flightClearances.lastClearance[11].freq = null
      }
    }
    setMessages((prev) => [
      ...prev,
      {
        text:
          assumeClearance +
          directClearance +
          headingClearance +
          levelClearance +
          textToSpeech,
        cP: flightClearances.lastClearance[8],
        pitch: flightClearances.lastClearance[9],
      },
    ])
  }, [flightClearances])

  const handleCHange = ({ target }) => {
    setVoiceId(target.value)
  }

  const [value, setValue] = useState('')
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setValue((prev) => prev + ' ' + result)
    },
  })

  return (
    <Window
      toggleWindow={() => {
        setPttWindows((prev) => ({ ...prev, communicationWindow: false }))
      }}
      label={'Communication'}
      width={200}
      maxContentHeight={'auto'}
    >
      <div style={{ color: 'green', textAlign: 'center' }}>
        {sectors.ops.freq}
      </div>
      <div
        style={{
          display: 'inline-block',
          width: '98px',
          height: '50px',
          backgroundColor: 'rgb(255,215,0)',
          textAlign: 'center',
          lineHeight: '50px',
        }}
        className={speaking && 'rxBlink'}
      >
        RX
      </div>
      <div
        style={{
          display: 'inline-block',
          width: '98px',
          height: '50px',
          //   backgroundColor: 'rgb(0,255,0)',
          textAlign: 'center',
          lineHeight: '50px',
        }}
      >
        TX
      </div>
      <div>
        <select
          style={{ width: '195px' }}
          onChange={handleCHange}
          value={voiceId}
        >
          {voices.map((vc, index) => (
            <option key={vc.voiceURI} value={index}>
              {vc.name}
            </option>
          ))}
        </select>
      </div>
      {false && (
        <div>
          <textarea
            value={value}
            onChange={(event) => setValue(event.target.value)}
          />
          <button
            onMouseDown={() => listen({ interimResults: false, lang: 'en-GB' })}
            onMouseUp={stop}
          >
            🎤
          </button>
          {listening && <div>Go ahead I'm listening</div>}
        </div>
      )}
    </Window>
  )
}

export default CommunicationWindow
