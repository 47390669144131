import * as React from 'react'

import CWP from '../../components/cwp/CWP'
import ATG from '../../components/atg/ATG'
import DemoMsgWindow from '../../components/cwp/graphics/windows/modalWindows/DemoMsgWindow'

const CWPPage = ({ isDemo, isPlt, isCon, isEpp }) => {
  return (
    <>
      <CWP isDemo={isDemo} isCon={isCon} isPlt={isPlt} isEpp={isEpp} />
      {!isCon && <ATG />}
      {isDemo && <DemoMsgWindow />}
    </>
  )
}

export default CWPPage
