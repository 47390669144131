import React, { useCallback, useEffect, useState } from 'react'
import { useCwpContext } from '../../contexts/CwpContext'

import WindowLayer from './graphics/layers/WindowLayer'
import DemoManager from '../demo/DemoManager'
import InfoPanel from './graphics/InfoPanel'
import { ASW } from './asw/ASW'
import MainMenu from './graphics/MainMenu'

import Loading from '../Loader/Loading'

import VideoChatContainer from '../network/VideoChatContainer'

function CWP(props) {
  const { isEpp, isPlt, isDemo } = props
  const {
    updateFlight,
    selectedFlight,
    getData,
    isConnected,
    sessionId,
    isLoading,
    pttWindows,
    setZoom,
  } = useCwpContext()

  const [userName, setUserName] = useState('Lviv')

  const getHistorical = useCallback(async () => {
    try {
      if (isPlt || isEpp || isDemo) {
        await getData()
      }

      setUserName(
        localStorage.getItem('user_display_name') +
          '___' +
          Math.round(Math.random() * 999999),
      )
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    getHistorical()
  }, [])

  function onKeydown(e) {
    switch (e.key) {
      case 'PageDown':
        setZoom((prev) => prev - 100)
        break
      case 'PageUp':
        setZoom((prev) => prev + 100)
        break
      case 'ArrowUp':
        isEpp && updateFlight(selectedFlight, 3)
        break
      case 'ArrowDown':
        isEpp && updateFlight(selectedFlight, -3)
        break
      default:
        break
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeydown)
    return () => window.removeEventListener('keydown', onKeydown)
  }, [selectedFlight, setZoom, updateFlight])

  return (
    <div className="cwp" style={{ height: '100vh', zIndex: 99 }}>
      {isLoading && <Loading />}
      <ASW {...props} />
      {isConnected && (
        <VideoChatContainer
          {...props}
          username={userName}
          room={'session' + sessionId}
        />
      )}
      <WindowLayer {...props} />
      {isDemo && <DemoManager />}
      <InfoPanel {...props} />
      {pttWindows.mainMenu && <MainMenu {...props} />}
    </div>
  )
}

export default CWP
